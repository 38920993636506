<div class="container   ">
    <div class="row ">
        <div class="col-lg-6 ">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Search by Date</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

            </mat-form-field>
        </div>
        <div class="col-lg-6 "><span class="float-right">
                <div class="send-email-prescription d-flex align-items-baseline">

                    <mat-form-field class="w-100 mr-3" appearance="outline">
                        <mat-label>Enter Email to send records</mat-label>
                        <input matInput type="text" [formControl]="email" placeholder="Enter email">
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="mx-5" (click)="send()">Send</button> <br>
                    <button mat-mini-fab color="mute" (click)="downloadAsPDF()"><mat-icon>download</mat-icon></button>
                    <button class="btn backG float-right ml-3 no-print" onClick="window.focus(); window.print()"
                        value="print"><mat-icon>print</mat-icon></button>
                </div>
            </span></div>

    </div>

    <div #PatientVisit id="printable">
        <app-view-hospital></app-view-hospital>







        <h1 class="text-center" style="font-weight:bold ;">Patent Medical Records</h1>




        <div class="">
            <h2 class="">Patient Information</h2>

            <div class="row">
                <div class="col-lg-2"><span class="bld">Patient No:</span>
                    {{patient?.PatientId}}</div>
                <div class="col-lg-3">
                    <span class="bld">Patient Name:</span> {{patient?.PatientFirstName}} {{patient?.PatientLastName}}
                </div>
                <div class="col-lg-2"><span class="bld">Date Of Birth:</span> {{patient?.PatientDOB}}</div>
                <div class="col-lg-2"><span class="bld">SSN:</span> {{patient?.PatientNationalId}}</div>
                <div class="col-lg-3"><span class="bld">Address:</span> {{patient?.PatientAddress}}</div>
            </div>
        </div>
        <hr>



        <div class=" mt-5" *ngFor="let item of filteredList ">






            <div class=" row">
                <div class="col-lg-6">
                    <h2 class="">Medical Report Date: {{item?.Date }}</h2>
                    <h4 class="">Report Id: {{item?.PatientVisitId }}</h4>
                    <!-- | date: 'dd/MM/yyyy' -->

                </div>

            </div>
            <div class="row">
                <div class="col-lg-2"> <span class="bld">Provider:</span> {{item?.DoctorName}}</div>
                <!-- <div class="col-lg-3"> <span class="bld">Doctor Speciality:</span> {{item?.DoctorSpecialization}} </div>-->
                <div class="col-lg-2"><span class="bld">Visit Type:</span> {{item?.VisitType}}</div>
                <div class="col-lg-5"><span class="bld">Visit Date & time:</span> {{item?.createdDate | date :'short'}}
                </div>
                <div class="col-lg-3"><span class="bld">Visit Time:</span> {{item?.VistTime}}</div>
            </div>

            <!-- <hr>
        <div class="row">
  
          <div class="col-lg-2"><span class="bld">Blood Pressure:</span> {{item?.BloodPressure}}</div>
          <div class="col-lg-2"><span class="bld">Temparature:</span> {{item?.Temparature}}</div>
          <div class="col-lg-2"><span class="bld">Heart Rate:</span> {{item?.HeartRate}}</div>
          <div class="col-lg-2"><span class="bld">Pulse:</span> {{item?.Pulse}}</div>
  
        </div> -->
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Chief Complaint :</span> {{item?.ChiefComplaint}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Present Illness :</span> {{item?.PresentIllness}}</div>
            </div>
            <hr>
            <h2> Past History</h2>

            <div class="row">
                <div class="col-lg-6"><span class="bld">Medical :</span> {{item?.Medical}}</div>
                <div class="col-lg-6"><span class="bld">Surgical :</span> {{item?.Surgical}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Allergies :</span> {{item?.Allergies}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Medications :</span> {{item?.Medications}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Social History :</span> {{item?.SocialHistory}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Family History :</span> {{item?.FamilyHistory}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Review Of Symptoms :</span> {{item?.ReviewOfSymptoms}}</div>
            </div>
            <hr>
            <h2>Physical Examinations</h2>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Diagnosis Codes :</span> {{item?.IcdCode}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Head, Eyes, Ears, Nose and Throat :</span> {{item?.HENT}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Neck :</span> {{item?.Neck}}</div>

            </div>
            <hr>
            <h2>VitalSigns</h2>
            <div class="row">
                <div class="col-lg-2"><span class="bld"> Height:</span> {{item?.Height}}</div>
                <div class="col-lg-2"><span class="bld"> Weight:</span> {{item?.Weight}}</div>
                <div class="col-lg-2"><span class="bld"> BloodPressure:</span> {{item?.BloodPressure}}</div>
                <div class="col-lg-2"><span class="bld"> Pulse:</span> {{item?.Pulse}}</div>
                <div class="col-lg-2"><span class="bld"> Temparature:</span> {{item?.Temparature}}</div>
                <div class="col-lg-2"><span class="bld"> HeartRate:</span> {{item?.HeartRate}}</div>

            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Chest :</span> {{item?.Chest}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Heart :</span> {{item?.Heart}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Abdomen :</span> {{item?.Abdomen}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Extermities :</span> {{item?.Extermities}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Neurological :</span> {{item?.Neurological}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Radiology (X-Rays/MRI's) :</span> {{item?.Radiology}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Impressions :</span> {{item?.Impression}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Recomendations :</span> {{item?.Recomendation}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Doctor Name :</span> {{item?.DoctorName}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Signature :</span> {{item?.Signature}}</div>
            </div>
            <hr>
            <div class="row">
                <div class="col-lg-12"><span class="bld">Next Follow Up :</span> {{item?.NextFollowUp }}</div>
            </div>
            <hr>

            <!-- <div class="row">
        <div class="col-lg-6">
    <h3 class="bld">Patient Complaints</h3>
  
            {{item.PatientComplaints}}
        </div>
        <div class="col-lg-6">
    <h3 class="bld"> Issues Started From</h3>
  
            {{item.IssueStartDate}}
        </div>
       
    </div>
  
  <hr> -->

            <!-- <div class="row">
        <div class="col-lg-6">
            <h3 class="bld">Dignosis</h3>
        
                    {{item.Dignosis}}
                </div>
        <div class="col-lg-6">
    <h3 class="bld">Findings</h3>
  
            {{item.Findings}}
        </div>
        
    </div> -->




        </div>
    </div>
    <button color="primary" class="mx-5 my-5" mat-raised-button *ngIf="hasMoreItems()" (click)="loadMore()">
        Load More
    </button>




</div>



<hr class="bg-primary">