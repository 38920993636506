import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import Quill from 'quill';
import { Observable, startWith, map } from 'rxjs';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorReport } from 'src/app/Models/doctorreport.model';
import { DoctorTemplate } from 'src/app/Models/doctortemplate.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorReportService } from 'src/app/services/doctor-report.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization

import { NurseReport } from 'src/app/Models/nursereport.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { RadiologyReport } from 'src/app/Models/radiologyreport.model';
import { Labreport } from 'src/app/Models/labreport.model';
import { RadiologyMri } from 'src/app/Models/radiologymri.model';
import { UploadLabReport } from 'src/app/Models/uploadlabreport.model';
import { Allergy } from 'src/app/Models/allergy.models';
import { HospitalService } from 'src/app/services/hospital.service';
import { HospitalTemplate } from 'src/app/Models/hospitaltemplate.mode';
import { HospitaltemplateService } from 'src/app/services/hospitaltemplate.service';

import { TokenService } from 'src/app/services/token.service';
import { AddInvoiceOnProgressNoteComponent } from '../add-invoice-on-progress-note/add-invoice-on-progress-note.component';
import { MatDialog } from '@angular/material/dialog';
import { FavoriteDiagnosisPopupComponent } from '../favorite-diagnosis-popup/favorite-diagnosis-popup.component';
import { ClinicalAssistanceService } from 'src/app/services/clinical-assistance.service';

@Component({
  selector: 'app-add-doctor-progress-note',
  templateUrl: './add-doctor-progress-note.component.html',
  styleUrls: ['./add-doctor-progress-note.component.css'],
})
export class AddDoctorProgressNoteComponent implements OnInit {
  displayedColumns: string[] = ['Name'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  id = 0;
  reportList: DoctorReport[] = [];
  isSuccess = false;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  UserId: number = 0;
  doctor: Doctor;
  patient: Patient;
  templates: DoctorTemplate[] = [];
  templatesDrProgressNote: DoctorReport[] = [];
  templatesVitals: NurseReport[] = [];
  templatesPrescriptions: Prescription[] = [];
  templatesLabReports: Labreport[];
  templatesRadiologyReports: RadiologyReport[];
  templatesLabReportsUploaded: UploadLabReport[];

  filteredReportName: Observable<string[]>;
  filteredShortcut: Observable<string[]>;
  shortcutNames: string[] = ['@MostRecent', '@00'];

  reportNames: string[] = [
    'Consult note',
    'Progress note',
    'Procedure note',
    'Office visit note',
    'Telephone Note',
  ];
  quillEditor: any;
  previousTranscript: string = '';
  recognition: any;
  isRecognizing: any;

  @ViewChild('editorContainer') editorContainer!: ElementRef;

  cptCodeList = [
    {
      code: 'E11.9',
      description: 'Type 2 diabetes mellitus without complications',
    },
    {
      code: 'I10',
      description: 'Essential hypertension',
    },
    {
      code: '99203',
      description: 'Office visit for back pain, new patient, 45 minutes',
    },
    {
      code: '99211',
      description: 'Office visit for established patient, 5 minutes',
    },
    {
      code: '99212',
      description: 'Office visit for established patient, 10 minutes',
    },
  ];

  suggestions: any[] = []; // Holds the suggestions based on the input
  selectedCptCode: any = null; // Store the selected CPT code to insert into the editor
  editorContent: string = ''; // To hold the content typed into the editor
  templatesRadiologyStudyReports: RadiologyMri[];
  templatesAllergies: Allergy[];
  hospitaltemplates: HospitalTemplate[];
  hospitalId = 0;
  favoriteDiagnosisCodes = [];

  constructor(
    private fb: UntypedFormBuilder,
    private doctorReportService: DoctorReportService,
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private hospitalTemplateService: HospitaltemplateService,
    private dialog: MatDialog,
    private assistanceService: ClinicalAssistanceService,
    private cdRef: ChangeDetectorRef,
    private tokenService: TokenService
  ) {}
  editorModules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
      ['blockquote', 'code-block'],
      [{ script: 'sub' }, { script: 'super' }],
      ['emoji'], // You can add any additional modules you want
    ],
    clipboard: true, // Enable clipboard for copy-paste
  };
  ngOnInit(): void {
    this.quillEditor = new Quill('#editor', {
      theme: 'snow',
      modules: this.editorModules,
    });
    // Bind the text input event
    this.quillEditor.on('text-change', (delta, oldDelta, source) => {
      const editor = document.querySelector('.ql-editor') as HTMLElement;
      const container = document.querySelector('.ql-container') as HTMLElement;
      container.style.height = editor.scrollHeight + 'px';
      this.onTextInput();
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.UserId = parseInt(decodedToken.nameid);

    this.loadTemplates(this.UserId);

    this.loadGlobalTemplates();

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.loadVitals(this.id);
    this.loadAllergies(this.id);
    this.loadPrescriptions(this.id);
    // this.loadDoctorProgressNote(this.id);
    // this.loadStudyReportsUploaded(this.id);
    // this.loadLabReportsUploaded(this.id);
    this.loadLabReports(this.id);

    this.getPatient(this.id);
    this.getDoctor(this.UserId);

    this.filteredReportName = this.ReportName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter5(value || ''))
    );
    this.filteredShortcut = this.shortcut.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter6(value || ''))
    );
  }

  Frm = this.fb.group({
    shortcut: ['', Validators.required],
    ReportName: ['', Validators.required],
  });
  get shortcut() {
    return this.Frm.get('shortcut');
  }
  get ReportName() {
    return this.Frm.get('ReportName');
  }

  get ReportDescription() {
    return this.Frm.get('ReportDescription');
  }

  startVoiceRecognition() {
    this.recognition = new (window.SpeechRecognition ||
      (window as any).webkitSpeechRecognition)();
    this.recognition.lang = 'en-US';
    this.recognition.continuous = true; // Keep listening
    this.recognition.interimResults = true; // Show real-time speech results
    this.recognition.onresult = (event: any) => {
      const transcript = event.results[event.resultIndex][0].transcript;

      // Only insert the final result or if the text is different from the previous
      if (
        event.results[event.resultIndex].isFinal &&
        transcript !== this.previousTranscript
      ) {
        // console.log('Final Recognized Text:', transcript);

        // Get the current selection (cursor position)
        const range = this.quillEditor.getSelection();

        if (range) {
          // Insert the recognized text at the current cursor position
          this.quillEditor.insertText(range.index, transcript);
        }

        // Update the previousTranscript to prevent duplicate insertions
        this.previousTranscript = transcript;
      }
    };

    this.recognition.onerror = (event: any) => {
      // console.error('Speech recognition error:', event.error);
    };
    // Event listener for when recognition ends (you can stop manually here too if needed)
    this.recognition.onend = () => {
      // console.log('Speech recognition ended');
      this.isRecognizing = false;
    };
    this.recognition.start();
  }
  // Stop Speech Recognition
  stopRecognition() {
    if (this.isRecognizing) {
      console.log('Stopping recognition...');
      this.recognition.stop(); // Stop the recognition
      this.isRecognizing = false;
    }
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  loadTemplates(id) {
    this.doctorService.getDoctorTemplates(id).subscribe((data) => {
      // console.log(data);
      this.templates = data.DoctorTemplates;
      this.dataSource = new MatTableDataSource(data.DoctorTemplates);
      this.dataSource.paginator = this.paginator;
    });
  }
  loadGlobalTemplates() {
    this.hospitalTemplateService.getTemplates().subscribe((data) => {
      // console.log(data);
      this.hospitaltemplates = data;
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    });
  }
  loadAllergies(id) {
    this.patientService.getPatientAllergy(id).subscribe((data) => {
      // console.log(data);
      this.templatesAllergies = data.Allergies;
    });
  }
  loadVitals(id) {
    this.patientService.getNurseReport(id).subscribe((data) => {
      // console.log(data);
      this.templatesVitals = data.NurseReports;
    });
  }
  loadPrescriptions(id) {
    this.patientService.getPatientPrescription(id).subscribe((data) => {
      // console.log(data);
      this.templatesPrescriptions = data.Prescriptions;
    });
  }
  // loadDoctorProgressNote(id) {
  //   this.patientService.getDoctorReport(id).subscribe((data) => {
  //     this.templatesDrProgressNote = data.DoctorReports;
  //   });
  // }
  loadLabReports(id) {
    this.patientService.getPatienLabReport(id).subscribe((data) => {
      this.templatesLabReports = data.LabReports;
    });
  }
  // loadLabReportsUploaded(id) {
  //   this.patientService.GetUploadedLabReport(id).subscribe((data) => {
  //     this.templatesLabReportsUploaded = data.UploadLabReports;
  //   });
  // }
  // loadStudyReportsUploaded(id) {
  //   this.patientService.getRadiologyMRI(id).subscribe((data) => {
  //     this.templatesRadiologyStudyReports = data.RadiologyMris;
  //   });
  // }

  // Method to handle click on the table row for template selection
  onTableRowClick(template: any) {
    // console.log(template.Content);
    this.quillEditor.clipboard.dangerouslyPasteHTML(template.Content);
    // this.selectTemplate(template.Content);
  }
  // This function is triggered on every keystroke or text area change in the Quill editor
  onTextInput(): void {
    const cursorPosition = this.quillEditor.getSelection()?.index;
    let currentText = this.quillEditor.getText();
    // let currentText = this.quillEditor.getText().toLowerCase().trim();
    // Remove extra spaces and newlines

    // this.suggestions = this.getCptCodeSuggestions(currentText);
    // this.cdRef.detectChanges();
    // Loop through templates and replace shortcut with actual content
    this.templates.forEach((template) => {
      if (currentText.includes(template.shortcut)) {
        const startIndex = currentText.indexOf(template.shortcut);
        const endIndex = startIndex + template.shortcut.length;

        // Remove shortcut and insert content
        this.quillEditor.deleteText(startIndex, template.shortcut.length);

        // Sanitize HTML content to ensure safe insertion
        const sanitizedContent = DOMPurify.sanitize(template.Content);

        // Insert sanitized content
        this.quillEditor.clipboard.dangerouslyPasteHTML(
          startIndex,
          sanitizedContent
        );
        this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
      }
    });
    this.hospitaltemplates.forEach((template) => {
      if (currentText.includes(template.shortcut)) {
        const startIndex = currentText.indexOf(template.shortcut);
        const endIndex = startIndex + template.shortcut.length;

        // Remove shortcut and insert content
        this.quillEditor.deleteText(startIndex, template.shortcut.length);

        // Sanitize HTML content to ensure safe insertion
        const sanitizedContent = DOMPurify.sanitize(template.Content);

        // Insert sanitized content
        this.quillEditor.clipboard.dangerouslyPasteHTML(
          startIndex,
          sanitizedContent
        );
        this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
      }
    });
    this.templatesAllergies.forEach((DrReport) => {
      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        // Check for the keyword "MostRecent"
        if (currentText.includes('Allergy')) {
          // Find the position of the keyword
          const startIndex = currentText.indexOf('Allergy');
          const endIndex = startIndex + 'Allergy'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'Allergy'.length);

          // Fetch or construct the table content dynamically
          const mostRecentData = this.templatesAllergies.sort(
            (a, b) =>
              new Date(b.createtdAt).getTime() -
              new Date(a.createtdAt).getTime()
          )[0];

          if (mostRecentData) {
            // Create an HTML table with the most recent data
            const tableContent = `
                <p><strong>Most Recently  reported  Allergies (Recorded: ${new Date(
                  mostRecentData.createtdAt
                ).toLocaleString()}):</strong></p>
        <p>Allergic to Substance: ${mostRecentData.AllergicTo}</p>
        <p>Reaction: ${mostRecentData.Reaction}</p>
       
      `;

            // Sanitize the table content
            const sanitizedContent = DOMPurify.sanitize(tableContent);

            // Insert the sanitized table into the editor
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            // Set the cursor position after the inserted table
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
    //     this.templatesDrProgressNote.forEach((DrReport) => {
    //       if (currentText.includes(DrReport.shortcut)) {
    //         const startIndex = currentText.indexOf(DrReport.shortcut);
    //         const endIndex = startIndex + DrReport.shortcut.length;

    //         this.quillEditor.deleteText(startIndex, DrReport.shortcut.length);
    //         const combinedData = `
    //         <p><strong>Most Recent  Progress Note (Recorded: ${new Date(
    //           DrReport.createdDate
    //         ).toLocaleString()}):</strong></p>
    // <p>Progress Note: ${DrReport.ReportDescription}</p>

    // `;
    //         const sanitizedContent = DOMPurify.sanitize(combinedData);

    //         this.quillEditor.clipboard.dangerouslyPasteHTML(
    //           startIndex,
    //           sanitizedContent
    //         );
    //         this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
    //       }
    //       this.quillEditor.on('text-change', () => {
    //         const currentText = this.quillEditor.getText();

    //         if (currentText.includes('MostRecentProgressNote')) {
    //           const startIndex = currentText.indexOf('MostRecentProgressNote');
    //           const endIndex = startIndex + 'MostRecentProgressNote'.length;

    //           this.quillEditor.deleteText(
    //             startIndex,
    //             'MostRecentProgressNote'.length
    //           );

    //           const mostRecentData = this.templatesDrProgressNote.sort(
    //             (a, b) =>
    //               new Date(b.createdDate).getTime() -
    //               new Date(a.createdDate).getTime()
    //           )[0];

    //           if (mostRecentData) {
    //             const tableContent = `
    //                 <p><strong>Most Recent  Progress Note (Recorded: ${new Date(
    //                   mostRecentData.createdDate
    //                 ).toLocaleString()}):</strong></p>
    //         <p>Progress Note: ${mostRecentData.ReportDescription}</p>

    //       `;

    //             const sanitizedContent = DOMPurify.sanitize(tableContent);

    //             this.quillEditor.clipboard.dangerouslyPasteHTML(
    //               startIndex,
    //               sanitizedContent
    //             );

    //             this.quillEditor.setSelection(
    //               startIndex + sanitizedContent.length,
    //               0
    //             );
    //           }
    //         }
    //       });
    //     });
    this.templatesVitals.forEach((nureseReport) => {
      if (currentText.includes(nureseReport.shortcut)) {
        const startIndex = currentText.indexOf(nureseReport.shortcut);

        // Remove the shortcut
        this.quillEditor.deleteText(startIndex, nureseReport.shortcut.length);

        // Insert nureseReport data
        const nureseReportContent = `
         <p><strong>Most Recent Vitals(Recorded: ${new Date(
           nureseReport.createdDate
         ).toLocaleString()}):</strong></p>
          <p>Respiration: ${nureseReport.Respiration}</p>
          <p>Blood Pressure: ${nureseReport.BloodPressure}</p>
          <p>Temperature: ${nureseReport.Temparature}</p>
          <p>Heart Beat: ${nureseReport.HeartBeat}</p>
          <p>Oxygen Saturation: ${nureseReport.OxygenSaturation}</p>
          <p>BMI: ${nureseReport.BMI}</p>
        `;

        // Sanitize and insert content
        const sanitizedContent = DOMPurify.sanitize(nureseReportContent);
        this.quillEditor.clipboard.dangerouslyPasteHTML(
          startIndex,
          sanitizedContent
        );
        this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
      }

      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        // Check for the keyword "MostRecentVitals"
        if (currentText.includes('LatestVitals')) {
          const startIndex = currentText.indexOf('LatestVitals');
          const endIndex = startIndex + 'LatestVitals'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'LatestVitals'.length);

          // Fetch or construct the most recent vitals data
          const mostRecentData = this.templatesVitals.sort(
            (a, b) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime()
          )[0];

          if (mostRecentData) {
            const tableContent = `
        <p><strong>Most Recent Vitals (Recorded: ${new Date(
          mostRecentData.createdDate
        ).toLocaleString()}):</strong></p>
        <p>Respiration: ${mostRecentData.Respiration}</p>
        <p>Blood Pressure: ${mostRecentData.BloodPressure}</p>
        <p>Temperature: ${mostRecentData.Temparature}</p>
        <p>Heart Beat: ${mostRecentData.HeartBeat}</p>
            <p>Oxygen Saturation: ${nureseReport.OxygenSaturation}</p>
          <p>BMI: ${nureseReport.BMI}</p>
      `;
            const sanitizedContent = DOMPurify.sanitize(tableContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
        // Check for the keyword "MostRecentVitals"
        if (currentText.includes('VitalsRecent-10')) {
          const startIndex = currentText.indexOf('VitalsRecent-10');
          const endIndex = startIndex + 'VitalsRecent-10'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'VitalsRecent-10'.length);

          // Fetch or construct the 10 most recent vitals data
          const mostRecentData = this.templatesVitals
            .sort(
              (a, b) =>
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime()
            )
            .slice(0, 10); // Get the 10 most recent records

          if (mostRecentData.length > 0) {
            // Construct the table content for the 10 most recent vitals
            let tableContent;
            mostRecentData.forEach((record) => {
              tableContent += `
         <p><strong>Most Recent Vitals(Recorded: ${new Date(
           nureseReport.createdDate
         ).toLocaleString()}):</strong></p>
          <p>Respiration: ${nureseReport.Respiration}</p>
          <p>Blood Pressure: ${nureseReport.BloodPressure}</p>
          <p>Temperature: ${nureseReport.Temparature}</p>
          <p>Heart Beat: ${nureseReport.HeartBeat}</p>
              <p>Oxygen Saturation: ${nureseReport.OxygenSaturation}</p>
          <p>BMI: ${nureseReport.BMI}</p>
        `;
            });

            tableContent += `
        </tbody>
      </table>
    `;

            // Sanitize the content
            const sanitizedContent = DOMPurify.sanitize(tableContent);

            // Insert the sanitized content into the Quill editor
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );

            // Adjust the cursor position
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        // Check for the keyword "MostRecentBloodPressure"
        if (currentText.includes('BPRecent')) {
          const startIndex = currentText.indexOf('BPRecent');
          const endIndex = startIndex + 'BPRecent'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'BPRecent'.length);

          // Fetch or construct the 5 most recent blood pressure readings
          const recentBloodPressure = this.templatesVitals
            .filter((vital) => vital.BloodPressure)
            .sort(
              (a, b) =>
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime()
            )
            .slice(0, 5);

          if (recentBloodPressure.length > 0) {
            let bpContent =
              '<p><strong>Most Recent Blood Pressure Readings:</strong></p>';
            recentBloodPressure.forEach((entry) => {
              bpContent += `
        
             <p>Date: ${new Date(
               entry.createdDate
             ).toLocaleString()}: Blood Pressure ${entry.BloodPressure}</p>
        `;
            });
            // <p>Heart Rate: ${entry.HeartBeat}</p>
            // <p><strong>Recorded: ${new Date(
            //   entry.createdDate
            // ).toLocaleString()}</strong></p>
            const sanitizedContent = DOMPurify.sanitize(bpContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        // Check for the keyword "BloodPressureLast10Days"
        if (currentText.includes('BPlast-10days')) {
          const startIndex = currentText.indexOf('BPlast-10days');
          const endIndex = startIndex + 'BPlast-10days'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'BPlast-10days'.length);

          // Get blood pressure readings from the last 10 days
          const last10DaysBP = this.templatesVitals
            .filter(
              (vital) =>
                new Date(vital.createdDate) >=
                new Date(new Date().setDate(new Date().getDate() - 10))
            )
            .sort(
              (a, b) =>
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime()
            );

          if (last10DaysBP.length > 0) {
            let bpContent =
              '<p><strong>Blood Pressure Trend (Last 10 Days):</strong></p>';
            last10DaysBP.forEach((entry) => {
              bpContent += `
         
         <p>Date: ${new Date(
           entry.createdDate
         ).toLocaleString()}: Blood Pressure ${entry.BloodPressure}</p>
        `;
            });
            // <p>Heart Rate: ${entry.HeartBeat}</p>
            // <p><strong>Recorded: ${new Date(
            //   entry.createdDate
            // ).toLocaleString()}</strong></p>
            const sanitizedContent = DOMPurify.sanitize(bpContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
    this.templatesPrescriptions.forEach((prescription) => {
      if (currentText.includes(prescription.shortcut)) {
        const startIndex = currentText.indexOf(prescription.shortcut);

        // Remove the shortcut
        this.quillEditor.deleteText(startIndex, prescription.shortcut.length);

        // Insert prescription data
        const prescriptionContent = `
          <p><strong>Most Recent Precriptions (Recorded: ${new Date(
            prescription.CreatedDate
          ).toLocaleString()}):</strong></p>
        <p>Drug Name: ${prescription.DrugName}</p>
        <p>Amount PerDose: ${prescription.AmountPerDose}${prescription.Unit}</p>
        <p>Route: ${prescription.Route}</p>
        <p>Frequency: ${prescription.Frequency}</p>
        <p>Refill Duration: ${prescription.RefillDuration}</p>
        <p>Number of Refills: ${prescription.Numberofrefills}</p>
        <p>Quantity: ${prescription.Quantity}${prescription.Unit}</p>
        <p>StartDate: ${prescription.StartDate}</p>
        <p>Expiry Date: ${prescription.EndDate}</p>
        <p>Direction of Use: ${prescription.Instruction}</p>
      `;

        // Sanitize and insert content
        const sanitizedContent = DOMPurify.sanitize(prescriptionContent);
        this.quillEditor.clipboard.dangerouslyPasteHTML(
          startIndex,
          sanitizedContent
        );
        this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
      }

      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        // Check for the keyword "MostRecentPrescriptions"
        if (currentText.includes('RxActive')) {
          const startIndex = currentText.indexOf('RxActive');
          const endIndex = startIndex + 'RxActive'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'RxActive'.length);

          // Find the most recent prescriptions
          const mostRecentDate = new Date(
            Math.max(
              ...this.templatesPrescriptions.map((p) =>
                new Date(p.CreatedDate).getTime()
              )
            )
          );
          const mostRecentPrescriptions = this.templatesPrescriptions.filter(
            (p) =>
              new Date(p.CreatedDate).toDateString() ===
              mostRecentDate.toDateString()
          );

          if (mostRecentPrescriptions.length > 0) {
            const prescriptionsContent = mostRecentPrescriptions
              .map(
                (prescription) => `
                <p><strong>Prescription Recorded: ${new Date(
                  prescription.CreatedDate
                ).toLocaleString()}</strong></p>
                <p>Drug Name: ${prescription.DrugName}</p>
                <p>Amount Per Dose: ${prescription.AmountPerDose} ${
                  prescription.Unit
                }</p>
                <p>Route: ${prescription.Route}</p>
                <p>Frequency: ${prescription.Frequency}</p>
                <p>Refill Duration: ${prescription.RefillDuration}</p>
                <p>Number of Refills: ${prescription.Numberofrefills}</p>
                <p>Quantity: ${prescription.Quantity} ${prescription.Unit}</p>
                <p>Start Date: ${prescription.StartDate}</p>
                <p>Expiry Date: ${prescription.EndDate}</p>
                <p>Direction of Use: ${prescription.Instruction}</p>
                <p>____________________________________________________________</p>
              `
              )
              .join('');

            const sanitizedContent = DOMPurify.sanitize(prescriptionsContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        // Check for the keyword "recent5prescriptions"
        if (currentText.includes('RxLast-5')) {
          const startIndex = currentText.indexOf('RxLast-5');
          const endIndex = startIndex + 'RxLast-5'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'RxLast-5'.length);

          // Sort prescriptions by created date and take the most recent 5
          const recent5Prescriptions = this.templatesPrescriptions
            .sort(
              (a, b) =>
                new Date(b.CreatedDate).getTime() -
                new Date(a.CreatedDate).getTime()
            )
            .slice(0, 5);

          if (recent5Prescriptions.length > 0) {
            const prescriptionsContent = recent5Prescriptions
              .map(
                (prescription) => `
                <p><strong>Prescription Recorded: ${new Date(
                  prescription.CreatedDate
                ).toLocaleString()}</strong></p>
                <p>Drug Name: ${prescription.DrugName}</p>
                <p>Amount Per Dose: ${prescription.AmountPerDose} ${
                  prescription.Unit
                }</p>
                <p>Route: ${prescription.Route}</p>
                <p>Frequency: ${prescription.Frequency}</p>
                <p>Refill Duration: ${prescription.RefillDuration}</p>
                <p>Number of Refills: ${prescription.Numberofrefills}</p>
                <p>Quantity: ${prescription.Quantity} ${prescription.Unit}</p>
                <p>Start Date: ${prescription.StartDate}</p>
                <p>Expiry Date: ${prescription.EndDate}</p>
                <p>Direction of Use: ${prescription.Instruction}</p>
                <p>____________________________________________________________</p>
              `
              )
              .join('');

            const sanitizedContent = DOMPurify.sanitize(prescriptionsContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        // Check for the keyword "last10DaysPrescriptions"
        if (currentText.includes('RxLast-10')) {
          const startIndex = currentText.indexOf('RxLast-10');
          const endIndex = startIndex + 'RxLast-10'.length;

          // Remove the keyword from the editor
          this.quillEditor.deleteText(startIndex, 'RxLast-10'.length);

          // Filter prescriptions from the last 10 days
          const last10DaysPrescriptions = this.templatesPrescriptions.filter(
            (prescription) =>
              new Date(prescription.CreatedDate) >=
              new Date(new Date().setDate(new Date().getDate() - 10))
          );

          if (last10DaysPrescriptions.length > 0) {
            const prescriptionsContent = last10DaysPrescriptions
              .map(
                (prescription) => `
                <p><strong>Prescription Recorded: ${new Date(
                  prescription.CreatedDate
                ).toLocaleString()}</strong></p>
                <p>Drug Name: ${prescription.DrugName}</p>
                <p>Amount Per Dose: ${prescription.AmountPerDose} ${
                  prescription.Unit
                }</p>
                <p>Route: ${prescription.Route}</p>
                <p>Frequency: ${prescription.Frequency}</p>
                <p>Refill Duration: ${prescription.RefillDuration}</p>
                <p>Number of Refills: ${prescription.Numberofrefills}</p>
                <p>Quantity: ${prescription.Quantity} ${prescription.Unit}</p>
                <p>Start Date: ${prescription.StartDate}</p>
                <p>Expiry Date: ${prescription.EndDate}</p>
                <p>Direction of Use: ${prescription.Instruction}</p>
                <p>____________________________________________________________</p>
              `
              )
              .join('');

            const sanitizedContent = DOMPurify.sanitize(prescriptionsContent);

            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });

    // this.templatesRadiologyStudyReports.forEach((DrReport) => {
    //   this.quillEditor.on('text-change', () => {
    //     const currentText = this.quillEditor.getText();

    //     if (currentText.includes('MostRecentStudyReports')) {
    //       const startIndex = currentText.indexOf('MostRecentStudyReports');
    //       const endIndex = startIndex + 'MostRecentStudyReports'.length;

    //       this.quillEditor.deleteText(
    //         startIndex,
    //         'MostRecentStudyReports'.length
    //       );

    //       const mostRecentData = this.templatesRadiologyStudyReports.sort(
    //         (a, b) =>
    //           new Date(b.createdDate).getTime() -
    //           new Date(a.createdDate).getTime()
    //       )[0];

    //       if (mostRecentData) {
    //         let reportContent = `<p><strong>Most Recent Study Report (Recorded: ${new Date(
    //           mostRecentData.createdDate
    //         ).toLocaleString()}):</strong></p>`;

    //         if (
    //           (mostRecentData.StudyReport &&
    //             mostRecentData.StudyReport.endsWith('.jpg')) ||
    //           mostRecentData.StudyReport.endsWith('.jpeg') ||
    //           mostRecentData.StudyReport.endsWith('.pdf') ||
    //           mostRecentData.StudyReport.endsWith('.png')
    //         ) {
    //           reportContent += `<p><img src="${mostRecentData.StudyReport}" alt="Study Report Image" style="max-width: 100%; height: auto;" /></p>`;
    //         } else if (
    //           mostRecentData.StudyReport &&
    //           mostRecentData.StudyReport.endsWith('.pdf')
    //         ) {
    //           reportContent += `<p><a href="${mostRecentData.StudyReport}" target="_blank">Download PDF Report</a></p>`;
    //         } else {
    //           reportContent += `<p>Study Report: <a href="${mostRecentData.StudyReport}" target="_blank">${mostRecentData.StudyReport}</a></p>`;
    //         }

    //         const sanitizedContent = DOMPurify.sanitize(reportContent);

    //         this.quillEditor.clipboard.dangerouslyPasteHTML(
    //           startIndex,
    //           sanitizedContent
    //         );

    //         this.quillEditor.setSelection(
    //           startIndex + sanitizedContent.length,
    //           0
    //         );
    //       }
    //     }
    //   });
    // });
    // this.templatesLabReportsUploaded.forEach((DrReport) => {
    //   this.quillEditor.on('text-change', () => {
    //     const currentText = this.quillEditor.getText();

    //     // Check for the keyword "MostRecentStudyReports"
    //     if (currentText.includes('MostRecentLabReportsUploaded')) {
    //       // Find the position of the keyword
    //       const startIndex = currentText.indexOf(
    //         'MostRecentLabReportsUploaded'
    //       );
    //       const endIndex = startIndex + 'MostRecentLabReportsUploaded'.length;

    //       // Remove the keyword from the editor
    //       this.quillEditor.deleteText(
    //         startIndex,
    //         'MostRecentLabReportsUploaded'.length
    //       );

    //       // Fetch or construct the most recent study report
    //       const mostRecentData = this.templatesLabReportsUploaded.sort(
    //         (a, b) =>
    //           new Date(b.createdDate).getTime() -
    //           new Date(a.createdDate).getTime()
    //       )[0];

    //       if (mostRecentData) {
    //         // Create an HTML structure based on the type of the study report (image or PDF)
    //         let reportContent = `<p><strong>Most Recent Lab Report (Recorded: ${new Date(
    //           mostRecentData.createdDate
    //         ).toLocaleString()}):</strong></p>`;

    //         // Check if the report is an image or a PDF and create the appropriate HTML tag
    //         if (
    //           (mostRecentData.LabReportImage &&
    //             mostRecentData.LabReportImage.endsWith('.jpg')) ||
    //           mostRecentData.LabReportImage.endsWith('.jpeg') ||
    //           mostRecentData.LabReportImage.endsWith('.pdf') ||
    //           mostRecentData.LabReportImage.endsWith('.png')
    //         ) {
    //           // If the study report is an image
    //           reportContent += `<p><img src="${mostRecentData.LabReportImage}" alt="Lab Report Image" style="max-width: 100%; height: auto;" /></p>`;
    //         } else if (
    //           mostRecentData.LabReportImage &&
    //           mostRecentData.LabReportImage.endsWith('.pdf')
    //         ) {
    //           // If the study report is a PDF
    //           reportContent += `<p><a href="${mostRecentData.LabReportImage}" target="_blank">Download PDF Report</a></p>`;
    //         } else {
    //           // If the report is neither an image nor a PDF, just display the URL as text
    //           reportContent += `<p>Lab Report: <a href="${mostRecentData.LabReportImage}" target="_blank">${mostRecentData.LabReportImage}</a></p>`;
    //         }

    //         // Sanitize the content to prevent XSS attacks
    //         const sanitizedContent = DOMPurify.sanitize(reportContent);

    //         // Insert the sanitized content into the editor
    //         this.quillEditor.clipboard.dangerouslyPasteHTML(
    //           startIndex,
    //           sanitizedContent
    //         );

    //         // Set the cursor position after the inserted content
    //         this.quillEditor.setSelection(
    //           startIndex + sanitizedContent.length,
    //           0
    //         );
    //       }
    //     }
    //   });
    // });
    // Replace flatMap with map + flat (or equivalent)
    this.templatesLabReports.forEach((labReport) => {
      this.quillEditor.on('text-change', () => {
        const currentText = this.quillEditor.getText();

        const allLabTests = this.templatesLabReports
          .map((report) => report.LabTests)
          .reduce((acc, val) => acc.concat(val), []);

        if (currentText.includes('LatestLabReports')) {
          const startIndex = currentText.indexOf('LatestLabReports');
          this.quillEditor.deleteText(startIndex, 'LatestLabReports'.length);

          const mostRecentData = this.templatesLabReports.sort(
            (a, b) =>
              new Date(b.createdDate).getTime() -
              new Date(a.createdDate).getTime()
          )[0];

          if (mostRecentData) {
            let reportContent = `
              <p><strong>Most Recent Lab Report (Recorded: ${new Date(
                mostRecentData.createdDate
              ).toLocaleString()}):</strong></p>
            `;
            mostRecentData.LabTests.forEach((test) => {
              reportContent += `
                <p>Test Name: ${test.TestName}</p>
                <p>Result: ${test.TestResultValue} ${test.Units}</p>
                <p>Reference Range: ${test.ReferanceRange}</p>
              `;
            });

            const sanitizedContent = DOMPurify.sanitize(reportContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        if (currentText.includes('BSugarlast-5')) {
          const startIndex = currentText.indexOf('BSugarlast-5');
          this.quillEditor.deleteText(startIndex, 'BSugarlast-5'.length);

          const recentBloodSugarTests = allLabTests
            .filter((test) => test.TestName.toLowerCase() === 'blood sugar')
            .sort(
              (a, b) =>
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime()
            )
            .slice(0, 5);

          if (recentBloodSugarTests.length > 0) {
            let reportContent = `
              <p><strong>Most Recent 5 Blood Sugar Readings:</strong></p>
            `;
            recentBloodSugarTests.forEach((test) => {
              reportContent += `
                <p>Date: ${new Date(test.createdDate).toLocaleString()}</p>
                <p>Result: ${test.TestResultValue} ${test.Units}</p>
                <p>Reference Range: ${test.ReferanceRange}</p>
              `;
            });

            const sanitizedContent = DOMPurify.sanitize(reportContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        if (currentText.includes('LatestCholesterol-5')) {
          const startIndex = currentText.indexOf('LatestCholesterol-5');
          this.quillEditor.deleteText(startIndex, 'LatestCholesterol-5'.length);

          const recentCholesterolTests = allLabTests
            .filter((test) => test.TestName.toLowerCase() === 'cholesterol')
            .sort(
              (a, b) =>
                new Date(b.createdDate).getTime() -
                new Date(a.createdDate).getTime()
            )
            .slice(0, 5);

          if (recentCholesterolTests.length > 0) {
            let reportContent = `
              <p><strong>Most Recent 5 Cholesterol Readings:</strong></p>
            `;
            recentCholesterolTests.forEach((test) => {
              reportContent += `
                <p>Date: ${new Date(test.createdDate).toLocaleString()}</p>
                <p>Result: ${test.TestResultValue} ${test.Units}</p>
                <p>Reference Range: ${test.ReferanceRange}</p>
              `;
            });

            const sanitizedContent = DOMPurify.sanitize(reportContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        if (currentText.includes('BSugarlast10d')) {
          const startIndex = currentText.indexOf('BSugarlast10d');
          this.quillEditor.deleteText(startIndex, 'BSugarlast10d'.length);

          const last10DaysSugar = allLabTests.filter(
            (test) =>
              test.TestName.toLowerCase() === 'blood sugar' &&
              new Date(test.createdDate) >=
                new Date(new Date().setDate(new Date().getDate() - 10))
          );

          if (last10DaysSugar.length > 0) {
            let reportContent = `
              <p><strong>Blood Sugar Trend (Last 10 Days):</strong></p>
            `;
            last10DaysSugar.forEach((test) => {
              reportContent += `
                <p>Date: ${new Date(test.createdDate).toLocaleString()}</p>
                <p>Result: ${test.TestResultValue} ${test.Units}</p>
                <p>Reference Range: ${test.ReferanceRange}</p>
              `;
            });

            const sanitizedContent = DOMPurify.sanitize(reportContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }

        if (currentText.includes('LatestCholesterol-10d')) {
          const startIndex = currentText.indexOf('LatestCholesterol-10d');
          this.quillEditor.deleteText(
            startIndex,
            'LatestCholesterol-10d'.length
          );

          const last10DaysCholesterol = allLabTests.filter(
            (test) =>
              test.TestName.toLowerCase() === 'cholesterol' &&
              new Date(test.createdDate) >=
                new Date(new Date().setDate(new Date().getDate() - 10))
          );

          if (last10DaysCholesterol.length > 0) {
            let reportContent = `
              <p><strong>Cholesterol Trend (Last 10 Days):</strong></p>
            `;
            last10DaysCholesterol.forEach((test) => {
              reportContent += `
                <p>Date: ${new Date(test.createdDate).toLocaleString()}</p>
                <p>Result: ${test.TestResultValue} ${test.Units}</p>
                <p>Reference Range: ${test.ReferanceRange}</p>
              `;
            });

            const sanitizedContent = DOMPurify.sanitize(reportContent);
            this.quillEditor.clipboard.dangerouslyPasteHTML(
              startIndex,
              sanitizedContent
            );
            this.quillEditor.setSelection(
              startIndex + sanitizedContent.length,
              0
            );
          }
        }
      });
    });
  }

  // This function checks the current text in the editor and suggests CPT codes
  // getCptCodeSuggestions(text: string): any[] {
  //   const suggestions = [];

  //   this.cptCodeList.forEach((cpt) => {
  //     if (cpt.description.toLowerCase().includes(text)) {
  //       suggestions.push(cpt); // Add matching CPT code to suggestions
  //     }
  //   });

  //   return suggestions;
  // }

  // onSelectCptCode(cpt) {
  //   this.selectedCptCode = cpt;
  //   this.insertCptCodeIntoEditor(cpt);
  //   this.suggestions = []; // Clear suggestions after selection
  // }
  // Inserts the selected CPT code into the Quill editor at the cursor position
  // insertCptCodeIntoEditor(cpt: any): void {
  //   if (!this.quillEditor) {
  //     return;
  //   }

  //   this.quillEditor.focus();

  //   const currentSelection = this.quillEditor.getSelection();

  //   if (!currentSelection) {
  //     this.quillEditor.insertText(0, `${cpt.code}: ${cpt.description}`); // Insert at the beginning
  //     return;
  //   }

  //   const currentPosition = currentSelection.index;

  //   const cptText = `${cpt.code}: ${cpt.description}`;
  //   this.quillEditor.insertText(currentPosition, cptText);

  //   this.quillEditor.setSelection(currentPosition + cptText.length, 0); // Move the cursor after inserted text
  // }

  // This method is triggered when a template is selected
  // selectTemplate(templateContent: string): void {
  //   const cursorPosition = this.quillEditor.getSelection()?.index;

  //   if (cursorPosition !== null) {
  //     this.quillEditor.insertText(cursorPosition, templateContent);

  //     this.onTextInput(); // Manually trigger suggestions check
  //   }
  // }
  formSubmit() {
    let Shortcut = this.Frm.value['shortcut'];
    let ReportName = this.Frm.value['ReportName'];
    let reportDescription = this.Frm.value['ReportDescription'];

    let body = {
      PatientId: this.id,
      DoctorId: this.UserId,
      ReportName: ReportName,
      shortcut: Shortcut,
      ReportDescription: this.quillEditor.root.innerHTML,
      DoctorName: this.doctor.DoctorName,
      Status: true,
    };
    this.doctorReportService.registerDoctorReport(body).subscribe((res) => {
      this.isSuccess = true;
      this.Frm.reset();
      this.quillEditor = '';
    });
  }

  private _filter5(value: string): string[] {
    const ds = value.toLowerCase();

    return this.reportNames.filter((aa) => aa.toLowerCase().includes(ds));
  }
  private _filter6(value: string): string[] {
    const ss = value.toLowerCase();

    return this.shortcutNames.filter((dd) => dd.toLowerCase().includes(ss));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.doctorReportService.deleteDoctorReport(id).subscribe((res) => {
      this.dataSource(this.id);
    });
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.DOWN_ARROW) {
      // Your row selection code
      // console.log(event);
      this.stopRecognition();
    }
  }
  pasteIntoQuill(quillEditor: any): void {
    navigator.clipboard
      .readText()
      .then((clipboardData) => {
        quillEditor.clipboard.dangerouslyPasteHTML(clipboardData);
      })
      .catch((err) => {
        console.error('Failed to read clipboard data: ', err);
      });
  }
  newInvoice(): void {
    const patientId = Number(this.id); // Ensure `patientId` is a number
    const dialogRef = this.dialog.open(AddInvoiceOnProgressNoteComponent, {
      width: '50%',
      height: '80%',
      disableClose: false, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container',
      data: { patientId }, // Pass data as number
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
    });
  }

  openFavoriteDiagnosisPopup() {
    const dialogRef = this.dialog.open(FavoriteDiagnosisPopupComponent, {
      width: '400px',
      data: { favoriteDiagnosisCodes: this.favoriteDiagnosisCodes }, // Pass your favorite codes here
    });

    dialogRef.afterClosed().subscribe((selectedCodes) => {
      if (selectedCodes && selectedCodes.length > 0) {
        this.insertCodesIntoEditor(selectedCodes); // Handle multiple selected codes
      }
    });
  }

  insertCodesIntoEditor(codes: any[]) {
    // Format selected codes: Each code on a new line with a description
    const formattedCodes = codes.map((code) => `${code.Code} `).join('\n');

    // Ensure the editor is focused
    this.quillEditor.focus();

    // Get the current cursor position or default to the end of the text
    const selection = this.quillEditor.getSelection();
    const cursorPosition =
      selection?.index !== null && selection?.index !== undefined
        ? selection.index
        : this.quillEditor.getLength(); // Fallback to the end of the editor content

    // Insert the formatted text at the cursor position
    this.quillEditor.insertText(cursorPosition, formattedCodes);

    // Move the cursor to the end of the inserted text
    this.quillEditor.setSelection(cursorPosition + formattedCodes.length);
  }

  generate() {
    // Get the current content from the Quill editor
    const query = this.quillEditor.getText().trim();
    if (query) {
      this.assistanceService.generateDocumentation(query).subscribe(
        (res) => {
          this.insertTextAtCursor(res.result);
        },
        (err) => {
          console.error('Error:', err);
        }
      );
    }
  }

  insertTextAtCursor(text: string) {
    const range = this.quillEditor.getSelection();
    if (range) {
      this.quillEditor.insertText(range.index, text);
      this.quillEditor.setSelection(range.index + text.length); // Move cursor after the inserted text
    } else {
      this.quillEditor.insertText(this.quillEditor.getLength(), text); // Append at the end if no selection
    }
  }
}

export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}
