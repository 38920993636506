import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CptCode } from 'src/app/Models/cptcode.model';
import { CptcodeService } from 'src/app/services/cptcode.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-nurse-patient-visit',
  templateUrl: './update-nurse-patient-visit.component.html',
  styleUrls: ['./update-nurse-patient-visit.component.css'],
})
export class UpdateNursePatientVisitComponent implements OnInit {
  isSuccess = false;
  selectedCPTCodes: string[] = [];
  cptCodeList: CptCode[] = [];
  filteredValues: CptCode[];
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  id = 0;
  NurseId: number;
  CurrentNurseId: number;
  addVisitFrm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private patientVisitService: PatientvisitService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private plt: Platform,
    private cptCodeService: CptcodeService,
    private cd: ChangeDetectorRef,
    private http: HttpClient
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.addVisitFrm = this.fb.group({
      DoctorName: [''],
      NurseName: [''],
      DoctorSpecialization: [''],
      RoomNo: [''],
      AdmissionDate: [''],
      DischargeDate: [''],
      VisitType: [''],
      VistitDate: [''],
      VistTime: [''],

      PatientNote: [''],
      BloodPressure: [''],
      Pulse: [''],
      CptCode: [''],
      Objective: [''],
      Subjective: [''],
      Assessment: [''],
    });

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.patientVisitService.getPatientVisitById(this.id).subscribe((res) => {
      this.Frm.patchValue(res);
    });
    this.getCptCodes();
    this.CptCode.valueChanges.subscribe((newValue) => {
      this.filteredValues = this.filterValues(newValue);
    });
  }
  filterValues(search: string): CptCode[] {
    const filterValue = search.toLowerCase();
    return this.cptCodeList.filter(function (opt) {
      if (opt.Code.includes(filterValue)) {
        return opt;
      }
    });
  }
  Frm = this.fb.group({
    PatientId: this.id,
    ChiefComplaint: ['', Validators.required],
    PresentIllness: ['', Validators.required],
    Medical: ['', Validators.required],
    Surgical: ['', Validators.required],
    Allergies: ['', Validators.required],
    Medications: ['', Validators.required],
    SocialHistory: ['', Validators.required],
    FamilyHistory: ['', Validators.required],
    ReviewOfSymptoms: ['', Validators.required],
    VitalSigns: ['', Validators.required],
    HENT: ['', Validators.required],
    Neck: ['', Validators.required],
    Chest: ['', Validators.required],
    Heart: ['', Validators.required],
    Abdomen: ['', Validators.required],
    Extermities: ['', Validators.required],
    Neurological: ['', Validators.required],
    Radiology: ['', Validators.required],
    Impression: ['', Validators.required],
    Recomendation: ['', Validators.required],
    DoctorName: ['', Validators.required],
    Signature: ['', Validators.required],
    Date: ['', Validators.required],
    CptCode: [''],
  });

  get Date() {
    return this.Frm.get('Date');
  }
  get Signature() {
    return this.Frm.get('Signature');
  }
  get DoctorName() {
    return this.Frm.get('DoctorName');
  }
  get NurseName() {
    return this.Frm.get('NurseName');
  }
  get Recomendation() {
    return this.Frm.get('Recomendation');
  }
  get Impression() {
    return this.Frm.get('Impression');
  }
  get Radiology() {
    return this.Frm.get('Radiology');
  }
  get Neurological() {
    return this.Frm.get('Neurological');
  }
  get Extermities() {
    return this.Frm.get('Extermities');
  }
  get Abdomen() {
    return this.Frm.get('Abdomen');
  }
  get Heart() {
    return this.Frm.get('Heart');
  }
  get Chest() {
    return this.Frm.get('Chest');
  }

  get Neck() {
    return this.Frm.get('Neck');
  }
  get HENT() {
    return this.Frm.get('HENT');
  }
  get VitalSigns() {
    return this.Frm.get('VitalSigns');
  }
  get ReviewOfSymptoms() {
    return this.Frm.get('ReviewOfSymptoms');
  }
  get FamilyHistory() {
    return this.Frm.get('FamilyHistory');
  }
  get SocialHistory() {
    return this.Frm.get('SocialHistory');
  }
  get Medications() {
    return this.Frm.get('Medications');
  }
  get Allergies() {
    return this.Frm.get('Allergies');
  }
  get Surgical() {
    return this.Frm.get('Surgical');
  }
  get Medical() {
    return this.Frm.get('Medical');
  }

  get ChiefComplaint() {
    return this.Frm.get('ChiefComplaint');
  }
  get PresentIllness() {
    return this.Frm.get('PresentIllness');
  }
  get CptCode() {
    return this.Frm.get('CptCode');
  }
  addOption(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    // console.log('value: ', value);

    if (!this.selectedCPTCodes.includes(value)) {
      this.selectedCPTCodes.push(value);
      this.CptCode.setValue(this.selectedCPTCodes.join(','));
    }
  }
  removeOption(option) {
    const index = this.selectedCPTCodes.indexOf(option);

    if (index >= 0) {
      this.selectedCPTCodes.splice(index, 1);
    }
  }
  getCptCodes() {
    this.cptCodeService.getCptCodes().subscribe((res) => {
      this.cptCodeList = res;
    });
  }
  formSubmit() {
    this.patientVisitService
      .updatePatientVisit(this.id, this.Frm.value)
      .subscribe((res) => {
        this.router.navigate(['/nurseDash/']);
      });
  }
}
