<div class="container ">
    <div class="ml-3">
        <div class="row">
            <div class="col-lg float-right">
                <mat-form-field class="example-full-width mt-5">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class=" mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>




            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let row" (click)="onTableRowClick(row)"> {{row.Name}} </td>
            </ng-container>




            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[2, 5,10, 25, 100]"></mat-paginator>
    </div>








    <div class="row mt-4">

        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Progress Notes</h1>
            <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Report Name</mat-label>
                    <!-- <input type="text" formControlName="ReportName" matInput> -->
                    <input type="text" matInput formControlName="ReportName" [matAutocomplete]="auto5"> <mat-icon
                        matSuffix>search</mat-icon>
                    <mat-autocomplete #auto5="matAutocomplete">
                        <mat-option *ngFor="let dos of filteredReportName | async" [value]="dos">
                            {{ dos }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error>Please enter Report Name</mat-error>
                </mat-form-field>

                <!-- Quill Editor Container -->

                <div #editorContainer id="editor" class="editor-container"></div>

                <!-- CPT Code Suggestions Dropdown -->
                <!-- <div *ngIf="suggestions.length > 0" class="cpt-suggestions">
                    <ul>
                        <li *ngFor="let ss of suggestions" (click)="onSelectCptCode(ss)">
                            {{ ss.code }}: {{ ss.description }}
                        </li>
                    </ul>
                </div> -->
                <button type="button" mat-raised-button color="primary" (click)="startVoiceRecognition()">Start Voice
                    Recognition</button>
                <button type="button" class="mx-5" type="button" color="accent" (click)="stopRecognition()"
                    mat-raised-button>Stop</button>


                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Sign & Save</button>

            </form>

        </div>
    </div>