<div class="container">
    <h2>Roster Management</h2>

    <!-- View and Select a Roster -->
    <h3>View and Update Roster</h3>
    <table>
        <thead>
            <tr>
                <th>Ward</th>
                <th>Week Commencing</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let roster of rosters">
                <td>{{ roster.WardName }}</td>
                <td>{{ roster.WeekCommencing | date }}</td>
                <td>
                    <button (click)="selectRoster(roster.RosterId)">View</button>
                </td>
            </tr>
        </tbody>
    </table>

    <!-- Duty Management for Selected Roster -->
    <div *ngIf="selectedRoster">
        <h3>Duty Records for Roster (Week Commencing: {{ selectedRoster.WeekCommencing | date }})</h3>

        <table>
            <thead>
                <tr>
                    <th>Employee</th>
                    <th>Day</th>
                    <th>Duty Type</th>
                    <th>Duty Duration</th>
                    <th>Comments</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let duty of duties">
                    <td>{{ duty.EmployeeName }}</td>
                    <td>{{ duty.Day }}</td>
                    <td>{{ duty.DutyType }}</td>
                    <td>{{ duty.DutyDuration }}</td>
                    <td>{{ duty.Comments }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>