import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { Medicine } from '../Models/medicine.model';

@Injectable({
  providedIn: 'root',
})
export class MedicineService {
  url = environment.Url2 + 'Medicines';
  // url = environment.Url + 'Medicines';
  drugNameurl = environment.Url + 'Medicines/drugNameSearch';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  registerMedicine(body): Observable<Medicine> {
    return this.http
      .post<Medicine>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getMedicines(): Observable<Medicine[]> {
    return this.http
      .get<Medicine[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getMedicineById(id): Observable<Medicine> {
    return this.http.get<Medicine>(this.url + '/' + id);
  }
  getMedicineByDrugName(search): Observable<Medicine> {
    return this.http.get<Medicine>(this.drugNameurl + '/' + search);
  }

  updateMedicine(id, body): Observable<Medicine> {
    return this.http
      .put<Medicine>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deleteMedicine(id): Observable<Medicine> {
    return this.http
      .delete<Medicine>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  UploadJsonDrugData(data): Observable<Medicine[]> {
    return this.http
      .post<Medicine[]>(this.url + '/UploadJsonDrugData', data)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
