import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialhistoryService } from 'src/app/services/socialhistory.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-socialhistory',
  templateUrl: './add-socialhistory.component.html',
  styleUrls: ['./add-socialhistory.component.css']
})
export class AddSocialhistoryComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();
  parent: string;
  id=0;
  isSuccess= false;
  Frm: FormGroup;
  constructor(private fb:UntypedFormBuilder,private socialHistoryService:SocialhistoryService,private route:ActivatedRoute,private router:Router, private tokenService: TokenService) { }

  ngOnInit(): void {

    this.route.params.subscribe(res=>{

      this.id= res['id'];
    })

    
  this.Frm = this.fb.group({
    PatientId: this.id,
    DoYouDrinkAlcohol:[false],
    AlcoholDrinkingFrequency:[''],
    DoYouSmoke:[false],
    HowDoYouSmoke:[''],
    DoYouDrinkCaffeine:[false],
    CaffeineDrinkFrequency:[''],
    AreYouSexuallyActive:[false],
    DoYouWishToBeCheckedForSTDs:[false],
 
  })
  }





  get PatientId(){
    return this.Frm.get('PatientId')
  }

get DoYouDrinkAlcohol(){
  return this.Frm.get('DoYouDrinkAlcohol')
}
get AlcoholDrinkingFrequency(){
  return this.Frm.get('AlcoholDrinkingFrequency')
}
get DoYouSmoke(){
  return this.Frm.get('DoYouSmoke')
}
get HowDoYouSmoke(){
  return this.Frm.get('HowDoYouSmoke')
}
get DoYouDrinkCaffeine(){
  return this.Frm.get('DoYouDrinkCaffeine')
}
get CaffeineDrinkFrequency(){
  return this.Frm.get('CaffeineDrinkFrequency')
}
get AreYouSexuallyActive(){
  return this.Frm.get('AreYouSexuallyActive')
}
get DoYouWishToBeCheckedForSTDs(){
  return this.Frm.get('DoYouWishToBeCheckedForSTDs')
}





  formSubmit(){
this.socialHistoryService.registerSocialHistory(this.Frm.value).subscribe(res=>{
  this.isSuccess=true
  this.Frm.reset();
  this.submitAction.emit("")
})
  }
}

