<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>New Social History</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">

        <hr>
        <div class="row">

          <div class="col-md-12">
            <mat-label>Do You Drink Alcohol?</mat-label>
            <mat-radio-group formControlName="DoYouDrinkAlcohol">
              <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
              <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
            </mat-radio-group> <br>

            <mat-label>Alcohol Drinking Frequency </mat-label>
            <mat-radio-group formControlName="AlcoholDrinkingFrequency">
              <mat-radio-button class="ml-3" value="Daily">Daily</mat-radio-button>
              <mat-radio-button class="ml-3" value="Weekly">Weekly</mat-radio-button>
              <mat-radio-button class="ml-3" value="Infrequently">Infrequently</mat-radio-button>
              <mat-radio-button class="ml-3" value="RecoveringAcaholic">Recovering Acaholic</mat-radio-button>
            </mat-radio-group>
          </div>


        </div>
        <div class="row mt-3 mb-3">

          <div class="col-md-4">
            <mat-label> Do You Smoke?</mat-label>
            <mat-radio-group formControlName="DoYouSmoke">
              <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
              <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
            </mat-radio-group>

          </div>
          <div class="col-md-6">


            <mat-form-field>
              <mat-label>How much Do You Smoke?</mat-label>

              <input type="text" formControlName="HowDoYouSmoke" matInput placeholder="Smoke(--packet/day),Chew">
            </mat-form-field>

          </div>

        </div>
        <div class="row">

          <div class="col-md-12">
            <mat-label>Do You Drink Caffeine?</mat-label>
            <mat-radio-group formControlName="DoYouDrinkCaffeine">
              <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
              <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
            </mat-radio-group>

            <br>

            <mat-label>Caffeine Drinking Frequency </mat-label>
            <mat-radio-group aria-label="Select an option" formControlName="CaffeineDrinkFrequency">
              <mat-radio-button class="ml-3" value="Daily">Daily</mat-radio-button>
              <mat-radio-button class="ml-3" value="Weekly">Weekly</mat-radio-button>
              <mat-radio-button class="ml-3" value="Infrequently">Infrequently</mat-radio-button>
            </mat-radio-group>
          </div>



        </div>

        <div class="row">
          <div class="col-md-6">
            <mat-label> Are You Sexually Active?</mat-label>
            <mat-radio-group formControlName="AreYouSexuallyActive">
              <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
              <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
            </mat-radio-group>


          </div>
          <div class="col-md-6">
            <mat-label> Do You Wish To Be Checked For STD's?
            </mat-label>
            <mat-radio-group formControlName="DoYouWishToBeCheckedForSTDs">
              <mat-radio-button class="ml-3" value="true">Yes</mat-radio-button>
              <mat-radio-button class="ml-3" value="false">No</mat-radio-button>
            </mat-radio-group>


          </div>
        </div>
        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>

  </div>
</div>