<div class="ml-3">
  <div class="row">
    <div class="col-lg-6 float-right">
      <mat-form-field class="example-full-width mt-5">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
      </mat-form-field>
    </div>
  </div>
</div>
<div class=" mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>




    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'short'}} </td>
    </ng-container>
    <ng-container matColumnDef="shortcut">
      <th mat-header-cell *matHeaderCellDef> shortcut </th>
      <td mat-cell *matCellDef="let row"> {{row.shortcut}} </td>
    </ng-container>
    <ng-container matColumnDef="ReportName">
      <th mat-header-cell *matHeaderCellDef> Report </th>
      <td mat-cell *matCellDef="let row"> {{row.ReportName}} </td>
    </ng-container>
    <ng-container matColumnDef="Decription">
      <th mat-header-cell *matHeaderCellDef> Decription </th>
      <td mat-cell *matCellDef="let row" [innerHTML]="row.ReportDescription"> </td>
    </ng-container>
    <ng-container matColumnDef="NurseName">
      <th mat-header-cell *matHeaderCellDef> Nurse Name </th>
      <td mat-cell *matCellDef="let row"> {{row.NurseName}} </td>
    </ng-container>

    <ng-container matColumnDef="Signature">
      <th mat-header-cell *matHeaderCellDef> Signed </th>
      <td mat-cell *matCellDef="let row"> Yes </td>
    </ng-container>


    <ng-container matColumnDef="View">
      <th mat-header-cell *matHeaderCellDef> View </th>
      <td mat-cell *matCellDef="let row">

        <button class="btn btn-primary" (click)="modal3.show()" mat-mini-fab
          color="primary"><mat-icon>visibility</mat-icon></button>&nbsp;

        <app-modal id="printable" #modal3 class="panel-demo1" [maximizable]="true" [backdrop]="false">
          <ng-container class="app-modal-header">Patient Name: {{patient?.PatientFirstName}} &nbsp;
            {{patient?.PatientLastName}}</ng-container>
          <ng-container class="app-modal-body">
            <div class="card" #LabOrder>
              <div class="modal-body " id="printable">


                <p> {{row.ReportDescription}}</p>
                <p class="mt-5">Reported By: {{row.NurseName}}</p>
                <p>Date: {{row.createdDate | date:'short'}}</p>

              </div>
            </div>
            <div>
              <!-- <form (keydown.enter)="$event.preventDefault()"  action="">
                <mat-form-field class="w-30 ml-5 mr-3" appearance="outline">
                  <mat-label>Email to Lab</mat-label>
                  <input matInput type="text" [formControl]="email" placeholder="Enter email">
                </mat-form-field>
                <button mat-raised-button color="primary" class="mb-1" (click)="sendtoLab()">Send</button>
                <div class="send-fax-prescription d-flex align-items-baseline">
                  <mat-form-field class="w-30 ml-5 mr-3" appearance="outline">
                    <input matInput type="text" [formControl]="fax" placeholder="Enter Fax Number">
                  </mat-form-field>
                  <button mat-raised-button color="primary" class="mb-1" (click)="faxPresciption()">Send</button>
                </div>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                  Fax has been sent Successfully.
                  <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div *ngIf="errorMessage" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                  {{errorMessage}}
                  <button type="button" class="close " data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </form> -->
            </div>

          </ng-container>
          <ng-container class="app-modal-footer">
            <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

            <button class="dt-button dt-blue" style="float: right;" (click)="modal3.hide()">Close</button>

          </ng-container>
        </app-modal>

        <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg"> View
        </button> -->

        <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Exam / Report</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body " id="printable">


                <p> {{row.ReportDescription}}</p>
                <p class="mt-5">Reported By: {{row.NurseName}}</p>
                <p>Date: {{row.createdDate | date:'short'}}</p>

              </div>
              <div class="modal-footer">
                <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
              </div>
            </div>
          </div>
        </div>

      </td>
    </ng-container>
    <ng-container matColumnDef="Update">
      <th mat-header-cell *matHeaderCellDef> Update</th>
      <td mat-cell *matCellDef="let row">
        <button [routerLink]="['/updateNurseNote/',row.NurseNoteId]" color="primary"
          [disabled]="currentNurseId != row.NurseId" mat-mini-fab><mat-icon mat-list-icon>edit</mat-icon></button>
      </td>
    </ng-container>
    <!-- modal  -->



    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef> Delete</th>
      <td mat-cell *matCellDef="let row">
        <button [disabled]="currentNurseId != row.NurseId" (click)="delete(row.NurseNoteId)" mat-mini-fab
          color="accent"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>