<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
</div>
<button mat-raised-button color="primary" (click)="selectedPrecriptions()">Select Prescritions to copy</button>

<div *ngIf="groupedPrescriptions.length > 0; else noDataTemplate">
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="DatePrescribed">
                <th mat-header-cell *matHeaderCellDef> Date Prescribed </th>
                <td mat-cell *matCellDef="let rowGlobal">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{ rowGlobal | date: 'MM/dd/yyyy' }}
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <mat-panel-content>
                                <div class="d-flex justify-content-end m-2">
                                    <button type="button" (click)="PrintPrescription(rowGlobal)"
                                        class="btn btn-primary">
                                        Print Prescription
                                    </button>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Select</th>
                                            <th>DrugName</th>
                                            <th>Amt./dose</th>
                                            <th>Quantity</th>
                                            <th>Instruction</th>
                                            <th>Note</th>
                                            <th>Route</th>
                                            <th>Frequency</th>
                                            <th>Issued Date</th>
                                            <th>End</th>
                                            <th>Status</th>
                                            <th>Update</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of getPrescriptionOfOneDay(rowGlobal)">
                                            <td><mat-checkbox></mat-checkbox></td>
                                            <td>{{ row.DrugName }}</td>
                                            <td>{{ row.AmountPerDose }} {{ row.Unit }}</td>
                                            <td>{{ row.Quantity }} {{ row.Unit }}</td>
                                            <td>{{ row.Instruction }}</td>
                                            <td>{{ row.Note }}</td>
                                            <td>{{ row.Route }}</td>
                                            <td>{{ row.Frequency }}</td>
                                            <td>{{ row.StartDate | date: 'MM/dd/yyyy' }}</td>
                                            <td>{{ row.EndDate | date: 'MM/dd/yyyy' }}</td>
                                            <td>{{ row.Status ? 'Current' : 'Stopped' }}</td>
                                            <td>
                                                <button type="button"
                                                    (click)="openAddAppointmentModal(row.PrescriptionId)" mat-mini-fab
                                                    color="primary">
                                                    <mat-icon>edit</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </mat-panel-content>
                        </mat-expansion-panel>
                    </mat-accordion>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="['DatePrescribed']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['DatePrescribed'];"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
</div>

<ng-template #noDataTemplate>
    <p>No prescriptions to display.</p>
</ng-template>