import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
import { Doctor } from 'src/app/Models/doctor.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicineService } from 'src/app/services/medicine.service';
import { PatientPrescriptionService } from 'src/app/services/patient-prescription.service';
import { ChangeDetectionStrategy } from '@angular/core';
import { TokenService } from 'src/app/services/token.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-add-patient-prescription',
  templateUrl: './add-patient-prescription.component.html',
  styleUrls: ['./add-patient-prescription.component.css'],
})
export class AddPatientPrescriptionComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  states;
  isSuccess = false;
  doctor: Doctor;
  QTimes: string[] = [
    'Q1-Every Hour',
    'Q2-Every 2 hours',
    'Q3-Every 3 hours',
    'Q4-Every 4 hours',
    'Q5-Every 5 hours',
    'Q4-Every 6 hours',
    'Q4-Every 6 hours',
    'Q8-Every 8 hours',
    'Q12-Every 12 hours',
  ];

  instructionList: string[] = [
    'Take 1 tablet in the Morning',
    'Take 2 tablet in the Morning',
    'Take 3 tablet in the Morning',
    'Take 1 tablet in the Afternoon',
    'Take 2 tablet in the Afternoon',
    'Take 3 tablet in the Afternoon',
    'Take 1 tablet in the Evening',
    'Take 2 tablet in the Evening',
    'Take 3 tablet in the Evening',
    'Take 1 tablet at bedtime',
    'Take 2 tablet at bedtime',
    'Take 3 tablet at bedtime',
    'Take 1 tablet in the Morning and 1 tablet at bedtime',
    'Take 2 tablet in the Morning and 2 tablet at bedtime',
    'Take 3 tablet in the Morning and 3 tablet at bedtime',
    'Take 1 tablet in the Morning ,ake 1 tablet in the Afternoon and Take 1 tablet in the Evening ',
    'Take 2 tablet in the Morning ,ake 2 tablet in the Afternoon and Take 2 tablet in the Evening ',
    'Take 3 tablet in the Morning ,ake 3 tablet in the Afternoon and Take 3 tablet in the Evening ',
    'Take 1 tablet in the Morning ,ake 1 tablet in the Afternoon and Take 1 tablet in the bedtime ',
    'Take 2 tablet in the Morning ,ake 2 tablet in the Afternoon and Take 2 tablet in the bedtime ',
    'Take 3 tablet in the Morning ,ake 3 tablet in the Afternoon and Take 3 tablet in the bedtime ',
    'Take 1 tablet b.i.d as needed',
    'Take 2 tablet b.i.d as needed',
    'Take 3 tablet b.i.d as needed',
    'Take 1 tablet t.i.d as needed',
    'Take 2 tablet t.i.d as needed',
    'Take 3 tablet t.i.d as needed',
    'Take 1 tablet every hour',
    'Take 1 tablet every 2 hours',
    'Take 1 tablet every 4 hours',
    'Take 1 tablet every 6 hours',
    'Take 1 tablet every 8 hours',
    'Take 1 tablet every 12 hours',
  ];

  prescriptionFrm: FormGroup;
  medicineList: Medicine[];
  hospitalId;
  constructor(
    private fb: UntypedFormBuilder,
    private patientPrescriptionService: PatientPrescriptionService,
    private router: Router,
    private route: ActivatedRoute,
    private medicineService: MedicineService,
    private doctorService: DoctorService,
    private toastr: ToastrService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);
    this.doctorService.getDoctorHospital(this.doctorId).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
    });
    this.getMedicine();
    this.prescriptionFrm = this.fb.group({
      PatientId: this.id,
      DoctorId: this.doctorId,
      DoctorName: [''],
      DoctorSignature: [''],
      IsSendToPharmacy: [false],
      InstructionToPharmacy: [''],
      PrescriptionDrugs: this.fb.array([]),
    });
    this.addItem();
  }

  public addItem(): void {
    const item = this.fb.group({
      DrugName: ['', Validators.required],
      Quantity: ['', Validators.required],
      Instruction: ['', Validators.required],
      MorningTime: [''],
      AfternoonTime: [''],
      EveningTime: [''],
      NightTime: [''],
      QTime: [''],
      Status: [null, Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      RefillDuration: ['', Validators.required],
      Numberofrefills: ['', Validators.required],

      // DoctorSignature: [''],
    });
    this.PrescriptionDrugs.push(item);
  }

  public removeItem(i: number): void {
    this.PrescriptionDrugs.removeAt(i);
  }

  get PrescriptionDrugs(): UntypedFormArray {
    return this.prescriptionFrm.get('PrescriptionDrugs') as UntypedFormArray;
  }

  get Numberofrefills() {
    return this.prescriptionFrm.get('Numberofrefills');
  }
  get HospitalId() {
    return this.prescriptionFrm.get('HospitalId');
  }

  get InstructionToPharmacy() {
    return this.prescriptionFrm.get('InstructionToPharmacy');
  }
  get IsSendToPharmacy() {
    return this.prescriptionFrm.get('IsSendToPharmacy');
  }

  get RefillDuration() {
    return this.prescriptionFrm.get('RefillDuration');
  }

  get DoctorName() {
    return this.prescriptionFrm.get('DoctorName');
  }

  get StartDate() {
    return this.prescriptionFrm.get('StartDate');
  }

  get EndDate() {
    return this.prescriptionFrm.get('EndDate');
  }

  get Status() {
    return this.prescriptionFrm.get('Status');
  }
  get MorningTime() {
    return this.prescriptionFrm.get('MorningTime');
  }
  get AfternoonTime() {
    return this.prescriptionFrm.get('AfternoonTime');
  }
  get EveningTime() {
    return this.prescriptionFrm.get('EveningTime');
  }
  get NightTime() {
    return this.prescriptionFrm.get('NightTime');
  }
  get QTime() {
    return this.prescriptionFrm.get('QTime');
  }
  get DoctorId() {
    return this.prescriptionFrm.get('DoctorId');
  }
  get DoctorSignature() {
    return this.prescriptionFrm.get('DoctorSignature');
  }
  get Quantity() {
    return this.prescriptionFrm.get('Quantity');
  }
  get DrugName() {
    return this.prescriptionFrm.get('DrugName');
  }
  get Dosage() {
    return this.prescriptionFrm.get('Strength');
  }

  get BrandName() {
    return this.prescriptionFrm.get('BrandName');
  }
  get DatePrescribed() {
    return this.prescriptionFrm.get('DatePrescribed');
  }

  get Instruction() {
    return this.prescriptionFrm.get('Instruction');
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
      this.DoctorName.setValue(res.DoctorName);
    });
  }

  getMedicine() {
    this.medicineService.getMedicines().subscribe((res) => {
      this.medicineList = res;
    });
  }

  formSubmit() {
    let body = {
      ...this.prescriptionFrm.value,
      HospitalId: this.hospitalId,
      // DoctorSignature: this.signatureImg,
    };
    this.patientPrescriptionService
      .registerPatientPrescription(body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.prescriptionFrm.reset();
      });
  }

  // @ViewChild('signPad', { static: false })
  // signPad!: ElementRef<HTMLCanvasElement>;
  // @Output() signatureSaved = new EventEmitter();
  // private signatureImg?: string;
  // private sigPadElement: any;
  // private context: any;
  // private isDrawing!: boolean;

  // public ngAfterViewInit(): void {
  //   this.sigPadElement = this.signPad.nativeElement;
  //   this.context = this.sigPadElement.getContext('2d');
  //   this.context.strokeStyle = '#000';
  // }

  // onMouseDown(e: any): void {
  //   this.isDrawing = true;
  //   const coords = this.relativeCoords(e);
  //   this.context.moveTo(coords.x, coords.y);
  // }

  // @HostListener('document:mouseup', ['$event'])
  // onMouseUp(e: any): void {
  //   this.isDrawing = false;
  // }

  // onMouseMove(e: any): void {
  //   e.preventDefault();

  //   if (this.isDrawing) {
  //     const coords = this.relativeCoords(e);
  //     this.context.lineTo(coords.x, coords.y);
  //     this.context.stroke();
  //   }
  // }

  // clearSignature(e): void {
  //   e.preventDefault;

  //   this.signatureImg = undefined;
  //   this.context.clearRect(
  //     0,
  //     0,
  //     this.sigPadElement.width,
  //     this.sigPadElement.height
  //   );
  //   this.context.beginPath();
  // }

  // saveSignature(e): void {
  //   e.preventDefault;
  //   this.signatureImg = this.sigPadElement.toDataURL('image/png');

  //   this.signatureSaved.emit(this.signatureImg);
  // }

  // private relativeCoords(event: any): { x: number; y: number } {
  //   const bounds = event.target.getBoundingClientRect();
  //   const cords = {
  //     clientX: event.clientX || event.changedTouches[0].clientX,
  //     clientY: event.clientY || event.changedTouches[0].clientY,
  //   };
  //   const x = cords.clientX - bounds.left;
  //   const y = cords.clientY - bounds.top;
  //   return { x, y };
  // }
}
