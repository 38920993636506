import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

import { TokenService } from 'src/app/services/token.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css'],
})
export class SetpasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitted = false;
  successMessage = '';
  errorMessage = '';
  passwordFieldType: string = 'password';
  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      userName: ['', Validators.required],
      token: ['', Validators.required],
      password: ['', Validators.required],
    });
  }
  ngOnInit(): void {
    // Subscribe to queryParams to get the userName
    this.route.queryParams.subscribe((params) => {
      const userName = params['userName'];

      if (userName) {
        // Patch the value to the form control
        this.resetPasswordForm.patchValue({ userName });
      }
    });
  }
  togglePasswordVisibility(): void {
    this.passwordFieldType =
      this.passwordFieldType === 'password' ? 'text' : 'password';
  }
  onSubmit() {
    this.submitted = true;

    if (this.resetPasswordForm.invalid) {
      return;
    }

    const { userName, token, password } = this.resetPasswordForm.value;

    this.userService.ResetPassword({ userName, token, password }).subscribe({
      next: (response: any) => {
        this.successMessage =
          response.message || 'Password reset successfully.';
        this.errorMessage = '';
        this.router.navigate(['/']);
      },
      error: (err) => {
        this.errorMessage =
          err.error.message || 'An error occurred. Please try again.';
        this.successMessage = '';
      },
    });
  }
}
