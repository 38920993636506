import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { UploadLabReport } from 'src/app/Models/uploadlabreport.model';
import { PatientService } from 'src/app/services/patient.service';
import { UploadLabReportService } from 'src/app/services/upload-lab-report.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-upload-lab-report',
  templateUrl: './view-upload-lab-report.component.html',
  styleUrls: ['./view-upload-lab-report.component.css'],
})
export class ViewUploadLabReportComponent implements OnInit {
  displayedColumns: string[] = ['Date', 'View', 'Delete'];

  id = 0;
  uploadedRadiologyReport: UploadLabReport[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  patient: Patient;

  constructor(
    private patientService: PatientService,
    private uploadedLabReportservice: UploadLabReportService,
    private route: ActivatedRoute,
    private router: Router
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetLabReports(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  GetLabReports(id) {
    this.patientService.GetUploadedLabReport(id).subscribe((res) => {
      // this.LabReport = res.LabReports

      this.dataSource = new MatTableDataSource(
        res.UploadLabReports.sort(function (x, y) {
          return y.UploadLabReportId - x.UploadLabReportId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.uploadedLabReportservice.deleteUploadLabReport(id).subscribe((res) => {
      // this.LabReport = this.LabReport.filter(x=>x.LabReportId !=id)
      this.GetLabReports(this.id);
    });
  }
  view() {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  print() {
    window.print();
  }

  public viewLabReport(report: UploadLabReport): void {
    // console.log('report: ', report);
    this.labDocument = report.LabReportImage;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const radiologyImage = document.querySelector(
        '.rad-document img'
      ) as HTMLImageElement;
      if (radiologyImage) {
        radiologyImage.style.width = '100%';
      }
    }, 0);
  }
}
