import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BloodTransfusionConsentService } from 'src/app/services/blood-transfusion-consent.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-blood-transfusion-consent',
  templateUrl: './add-blood-transfusion-consent.component.html',
  styleUrls: ['./add-blood-transfusion-consent.component.css'],
})
export class AddBloodTransfusionConsentComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();

  parent: string;
  id = 0;
  isSuccess = false;
  Frm: FormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private bloodTransfusionConsentService: BloodTransfusionConsentService,
    private route: ActivatedRoute,
    private router: Router
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.Frm = this.fb.group({
      PatientId: this.id,
      ConsentBy: [''],
      WitnessBy: [''],
      LanguageLineAssistanceConfirmationNumber: [''],

      DoctorName: [''],
      PatientName: [''],
      Signauture: [''],
    });
  }

  get PatientId() {
    return this.Frm.get('PatientId');
  }

  get ConsentBy() {
    return this.Frm.get('ConsentBy');
  }
  get WitnessBy() {
    return this.Frm.get('WitnessBy');
  }
  get LanguageLineAssistanceConfirmationNumber() {
    return this.Frm.get('LanguageLineAssistanceConfirmationNumber');
  }

  get DoctorName() {
    return this.Frm.get('DoctorName');
  }

  get PatientName() {
    return this.Frm.get('PatientName');
  }

  get Signauture() {
    return this.Frm.get('Signauture');
  }

  formSubmit() {
    this.bloodTransfusionConsentService
      .registerBloodTransfusionConsent(this.Frm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
        this.submitAction.emit('');
      });
  }
}
