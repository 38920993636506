import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { PastMedication } from 'src/app/Models/pastmedication.model';
import { PastMedicationService } from 'src/app/services/past-medication.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-patient-view-pastmedication',
  templateUrl: './patient-view-pastmedication.component.html',
  styleUrls: ['./patient-view-pastmedication.component.css'],
})
export class PatientViewPastmedicationComponent implements OnInit {
  displayedColumns: string[] = ['DrugName', 'Dosage', 'StartDate', 'EndDate'];
  PastMedicationList: PastMedication[] = [];
  dataSource: MatTableDataSource<any>;

  id = 0;
  isPrint: boolean;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private pastMedicationService: PastMedicationService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPastMedication(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPastMedication(id) {
    this.patientService.GetPastMedication(id).subscribe((res) => {
      // this.PastMedicationList = res;

      this.dataSource = new MatTableDataSource(res.PastMedications);
      this.dataSource.paginator = this.paginator;
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getPastMedication(this.id);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteDrug(PastMedicationId) {
    this.pastMedicationService.deletePastMedication(PastMedicationId).subscribe(
      (res) => {
        alert('PastMedication Deleted with success');
        this.getPastMedication(this.id);
      },
      (err) => {
        alert('Unsuccessful');
      }
    );
  }
  print() {
    this.isPrint = true;
    window.print();

    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const radiologyImage = document.querySelector(
        '.rad-document img'
      ) as HTMLImageElement;
      if (radiologyImage) {
        radiologyImage.style.width = '100%';
      }
    }, 0);
  }
}
