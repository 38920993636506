import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { PatientVisit } from '../Models/patientvisit.model';

@Injectable({
  providedIn: 'root',
})
export class PatientvisitService {
  url = environment.Url + 'PatientVisits';
  urlVisit = environment.Url + 'Visits';
  patientVisitImport = environment.Url + 'Visits/patientVisitImport';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });
  constructor(private http: HttpClient) {}

  registerPatientVisit(body): Observable<PatientVisit> {
    return this.http
      .post<PatientVisit>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getPatientVisits(): Observable<PatientVisit[]> {
    return this.http
      .get<PatientVisit[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getPatientVisitById(id): Observable<PatientVisit> {
    return this.http.get<PatientVisit>(this.url + '/' + id);
  }

  updatePatientVisit(id, body): Observable<PatientVisit> {
    return this.http
      .put(this.urlVisit + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deletePatientVisit(id): Observable<PatientVisit> {
    return this.http
      .delete<PatientVisit>(this.urlVisit + '/' + id)
      .pipe(catchError(this.handleError));
  }

  getPatientVisitJsonData(id): Observable<PatientVisit> {
    return this.http.get<PatientVisit>(this.patientVisitImport + '/' + id);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
