import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab-nurse-side',
  templateUrl: './tab-nurse-side.component.html',
  styleUrls: ['./tab-nurse-side.component.css'],
})
export class TabNurseSideComponent implements OnInit {
  @Input() tabsArray: string[] = [];
  @Output() onTabChange = new EventEmitter<number>();
  activatedTab: number = 0;

  constructor() {}

  ngOnInit(): void {}
  setTab(index: number) {
    this.activatedTab = index;
    // debugger;
    this.onTabChange.emit(this.activatedTab);
  }
}
