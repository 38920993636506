import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserMultiFormatReader, Result } from '@zxing/library';
import { WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-qr-scanner-nurse',
  templateUrl: './qr-scanner-nurse.component.html',
  styleUrls: ['./qr-scanner-nurse.component.css'],
})
export class QrScannerNurseComponent {
  private trigger: Subject<void> = new Subject();
  public webcamImage: WebcamImage | null = null;
  public scannedResult: string = ''; // Store QR code data

  constructor(private router: Router, private tokenService: TokenService) {}

  // Trigger webcam capture
  public getSnapshot(): void {
    this.trigger.next();
  }

  // Handle captured image
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;

    if (this.webcamImage) {
      this.decodeQRCode(this.webcamImage.imageAsDataUrl);
    }
  }

  // Decode QR code using zxing library
  private decodeQRCode(imageBase64: string): void {
    const codeReader = new BrowserMultiFormatReader();
    const img = new Image();
    img.src = imageBase64;

    img.onload = () => {
      codeReader
        .decodeFromImageElement(img)
        .then((result: Result) => {
          this.scannedResult = result.getText(); // Successfully scanned QR code
          // console.log('QR Code Result:', this.scannedResult);
          const patientId = parseInt(this.scannedResult);
          this.router.navigate(['/nursePatient/', patientId]);
        })
        .catch((err) => {
          // console.error('QR Code not detected:', err);
          this.scannedResult = 'No QR code detected.';
        });
    };

    img.onerror = () => {
      // console.error('Error loading captured image.');
      this.scannedResult = 'Failed to load captured image.';
    };
  }

  // Observable for triggering the webcam
  public get invokeObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
}
