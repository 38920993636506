import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Doctor } from 'src/app/Models/doctor.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientBooking } from 'src/app/Models/patientbooking.model';
import { DoctorService } from 'src/app/services/doctor.service';

import { TokenService } from 'src/app/services/token.service';
import { VideoCallComponent } from '../video-call/video-call.component';

@Component({
  selector: 'app-view-doctor-appointments-today',
  templateUrl: './view-doctor-appointments-today.component.html',
  styleUrls: ['./view-doctor-appointments-today.component.css'],
})
export class ViewDoctorAppointmentsTodayComponent implements OnInit {
  doctor: Doctor;

  id = 0;
  ptAppointments: PatientBooking[];

  filteredList;
  dataSource: MatTableDataSource<Patient>;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();
  date;

  constructor(
    private doctorService: DoctorService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // this.route.params.subscribe(res=>{
    //   this.id= res['id'];
    // })
    // setInterval(() => {
    //   this.getAppt(this.id);
    // }, 60000);

    // 60 seconds
    this.getAppt(this.id);
    this.getDoctor(this.id);

    let dat = Date.now();
    this.date = moment(dat).format('MM/DD/YYYY');
    // this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getAppt(id) {
    this.doctorService.getDoctorBooking(id).subscribe((res) => {
      this.filteredList = res.PatientBookings.filter(
        (x) => x.VisitDate == this.date
      );

      this.ptAppointments = res.PatientBookings;
    });
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.ptAppointments.filter((x) =>
        x.VisitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  joinCall(patientId: string): void {
    const doctorId = this.id; // Get doctor ID from JWT
    const userName = this.doctor.DoctorName;

    const dialogRef = this.dialog.open(VideoCallComponent, {
      width: '50%',
      height: '80%',
      disableClose: true, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { doctorId, patientId, userName }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }
}
