<div class="toolbox">
    <h3>Toolbox</h3>
    <div *ngFor="let field of toolboxFields" cdkDrag class="tool-item" (cdkDragEnded)="onDragEnd($event, field)">
        {{ field.label }}
    </div>
</div>

<div class="form-area" cdkDropList (cdkDropListDropped)="onDrop($event)">
    <h3>Form Builder</h3>
    <div *ngFor="let item of formFields" class="form-item">
        <!-- Editable Label -->
        <label *ngIf="!item.labelEditable" (dblclick)="toggleLabelEdit(item)">
            {{ item.label }}
        </label>
        <input *ngIf="item.labelEditable" [(ngModel)]="item.label" (blur)="toggleLabelEdit(item)"
            (keyup.enter)="toggleLabelEdit(item)" />

        <!-- Render Field Types -->
        <input *ngIf="item.type === 'text'" type="text" />
        <textarea *ngIf="item.type === 'textarea'"></textarea>
        <button *ngIf="item.type === 'button'">{{ item.label }}</button>
        <div *ngIf="item.type === 'checkbox'">
            <input type="checkbox" /> {{ item.label }}
        </div>
        <div *ngIf="item.type === 'radio'">
            <div *ngFor="let option of item.options">
                <input type="radio" name="{{ item.id }}" /> {{ option }}
            </div>
        </div>
        <select *ngIf="item.type === 'dropdown'">
            <option *ngFor="let option of item.options">{{ option }}</option>
        </select>
        <input *ngIf="item.type === 'date'" type="date" />
        <input *ngIf="item.type === 'file'" type="file" />
        <div *ngIf="item.type === 'signature'" class="signature-field">
            <p>Signature Field (Draw Here)</p>
        </div>
    </div>
</div>

<div class="form-actions">
    <button (click)="saveForm()">Save Form</button>
    <button (click)="loadForm()">Load Form</button>
    <button (click)="exportForm()">Export Form</button>
</div>