import { Component } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-nurse-patient-reports',
  templateUrl: './nurse-patient-reports.component.html',
  styleUrls: ['./nurse-patient-reports.component.css']
})
export class NursePatientReportsComponent {

}
