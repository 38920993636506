import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ChatMessage } from 'src/app/Models/chatmessage.model';
import { Doctor } from 'src/app/Models/doctor.model';
import { Nurse } from 'src/app/Models/nurse.model';
import { User } from 'src/app/Models/user.model';
import { ChatService } from 'src/app/services/chat.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { NurseService } from 'src/app/services/nurse.service';
import { UserService } from 'src/app/services/user.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {
  // users = ['User1', 'User2', 'User3']; // Replace with real user list
  selectedUser: string;
  message: string;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  users: User[] = [];
  myMessage: ChatMessage[];
  hospitalId: number;
  doctorList: Doctor[];
  nurseList: Nurse[];
  doctor: Doctor;
  combinedData: any[] = [];
  isSuccess = false;
  Frm: FormGroup;

  constructor(
    private chatService: ChatService,
    private fb: FormBuilder,
    private doctorService: DoctorService,
    private nurseService: NurseService,
    private hospitalService: HospitalService
  , private tokenService: TokenService) {}

  ngOnInit() {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);

    this.doctorService.getDoctorHospital(this.doctorId).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getNurses(this.hospitalId);
    });
    this.getMessages(this.doctorId);

    this.Frm = this.fb.group({
      Message: [''],
      UserId: [],
    });
  }

  get UserId() {
    return this.Frm.get('UserId');
  }
  get Message() {
    return this.Frm.get('Message');
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  getNurses(id) {
    this.hospitalService.getNurses(id).subscribe((res) => {
      this.nurseList = res.Nurses;
    });
  }

  getMessages(id) {
    this.doctorService.getdoctorChats(id).subscribe((res) => {
      this.myMessage = res.ChatMessages;
    });
  }
  formSubmit() {
    let userId = this.Frm.value['UserId'];
    let message = this.Frm.value['Message'];
    let nurseName = this.nurseList.filter((it) => it.NurseId == userId)[0]
      .NurseName;
    let body = {
      Message: message,
      FromUserName: this.doctor.DoctorName,
      ToUserName: nurseName,
      DoctorId: this.doctorId,
      NurseId: userId,
    };
    this.chatService.sendChatMessage(body).subscribe((res) => {
      this.isSuccess = true;
      this.myMessage.push(res);
    });
  }
  // sendMessage() {
  //   if (this.selectedUser && this.message) {
  //     this.chatService.sendMessage(this.selectedUser, this.message);
  //     this.message = ''; /
  //   }
  // }

  delete(id) {
    this.chatService.deleteChatMessage(id).subscribe((res) => {
      alert('are you sure do you wanted to delete message?');
      this.getMessages(this.doctorId);
    });
  }
}
