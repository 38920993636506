<div class="container mt-3">
    <div class="row">
        <div class="col-lg-6 "><span class="float-right"></span></div>
        <div class="col-lg-6 ">
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Search by Date</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6" *ngFor="let ps of filteredList | slice:0:itemsToShow" style="max-width: 500px;">

            <mat-card class="example-card mat-elevation-z8 mx-1 my-1">
                <mat-card-header>
                    <mat-card-title>{{ ps?.DrugName }}</mat-card-title>
                    <!-- <mat-card-subtitle>{{ ps?.Instruction }}</mat-card-subtitle> -->
                </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <div class="col-lg-6">

                            <p>Prescribed Date : {{ ps?.CreatedDate | date: 'MM/dd/yyyy'}}</p>
                            <p *ngIf="ps.DoctorName">Approved By : {{ ps.DoctorName }}</p>
                            <p *ngIf="ps.NurseName">NP : {{ ps.NurseName }}</p>
                        </div>
                        <div class="col-lg-6">
                            <h6>Refill Details</h6>
                            <p>Quantity : {{ ps?.Quantity }}</p>
                            <p>Refills : {{ ps?.Numberofrefills }}</p>
                            <p>Refill End By : {{ ps?.EndDate | date: 'MM/dd/yyyy'}} </p>
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h4>Frequency: {{ ps?.Frequency }}</h4>
                            <h4>Direction of Usage: {{ ps?.Instruction }}</h4>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h4>Note: {{ ps?.Note }}</h4>

                        </div>
                    </div>


                </mat-card-content>
                <mat-card-actions>
                    <button type="button" (click)="openAddAppointmentModal(ps.PrescriptionId)" mat-mini-fab
                        [disabled]="CurrentDoctorId != ps.DoctorId" color="primary">
                        <mat-icon matPrefix>edit</mat-icon>
                    </button>
                    <!-- <button type="button" [routerLink]="['/updatePrescription/',ps.PrescriptionId,this.id]" mat-mini-fab
                        color="primary">
                        <mat-icon matPrefix>edit</mat-icon>
                    </button> -->
                    <button class="ml-5" (click)="copy(ps)" mat-raised-button>Copy Data</button>

                    <button type="button" class="float-right" (click)="delete(ps.PrescriptionId)" mat-mini-fab
                        [disabled]="CurrentDoctorId != ps.DoctorId" color="accent">
                        <mat-icon matPrefix>delete</mat-icon>
                    </button>

                </mat-card-actions>

            </mat-card>

        </div>

    </div>
    <button color="primary" class="mx-5 my-5" mat-raised-button *ngIf="hasMoreItems()" (click)="loadMore()">
        Load More
    </button>
</div>