import { Component, OnInit } from '@angular/core';
import { AuditLog, AuditLogService } from 'src/app/services/audit-log.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.css'],
})
export class AuditLogComponent implements OnInit {
  auditLogs: AuditLog[] = [];
  isLoading = true; // Show loading spinner while data is being fetched

  constructor(private auditLogService: AuditLogService) {}

  ngOnInit(): void {
    this.fetchAuditLogs();
  }

  // Fetch audit logs from the service
  fetchAuditLogs(): void {
    this.auditLogService.getAuditLogs().subscribe(
      (data: AuditLog[]) => {
        this.auditLogs = data;
        this.isLoading = false;
      },
      (error) => {
        console.error('Error fetching audit logs', error);
        this.isLoading = false;
      }
    );
  }
}
