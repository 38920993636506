import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CptcodeService } from '../../services/cptcode.service';
import { CptCode } from '../../Models/cptcode.model';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-cptcode',
  templateUrl: './add-cptcode.component.html',
  styleUrls: ['./add-cptcode.component.css'],
})
export class AddCptcodeComponent implements OnInit {
  cptCodeList: CptCode[] = [];
  isSuccess = false;
  constructor(
    private fb: UntypedFormBuilder,
    private cptCodeService: CptcodeService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.getCptCodes();
  }

  cptFrm = this.fb.group({
    Code: ['', Validators.required],
    Description: ['', Validators.required],
    Price: ['', Validators.required],
  });

  getCode() {
    return this.cptFrm.get('Code');
  }

  getDescription() {
    return this.cptFrm.get('Description');
  }

  getPrice() {
    return this.cptFrm.get('Price');
  }

  getCptCodes() {
    this.cptCodeService.getCptCodes().subscribe((res) => {
      this.cptCodeList = res;
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getCode();
    }
  }
  formSubmit() {
    let code = this.cptFrm.value['Code'];
    let description = this.cptFrm.value['Description'];
    let price = this.cptFrm.value['Price'];

    let body = {
      Code: code,
      Description: description,
      Price: price,
    };

    this.cptCodeService.registerCptCode(body).subscribe((res) => {
      this.cptCodeList.push(res);
      this.isSuccess = true;
      this.cptFrm.reset();
    });
  }

  itemsToShow = 2;

  // Function to load more items
  loadMore() {
    this.itemsToShow += 1; // Increase the number of items to show by 3
  }

  // Function to check if there are more items to load
  hasMoreItems() {
    return this.itemsToShow < this.cptCodeList?.length;
  }
}
