import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { DepartmentService } from 'src/app/services/department.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { UserService } from 'src/app/services/user.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-doctor-profile',
  templateUrl: './view-doctor-profile.component.html',
  styleUrls: ['./view-doctor-profile.component.css'],
})
export class ViewDoctorProfileComponent implements OnInit {
  id = 0;

  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  showItem = '';
  doctorList: Doctor[] = [];
  filteredList: Doctor[];
  dataSource: MatTableDataSource<Doctor>;
  doctor: Doctor;
  constructor(
    private route: ActivatedRoute,
    private departService: DepartmentService,
    private doctorService: DoctorService,
    private userService: UserService
  , private tokenService: TokenService) // private hosptalService: HospitalService
  {}

  ngOnInit(): void {
    // this.route.params.subscribe(res=>{
    //   this.id = res['id']
    // })
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.getDoctor(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
      // this.filteredList = res;
    });
  }

  // getDoctor(id){
  // this.departService.getDepartmentDoctors(id).subscribe(res=>{
  //   this.doctorList = res.Doctors;
  //   this.filteredList = res.Doctors;
  // })
  // }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getDoctor(this.id);
    }
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.doctorList.filter((x) =>
        x.DoctorName.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }
}
