import { Component } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-duty',
  templateUrl: './view-duty.component.html',
  styleUrls: ['./view-duty.component.css']
})
export class ViewDutyComponent {

}
