import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { RadiologyReport } from 'src/app/Models/radiologyreport.model';
import { PatientService } from 'src/app/services/patient.service';
import { RadiologyReportService } from 'src/app/services/radiology-report.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-radiology-report',
  templateUrl: './view-radiology-report.component.html',
  styleUrls: ['./view-radiology-report.component.css'],
})
export class ViewRadiologyReportComponent implements OnInit {
  displayedColumns: string[] = [
    'Date',
    'ReportType',
    'Description',
    'OrderedBy',
    'LabName',
    'ReportedBy',
    'VerifiedBy',
    'View',
    'Update',
    'Delete',
  ];

  id = 0;
  radiologyReport: RadiologyReport[] = [];
  dataSource: MatTableDataSource<any>;
  radioDocument: string = '';
  patient: Patient;

  constructor(
    private patientService: PatientService,
    private radiologyReportservice: RadiologyReportService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetRadiologyReports(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  GetRadiologyReports(id) {
    this.patientService.getPatientRadiologyReport(id).subscribe((res) => {
      // this.radiologyReport = res.RadiologyReports

      this.dataSource = new MatTableDataSource(
        res.RadiologyReports.sort(function (x, y) {
          return y.RadiologyReportId - x.RadiologyReportId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.radiologyReportservice.deleteRadiologyReport(id).subscribe((res) => {
      // this.radiologyReport = this.radiologyReport.filter(x=>x.RadiologyReportId !=id)
      this.GetRadiologyReports(this.id);
    });
  }
  view() {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  print() {
    window.print();
  }

  public viewLabReport(report: RadiologyReport): void {
    // console.log('report: ', report);
    this.radioDocument = report.ReportDescription;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const radiologyImage = document.querySelector(
        '.rad-document img'
      ) as HTMLImageElement;
      if (radiologyImage) {
        radiologyImage.style.width = '100%';
      }
    }, 0);
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
    }
  }

  copyRowData(row: any): void {
    const htmlContent = `
      <p><strong>Date:</strong> ${new Date(
        row.createdDate
      ).toLocaleString()}</p>
      <p><strong>Report Type:</strong> ${row.ReportType}</p>
      <p><strong>Shortcut:</strong> ${row.shortcut}</p>
      <p><strong>Radiologyst:</strong> ${row.DoctorName}</p>
               <P ><strong>Examination:</strong> {{row?.Examination}}</P>
                  <P ><strong>Indication:</strong> {{row?.Indication}}</P>
                  <P ><strong>Technique:</strong> {{row?.Technique}}</P>
                  <P ><strong>Findings:</strong> {{row?.Description}}</P>
                  <P ><strong>Impression:</strong> {{row?.Impression}}</P>
    `;

    navigator.clipboard
      .writeText(htmlContent)
      .then(() => {
        console.log('Row data copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy row data: ', err);
      });
  }
}
