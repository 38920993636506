import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DoctorTemplate } from '../Models/doctortemplate.model';

@Injectable({
  providedIn: 'root',
})
export class DoctorTemplateService {
  private apiUrl = environment.Url + 'DoctorTemplates';
  // private apiUrl = 'https://localhost:5001/api/templates';
  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  saveTemplate(template: any): Observable<any> {
    return this.http.post(this.apiUrl, template);
  }

  getTemplates(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl);
  }

  getTemplate(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`);
  }
  updateTemplate(id, body) {
    return this.http
      .put<DoctorTemplate>(this.apiUrl + '/' + id, body)
      .pipe(catchError(this.handleError));
  }
  getTemplateById(id) {
    return this.http.get<DoctorTemplate>(this.apiUrl + '/' + id);
  }
  deleteTemplate(id) {
    return this.http
      .delete<DoctorTemplate>(this.apiUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  // deleteTemplate(id: number): Observable<any> {
  //   return this.http.get<any>(`${this.apiUrl}/${id}`);

  // }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
