import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-all-test-results',
  templateUrl: './all-test-results.component.html',
  styleUrls: ['./all-test-results.component.css'],
})
export class AllTestResultsComponent implements OnInit {
  id = 0;

  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  labFrm = this.fb.group({});

  logIndex(e) {}
}
