import { Component } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-draw-tool',
  templateUrl: './draw-tool.component.html',
  styleUrls: ['./draw-tool.component.css'],
})
export class DrawToolComponent {}
