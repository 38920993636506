import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Invoice } from 'src/app/Models/invoice.model';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';
import { PrintInvoicePtSideComponent } from '../print-invoice-pt-side/print-invoice-pt-side.component';

@Component({
  selector: 'app-ptinvoices',
  templateUrl: './ptinvoices.component.html',
  styleUrls: ['./ptinvoices.component.css'],
})
export class PtinvoicesComponent implements OnInit {
  displayedColumns: string[] = [
    'PatientName',
    'Title',
    'Amount',
    'Date',
    'Status',
    'Print',
  ];
  // 'Description',
  // 'Units',
  // 'Duration',
  id = 0;
  invoiceList: Invoice[] = [];

  filteredList;
  dataSource: MatTableDataSource<any>;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getInvoice(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getInvoice(id) {
    this.patientService.getPatieninvoices(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.Invoices);
      this.dataSource.paginator = this.paginator;
      //   this.filteredList = res.Invoices
      //  this.invoiceList= res.Invoices
    });
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.invoiceList.filter((x) =>
        x.Title.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  printInvoice(InvoiceId: string): void {
    const dialogRef = this.dialog.open(PrintInvoicePtSideComponent, {
      width: '60%',
      height: '80%',
      disableClose: false, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { InvoiceId }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }
}
