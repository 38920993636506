<div class="subscription-container">
    <h2>Select a Subscription Plan</h2>

    <div *ngFor="let plan of plans">
        <div class="plan-card" (click)="selectedPlanId = plan.planId">
            <h3>{{ plan.planName }}</h3>
            <p>Price: {{ plan.price | currency }}</p>
            <p>Max Users: {{ plan.maxUsers }}</p>
            <p>Max Appointments: {{ plan.maxAppointments }}</p>
            <p>Features: {{ plan.features }}</p>
        </div>
    </div>

    <button (click)="subscribeToPlan()">Subscribe</button>
</div>