import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { EmailService } from 'src/app/services/email.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit{

  isSuccess=false;
  filteredOptions: Observable<string[]>;
  Codes: any[]= [];

  constructor(private fb:FormBuilder,private emailService:EmailService, private http: HttpClient, private tokenService: TokenService){

  }
  ngOnInit(): void {
    

    this.filteredOptions = this.Frm.controls.CountryCode.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value || '')),
    );
  }

  Frm = this.fb.group({
    CountryCode: ['+1',Validators.required  ],
    PhoneNumber:['',Validators.required],
    Body:['',Validators.required],
  })


get CountryCode(){
  return this.Frm.get('CountryCode')
}

get PhoneNumber(){
  return this.Frm.get('PhoneNumber')
}


get Body(){
  return this.Frm.get('Body')
}





  formSubmit(){

    // let phoneNumber = this.Frm.value['PhoneNumber']
    let phoneNumber =
    this.Frm.value['CountryCode'] +
    this.Frm.value['PhoneNumber'];
    let body = this.Frm.value['Body']

    let TextMessage={
      PhoneNumber:phoneNumber,
      Body:body
    }
this.emailService.sendSms(TextMessage).subscribe(res=>{

  this.Frm.reset();
  this.isSuccess=true;
})
  }


  public countryCodes() {
    this.http.get("/assets/json/country-codes.json").subscribe({
      next: (res:any) => {
        this.Codes = res.map(x => x.dial_code);
      }
    });
   }
  
   private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
  
    return this.Codes.filter(option => option.toLowerCase().includes(filterValue));
  }
}
