<div class="col-lg regFrm mat-elevation-z8">
    <h1>New Prescription</h1>

    <form (keydown.enter)="$event.preventDefault()" [formGroup]="prescriptionForm" (ngSubmit)="submitForm()">
        <div formArrayName="prescriptions">
            <div *ngFor="let prescription of prescriptions.controls; let i = index" [formGroupName]="i">
                <div class="row">
                    <div class="col">
                        <p id="result"></p>

                    </div>
                    <div class="col">
                        <div *ngIf="daApprovalResult"
                            [ngStyle]="{'color': daApprovalResult.includes('required') ? 'orange' : 'blue'}">
                            <p>{{ daApprovalResult }}</p>
                        </div>

                        <div *ngIf="isDARequired">
                            <label for="daCode">Enter DEA Number:</label>
                            <input type="text" id="daCode" [ngModelOptions]="{standalone: true}" [(ngModel)]="daCode"
                                placeholder="Enter DA code">

                            <button type="button" (click)="validateDACode()">Validate DA Code</button>

                            <div *ngIf="daValidationMessage">
                                <p [ngStyle]="{'color': daValidationMessage.includes('valid') ? 'green' : 'red'}">
                                    {{ daValidationMessage }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="isDAvalidated">

                    <div class="col-lg-6 p-3  mat-elevation-z6">
                        <form (keydown.enter)="$event.preventDefault()" [formGroup]="loginForm"
                            (ngSubmit)="loginSubmit()" class="example-form">
                            <mat-form-field class="example-full-width">
                                <mat-label>UserName</mat-label>
                                <input type="text" formControlName="UserName" matInput>
                            </mat-form-field>

                            <mat-form-field class="example-full-width">
                                <mat-label>Password</mat-label>
                                <input type="password" matInput formControlName="Password">
                            </mat-form-field>



                            <button class="float-right lgBtCl" mat-button color="primary">
                                <mat-icon>login</mat-icon>
                                Login</button>



                        </form>
                        <div *ngIf="error" class="alert alert-danger mt-2">{{netWorkFailure}}</div>

                        <div *ngIf="IsthrowError" class="alert alert-danger mt-2">{{IsthrowError}}</div>
                    </div>

                </div>

                <div class="form-row">
                    <!-- Drug Name -->
                    <div class="col-lg-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Drug Name</mat-label>


                            <mat-select formControlName="DrugName">
                                <mat-option>
                                    <lib-mat-select-search [list]="medicineList" [searchProperties]="['DrugName']"
                                        (filtered)="flteredmedicineList = $event"
                                        (ngModelChange)="calculateQuantity(i); calculateEndDate(i)">
                                    </lib-mat-select-search>
                                </mat-option>

                                <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" itemSize="25">
                                    <mat-option *cdkVirtualFor="let entry of flteredmedicineList"
                                        [value]="entry.DrugName" #drgName
                                        (onSelectionChange)="getSelectedDrug(drgName.value)">
                                        {{entry.DrugName}}
                                    </mat-option>
                                </cdk-virtual-scroll-viewport>
                            </mat-select>




                            <!-- <input type="text" matInput formControlName="DrugName" [matAutocomplete]="auto[i]" /> -->
                            <!-- <mat-autocomplete #auto="matAutocomplete">
                            <cdk-virtual-scroll-viewport itemSize="48" class="virtual-scroll-viewport">
                                <mat-option *cdkVirtualFor="let option of filteredDrugOptions[i] " [value]="option">
                                    {{ option }}
                                </mat-option>
                            </cdk-virtual-scroll-viewport>
                        </mat-autocomplete> -->
                        </mat-form-field>
                    </div>

                    <!-- Amount Per Dose -->
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Amount Per Dose</mat-label>
                            <mat-select formControlName="AmountPerDose" (selectionChange)="calculateQuantity(i)">
                                <mat-option *ngFor="let amount of amountPerDoseOptions" [value]="amount">
                                    {{ amount }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- QuantityUnit -->
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Units</mat-label>

                            <mat-error>Units required</mat-error>
                            <input type="text" matInput formControlName="Unit" [matAutocomplete]="auto6"> <mat-icon
                                matSuffix>search</mat-icon>
                            <mat-autocomplete #auto6="matAutocomplete">
                                <mat-option *ngFor="let ext of filteredExt | async" [value]="ext" #extTb
                                    (onSelectionChange)="getExtValue(extTb.value)">
                                    {{ ext }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>


                    </div>


                    <!-- Route -->
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Route</mat-label>
                            <input type="text" matInput formControlName="Route" [matAutocomplete]="auto"> <mat-icon
                                matSuffix>search</mat-icon>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error>Route
                                required</mat-error>

                        </mat-form-field>
                    </div>

                    <!-- Frequency -->
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Frequency</mat-label>
                            <mat-select formControlName="Frequency" (selectionChange)="calculateQuantity(i)">
                                <mat-option *ngFor="let option of frequencyList" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <!-- Refill Duration -->
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Refill Duration</mat-label>
                            <mat-select formControlName="RefillDuration"
                                (selectionChange)="calculateQuantity(i); calculateEndDate(i)">
                                <mat-option *ngFor="let option of refillDurationList" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Number of Refills -->
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Number of Refills</mat-label>
                            <input matInput formControlName="NumberOfRefills"
                                (input)="calculateQuantity(i); calculateEndDate(i)" />
                        </mat-form-field>
                    </div>
                    <!-- Quantity -->
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Quantity</mat-label>
                            <input matInput formControlName="Quantity" />
                        </mat-form-field>
                    </div>
                    <!-- Quantity -->
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Unit</mat-label>
                            <input matInput formControlName="Unit" />
                        </mat-form-field>
                    </div>
                    <!-- Start Date -->
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Start Date</mat-label>
                            <input matInput formControlName="StartDate" />
                        </mat-form-field>
                    </div>

                    <!-- End Date -->
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>End Date</mat-label>
                            <input matInput formControlName="EndDate" />
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6">
                        <!-- Direction of Use -->
                        <mat-form-field class="w-100">
                            <mat-label>Direction of Use</mat-label>
                            <textarea matInput formControlName="Instruction" placeholder="Enter directions"></textarea>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                        <!-- Note -->
                        <mat-form-field class="w-100">
                            <mat-label>Note</mat-label>
                            <textarea matInput formControlName="Note" placeholder="Enter note"></textarea>
                        </mat-form-field>
                    </div>


                    <div class="col-lg-12">

                        <button mat-mini-fab color="primary" type="button"
                            (click)="addPrescription()"><mat-icon>add</mat-icon></button>
                        <button class="float-right" mat-icon-button color="warn" type="button"
                            (click)="removePrescription(i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <button [disabled]="prescriptionForm.invalid" type="submit" class="float-right  lgBtCl" mat-button>
            <mat-icon>save</mat-icon>
            Save</button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
            Successfully Created Prescription
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

    </form>

</div>
<hr class="mt-5">
<h4>Pharmacy Details</h4>
<ul class="list-unstyled mb-5">

    <li>Pharmacy Name : <span>{{pharmacy[0].PharmacyName}}</span>
    <li>Pharmacy Address : <span>{{pharmacy[0].PharmacyAddress}}</span>
        <!-- <li>Pharmacy Phone : <span>{{pharmacy[0]?.PhoneNumber}}</span>
          <li>Pharmacy Fax : <span>{{pharmacy[0]?.FaxNumber}}</span> -->


</ul>