import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { PatientService } from 'src/app/services/patient.service';
import { NursereportService } from '../../services/nursereport.service';
import { Patient } from 'src/app/Models/patient.model';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-patient-nursereport',
  templateUrl: './view-patient-nursereport.component.html',
  styleUrls: ['./view-patient-nursereport.component.css'],
})
export class ViewPatientNursereportComponent implements OnInit {
  displayedColumns: string[] = [
    'createdDate',
    'Respiration',
    'Temparature',
    'HeartBeat',
    'NurseName',
    'Signature',
    'View',
    'Update',
    'Delete',
  ];
  id = 0;
  nurseReports: NurseReport[] = [];
  dataSource: MatTableDataSource<any>;
  public dialogData: string = '';
  patient: Patient;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private nurseReportService: NursereportService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getNurseReport(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('nursereportList') nurseReport!: ElementRef<any>;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getNurseReport(id) {
    this.patientService.getNurseReport(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.NurseReports.sort(function (x, y) {
          return y.NurseReportId - x.NurseReportId;
        })
      );
      this.dataSource.paginator = this.paginator;
      this.nurseReports = res.NurseReports;
    });
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  delete(id) {
    this.nurseReportService.deleteNurseReport(id).subscribe((res) => {
      // this.nurseReports = this.nurseReports.filter(x=>x.NurseReportId !=id);
      this.getNurseReport(this.id);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewNurseReport(row: NurseReport): void {
    this.dialogData = row.Comments;
  }

  PrintReport(printReport: NurseReport) {
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      console.log('pres', this.nurseReport.nativeElement.innerText);
    }, 0);
  }

  print() {
    window.print();
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getNurseReport(this.id);
    }
  }
}
