import { PrescriptionService } from './../../services/prescription.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MedicineService } from '../../services/medicine.service';
import { Medicine } from 'src/app/Models/medicine.model';
import * as moment from 'moment';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PatientService } from 'src/app/services/patient.service';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { Allergy } from 'src/app/Models/allergy.models';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { TwofaComponent } from '../twofa/twofa.component';
import { TwofaDeaComponent } from '../twofa-dea/twofa-dea.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-prescription',
  templateUrl: './add-prescription.component.html',
  styleUrls: ['./add-prescription.component.css'],
})
export class AddPrescriptionComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  states;
  isSuccess = false;
  doctor: Doctor;

  flteredmedicineList: Observable<any>;
  options: string[] = [
    'Oral',
    'Topical',
    'Intracerebroventricular',
    'Intramuscular',
    'Intragastric',
    'Intraperitoneal',
    'Intradermal',
    'Intracerebral',
    'Intra-arterial',
    'Intrathecal',
    'Intravenous',
    'Subcutaneous',
  ];
  filteredOptions: Observable<string[]>;
  filteredDose: Observable<string[]>;
  filteredinstructions: Observable<string[]>;
  filteredFrequency: Observable<string[]>;
  filteredNoOfTablets: Observable<string[]>;
  filteredExt: Observable<string[]>;
  filteredRefill: Observable<string[]>;
  QTimes: string[] = [
    'Q1-Every Hour',
    'Q2-Every 2 hours',
    'Q3-Every 3 hours',
    'Q4-Every 4 hours',
    'Q5-Every 5 hours',
    'Q4-Every 6 hours',
    'Q4-Every 6 hours',
    'Q8-Every 8 hours',
    'Q12-Every 12 hours',
  ];

  DrugsList: string[] = [
    'Calpol 200mg',
    'Paracetamol 200mg',
    'Co-codomol 200mg',
    'Ibuprofen 200mg',
  ];
  instructionList: string[] = [
    'Take 1 tablet in the Morning',
    'Take 2 tablet in the Morning',
    'Take 3 tablet in the Morning',
    'Take 1 tablet in the Afternoon',
    'Take 2 tablet in the Afternoon',
    'Take 3 tablet in the Afternoon',
    'Take 1 tablet in the Evening',
    'Take 2 tablet in the Evening',
    'Take 3 tablet in the Evening',
    'Take 1 tablet at bedtime',
    'Take 2 tablet at bedtime',
    'Take 3 tablet at bedtime',
    'Take 1 tablet in the Morning and 1 tablet at bedtime',
    'Take 2 tablet in the Morning and 2 tablet at bedtime',
    'Take 3 tablet in the Morning and 3 tablet at bedtime',
    'Take 1 tablet in the Morning ,ake 1 tablet in the Afternoon and Take 1 tablet in the Evening ',
    'Take 2 tablet in the Morning ,ake 2 tablet in the Afternoon and Take 2 tablet in the Evening ',
    'Take 3 tablet in the Morning ,ake 3 tablet in the Afternoon and Take 3 tablet in the Evening ',
    'Take 1 tablet in the Morning ,ake 1 tablet in the Afternoon and Take 1 tablet in the bedtime ',
    'Take 2 tablet in the Morning ,ake 2 tablet in the Afternoon and Take 2 tablet in the bedtime ',
    'Take 3 tablet in the Morning ,ake 3 tablet in the Afternoon and Take 3 tablet in the bedtime ',
    'Take 1 tablet b.i.d as needed',
    'Take 2 tablet b.i.d as needed',
    'Take 3 tablet b.i.d as needed',
    'Take 1 tablet t.i.d as needed',
    'Take 2 tablet t.i.d as needed',
    'Take 3 tablet t.i.d as needed',
    'Take 1 tablet every hour',
    'Take 1 tablet every 2 hours',
    'Take 1 tablet every 4 hours',
    'Take 1 tablet every 6 hours',
    'Take 1 tablet every 8 hours',
    'Take 1 tablet every 12 hours',
  ];
  frequencyList: string[] = [
    'QD (quaque die) - Once a day',
    'BID (bis in die) - Twice a day',
    'TID (ter in die) - Three times a day',
    'QID (quater in die) - Four times a day',
    'QHS (quaque hora somni) - Every night at bedtime',
    'Q4H (quaque 4 hora) - Every 4 hours',
    'Q6H (quaque 6 hora) - Every 6 hours',
    'Q8H (quaque 8 hora) - Every 8 hours',
    'Q12H (quaque 12 hora) - Every 12 hours',
    'PRN (pro re nata) - As needed',

    // 'drop',
  ];
  prnInstruction: string[] = [
    'PRN Pain - As needed for pain',
    'PRN Fever - As needed for fever',
    'PRN Nausea - As needed for nausea',
    'PRN Anxiety - As needed for anxiety',
    'PRN Sleep - As needed for sleep',
  ];
  medicineList: Medicine[];
  // dose: string[] = ['mg', 'ml'];
  dose: string[] = [
    '5 mg ',
    '10 mg ',
    '15 mg ',
    '20 mg ',
    '25 mg ',
    '30 mg ',
    '40 mg ',
    '50 mg ',
    '80 mg ',
    '100 mg ',
    '200 mg ',
    '300 mg ',
    '400 mg ',
    '500 mg ',
    '600 mg ',
    '800 mg ',
    '1000 mg ',
    '5 mg ',
    '10 mg ',
    '15 mg ',
    '20 mg ',
    '25 mg ',
    '30 mg ',
    '40 mg ',
    '50 mg ',
    '80 mg ',
    '100 mg ',
    '200 mg ',
    '300 mg ',
    '400 mg ',
    '500 mg ',
    '600 mg ',
    '800 mg ',
    '1000 mg ',
    '5 ml',
    '10 ml',
    '15 ml',
    '20 ml',
    '25 ml',
    '30 ml',
    '40 ml',
    '50 ml',
    '80 ml',
    '100 ml',
    '200 ml',
    '300 ml',
    '400 ml',
    '500 ml',
    '600 ml',
    '800 ml',
    '1000 ml',
  ];
  dosageOptions = [
    '50mg',
    '100mg',
    '200mg',
    '250mg',
    '300mg',
    '400mg',
    '500mg',
  ];
  refill: string[] = [
    '30 Days',
    '90 Days',
    '6 Months',
    '1 Year',
    '1 Week',
    '2 Week',
    '3 Week',
    '4 Week',
    '5 Week',
    '6 Week',
  ];
  noOfTablet: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  drugExtention: string[] = [
    'Tablet',
    'Capsules',
    'ML',
    'Applications',
    // 'suspensions',
    // 'solutions',
    // 'colloids',
  ];
  twiceValue: number;
  refillValue: number;
  frequencyValue: number;
  tabLets: number;
  drgNumber: number;
  today: any;
  pharmacy: PatientsPharmacy[];
  noRefills: any;
  ext: any;
  allergies: Allergy[];
  isDAvalidated: boolean = false;
  netWorkFailure: any;
  error: boolean;
  IsthrowError: string;
  logiForm: any;

  constructor(
    private fb: UntypedFormBuilder,
    private prescriptionService: PrescriptionService,
    private router: Router,
    private route: ActivatedRoute,
    private medicineService: MedicineService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private authService: AuthService,
    private dialog: MatDialog,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.controlledDrugs();
    this.getMedicine();
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPharmacy(this.id);
    this.getAllergies(this.id);

    this.doctorService.getDoctorById(this.doctorId).subscribe((res) => {
      this.doctor = res;
      this.DoctorName.setValue(res.DoctorName);
    });
    this.filteredOptions = this.Route.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value || ''))
    );
    this.filteredinstructions = this.Instruction.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter2(value || ''))
    );
    this.filteredFrequency = this.Frequency.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter3(value || ''))
    );
    this.filteredRefill = this.RefillDuration.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter4(value || ''))
    );
    this.filteredDose = this.DosageExtn.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter5(value || ''))
    );
    this.filteredNoOfTablets = this.NoOfTablets.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter6(value || ''))
    );
    this.filteredExt = this.DosageExtn.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter7(value || ''))
    );

    const currentDate = new Date();
    const formattedDate =
      ('0' + (currentDate.getMonth() + 1)).slice(-2) +
      '/' + // Month
      ('0' + currentDate.getDate()).slice(-2) +
      '/' + // Day
      currentDate.getFullYear(); // Year

    const dateControl = new FormControl(formattedDate);
  }

  prescriptionFrm = this.fb.group({
    DrugName: ['', Validators.required],
    Strength: [''],
    DosageExtn: [''],
    BrandName: [''],
    Quantity: ['', Validators.required],
    DatePrescribed: [''],
    Instruction: ['As Directed', Validators.required],
    MorningTime: [''],
    AfternoonTime: [''],
    EveningTime: [''],
    NightTime: [''],
    QTime: [''],
    Status: [],
    // StartDate: new FormControl(moment().format('MM/DD/YYYY')),
    StartDate: [''],
    EndDate: [''],
    RefillDuration: ['', Validators.required],
    Numberofrefills: ['', Validators.required],
    DoctorName: [''],
    Note: [''],
    Route: [''],
    Frequency: ['', Validators.required],
    NoOfTablets: ['', Validators.required],
  });
  selectDosage(Strength: string): void {
    this.prescriptionFrm.patchValue({ Strength }); // Update dosage value in the form
  }
  get NoOfTablets() {
    return this.prescriptionFrm.get('NoOfTablets');
  }
  get Frequency() {
    return this.prescriptionFrm.get('Frequency');
  }
  get Route() {
    return this.prescriptionFrm.get('Route');
  }
  get Numberofrefills(): any {
    return this.prescriptionFrm.get('Numberofrefills');
  }
  get RefillDuration() {
    return this.prescriptionFrm.get('RefillDuration');
  }

  get DoctorName() {
    return this.prescriptionFrm.get('DoctorName');
  }

  get StartDate() {
    return this.prescriptionFrm.get('StartDate');
  }

  get EndDate() {
    return this.prescriptionFrm.get('EndDate');
  }

  get Status() {
    return this.prescriptionFrm.get('Status');
  }
  get MorningTime() {
    return this.prescriptionFrm.get('MorningTime');
  }
  get AfternoonTime() {
    return this.prescriptionFrm.get('AfternoonTime');
  }
  get EveningTime() {
    return this.prescriptionFrm.get('EveningTime');
  }
  get NightTime() {
    return this.prescriptionFrm.get('NightTime');
  }
  get QTime() {
    return this.prescriptionFrm.get('QTime');
  }
  get DoctorId() {
    return this.prescriptionFrm.get('DoctorId');
  }
  get DoctorSignature() {
    return this.prescriptionFrm.get('DoctorSignature');
  }
  get Quantity() {
    return this.prescriptionFrm.get('Quantity');
  }
  get DrugName() {
    return this.prescriptionFrm.get('DrugName');
  }
  get Strength() {
    return this.prescriptionFrm.get('Strength');
  }
  get DosageExtn() {
    return this.prescriptionFrm.get('DosageExtn');
  }

  get BrandName() {
    return this.prescriptionFrm.get('BrandName');
  }
  get DatePrescribed() {
    return this.prescriptionFrm.get('DatePrescribed');
  }

  get Instruction() {
    return this.prescriptionFrm.get('Instruction');
  }

  get Note() {
    return this.prescriptionFrm.get('Note');
  }

  getMedicine() {
    this.medicineService.getMedicines().subscribe((res) => {
      this.medicineList = res;
    });
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  getPharmacy(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((res) => {
      this.pharmacy = res.PatientsPharmacys;
    });
  }
  getAllergies(id) {
    this.patientService.getPatientAllergy(id).subscribe((res) => {
      this.allergies = res.Allergies;
    });
  }
  daRequiredDrugs: string[] = [];

  public controlledDrugs() {
    this.http.get('/assets/json/ControlledSubstance.json').subscribe({
      next: (res: any) => {
        this.daRequiredDrugs = res.map((x) => x.SUBSTANCE);
        // console.log(this.daRequiredDrugs);
      },
    });
  }
  daApprovalResult: string = '';
  daCode: any; // The DA code entered by the user
  isDARequired: boolean = false; // Flag to track if DA approval is required
  daValidationMessage: string = ''; // DA validation message

  getSelectedDrug(selectedDrug) {
    // Normalize selectedDrug by trimming spaces and converting to lowercase
    const normalizedSelectedDrug = selectedDrug.trim().toLowerCase();

    const patientAllergies = this.allergies.map((x) => x.AllergicTo);

    // Function to extract the base drug name from the selectedDrug
    const extractBaseDrugName = (drug) => {
      // Remove common qualifiers (e.g., strength, dosage) using a regular expression
      // For simplicity, we remove any numbers, units, and extra info after the base drug name
      return drug
        .replace(/[\d\w\s]+$/, '')
        .trim()
        .toLowerCase();
    };

    // Extract the base name of the selected drug
    const baseDrugName = extractBaseDrugName(normalizedSelectedDrug);

    // console.log('Base drug name extracted:', baseDrugName);

    // Check if any of the patient's allergies contain the base drug name
    const isAllergic = patientAllergies.some((allergy) =>
      allergy.toLowerCase().includes(baseDrugName)
    );

    const resultElement = document.getElementById('result');

    // Update the result element based on whether the patient is allergic or not
    if (isAllergic) {
      resultElement.textContent = `Patient is allergic to ${selectedDrug}`;
      resultElement.style.color = 'red'; // Optional: change text color for emphasis
    } else {
      resultElement.textContent = 'Patient has no allergies to selected drug';
      resultElement.style.color = 'green'; // Optional: change text color for success
    }

    this.isDARequired = this.daRequiredDrugs.some((drug) =>
      drug.toLowerCase().includes(baseDrugName)
    );
    // Set the DA approval result message
    if (this.isDARequired) {
      this.daApprovalResult = `DA approval is required for ${selectedDrug}`;
    } else {
      this.daApprovalResult = `${selectedDrug} does not require DA approval`;
    }
  }

  validateDACode() {
    event.preventDefault();
    const trimmedDACode = this.daCode.trim(); // Trim any leading/trailing whitespace
    console.log('Entered DA code:', this.daCode);
    const today = new Date().toISOString();
    const DeaDateIsValid = this.doctor.DeaExpiryDate >= today;
    const doctorDeaCode = this.doctor.DeaCode;
    // Check if the DA code is empty
    if (trimmedDACode === '') {
      this.daValidationMessage = 'DA code cannot be empty.';
    }
    // Validate if the DA code is correct
    else if (trimmedDACode === doctorDeaCode && DeaDateIsValid) {
      // Example valid code
      this.daValidationMessage = 'DA code is valid.';
      this.isDAvalidated = true;
    } else {
      this.daValidationMessage = 'Invalid DA code.';
    }
  }

  loginForm = this.fb.group({
    UserName: ['', [Validators.required]],
    Password: ['', Validators.required],
  });

  get UserName() {
    return this.logiForm.get('UserName');
  }
  get Password() {
    return this.logiForm.get('Password');
  }

  loginSubmit() {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe(
        (res) => {
          window.localStorage.setItem('token', res['token']);

          let currentToken = res['token'];

          let decodedToken = this.helper.decodeToken(currentToken);
          this.id = parseInt(decodedToken.nameid);
          this.showTwoFactorDialog(decodedToken);
          this.isDAvalidated = false;

          // this.router.navigate(['twofa', decodedToken]);
        },
        (err) => {
          this.error = true;
          this.netWorkFailure = err;
        }
      );
    } else {
      this.IsthrowError =
        'Please review and check privacy policy to continue login';
    }
  }
  // Open 2FA dialog for entering the 2FA code
  showTwoFactorDialog(decodedToken: string) {
    const dialogRef = this.dialog.open(TwofaDeaComponent, {
      data: { decodedToken },
    });
  }

  formSubmit() {
    let nooftablets =
      this.prescriptionFrm.value['NoOfTablets'] +
      this.prescriptionFrm.value['DosageExtn'];
    let qty =
      this.prescriptionFrm.value['Quantity'] +
      this.prescriptionFrm.value['DosageExtn'];

    let body = {
      ...this.prescriptionFrm.value,
      PatientId: this.id,
      DoctorId: this.doctorId,
      Status: true,
      DoctorName: this.doctor.DoctorName,
      NpiNumber: this.doctor.NpiNumber,
      StartDate: new Date().toISOString().substring(0, 10),
      DatePrescribed: new Date().toISOString().substring(0, 10),
      NoOfTablets: nooftablets,
      Quantity: qty,
      isDAvalidated: this.isDAvalidated,
      // Strength: dosage,
    };
    // console.log(body);
    this.prescriptionService.registerPrescription(body).subscribe((res) => {
      this.isSuccess = true;
      this.prescriptionFrm.reset();
    });
  }
  // location.reload();

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  private _filter2(value: string): string[] {
    const x = value.toLowerCase();

    return this.instructionList.filter((ins) => ins.toLowerCase().includes(x));
  }

  private _filter3(value: string): string[] {
    const y = value.toLowerCase();

    return this.frequencyList.filter((fc) => fc.toLowerCase().includes(y));
  }

  private _filter4(value: string): string[] {
    const cx = value.toLowerCase();

    return this.refill.filter((gg) => gg.toLowerCase().includes(cx));
  }
  private _filter5(value: string): string[] {
    const ds = value.toLowerCase();

    return this.dose.filter((aa) => aa.toLowerCase().includes(ds));
  }
  private _filter6(value: string): string[] {
    const ftb = value.toLowerCase();

    return this.noOfTablet.filter((aa) => aa.toLowerCase().includes(ftb));
  }
  private _filter7(value: string): string[] {
    const fext = value.toLowerCase();

    return this.drugExtention.filter((aa) => aa.toLowerCase().includes(fext));
  }

  public getFrequency(e) {
    if (e.includes('Once a day')) {
      this.frequencyValue = 1;
    } else if (e.includes('Twice a day')) {
      this.frequencyValue = 2;
    } else if (e.includes('Twice a day')) {
      this.frequencyValue = 2;
    } else if (e.includes('Three times a day')) {
      this.frequencyValue = 3;
    } else if (e.includes('Four times a day')) {
      this.frequencyValue = 4;
    } else if (e.includes('Every night at bedtime')) {
      this.frequencyValue = 1;
    } else if (e.includes('Every 4 hours')) {
      this.frequencyValue = 6;
    } else if (e.includes('Every 6 hours')) {
      this.frequencyValue = 4;
    } else if (e.includes('Every 8 hours')) {
      this.frequencyValue = 3;
    } else if (e.includes('Every 12 hours')) {
      this.frequencyValue = 2;
    } else {
      this.frequencyValue = 1;
    }
    // console.log(this.frequencyValue);
    this.getGrandTotal();

    // this.invForm.get('Total').setValue(total);
    // this.SubTotal.setValue(total);
  }
  getRefillValue(v) {
    if (v.includes('30 Days')) {
      this.refillValue = 30;
    } else if (v.includes('90 Days')) {
      this.refillValue = 90;
    } else if (v.includes('6 Months')) {
      this.refillValue = 180;
    } else if (v.includes('1 Year')) {
      this.refillValue = 365;
    } else if (v.includes('1 Week')) {
      this.refillValue = 7;
    } else if (v.includes('2 Week')) {
      this.refillValue = 14;
    } else if (v.includes('3 Week')) {
      this.refillValue = 21;
    } else if (v.includes('5 Week')) {
      this.refillValue = 35;
    } else if (v.includes('6 Week')) {
      this.refillValue = 42;
    }
    // console.log(this.refillValue);
    this.getGrandTotal();
    this.setEndDate();
  }
  getNoOfTabletValue(tb) {
    if (tb.includes('1')) {
      this.tabLets = 1;
    } else if (tb.includes('2')) {
      this.tabLets = 2;
    } else if (tb.includes('3')) {
      this.tabLets = 3;
    } else if (tb.includes('4')) {
      this.tabLets = 4;
    } else if (tb.includes('5')) {
      this.tabLets = 5;
    } else if (tb.includes('6')) {
      this.tabLets = 6;
    } else if (tb.includes('7')) {
      this.tabLets = 7;
    } else if (tb.includes('8')) {
      this.tabLets = 8;
    } else if (tb.includes('9')) {
      this.tabLets = 9;
    } else if (tb.includes('10')) {
      this.tabLets = 10;
    }
    // console.log(this.tabLets);
    this.getGrandTotal();
  }
  getDrugValue(dr) {
    if (dr.includes('5')) {
      this.drgNumber = 5;
    } else if (dr.includes('10')) {
      this.drgNumber = 10;
    } else if (dr.includes('15')) {
      this.drgNumber = 15;
    } else if (dr.includes('20')) {
      this.drgNumber = 20;
    } else if (dr.includes('25')) {
      this.drgNumber = 25;
    } else if (dr.includes('30')) {
      this.drgNumber = 30;
    } else if (dr.includes('40')) {
      this.drgNumber = 40;
    } else if (dr.includes('50')) {
      this.drgNumber = 50;
    } else if (dr.includes('60')) {
      this.drgNumber = 60;
    } else if (dr.includes('75')) {
      this.drgNumber = 75;
    } else if (dr.includes('70')) {
      this.drgNumber = 70;
    } else if (dr.includes('80')) {
      this.drgNumber = 80;
    } else if (dr.includes('90')) {
      this.drgNumber = 90;
    } else if (dr.includes('100')) {
      this.drgNumber = 100;
    }
    if (dr.includes('150')) {
      this.drgNumber = 150;
    } else if (dr.includes('200')) {
      this.drgNumber = 200;
    } else if (dr.includes('250')) {
      this.drgNumber = 250;
    } else if (dr.includes('300')) {
      this.drgNumber = 300;
    } else if (dr.includes('400')) {
      this.drgNumber = 400;
    } else if (dr.includes('500')) {
      this.drgNumber = 500;
    } else if (dr.includes('600')) {
      this.drgNumber = 600;
    } else if (dr.includes('700')) {
      this.drgNumber = 700;
    } else if (dr.includes('750')) {
      this.drgNumber = 750;
    } else if (dr.includes('800')) {
      this.drgNumber = 800;
    } else if (dr.includes('900')) {
      this.drgNumber = 900;
    } else if (dr.includes('1000')) {
      this.drgNumber = 1000;
    }
    // console.log(this.tabLets);
    this.getGrandTotal();
  }

  getGrandTotal() {
    let total = 0;
    // let x = this.frequencyValue;
    // let y = this.refillValue;

    // console.log(this.frequencyValue);

    // console.log(this.refillValue);

    total = this.frequencyValue * this.refillValue * this.tabLets;
    // console.log(total);
    this.Quantity.setValue(total);
  }
  endDate(e) {
    this.noRefills = e.target.value;
    // this.prescriptionFrm.patchValue({ EndDate });
    this.setEndDate();
  }

  setEndDate() {
    let x = 0;
    x = this.noRefills * this.refillValue;
    let currentDate = moment();
    let futureDate = currentDate.add(x, 'days');
    this.EndDate.setValue(futureDate.format('YYYY-MM-DD'));
  }

  getExtValue(e) {
    this.ext = e;
    this.DosageExtn.setValue(e);
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.prescriptionFrm;
    }
  }
}
