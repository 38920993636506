import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DischargeSummary } from 'src/app/Models/dischargesummary.model';
import { Patient } from 'src/app/Models/patient.model';
import { DischargeSummaryService } from 'src/app/services/discharge-summary.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-discharge-summary',
  templateUrl: './view-discharge-summary.component.html',
  styleUrls: ['./view-discharge-summary.component.css']
})
export class ViewDischargeSummaryComponent implements OnInit {
id=0
displayedColumns: string[] = ['Date', 'View','Edit','Delete'];
dataSource: MatTableDataSource<DischargeSummary>;
patient:Patient

dischargeSummaryList:DischargeSummary[]=[];
  constructor(private route:ActivatedRoute,private patientService:PatientService ,private dischargeSummaryService:DischargeSummaryService, private tokenService: TokenService){}


  ngOnInit(): void {
    this.route.params.subscribe(res=>{
      this.id = res['id']
    })
this.getDischargeSummary(this.id);
this.getPatient(this.id)
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  
getDischargeSummary(id){
  this.patientService.getdischargeSummary(id).subscribe(res=>{
    this.dataSource = new MatTableDataSource(res.DischargeSummaries);
    this.dataSource.paginator=this.paginator;
 this.dischargeSummaryList = res.DischargeSummaries
  })
}


getPatient(id) {
  this.patientService.getPatientById(id).subscribe(res => {
    this.patient = res;
  })
}





print(){
  window.print();
}






applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();

  if (this.dataSource.paginator) {
    this.dataSource.paginator.firstPage();
  }
}

deleteDpt(id){
  this.dischargeSummaryService.deleteDischargeSummary(id).subscribe(res=>{
    // this.dptList=this.dptList.filter(x=>x.DepartmentId !=id)
    this.getDischargeSummary(this.id);
  })
}
}
