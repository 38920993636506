import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { subscribeOn } from 'rxjs/operators';
import { Nurse } from 'src/app/Models/nurse.model';
import { NurseNote } from 'src/app/Models/nursenote.model';
import { NurseNoteService } from 'src/app/services/nurse-note.service';
import { NurseService } from 'src/app/services/nurse.service';
import { VoiceRecognitionObjectiveService } from 'src/app/services/voice-recognition-objective.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-nurse-note',
  templateUrl: './update-nurse-note.component.html',
  styleUrls: ['./update-nurse-note.component.css'],
})
export class UpdateNurseNoteComponent implements OnInit {
  id = 0;
  reportList: NurseNote[] = [];
  isSuccess = false;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  UserId: number = 0;
  nurse: Nurse;
  listening: boolean;
  listeningReportName: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private nurseNoteService: NurseNoteService,
    private route: ActivatedRoute,
    public service: VoiceRecognitionService,
    public serviceObjective: VoiceRecognitionObjectiveService,
    private nurseService: NurseService,
    private router: Router
  , private tokenService: TokenService) {
    this.service.init();
    this.serviceObjective.init();
  }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.UserId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.nurseNoteService.getNurseNoteById(this.id).subscribe((res) => {
      this.Frm.patchValue(res);
    });

    this.getNurse(this.UserId);
  }

  Frm = this.fb.group({
    ReportName: ['', Validators.required],
    ReportDescription: ['', Validators.required],
  });

  getReportName() {
    return this.Frm.get('ReportName');
  }

  getReportDescription() {
    return this.Frm.get('ReportDescription');
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getReportName();
    }
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }

  startService() {
    this.service.start();
    this.listening = true;
  }

  stopService() {
    this.service.stop();
    this.listening = false;
  }

  onSubjectiveChange(e) {
    this.service.message1 = e.target.value;
  }

  onSubjectiveBlur() {
    this.stopService();
    this.stopServiceObjective();
  }

  startServiceObjective() {
    this.serviceObjective.startObjective();
    this.listeningReportName = true;
  }

  stopServiceObjective() {
    this.serviceObjective.stopObjective();
    this.listeningReportName = false;
  }

  onObjectiveChange(e) {
    this.serviceObjective.Objective = e.target.value;
  }

  onObjectiveBlur() {
    this.stopServiceObjective();
    this.stopService();
  }
  stopAll() {
    this.stopServiceObjective();
    this.stopService();
  }

  formSubmit() {
    let ReportName = this.Frm.value['ReportName'];
    let reportDescription = this.Frm.value['ReportDescription'];

    let body = {
      NurseId: this.UserId,
      ReportName: ReportName,
      ReportDescription: reportDescription,
      NurseName: this.nurse.NurseName,
    };

    this.nurseNoteService.updateNurseNote(this.id, body).subscribe((res) => {
      this.isSuccess = true;
      this.Frm.reset();
      this.router.navigate(['/nurseDash/']);
    });
  }
}
