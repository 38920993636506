import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService) {}
  // intercept(
  //   request: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   const token: string = localStorage.getItem('token');
  //   // if (token) {
  //   //   request = request.clone({
  //   //     headers: request.headers.set('Authorization', 'Bearer ' + token),
  //   //   });
  //   // }
  //   if (
  //     (token &&
  //       request.url !== 'https://api.authorize.net/xml/v1/request.api') ||
  //     request.url !== 'https://api.stripe.com/v1/charges'
  //   ) {
  //     if (!request.url.includes('inputtools')) {
  //       request = request.clone({
  //         headers: request.headers.set('Authorization', 'Bearer ' + token),
  //       });
  //     } else {
  // request = request.clone({
  //   headers: request.headers.set('content-type', 'application/json'),
  // });
  //     }
  //   }
  //   // if (request.url.includes('https://firebasestorage.googleapis.com/')) {
  //   //     request = request.clone({ headers: request.headers.set('Acess-Control-Allow-Origin', '*') });
  //   // }

  //   //     request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token)
  //   //     .set('Acess-Control-Allow-Origin', '*')
  //   //     .set("Access-Control-Allow-Credentials", "true")
  //   //     .set("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT")
  //   //     .set("Access-Control-Allow-Headers", "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers")

  //   // })

  //   // if (!request.headers.has('Content-Type')) {
  //   //     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
  //   // }

  //   // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

  //   return next.handle(request).pipe(
  //     map((event: HttpEvent<any>) => {
  //       if (event instanceof HttpResponse) {
  //         // console.log('event--->>>', event);
  //       }
  //       return event;
  //     })
  //   );
  // }

  /**
   * Checks if Api request header is required or not.
   * If request header is required then set headers with token in the Api request.
   *
   * @param request Request
   * @param next in flight HttpRequest
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.tokenService.getToken();
    if (this.isHeaderRequired(request)) {
      request = this.setRequestHeader(request);
    }

    request = request.clone({
      headers: request.headers,
      // .set('content-type', 'application/json')
      // .set('Content-Type', 'multipart/form-data'),
    });
    // if (token) {
    //   const cloned = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    //   return next.handle(cloned);
    // }
    return next.handle(request);
  }

  /**
   * Checks either request headers are required for current request or not.
   *
   * @param request: HttpRequest<any> request API request to be made
   * @returns boolean
   */
  private isHeaderRequired(request: HttpRequest<any>): boolean {
    return !(
      request.url.includes('https://localhost:46740/api/') ||
      request.url.includes('https://api.telnyx.com/v2/media') ||
      request.url.includes('https://api.telnyx.com/v2/faxes') ||
      request.url.includes('https://api.stripe.com/v1/charges') ||
      request.url.includes('https://api.authorize.net/xml/v1/request.api') ||
      request.url.includes('amazonaws') ||
      request.url.includes('inputtools')
    );
  }

  /**
   * Set headers with authentication token in API request.
   * If the access token didn't expire, add the Authorization header. We won't add the Authorization header if the access token expired.
   * This will force the server to return a "401 Unauthorized" response for the protected API routes which our response interceptor will
   * catch and delete the access token from the local storage while logging the user out from the app.
   *
   * @param request<any> request API request to be made
   * @returns HttpRequest<any> Request with/without headers
   */
  // private setRequestHeader(request: HttpRequest<any>): HttpRequest<any> {
  //   const currentToken = localStorage.getItem('token');
  //   if (currentToken?.length) {
  //     const httpOptions = {
  //       headers: new HttpHeaders({
  //         authorization: `Bearer ${currentToken}`,
  //       }),
  //     };

  //     request = request.clone(httpOptions);
  //   }

  //   return request;
  // }

  private setRequestHeader(request: HttpRequest<any>): HttpRequest<any> {
    const currentToken = this.tokenService.getToken();

    if (currentToken?.length) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentToken}`,
        },
      });
    }

    return request;
  }
}
