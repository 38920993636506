import { Component } from '@angular/core';

@Component({
  selector: 'app-favorite-dignosis-code',
  templateUrl: './favorite-dignosis-code.component.html',
  styleUrls: ['./favorite-dignosis-code.component.css']
})
export class FavoriteDignosisCodeComponent {

}
