<div class="ml-3">
  <div class="row">
    <div class="col-lg-6 float-right">
      <mat-form-field class="example-full-width mt-5">
        <mat-label>Patient Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
      </mat-form-field>
    </div>
  </div>
</div>
<div class=" mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>


    <ng-container matColumnDef="Id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let row"> {{row.PatientId}} </td>
    </ng-container>


    <ng-container matColumnDef="FirstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
      <td mat-cell *matCellDef="let row"> {{row.PatientFirstName}} </td>
    </ng-container>
    <ng-container matColumnDef="LastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
      <td mat-cell *matCellDef="let row"> {{row.PatientLastName}} </td>
    </ng-container>
    <ng-container matColumnDef="Gender">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
      <td mat-cell *matCellDef="let row"> {{row.PatientGender}} </td>
    </ng-container>
    <ng-container matColumnDef="PatientNationalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> National Id </th>
      <td mat-cell *matCellDef="let row"> {{row.PatientNationalId}} </td>
    </ng-container>
    <ng-container matColumnDef="Email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let row"> {{row.Email}} </td>
    </ng-container>
    <ng-container matColumnDef="PhoneNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> PhoneNumber </th>
      <td mat-cell *matCellDef="let row"> {{row.PhoneNumber}} </td>
    </ng-container>
    <ng-container matColumnDef="PrimaryDoctor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Doctor </th>
      <td mat-cell *matCellDef="let row"> {{row.PrimaryDoctorsName}} </td>
    </ng-container>
    <ng-container matColumnDef="WardName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ward </th>
      <td mat-cell *matCellDef="let row"> {{row.WardName}} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="RoomNo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Room No </th>
      <td mat-cell *matCellDef="let row"> {{row.RoomNo}} </td>
    </ng-container> -->
    <ng-container matColumnDef="visit">
      <th mat-header-cell *matHeaderCellDef> Call Encounter</th>
      <td mat-cell *matCellDef="let row">
        <a type="button" target="_blank" [routerLink]="['/callEncounter/',row.PatientId]" mat-mini-fab color="primary">
          <mat-icon matPrefix>border_clear</mat-icon>
        </a>
      </td>
    </ng-container>
    <ng-container matColumnDef="Detail">
      <th mat-header-cell *matHeaderCellDef>Office Visit Encounter </th>
      <td mat-cell *matCellDef="let row">
        <!-- <button class="btn btn-primary"
          (click)="openAddAppointmentModal(row.PatientId,row.PatientFirstName,row.PatientLastName)"><mat-icon>schedule</mat-icon></button> -->
        <a target="_blank" [routerLink]="['/splitView/',row.PatientId]" mat-mini-fab color="primary">
          <mat-icon matPrefix>remove_red_eye</mat-icon>
        </a>
        <!-- <a target="_blank" [routerLink]="['/splitScreenDctorPatient/',row.PatientId]" mat-mini-fab color="primary">
          <mat-icon matPrefix>remove_red_eye</mat-icon>
        </a> -->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>