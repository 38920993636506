<div class="container">
    <h1>Search Patients</h1>

    <!-- Input Fields for National ID and Phone Number -->
    <div class="form-group">
        <label for="nationalId">National ID:</label>
        <input type="text" id="nationalId" class="form-control" [(ngModel)]="nationalId"
            placeholder="Enter National ID" />
    </div>
    <h2>Or</h2>
    <div class="form-group">
        <label for="phoneNumber">Phone Number:</label>
        <input type="text" id="phoneNumber" class="form-control" [(ngModel)]="phoneNumber"
            placeholder="Enter Phone Number eg: +13460090910" />
    </div>

    <!-- Search Button -->
    <button type="button" class="btn btn-primary" color="primary" mat-raised-button
        (click)="searchPatients()"><mat-icon>search</mat-icon>Search</button>

    <!-- Display Patients -->
    <div *ngIf="patients.length > 0">
        <h2>Patient List</h2>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>MRN</th>
                    <th>Patient Name</th>
                    <th>National ID</th>
                    <th>Phone Number</th>
                    <th>Address</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let patient of patients">
                    <td>{{ patient.PatientId }}</td>
                    <td>{{ patient.PatientFirstName }} {{ patient.PatientLastName }}</td>
                    <td>{{ patient.PatientNationalId }}</td>
                    <td>{{ patient.PhoneNumber }}</td>
                    <td>{{ patient.PatientAddress }}</td>
                    <td>
                        <a target="_blank" [routerLink]="['/splitView/',patient.PatientId]" mat-mini-fab
                            color="primary">
                            <mat-icon matPrefix>remove_red_eye</mat-icon>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Error Message -->
    <div *ngIf="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
    </div>
</div>