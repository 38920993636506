<div class="container mt-5">
    <!-- <div class="row mt-3">
        <div class="col-lg-3 ">

            Patient Name : {{patient?.PatientFirstName}}
            {{patient?.PatientLastName}},
        </div>
        <div class="col-lg-5">

            D.O.B : {{patient?.PatientDOB}},
            Patient No : {{patient?.PatientId}}

        </div>
        <div class="col-lg-4"></div>
    </div> -->
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>Physician Initial Evaluation</h2>
            <hr>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">

                <div class="row">
                    <div class="col-lg-3">
                        <mat-label> <strong>Patient Name:</strong> {{patient?.PatientFirstName +
                            patient?.PatientLastName}}</mat-label>

                        <!-- <mat-form-field class="example-full-width">
                            <mat-label>Patient Name</mat-label>
                            <input type="text" formControlName="PatientName" matInput>
                            <mat-error>Please enter Patient Name</mat-error>
                        </mat-form-field> -->
                    </div>
                    <div class="col-lg-3">
                        <mat-label><strong>Patient D.O.B:</strong> {{patient?.PatientDOB}}</mat-label>

                        <!-- <mat-form-field class="example-full-width">
                            <mat-label>Patient D.O.B (mm/dd/yyyy)</mat-label>
                            <input type="text" formControlName="PatientDOB" matInput>
                            <mat-error>Please enter Patient Date of Birth</mat-error>
                        </mat-form-field> -->
                    </div>
                    <div class="col-lg-3">
                        <mat-label><strong>Height:</strong> {{patient?.PatientHeight}}</mat-label>

                        <!-- <mat-form-field class="example-full-width">
                            <mat-label>Patient Height</mat-label>
                            <input type="text" formControlName="PatientHeight" matInput>
                            <mat-error>Please enter Patient Height</mat-error>
                        </mat-form-field> -->
                    </div>
                    <div class="col-lg-3">
                        <mat-label><strong>Weight:</strong>{{patient?.PatientWeight}}</mat-label>

                        <!-- <mat-form-field class="example-full-width">
                            <mat-label>Patient Weight</mat-label>
                            <input type="text" formControlName="PatientWeight" matInput>
                            <mat-error>Please enter Patient Weight</mat-error>
                        </mat-form-field> -->
                    </div>

                </div>
                <hr>
                <div class="row">
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Blood Pressure</mat-label>
                            <input type="text" formControlName="BloodPressure" matInput>
                            <mat-error>Please enter Blood Pressure</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Pulse</mat-label>
                            <input type="text" formControlName="Pulse" matInput>
                            <mat-error>Please enter Pulse</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Is Patient on Any Medication</mat-label>
                            <input type="text" formControlName="IsPatientonAnyMedication" matInput>
                            <mat-error>Please enter Is Patienton Any Medication</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Is Patient Under Any Medical Treatment</mat-label>
                            <input type="text" formControlName="IsPatientUnderAnyMedicalTreatment" matInput>
                            <mat-error>Please enter Is Patient Under Any Medical Treatment</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <h2>Refferring Physician(if any)</h2>
                <div class="row">
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Refferring Physician Name</mat-label>
                            <input type="text" formControlName="RefferringPhysicianName" matInput>
                            <mat-error>Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Refferring Physician Address</mat-label>
                            <input type="text" formControlName="RefferringPhysicianAddress" matInput>
                            <mat-error>Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Refferring Physician Phone</mat-label>
                            <input type="text" formControlName="RefferringPhysicianPhone" matInput>
                            <mat-error>Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>PrimaryCareDoctorName</mat-label>
                            <input type="text" formControlName="PrimaryCareDoctorName" matInput>
                            <mat-error>Please enter Primary Care DoctorName</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <hr>
                <h2> CHIEF COMPLAINT/REASON FOR VISIT
                </h2>
                <div class="row">
                    <div class="col-lg-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>What is the reasonfor yourvisit today?</mat-label>
                            <input type="text" formControlName="Whatisthereasonforyourvisittoday" matInput>
                            <mat-error>Please enter a vlaue</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Is this visit a work related injury</mat-label>
                            <input type="text" formControlName="Isthisvisitrelatedworkrelatedinjury" matInput>
                            <mat-error>Please enter a value</mat-error>
                        </mat-form-field>
                    </div>


                </div>
                <div class="row">

                    <div class="col-lg-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Is this visit related to a motorvehicle accident</mat-label>
                            <input type="text" formControlName="Isthisvisitrelatedtoamotorvehicleaccident" matInput>
                            <mat-error>Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Are you experiencing any pain</mat-label>
                            <input type="text" formControlName="Areyouexperiencinganypain" matInput>
                            <mat-error>Please enter a value</mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Pain Location</mat-label>
                            <input type="text" formControlName="PainLocation" matInput>
                            <mat-error>Please enter a vlaue</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Level Of Pain</mat-label>
                            <!-- <input type="text" formControlName="LevelOfPain" matInput> -->
                            <mat-select formControlName="LevelOfPain">
                                <mat-option *ngFor="let lp of painLevel" [value]="lp.value">{{lp.value}}</mat-option>
                            </mat-select>
                            <mat-error>Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Date Of Symtoms Began</mat-label>
                            <input type="date" formControlName="DateOfSymtomsBegan" matInput>
                            <mat-error>Please enter a value</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Severity Of Symptoms</mat-label>
                            <!-- <input type="text" formControlName="SeverityOfSymptoms" matInput> -->
                            <mat-select formControlName="SeverityOfSymptoms">
                                <mat-option *ngFor="let sp of seveierityOfpain"
                                    [value]="sp.value">{{sp.value}}</mat-option>
                            </mat-select>
                            <mat-error>Please enter a value</mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <h2>Location of Pain</h2>
                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="LowerBack">
                                Lower Back
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Neck">
                                Neck
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="LeftArm">
                                Left Arm
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RightArm">
                                Right Arm
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="LeftLeg">
                                Left Leg
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RightLeg">
                                Right Leg
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="LeftHip">
                                Left Hip
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RightHip">
                                Right Hip
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="LeftShoulder">
                                Left Shoulder
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RightShoulder">
                                Right Shoulder
                            </mat-checkbox>
                        </div>
                        <div class="col-md-1">
                            <mat-checkbox color="primary" formControlName="NoPain">
                                NoPain
                            </mat-checkbox>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other Pain</mat-label>
                                <textarea matInput formControlName="OtherPain" cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                    cdkAutosizeMaxRows="100"></textarea>

                                <!-- 
                                <mat-error *ngIf="OtherPain.invalid && OtherPain.touched">Please enter
                                    Comment's</mat-error> -->
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <h2>Quality of Pain</h2>
                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Ache">
                                Ache
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Burning">
                                Burning
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Deep">
                                Deep
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Discomforting">
                                Discomforting
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Diffuse">
                                Diffuse
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Dull">
                                Dull
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Localized">
                                Localized
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Numbness">
                                Numbness
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Throbbing">
                                Throbbing
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Piercing">
                                Piercing
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Sharp">
                                Sharp
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Shooting">
                                Shooting
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <h2>Radiation of Pain</h2>
                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingLowerBack">
                                Radiating Lower Back
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingNeck">
                                Radiating Neck
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingLeftArm">
                                Radiating Left Arm
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingRightArm">
                                Radiating Right Arm
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingLeftLeg">
                                Radiating Left Leg
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingRightLeg">
                                Radiating Right Leg
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingLeftHip">
                                Radiating Left Hip
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingRightHip">
                                Radiating Right Hip
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingLeftShoulder">
                                Radiating Left Shoulder
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingRightShoulder">
                                Radiating Right Shoulder
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingOtherPain">
                                Radiating Other Pain
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RadiatingNoPain">
                                Radiating No Pain
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <h2>Constitutional/General</h2>
                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RecentWeightChanges">
                                Recent Weight Changes
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Chills">
                                Chills
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Fever">
                                Fever
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Weakness">
                                Weakness
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoGeneralProblems">
                                No Other Problems
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other Problems</mat-label>
                                <input type="text" formControlName="OtherGeneralProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <h2>Eyes
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="VisionChange">
                                Vision Change
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="GlassessOrContacts">
                                Glassess/Contacts
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Catracts">
                                Catracts
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="Glaucoma">
                            Glaucoma
                        </mat-checkbox>
                    </div>
                    <div class="col-lg-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoEyeProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherEyeProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Ear,Nose,Throat
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="HearingLoss">
                                Hearing Loss
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="EarAcheOrInfection">
                                EarAche/Infection
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RingingInEar">
                                Ringing In Ear
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-lg-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoEarProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherEarProblems" matInput>
                            </mat-form-field>
                        </div>

                    </div>
                </div>

                <h2>Cardio Vascular
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="ChestPain">
                                Chest Pain
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="SwellingInLegs">
                                Swelling In Legs
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Palpitations">
                                Palpitations
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoCadioVascularProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherCadioVascularProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Respiratory
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="ShortnessOfBreath">
                                Shortness Of Breath
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="WheezingOrAsthma">
                                Wheezing Or Asthma
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="FrequentCough">
                                Frequent Cough
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoRespiratoryProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherRespiratoryProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Gastrointestinal
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="AcidReflex">
                                Acid Reflex
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NauseaOrVomitimng">
                                Nausea Or Vomitimng
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="AbdominalPain">
                                Abdominal Pain
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoGastricProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherGastricProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Muskulatal
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="MuscleAches">
                                Muscle Aches
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="SwellingOfJoints">
                                Swelling Of Joints
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="StiffnessInJoints">
                                Stiffness In Joints
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoMuskularProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherMuskularProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Skin
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Rash">
                                Rash
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Ulcers">
                                Ulcers
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="AbnormalScars">
                                Abnormal Scars
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoSkinProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherSkinProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Neurological
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Headaches">
                                Headaches
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Dizziness">
                                Dizziness
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NumbnessTinglingLossofSensation">
                                Numbness, Tingling ,Loss of Sensation
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoNeurologicalProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherNeurologicalProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Psychiatric
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Depression">
                                Depression
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Nervousness">
                                Nervousness
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Anxiety">
                                Anxiety
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="MoodSwings">
                                MoodSwings
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoPsychatricProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherPsychatricProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Endocrine
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="ExessiveThirstOrHunger">
                                Exessive Thirst Or Hunger
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="HotColdIntolerance">
                                Hot Cold Intolerance
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="HotFlashes">
                                Hot Flashes
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoEndocrine">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherEndocrine" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Pain Management
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-6">
                            <mat-checkbox color="primary" formControlName="Paralysis">
                                Paralysis
                            </mat-checkbox>
                        </div>
                        <div class="col-md-6">
                            <mat-checkbox color="primary" formControlName="Areyouonbloodthinners">
                                Are you on blood thinners
                            </mat-checkbox>
                        </div>

                    </div>
                    <div class="col-md-6">
                        <div class="col-md-4">
                            <mat-checkbox color="primary"
                                formControlName="Areyouunderthecareofapainmanagementphysician">
                                Are you under the care of a painmanagement physician
                            </mat-checkbox>
                        </div>
                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Pain Management Physician </mat-label>
                                <input type="text" formControlName="PainManagementPhysician" matInput>
                            </mat-form-field>
                        </div>

                    </div>


                </div>
                <button class="float-right lgBtCl" mat-button>
                    <!-- <mat-icon>save</mat-icon> -->
                    Save</button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Prescription
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>