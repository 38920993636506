import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent  implements OnInit{
  isSuccess=false;


  constructor(private fb:UntypedFormBuilder,private userService:UserService, private tokenService: TokenService){}

  ngOnInit() {

  }


  addFrm = this.fb.group({
    UserName:['',Validators.required],
    CompanyName:['',Validators.required],
    Email:['',Validators.required],
    Password:['',Validators.required],
    PhoneNumber:['',Validators.required],
    Address:['',Validators.required],
    ContactPerson:['',Validators.required],

  })

  get UserName(){
  return this.addFrm.get('UserName')
  }
  get CompanyName(){
  return this.addFrm.get('CompanyName')
  }
  get Email(){
  return this.addFrm.get('Email')
  }
  get Password(){
  return this.addFrm.get('Password')
  }
  get PhoneNumber(){
  return this.addFrm.get('PhoneNumber')
  }
  get Address(){
  return this.addFrm.get('Address')
  }
  get ContactPerson(){
  return this.addFrm.get('ContactPerson')
  }

  formSubmit(){

    this.userService.registerCompanyAdmin(this.addFrm.value).subscribe(res=>{
      this.isSuccess=true;
      this.addFrm.reset();
    })

  }
}
