<mat-tab-group (selectedTabChange)="tabChanged($event)" #tabGroup>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">pill</mat-icon>
      New Prescription
    </ng-template>
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>New Prescription</h1>

      <form (keydown.enter)="$event.preventDefault()" [formGroup]="prescriptionForm"
        (keydown.enter)="$event.preventDefault()" (ngSubmit)="submitForm()">
        <div formArrayName="prescriptions">
          <div *ngFor="let prescription of prescriptions.controls; let i = index" [formGroupName]="i">
            <div class="row">
              <div class="col">
                <p id="result"></p>

              </div>
              <div class="col">
                <div *ngIf="daApprovalResult"
                  [ngStyle]="{'color': daApprovalResult.includes('required') ? 'orange' : 'blue'}">
                  <p>{{ daApprovalResult }}</p>
                </div>

                <div *ngIf="isDARequired">
                  <label for="daCode">Enter DEA Number:</label>
                  <input type="text" id="daCode" [ngModelOptions]="{standalone: true}" [(ngModel)]="daCode"
                    placeholder="Enter DA code">

                  <button type="button" (click)="validateDACode()">Validate DA Code</button>

                  <div *ngIf="daValidationMessage">
                    <p [ngStyle]="{'color': daValidationMessage.includes('valid') ? 'green' : 'red'}">
                      {{ daValidationMessage }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="isDAvalidated">

              <div class="col-lg-6 p-3  mat-elevation-z6">
                <form (keydown.enter)="$event.preventDefault()" [formGroup]="loginForm" (ngSubmit)="loginSubmit()"
                  class="example-form">
                  <mat-form-field class="example-full-width">
                    <mat-label>UserName</mat-label>
                    <input type="text" formControlName="UserName" matInput>
                  </mat-form-field>

                  <mat-form-field class="example-full-width">
                    <mat-label>Password</mat-label>
                    <input type="password" matInput formControlName="Password">
                  </mat-form-field>



                  <button class="float-right lgBtCl" mat-button color="primary">
                    <mat-icon>login</mat-icon>
                    Login</button>



                </form>
                <div *ngIf="error" class="alert alert-danger mt-2">{{netWorkFailure}}</div>

                <div *ngIf="IsthrowError" class="alert alert-danger mt-2">{{IsthrowError}}</div>
              </div>

            </div>
            <div class="row" *ngIf="isFavorite">
              <div class="col-lg-6">
                <mat-form-field class="example-full-width">
                  <mat-label>Drug Name</mat-label>
                  <input type="text" matInput formControlName="DrugName">
                </mat-form-field>
              </div>
            </div>
            <div class="form-row">
              <!-- Drug Name -->
              <div class="col-lg-5">
                <mat-form-field class="example-full-width">
                  <mat-label>Drug Name</mat-label>
                  <mat-select formControlName="DrugName">
                    <mat-option>
                      <lib-mat-select-search [list]="medicineList" [searchProperties]="['DrugName']"
                        (filtered)="onFilter($event, i)" (ngModelChange)="calculateQuantity(i); calculateEndDate(i)">
                      </lib-mat-select-search>
                    </mat-option>

                    <cdk-virtual-scroll-viewport [ngStyle]="{ height: '200px' }" itemSize="25">
                      <mat-option *cdkVirtualFor="let entry of filteredMedicineLists[i]" [value]="entry.DrugName"
                        (onSelectionChange)="getSelectedDrug(entry.DrugName, i)">
                        {{ entry.DrugName }}
                      </mat-option>
                    </cdk-virtual-scroll-viewport>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Amount Per Dose -->
              <div class="col-lg-3">

                <mat-form-field class="example-full-width">
                  <mat-label>Amount Per Dose</mat-label>
                  <mat-select formControlName="AmountPerDose" (selectionChange)="calculateQuantity(i)">
                    <mat-option *ngFor="let amount of amountPerDoseOptions" [value]="amount">
                      {{ amount }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- QuantityUnit -->
              <div class="col-lg-3">
                <mat-form-field class="example-full-width">
                  <mat-label>Units</mat-label>

                  <mat-error>Units required</mat-error>
                  <input type="text" matInput formControlName="Unit" [matAutocomplete]="auto6"> <mat-icon
                    matSuffix>search</mat-icon>
                  <mat-autocomplete #auto6="matAutocomplete">
                    <mat-option *ngFor="let ext of filteredExt | async" [value]="ext" #extTb
                      (onSelectionChange)="getExtValue(extTb.value)">
                      {{ ext }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>


              </div>


              <!-- Route -->
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Route</mat-label>
                  <input type="text" matInput formControlName="Route" [matAutocomplete]="autox2"> <mat-icon
                    matSuffix>search</mat-icon>
                  <mat-autocomplete #autox2="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error>Route
                    required</mat-error>

                </mat-form-field>
              </div>

              <!-- Frequency -->
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Frequency</mat-label>
                  <mat-select formControlName="Frequency" (selectionChange)="calculateQuantity(i)">
                    <mat-option *ngFor="let option of frequencyList" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

              </div>

              <!-- Refill Duration -->
              <div class="col-lg-4">

                <mat-form-field class="example-full-width">
                  <mat-label>Refill Duration</mat-label>
                  <mat-select formControlName="RefillDuration"
                    (selectionChange)="calculateQuantity(i); calculateEndDate(i)">
                    <mat-option *ngFor="let option of refillDurationList" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Number of Refills -->
              <div class="col-lg-4">

                <mat-form-field class="example-full-width">
                  <mat-label>Number Of Refills</mat-label>
                  <mat-select formControlName="NumberOfRefills"
                    (selectionChange)="calculateQuantity(i); calculateEndDate(i)">
                    <mat-option *ngFor="let option of NumberOfRefillsList" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Number Of Refills</mat-label>
                  <input type="text" matInput formControlName="NumberOfRefills" [matAutocomplete]="autoR"> <mat-icon
                    matSuffix>search</mat-icon>
                  <mat-autocomplete #autoR="matAutocomplete">
                    <mat-option *ngFor="let option of filteredNoOfrefills | async" [value]="option"
                      (selectionChange)="calculateQuantity(i); calculateEndDate(i)">
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error>Number O fRefills
                    required</mat-error>

                </mat-form-field>
              </div> -->
              <!-- <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Number of Refills</mat-label>
                  <input matInput formControlName="NumberOfRefills"
                    (input)="calculateQuantity(i); calculateEndDate(i)" />
                </mat-form-field>
              </div> -->
              <!-- Quantity -->
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Quantity</mat-label>
                  <input matInput formControlName="Quantity" />
                </mat-form-field>
              </div>
              <!-- Quantity -->
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Unit</mat-label>
                  <input matInput formControlName="Unit" />
                </mat-form-field>
              </div>
              <!-- Start Date -->
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Start Date</mat-label>
                  <input matInput formControlName="StartDate" />
                </mat-form-field>
              </div>

              <!-- End Date -->
              <div class="col-lg-4">
                <mat-form-field class="example-full-width">
                  <mat-label>End Date</mat-label>
                  <input matInput formControlName="EndDate" />
                </mat-form-field>
              </div>

              <div class="col-lg-6">
                <!-- Direction of Use -->
                <mat-form-field class="w-100">
                  <mat-label>Direction of Use</mat-label>
                  <textarea matInput formControlName="Instruction" placeholder="Enter directions"></textarea>
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <!-- Note -->
                <mat-form-field class="w-100">
                  <mat-label>Note</mat-label>
                  <textarea matInput formControlName="Note" placeholder="Enter note"></textarea>
                </mat-form-field>
              </div>

              <div class="col-lg-12">
                <button class="my-3 " mat-mini-fab color="warn" type="button" (click)="saveAsFavorite(i)">
                  <mat-icon>star</mat-icon>
                </button>
                <!-- <input class="my-3 " type="checkbox" [(ngModel)]="saveAsFavorite"> Save as Favorite -->

              </div>

              <div class="col-lg-12">

                <button mat-mini-fab color="primary" type="button"
                  (click)="addPrescription()"><mat-icon>add</mat-icon></button>
                <button class="float-right" mat-icon-button color="warn" type="button" (click)="removePrescription(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <button [disabled]="prescriptionForm.invalid" type="submit" class="float-right  lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Save</button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Created Prescription
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

      </form>

    </div>
    <hr class="mt-5">
    <h4>Pharmacy Details</h4>
    <ul class="list-unstyled mb-5">

      <li>Pharmacy Name : <span>{{pharmacy[0].PharmacyName}}</span>
      <li>Pharmacy Address : <span>{{pharmacy[0].PharmacyAddress}}</span>
        <!-- <li>Pharmacy Phone : <span>{{pharmacy[0]?.PhoneNumber}}</span>
            <li>Pharmacy Fax : <span>{{pharmacy[0]?.FaxNumber}}</span> -->


    </ul>

    <!-- <div>
      <app-prescription-display></app-prescription-display>
    </div> -->
  </mat-tab>
  <!-- Tab 2: Favorite Prescriptions -->
  <mat-tab label="Favorites">
    <div *ngFor="let favorite of favoritePrescriptions; let i = index">
      <mat-checkbox (change)="onFavoriteSelect(favorite, $event)">
        <ng-container *ngIf="isEditing && editingIndex === i">
          <input [(ngModel)]="favorite.DrugName" placeholder="Drug Name" />
          <input [(ngModel)]="favorite.AmountPerDose" type="number" placeholder="Amount Per Dose" />
          <input [(ngModel)]="favorite.Frequency" type="text" placeholder="Frequency" />
          <!-- Add other fields as necessary -->
        </ng-container>
        <ng-container *ngIf="!isEditing || editingIndex !== i">
          {{ favorite.DrugName }} - {{ favorite.AmountPerDose }} {{ favorite.Unit }}---{{favorite.Frequency}}
        </ng-container>
      </mat-checkbox>
      <button mat-button class="ml-3" color="primary" *ngIf="!isEditing || editingIndex !== i"
        (click)="editFavorite(favorite, i)">
        Edit
      </button>
      <button mat-button color="warn" *ngIf="!isEditing || editingIndex !== i"
        (click)="deleteFavorite(favorite.FavoritePrescriptionId, i)">
        Delete
      </button>
      <div *ngIf="isEditing && editingIndex === i">
        <button mat-button color="primary"
          (click)="saveEditFavorite(favorite.FavoritePrescriptionId, favorite)">Save</button>
        <button mat-button (click)="cancelEdit()">Cancel</button>
      </div>
    </div>
    <button mat-button color="primary" (click)="addSelectedFavorites(tabGroup)">Add Selected to Form</button>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">pill</mat-icon>
      Prescription Free Text
    </ng-template>
    <app-add-prescription-free-text></app-add-prescription-free-text>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">pill</mat-icon>
      Patients Pharmacy
    </ng-template>
    <app-view-patient-pharmacy></app-view-patient-pharmacy>

  </mat-tab>
</mat-tab-group>


<!-- <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Signature</mat-label>
                    <input type="text" formControlName="DoctorSignature" matInput>
                    <mat-error>Sign required</mat-error>
                  </mat-form-field>
                </div> -->