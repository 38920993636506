import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import Quill from 'quill';
import { Observable, startWith, map } from 'rxjs';
import { Nurse } from 'src/app/Models/nurse.model';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { NurseTemplate } from 'src/app/Models/nursetemplate.model';
import { Patient } from 'src/app/Models/patient.model';
import { IcdCodeService } from 'src/app/services/icd-code.service';
import { NurseProgressReportService } from 'src/app/services/nurse-progress-report.service';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization

import { TokenService } from 'src/app/services/token.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-nurse-progress-note',
  templateUrl: './update-nurse-progress-note.component.html',
  styleUrls: ['./update-nurse-progress-note.component.css'],
})
export class UpdateNurseProgressNoteComponent implements OnInit {
  displayedColumns: string[] = ['Name'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  id = 0;
  reportList: NurseReport[] = [];
  isSuccess = false;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  UserId: number = 0;
  nurse: Nurse;
  patient: Patient;
  templates: NurseTemplate[] = [];
  filteredReportName: Observable<string[]>;
  filteredShortcut: Observable<string[]>;
  shortcutNames: string[] = ['@MostRecent', '@00'];
  reportNames: string[] = [
    'Consult note',
    'Progress note',
    'Procedure note',
    'Office visit note',
    'Telephone Note',
  ];
  quillEditor: any;
  previousTranscript: string = '';
  recognition: any;
  isRecognizing: any;

  @ViewChild('editorContainer') editorContainer!: ElementRef;

  cptCodeList = [
    {
      code: 'E11.9',
      description: 'Type 2 diabetes mellitus without complications',
    },
    {
      code: 'I10',
      description: 'Essential hypertension',
    },
    {
      code: '99203',
      description: 'Office visit for back pain, new patient, 45 minutes',
    },
    {
      code: '99211',
      description: 'Office visit for established patient, 5 minutes',
    },
    {
      code: '99212',
      description: 'Office visit for established patient, 10 minutes',
    },
  ];

  suggestions: any[] = []; // Holds the suggestions based on the input
  selectedCptCode: any = null; // Store the selected CPT code to insert into the editor
  editorContent: string = ''; // To hold the content typed into the editor
  patientId: any;
  constructor(
    private fb: UntypedFormBuilder,
    private nurseProgressReportService: NurseProgressReportService,
    private route: ActivatedRoute,
    private nurseService: NurseService,
    private patientService: PatientService,
    private icdCodeService: IcdCodeService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private tokenService: TokenService,

    @Inject(MAT_DIALOG_DATA)
    public data: { reportId },
    private dialogRef: MatDialogRef<UpdateNurseProgressNoteComponent>
  ) {}
  editorModules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
      ['blockquote', 'code-block'],
      [{ script: 'sub' }, { script: 'super' }],
      ['emoji'], // You can add any additional modules you want
    ],
    clipboard: true, // Enable clipboard for copy-paste
  };
  ngOnInit(): void {
    this.quillEditor = new Quill('#editor', {
      theme: 'snow',
      modules: this.editorModules,
    });
    // Bind the text input event
    this.quillEditor.on('text-change', (delta, oldDelta, source) => {
      this.onTextInput();
    });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.UserId = parseInt(decodedToken.nameid);

    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    //   this.patientId = res['PatientId'];
    // });
    const reportId = this.data.reportId;
    this.nurseProgressReportService
      .getNurseProgressReportById(reportId)
      .subscribe((res) => {
        this.Frm.controls.ReportName.setValue(res.ReportName);
        this.quillEditor.clipboard.dangerouslyPasteHTML(res.ReportDescription);
      });
    this.UserId = parseInt(decodedToken.nameid);
    this.loadTemplates(this.UserId);

    this.getPatient(this.id);
    this.getNurse(this.UserId);

    this.filteredReportName = this.ReportName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter5(value || ''))
    );
    this.filteredShortcut = this.shortcut.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter6(value || ''))
    );
  }

  Frm = this.fb.group({
    shortcut: [''],
    ReportName: ['', Validators.required],
    ReportDescription: ['', Validators.required],
  });

  get shortcut() {
    return this.Frm.get('shortcut');
  }
  get ReportName() {
    return this.Frm.get('ReportName');
  }

  get ReportDescription() {
    return this.Frm.get('ReportDescription');
  }

  startVoiceRecognition() {
    this.recognition = new (window.SpeechRecognition ||
      (window as any).webkitSpeechRecognition)();
    this.recognition.lang = 'en-US';
    this.recognition.continuous = true; // Keep listening
    this.recognition.interimResults = true; // Show real-time speech results
    this.recognition.onresult = (event: any) => {
      const transcript = event.results[event.resultIndex][0].transcript;

      // Only insert the final result or if the text is different from the previous
      if (
        event.results[event.resultIndex].isFinal &&
        transcript !== this.previousTranscript
      ) {
        console.log('Final Recognized Text:', transcript);

        // Get the current selection (cursor position)
        const range = this.quillEditor.getSelection();

        if (range) {
          // Insert the recognized text at the current cursor position
          this.quillEditor.insertText(range.index, transcript);
        }

        // Update the previousTranscript to prevent duplicate insertions
        this.previousTranscript = transcript;
      }
    };

    this.recognition.onerror = (event: any) => {};
    // Event listener for when recognition ends (you can stop manually here too if needed)
    this.recognition.onend = () => {
      this.isRecognizing = false;
    };
    this.recognition.start();
  }
  // Stop Speech Recognition
  stopRecognition() {
    if (this.isRecognizing) {
      this.recognition.stop(); // Stop the recognition
      this.isRecognizing = false;
    }
  }
  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  loadTemplates(id) {
    this.nurseService.getNurseTemplates(id).subscribe((data) => {
      // console.log(data);
      this.templates = data.NurseTemplates;
      this.dataSource = new MatTableDataSource(data.NurseTemplates);
      this.dataSource.paginator = this.paginator;
    });
  }

  // Method to handle click on the table row for template selection
  onTableRowClick(template: any) {
    // console.log(template.Content);
    this.quillEditor.clipboard.dangerouslyPasteHTML(template.Content);
    // this.selectTemplate(template.Content);
  }
  // This function is triggered on every keystroke or text area change in the Quill editor
  onTextInput(): void {
    const cursorPosition = this.quillEditor.getSelection()?.index;
    let currentText = this.quillEditor.getText();
    // let currentText = this.quillEditor.getText().toLowerCase().trim();
    // Remove extra spaces and newlines

    // this.suggestions = this.getCptCodeSuggestions(currentText);
    // this.cdRef.detectChanges();
    // Loop through templates and replace shortcut with actual content
    this.templates.forEach((template) => {
      if (currentText.includes(template.shortcut)) {
        const startIndex = currentText.indexOf(template.shortcut);
        const endIndex = startIndex + template.shortcut.length;

        // Remove shortcut and insert content
        this.quillEditor.deleteText(startIndex, template.shortcut.length);

        // Sanitize HTML content to ensure safe insertion
        const sanitizedContent = DOMPurify.sanitize(template.Content);

        // Insert sanitized content
        this.quillEditor.clipboard.dangerouslyPasteHTML(
          startIndex,
          sanitizedContent
        );
        this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
      }
    });
  }
  // This function checks the current text in the editor and suggests CPT codes
  // getCptCodeSuggestions(text: string): any[] {
  //   const suggestions = [];

  //   this.cptCodeList.forEach((cpt) => {
  //     if (cpt.description.toLowerCase().includes(text)) {
  //       suggestions.push(cpt); // Add matching CPT code to suggestions
  //     }
  //   });

  //   return suggestions;
  // }

  // onSelectCptCode(cpt) {
  //   this.selectedCptCode = cpt;
  //   this.insertCptCodeIntoEditor(cpt);
  //   this.suggestions = []; // Clear suggestions after selection
  // }
  // Inserts the selected CPT code into the Quill editor at the cursor position
  // insertCptCodeIntoEditor(cpt: any): void {
  //   if (!this.quillEditor) {
  //     return;
  //   }

  //   this.quillEditor.focus();

  //   const currentSelection = this.quillEditor.getSelection();

  //   if (!currentSelection) {
  //     this.quillEditor.insertText(0, `${cpt.code}: ${cpt.description}`); // Insert at the beginning
  //     return;
  //   }

  //   const currentPosition = currentSelection.index;

  //   const cptText = `${cpt.code}: ${cpt.description}`;
  //   this.quillEditor.insertText(currentPosition, cptText);

  //   this.quillEditor.setSelection(currentPosition + cptText.length, 0); // Move the cursor after inserted text
  // }

  // This method is triggered when a template is selected
  // selectTemplate(templateContent: string): void {
  //   const cursorPosition = this.quillEditor.getSelection()?.index;

  //   if (cursorPosition !== null) {
  //     this.quillEditor.insertText(cursorPosition, templateContent);

  //     this.onTextInput(); // Manually trigger suggestions check
  //   }
  // }

  formSubmit() {
    let Shortcut = this.Frm.value['shortcut'];
    let ReportName = this.Frm.value['ReportName'];
    let reportDescription = this.Frm.value['ReportDescription'];

    let body = {
      PatientId: this.patientId,
      NurseId: this.UserId,
      shortcut: Shortcut,
      ReportName: ReportName,
      ReportDescription: this.quillEditor.root.innerHTML,
      NurseName: this.nurse.NurseName,
      Status: true,
    };

    this.nurseProgressReportService
      .updateNurseProgressReport(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;
        // this.Frm.reset();
        this.router.navigate(['/nursePatientTabed/', this.patientId]);
      });
  }

  private _filter5(value: string): string[] {
    const ds = value.toLowerCase();

    return this.reportNames.filter((aa) => aa.toLowerCase().includes(ds));
  }
  private _filter6(value: string): string[] {
    const ss = value.toLowerCase();

    return this.shortcutNames.filter((ee) => ee.toLowerCase().includes(ss));
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.nurseProgressReportService
      .deleteNurseProgressReport(id)
      .subscribe((res) => {
        this.dataSource(this.id);
      });
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.DOWN_ARROW) {
      // Your row selection code
      // console.log(event);
      this.stopRecognition();
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}
