import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { NerveStudyResultService } from 'src/app/services/nerve-study-result.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-nerve-study-results',
  templateUrl: './add-nerve-study-results.component.html',
  styleUrls: ['./add-nerve-study-results.component.css'],
})
export class AddNerveStudyResultsComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  isSuccess = false;
  id = 0;
  studyType: string[] = [
    'Motor Nerve Results',
    'Sensory Sites',
    'F Waves',
    'H Reflex',
  ];
  studySubType: string[] = [
    'Right Median (APB)',
    'Right Ulnar(ADM)',
    'Left Median(Rec:Wrist)',
    'Right Median',
    'Right Radial',
    'R Peroneal-EDB',
    'L Peroneal-EDB',
    'R Tibial-AH',
    'L Tibial-AH',
  ];
  doctorId: number = 0;
  doctor: Doctor;
  patient: Patient;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private nerveStudyResultService: NerveStudyResultService,
    private patientService: PatientService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);
  }

  Frm = this.fb.group({
    StudyType: [''],

    StudySubType: [''],

    NerveSite: [''],

    RecSite: [''],

    LatencyMs: [''],

    LatencyNorm: [''],

    AmplitudeMv: [''],
    AmplitudeNorm: [''],
    AmplitudeRatio: [''],
    DurationMs: [''],
    Segment: [''],
    Distance: [''],
    VelocityMs: [''],
    VelocityNorm: [''],
    Comments: [''],

    Signature: [''],
    DoctorName: [''],
  });

  get StudyType() {
    return this.Frm.get('StudyType');
  }
  get StudySubType() {
    return this.Frm.get('StudySubType');
  }
  get NerveSite() {
    return this.Frm.get('NerveSite');
  }
  get RecSite() {
    return this.Frm.get('RecSite');
  }
  get LatencyMs() {
    return this.Frm.get('LatencyMs');
  }
  get LatencyNorm() {
    return this.Frm.get('LatencyNorm');
  }
  get AmplitudeMv() {
    return this.Frm.get('AmplitudeMv');
  }
  get AmplitudeNorm() {
    return this.Frm.get('AmplitudeNorm');
  }
  get AmplitudeRatio() {
    return this.Frm.get('AmplitudeRatio');
  }
  get DurationMs() {
    return this.Frm.get('DurationMs');
  }
  get Segment() {
    return this.Frm.get('Segment');
  }
  get Distance() {
    return this.Frm.get('Distance');
  }
  get VelocityMs() {
    return this.Frm.get('VelocityMs');
  }
  get VelocityNorm() {
    return this.Frm.get('VelocityNorm');
  }
  get Comments() {
    return this.Frm.get('Comments');
  }
  get DoctorName() {
    return this.Frm.get('DoctorName');
  }
  get Signature() {
    return this.Frm.get('Signature');
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
      this.DoctorName.setValue(res.DoctorName);
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  formSubmit() {
    let body = {
      ...this.Frm.value,
      PatientId: this.id,
    };
    this.nerveStudyResultService
      .registerNerveStudyResult(body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }

  resetFn(e) {
    if (e) {
      this.Frm.reset();
    }
  }
}
