import { Component } from '@angular/core';

@Component({
  selector: 'app-view-favorite-prescription',
  templateUrl: './view-favorite-prescription.component.html',
  styleUrls: ['./view-favorite-prescription.component.css']
})
export class ViewFavoritePrescriptionComponent {

}
