<div class="container mt-5">
    <div class="row">
        <div class="col-md-6">
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="messageForm" (ngSubmit)="onSendMessage()">
                <div>
                    <label for="recipient">Select Recipient:</label>
                    <select id="recipient" formControlName="recipientId" required>
                        <option *ngFor="let user of users" [value]="user.id">{{ user.name }} ({{ user.role }})</option>
                    </select>
                </div>

                <div>
                    <label for="content">Message Content:</label>
                    <textarea id="content" formControlName="content" placeholder="Enter your message here..."
                        required></textarea>
                </div>

                <div>
                    <label for="attachments">Attachments:</label>
                    <input type="file" (change)="onFileSelect($event)" multiple>
                </div>

                <div *ngIf="progress > 0">
                    <p>Upload Progress: {{ progress }}%</p>
                </div>

                <button type="submit" [disabled]="messageForm.invalid">Send Message</button>
            </form>
        </div>
    </div>
</div>