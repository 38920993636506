<div class="ml-3">
  <div class="row">
    <div class="col-lg-6 float-right">
      <mat-form-field class="example-full-width mt-5">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
      </mat-form-field>
    </div>
  </div>
</div>
<div class=" mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>


    <ng-container matColumnDef="createdDate">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Date </th>
      <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'short'}} </td>
    </ng-container>

    <ng-container matColumnDef="OxygenSaturation">
      <th mat-header-cell *matHeaderCellDef> Oxygen Saturation </th>
      <td mat-cell *matCellDef="let row"> {{row.OxygenSaturation}} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="shortcut">
      <th mat-header-cell *matHeaderCellDef> shortcut </th>
      <td mat-cell *matCellDef="let row"> {{row.shortcut}} </td>
    </ng-container> -->
    <!-- <ng-container matColumnDef="Time">
      <th mat-header-cell *matHeaderCellDef > Time </th>
      <td mat-cell *matCellDef="let row"> {{row.Time}} </td>
    </ng-container> -->
    <ng-container matColumnDef="Respiration">
      <th mat-header-cell *matHeaderCellDef> Respiration </th>
      <td mat-cell *matCellDef="let row"> {{row.Respiration}} </td>
    </ng-container>
    <ng-container matColumnDef="Temparature">
      <th mat-header-cell *matHeaderCellDef> Temparature </th>
      <td mat-cell *matCellDef="let row"> {{row.Temparature}} </td>
    </ng-container>
    <ng-container matColumnDef="HeartBeat">
      <th mat-header-cell *matHeaderCellDef> Heart Beat </th>
      <td mat-cell *matCellDef="let row"> {{row.HeartBeat}} </td>
    </ng-container>
    <ng-container matColumnDef="BloodPressure">
      <th mat-header-cell *matHeaderCellDef> Blood Pressure </th>
      <td mat-cell *matCellDef="let row"> {{row.BloodPressure}} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="Comments">
      <th mat-header-cell *matHeaderCellDef> Comments </th>
      <td mat-cell *matCellDef="let row"> {{row.Comments}} </td>
    </ng-container> -->

    <ng-container matColumnDef="NurseName">
      <th mat-header-cell *matHeaderCellDef> Nurse Name </th>
      <td mat-cell *matCellDef="let row"> {{row.NurseName}} </td>
    </ng-container>
    <ng-container matColumnDef="Signature">
      <th mat-header-cell *matHeaderCellDef> Signature </th>
      <td mat-cell *matCellDef="let row"> {{row.Signature}} </td>
    </ng-container>


    <ng-container matColumnDef="View">
      <th mat-header-cell *matHeaderCellDef> View Report </th>
      <td mat-cell *matCellDef="let row">
        <button class="btn btn-primary"
          (click)="modal7.show();PrintReport(row) "><mat-icon>visibility</mat-icon></button>&nbsp;

        <app-modal id="printable" #modal7 class="panel-demo1" [maximizable]="true" [backdrop]="false">
          <ng-container class="app-modal-header">Patient Name: {{patient?.PatientFirstName}} &nbsp;
            {{patient?.PatientLastName}}</ng-container>
          <ng-container class="app-modal-body">
            <div class="modal-body " id="printable" #nursereportList>
              <!-- <app-view-hospital></app-view-hospital>
              <div>
                <div class="areaStyle mb-1">
                  <P>Patient ID: {{patient?.PatientId}} </P>

                  <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
                  <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                  <P>Patient Address: {{patient?.PatientAddress}} </P>
                </div> -->
              <div class="areaStyle mb-1 ">
                <P>Height: {{row.Height}}</P>
                <P>Weight: {{row.Weight}}</P>
                <P>BMI: {{row.BMI}}</P>
                <P>Respiration: {{row.Respiration}}</P>
                <P>Temparature: {{row.Temparature}}</P>
                <P>Heart Beat: {{row.HeartBeat}}</P>
                <P>Blood Pressure: {{row.BloodPressure}}</P>
                <P>Oxygen Saturation: {{row.OxygenSaturation}}</P>
                <P>Comments: {{row.Comments}}</P>
              </div>
              <div class="areaStyle">
                <P *ngIf="row.NurseName">Nurse Name: {{row.NurseName}}</P>
                <P *ngIf="row.DoctorName">Doctor Name: {{row.DoctorName}}</P>
                <P> Signature: {{row.Signature}}</P>
                <P> Date: {{row.createdDate | date:'short'}}</P>


              </div>
            </div>

            <!-- </div> -->
            <!-- <div>
              <form (keydown.enter)="$event.preventDefault()"  action="">
                <mat-form-field class="w-30 ml-5 mr-3" appearance="outline">
                  <mat-label>Email to Lab</mat-label>
                  <input matInput type="text" [formControl]="email" placeholder="Enter email">
                </mat-form-field>
                <button mat-raised-button color="primary" class="mb-1" (click)="sendtoLab()">Send</button>
                <div class="send-fax-prescription d-flex align-items-baseline">
                  <mat-form-field class="w-30 ml-5 mr-3" appearance="outline">
                    <input matInput type="text" [formControl]="fax" placeholder="Enter Fax Number">
                  </mat-form-field>
                  <button mat-raised-button color="primary" class="mb-1" (click)="faxPresciption()">Send</button>
                </div>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                  Fax has been sent Successfully.
                  <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div *ngIf="errorMessage" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                  {{errorMessage}}
                  <button type="button" class="close " data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </form>
            </div> -->

          </ng-container>
          <ng-container class="app-modal-footer">
            <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

            <button class="dt-button dt-blue" style="float: right;" (click)="modal7.hide()">Close</button>

          </ng-container>
        </app-modal>

        <!-- <button type="button" (click)="PrintReport(row)" class="btn btn-primary" data-toggle="modal"
          data-target=".bd-example-modal-lg">View Report</button>

        <div class="modal w-100 fade bd-example-modal-lg" tabindex="-1" role="dialog"
          aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Print Report</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body " id="printable" #nursereportList>
                <app-view-hospital></app-view-hospital>
                <div>
                  <div class="areaStyle mb-1">
                    <P>Patient ID: {{patient?.PatientId}} </P>

                    <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
                    <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                    <P>Patient Address: {{patient?.PatientAddress}} </P>
                  </div>
                  <div class="areaStyle mb-1 ">
                    <P>Respiration: {{row.Respiration}}</P>
                    <P>Temparature: {{row.Temparature}}</P>
                    <P>Heart Beat: {{row.HeartBeat}}</P>
                    <P>Blood Pressure: {{row.BloodPressure}}</P>
                    <P>Comments: {{row.Comments}}</P>
                  </div>
                  <div class="areaStyle">
                    <P>Nurse Name: {{row.NurseName}}</P>
                    <P> Signature: {{row.Signature}}</P>
                    <P> Date: {{row.createdDate | date:'short'}}</P>


                  </div>
                </div>

              </div>
              <div class="modal-footer d-flex flex-sm-row flex-column justify-content-between align-items-center">



                <div>
                  <button type="button" (click)="print()" class="btn btn-primary ">Print</button>
                  <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div> -->

      </td>
    </ng-container>
    <ng-container matColumnDef="Update">
      <th mat-header-cell *matHeaderCellDef> Update</th>
      <td mat-cell *matCellDef="let row">
        <button [disabled]="currentNurseId != row.NurseId" mat-mini-fab
          [routerLink]="['/updateNurseReport/',row.NurseReportId]" color="primary"><mat-icon
            mat-list-icon>edit</mat-icon></button>
      </td>
    </ng-container>
    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef> Delete</th>
      <td mat-cell *matCellDef="let row">
        <button mat-mini-fab color="accent" (click)="delete(row.NurseReportId)"
          [disabled]="currentNurseId != row.NurseId"><mat-icon mat-list-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>