<div class="mt-5">
  <mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab *ngIf="!currentPatient.IsInsuranceCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Insurance
      </ng-template>
      <app-add-patient-insurance (submitAction)="handleSubmitAction()"></app-add-patient-insurance>

    </mat-tab>

    <mat-tab *ngIf="!currentPatient.IsIdProofCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        ID Proof
      </ng-template>
      <app-add-patient-idproof (submitAction)="handleSubmitAction()"></app-add-patient-idproof>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsEmergencyContactCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Emergency Contact
      </ng-template>
      <app-add-emergency-contact (submitAction)="handleSubmitAction()"></app-add-emergency-contact>

    </mat-tab>


    <mat-tab *ngIf="!currentPatient.IsFamilyHistoryCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Family History
      </ng-template>
      <app-add-familyhistory (submitAction)="handleSubmitAction()"></app-add-familyhistory>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsSocialHistoryCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Social History
      </ng-template>
      <app-add-socialhistory (submitAction)="handleSubmitAction()"></app-add-socialhistory>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsMedicalHistoryCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Medical History
      </ng-template>
      <app-add-medical-history (submitAction)="handleSubmitAction()"></app-add-medical-history>

    </mat-tab>
    <mat-tab *ngIf="!currentPatient.IsHippaCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Hippa Compliance
      </ng-template>
      <app-add-hippa-compliance (submitAction)="handleSubmitAction()"></app-add-hippa-compliance>

    </mat-tab>


    <!-- *ngIf="!currentPatient.IsNarcoticMedicalPolicyAgreementCompleted" -->
    <mat-tab *ngIf="!currentPatient.IsNarcoticMedicalPolicyAgreementCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Narcotic (Opioid)

      </ng-template>
      <!-- (submitAction)="handleSubmitAction()" -->
      <app-add-narcotic-medical-policy-agreement
        (submitAction)="handleSubmitAction()"></app-add-narcotic-medical-policy-agreement>

    </mat-tab>
    <!-- *ngIf="!currentPatient.IsGeneralConsentForTreatmentCompleted" -->
    <mat-tab *ngIf="!currentPatient.IsGeneralConsentForTreatmentCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        General Consent
      </ng-template>
      <!-- (submitAction)="handleSubmitAction()" -->
      <app-add-general-consent-for-treatment
        (submitAction)="handleSubmitAction()"></app-add-general-consent-for-treatment>

    </mat-tab>
    <!-- *ngIf="!currentPatient.IsOfficePolicyConsentCompleted" -->
    <mat-tab *ngIf="!currentPatient.IsOfficePolicyConsentCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Office Policies
      </ng-template>
      <!-- (submitAction)="handleSubmitAction()" -->
      <app-add-office-policy-consent (submitAction)="handleSubmitAction()"></app-add-office-policy-consent>

    </mat-tab>
    <!-- *ngIf="!currentPatient.IsPhtographicConsentCompleted" -->
    <mat-tab *ngIf="!currentPatient.IsPhtographicConsentCompleted">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">add</mat-icon>
        Photograph/Video Consent
      </ng-template>
      <!-- (submitAction)="handleSubmitAction()" -->
      <app-add-photographic-consent (submitAction)="handleSubmitAction()"></app-add-photographic-consent>

    </mat-tab>
    <!-- <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
        View Insurance
      </ng-template>
      <app-view-patient-insurance></app-view-patient-insurance>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
        View Emergency Contact
      </ng-template>
      <app-emergency-contact></app-emergency-contact>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
        View Allergies
      </ng-template>
      <app-view-allergy></app-view-allergy>

    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">visibility</mat-icon>
        View Surgical History
      </ng-template>
      <app-view-surgical-history></app-view-surgical-history>

    </mat-tab> -->
  </mat-tab-group>
</div>



<!-- 
 <mat-tab>
  <ng-template mat-tab-label>
    <mat-icon class="example-tab-icon">add</mat-icon>
    Immunization
  </ng-template>
  <app-add-vaccine></app-add-vaccine>

</mat-tab>
<mat-tab>
  <ng-template mat-tab-label>
    <mat-icon class="example-tab-icon">add</mat-icon>
    Allergy
  </ng-template>
  <app-add-allergy></app-add-allergy>

</mat-tab>
<mat-tab>
  <ng-template mat-tab-label>
    <mat-icon class="example-tab-icon">add</mat-icon>
    Surgical History
  </ng-template>
  <app-add-surgical-history></app-add-surgical-history>

</mat-tab> -->