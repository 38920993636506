<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>New Pharmacy</h2>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="phFrm" (ngSubmit)="formSubmit()"
                class="example-form">

                <div class="row">
                    <div class="col-lg-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Pharmacy Name (include store id)</mat-label>
                            <input type="text" formControlName="PharmacyName" matInput>
                            <mat-error>Please enter Pharmacy Name</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-1">
                        <mat-form-field class="example-full-width">
                            <mat-label>Country Code</mat-label>
                            <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput
                                placeholder="+1, +91">
                            <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Phone Number</mat-label>
                            <input type="text" formControlName="PhoneNumber" matInput>
                            <mat-error>Please enter PhoneNumber</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-1">
                        <mat-form-field class="example-full-width">
                            <mat-label>Country Code</mat-label>
                            <input type="text" formControlName="CountryCodeFax" [matAutocomplete]="auto" matInput
                                placeholder="+1, +91">
                            <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Fax Number</mat-label>
                            <input type="text" formControlName="FaxNumber" matInput>
                            <mat-error>Please enter FaxNumber</mat-error>
                        </mat-form-field>

                    </div>


                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <mat-form-field class="example-full-width">
                            <mat-label> Email</mat-label>
                            <input type="email" formControlName="Email" matInput>
                            <mat-error>Please enter Email</mat-error>
                        </mat-form-field>

                    </div>
                </div>
                <div class="row">



                    <div class="col-lg-8">

                        <mat-form-field class="example-full-width">
                            <mat-label> Pharmacy Address</mat-label>
                            <!-- <input type="tel" formControlName="PharmacyAddress" matInput> -->
                            <textarea matInput formControlName="PharmacyAddress" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>

                            <mat-error>Please enter Pharmacy Address</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-4">

                        <p>Status</p>
                        <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                            <mat-radio-button class="mx-3" value="true">Active</mat-radio-button>
                            <mat-radio-button class="mx-3 " value="false"> Inactive</mat-radio-button>
                        </mat-radio-group>

                    </div>
                </div>


                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Pharmcy
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>

</div>