<mat-tab-group (selectedTabChange)="tabChanged($event)">

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">pill</mat-icon>
      New Prescription
    </ng-template>
    <div class="container">

      <div class="col-lg regFrm mat-elevation-z8">
        <h1>New Prescription</h1>
        <form [formGroup]="prescriptionFrm" (keydown.enter)="$event.preventDefault()" (ngSubmit)="formSubmit()"
          class="example-form">
          <div class="">
            <div>
              <!-- <div class="row">
                <div class="col-lg-6"></div>
                <div class="col-lg-6">
                  <div>
                    <div class="button-group float-right">
                      <button type="button" *ngFor="let dosage of dosageOptions" (click)="selectDosage(dosage)"
                        mat-basic-chip>
                        {{ dosage }}
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="row">
                <div class="col">
                  <p id="result"></p>

                </div>
                <div class="col">
                  <div *ngIf="daApprovalResult"
                    [ngStyle]="{'color': daApprovalResult.includes('required') ? 'orange' : 'blue'}">
                    <p>{{ daApprovalResult }}</p>
                  </div>

                  <div *ngIf="isDARequired">
                    <label for="daCode">Enter DEA Number:</label>
                    <input type="text" id="daCode" [ngModelOptions]="{standalone: true}" [(ngModel)]="daCode"
                      placeholder="Enter DA code">

                    <button type="button" (click)="validateDACode()">Validate DA Code</button>

                    <div *ngIf="daValidationMessage">
                      <p [ngStyle]="{'color': daValidationMessage.includes('valid') ? 'green' : 'red'}">
                        {{ daValidationMessage }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="isDAvalidated">

                <div class="col-lg-6 p-3  mat-elevation-z6">
                  <form [formGroup]="loginForm" (ngSubmit)="loginSubmit()" class="example-form">
                    <mat-form-field class="example-full-width">
                      <mat-label>UserName</mat-label>
                      <input type="text" formControlName="UserName" matInput>
                    </mat-form-field>

                    <mat-form-field class="example-full-width">
                      <mat-label>Password</mat-label>
                      <input type="password" matInput formControlName="Password">
                    </mat-form-field>



                    <button class="float-right lgBtCl" mat-button color="primary">
                      <mat-icon>login</mat-icon>
                      Login</button>



                  </form>
                  <div *ngIf="error" class="alert alert-danger mt-2">{{netWorkFailure}}</div>

                  <div *ngIf="IsthrowError" class="alert alert-danger mt-2">{{IsthrowError}}</div>
                </div>

              </div>
              <div class="row">
                <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Drug Name & Dosage</mat-label>
                    <mat-select formControlName="DrugName">
                      <mat-option>
                        <lib-mat-select-search [list]="medicineList" [searchProperties]="['DrugName']"
                          (filtered)="flteredmedicineList = $event">
                        </lib-mat-select-search>
                      </mat-option>

                      <cdk-virtual-scroll-viewport [ngStyle]="{height: '200px'}" itemSize="25">
                        <mat-option *cdkVirtualFor="let entry of flteredmedicineList" [value]="entry.DrugName" #drgName
                          (onSelectionChange)="getSelectedDrug(drgName.value)">
                          {{entry.DrugName}}
                        </mat-option>
                      </cdk-virtual-scroll-viewport>
                    </mat-select>
                    <!-- <input type="text" formControlName="DrugName" matInput > -->
                    <mat-error>Drug Name required</mat-error>

                  </mat-form-field>
                </div>
                <!-- <div class="col-lg-3">


                  <mat-form-field class="example-full-width">
                    <mat-label>BrandName</mat-label>
                    <mat-select formControlName="BrandName">

                      <mat-option *ngFor="let br of medicineList" [value]="br.BrandName">{{br.BrandName}}</mat-option>

                    </mat-select>

                  </mat-form-field>


                </div> -->
                <!-- <div class="col-lg-4">
                  <mat-form-field class="example-full-width">
                    <mat-label>Dose</mat-label>

                    <mat-error>Dosage required</mat-error>
                    <input type="text" matInput formControlName="Strength">

                  </mat-form-field>

                </div>
                <div class="col-lg-2">
                  <mat-form-field class="example-full-width">
                    <mat-label>mg/ml</mat-label>

                    <input type="text" matInput formControlName="DosageExtn" [matAutocomplete]="auto5"> <mat-icon
                      matSuffix>search</mat-icon>
                    <mat-autocomplete #auto5="matAutocomplete">
                      <mat-option *ngFor="let dos of filteredDose | async" [value]="dos">
                        {{ dos }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>Dosage required</mat-error>

                  </mat-form-field>

                </div> -->
                <div class="col-lg-3">
                  <!-- <mat-form-field class="example-full-width">
                    <mat-label>Dosage</mat-label>

                    <mat-error>Dosage required</mat-error>
                    <input type="text" matInput formControlName="Strength" [matAutocomplete]="auto5"> <mat-icon
                      matSuffix>search</mat-icon>
                    <mat-autocomplete #auto5="matAutocomplete">
                      <mat-option *ngFor="let dos of filteredDose | async" [value]="dos">
                        {{ dos }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field> -->
                  <mat-form-field class="example-full-width">
                    <mat-label>Amount per Dose</mat-label>
                    <!-- <mat-select formControlName="Instruction"> -->
                    <input type="text" matInput formControlName="NoOfTablets" [matAutocomplete]="autoTablets">
                    <mat-autocomplete #autoTablets="matAutocomplete">
                      <mat-option *ngFor="let tb of filteredNoOfTablets | async" #stateInputTb [value]="tb"
                        (onSelectionChange)="getNoOfTabletValue(stateInputTb.value)">
                        {{ tb }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>No.Of items/day
                      required</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-lg-3">
                  <mat-form-field class="example-full-width">
                    <mat-label>Units</mat-label>

                    <mat-error>Units required</mat-error>
                    <input type="text" matInput formControlName="DosageExtn" [matAutocomplete]="auto6"> <mat-icon
                      matSuffix>search</mat-icon>
                    <mat-autocomplete #auto6="matAutocomplete">
                      <mat-option *ngFor="let ext of filteredExt | async" [value]="ext" #extTb
                        (onSelectionChange)="getExtValue(extTb.value)">
                        {{ ext }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>

                </div>

              </div>
              <div class="row">


                <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Route</mat-label>
                    <input type="text" matInput formControlName="Route" [matAutocomplete]="auto"> <mat-icon
                      matSuffix>search</mat-icon>
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>Route
                      required</mat-error>

                  </mat-form-field>

                </div>

                <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Frequency</mat-label>
                    <!-- <mat-select formControlName="Instruction"> -->
                    <input type="text" matInput formControlName="Frequency" [matAutocomplete]="auto3"><mat-icon
                      matSuffix>search</mat-icon>
                    <mat-autocomplete #auto3="matAutocomplete">
                      <mat-option *ngFor="let fc of filteredFrequency | async" #stateInput [value]="fc"
                        (onSelectionChange)="getFrequency(stateInput.value)">
                        {{ fc }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>Frequency
                      required</mat-error>
                  </mat-form-field>

                </div>
              </div>
              <div class="row">


                <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Refill Duration</mat-label>
                    <!-- <input type="text" matInput formControlName="RefillDuration"> -->
                    <input type="text" matInput formControlName="RefillDuration" [matAutocomplete]="auto4"><mat-icon
                      matSuffix>search</mat-icon>
                    <mat-autocomplete #auto4="matAutocomplete">
                      <mat-option *ngFor="let fgc of filteredRefill | async" [value]="fgc" #stateInput2
                        (onSelectionChange)="getRefillValue(stateInput2.value)">
                        {{ fgc }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>Refill Duration required</mat-error>

                  </mat-form-field>

                </div>
                <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>No.of refills</mat-label>
                    <input type="number" matInput formControlName="Numberofrefills" (change)="endDate($event)">
                    <mat-error>Number of refills required</mat-error>

                  </mat-form-field>

                </div>
              </div>
              <div class="row">


                <div class="col-lg-5">
                  <mat-form-field class="example-full-width">
                    <mat-label>Quantity</mat-label>
                    <!-- (input)="getGrandTotal()" -->
                    <input type="number" matInput formControlName="Quantity" (input)="getGrandTotal()">
                    <mat-error>Quantity
                      required</mat-error>

                  </mat-form-field>

                </div>
                <div class="col-lg-3">
                  <mat-form-field class="example-full-width">
                    <mat-label>Units</mat-label>
                    <input type="text" matInput formControlName="DosageExtn" [matAutocomplete]="auto6">

                    <mat-error>Units required</mat-error>
                    <!-- <input type="text" matInput formControlName="DosageExtn" [matAutocomplete]="auto6"> <mat-icon
                      matSuffix>search</mat-icon>
                    <mat-autocomplete #auto6="matAutocomplete">
                      <mat-option *ngFor="let ext of filteredExt | async" [value]="ext">
                        {{ ext }}
                      </mat-option>
                    </mat-autocomplete> -->
                  </mat-form-field>

                </div>
                <div class="col-lg-4">
                  <mat-form-field class="example-full-width">
                    <mat-label>End Date</mat-label>
                    <input type="text" matInput formControlName="EndDate" (input)="setEndDate()">
                    <mat-error>End Date required</mat-error>

                  </mat-form-field>

                </div>
              </div>
              <!-- <div class="row">

            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label> Morning </mat-label>
                <input [ngxTimepicker]="picker" matInput formControlName="MorningTime">
                <ngx-material-timepicker #picker></ngx-material-timepicker>
                <mat-error>Please enter Available Morning</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label> Afternoon </mat-label>
                <input [ngxTimepicker]="picker1" matInput formControlName="AfternoonTime">
                <ngx-material-timepicker #picker1></ngx-material-timepicker>
                <mat-error>Please enter Available Morning</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label> Evening </mat-label>
                <input [ngxTimepicker]="picker2" matInput formControlName="EveningTime">
                <ngx-material-timepicker #picker2></ngx-material-timepicker>
                <mat-error>Please enter Available Morning</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field class="example-full-width">
                <mat-label> Night </mat-label>
                <input [ngxTimepicker]="picker3" matInput formControlName="NightTime">
                <ngx-material-timepicker #picker3></ngx-material-timepicker>
                <mat-error>Please enter Available Morning</mat-error>
              </mat-form-field>
            </div>


          </div> -->
              <div class="row">
                <!-- <div class="col-lg-6">
              <mat-form-field class="example-full-width">
                <mat-label>Q-Time</mat-label>
                <mat-select formControlName="QTime">

                  <mat-option *ngFor="let q of QTimes" [value]="q">{{q}}</mat-option>

                </mat-select>

              </mat-form-field>
            </div> -->

              </div>

              <div class="row">
                <!-- 
                <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Start Date</mat-label>
                    <input type="text" matInput formControlName="StartDate">
                    <mat-error>Start Date required</mat-error>

                  </mat-form-field>

                </div> -->
                <!-- <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>End Date</mat-label>
                    <input type="text" matInput formControlName="EndDate" (input)="setEndDate()">
                    <mat-error>End Date required</mat-error>

                  </mat-form-field>

                </div> -->

                <!-- <div class=" col-lg-4">

                    <p>Status</p>
                    <mat-radio-group formControlName="Status" aria-label="Select an option my-3">
                      <mat-radio-button class="mx-3" value="true">Current</mat-radio-button>
                      <mat-radio-button class="mx-3 " value="false"> Stopped</mat-radio-button>
                    </mat-radio-group>

                </div> -->
              </div>
              <div class="row">


                <div class="col-lg-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>Direction of Use (Patient Sig)</mat-label>
                    <input type="text" matInput formControlName="Instruction">
                    <mat-error>Number of Instruction required</mat-error>

                  </mat-form-field>
                  <!-- <mat-form-field class="example-full-width">
                    <mat-label>Direction of Use (Patient Sig)</mat-label>
                 
                    <input type="text" matInput formControlName="Instruction" [matAutocomplete]="auto2"><mat-icon
                      matSuffix>search</mat-icon>
                    <mat-autocomplete #auto2="matAutocomplete">
                      <mat-option *ngFor="let ins of filteredinstructions | async" [value]="ins">
                        {{ ins }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>Direction of use
                      required</mat-error>
                  </mat-form-field> -->

                </div>

              </div>
              <div class="row">


                <div class="col-lg-12">
                  <mat-form-field class="example-full-width">
                    <mat-label>Comments/Note to Pharmacy</mat-label>
                    <input type="text" formControlName="Note" matInput>
                    <mat-error>Note</mat-error>
                  </mat-form-field>
                </div>




                <!-- <div class="col-lg-4">
                  <mat-form-field class="example-full-width">
                    <mat-label>Doctor Name </mat-label>
                    <input type="text" formControlName="DoctorName" matInput>
                    <mat-error>DoctorName</mat-error>
                  </mat-form-field>
                </div> -->

              </div>








            </div>





          </div>




          <button [disabled]="prescriptionFrm.invalid" type="submit" class="float-right lgBtCl" mat-button>
            <mat-icon>save</mat-icon>
            Save</button>
          <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
            Successfully Created Prescription
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </form>
        <h4>Pharmacy Details</h4>
        <ul class="list-unstyled">

          <li>Pharmacy Name : <span>{{pharmacy[0].PharmacyName}}</span>
          <li>Pharmacy Address : <span>{{pharmacy[0].PharmacyAddress}}</span>
            <!-- <li>Pharmacy Phone : <span>{{pharmacy[0]?.PhoneNumber}}</span>
          <li>Pharmacy Fax : <span>{{pharmacy[0]?.FaxNumber}}</span> -->


        </ul>
      </div>
    </div>

    <!-- <div>
      <app-prescription-display></app-prescription-display>
    </div> -->
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">pill</mat-icon>
      Prescription Free Text
    </ng-template>
    <app-add-prescription-free-text></app-add-prescription-free-text>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">pill</mat-icon>
      Patients Pharmacy
    </ng-template>
    <app-view-patient-pharmacy></app-view-patient-pharmacy>

  </mat-tab>
</mat-tab-group>


<!-- <div class="col-lg-6">
                  <mat-form-field class="example-full-width">
                    <mat-label>Signature</mat-label>
                    <input type="text" formControlName="DoctorSignature" matInput>
                    <mat-error>Sign required</mat-error>
                  </mat-form-field>
                </div> -->