import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

import { TokenService } from 'src/app/services/token.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css'],
})
export class ResetpasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  submitted = false;
  successMessage = '';
  errorMessage = '';

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private userProfileService: UserService,
    private router: Router
  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      userName: ['', Validators.required],
    });
  }
  ngOnInit(): void {}

  onSubmit() {
    this.submitted = true;

    if (this.forgotPasswordForm.invalid) {
      return;
    }

    const userName = this.forgotPasswordForm.value.userName;

    this.http
      .post(
        ' https://binseraneurology.com/api/Users/ForgotPassword',
        { userName },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .subscribe({
        next: (response: any) => {
          this.successMessage =
            response.message ||
            'Reset token sent via SMS. Please check your phone.';
          this.errorMessage = '';
          setTimeout(() => {
            this.router.navigate(['/setpassword'], {
              queryParams: { userName },
            });
          }, 2000);
        },
        error: (err) => {
          this.errorMessage =
            err.error.message || 'An error occurred. Please try again.';
          this.successMessage = '';
        },
      });
  }
}
