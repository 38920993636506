import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LabreportService } from '../../services/labreport.service';
import { Doctor } from '../../Models/doctor.model';
import { DoctorService } from '../../services/doctor.service';
import * as moment from 'moment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from 'src/app/Models/patient.model';
import { HospitalService } from 'src/app/services/hospital.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-labreport',
  templateUrl: './add-labreport.component.html',
  styleUrls: ['./add-labreport.component.css'],
})
export class AddLabreportComponent implements OnInit {
  id = 0;
  imageUrl: string;
  doctorList: Doctor[];
  isSuccess = false;

  labFrm: FormGroup;
  patient: Patient;
  hospitalId: number;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private labreportService: LabreportService,
    private router: Router,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private hospitalService: HospitalService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);

    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getDoctors(this.hospitalId);
    });

    this.labFrm = this.fb.group({
      ReportType: [''],
      PatientId: this.id,
      DateReceived: [''],
      DateOfReport: [''],
      DateDrawn: [''],
      Document: [''],
      Description: ['', Validators.required],
      Date: ['', Validators.required],
      OrderedBy: ['', Validators.required],
      LabName: ['', Validators.required],
      ReportedBy: ['', Validators.required],
      LabTests: this.fb.array([]),
    });
    this.addItem();
  }

  public addItem(): void {
    const item = this.fb.group({
      TestName: [''],
      TestResultValue: [''],
      ReferanceRange: [''],
      Units: [''],
    });
    this.LabTests.push(item);
  }

  public removeItem(i: number): void {
    this.LabTests.removeAt(i);
  }

  get LabTests(): UntypedFormArray {
    return this.labFrm.get('LabTests') as UntypedFormArray;
  }

  get DateReceived() {
    return this.labFrm.get('DateReceived');
  }
  get DateOfReport() {
    return this.labFrm.get('DateOfReport');
  }

  get DateDrawn() {
    return this.labFrm.get('DateDrawn');
  }

  get TestName() {
    return this.labFrm.get('TestName');
  }
  get TestResultValue() {
    return this.labFrm.get('TestResultValue');
  }
  get ReferanceRange() {
    return this.labFrm.get('ReferanceRange');
  }
  get Units() {
    return this.labFrm.get('Units');
  }
  get ReportType() {
    return this.labFrm.get('ReportType');
  }

  get Document() {
    return this.labFrm.get('Document');
  }
  get Description() {
    return this.labFrm.get('Description');
  }
  get Date() {
    return this.labFrm.get('Date');
  }
  get OrderedBy() {
    return this.labFrm.get('OrderedBy');
  }
  get LabName() {
    return this.labFrm.get('LabName');
  }
  get ReportedBy() {
    return this.labFrm.get('ReportedBy');
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.labreportService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        // this.imageUrl = stImg
        this.imageUrl = `<p><img src="${stImg}"/></p>`;
      },
      (err) => console.log(err)
    );
  }

  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  formSubmit() {
    // let reportType = this.labFrm.value['ReportType'];
    // let testName = this.labFrm.value['TestName'];
    // let testResultValue = this.labFrm.value['TestResultValue'];
    // let referanceRange = this.labFrm.value['ReferanceRange'];
    // let units = this.labFrm.value['Units'];
    // let document = this.labFrm.value['Document'];
    // let description = this.labFrm.value['Description'];
    // let date = this.labFrm.value['Date'];
    // date = moment(date).format('MM/DD/YYYY');
    // let orderedBy = this.labFrm.value['OrderedBy'];
    // let labName = this.labFrm.value['LabName'];
    // let reportedBy = this.labFrm.value['ReportedBy'];

    // let body = {
    //   PatientId: this.id,
    //   ReportType: reportType,
    //   Document: this.imageUrl,
    //   Description: description,
    //   Date: date,
    //   OrderedBy: orderedBy,
    //   LabName: labName,
    //   ReportedBy: reportedBy,
    //   TestName: testName,
    //   TestResultValue: testResultValue,
    //   ReferanceRange: referanceRange,
    //   Units: units,
    // };
    this.labreportService
      .registerLabReport(this.labFrm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.labFrm.reset();
      });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
    }
  }
}
