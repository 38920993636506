<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>

</div>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>


        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientId}} </td>
        </ng-container>



        <ng-container matColumnDef="PatientName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> PatientName </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientName}} </td>
        </ng-container>
        <ng-container matColumnDef="BalanceAmount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> BalanceAmount </th>
            <td mat-cell *matCellDef="let row"> {{row.BalanceAmount}} </td>
        </ng-container>
        <ng-container matColumnDef="Total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Amount </th>
            <td mat-cell *matCellDef="let row"> {{row.Total}} </td>
        </ng-container>
        <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
            <td class="ml-4" mat-cell *matCellDef="let row"> {{row.Status}} </td>
        </ng-container>
        <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let row"> {{row.Date}} </td>
        </ng-container>
        <!-- 
        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> Patient Details</th>
            <td mat-cell *matCellDef="let row">
                <button type="button" [routerLink]="['/invoiceBalances/',row.PatientId]" mat-mini-fab color="success">
                    <mat-icon matPrefix>remove_red_eye</mat-icon>
                </button>
            </td>
        </ng-container> -->

        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> View Contact </th>
            <td mat-cell *matCellDef="let row">
                <button type="button" color="primary" (click)="viewContact(row.PatientId)" mat-mini-fab
                    data-toggle="modal" data-target=".bd-example-modal-lg">
                    <mat-icon>Contact</mat-icon>
                </button>

                <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog"
                    aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLongTitle">Contact </h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body " id="printable" #labReports>


                                <div class="areaStyle mb-1">
                                    <P>Patient ID: {{patient?.PatientId}} </P>

                                    <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}}
                                    </P>
                                    <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                                    <P>Patient Address: {{patient?.PatientAddress}} </P>
                                    <P>Patient Email: {{patient?.Email}} </P>
                                    <P>Patient PhoneNumber: {{patient?.PhoneNumber}} </P>
                                    <hr>
                                </div>

                            </div>
                            <hr>

                            <div class="modal-footer">
                                <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

                                <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>