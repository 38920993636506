import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WardService } from '../../services/ward.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HospitalService } from 'src/app/services/hospital.service';
import { JwtHelperService } from '@auth0/angular-jwt';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-ward',
  templateUrl: './view-ward.component.html',
  styleUrls: ['./view-ward.component.css'],
})
export class ViewWardComponent implements OnInit {
  displayedColumns: string[] = ['WardName', 'WardDescription', 'Delete'];

  dataSource: MatTableDataSource<any>;
  id = 0;

  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  constructor(
    private wardService: WardService,
    private hosptalService: HospitalService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.getWard(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getWard(id) {
    this.hosptalService.getWards(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.Wards);
      this.dataSource.paginator = this.paginator;
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getWard(this.id);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.wardService.deleteWard(id).subscribe((res) => {
      this.getWard(this.id);
    });
  }
}
