import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NurseDailyAssessmentService } from 'src/app/services/nurse-daily-assessment.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-nurse-daily-assessment',
  templateUrl: './update-nurse-daily-assessment.component.html',
  styleUrls: ['./update-nurse-daily-assessment.component.css'],
})
export class UpdateNurseDailyAssessmentComponent implements OnInit {
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  id = 0;

  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private nurseDailyAssessmentService: NurseDailyAssessmentService,
    private router: Router
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.nurseDailyAssessmentService
      .getNurseDailyAssessmentById(this.id)
      .subscribe((res) => {
        this.Frm.patchValue(res);
      });

    this.Frm = this.fb.group({
      nurseId: this.nurseId,
      PatientName: ['', Validators.required],
      PatientDOB: ['', Validators.required],
      PatientHeight: ['', Validators.required],
      PatientWeight: ['', Validators.required],
      BloodPressure: ['', Validators.required],
      Pulse: ['', Validators.required],
      IsPatientonAnyMedication: ['', Validators.required],
      IsPatientUnderAnyMedicalTreatment: ['', Validators.required],
      //Constitutional/Genaral
      RecentWeightChanges: [false],
      Chills: [false],
      Fever: [false],
      Weakness: [false],
      NoGeneralProblems: [false],
      OtherGeneralProblems: [''],
      //Eyes
      VisionChange: [false],
      GlassessOrContacts: [false],
      Catracts: [false],
      Glaucoma: [false],
      NoEyeProblems: [''],
      OtherEyeProblems: [''],
      //Ear,Nose,Throat
      HearingLoss: [false],
      EarAcheOrInfection: [false],
      RingingInEar: [false],
      NoEarProblems: [false],
      OtherEarProblems: [''],
      //Cardio Vascular
      ChestPain: [false],
      SwellingInLegs: [false],
      Palpitations: [false],
      Cyanosis: [false],
      Edema: [false],
      Rythm: [''],
      Volume: [''],
      NoCadioVascularProblems: [false],
      OtherCadioVascularProblems: [''],
      //Respiratory
      ShortnessOfBreath: [false],
      WheezingOrAsthma: [false],
      FrequentCough: [false],
      Dyspnoea: [false],
      Gasping: [false],
      Haemoptysis: [false],
      Expectorant: [false],
      Oxygen: [false],
      NoRespiratoryProblems: [false],
      OtherRespiratoryProblems: [''],
      //Gastrointestinal
      AcidReflex: [false],
      NauseaOrVomitimng: [false],
      AbdominalPain: [false],
      Tenderness: [false],
      Constipation: [false],
      Incontinence: [false],
      Diarrhea: [false],
      NoGastricProblems: [''],
      OtherGastricProblems: [''],
      DateOfLastBowelMovement: [''],
      //Muskulatal
      MuscleAches: [false],
      SwellingOfJoints: [false],
      StiffnessInJoints: [false],
      NoMuskularProblems: [false],
      OtherMuskularProblems: [''],

      //Skin
      Rash: [false],
      Ulcers: [false],
      AbnormalScars: [false],
      Dehydrated: [false],
      Cyanotic: [false],
      Pale: [false],
      Icterus: [false],
      Cold: [false],
      Redness: [false],
      Lesson: [false],
      Wound: [false],
      NoSkinProblems: [false],
      OtherSkinProblems: [''],

      //Neurological

      Headaches: [false],
      Dizziness: [false],
      NumbnessTinglingLossofSensation: [false],
      Paralysis: [false],
      Oriented: [false],
      Tremors: [false],
      Unresponsive: [false],
      Swallow: [false],
      Speech: [false],
      NoNeurologicalProblems: [false],
      OtherNeurologicalProblems: [''],

      // Psychiatric
      Depression: [false],
      Nervousness: [false],
      Anxiety: [false],
      MoodSwings: [false],
      NoPsychatricProblems: [''],
      OtherPsychatricProblems: [''],
      //Endocrine
      ExessiveThirstOrHunger: [false],
      HotColdIntolerance: [false],
      HotFlashes: [false],
      NoEndocrine: [false],
      OtherEndocrine: [''],
      //Urinary Tract
      UrenaryIncontinence: [false],
      Retention: [false],
      Hematuria: [false],
      UrineColor: [''],
      CatheterType: [''],
      CatheterInsertedOn: [''],
      NoUrenaryProblems: [false],
      OtherUrenaryProblems: [''],
      //gynecology
      Lmp: [''],
      //Fall Risk
      Vulnerable: [false],
      SideRailon: [false],
      AttendantInformed: [false],

      NurseName: [''],
    });
  }

  get NoUrenaryProblems() {
    return this.Frm.get('NoUrenaryProblems');
  }
  get OtherUrenaryProblems() {
    return this.Frm.get('OtherUrenaryProblems');
  }
  get Vulnerable() {
    return this.Frm.get('Vulnerable');
  }
  get SideRailon() {
    return this.Frm.get('SideRailon');
  }
  get AttendantInformed() {
    return this.Frm.get('AttendantInformed');
  }
  get Lmp() {
    return this.Frm.get('Lmp');
  }

  get UrenaryIncontinence() {
    return this.Frm.get('UrenaryIncontinence');
  }
  get Retention() {
    return this.Frm.get('Retention');
  }
  get Hematuria() {
    return this.Frm.get('Hematuria');
  }
  get UrineColor() {
    return this.Frm.get('UrineColor');
  }
  get CatheterType() {
    return this.Frm.get('CatheterType');
  }
  get CatheterInsertedOn() {
    return this.Frm.get('CatheterInsertedOn');
  }
  get Speech() {
    return this.Frm.get('Speech');
  }

  get Swallow() {
    return this.Frm.get('Swallow');
  }

  get Unresponsive() {
    return this.Frm.get('Unresponsive');
  }
  get Tremors() {
    return this.Frm.get('Tremors');
  }

  get Oriented() {
    return this.Frm.get('Oriented');
  }
  get Paralysis() {
    return this.Frm.get('Paralysis');
  }

  get Wound() {
    return this.Frm.get('Wound');
  }

  get Lesson() {
    return this.Frm.get('Lesson');
  }

  get Redness() {
    return this.Frm.get('Redness');
  }

  get Cold() {
    return this.Frm.get('Cold');
  }
  get Icterus() {
    return this.Frm.get('Icterus');
  }

  get Pale() {
    return this.Frm.get('Pale');
  }

  get Cyanotic() {
    return this.Frm.get('Cyanotic');
  }

  get Dehydrated() {
    return this.Frm.get('Dehydrated');
  }

  get DateOfLastBowelMovement() {
    return this.Frm.get('DateOfLastBowelMovement');
  }

  get Diarrhea() {
    return this.Frm.get('Diarrhea');
  }

  get Incontinence() {
    return this.Frm.get('Incontinence');
  }
  get Constipation() {
    return this.Frm.get('Constipation');
  }
  get Tenderness() {
    return this.Frm.get('Tenderness');
  }

  get Oxygen() {
    return this.Frm.get('Oxygen');
  }
  get Expectorant() {
    return this.Frm.get('Expectorant');
  }
  get Haemoptysis() {
    return this.Frm.get('Haemoptysis');
  }

  get Gasping() {
    return this.Frm.get('Gasping');
  }

  get Dyspnoea() {
    return this.Frm.get('Dyspnoea');
  }

  get Rythm() {
    return this.Frm.get('Rythm');
  }

  get Volume() {
    return this.Frm.get('Volume');
  }
  get Edema() {
    return this.Frm.get('Edema');
  }

  get Cyanosis() {
    return this.Frm.get('Cyanosis');
  }

  get PatientName() {
    return this.Frm.get('PatientName');
  }
  get PatientDOB() {
    return this.Frm.get('PatientDOB');
  }
  get PatientId() {
    return this.Frm.get('PatientId');
  }
  get NurseId() {
    return this.Frm.get('NurseId');
  }
  get PatientHeight() {
    return this.Frm.get('PatientHeight');
  }
  get PatientWeight() {
    return this.Frm.get('PatientWeight');
  }
  get BloodPressure() {
    return this.Frm.get('BloodPressure');
  }
  get Pulse() {
    return this.Frm.get('Pulse');
  }
  get IsPatientonAnyMedication() {
    return this.Frm.get('IsPatientonAnyMedication');
  }
  get IsPatientUnderAnyMedicalTreatment() {
    return this.Frm.get('IsPatientUnderAnyMedicalTreatment');
  }
  get RecentWeightChanges() {
    return this.Frm.get('RecentWeightChanges');
  }
  get Chills() {
    return this.Frm.get('Chills');
  }
  get Fever() {
    return this.Frm.get('Fever');
  }
  get Weakness() {
    return this.Frm.get('Weakness');
  }
  get NoGeneralProblems() {
    return this.Frm.get('NoGeneralProblems');
  }
  get OtherGeneralProblems() {
    return this.Frm.get('OtherGeneralProblems');
  }
  get VisionChange() {
    return this.Frm.get('VisionChange');
  }
  get GlassessOrContacts() {
    return this.Frm.get('GlassessOrContacts');
  }
  get Catracts() {
    return this.Frm.get('Catracts');
  }
  get Glaucoma() {
    return this.Frm.get('Glaucoma');
  }
  get NoEyeProblems() {
    return this.Frm.get('NoEyeProblems');
  }
  get OtherEyeProblems() {
    return this.Frm.get('OtherEyeProblems');
  }
  get HearingLoss() {
    return this.Frm.get('HearingLoss');
  }
  get EarAcheOrInfection() {
    return this.Frm.get('EarAcheOrInfection');
  }
  get RingingInEar() {
    return this.Frm.get('RingingInEar');
  }
  get NoEarProblems() {
    return this.Frm.get('NoEarProblems');
  }
  get OtherEarProblems() {
    return this.Frm.get('OtherEarProblems');
  }
  get ChestPain() {
    return this.Frm.get('ChestPain');
  }
  get SwellingInLegs() {
    return this.Frm.get('SwellingInLegs');
  }
  get Palpitations() {
    return this.Frm.get('Palpitations');
  }
  get NoCadioVascularProblems() {
    return this.Frm.get('NoCadioVascularProblems');
  }
  get OtherCadioVascularProblems() {
    return this.Frm.get('OtherCadioVascularProblems');
  }
  get ShortnessOfBreath() {
    return this.Frm.get('ShortnessOfBreath');
  }
  get WheezingOrAsthma() {
    return this.Frm.get('WheezingOrAsthma');
  }
  get FrequentCough() {
    return this.Frm.get('FrequentCough');
  }
  get NoRespiratoryProblems() {
    return this.Frm.get('NoRespiratoryProblems');
  }
  get OtherRespiratoryProblems() {
    return this.Frm.get('OtherRespiratoryProblems');
  }
  get AcidReflex() {
    return this.Frm.get('AcidReflex');
  }
  get NauseaOrVomitimng() {
    return this.Frm.get('NauseaOrVomitimng');
  }
  get AbdominalPain() {
    return this.Frm.get('AbdominalPain');
  }
  get NoGastricProblems() {
    return this.Frm.get('NoGastricProblems');
  }
  get OtherGastricProblems() {
    return this.Frm.get('OtherGastricProblems');
  }
  get MuscleAches() {
    return this.Frm.get('MuscleAches');
  }
  get NoMuskularProblems() {
    return this.Frm.get('NoMuskularProblems');
  }

  get OtherMuskularProblems() {
    return this.Frm.get('OtherMuskularProblems');
  }

  get Rash() {
    return this.Frm.get('Rash');
  }

  get Ulcers() {
    return this.Frm.get('Ulcers');
  }

  get AbnormalScars() {
    return this.Frm.get('AbnormalScars');
  }

  get NoSkinProblems() {
    return this.Frm.get('NoSkinProblems');
  }

  get OtherSkinProblems() {
    return this.Frm.get('OtherSkinProblems');
  }

  get Headaches() {
    return this.Frm.get('Headaches');
  }

  get Dizziness() {
    return this.Frm.get('Dizziness');
  }

  get NumbnessTinglingLossofSensation() {
    return this.Frm.get('NumbnessTinglingLossofSensation');
  }

  get NoNeurologicalProblems() {
    return this.Frm.get('NoNeurologicalProblems');
  }

  get OtherNeurologicalProblems() {
    return this.Frm.get('OtherNeurologicalProblems');
  }

  get Depression() {
    return this.Frm.get('Depression');
  }

  get Nervousness() {
    return this.Frm.get('Nervousness');
  }

  get Anxiety() {
    return this.Frm.get('Anxiety');
  }
  get MoodSwings() {
    return this.Frm.get('MoodSwings');
  }
  get NoPsychatricProblems() {
    return this.Frm.get('NoPsychatricProblems');
  }
  get OtherPsychatricProblems() {
    return this.Frm.get('OtherPsychatricProblems');
  }
  get ExessiveThirstOrHunger() {
    return this.Frm.get('ExessiveThirstOrHunger');
  }
  get HotColdIntolerance() {
    return this.Frm.get('HotColdIntolerance');
  }
  get HotFlashes() {
    return this.Frm.get('HotFlashes');
  }
  get NoEndocrine() {
    return this.Frm.get('NoEndocrine');
  }
  get OtherEndocrine() {
    return this.Frm.get('OtherEndocrine');
  }

  formSubmit() {
    this.nurseDailyAssessmentService
      .updateNurseDailyAssessment(this.id, this.Frm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }
}
