<div class="container mt-5">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>Communication - Assessment</h2>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">
                <div class="row">
                    <div class="col-lg-4">

                        <div class="row">
                            <p class="ml-5">Interpreter Required</p>
                            <mat-radio-group formControlName="InterpreterRequired" aria-label="Select an option my-3">
                                <mat-radio-button class="mx-3" value="true">Yes</mat-radio-button>
                                <mat-radio-button class="mx-3 " value="false"> No</mat-radio-button>
                            </mat-radio-group>
                        </div>

                    </div>
                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Primary Language</mat-label>
                            <input type="text" formControlName="PrimaryLanguage" matInput>
                            <mat-error>Please enter Primary Language</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">

                        <mat-form-field class="example-full-width">
                            <mat-label>Preffered Language</mat-label>
                            <input type="text" formControlName="PrefferedLanguage" matInput>
                            <mat-error>Please enter Preffered Language</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <mat-checkbox color="primary" formControlName="AbleToRead">
                            AbleToRead
                        </mat-checkbox>
                    </div>
                    <div class="col-md-6">
                        <mat-checkbox color="primary" formControlName="None">
                            None
                        </mat-checkbox>
                    </div>
                </div>

                <h2>Language Barriers</h2>
                <div class="row">


                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="AgeRelated">
                                Age Related
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Auditory">
                                Auditory
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Cognitive">
                                Cognitive
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Cultural">
                                Cultural
                            </mat-checkbox>
                        </div>


                        <div class="col-md-1">
                            <mat-checkbox color="primary" formControlName="Emotional">
                                Emotional
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="EnergyLevel">
                                EnergyLevel
                            </mat-checkbox>
                        </div>


                        <div class="col-md-1">
                            <mat-checkbox color="primary" formControlName="Financial">
                                Financial
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Motivation">
                                Motivation
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Physical">
                                Physical
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="ReadingSkills">
                                ReadingSkills
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Religion">
                                Religion
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Visual">
                                Visual
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="InstructionsRefused">
                                Instructions Refused
                            </mat-checkbox>
                        </div>
                    </div>



                </div>

                <h2>Communiction Tools</h2>
                <div class="row">


                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="FacialExpression">
                                Facial Expression
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="LetterBoard">
                                Letter Board
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="PictireBoard">
                                Pictire Board
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="PhysicalGestures">
                                Physical Gestures
                            </mat-checkbox>
                        </div>


                        <div class="col-md-1">
                            <mat-checkbox color="primary" formControlName="WritingTablet">
                                Writing Tablet
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="SignLanguage">
                                Sign Language
                            </mat-checkbox>
                        </div>


                        <div class="col-md-1">
                            <mat-checkbox color="primary" formControlName="LipReading">
                                Lip Reading
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">




                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="LargePrintLiterature">
                                Large Print Literature
                            </mat-checkbox>
                        </div>


                        <div class="col-md-1">
                            <mat-checkbox color="primary" formControlName="Braille">
                                Braille
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="VoiceProsthesis">
                                Voice Prosthesis
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="SpeakingValve">
                                Speaking Valve
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="ElectroLarinx">
                                Electro Larinx
                            </mat-checkbox>
                        </div>

                        <div class="col-md-1">
                            <mat-checkbox color="primary" formControlName="Amplifier">
                                Amplifier
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="ElectronicEquipment">
                                Electronic Equipment
                            </mat-checkbox>
                        </div>
                    </div>



                </div>

                <h2>CommunicationBarriers</h2>
                <div class="row">


                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoBarriers">
                                No Barriers
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Blind">
                                Blind
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Deaf">
                                Deaf
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="VisualBarriers">
                                Visual Barriers
                            </mat-checkbox>
                        </div>


                        <div class="col-md-1">
                            <mat-checkbox color="primary" formControlName="CognitiveBarriers">
                                Cognitive Barriers
                            </mat-checkbox>
                        </div>


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="CulturalBarriers">
                                Cultural Barriers
                            </mat-checkbox>
                        </div>


                        <div class="col-md-1">
                            <mat-checkbox color="primary" formControlName="MotivationBarriers">
                                Motivation Barriers
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <mat-form-field class="example-full-width">
                            <mat-label>Comments</mat-label>
                            <input type="text" formControlName="CommunicationComments" matInput>
                            <mat-error>Please enter Comments</mat-error>
                        </mat-form-field>
                    </div>

                </div>


                <!-- </div> -->
                <button class="float-right lgBtCl" mat-button>
                    <!-- <mat-icon>save</mat-icon> -->
                    Save</button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Prescription
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>