import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Clipboard } from '@angular/cdk/clipboard';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-doctor-custom-template',
  templateUrl: './doctor-custom-template.component.html',
  styleUrls: ['./doctor-custom-template.component.css'],
})
export class DoctorCustomTemplateComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  doctorId = 0;
  Doctor: Doctor;

  templateText: string = 'Hello John Doe';
  editorData: FormControl = new FormControl('Hello %user_name%');
  // editorData: FormControl = new FormControl('Hello %user_name%');
  fieldsAvailable = [
    { key: 'user_name', example: 'John Doe' },
    { key: 'user_age', example: '20 years' },
    { key: 'user_email', example: 'john@mail.com' },
    { key: 'app_name', example: 'My App' },
    { key: 'app_url', example: 'https://google.com' },
  ];
  constructor(
    private clipboard: Clipboard,
    private doctorService: DoctorService
  , private tokenService: TokenService) {}
  ngOnInit() {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);

    this.editorData.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe((v) => this.updateText());
  }

  updateText() {
    if (this.editorData.value !== null) {
      this.templateText = this.editorData.value.replace(
        /%(\w+)%/g, // this is the regex to replace %variable%
        (match, field) => {
          const ex = this.fieldsAvailable.find((f) => f.key === field);
          if (ex) {
            return ex.example;
          }
          return match;
        }
      );
      this.templateText.trim();
    }
  }
  copyField(field) {
    this.clipboard.copy(`%${field.key}%`);
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.Doctor = res;
    });
  }
}
