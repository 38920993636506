import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITool, IViewerProvider } from 'ngx-dicom';
import { IDicomConfig, ToolModeEnum } from 'ngx-dicom';
import { RadiologyMri } from 'src/app/Models/radiologymri.model';
import { MriService } from 'src/app/services/mri.service';
import { PatientService } from 'src/app/services/patient.service';
import { environment } from 'src/environments/environment';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-mri-multiple',
  templateUrl: './view-mri-multiple.component.html',
  styleUrls: ['./view-mri-multiple.component.css'],
})
export class ViewMriMultipleComponent {
  imageUrl: { series: string; images: string[] }[] = [];
  hideDicom: boolean = true;

  config: IDicomConfig = {
    fileUrl: '',
    tools: [
      {
        name: 'StackScrollMouseWheel',
        options: { mouseButtonMask: 1 },
        mode: ToolModeEnum.Active,
      },
    ],
    classList: 'canvas-container',
  };

  viewerProvider: IViewerProvider | undefined;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private mriService: MriService,
    private patientService: PatientService,
    private tokenService: TokenService
  ) {}

  id = 0;
  isSuccess = false;
  uploadProgress: number = 0;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.getPatientMri(this.id);
  }

  loadUrl(image: string): void {
    this.config.fileUrl = image;
    this.hideDicom = false;
    setTimeout(() => (this.hideDicom = true));
  }

  getPatientMri(patientId: number): void {
    this.patientService.getRadiologyMRI(patientId).subscribe(
      (res) => {
        console.log('Response:', res.RadiologyMris);

        if (res?.RadiologyMris && Array.isArray(res.RadiologyMris)) {
          res.RadiologyMris.forEach((item) => {
            // The folder URL for the series
            const seriesPath = 'Series001';
            this.patientService.getImages().subscribe(
              (images: any[]) => {
                // Log the images for debugging
                console.log(`Images for ${item.series}:`, images);

                // Process and display images dynamically
                this.imageUrl.push({
                  series: item.series,
                  images: images.map((img) => img.Url),
                });
              },
              (error) => {
                console.error(
                  `Error fetching images for ${item.series}:`,
                  error
                );
              }
            );
          });
        } else {
          console.warn('No RadiologyMris data available for this patient.');
          this.imageUrl = [];
        }
      },
      (error) => {
        console.error('Error fetching MRI data:', error);
      }
    );
  }

  image(e: any): void {
    const formData = new FormData();
    const files: FileList = e.target.files;

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i], files[i].webkitRelativePath);
    }

    formData.append('patientId', this.id.toString());

    this.mriService.imageUpload(formData).subscribe(
      (res: HttpEvent<any>) => {
        if (res.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round((100 * res.loaded) / res.total);
        } else if (res.type === HttpEventType.Response) {
          console.log('Upload successful:', res.body);
          this.getPatientMri(this.id); // Refresh the displayed images
        }
      },
      (error) => {
        console.error('Upload error:', error);
      }
    );
  }

  delete(id: number): void {
    this.mriService.deleteRadiologyMri(id).subscribe(() => {
      this.getPatientMri(this.id);
    });
  }
}
