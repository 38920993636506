import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Labreport } from 'src/app/Models/labreport.model';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-lab-test-chart',
  templateUrl: './lab-test-chart.component.html',
  styleUrls: ['./lab-test-chart.component.css'],
})
export class LabTestChartComponent implements OnInit {
  labTestChart: any = {
    xAxis: {
      type: 'datetime',
    },
    series: [],
    title: {
      text: 'Lab Results Graph',
    },
    chart: {
      type: 'line',
    },
    tooltip: {
      shared: true,
      x: {
        format: 'dd MMM yyyy', // Format the date in the tooltip
      },
    },
  };

  id = 0;
  labReports: Labreport[] = [];

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
      this.getlabReport(this.id);
    });
  }

  getlabReport(id: number) {
    this.patientService.getPatienLabReport(id).subscribe((res) => {
      this.labReports = res.LabReports; // Directly assigning the response for clarity
      this.labTestChart.series = []; // Reset series before processing

      if (this.labReports?.length > 0) {
        const testMap = new Map<string, any[]>(); // Map to group data by TestName

        this.labReports.forEach((report) => {
          if (report.LabTests?.length > 0) {
            report.LabTests.forEach((test) => {
              const testName = test.TestName;
              const testResult = parseFloat(test.TestResultValue); // Convert to number
              const reportDate = new Date(report.DateOfReport).getTime(); // Convert date to timestamp

              if (!isNaN(testResult) && reportDate) {
                if (!testMap.has(testName)) {
                  testMap.set(testName, []); // Initialize if not already present
                }
                testMap.get(testName).push([reportDate, testResult]); // Push data
              }
            });
          }
        });

        // Populate series from the testMap
        testMap.forEach((data, name) => {
          this.labTestChart.series.push({
            name: name,
            data: data, // Sorted by date implicitly from API
          });
        });

        // Ensure Angular detects changes
        this.labTestChart.series = [...this.labTestChart.series];
      }
    });
  }
}
