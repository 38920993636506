import { Component, OnInit, ViewChild } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorTemplateService } from 'src/app/services/doctor-template.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Observable } from 'rxjs';
import { DoctorTemplate } from 'src/app/Models/doctortemplate.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-doctor-template-list',
  templateUrl: './doctor-template-list.component.html',
  styleUrls: ['./doctor-template-list.component.css'],
})
export class DoctorTemplateListComponent implements OnInit {
  displayedColumns: string[] = ['Name', 'Update', 'Delete'];

  dataSource: MatTableDataSource<any>;

  templates: any[] = [];
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  templateContent: any;
  filteredList: Observable<any[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private templateService: DoctorTemplateService,
    private doctorService: DoctorService,
    private clipboard: Clipboard
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);

    this.loadTemplates(this.doctorId);
  }

  loadTemplates(id) {
    this.doctorService.getDoctorTemplates(id).subscribe((data) => {
      this.templates = data.DoctorTemplates;
      this.dataSource = new MatTableDataSource(data.DoctorTemplates);
      this.dataSource.paginator = this.paginator;
    });
  }

  loadTemplate(id: number) {
    this.templateService.getTemplate(id).subscribe((template) => {
      this.templateContent = template.Content.replace(/\n/g, '<br/>');
      // Handle the template loading logic here
      // template.Content.replace(/\n/g, '<br/>');
    });
  }
  copyField(field) {
    this.clipboard.copy(`%${field.key}%`);
  }

  copyTemplate() {
    const templateHtml = this.templateContent.nativeElement.innerHTML;

    navigator.clipboard
      .writeText(templateHtml)
      .then(() => {
        alert('Template content copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  }
  copy(e) {
    this.clipboard.copy(e);
  }

  delete(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.templateService.deleteTemplate(id).subscribe((res) => {
        this.loadTemplates(this.doctorId);
      });
    }
  }

  search(searchValue) {
    if (searchValue.value != null) {
      this.templates = this.templates.filter((x) =>
        x.Name.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
