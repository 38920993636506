import { Component, Input, OnInit } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  constructor(private tokenService: TokenService) { }

  ngOnInit(): void {
  }
  @Input('error_message') errormessage:string
}
