import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NurseTemplateService } from 'src/app/services/nurse-template.service';
import { NurseService } from 'src/app/services/nurse.service';
import { environment } from 'src/environments/environment';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-nurse-templates',
  templateUrl: './add-nurse-templates.component.html',
  styleUrls: ['./add-nurse-templates.component.css'],
})
export class AddNurseTemplatesComponent implements OnInit {
  id = 0;
  nurseId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();

  isSuccess = false;

  constructor(
    private templateService: NurseTemplateService,
    private nurseService: NurseService
  , private tokenService: TokenService) {}
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);
  }

  template = {
    name: '',
    shortcut: '',
    // content: '',
  };
  question_image(event) {
    // console.log(e)
    let formData = new FormData();
    let files = event.target.files;
    formData.append('file', files[0], files[0].name);

    this.nurseService.imageUpload(formData).subscribe(
      (res) => {
        let qImag = `<p><img src="${environment.imgurl + res['path']}"/></p>`;
        // let stImg = environment.imgurl + res['path'];

        this.editorContent.setValue(this.editorContent.value + qImag);
      },
      (err) => console.log(err)
    );
  }

  editorContent: any = ''; // Your editor content

  // Update the content on every change in the editor
  onContentChanged(event: any) {
    this.editorContent = event.html;
    //   let cleanedContent = this.editorContent.replace(/<\/?p[^>]*>/gi, '\n');

    //   cleanedContent = cleanedContent.replace(/<\/?strong[^>]*>/gi, '');

    //   cleanedContent = cleanedContent.replace(/<\/?[^>]+(>|$)/g, '');

    //   cleanedContent = cleanedContent.replace(/\n\s*\n/g, '\n').trim();

    //   this.editorContent = cleanedContent;
  }
  saveTemplate() {
    let body = {
      ...this.template,
      Content: this.editorContent,
      NurseId: this.nurseId,
    };

    this.templateService.saveTemplate(body).subscribe((response) => {
      this.isSuccess = true;
    });
  }
}
// Content: this.editorContent.replace(/<\/?p[^>]*>/g, ''),
// Content: this.editorContent.replace(/<\/?p[^>]*>/g, '\n'),
