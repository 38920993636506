<div class="d-flex flex-column p-1 p-sm-4">
    <h4 class="text-center">NURSING ASSESSMENT</h4>
    <mat-horizontal-stepper [linear]="'true'" #stepper color="primary">

        <form (keydown.enter)="$event.preventDefault()" [formGroup]="nursingAssessmentForm">
            <mat-step label="NURSING ASSESSMENT FORM 1">
                <div class="block">
                    <div class="row">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" value="true" formControlName="DOA">
                                Death On Arrival
                            </mat-checkbox>
                        </div>
                        <div class="col-md-5">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Bed No.</mat-label>
                                <input matInput placeholder="Enter Bed No." formControlName="BedNo">
                                <mat-icon matSuffix>bed</mat-icon>
                                <mat-error>Required</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-5">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Treating Doctor</mat-label>
                                <input matInput placeholder="Doctor Name" formControlName="TreatingDoctor">
                                <mat-icon matSuffix>person</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Id Band</mat-label>
                                <input matInput placeholder="Enter ID band no" formControlName="IdBand">
                                <mat-icon matSuffix>healing</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Date</mat-label>
                                <input matInput [matDatepicker]="datePicker" formControlName="Date">
                                <mat-datepicker-toggle matIconSuffix [for]="datePicker"></mat-datepicker-toggle>
                                <mat-datepicker #datePicker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <div class="mat-form-field">
                                <mat-form-field appearance="fill" class="w-100">
                                    <mat-label>Time</mat-label>
                                    <input matInput [ngxTimepicker]="timePicker" formControlName="Time">
                                    <mat-icon matSuffix>time</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Department</mat-label>
                                <input matInput placeholder="Department Name" formControlName="Department">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2  reason-for-admission col__left">
                            <h4>
                                Reason <br> for <br> Admission
                            </h4>
                        </div>
                        <div class="col-sm-10">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Reason for Admission</mat-label>
                                <textarea matInput placeholder="Add Reason for admission"
                                    formControlName="ReasonForAdmission"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2 col__left">
                            <h4>
                                Allergies
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-4 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Food</mat-label>
                                        <input matInput placeholder="Food" formControlName="Food">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Drug</mat-label>
                                        <input matInput placeholder="Drug" formControlName="Drug">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Blood Transfusion</mat-label>
                                        <input matInput placeholder="Blood Transfusion"
                                            formControlName="BloodTranfusion">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2  reason-for-admission col__left">
                            <h4>
                                Previous History
                            </h4>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>DM</mat-label>
                                        <input matInput placeholder="DM" formControlName="DM">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>HTN</mat-label>
                                        <input matInput placeholder="HTN" formControlName="HTN">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>BA</mat-label>
                                        <input matInput placeholder="BA" formControlName="BA">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>TB</mat-label>
                                        <input matInput placeholder="TB" formControlName="TB">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Epilepsy</mat-label>
                                        <input matInput placeholder="Epilepsy" formControlName="Epilepsy">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Cardiac</mat-label>
                                        <input matInput placeholder="Cardiac" formControlName="Cardiac">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Others</mat-label>
                                        <input matInput placeholder="OthersPreviousMedicalHistory"
                                            formControlName="OthersPreviousMedicalHistory">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2 col__left">
                            <h4>
                                Previous <br> Surgeries
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Previous Surgeries</mat-label>
                                <textarea matInput placeholder="Add Previous Surgeries"
                                    formControlName="PreviousSurgeries"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2 col__left">
                            <h4>
                                Diet <br> & <br> Nutrition
                            </h4>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Veg</mat-label>
                                        <input matInput formControlName="Veg" placeholder="Veg">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Egg</mat-label>
                                        <input matInput formControlName="Egg" placeholder="Egg">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Non Veg</mat-label>
                                        <input matInput formControlName="NonVeg" placeholder="Non Veg">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Garlic free</mat-label>
                                        <input matInput formControlName="GarlicFree" placeholder="Garlic free">
                                    </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Onion free</mat-label>
                                        <input matInput formControlName="OnionFree" placeholder="Onion free">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Others</mat-label>
                                        <input matInput formControlName="OthersDietAndNutrition" placeholder="Others">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2  reason-for-admission col__left">
                            <h4>
                                Habits <br> (since)
                            </h4>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col-md-3 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Smoking</mat-label>
                                        <input matInput formControlName="Smoking" placeholder="Smoking">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Alcohol</mat-label>
                                        <input matInput formControlName="Alcohol" placeholder="Alcohol">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Tobbaco</mat-label>
                                        <input matInput formControlName="Tobbaco" placeholder="Tobbaco">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Others</mat-label>
                                        <input matInput formControlName="OthersHabits" placeholder="Others">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-md-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Dentures</mat-label>
                                <input matInput formControlName="Dentures" placeholder="Dentures">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Contact Lens</mat-label>
                                <input matInput formControlName="ContactLens" placeholder="Contact Lens">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Hearing Aids</mat-label>
                                <input matInput formControlName="HearingAid" placeholder="Hearing Aids">
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Others</mat-label>
                                <input matInput formControlName="OtherDependentTech" placeholder="Others">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2  reason-for-admission col__left">
                            <h4>
                                Others
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Others</mat-label>
                                <textarea matInput placeholder="Others" formControlName="Others"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2  reason-for-admission col__left">
                            <h4>
                                Orientation
                            </h4>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div class="col-md-3 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Rooms</mat-label>
                                        <input matInput formControlName="Rooms" placeholder="Rooms">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Belongings</mat-label>
                                        <input matInput formControlName="Belongings" placeholder="Belongings">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-3 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Visiting Hours</mat-label>
                                        <input [ngxTimepicker]="picker" matInput formControlName="VisitingTime">
                                        <ngx-material-timepicker #picker></ngx-material-timepicker>

                                    </mat-form-field>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Rights & Resoponsibility</mat-label>
                                        <input matInput formControlName="RightAndResponsibilities"
                                            placeholder="Rights & Resoponsibility">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2  col__left">
                            <h4>
                                Nursing <br> Plan of <br> Care
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Nursing Plan</mat-label>
                                <textarea [rows]="'10'" matInput placeholder="Nursing Plan"
                                    formControlName="NursingPlanOfCare"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="mt-3">
                    <button mat-raised-button color="primary" matStepperNext>Next</button>
                </div>
            </mat-step>
            <mat-step label="NURSING ASSESSMENT FORM 2">
                <div class="block">
                    <div class="row">
                        <div class="col-sm-4  d-flex justify-content-center align-items-center col__left">
                            <h4 class="">
                                Patient Label
                            </h4>
                        </div>
                        <div class="col-sm-8">
                            <div class="row">
                                <div class="col-md-6 col__left ">
                                    <div class="col-md-3 w-100">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>Date</mat-label>
                                            <input matInput formControlName="PatientLabelDate"
                                                [matDatepicker]="datePicker2">
                                            <mat-datepicker-toggle matIconSuffix
                                                [for]="datePicker2"></mat-datepicker-toggle>
                                            <mat-datepicker #datePicker2></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 w-100">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>Time</mat-label>
                                            <input matInput formControlName="PatientLabelTime"
                                                [ngxTimepicker]="timePicker2">
                                            <mat-icon matSuffix>time</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="col-md-3 w-100">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>Treating Doctor</mat-label>
                                            <input matInput formControlName="PatientLabelTreatingDoctor"
                                                placeholder="Treating Doctor">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 w-100">
                                        <mat-form-field appearance="fill" class="w-100">
                                            <mat-label>Department</mat-label>
                                            <input matInput formControlName="PatientLabelDepartment"
                                                placeholder="Department">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2  reason-for-admission col__left">
                            <h4>
                                Temp
                            </h4>
                        </div>
                        <div class="col-md-2 col__left">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>HR</mat-label>
                                <input matInput formControlName="HR" placeholder="HR">
                            </mat-form-field>
                        </div>
                        <div class="col-md-2 col__left">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>RR</mat-label>
                                <input matInput formControlName="RR" placeholder="RR">
                            </mat-form-field>
                        </div>
                        <div class="col-md-2 col__left">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>BP</mat-label>
                                <input matInput formControlName="BP" placeholder="BP">
                            </mat-form-field>
                        </div>
                        <div class="col-md-2 col__left">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Ht</mat-label>
                                <input matInput formControlName="Ht" placeholder="Ht">
                            </mat-form-field>
                        </div>
                        <div class="col-md-2 ">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Wt</mat-label>
                                <input matInput formControlName="Wt" placeholder="Wt">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2 d-flex justify-content-between flex-column align-items-start col__left">
                            <h4>
                                Pain
                            </h4>
                            <h4>
                                Assessment
                            </h4>
                        </div>
                        <div class="col-sm-10">
                            <div class="row">
                                <div class="col-sm-3 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Location</mat-label>
                                        <textarea [rows]="'4'" matInput formControlName="Location"
                                            placeholder="Location"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-3 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Severity</mat-label>
                                        <textarea [rows]="'4'" matInput formControlName="Severity"
                                            placeholder="Severity"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-3 col__left">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Intervention</mat-label>
                                        <textarea [rows]="'4'" matInput formControlName="Intervention"
                                            placeholder="Intervention"></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-3">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Assessment Frequency</mat-label>
                                        <textarea [rows]="'4'" matInput formControlName="AssessmentFrequency"
                                            placeholder="Assessment Frequency"></textarea>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-md-2 d-flex justify-content-between flex-column align-items-start col__left">
                            <h4>
                                CNS
                            </h4>
                            <h4>
                                Assessment
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Concious">
                                        Conscious
                                    </mat-checkbox>
                                    <!-- <mat-checkbox class="example-margin" [(ngModel)]="checked"
                                        [(indeterminate)]="indeterminate" [labelPosition]="labelPosition"
                                        [disabled]="disabled">
                                        I'm a checkbox
                                    </mat-checkbox> -->
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Dizziness">
                                        Dizziness
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Disoriented">
                                        Disoriented
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Unresponsive">
                                        Unresponsive
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Oriented">
                                        Oriented
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-1">
                                    <mat-checkbox color="primary" formControlName="Speech">
                                        Speech
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-1">
                                    <mat-checkbox color="primary" formControlName="Swallow">
                                        Swallow
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="PupilAssessment">
                                        PupilAssessment
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Gcs">
                                        Gcs
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-1">
                                    <mat-form-field class="example-full-width">
                                        <mat-label> GcsScore</mat-label>
                                        <input type="text" formControlName="GcsScore" matInput>
                                        <mat-error>Please enter GcsScore</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row d-flex align-items-baseline">
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Tremors">
                                        Tremors
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Paralysis">
                                        Paralysis
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Convulsions">
                                        Convulsion
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Others</mat-label>
                                        <textarea [rows]="'1'" formControlName="OthersCNSAssessment" matInput
                                            placeholder="Others"></textarea>
                                    </mat-form-field>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-md-2 d-flex justify-content-between flex-column align-items-start col__left">
                            <h4>
                                Cardiovascular
                            </h4>
                            <h4>
                                Assessment
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-2">

                                    <mat-checkbox color="primary" formControlName="Cyanosis">
                                        Cyanosis
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Rythm</mat-label>
                                        <input matInput formControlName="Rythm" placeholder="Regular / Irregular">
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Volume</mat-label>
                                        <input matInput formControlName="Volume" placeholder="Weak / Bounding">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-checkbox color="primary" formControlName="ChestPain">
                                        Chest Pain
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-6">
                                    <mat-checkbox color="primary" formControlName="Edema">
                                        Edema
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-md-2 d-flex justify-content-between flex-column align-items-start col__left">
                            <h4>
                                Respiratory
                            </h4>
                            <h4>
                                Assessment
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Oxygen">
                                        Oxygen
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Normal">
                                        Normal
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Dyspnea">
                                        Dyspnoea
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Wheeze">
                                        Wheeze
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Gasping">
                                        Gasping
                                    </mat-checkbox>
                                </div>


                            </div>
                            <div class="row d-flex align-items-baseline">
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Cough">
                                        Cough
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Haemoptysis">
                                        Haemoptysis
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Expectorant">
                                        Expectorant
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Other</mat-label>
                                        <input matInput formControlName="OthersRespiratoryAssessment"
                                            placeholder="Other">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-md-2 d-flex justify-content-between flex-column align-items-start col__left">
                            <h4>
                                GIT
                            </h4>
                            <h4>
                                Assessment
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="NormalGitAssessment">
                                        Normal
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="NauseaOrVomiting">
                                        <span style="word-break: break-word;">Nausea / Vomiting</span>
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="AbdominalDistension">
                                        Abdominal Distention
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Tenderness">
                                        Tenderness
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="row d-flex align-items-baseline">
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Diarrhea">
                                        Diarrhea
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Constipation">
                                        Constipation
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Incontinence">
                                        Incontinence
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">

                                    <mat-form-field class="example-full-width">
                                        <mat-label>Last Bowel Movement</mat-label>
                                        <input type="date" formControlName="LastBowelMovement" matInput>
                                    </mat-form-field>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-md-2 d-flex justify-content-between flex-column align-items-start col__left">
                            <h4>
                                Urinary Tract
                            </h4>
                            <h4>
                                Assessment
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="NormalUrinaryAssessment">
                                        Normal
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="UrinaryAssessmentIncontinence">
                                        Incontinence
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Retention">
                                        Retention
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="Hematuria">
                                        Hematuria
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <mat-checkbox color="primary" formControlName="UrineColor">
                                        UrineColor
                                    </mat-checkbox>
                                </div>
                            </div>
                            <div class="row d-flex align-items-baseline">
                                <div class="col w-100">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Catheter Type</mat-label>
                                        <input matInput formControlName="CatheterType" placeholder="Catheter Type">
                                    </mat-form-field>
                                </div>
                                <div class="col w-100">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Inserted On</mat-label>
                                        <input matInput formControlName="InsertedOn" placeholder="Inserted on">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-md-2 d-flex justify-content-between flex-column align-items-start col__left">
                            <h4>
                                Skin
                            </h4>
                            <h4>
                                Assessment
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="NormalSkinAssessment">
                                        Normal
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Pale">
                                        Pale
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Cyanotic">
                                        Cyanotic
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Icterus">
                                        Icterus
                                    </mat-checkbox>
                                </div>


                            </div>
                            <div class="row d-flex align-items-baseline">
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Dehydrated">
                                        Dehydrated
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Sweaty">
                                        Sweaty
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Cold">
                                        Cold
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Abrasion">
                                        Abrasion
                                    </mat-checkbox>
                                </div>
                                <div class="col-2">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Redness</mat-label>
                                        <input matInput formControlName="Redness" placeholder="Redness">
                                    </mat-form-field>
                                </div>
                                <div class="col-2">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Lession</mat-label>
                                        <input matInput formControlName="Lession" placeholder="Lession">
                                    </mat-form-field>
                                </div>
                                <div class="col-2">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Wound</mat-label>
                                        <input matInput formControlName="Wound" placeholder="Wound">
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-form-field appearance="fill" class="w-100">
                                        <mat-label>Other</mat-label>
                                        <input matInput formControlName="OtherSkinAssessment" placeholder="Other">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2 col__left">
                            <h4>
                                gynecology
                            </h4>
                        </div>
                        <div class="col-sm-10">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>LMP</mat-label>
                                <textarea matInput formControlName="GynecologyLmp" placeholder="LMP"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-md-2 d-flex justify-content-between flex-column align-items-start col__left">
                            <h4>
                                Fall Risk
                            </h4>
                            <h4>
                                Assessment
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <div class="row">
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" formControlName="Vulnerable">
                                        Vulnerable
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" value="true" formControlName="SideRails">
                                        Side Rails on
                                    </mat-checkbox>
                                </div>
                                <div class="col-md-3">
                                    <mat-checkbox color="primary" value="true" formControlName="AttendantInformed">
                                        Attendant Informed
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block block__top">
                    <div class="row">
                        <div class="col-sm-2 col__left">
                            <h4>
                                Mediation <br> Reconcillation
                            </h4>
                        </div>
                        <div class="col-md-10">
                            <mat-form-field appearance="fill" class="w-100">
                                <mat-label>Mediation Reconcillation</mat-label>
                                <textarea [rows]="'10'" formControlName="MedicationReconciliation" matInput
                                    placeholder="Mediation Reconcillation"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <button mat-raised-button color="primary" matStepperPrevious>Previous</button>
                    <button mat-raised-button color="primary" class="mx-3" matStepperNext>Next</button>
                </div>
                <div class="d-flex justify-content-end w-100">
                    <button mat-raised-button color="primary" (click)="save()">Save</button>
                </div>
            </mat-step>

        </form>
    </mat-horizontal-stepper>

</div>

<ngx-material-timepicker #timePicker></ngx-material-timepicker>
<ngx-material-timepicker #timePicker2></ngx-material-timepicker>