import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmergencycontactService } from '../../services/emergencycontact.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-emergency-contact',
  templateUrl: './add-emergency-contact.component.html',
  styleUrls: ['./add-emergency-contact.component.css']
})
export class AddEmergencyContactComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();
id=0;
isSuccess= false;
  constructor(private fb:UntypedFormBuilder,private route:ActivatedRoute,private emContactService:EmergencycontactService,private router:Router, private tokenService: TokenService) { }

  ngOnInit(): void {
    this.route.params.subscribe(res=>{
      this.id = res['id'];
    })
  }


  emgencyFrm = this.fb.group({
    EmergencyContactName:['',Validators.required],
    EmergencyContactPhone:['',Validators.required],
    EmergencyContactRelation:['',Validators.required],
  })

  get EmergencyContactName (){
return this.emgencyFrm.get('EmergencyContactName');
  }
  get EmergencyContactPhone (){
return this.emgencyFrm.get('EmergencyContactPhone');
  }
  get EmergencyContactRelation (){
return this.emgencyFrm.get('EmergencyContactRelation');
  }

  formSubmit(){

    let emContactName= this.emgencyFrm.value['EmergencyContactName']
    let emPhone= this.emgencyFrm.value['EmergencyContactPhone']
    let emRelation= this.emgencyFrm.value['EmergencyContactRelation']

    let body ={
      "PatientId":this.id,
      "EmergencyContactName":emContactName,
      "EmergencyContactPhone":emPhone,
      "EmergencyContactRelation":emRelation
    }
this.emContactService.registerEmergencyContact(body).subscribe(res=>{

  this.isSuccess=true
 this.emgencyFrm.reset();
   this.submitAction.emit("")
})
  }
}
