<mat-tab-group (selectedTabChange)="tabChanged($event)">

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">pill</mat-icon>
      Medicine
    </ng-template>

    <div class="ml-3">
      <div class="row">
        <div class="col-lg-6 float-right">
          <mat-form-field class="example-full-width mt-5">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class=" mat-elevation-z8">

      <div class="d-flex justify-content-end m-2">
        <form (keydown.enter)="$event.preventDefault()" (ngSubmit)="uploadFile()">
          <input type="file" class="formControl" (change)=" onFileSelected($event)" accept="application/json">
          <button type="submit btn btn-warning">Upload Medicines</button>
        </form>

      </div>

      <table mat-table [dataSource]="dataSource" matSort>


        <ng-container matColumnDef="DrugName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Drug Name </th>
          <td mat-cell *matCellDef="let row"> {{row.DrugName}} </td>
        </ng-container>



        <ng-container matColumnDef="BrandName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> BrandName </th>
          <td mat-cell *matCellDef="let row"> {{row.BrandName}} </td>
        </ng-container>
        <ng-container matColumnDef="Category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Category </th>
          <td mat-cell *matCellDef="let row"> {{row.Category}} </td>
        </ng-container>
        <ng-container matColumnDef="Dosage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Dosage </th>
          <td mat-cell *matCellDef="let row"> {{row.Dosage}} </td>
        </ng-container>
        <ng-container matColumnDef="DrugCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> DrugCode </th>
          <td mat-cell *matCellDef="let row"> {{row.DrugCode}} </td>
        </ng-container>
        <ng-container matColumnDef="Instruction">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Instruction </th>
          <td mat-cell *matCellDef="let row"> {{row.Instruction}} </td>
        </ng-container>
        <ng-container matColumnDef="Description">
          <th class="message" mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
          <td class="message mt-4" mat-cell *matCellDef="let row"> {{row.Description}} </td>
        </ng-container>
        <ng-container matColumnDef="Price">
          <th class="mlf" mat-header-cell *matHeaderCellDef mat-sort-header> Price </th>
          <td class="mlf" mat-cell *matCellDef="let row"> {{row.Price}} </td>
        </ng-container>
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let row"> {{row.Status}} </td>
        </ng-container>

        <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Delete </th>
          <td mat-cell *matCellDef="let row">
            <button class="btn btn-danger" (click)="deleteDrug(row.MedicineId)">
              Delete
            </button>
          </td>
        </ng-container>
        <!-- <ng-container matColumnDef="Detail">
              <th  mat-header-cell *matHeaderCellDef> Patient Details</th>
              <td mat-cell *matCellDef="let element"> 
                <button type="button"  [routerLink]="['/doctorPatient/',element.PatientId]" mat-mini-fab color="primary">
                  <mat-icon matPrefix>remove_red_eye</mat-icon>
                </button>
              </td>
            </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </mat-tab>


  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      Register Medicine
    </ng-template>
    <app-add-medicine></app-add-medicine>
  </mat-tab>


</mat-tab-group>