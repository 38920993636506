<div style="padding: 32px; height: calc(100% - 64px);">
    <ng-container *ngIf="viewerProvider">
        <div style="margin-bottom: 16px; display: flex; justify-content: center" *ngIf="viewerProvider.playClip">
            <button style="margin-right: 16px" mat-mini-fab color="primary" (click)="viewerProvider.playClip.play(10)">
                <mat-icon>play_arrow</mat-icon>
            </button>
            <button mat-mini-fab color="warn" (click)="viewerProvider.playClip.stop()">
                <mat-icon>stop</mat-icon>
            </button>
        </div>
        <div style="margin-bottom: 8px; display: flex; justify-content: center; flex-wrap: wrap;">
            <button style="margin-right: 8px; margin-bottom: 8px" mat-raised-button
                [color]="isActive(tool) ? 'primary' : undefined" *ngFor="let tool of config.tools"
                (click)="activateTool(tool.name)">{{tool.name}}</button>
        </div>
        <div style="display: flex; justify-content: center; margin-bottom: 16px">
            <button style="margin-right: 8px" mat-raised-button color="accent" (click)="exportStateToJson()">Export
                tools state to JSON file</button>
            <button mat-raised-button color="accent" (click)="saveAs()">Save as PNG</button>
        </div>
    </ng-container>
    <div>
        <!-- style="display: flex; justify-content: center" -->
        <div *ngIf="config.fileUrl && hideDicom">
            <ngx-dicom [config]="config" (initialized)="viewerProvider = $event"></ngx-dicom>
        </div>
    </div>


</div>



<div class="container">
    <table class="w-100">
        <thead>
            <th>Uploaded Date</th>
            <th>Study Name</th>
            <th>View Image</th>
            <th>Upload Report</th>
            <th>View Report</th>
            <th>Delete</th>
        </thead>
        <tbody>
            <tr *ngFor="let item of imageUrl">
                <td>{{item.createdDate}}</td>
                <td>{{item.StudyName}}</td>
                <td><button type="button" style="cursor: pointer; color: blue;" (click)="loadUrl(item.MriImage)"
                        mat-mini-fab color="success">
                        <mat-icon matPrefix>remove_red_eye</mat-icon>
                    </button> </td>
                <td><button type="button" mat-mini-fab color="primary" data-toggle="modal" data-target="#exampleModal">
                        <mat-icon matPrefix>cloud_upload</mat-icon>

                    </button>

                    <!-- Modal -->
                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Upload Report</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form action="">
                                        <input type="file" (change)="image($event)">
                                        <div class="my-3">
                                            <!-- <mat-progress-bar mode="determinate"></mat-progress-bar> -->
                                            <mat-progress-spinner class="example-margin" [value]="uploadProgress">
                                            </mat-progress-spinner>
                                            <p>Once the upload progress is complete, press the save report button.</p>
                                        </div>
                                        <button type="button" (click)="formSubmit(item.RadiologyMriId)"
                                            class="btn btn-primary">Save Report</button>
                                    </form>
                                </div>
                                <div class="modal-footer">

                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

                                </div>
                                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5"
                                    role="alert">
                                    You have Successfully submitted
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <!-- modal  -->
                    <button type="button" (click)="viewRdiologyReport(item)" mat-mini-fab color="primary"
                        data-toggle="modal" data-target="#lab_report_modal">
                        <mat-icon matPrefix>
                            summarize
                        </mat-icon>

                    </button>

                    <div class="modal fade bd-example-modal-lg" id="lab_report_modal" tabindex="-1" role="dialog"
                        aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLongTitle">
                                        Report
                                    </h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body " id="printable">
                                    <img src="{{radioDocument}}" alt="">
                                    <!-- <mat-card class="example-card">
                                        <mat-card-header class="example-header" style="padding-left: 40%;">
                                        </mat-card-header>
                                        <mat-card-content>
                                            <div [innerHTML]="radioDocument "></div>

                                        </mat-card-content>
                                    </mat-card> -->

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                        Close
                                    </button>
                                    <button type="button" (click)="print()" class="btn btn-secondary mx-3"
                                        data-dismiss="modal">
                                        Print
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- modal  -->
                </td>
                <td>
                    <button (click)="delete(item.RadiologyMriId)" mat-mini-fab
                        color="accent"><mat-icon>delete</mat-icon></button>
                </td>
            </tr>
        </tbody>
    </table>
</div>