import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { NurseTemplateService } from 'src/app/services/nurse-template.service';
import { NurseService } from 'src/app/services/nurse.service';
import { Clipboard } from '@angular/cdk/clipboard';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-nurse-templates',
  templateUrl: './view-nurse-templates.component.html',
  styleUrls: ['./view-nurse-templates.component.css'],
})
export class ViewNurseTemplatesComponent implements OnInit {
  displayedColumns: string[] = ['Name', 'Update', 'Delete'];

  dataSource: MatTableDataSource<any>;

  templates: any[] = [];
  nurseId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  templateContent: any;
  filteredList: Observable<any[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private templateService: NurseTemplateService,
    private nurseService: NurseService,
    private clipboard: Clipboard
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.loadTemplates(this.nurseId);
  }

  loadTemplates(id) {
    this.nurseService.getNurseTemplates(id).subscribe((data) => {
      this.templates = data.NurseTemplates;
      this.dataSource = new MatTableDataSource(data.NurseTemplates);
      this.dataSource.paginator = this.paginator;
    });
  }

  loadTemplate(id: number) {
    this.templateService.getTemplate(id).subscribe((template) => {
      this.templateContent = template.Content.replace(/\n/g, '<br/>');
      // Handle the template loading logic here
      // template.Content.replace(/\n/g, '<br/>');
    });
  }
  copyField(field) {
    this.clipboard.copy(`%${field.key}%`);
  }

  copyTemplate() {
    const templateHtml = this.templateContent.nativeElement.innerHTML;

    navigator.clipboard
      .writeText(templateHtml)
      .then(() => {
        alert('Template content copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  }
  copy(e) {
    this.clipboard.copy(e);
  }

  delete(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.templateService.deleteTemplate(id).subscribe((res) => {
        this.loadTemplates(this.nurseId);
      });
    }
  }

  search(searchValue) {
    if (searchValue.value != null) {
      this.templates = this.templates.filter((x) =>
        x.Name.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
