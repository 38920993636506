import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { DisclosureAndConsent } from 'src/app/Models/disclosureandconsent.model';
import { DiclosureandconsentService } from 'src/app/services/diclosureandconsent.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-disclosure-and-consent',
  templateUrl: './view-disclosure-and-consent.component.html',
  styleUrls: ['./view-disclosure-and-consent.component.css'],
})
export class ViewDisclosureAndConsentComponent implements OnInit {
  panelOpenState = false;
  id = 0;
  diclosureandconsentList: DisclosureAndConsent[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private discloreandConsentService: DiclosureandconsentService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getDisclosureAndConsent(this.id);
  }

  getDisclosureAndConsent(id) {
    this.patientService.getDisclosureAndConsent(id).subscribe((res) => {
      this.diclosureandconsentList = res.DisclosureAndConsents;
    });
  }

  print() {
    window.print();
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getDisclosureAndConsent(this.id);
    }
  }

  delete(id) {
    this.discloreandConsentService
      .deleteDisclosureAndConsent(id)
      .subscribe((res) => {
        this.getDisclosureAndConsent(this.id);
      });
  }
}
