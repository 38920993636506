import { Component } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-bmi',
  templateUrl: './add-bmi.component.html',
  styleUrls: ['./add-bmi.component.css']
})
export class AddBmiComponent {

}
