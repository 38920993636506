<div id="dashboard">
    <div class="content">
        <div class="sidebar mat-elevation">
            <div class="header">
                <!-- <img src="../../../assets/admin.png" class="image" alt=""> -->

                <!-- <h4 class="text-white ml-2">Hospital Dashboard</h4> -->
            </div>







        </div>

        <div class="main">
            <div class="toolbar mat-elevation-z6"></div>

            <div class="row">
                <div class="col-lg-4"></div>

                <div class="col-lg-4">
                    <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" class="example-form">

                        <mat-form-field class="example-full-width">
                            <mat-label>Hospital Name</mat-label>
                            <mat-select formControlName="HospitalId" (selectionChange)="getAllPatientVisits()">
                                <mat-option *ngFor="let hs of hospitalList" [value]="hs.HospitalId">{{
                                    hs.HospitalName }}</mat-option>
                            </mat-select>
                            <mat-error>Select a Hospital Name</mat-error>
                        </mat-form-field>



                    </form>
                </div>
                <div class="col-lg-4"></div>

            </div>

            <div class="container">
                <div class="statistic-card">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    No. Of Visits & Fees
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="d-flex flex-column">
                                <span class="text-success"> Monthly Visits: {{ PatientVisits?.New }}</span>

                                <span>Software Fee: <span class="text-muted ml-2">{{ PatientVisits?.SoftwareFee
                                        }}</span></span>


                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <!-- <table class="table table w-100">
                    <thead>
                        <th>Date</th>
                        <th>Software Fee</th>
                    </thead>
                    <tbody *ngFor="let hospital of filteredList">
                        <tr>
                            <td>{{hospital?.createdDate | date: 'dd/MM/yyyy'}}</td>
                            <td>{{hospital?.SoftwareFee}}</td>
                        </tr>
                    </tbody>

                </table> -->

            </div>
        </div>

        <!-- <div class="container mt-3 "> -->
        <!-- <div class="row" *ngFor="let hospital of filteredList"> -->
        <!-- <div class="col-lg-12">

                        <div class="col-lg-4"> -->
        <!-- <h2>Methodist Hospital</h2> -->
        <!-- <img src="../../../assets/methodist.png" style="max-width: 270px;" alt=""> -->
        <!-- <div [innerHTML]="hospital?.HospitalLogo"></div> -->
        <!-- <div [innerHTML]="hospital?.HospitalLogo" class="cell"></div> -->

        <!-- </div>
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4 ">
                            <p>Visit Date: {{hospital?.createdDate | date: 'dd/MM/yyyy'}}</p>
                            <p> Software Fee: {{hospital?.SoftwareFee}}</p> -->
        <!-- <p>Address: {{hospital?.HospitalAddress}}</p>
                            <p>Phone: {{hospital?.HospitalPhone}}</p>
                            <p>Email: {{hospital?.HospitalEmail}}</p> -->

        <!-- <button type="button" [routerLink]="['/hospitalDash/',hospital.HospitalId]" mat-mini-fab
                                color="success">
                                <mat-icon matPrefix>remove_red_eye</mat-icon>
                            </button> -->

        <!-- </div>
                    </div>
                </div> -->
        <!-- </div> -->

        <!-- <app-view-department></app-view-department> -->

        <!-- </div> -->