import { Component } from '@angular/core';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.css'],
})
export class FormBuilderComponent {
  toolboxFields = [
    { type: 'text', label: 'Text Input' },
    { type: 'textarea', label: 'Textarea' },
    { type: 'button', label: 'Button' },
    { type: 'checkbox', label: 'Checkbox' },
    { type: 'radio', label: 'Radio Group', options: ['Option 1', 'Option 2'] },
    { type: 'date', label: 'Date Picker' },
    { type: 'signature', label: 'Signature Field' },
    { type: 'dropdown', label: 'Dropdown', options: ['Option 1', 'Option 2'] },
    { type: 'file', label: 'File Upload' },
  ];

  formFields: any[] = [];

  onDrop(event: any) {
    const field = this.toolboxFields[event.previousIndex];
    const newField = {
      ...field,
      id: Date.now(),
      labelEditable: false, // Add editable flag
    };
    if (field.type === 'radio' || field.type === 'dropdown') {
      newField.options = [...field.options];
    }
    this.formFields.push(newField);
  }

  toggleLabelEdit(field: any) {
    field.labelEditable = !field.labelEditable;
  }

  saveForm() {
    const formLayout = JSON.stringify(this.formFields);
    localStorage.setItem('formLayout', formLayout);
    alert('Form saved successfully!');
  }

  loadForm() {
    const formLayout = localStorage.getItem('formLayout');
    if (formLayout) {
      this.formFields = JSON.parse(formLayout);
    } else {
      alert('No saved form found!');
    }
  }

  exportForm() {
    const formJSON = JSON.stringify(this.formFields, null, 2);
    const blob = new Blob([formJSON], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'form.json';
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
