import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Electromyography } from 'src/app/Models/electromyography.model';
import { ElectromyographyService } from 'src/app/services/electromyography.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-electromyography',
  templateUrl: './view-electromyography.component.html',
  styleUrls: ['./view-electromyography.component.css'],
})
export class ViewElectromyographyComponent implements OnInit {
  id = 0;
  date: string;
  studyResult: Electromyography[] = [];
  studyReport: Electromyography[];
  constructor(
    private patientService: PatientService,
    private electromyographyService: ElectromyographyService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  , private tokenService: TokenService) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    // this.date = new Date(moment().format('MM/DD/YYYY')).toISOString()
    this.date = this.datePipe.transform(new Date(), 'MM/dd/YYYY');
    this.geElectromyography(this.id);
  }

  geElectromyography(id) {
    this.patientService.GetElectromyography(id).subscribe((res) => {
      this.studyResult = res.Electromyographys.sort(function (x, y) {
        return y.ElectromyographyId - x.ElectromyographyId;
      });

      this.studyReport = res.Electromyographys.sort(function (x, y) {
        return y.ElectromyographyId - x.ElectromyographyId;
      });
    });
  }

  delete(id) {
    this.electromyographyService.deleteElectromyography(id).subscribe((res) => {
      this.geElectromyography(this.id);
    });
  }
  applyFilter(searchValue) {
    if (searchValue.value != null) {
      this.studyResult = this.studyReport.filter((x) =>
        x.createdDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }
}
