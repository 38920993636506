import { Component } from '@angular/core';

@Component({
  selector: 'app-update-favorite-dignosis-code',
  templateUrl: './update-favorite-dignosis-code.component.html',
  styleUrls: ['./update-favorite-dignosis-code.component.css']
})
export class UpdateFavoriteDignosisCodeComponent {

}
