import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NurseProgressReport } from 'src/app/Models/nurseprogressreport.model';
import { Patient } from 'src/app/Models/patient.model';
import { RadiologyReport } from 'src/app/Models/radiologyreport.model';
import { PatientService } from 'src/app/services/patient.service';
import { RadiologyReportService } from 'src/app/services/radiology-report.service';
import { TokenService } from 'src/app/services/token.service';
import { UpdateRadiologyReportComponent } from '../update-radiology-report/update-radiology-report.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-radiology-report-peach-recogintion-enabled',
  templateUrl:
    './view-radiology-report-peach-recogintion-enabled.component.html',
  styleUrls: [
    './view-radiology-report-peach-recogintion-enabled.component.css',
  ],
})
export class ViewRadiologyReportPeachRecogintionEnabledComponent
  implements OnInit
{
  displayedColumns: string[] = [
    'actions',
    'Date',
    'ReportName',
    'DoctorName',
    // 'Decription',
    'Signature',
    'View',
    'Update',
    'Delete',
  ];

  id = 0;
  radiologyReport: RadiologyReport[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  patient: Patient;
  reportDetails: string;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();

  CurrentDoctorId: number;

  constructor(
    private patientService: PatientService,
    private radiologyReportService: RadiologyReportService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
    this.GetReports(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
    }
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetReports(id) {
    this.patientService.getPatientRadiologyReport(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.RadiologyReports.sort(function (x, y) {
          return y.RadiologyReportId - x.RadiologyReportId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.radiologyReportService.registerRadiologyReport(id).subscribe((res) => {
      alert('are you sure you wanted to delete record?');
      this.GetReports(this.id);
    });
  }
  print() {
    // Before printing, temporarily hide the body of the modal
    const modalBody = document.querySelector('.app-modal-body');

    // Open the print window and write the content
    let printWindow = window.open('', '', 'height=900,width=1200');
    printWindow.document.write('<html><head><title>Print</title>');

    // Include custom print styles
    printWindow.document.write(`
        <style>
            @media print {
                body {
                    margin: 10a;
                    padding: 0;
                }
                .app-modal-body {
                    display: none !important;   /* Hide modal body */
                }
                .app-modal-header, .app-modal-footer {
                    display: block !important;  /* Ensure header and footer are shown */
                }
                .dt-button, .btn-primary {
                    display: none !important;   /* Hide print buttons */
                }
                @page {
                    margin: 10;
                }
            }
        </style>
    `);

    // Write modal content (this can include only the visible parts)
    printWindow.document.write('<body>');
    printWindow.document.write(
      document.querySelector('.panel-demo1').innerHTML
    );
    printWindow.document.write('</body>');

    printWindow.document.close();

    // Print the page
    printWindow.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewReport(report: NurseProgressReport): void {
    // console.log('report: ', report);
    this.reportDetails = report.ReportDescription;
  }

  copyRowData(row: any): void {
    const htmlContent = `
      <p><strong>Date:</strong> ${new Date(
        row.createdDate
      ).toLocaleString()}</p>
      <p><strong>Report Name:</strong> ${row.ReportName}</p>
      <p><strong>Shortcut:</strong> ${row.shortcut}</p>
      <p><strong>Nurse :</strong> ${row.NurseName}</p>
      <p><strong>Description:</strong> ${row.ReportDescription}</p>
    `;

    navigator.clipboard
      .writeText(htmlContent)
      .then(() => {
        console.log('Row data copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy row data: ', err);
      });
  }

  updateReport(reportId: string, id): void {
    const dialogRef = this.dialog.open(UpdateRadiologyReportComponent, {
      width: '50%',
      height: '80%',
      disableClose: false, // Prevent closing without confirmation
      hasBackdrop: false, // Disable the backdrop
      panelClass: 'custom-dialog-container', // Optional custom styling
      data: { reportId }, // Pass data to the dialog
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog closed:', result);
      // Handle any cleanup or actions after the dialog is closed
    });
  }
}
