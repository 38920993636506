import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-twofa-reset-passowrd',
  templateUrl: './twofa-reset-passowrd.component.html',
  styleUrls: ['./twofa-reset-passowrd.component.css'],
})
export class TwofaResetPassowrdComponent implements OnInit {
  otp: any;
  inputDigit: string = 'Verify Code';
  btnStaus: string = 'btn-light';
  decodedToken: any;
  UserName: any;
  id = 0;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();
  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  , private tokenService: TokenService) {}

  public configurations = {
    length: 5,
  };

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.UserName = res['UserName'];
      this.decodedToken = res;
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
  }

  onOtpChange(event: any) {
    this.otp = event;
    if (this.otp.length < this.configurations.length) {
      this.inputDigit =
        this.configurations.length - this.otp.length + ' digits left';
    }
    if (this.otp.length === this.configurations.length) {
      this.inputDigit = 'Go';
      this.btnStaus = 'btn-primary';
    }
  }

  veryFy(e) {
    let body = {
      TwoFactorCode: this.otp.toString(),
      UserName: this.UserName,
    };

    this.userService.veriFy(body).subscribe((res) => {
      if (res === true) {
        this.router.navigate(['/setpassword']);
      } else {
        alert('not user found');
      }
    });
  }
}
