<div class="col-lg regFrm mat-elevation-z8">
    <h1>New Progress Notes</h1>
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
        <mat-form-field class="example-full-width">
            <mat-label>Report Name</mat-label>
            <input type="text" matInput formControlName="ReportName" [matAutocomplete]="auto5">
            <mat-icon matSuffix>search</mat-icon>
            <mat-autocomplete #auto5="matAutocomplete">
                <mat-option *ngFor="let dos of filteredReportName | async" [value]="dos">
                    {{ dos }}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="Frm.get('ReportName')?.hasError('required')">
                Report Name is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Shortcut</mat-label>
            <input type="text" matInput formControlName="shortcut" [matAutocomplete]="auto6">
            <mat-icon matSuffix>search</mat-icon>
            <mat-autocomplete #auto6="matAutocomplete">
                <mat-option *ngFor="let dos of filteredShortcut | async" [value]="dos">
                    {{ dos }}
                </mat-option>
            </mat-autocomplete>
            <!-- <mat-error *ngIf="Frm.get('shortcut')?.hasError('required')">
          Shortcut is required.
        </mat-error> -->
        </mat-form-field>

        <!-- Quill Editor -->
        <button type="button" color="primary" mat-raised-button class="float-right"
            (click)="pasteIntoQuill(quillEditor)">
            Paste into Editor
        </button>
        <button color="primary" mat-raised-button (click)="generate()">AI-Assistance</button>

        <div #editorContainer id="editor" class="editor-container"></div>

        <!-- Voice Recognition Buttons -->
        <button type="button" mat-raised-button color="primary" (click)="startVoiceRecognition()">Start Voice
            Recognition</button>
        <button type="button" class="mx-5" mat-raised-button color="accent" (click)="stopRecognition()">Stop</button>

        <!-- Diagnosis Code Button -->
        <button type="button" mat-raised-button (click)="openFavoriteDiagnosisPopup()" color="warn">
            <mat-icon matPrefix>add</mat-icon> Diagnosis Code
        </button>

        <!-- Success Message -->
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
            Successfully submitted
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <!-- Submit Button -->
        <button type="submit" class="float-right lgBtCl" mat-button>
            <mat-icon>save</mat-icon>
            Sign & Save
        </button>
    </form>
</div>