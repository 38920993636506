import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IcdCode } from 'src/app/Models/icdcode.model';
import { IcdCodeService } from 'src/app/services/icd-code.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-icd-code',
  templateUrl: './add-icd-code.component.html',
  styleUrls: ['./add-icd-code.component.css'],
})
export class AddIcdCodeComponent implements OnInit {
  cptCodeList: IcdCode[] = [];
  isSuccess = false;
  constructor(
    private fb: UntypedFormBuilder,
    private icdCodeService: IcdCodeService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.getCptCodes();
  }

  cptFrm = this.fb.group({
    Code: ['', Validators.required],
    Description: ['Dont Enter anything here', Validators.required],
  });

  getCode() {
    return this.cptFrm.get('Code');
  }

  getDescription() {
    return this.cptFrm.get('Description');
  }

  getPrice() {
    return this.cptFrm.get('Price');
  }

  getCptCodes() {
    this.icdCodeService.getIcdCodes().subscribe((res) => {
      this.cptCodeList = res;
    });
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getCode();
    }
  }
  formSubmit() {
    let code = this.cptFrm.value['Code'];
    let description = this.cptFrm.value['Description'];

    let body = {
      Code: code,
      Description: description,
    };

    this.icdCodeService.registerIcdCode(body).subscribe((res) => {
      this.cptCodeList.push(res);
      this.isSuccess = true;
      this.cptFrm.reset();
    });
  }

  itemsToShow = 2;

  // Function to load more items
  loadMore() {
    this.itemsToShow += 1; // Increase the number of items to show by 3
  }

  // Function to check if there are more items to load
  hasMoreItems() {
    return this.itemsToShow < this.cptCodeList?.length;
  }
}
