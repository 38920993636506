import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseHandOffNoteService } from 'src/app/services/nurse-hand-off-note.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-nurse-hand-off-note',
  templateUrl: './update-nurse-hand-off-note.component.html',
  styleUrls: ['./update-nurse-hand-off-note.component.css'],
})
export class UpdateNurseHandOffNoteComponent implements OnInit {
  id = 0;
  isSuccess = false;
  constructor(
    private fb: UntypedFormBuilder,
    private nurseHandOffNoteService: NurseHandOffNoteService,
    private route: ActivatedRoute,
    private router: Router
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.nurseHandOffNoteService
      .getNurseHandOffNoteById(this.id)
      .subscribe((res) => {
        this.Frm.patchValue(res);
      });
  }

  Frm = this.fb.group({
    PatientName: ['', Validators.required],
    PatientDOB: ['', Validators.required],
    HandOffNote: ['', Validators.required],
  });

  get PatientName() {
    return this.Frm.get('PatientName');
  }
  get PatientDOB() {
    return this.Frm.get('PatientDOB');
  }
  get HandOffNote() {
    return this.Frm.get('HandOffNote');
  }

  formSubmit() {
    let patientName = this.Frm.value['PatientName'];
    let patientDOB = this.Frm.value['PatientDOB'];
    let handOffNote = this.Frm.value['HandOffNote'];

    let body = {
      PatientName: patientName,
      PatientDOB: patientDOB,
      HandOffNote: handOffNote,
    };

    this.nurseHandOffNoteService
      .updateNurseHandOffNote(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();

        // this.router.navigate(['/patientDetails/',this.id])
      });
  }
}
