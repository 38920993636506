<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Update Hand off Note</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">
                <div class="row">
                    <div class="col-lg">
                        <mat-form-field class="example-full-width">
                            <mat-label>Patient Name</mat-label>
                            <input type="text" formControlName="PatientName" matInput>
                            <mat-error>Please enter PatientName</mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Patient D.O.B</mat-label>
                            <input type="text" formControlName="PatientDOB" matInput>
                            <mat-error>Please enter Patient Date Of Birth</mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>Hand Off Note</mat-label>
                            <input type="text" formControlName="HandOffNote" matInput>
                            <mat-error>Please enter Notes</mat-error>
                        </mat-form-field>



                    </div>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>

    </div>
</div>