<div class="container mt-3">
    <div class="row">
        <div class="col-md-12" *ngFor="let ps of prescriptions" style="max-width: 500px;">

            <mat-card class="example-card mat-elevation-z8 mx-1 my-1">
                <mat-card-header>
                    <mat-card-title>{{ ps?.DrugName }}</mat-card-title>
                    <!-- <mat-card-subtitle>{{ ps?.Instruction }}</mat-card-subtitle> -->
                </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <div class="col-lg-6">

                            <p>Prescribed Date : {{ ps?.CreatedDate | date: 'MM/dd/yyyy'}}</p>
                            <p *ngIf="ps.DoctorName">Approved By : {{ ps.DoctorName }}</p>
                            <p *ngIf="ps.NurseName">NP : {{ ps.NurseName }}</p>
                        </div>
                        <div class="col-lg-6">
                            <h6>Refill Details</h6>
                            <p>Quantity : {{ ps?.Quantity }}</p>
                            <p>Refills: {{ ps?.Numberofrefills}} </p>
                            <p>Refill End By : {{ ps?.EndDate | date: 'MM/dd/yyyy'}} </p>
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h4>Frequency: {{ ps?.Frequency }}</h4>
                            <h4>Direction of Usage: {{ ps?.Instruction }}</h4>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <h4>Note: {{ ps?.Note }}</h4>

                        </div>
                    </div>

                    <!-- <h6>Prescription Details</h6> -->
                    <!-- <p>Prescribed Date : {{ ps?.CreatedDate | date: 'MM/dd/yyyy'}}</p> -->





                    <!-- <p>No. Of Refills: {{ ps?.Numberofrefills }}</p> -->
                    <!-- 
              <h4>Pharmacy Details</h4>
              <ul class="list-unstyled">
    
                <li>Pharmacy Name : <span>{{pharmacy[0]?.PharmacyName}}</span>
                <li>Pharmacy Address : <span>{{pharmacy[0]?.PharmacyAddress}}</span>
                <li>Pharmacy Phone : <span>{{pharmacy[0]?.PhoneNumber}}</span>
    
    
              </ul> -->
                </mat-card-content>
                <mat-card-actions>

                    <button type="button" [routerLink]="['/updatePrescription/',ps.PrescriptionId,this.id]" mat-mini-fab
                        color="primary">
                        <mat-icon matPrefix>edit</mat-icon>
                    </button>

                    <button type="button" class="float-right" (click)="delete(ps.PrescriptionId)" mat-mini-fab
                        color="accent">
                        <mat-icon matPrefix>delete</mat-icon>
                    </button>

                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>