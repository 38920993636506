import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Nurse } from 'src/app/Models/nurse.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientVisit } from 'src/app/Models/patientvisit.model';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';

@Component({
  selector: 'app-nurse-patient-tab-appearance',
  templateUrl: './nurse-patient-tab-appearance.component.html',
  styleUrls: ['./nurse-patient-tab-appearance.component.css'],
})
export class NursePatientTabAppearanceComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');

  id = 0;
  patient: Patient;
  patientVisitList: PatientVisit[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  patientVisitId: PatientVisit[];
  showItem = '';
  nurse: Nurse;
  nurseId = 0;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private ptVisitService: PatientvisitService,
    private nurseService: NurseService
  ) {
    this.start();
  }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.patientService.getPatientvisits(this.id).subscribe((res) => {
      this.patient = res;
      this.filteredList = res.PatientVisits;
      this.patientVisitList = res.PatientVisits;

      //  if(res.PatientVisits.filter(x=>x.VisitType=="In Patient")){
      //   this.isAdmitted
      //  }
    });

    this.getNurse(this.nurseId);
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }
  printPage() {
    window.print();
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.Date.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.ptVisitService.deletePatientVisit(id).subscribe((res) => {
        this.filteredList = this.patientVisitList.filter(
          (x) => x.PatientVisitId != id
        );
      });
    }
  }

  tabs: string[] = [
    'New Vitals Report',
    'New Progress Report',
    'Prescription',
    'New Visit',
    'New Communication-Assessment',
    'New Order',
    'New Refferal',
    'New Lab Results',
    'Upload Lab Report',
    'Upload Radiology Report',
    'Upload Study Images',
    'New Allergy',
    'New Surgical History',
  ];
  activatedTabIndex: number = 0;

  tabChange(tabIndex: number) {
    // debugger;
    this.activatedTabIndex = tabIndex;
  }
  viewMode = 'tab1';
  tabs2: string[] = [
    'Vitals Report',
    'Vital Chart',
    'Nurse Progress Note',
    'Prescription',
    'Doctor Progess Notes',
    'Visit Summary',
    'Communication-Assessments',
    'Doctor Initial Evaluation',
    'View Lab Order',
    'View Radiology Orders',
    'View-Procedure-Order',
    'Lab-Reports',
    'Lab Chart',
    'Procedure Test Result',
    'Radiology-Reports',
    'View Study Image',
    'Rounds Book',
    'Nerve Study Result',
    'Electromyography Result',
    'Allergy History',
    'Surgical History',
    'Patient Summary',
  ];
  activatedTabIndex2: number = 0;

  tabChange2(tabIndex2: number) {
    // debugger;
    this.activatedTabIndex2 = tabIndex2;
  }
  logout() {
    // Clear local session data
    localStorage.removeItem('authToken');
    // Set a flag to notify other tabs/windows
    localStorage.setItem('logoutEvent', 'logout' + Math.random());
    // Redirect to login page
    this.router.navigate(['/']);
  }

  // Bind the eventListener
  private start(): void {
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  // Logout only when key is 'logout-event'
  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event') {
        console.log('🔥 ~ storageEventListener ~ event', event.newValue);
        this.logout();
      }
    }
  }

  // Handle active listeners when onDestroy
  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.stop();
  }

  currentView: string = 'home';

  // You can add logic to switch the view dynamically
  changeView(view: string): void {
    this.currentView = view;
  }
}
