import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Patient } from 'src/app/Models/patient.model';
import { PatientInsurance } from 'src/app/Models/patientinsurance.model';
import { PatientPrescription } from 'src/app/Models/patientprescription.model';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { PrescriptionDrug } from 'src/app/Models/prescriptiondrug.model';
import { PatientPrescriptionService } from 'src/app/services/patient-prescription.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-patient-prescriptiion',
  templateUrl: './view-patient-prescriptiion.component.html',
  styleUrls: ['./view-patient-prescriptiion.component.css'],
})
export class ViewPatientPrescriptiionComponent implements OnInit {
  displayedColumns: string[] = [
    'Date',
    'DoctorName',
    'IsSendToPharmacy',
    'InstructionToPharmacy',
    'View',
    'Update',
    'Delete',
  ];
  email = new FormControl('');
  @ViewChild('patientPrescriptionList') prescription!: ElementRef<any>;

  id = 0;
  patientPrescriptionList: PatientPrescription[] = [];
  dataSource: MatTableDataSource<any>;
  // labDocument: string = '';
  DrugName: string = '';
  Quantity: any = '';
  Instruction: string = '';
  Status: boolean;
  Date: string = '';

  patient: Patient;
  ptDrugs: PrescriptionDrug[];
  isSuccess: boolean = false;
  Insurance: PatientInsurance[];
  pharmacy: PatientsPharmacy[];
  prescriptionId: PatientPrescription[];
  constructor(
    private patientService: PatientService,
    private patientPrescriptionService: PatientPrescriptionService,
    private route: ActivatedRoute,
    private router: Router
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);
    this.GetPTPrescription(this.id);
    this.getInsurance(this.id);
    this.getPharmacy(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  getInsurance(id) {
    this.patientService.getPatientInsurance(id).subscribe((res) => {
      this.Insurance = res.PatientInsurances;
    });
  }

  getPharmacy(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((res) => {
      this.pharmacy = res.PatientsPharmacys;
      this.email.setValue(res.PatientsPharmacys[0].Email);
    });
  }
  GetPTPrescription(id) {
    this.patientService.getPTPrescription(id).subscribe((res) => {
      this.patientPrescriptionList = res.PatientPrescriptions;
      this.prescriptionId = res.PatientPrescriptions.filter(
        (x) => x.PatientPrescriptionId
      );
      this.dataSource = new MatTableDataSource(
        res.PatientPrescriptions.sort(function (x, y) {
          return y.PatientPrescriptionId - x.PatientPrescriptionId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }

  delete(id) {
    this.patientPrescriptionService
      .deletePatientPrescription(id)
      .subscribe((res) => {
        // this.labReport = this.labReport.filter(x=>x.LabReportId !=id)
        this.GetPTPrescription(this.id);
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  print() {
    window.print();
  }

  public viewReport(report: PatientPrescription): void {
    this.DrugName = report.CreatedDate;
    this.Quantity = report.DoctorName;
    this.Instruction = report.InstructionToPharmacy;
    this.ptDrugs = report.PrescriptionDrugs;

    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
    }, 0);
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.GetPTPrescription(this.id);
    }
  }
  sendPresciption() {
    let body = {
      Email: this.email.value,
      Subject:
        this.patient.PatientFirstName +
        ' ' +
        this.patient.PatientLastName +
        "'s" +
        'Prescription',
      Template: `${this.prescription.nativeElement.innerHTML}`,
    };
    this.patientService.sendPrescription(body).subscribe((res) => {
      this.isSuccess = true;
      // let statusBody = {
      //   Status: true,
      // };
      // this.patientPrescriptionService.updatePatientPrescription(
      //   this.prescriptionId,
      //   statusBody
      // );
    });
  }
}
