<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h2>New Report</h2>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="labFrm" (ngSubmit)="formSubmit()"
        class="example-form">
        <div class="row">
          <div class="col-lg-6">

            <mat-form-field class="example-full-width">
              <mat-label>Report Name</mat-label>
              <input type="text" formControlName="ReportType" matInput>
              <mat-error>Please enter Report Name</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-3">

            <mat-form-field class="example-full-width">
              <mat-label>Date Of Report</mat-label>
              <input type="date" formControlName="Date" matInput>
              <mat-error>Please enter Date of report</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-3">
            <input type="file" (change)="image($event)">

          </div>

        </div>

        <div class="row">
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Ordered By Doctor</mat-label>




              <mat-select formControlName="OrderedBy">

                <mat-option *ngFor="let doctor of doctorList"
                  [value]="doctor.DoctorName">{{doctor.DoctorName}}</mat-option>

              </mat-select>
              <mat-error>Please select doctor</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Name of Laboratory</mat-label>
              <input type="text" formControlName="LabName" matInput>
              <mat-error>Please enter Name of Laboratory</mat-error>
            </mat-form-field>

          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Reported By</mat-label>
              <input type="text" formControlName="ReportedBy" matInput>
              <mat-error>Please enter Reported By</mat-error>
            </mat-form-field>

          </div>


        </div>

        <div class="row">
          <div class="col-lg-12">


            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Description </mat-label>

              <textarea matInput formControlName="Description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="120"></textarea>
              <mat-error *ngIf="Description.invalid && Description.touched">Please enter Description's</mat-error>

            </mat-form-field>
          </div>


        </div>



        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted Lab Report
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>