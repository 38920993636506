import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessagingServiceService {
  // private apiUrl = 'https://your-api-url/api/messages';
  apiUrl = environment.Url2 + 'Messages';

  constructor(private http: HttpClient) {}

  sendMessage(message: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/send`, message);
  }

  getInbox(userId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/inbox`, { params: { userId } });
  }

  replyToMessage(reply: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/reply`, reply);
  }
}
