<!-- <h4 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" back></h4> -->
<!-- <a type="button" [routerLink]="['/doctorDash/']" mat-raised-button color="primary">
    Back to Doctor Dashboard
</a> -->

<div class="row">
    <div class="col-lg-5" *ngIf="isDoctor">
        <div class="row" *ngIf="isConnected">
            <p class=" mx-5"><img class="Drimg" src="{{doctorImage}}" alt=""> {{DoctorName}} </p>

            <p>Time Remaining: {{ remainingTime | minutesSeconds }}</p>
            <button *ngIf="isDoctor" mat-button (click)="extendTimer(10)">Extend by 10 Minutes</button>
            <button mat-button color="warn" (click)="endCall()">End Call</button>
        </div>

        <app-add-doctor-progress-note></app-add-doctor-progress-note>
    </div>

    <!-- <div class="col-lg-7" > -->
    <div class="col-lg-7" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <mat-icon cdkDragHandle>open_with</mat-icon>

        <!-- <mat-toolbar color="primary">
                <span>Telemedicine</span>
                <span class="spacer"> </span>
                <button mat-button *ngIf="isConnected" (click)="leaveRoom()">Leave Room</button>
            </mat-toolbar> -->

        <div id="controls" *ngIf="isConnected" class="controls">
            <button mat-mini-fab color="accent" (click)="toggleMute()">
                <mat-icon>{{ isMuted ? 'mic_off' : 'mic' }}</mat-icon>
            </button>
            <button mat-mini-fab color="accent" (click)="toggleVideo()">
                <mat-icon>{{ isVideoDisabled ? 'videocam_off' : 'videocam' }}</mat-icon>
            </button>
            <button mat-mini-fab color="accent" (click)="startScreenShare()" *ngIf="!isSharingScreen">
                <mat-icon>screen_share</mat-icon>
            </button>
            <button mat-mini-fab color="warn" (click)="stopScreenShare()" *ngIf="isSharingScreen">
                <mat-icon>stop_screen_share</mat-icon>
            </button>
            <button mat-button color="warn" (click)="endCall()">End Call</button>
            <p>{{patient.PatientFirstName}}
                {{patient.PatientLastName}},{{patient.PatientDOB}},{{patient.PatientAddress}}
            </p>

        </div>

        <div id="video-grid">
            <div id="local-video" class="video-container"></div>
            <div id="remote-videos" class="video-container"></div>
        </div>

        <div class="controls">
            <button mat-raised-button (click)="zoomIn('local-video')">Zoom In Local</button>
            <button mat-raised-button (click)="zoomOut('local-video')">Zoom Out Local</button>
            <button mat-raised-button (click)="resetZoom('local-video')">Reset Local Zoom</button>

            <button mat-raised-button (click)="zoomIn('remote-videos')">Zoom In Remote</button>
            <button mat-raised-button (click)="zoomOut('remote-videos')">Zoom Out Remote</button>
            <button mat-raised-button (click)="resetZoom('remote-videos')">Reset Remote Zoom</button>

        </div>

        <!-- <mat-card class="participants-container" *ngIf="participantList.length > 0">
            <mat-card-content>
                <h3>Participants</h3>
                <ul>
                    <li *ngFor="let participant of participantList">{{ participant }}</li>
                </ul>
            </mat-card-content>
        </mat-card> -->

        <mat-card class="float-right" *ngIf="isConnected">
            <mat-card-content>
                <h3>Chat</h3>
                <div id="messages">
                    <div *ngFor="let message of messages" class="message">
                        <strong>{{ message.sender }}:</strong> {{ message.text }}
                    </div>
                </div>
                <mat-form-field appearance="fill">
                    <mat-label>Type your message</mat-label>
                    <input matInput [(ngModel)]="chatInput" (keyup.enter)="sendMessage()" />
                </mat-form-field>
                <button mat-raised-button color="primary" (click)="sendMessage()">Send</button>
            </mat-card-content>
        </mat-card>
    </div>
</div>