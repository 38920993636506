import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GeneralUploadService } from 'src/app/services/general-upload.service';
import { environment } from 'src/environments/environment';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-general-upload',
  templateUrl: './add-general-upload.component.html',
  styleUrls: ['./add-general-upload.component.css'],
})
export class AddGeneralUploadComponent implements OnInit {
  id = 0;
  imageUrl: string;
  isSuccess = false;
  uploadProgress: number = 0;
  constructor(
    private generalUploadService: GeneralUploadService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }

  mriFrm = this.fb.group({
    GeneralUploadImage: [''],
  });

  get GeneralUploadImage() {
    return this.mriFrm.get('GeneralUploadImage');
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.generalUploadService.imageUpload(formData).subscribe(
      (res) => {
        let stImg = environment.imgurl + res['path'];
        this.imageUrl = `<p><img src="${stImg}"/></p>`;
      },
      (err) => console.log(err)
    );
    //   (res: HttpEvent<any>) => {
    //     if (res.type === HttpEventType.UploadProgress) {
    //       this.uploadProgress = Math.round((100 * res.loaded) / res.total);
    //     } else if (res.type === HttpEventType.Response) {
    //       let stImg = environment.imgurl + res['path'];
    //       // this.imageUrl = stImg
    //       this.imageUrl = `<p><img src="${stImg}"/></p>`;
    //       this.uploadProgress = 100;
    //     }
    //   },
    //   ({ error }) => {
    //     console.log(error);
    //   }
    // );
  }

  formSubmit() {
    let LabReportImage = this.mriFrm.value['GeneralUploadImage'];

    let body = {
      PatientId: this.id,
      GeneralUploadImage: this.imageUrl,
    };
    // console.log(body);
    this.generalUploadService.registerGeneralUpload(body).subscribe((res) => {
      this.isSuccess = true;
      this.mriFrm.reset();
    });
  }
}
