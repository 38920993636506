import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Patient } from 'src/app/Models/patient.model';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-patient-intake-items',
  templateUrl: './patient-intake-items.component.html',
  styleUrls: ['./patient-intake-items.component.css'],
})
export class PatientIntakeItemsComponent implements OnInit {
  id = 0;
  currentPatient?: Patient;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
      this.patientService.getPatientById(this.id).subscribe((res) => {
        this.currentPatient = res;
        this.getPatientUpdate(this.id);
      });
    });
  }

  getPatientUpdate(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.currentPatient = res;
      if (
        !res.IsNewPatient &&
        res.IsIdProofCompleted &&
        res.IsInsuranceCompleted &&
        res.IsEmergencyContactCompleted &&
        res.IsVaccineCompleted &&
        res.IsAllergyCompleted &&
        res.IsSurgicalHistoryCompleted &&
        res.IsFamilyHistoryCompleted &&
        res.IsSocialHistoryCompleted &&
        res.IsMedicalHistoryCompleted &&
        res.IsHippaCompleted &&
        res.IsPhtographicConsentCompleted &&
        res.IsOfficePolicyConsentCompleted &&
        res.IsGeneralConsentForTreatmentCompleted &&
        res.IsNarcoticMedicalPolicyAgreementCompleted
      ) {
        this.patientService
          .completePatientInformation(res.PatientId)
          .subscribe((complete) => {
            alert('Completed Information');
          });
      }
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
    }
  }

  handleSubmitAction(eventData: any) {
    this.getPatientUpdate(this.id);
  }
}
