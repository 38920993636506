import { Component } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-review-of-system',
  templateUrl: './update-review-of-system.component.html',
  styleUrls: ['./update-review-of-system.component.css']
})
export class UpdateReviewOfSystemComponent {

}
