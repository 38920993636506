<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>New Text Message</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-4">
                <mat-form-field class="example-full-width">
                  <mat-label>Country Code</mat-label>
                  <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput
                    placeholder="+1, +91">
                  <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-8">
                <mat-form-field class="example-full-width">
                  <mat-label>Phone Number</mat-label>
                  <input type="text" formControlName="PhoneNumber" matInput>
                  <mat-error>Please enter a Phone Number</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <mat-form-field class="example-full-width">
              <mat-label>Message</mat-label>
              <input type="text" formControlName="Body" matInput>
              <mat-error>Please enter Message</mat-error>
            </mat-form-field>
          </div>
        </div>







        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>

  </div>
</div>