import { LabOrderService } from './../../services/lab-order.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LabOrder } from '../../Models/laborder.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Labreport } from 'src/app/Models/labreport.model';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from 'src/app/Models/patient.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import html2pdf from 'html2pdf.js';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-lab-order',
  templateUrl: './view-lab-order.component.html',
  styleUrls: ['./view-lab-order.component.css'],
})
export class ViewLabOrderComponent implements OnInit {
  @ViewChild('LabOrder') LabOrder!: ElementRef;

  displayedColumns: string[] = [
    'Date',
    'OrderedBy',
    'Subject',
    'Description',
    'View',
    'Delete',
  ];

  id = 0;
  labOrder: LabOrder[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  patient: Patient;
  email = new FormControl('');
  display: string;
  FaxImage: FormData;
  fax = new FormControl('');
  errorMessage: string;
  isSuccess: boolean;

  constructor(
    private patientService: PatientService,
    private labOrderservice: LabOrderService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private fb: FormBuilder
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.GetlabOrder(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetlabOrder(id) {
    this.patientService.getPatienLabOrder(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.LabOrders.sort(function (x, y) {
          return y.LabOrderId - x.LabOrderId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.labOrderservice.deleteLabOrder(id).subscribe((res) => {
      this.GetlabOrder(this.id);
    });
  }
  print() {
    window.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewLabReport(report: LabOrder): void {
    // console.log('report: ', report);
    this.labDocument = report.Description;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
    }, 0);
  }

  sendtoLab() {
    let body = {
      Email: this.email.value,
      Subject: 'Lab Test Order' + this.labOrder.filter((x) => x.Subject),
      Template: `${this.LabOrder.nativeElement.innerHTML}`,
    };
    this.labOrderservice.sendLabOroder(body).subscribe((res) => {
      alert('Order sent');
    });
  }

  Frm = this.fb.group({
    FaxNumber: ['', Validators.required],
    Subject: [''],
    Template: [''],
  });

  get FaxNumber() {
    return this.Frm.get('FaxNumber');
  }

  get Subject() {
    return this.Frm.get('Subject');
  }

  get Template() {
    return this.Frm.get('Template');
  }
  openModal() {
    this.display = 'block';
  }
  onCloseHandled() {
    this.display = 'none';
  }
  image(e: any) {
    let formData = new FormData();
    let files = e.target.files;
    // this.FaxImage = files[0].name;
    formData.append('media', files[0], files[0].name);
    this.FaxImage = formData;
  }
  formSubmit() {}
  faxPresciption() {
    // const { FaxNumber } = this.Frm.value;
    const faxNumber = this.fax.value!;
    this.apiTriggerer({ toNumber: faxNumber });
  }

  async apiTriggerer({ toNumber }: { toNumber: string }) {
    try {
      let mediaName: string;

      if (this.FaxImage) {
        // Upload image to Telnyx
        const { media_name } = await this.uploadImage();
        mediaName = media_name;
        console.log('media', mediaName);
      } else {
        // Generate PDF from Template
        const templatePdfBlob = await this.generatePdfFromTemplate(
          this.Frm.value['Template']!
        );

        // Upload PDF to Telnyx
        const {
          data: { media_name },
        } = await this.uploadPdf(templatePdfBlob);
        mediaName = media_name;
      }

      // Send fax using Telnyx API
      const faxResponse = await this.sendFax({ toNumber, mediaName });
      this.isSuccess = true;
      this.errorMessage = '';
      console.log('Fax sent successfully:', faxResponse);
    } catch (error) {
      console.error('Failed to send fax:', error);
      this.isSuccess = false;
      this.errorMessage = 'Failed to send fax. Please try again.';
    }
  }

  uploadImage(): Promise<{ media_name: string }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return new Promise((resolve, reject) => {
      this.http
        .post<any>('https://api.telnyx.com/v2/media', this.FaxImage, {
          headers,
        })
        .subscribe(
          (response) => resolve(response?.data),
          (error) => reject(error)
        );
    });
  }

  sendFax({
    toNumber,
    mediaName,
  }: {
    toNumber: string;
    mediaName: string;
  }): Promise<any> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: mediaName,
          from: '+12523900048',
          to: toNumber,
        },
        { headers }
      )
      .toPromise();
  }

  // async generatePdfFromTemplate(template: string): Promise<Blob> {
  //   const doc = new jsPDF()
  //   doc.text(template, 10, 10) // Add text from template

  //   return doc.output('blob')
  // }

  async generatePdfFromTemplate(template: string): Promise<Blob> {
    // Create a container element
    const container = document.createElement('div');
    (container.innerHTML = `${this.LabOrder.nativeElement.innerHTML}`),
      document.body.appendChild(container);

    return new Promise((resolve, reject) => {
      html2pdf()
        .from(container)
        .toPdf()
        .output('blob')
        .then((blob: Blob) => {
          document.body.removeChild(container);
          resolve(blob);
        })
        .catch((error: any) => {
          document.body.removeChild(container);
          reject(error);
        });
    });
  }

  uploadPdf(pdfBlob: Blob): Promise<{ data: { media_name: string } }> {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    const formData = new FormData();
    formData.append('media', pdfBlob, 'fax.pdf');

    return this.http
      .post<any>('https://api.telnyx.com/v2/media', formData, { headers })
      .toPromise();
  }

  faxes() {
    const headers = new HttpHeaders({
      Authorization:
        'Bearer KEY018F1B3936DBB73C952D24A5C5946FB7_uHXA9t9leqKfXcg7qaiwNj',
    });

    return this.http
      .post<any>(
        'https://api.telnyx.com/v2/faxes',
        {
          connection_id: '2433304486127601151',
          media_name: `${this.LabOrder.nativeElement.innerHTML}`,
          from: '+12523900048',
          to: this.fax.value,
        },
        { headers }
      )
      .subscribe((res) => {
        console.log(res);
      });
  }
}
