import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { SignatureService } from 'src/app/services/signature.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Signature } from 'src/app/Models/signature.model';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css'],
})
export class SignatureComponent implements OnInit {
  url = environment.Url + 'Signatures';

  showDropdown = false;
  signatures: Signature[] = [];
  signaturePad: any;
  id = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  Frm: FormGroup;
  textBoxContent: string;
  constructor(
    private http: HttpClient,
    private signatureService: SignatureService,
    private fb: FormBuilder
  , private tokenService: TokenService) {}
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.Frm = this.fb.group({
      UserId: this.id,

      // Signauture: ['', Validators.required],
    });
    this.getSignatures();
  }
  get Password() {
    return this.Frm.get('UserId');
  }
  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  getSignatures() {
    const userId = this.id; // Replace with actual user ID
    this.http
      .get<Signature>(this.url + '/' + this.id)
      .subscribe((data: any) => {
        console.log(data);
        this.signatures = data;
      });
  }

  copySignature(signatureData: string) {
    navigator.clipboard.writeText(signatureData).then(() => {
      alert('Signature copied!');
      this.textBoxContent += `<img src="${signatureData}" alt="Signature" />`;
      this.showDropdown = false; // Close dropdown after copying
    });
  }

  @ViewChild('signPad', { static: false })
  signPad!: ElementRef<HTMLCanvasElement>;
  @Output() signatureSaved = new EventEmitter();
  private signatureImg?: string;
  private sigPadElement: any;
  private context: any;
  private isDrawing!: boolean;

  public ngAfterViewInit(): void {
    this.sigPadElement = this.signPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#000';
  }

  onMouseDown(e: any): void {
    // The mouse button is clicked, which means the start of drawing the signature
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: any): void {
    // The mouse button is released, so this means the end of drawing the signature
    this.isDrawing = false;
  }

  onMouseMove(e: any): void {
    e.preventDefault();

    if (this.isDrawing) {
      // if we're not drawing we need to ignore the events
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  clearSignature(e): void {
    e.preventDefault;

    this.signatureImg = undefined;
    this.context.clearRect(
      0,
      0,
      this.sigPadElement.width,
      this.sigPadElement.height
    );
    this.context.beginPath();
  }

  saveSignature(e): void {
    e.preventDefault;
    this.signatureImg = this.sigPadElement.toDataURL('image/png');

    this.signatureSaved.emit(this.signatureImg);
    console.log('bite', this.signatureImg);
  }

  private relativeCoords(event: any): { x: number; y: number } {
    const bounds = event.target.getBoundingClientRect();
    const cords = {
      clientX: event.clientX || event.changedTouches[0].clientX,
      clientY: event.clientY || event.changedTouches[0].clientY,
    };
    const x = cords.clientX - bounds.left;
    const y = cords.clientY - bounds.top;
    return { x, y };
  }

  deleteSignature(id: number) {
    if (confirm('Are you sure you want to delete this signature?')) {
      this.http.delete(`api/signatures/${id}`).subscribe(() => {
        alert('Signature deleted!');
        this.getSignatures();
      });
    }
  }

  formSubmit() {
    let body = {
      ...this.Frm.value,
      SignatureData: this.signatureImg,
    };
    console.log(body);
    this.signatureService.registerSignature(body).subscribe((res) => {
      alert('Signature saved!');
      this.getSignatures();
    });

    // this.http
    //   .post('api/Signatures', { UserId: userId, SignatureData: signatureData })
    //   .subscribe(() => {
    //     alert('Signature saved!');
    //     this.getSignatures();
    //   });
  }
}
