<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Add Immunizatioin record</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
        <div class="row">
          <div class="col-lg">
            <mat-form-field class="example-full-width">
              <mat-label>Vaccine Name</mat-label>
              <input type="text" formControlName="VaccineName" matInput>
              <mat-error>Please enter Substance which Vaccine Name </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Vaccine Provider</mat-label>
              <input type="text" formControlName="VaccineProvider" matInput>
              <mat-error>Please enter Vaccine Provider</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Date Taken</mat-label>
              <input type="date" formControlName="DateTaken" matInput>
              <mat-error>Please enter Date Taken</mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <input type="text" formControlName="Description" matInput>
              <mat-error>Please enter Description</mat-error>
            </mat-form-field>



          </div>
        </div>
        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
    <!-- <div>
      <button (click)="completed()" class="float-right lgBtGreen" mat-button>
        Completed
      </button>
    </div> -->
  </div>
</div>