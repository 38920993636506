import { Invoice } from './../Models/invoice.model';
import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  url = environment.Url + 'Invoicess';
  urlinvoiceItems = environment.Url + 'Invoicess/invoiceItems';
  urlIcdCodes = environment.Url + 'Invoicess/icdCodes';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}
  generateEdiClaim(invoiceId: number): Observable<string> {
    return this.http.post<string>(
      `https://binsera.net/api/claim/generate-edi`,
      invoiceId
    );
  }

  submitClaim(payload: {
    ediFileContent: string;
    invoiceHtml: string;
  }): Observable<void> {
    return this.http.post<void>(
      'https://binsera.net/api/claim/submit-claim',
      payload
    );
  }

  registerInvoice(body): Observable<Invoice> {
    return this.http
      .post<Invoice>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getInvoices(): Observable<Invoice[]> {
    return this.http
      .get<Invoice[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getInvoiceById(id): Observable<Invoice> {
    return this.http.get<Invoice>(this.url + '/' + id);
  }
  getInvoiceItems(id): Observable<Invoice> {
    return this.http.get<Invoice>(this.urlinvoiceItems + '/' + id);
  }
  geticdCodes(id): Observable<Invoice> {
    return this.http.get<Invoice>(this.urlIcdCodes + '/' + id);
  }

  updateInvoice(id, body): Observable<Invoice> {
    return this.http
      .put<Invoice>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deleteInvoice(id): Observable<Invoice> {
    return this.http
      .delete<Invoice>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
