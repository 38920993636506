import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-twofa-dea',
  templateUrl: './twofa-dea.component.html',
  styleUrls: ['./twofa-dea.component.css'],
})
export class TwofaDeaComponent implements OnInit {
  otp: any;
  inputDigit: string = 'Verify Code';
  btnStaus: string = 'btn-light';
  decodedToken: any;
  UserName: any;
  id = 0;
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.Enter) {
      // Your row selection code
      // console.log(event);
      this.veryFy(event);
    }
  }
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<TwofaDeaComponent>, // This is how you control the dialog
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public configurations = {
    length: 5,
  };

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      // this.UserName = res['unique_name'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.UserName = decodedToken.unique_name;
  }

  onOtpChange(event: any) {
    this.otp = event;
    if (this.otp.length < this.configurations.length) {
      this.inputDigit =
        this.configurations.length - this.otp.length + ' digits left';
    }
    if (this.otp.length === this.configurations.length) {
      this.inputDigit = 'Go';
      this.btnStaus = 'btn-primary';
    }
  }

  veryFy(e) {
    let body = {
      TwoFactorCode: this.otp.toString(),
      UserName: this.UserName,
    };

    this.authService.TwoFa(body).subscribe((res) => {
      if (res === true) {
        alert('Successfully Validated');
        this.dialogRef.close();
      } else {
        alert('not user found');
      }
    });
  }
}
export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  Enter = 13,
}
