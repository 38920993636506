import { Component } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-duty',
  templateUrl: './update-duty.component.html',
  styleUrls: ['./update-duty.component.css']
})
export class UpdateDutyComponent {

}
