import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorReport } from 'src/app/Models/doctorreport.model';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { Patient } from 'src/app/Models/patient.model';
import { RadiologyMri } from 'src/app/Models/radiologymri.model';
import { UploadLabReport } from 'src/app/Models/uploadlabreport.model';
import { DoctorReportService } from 'src/app/services/doctor-report.service';
import { PatientService } from 'src/app/services/patient.service';
import { TokenService } from 'src/app/services/token.service';
import jsPDF from 'jspdf';
import { FormControl } from '@angular/forms';
@Component({
  selector: 'app-patient-side-progress-note',
  templateUrl: './patient-side-progress-note.component.html',
  styleUrls: ['./patient-side-progress-note.component.css'],
})
export class PatientSideProgressNoteComponent implements OnInit {
  @ViewChild('PatientVisit') PatientVisit!: ElementRef;
  email = new FormControl('');

  heartRateChart: any = {
    series: [
      {
        name: 'Heart Rate',
        data: [],
      },
    ],
    title: {
      text: 'Heart Rate Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  respirationChart: any = {
    series: [
      {
        name: 'Respiration Rate',
        data: [],
      },
    ],
    title: {
      text: 'Respiratory Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  tempratureChart: any = {
    series: [
      {
        name: 'Temprature',
        data: [],
      },
    ],
    title: {
      text: 'Temprature Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  bloodPressureChart: any = {
    series: [
      {
        name: 'Systolic',
        data: [],
      },
      {
        name: 'Diastolic',
        data: [],
      },
    ],
    title: {
      text: 'Blood Pressure Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      categories: [],
    },
  };
  OxygenSaturationChart: any = {
    series: [
      {
        name: 'Oxygen Saturation',
        data: [],
      },
    ],
    title: {
      text: 'Oxygen Saturation Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };
  displayedColumns: string[] = [
    'actions',
    'Date',
    'ReportName',
    'shortcut',
    'DoctorName',
    'Signature',
    'View',
    'Update',
    'Delete',
  ];

  id = 0;
  doctorReport: DoctorReport[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  patient: Patient;
  reportDetails: string;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();

  CurrentDoctorId: number;
  nurseReports: NurseReport[] = [];
  templatesLabReportsUploaded: UploadLabReport[];
  templatesRadiologyStudyReports: RadiologyMri[];
  progressNote: DoctorReport[];
  reportContent: SafeHtml | null = null;
  plainTextContent: string = '';
  constructor(
    private patientService: PatientService,
    private doctorReportService: DoctorReportService,
    private route: ActivatedRoute,
    private router: Router,
    private tokenService: TokenService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
    this.GetReports(this.id);
  }

  GetReports(id: number): void {
    this.patientService.getDoctorReport(id).subscribe(
      (res) => {
        if (res && res.DoctorReports) {
          const sortedReports = res.DoctorReports.sort(
            (x, y) => y.DoctorReportId - x.DoctorReportId
          );

          // Generate HTML content for display
          const htmlContent = sortedReports
            .map(
              (report: any) => `
              <div class="row">
                <div class="col-lg-12">
                  ${report.ReportDescription}<br>
                  <b>Doctor Name:</b> ${report.DoctorName}
                </div>
              </div>
              `
            )
            .join('');
          this.reportContent =
            this.sanitizer.bypassSecurityTrustHtml(htmlContent);

          // Generate plain text content for download
          this.plainTextContent = sortedReports
            .map(
              (report: any) =>
                `Report Description: ${report.ReportDescription}\nDoctor Name: ${report.DoctorName}`
            )
            .join('\n\n');
        } else {
          console.warn('No DoctorReports found in response.');
          this.reportContent = null;
          this.plainTextContent = '';
        }
      },
      (error) => {
        console.error('Error fetching doctor reports:', error);
        this.reportContent = null;
        this.plainTextContent = '';
      }
    );
  }
  send() {
    let body = {
      Email: this.email.value,
      Subject: 'Medical Records',
      Template: `${this.PatientVisit.nativeElement.innerHTML}`,
    };
    this.patientService.sendEmail(body).subscribe((res) => {
      alert('Medical Records sent');
    });
  }
  // Download the report content as a PDF
  downloadPDF(): void {
    const doc = new jsPDF();
    const lines = this.plainTextContent.split('\n');

    let y = 10; // Initial vertical position on the PDF
    lines.forEach((line) => {
      doc.text(line, 10, y);
      y += 10; // Move down by 10 units for each line
    });

    doc.save('DoctorReports.pdf'); // Save the PDF with a specific file name
  }

  printReports(): void {
    const printWindow = window.open('', '_blank', 'width=800,height=600');
    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Print Reports</title>
          </head>
          <body>
            ${this.reportContent}
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  }
}
