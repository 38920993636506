<form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" class="example-form">
    <div class="container mt-4">
        <div class="row ">
            <!-- <div class="col-lg-3">
          <mat-form-field class="example-full-width">
            <mat-label>Ward Name</mat-label>
            <mat-select formControlName="WardId" (selectionChange)="getPatients()">
              <mat-option *ngFor="let ward of wardList" [value]="ward.WardId">{{
                ward.WardName }}</mat-option>
            </mat-select>
            <mat-error>Select a Ward</mat-error>
          </mat-form-field>
        </div> -->
        </div>
    </div>
</form>
<div class="ml-3">
    <div class="row">
        <div class="col-lg-6 float-right">
            <mat-form-field class="example-full-width mt-5">
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
        Successfully Assigned Patient your workspace - Please refresh your dashboard
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<button mat-raised-button color="primary" *ngIf="isNurse" (click)="selectedPatient()">Select Patients</button>
<div class=" mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="select" *ngIf="isNurse">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Select </th>
            <td mat-cell *matCellDef="let row"> <mat-checkbox class="example-margin"
                    (change)="selectPatients($event, row)"></mat-checkbox> </td>
        </ng-container>


        <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientId}} </td>
        </ng-container>


        <ng-container matColumnDef="FirstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientFirstName}} </td>
        </ng-container>
        <ng-container matColumnDef="LastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientLastName}} </td>
        </ng-container>
        <ng-container matColumnDef="Gender">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientGender}} </td>
        </ng-container>
        <ng-container matColumnDef="PatientNationalId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> National Id </th>
            <td mat-cell *matCellDef="let row"> {{row.PatientNationalId}} </td>
        </ng-container>
        <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td class="ml-4" mat-cell *matCellDef="let row"> {{row.Email}} </td>
        </ng-container>
        <ng-container matColumnDef="PhoneNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number </th>
            <td mat-cell *matCellDef="let row"> {{row.PhoneNumber}} </td>
        </ng-container>
        <ng-container matColumnDef="PrimaryDoctor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Doctor </th>
            <td mat-cell *matCellDef="let row"> {{row.PrimaryDoctorsName}} </td>
        </ng-container>
        <ng-container matColumnDef="WardName">
            <th class="ml-4" mat-header-cell *matHeaderCellDef mat-sort-header> Ward </th>
            <td class="ml-4" mat-cell *matCellDef="let row"> {{row.WardName}} </td>
        </ng-container>

        <ng-container matColumnDef="View">
            <th mat-header-cell *matHeaderCellDef> Patient Details</th>
            <td mat-cell *matCellDef="let row">
                <button type="button" [routerLink]="['/nursePatient/',row.PatientId]" mat-mini-fab color="success">
                    <mat-icon matPrefix>remove_red_eye</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="Edit" *ngIf="!isNurse">
            <th mat-header-cell *matHeaderCellDef> Edit</th>
            <td mat-cell *matCellDef="let row">
                <button type="button" [routerLink]="['/updatePatient/',row.PatientId]" mat-mini-fab color="primary">
                    <mat-icon matPrefix>edit</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="Delete" *ngIf="!isNurse">
            <th mat-header-cell *matHeaderCellDef> Delete</th>
            <td mat-cell *matCellDef="let row">
                <button type="button" (click)="deletePatient(row.PatientId)" mat-mini-fab color="accent">
                    <mat-icon matPrefix>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>