import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientBooking } from 'src/app/Models/patientbooking.model';
import { PatientService } from 'src/app/services/patient.service';
import { AppointmentService } from '../../services/appointment.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HospitalService } from 'src/app/services/hospital.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import * as moment from 'moment';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-appointments-today',
  templateUrl: './view-appointments-today.component.html',
  styleUrls: ['./view-appointments-today.component.css'],
})
export class ViewAppointmentsTodayComponent implements OnInit {
  displayedColumns: string[] = [
    'PatientId',
    'DoctorName',
    'DoctorSpecialization',
    'VisitDate',
    'VisitTime',
    'Message',
    'Status',
    'checkin',
    'Detail',
    'Delete',
  ];

  id = 0;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  Appointments: PatientBooking[];

  filteredList;
  dataSource: MatTableDataSource<any>;
  date;
  Frm: FormGroup;
  doctor: Doctor[] = [];

  constructor(
    private appointmentService: AppointmentService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private hospitalService: HospitalService,
    private fb: FormBuilder,
    private doctorService: DoctorService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    let dat = Date.now();
    this.date = moment(dat).format('MM/DD/YYYY');

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.getDoctor(this.id);
    this.Frm = this.fb.group({
      DoctorId: [''],
    });
    // setInterval(() => {
    //   this.getAppt();
    // }, 60000);

    // 60 seconds
    this.getAppt();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get DoctorId() {
    return this.Frm.get('DoctorId');
  }

  getDoctor(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctor = res.Doctors;
    });
  }

  getAppt() {
    this.doctorService
      .getDoctorBooking(this.DoctorId.value)
      .subscribe((res) => {
        this.dataSource = new MatTableDataSource(
          res.PatientBookings.filter((x) => x.VisitDate == this.date)
        );
        this.dataSource.paginator = this.paginator;

        // this.filteredList = res.PatientBookings.filter(
        //   (x) => x.VisitDate == this.date
        // );

        this.Appointments = res.PatientBookings;
      });
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.Appointments.filter((x) =>
        x.VisitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  delete(id) {
    this.appointmentService.deletePatientBooking(id).subscribe((res) => {
      // this.filteredList = this.Appointments.filter(item=>item.PatientBookingId !=id)

      this.getAppt();
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
