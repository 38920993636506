import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ChatMessage } from 'src/app/Models/chatmessage.model';
import { Doctor } from 'src/app/Models/doctor.model';
import { Nurse } from 'src/app/Models/nurse.model';
import { User } from 'src/app/Models/user.model';
import { ChatService } from 'src/app/services/chat.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { NurseService } from 'src/app/services/nurse.service';

@Component({
  selector: 'app-chat-for-nurse',
  templateUrl: './chat-for-nurse.component.html',
  styleUrls: ['./chat-for-nurse.component.css'],
})
export class ChatForNurseComponent implements OnInit {
  // users = ['User1', 'User2', 'User3']; // Replace with real user list
  selectedUser: string;
  message: string;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  users: User[] = [];
  myMessage: ChatMessage[];
  hospitalId: number;
  doctorList: Doctor[];
  nurseList: Nurse[];
  doctor: Doctor;
  combinedData: any[] = [];
  isSuccess = false;
  Frm: FormGroup;
  nurse: Nurse;
  nurseId: number = 0;

  constructor(
    private chatService: ChatService,
    private fb: FormBuilder,
    private doctorService: DoctorService,
    private nurseService: NurseService,
    private hospitalService: HospitalService
  ) {}

  ngOnInit() {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);
    this.getNurse(this.nurseId);
    this.nurseService.getNurseHospital(this.nurseId).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getDoctors(this.hospitalId);
    });
    this.getMessages(this.nurseId);

    this.Frm = this.fb.group({
      Message: [''],
      UserId: [],
    });
  }

  get UserId() {
    return this.Frm.get('UserId');
  }
  get Message() {
    return this.Frm.get('Message');
  }

  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }
  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }

  getMessages(id) {
    this.nurseService.getnurseChats(id).subscribe((res) => {
      this.myMessage = res.ChatMessages;
    });
  }
  formSubmit() {
    let userId = this.Frm.value['UserId'];
    let message = this.Frm.value['Message'];
    let doctorName = this.doctorList.filter((it) => it.DoctorId == userId)[0]
      .DoctorName;
    let body = {
      Message: message,
      FromUserName: this.nurse.NurseName,
      ToUserName: doctorName,
      DoctorId: userId,
      NurseId: this.nurseId,
    };
    this.chatService.sendChatMessage(body).subscribe((res) => {
      this.isSuccess = true;
      this.myMessage.push(res);
    });
  }
  // sendMessage() {
  //   if (this.selectedUser && this.message) {
  //     this.chatService.sendMessage(this.selectedUser, this.message);
  //     this.message = ''; /
  //   }
  // }

  delete(id) {
    this.chatService.deleteChatMessage(id).subscribe((res) => {
      alert('are you sure do you wanted to delete message?');
      this.getMessages(this.doctorId);
    });
  }
}
