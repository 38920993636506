import { Component } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-roster',
  templateUrl: './update-roster.component.html',
  styleUrls: ['./update-roster.component.css']
})
export class UpdateRosterComponent {

}
