import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorTemplateService } from 'src/app/services/doctor-template.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-doctor-template-editor',
  templateUrl: './update-doctor-template-editor.component.html',
  styleUrls: ['./update-doctor-template-editor.component.css'],
})
export class UpdateDoctorTemplateEditorComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');

  isSuccess = false;
  editorContent: any = ''; // Your editor content
  template: { name: string; shortcut: string; content: string };

  constructor(
    private templateService: DoctorTemplateService,
    private doctorService: DoctorService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);

    this.templateService.getTemplateById(this.id).subscribe((res) => {
      this.template.name = res.Name;
      this.template.shortcut = res.shortcut;
      this.template.content = res.Content;
    });
    this.template = {
      name: '',
      shortcut: '',
      content: '',
    };
  }

  question_image(event) {
    // console.log(e)
    let formData = new FormData();
    let files = event.target.files;
    formData.append('file', files[0], files[0].name);

    this.doctorService.imageUpload(formData).subscribe(
      (res) => {
        let qImag = `<p><img src="${environment.imgurl + res['path']}"/></p>`;
        // let stImg = environment.imgurl + res['path'];

        this.editorContent.setValue(this.editorContent.value + qImag);
      },
      (err) => console.log(err)
    );
  }

  // Update the content on every change in the editor
  onContentChanged(event: any) {
    this.template.content = event.html; // Update the content based on Quill's HTML
  }
  saveTemplate() {
    let body = {
      ...this.template,
      Content: this.template.content.replace(/<\/?p[^>]*>/g, '\n'),
      DoctorId: this.doctorId,
    };

    this.templateService.updateTemplate(this.id, body).subscribe((response) => {
      this.isSuccess = true;
      this.router.navigate(['/doctorDash/']);
    });
  }
}
// Content: this.editorContent.replace(/<\/?p[^>]*>/g, ''),
