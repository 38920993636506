import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IntakeAssessmentService } from 'src/app/services/intake-assessment.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-intake-assessment',
  templateUrl: './update-intake-assessment.component.html',
  styleUrls: ['./update-intake-assessment.component.css']
})
export class UpdateIntakeAssessmentComponent  implements OnInit {

  helper = new JwtHelperService()

  myToken = this.tokenService.getToken();

  id=0;

nurseId=0;
  isSuccess= false;
  Frm:FormGroup

  constructor(private fb:UntypedFormBuilder,private route:ActivatedRoute,private intakeAssessmentService:IntakeAssessmentService,private router:Router, private tokenService: TokenService) { }

  ngOnInit(): void {
    this.route.params.subscribe(res=>{
      this.id = res['id'];
  })
  let decodedToken = this.helper.decodeToken(this.myToken);
  this.nurseId = parseInt(decodedToken.nameid);

  this.intakeAssessmentService.getIntakeAssessmentById(this.id).subscribe(res=>{
    this.Frm.patchValue(res);
  })

  this.Frm = this.fb.group({
    PatientId: this.id,
    NurseId:this.nurseId,
    Intake:[''],
    IntakeTotal:[''],
    Output:[''],
    OutputTotal:[''],
    Other:[''],
    PreviousWeight:[''],
    CurrentWeight:[''],
    DateOfLastBowelMovement:[''],
    WeightGainorLoss:[''],
    IVfluid:[''],
    OralIntake:[''],
    
  })
  
  }





get PatientId(){
  return this.Frm.get('PatientId')
}
get NurseId(){
  return this.Frm.get('NurseId')
}
get Intake(){
  return this.Frm.get('Intake')
}
get IntakeTotal(){
  return this.Frm.get('IntakeTotal')
}
get Output(){
  return this.Frm.get('Output')
}
get OutputTotal(){
  return this.Frm.get('OutputTotal')
}
get Other(){
  return this.Frm.get('Other')
}
get PreviousWeight(){
  return this.Frm.get('PreviousWeight')
}
get CurrentWeight(){
  return this.Frm.get('CurrentWeight')
}
get DateOfLastBowelMovement(){
  return this.Frm.get('DateOfLastBowelMovement')
}
get WeightGainorLoss(){
  return this.Frm.get('WeightGainorLoss')
}



get IVfluid(){
  return this.Frm.get('IVfluid')
}
get OralIntake(){
  return this.Frm.get('OralIntake')
}



getGrandTotal(){
  let total=0;
  total = total + this.Intake.value + this.IVfluid.value + this.OralIntake.value
  this.IntakeTotal.setValue(total)
}

formSubmit(){

  this.intakeAssessmentService.registerIntakeAssessment(this.Frm.value).subscribe(res=>{
    this.isSuccess=true;
    // this.Frm.reset();
  this.router.navigate(['nurseDash'])
  })

}


}
