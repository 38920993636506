<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>New Family History</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
        <h4>Please indicate if any of your immediate relatives have had any of the following </h4>
        <hr>
        <div class="row">

          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="AnesthesiaProblems">
              Anesthesia Problems
            </mat-checkbox>

            <mat-form-field class="example-full-width">
              <mat-label>Who had Anesthesia Problems Father / Mother? </mat-label>
              <input type="text" formControlName="WhoHadAnesthesiaProblems" matInput> </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="Arthritis">
              Arthritis
            </mat-checkbox>

            <mat-form-field class="example-full-width">
              <mat-label>Who had Arthritis Problems Father / Mother? </mat-label>
              <input type="text" formControlName="WhoHadArthritis" matInput> </mat-form-field>
          </div>

        </div>

        <div class="row">

          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="Cancer">
              Cancer Problems
            </mat-checkbox>

            <mat-form-field class="example-full-width">
              <mat-label>Who had Cancer Problems Father / Mother? </mat-label>
              <input type="text" formControlName="WhoHadCancer" matInput> </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="Diabetes">
              Diabetes
            </mat-checkbox>

            <mat-form-field class="example-full-width">
              <mat-label>Who had Diabetes Problems Father / Mother? </mat-label>
              <input type="text" formControlName="WhoHadDiabetes" matInput> </mat-form-field>
          </div>

        </div>
        <div class="row">

          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="HeartProblems">
              Heart Problems
            </mat-checkbox>

            <mat-form-field class="example-full-width">
              <mat-label>Who had Heart Problems Father / Mother? </mat-label>
              <input type="text" formControlName="WhoHadHeartProblems" matInput> </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="Hypertension">
              Hypertension
            </mat-checkbox>

            <mat-form-field class="example-full-width">
              <mat-label>Who had Hypertension Problems Father / Mother? </mat-label>
              <input type="text" formControlName="WhoHadHypertension" matInput> </mat-form-field>
          </div>

        </div>
        <div class="row">

          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="Stroke">
              Stroke
            </mat-checkbox>

            <mat-form-field class="example-full-width">
              <mat-label>Who had Stroke Father / Mother? </mat-label>
              <input type="text" formControlName="WhoHadStroke" matInput> </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-checkbox color="primary" formControlName="ThyroidDisorder">
              Thyroid Disorder
            </mat-checkbox>

            <mat-form-field class="example-full-width">
              <mat-label>Who had Thyroid Disorder Problems Father / Mother? </mat-label>
              <input type="text" formControlName="WhoHadThyroidDisorder" matInput> </mat-form-field>
          </div>

        </div>
        <hr>
        <div class="row">
          <h4> If not Listed above please add below</h4>
          <div class="col-md-12">

            <mat-form-field class="example-full-width">
              <mat-label>Problem?</mat-label>
              <input type="text" formControlName="Problem" matInput>
              <!-- <mat-error >Please enter Problem</mat-error> -->
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Who had problem ? Father / Mother? </mat-label>
              <input type="text" formControlName="WhoHadIt" matInput>

            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <input type="text" formControlName="Description" matInput>
              <!-- <mat-error >Please enter Description</mat-error> -->
            </mat-form-field>
          </div>
        </div>

        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>

  </div>
</div>