<div class="container mx-5 my-5  ">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Send Messsage</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Select a Physician</mat-label>


                    <!-- <input type="text" formControlName="DoctorName" matInput >
              <mat-error >Please enter Doctor Name</mat-error> -->

                    <mat-select formControlName="UserId">

                        <mat-option *ngFor="let dr of doctorList" [value]="dr.DoctorId">{{dr.DoctorName}}</mat-option>

                    </mat-select>
                    <mat-error>select an option</mat-error>
                </mat-form-field>

                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Message</mat-label>

                    <textarea matInput formControlName="Message" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="5" cdkAutosizeMaxRows="140" matInput></textarea>


                </mat-form-field>


                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>
<div class="mt-5">
    <table class="table table">
        <thead>
            <th>From</th>
            <th>To</th>
            <th>Message</th>
            <th>Time</th>
        </thead>
        <tbody>
            <tr *ngFor="let mes of myMessage">
                <td>{{mes.FromUserName}}</td>
                <td>{{mes.ToUserName}}</td>
                <td>{{mes.Message}}</td>
                <td>{{mes.Timestamp | date: 'medium'}}</td>
                <td><button color="accent" mat-mini-fab (click)="delete(mes.Id)"><mat-icon>delete</mat-icon></button>
                </td>
            </tr>
        </tbody>
    </table>