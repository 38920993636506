import { Component } from '@angular/core';

@Component({
  selector: 'app-printinvoice-doctor-patient',
  templateUrl: './printinvoice-doctor-patient.component.html',
  styleUrls: ['./printinvoice-doctor-patient.component.css']
})
export class PrintinvoiceDoctorPatientComponent {

}
