import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Hospital } from 'src/app/Models/hospital.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientVisit } from 'src/app/Models/patientvisit.model';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';

import { TokenService } from 'src/app/services/token.service';
import html2pdf from 'html2pdf.js';
@Component({
  selector: 'app-ptmedicalrecords',
  templateUrl: './ptmedicalrecords.component.html',
  styleUrls: ['./ptmedicalrecords.component.css'],
})
export class PtmedicalrecordsComponent implements OnInit {
  @ViewChild('PatientVisit') PatientVisit!: ElementRef;
  id = 0;
  helper = new JwtHelperService();
  show = false;
  myToken = this.tokenService.getToken();
  patient: Patient;
  patientVisitList: PatientVisit[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  patientVisitId: PatientVisit[];
  isHide = false;
  hospitalId: number;
  hospital: Hospital;
  email = new FormControl('');

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private ptVisitService: PatientvisitService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    // this.route.params.subscribe(res=>{
    //   this.id= res['id']
    // })

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospital = res.Hospital;
    });
    this.patientService.getPatientvisits(this.id).subscribe((res) => {
      this.patient = res;

      this.filteredList = res.PatientVisits;
      this.patientVisitList = res.PatientVisits;

      //  if(res.PatientVisits.filter(x=>x.VisitType=="In Patient")){
      //   this.isAdmitted
      //  }
    });
    this.getPatientById(this.id);
  }
  printPage() {
    window.print();
    // this.isHide = true;
  }
  downloadAsPDF() {
    const content = document.getElementById('printable');
    const button = document.querySelector('button'); // Select the button
    if (content) {
      button?.classList.add('downloadButton');
      // Configure html2pdf options
      const options = {
        margin: 1, // Margins in cm
        filename: 'medical-record.pdf', // Output file name
        image: { type: 'jpeg', quality: 0.98 }, // Image quality
        html2canvas: { scale: 2 }, // Scale for higher resolution
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }, // A4 portrait format
      };

      // Generate PDF
      html2pdf().set(options).from(content).save();
    }
  }
  send() {
    let body = {
      Email: this.email.value,
      Subject: 'Medical Records',
      Template: `${this.PatientVisit.nativeElement.innerHTML}`,
    };
    this.patientService.sendEmail(body).subscribe((res) => {
      alert('Medical Records sent');
    });
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.VistitDate.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  getPatientById(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;

      // console.log(this.isNewPatient);
    });
  }

  itemsToShow = 2;

  // Function to load more items
  loadMore() {
    this.itemsToShow += 1; // Increase the number of items to show by 3
  }

  // Function to check if there are more items to load
  hasMoreItems() {
    return this.itemsToShow < this.filteredList?.length;
  }
}
