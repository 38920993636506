import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
// import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
// import { DUO_CONFIG } from './duo-config';
import { JwtHelperService } from '@auth0/angular-jwt';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();
  error = false;
  IsthrowError = '';
  netWorkFailure: string;
  passwordFieldType: string = 'password';

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {}

  logiForm = this.fb.group({
    UserName: ['', [Validators.required]],
    Password: ['', Validators.required],
    PrivacyPolicy: ['', Validators.required],
  });

  get PrivacyPolicy() {
    return this.logiForm.get('PrivacyPolicy');
  }
  get UserName() {
    return this.logiForm.get('UserName');
  }
  get Password() {
    return this.logiForm.get('Password');
  }
  togglePasswordVisibility(): void {
    this.passwordFieldType =
      this.passwordFieldType === 'password' ? 'text' : 'password';
  }
  loginSubmit() {
    if (this.logiForm.valid) {
      this.authService.login(this.logiForm.value).subscribe(
        (res) => {
          window.localStorage.setItem('token', res['token']);

          let currentToken = res['token'];

          let decodedToken = this.helper.decodeToken(currentToken);
          this.id = parseInt(decodedToken.nameid);

          this.router.navigate(['twofa', decodedToken]);
          // if (decodedToken.role == 'Admin') {
          //   this.router.navigate(['/adminDash']);
          // } else if (decodedToken.role == 'Doctor') {
          //   this.router.navigate(['/doctorDash']);
          // } else if (decodedToken.role == 'Nurse') {
          //   this.router.navigate(['/nurseDash/']);
          // } else if (decodedToken.role == 'LabManager') {
          //   this.router.navigate(['/labDash/']);
          // } else if (decodedToken.role == 'PharmacyMember') {
          //   this.router.navigate(['/pharmacyDash/']);
          // } else if (decodedToken.role == 'Patient') {
          //   this.router.navigate(['/patientDash/', this.id]);
          // } else if (decodedToken.role == 'RadiologyMember') {
          //   this.router.navigate(['/radiologyDash/']);
          // } else {
          //   alert('not user found');
          // }
        },
        (err) => {
          this.error = true;
          this.netWorkFailure = err;
        }
      );
    } else {
      this.IsthrowError =
        'Please review and check privacy policy to continue login';
    }
  }
}
// if(this.logiForm.valid){
//   this.authService.TwoFa(this.logiForm.value).subscribe(res=>{
//    this.router.navigate(['twofa'])
//   })

//   }
