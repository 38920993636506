import { Component } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-familyhistory',
  templateUrl: './update-familyhistory.component.html',
  styleUrls: ['./update-familyhistory.component.css']
})
export class UpdateFamilyhistoryComponent {

}
