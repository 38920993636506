import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HospitaltemplateService } from 'src/app/services/hospitaltemplate.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-hospital-template',
  templateUrl: './hospital-template.component.html',
  styleUrls: ['./hospital-template.component.css'],
})
export class HospitalTemplateComponent {
  id = 0;
  nurseId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();

  isSuccess = false;
  hospitalId = 0;

  constructor(private templateService: HospitaltemplateService, private tokenService: TokenService) {}
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.hospitalId = parseInt(decodedToken.nameid);
  }

  template = {
    name: '',
    shortcut: '',
    // content: '',
  };
  question_image(event) {
    // console.log(e)
    let formData = new FormData();
    let files = event.target.files;
    formData.append('file', files[0], files[0].name);
  }

  editorContent: any = ''; // Your editor content

  // Update the content on every change in the editor
  onContentChanged(event: any) {
    this.editorContent = event.html;
    //   let cleanedContent = this.editorContent.replace(/<\/?p[^>]*>/gi, '\n');

    //   cleanedContent = cleanedContent.replace(/<\/?strong[^>]*>/gi, '');

    //   cleanedContent = cleanedContent.replace(/<\/?[^>]+(>|$)/g, '');

    //   cleanedContent = cleanedContent.replace(/\n\s*\n/g, '\n').trim();

    //   this.editorContent = cleanedContent;
  }
  saveTemplate() {
    let body = {
      ...this.template,
      Content: this.editorContent,
      HospitalId: this.hospitalId,
    };

    this.templateService.saveTemplate(body).subscribe((response) => {
      this.isSuccess = true;
    });
  }
}
// Content: this.editorContent.replace(/<\/?p[^>]*>/g, ''),
// Content: this.editorContent.replace(/<\/?p[^>]*>/g, '\n'),
