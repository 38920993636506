<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>New Medical History</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
        <h4>Have you ever had any of the following?</h4>
        <hr>

        <div class="row">
          <div class="col-md-4">
            <mat-checkbox class="mx-3" color="primary" formControlName="NoneOfOtherProblemsListed">
            </mat-checkbox>
            <mat-label>None Of Other Problems Listed</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Chestpain">
            </mat-checkbox>
            <mat-label>Chestpain</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Hypertension">
            </mat-checkbox>
            <mat-label>Hypertension</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="Osteoporosis">
            </mat-checkbox>
            <mat-label>Osteoporosis</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="Allergies">
            </mat-checkbox>
            <mat-label>Allergies</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Congestiveheartfailure">
            </mat-checkbox>
            <mat-label>Congestive heart failure</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Hypogonadismmale">
            </mat-checkbox>
            <mat-label>Hypogonadismmale</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="Pulmonaryembolism">
            </mat-checkbox>
            <mat-label>Pulmonaryembolism</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Anemia">
            </mat-checkbox>
            <mat-label>Anemia</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Chronicfatiguesyndrome">
            </mat-checkbox>
            <mat-label>Chronic Fatigue Syndrome</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Hypothyroidism">
            </mat-checkbox>
            <mat-label>Hypothyroidism</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="Seizuredisorders">
            </mat-checkbox>
            <mat-label>Seizuredisorders</mat-label>
            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="KidneyProblems">
            </mat-checkbox>
            <mat-label>Kidney Problems</mat-label>
            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="Menopause">
            </mat-checkbox>
            <mat-label>Menopause</mat-label>

          </div>
          <div class="col-md-4">
            <mat-checkbox class="mx-3" color="primary" formControlName="Arthritisconditions">
            </mat-checkbox>
            <mat-label>Arthritis conditions</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Depression">
            </mat-checkbox>
            <mat-label>Depression</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Infectionproblems">
            </mat-checkbox>
            <mat-label>Infection problems</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="ShortnessOfBreath">
            </mat-checkbox>
            <mat-label>Shortness Of Breath</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="Asthma">
            </mat-checkbox>
            <mat-label>Asthma</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Diabetes">
            </mat-checkbox>
            <mat-label>Diabetes</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Insomnia">
            </mat-checkbox>
            <mat-label>Insomnia</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="SinusConditions">
            </mat-checkbox>
            <mat-label>Sinus Conditions</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="ArterialFibrillation">
            </mat-checkbox>
            <mat-label>Arterial Fibrillation</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="BleedingProblems">
            </mat-checkbox>
            <mat-label>Bleeding Problems</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="BPH">
            </mat-checkbox>
            <mat-label>BPH</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="CADcoronaryArteryDisease">
            </mat-checkbox>
            <mat-label>CAD coronary Artery Disease</mat-label>
            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="MigrainesOrHeadaches">
            </mat-checkbox>
            <mat-label>Migraines Or Headaches</mat-label>
            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="Neuropathy">
            </mat-checkbox>
            <mat-label>Neuropathy</mat-label>
          </div>


          <div class="col-md-4">

            <mat-checkbox class="mx-3" color="primary" formControlName="Cancer">
            </mat-checkbox>
            <mat-label>Cancer</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="CardiacArrest">
            </mat-checkbox>
            <mat-label>Cardiac Arrest</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="CeliacDisease">
            </mat-checkbox>
            <mat-label>Celiac Disease</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="DrugOrAlcoholAbuse">
            </mat-checkbox>
            <mat-label>Drug Or Alcohol Abuse</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="ErectileDysfunction">
            </mat-checkbox>
            <mat-label>Erectile Dysfunction</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Fibromyalgia">
            </mat-checkbox>
            <mat-label>Fibromyalgia</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Hypogonadismmale">
            </mat-checkbox>
            <mat-label>Hypogonadismmale</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="Gerd">
            </mat-checkbox>
            <mat-label>Gerd</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="HeartDisease">
            </mat-checkbox>
            <mat-label>Heart Disease</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Hyperinsulinemia">
            </mat-checkbox>
            <mat-label>Hyperinsulinemia</mat-label>

            <br>
            <mat-checkbox class="mx-3" color="primary" formControlName="Hyperlipidemia">
            </mat-checkbox>
            <mat-label>Hyperlipidemia</mat-label>

            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="IrritableBowelSyndrome">
            </mat-checkbox>
            <mat-label>Irritable Bowel Syndrome</mat-label>
            <br>

            <mat-checkbox class="mx-3" color="primary" formControlName="Onychomycosis">
            </mat-checkbox>
            <mat-label>Onychomycosis</mat-label>

          </div>

        </div>





        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
        </button>
        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully submitted
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>

  </div>
</div>