import { HttpClient } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-user-activity-log',
  templateUrl: './user-activity-log.component.html',
  styleUrls: ['./user-activity-log.component.css'],
})
export class UserActivityLogComponent {
  private baseUrl = environment.Url + 'ActivityLog';
  // URL to your logging endpoint

  constructor(private http: HttpClient, private tokenService: TokenService) {}

  // Example of logging a button click event
  @HostListener('click', ['$event'])
  logButtonClick(event: Event) {
    const logData = {
      action: 'Button Click',
      elementId: (event.target as HTMLElement).id,
      timestamp: new Date().toISOString(),
      userId: localStorage.getItem('userId'), // Assuming user ID is stored in local storage
    };

    this.http.post(`${this.baseUrl}/log`, logData).subscribe();
  }
}
