import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Binsera Neurology';
  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
    // ...
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event) {
    // ...
  }
  constructor(private router: Router, private tokenService: TokenService) {}
  ngOnInit() {
    window.addEventListener('storage', this.handleLogoutEvent.bind(this));
  }

  @HostListener('window:beforeunload', ['$event'])
  handleClose($event) {
    $event.returnValue = false;
  }
  handleLogoutEvent(event: StorageEvent) {
    if (event.key === 'logoutEvent') {
      // Perform logout actions (clear session, redirect, etc.)
      localStorage.removeItem('token');
      this.router.navigate(['/login']);
    }
  }
}
