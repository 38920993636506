import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, startWith, map } from 'rxjs';
import { Doctor } from 'src/app/Models/doctor.model';
import { Nurse } from 'src/app/Models/nurse.model';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { NurseService } from 'src/app/services/nurse.service';
import { NursereportService } from 'src/app/services/nursereport.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-vitals-by-doctor',
  templateUrl: './add-vitals-by-doctor.component.html',
  styleUrls: ['./add-vitals-by-doctor.component.css'],
})
export class AddVitalsByDoctorComponent implements OnInit {
  id = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  nurse: Nurse;
  options: string[] = ['Morning', 'Afternoon', 'Evening', 'Night'];
  isSuccess = false;
  nurseReports: NurseReport[];
  nurseId = 0;
  filteredShortcut: Observable<string[]>;
  shortcutNames: string[] = ['@MostRecent', '@00'];
  doctor: Doctor;
  doctorId = 0;
  constructor(
    private fb: UntypedFormBuilder,
    private nurseReportService: NursereportService,
    private router: Router,
    private route: ActivatedRoute,
    private doctorService: DoctorService
  , private tokenService: TokenService) {}
  date;
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.date = new Date();
    let decodedToken = this.helper.decodeToken(this.myToken);

    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);

    this.filteredShortcut = this.shortcut.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter6(value || ''))
    );
  }

  medFrm = this.fb.group({
    NurseName: ['', Validators.required],
    // Date: [''],
    // shortcut: [''],
    // Time: [''],
    Height: ['', Validators.required],
    Weight: ['', Validators.required],
    BMI: ['', Validators.required],
    Respiration: ['', Validators.required],
    Comments: [''],
    Temparature: ['', Validators.required],
    HeartBeat: ['', Validators.required],
    BloodPressure: ['', Validators.required],
    OxygenSaturation: ['', Validators.required],
    Signature: ['', Validators.required],
  });
  get Height() {
    return this.medFrm.get('Height');
  }
  get Weight() {
    return this.medFrm.get('Weight');
  }
  get BMI() {
    return this.medFrm.get('BMI');
  }
  get shortcut() {
    return this.medFrm.get('shortcut');
  }
  get Respiration() {
    return this.medFrm.get('Respiration');
  }
  get Temparature() {
    return this.medFrm.get('Temparature');
  }
  get HeartBeat() {
    return this.medFrm.get('HeartBeat');
  }
  get BloodPressure() {
    return this.medFrm.get('BloodPressure');
  }
  get OxygenSaturation() {
    return this.medFrm.get('OxygenSaturation');
  }
  get NurseName() {
    return this.medFrm.get('NurseName');
  }

  // get Date() {
  //   return this.medFrm.get('Date');
  // }
  get Time() {
    return this.medFrm.get('Time');
  }
  get Comments() {
    return this.medFrm.get('Comments');
  }
  get Signature() {
    return this.medFrm.get('Signature');
  }
  private _filter6(value: string): string[] {
    const ss = value.toLowerCase();

    return this.shortcutNames.filter((dd) => dd.toLowerCase().includes(ss));
  }

  weight: number | null = null; // Input for weight in kg
  height: number | null = null; // Input for height in cm
  bmi: number | null = null; // Calculated BMI
  bmiCategory: string = ''; // BMI category

  // Method to calculate BMI
  calculateBMI(): void {
    if (this.weight && this.height) {
      const heightInMeters = this.height / 100; // Convert cm to meters
      this.bmi = this.weight / (heightInMeters * heightInMeters);
      this.BMI.setValue(this.bmi);
      // Determine BMI category
      if (this.bmi < 18.5) {
        this.bmiCategory = 'Underweight';
      } else if (this.bmi >= 18.5 && this.bmi < 24.9) {
        this.bmiCategory = 'Normal weight';
      } else if (this.bmi >= 25 && this.bmi < 29.9) {
        this.bmiCategory = 'Overweight';
      } else {
        this.bmiCategory = 'Obesity';
      }
    } else {
      alert('Please enter both weight and height!');
    }
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
      this.Signature.setValue(res.DoctorName);
    });
  }

  formSubmit() {
    let partofDay = this.medFrm.value['PartofDay'];
    let doctorName = this.medFrm.value['DoctorName'];
    let nurseName = this.medFrm.value['NurseName'];
    let drugName = this.medFrm.value['DrugName'];
    let dosage = this.medFrm.value['Dosage'];
    let brandName = this.medFrm.value['BrandName'];
    //    let date = this.medFrm.value['Date']
    // date=  moment(date).format('MM/DD/YYYY');

    let time = this.medFrm.value['Time'];
    let height = this.medFrm.value['Height'];
    let bMI = this.medFrm.value['BMI'];
    let weight = this.medFrm.value['Weight'];
    let Shortcut = this.medFrm.value['shortcut'];
    let respiration = this.medFrm.value['Respiration'];
    let temparature = this.medFrm.value['Temparature'];
    let heartBeat = this.medFrm.value['HeartBeat'];
    let bloodPressure = this.medFrm.value['BloodPressure'];
    let oxygenSaturation = this.medFrm.value['OxygenSaturation'];
    let comments = this.medFrm.value['Comments'];
    let signature = this.medFrm.value['Signature'];
    let doctorId = this.medFrm.value['DoctorId'];
    // let idoctorName = this.nurseList.filter(it => it.NurseId == doctorId)[0].NurseName;

    let body = {
      PatientId: this.id,
      DoctorId: this.doctorId,
      Comments: comments,
      DoctorName: this.doctor.DoctorName,
      Signature: signature,
      Respiration: respiration,
      Height: height,
      Weight: weight,
      BMI: bMI,
      Temparature: temparature,
      HeartBeat: heartBeat,
      BloodPressure: bloodPressure,
      OxygenSaturation: oxygenSaturation,
      Date: this.date,
    };
    this.nurseReportService.registerNurseReport(body).subscribe((res) => {
      this.isSuccess = true;
      this.medFrm.reset();
    });
  }
}
