<div class="container">
  <div class="row">
    <div class="col-lg regFrm mat-elevation-z8">
      <h1>Create Vitals Report</h1>
      <form (keydown.enter)="$event.preventDefault()" [formGroup]="medFrm" (ngSubmit)="formSubmit()"
        class="example-form">

        <!-- 
        <div class="row">
          <div class="col-lg">
            <mat-form-field class="example-full-width">
              <mat-label>Shortcut</mat-label>
              <input type="text" matInput formControlName="shortcut" [matAutocomplete]="auto6"> <mat-icon
                matSuffix>search</mat-icon>
              <mat-autocomplete #auto6="matAutocomplete">
                <mat-option *ngFor="let dos of filteredShortcut | async" [value]="dos">
                  {{ dos }}
                </mat-option>
              </mat-autocomplete>
              <mat-error>Please enter Report Name</mat-error>
            </mat-form-field>

          </div>
        </div> -->
        <div class="row">
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Height(cm)</mat-label>
              <input [(ngModel)]="height" type="text" formControlName="Height" matInput>
              <mat-error>Please enter Height</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Weight(kg)</mat-label>
              <input [(ngModel)]="weight" (input)="calculateBMI()" type="text" formControlName="Weight" matInput>
              <mat-error>Please enter Weight</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>BMI(kg)</mat-label>
              <input [value]="bmi ? bmi.toFixed(2) : ''" type="text" formControlName="BMI" matInput>
              <mat-error>Please enter BMI</mat-error>

            </mat-form-field>

          </div>
        </div>
        <div class="col-lg-12 mt-3" *ngIf="bmi">
          <p>Your BMI is <strong>{{ bmi.toFixed(2) }}</strong> and you are in the <strong>{{ bmiCategory
              }}</strong>
            category.</p>
        </div>

        <div class="row">

          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Respiration(breaths/min)</mat-label>
              <input type="text" formControlName="Respiration" matInput>
              <mat-error>Please enter Respiration</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-4">
            <mat-form-field class="example-full-width">
              <mat-label>Temparature(°C or °F)</mat-label>
              <input type="text" matInput formControlName="Temparature">
              <mat-error>Please enter Respiration</mat-error>

            </mat-form-field>
          </div>
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label>Heart Beat(bpm) </mat-label>
              <input type="text" matInput formControlName="HeartBeat">
              <mat-error>Please enter Heart Beat</mat-error>

            </mat-form-field>

          </div>

        </div>
        <div class="row">
          <div class="col-lg-6">

            <mat-form-field class="example-full-width">
              <mat-label>Blood Pressure (Systolic/Diastolic )(mmHg)</mat-label>
              <input type="text" matInput formControlName="BloodPressure">
              <mat-error>Please enter Blood Pressure</mat-error>

            </mat-form-field>

          </div>
          <div class="col-lg-6">

            <mat-form-field class="example-full-width">
              <mat-label>Oxygen Saturation (%)</mat-label>
              <input type="text" matInput formControlName="OxygenSaturation">
              <mat-error>Please enter Oxygen Saturation</mat-error>

            </mat-form-field>

          </div>
        </div>
        <div class="row">

          <!-- 
          <div class="col-lg-4">

            <mat-form-field class="example-full-width">
              <mat-label> Time </mat-label>
              <input [ngxTimepicker]="picker" matInput formControlName="Time">
              <ngx-material-timepicker #picker></ngx-material-timepicker>
              <mat-error>Please enter Time</mat-error>
            </mat-form-field>

          </div> -->
          <div class="col-lg-12">
            <mat-form-field appearance="fill" class="example-full-width">
              <mat-label>Additional Notes/Comments </mat-label>
              <!-- <mat-icon matPrefix>event</mat-icon> -->
              <!-- <input matInput type="text" class="txt_height" formControlName="TodoText"> -->
              <textarea matInput formControlName="Comments" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1" cdkAutosizeMaxRows="100"></textarea>


              <mat-error *ngIf="Comments.invalid && Comments.touched">Please enter Comment's</mat-error>
            </mat-form-field>
            <!-- <mat-form-field class="example-full-width">
                  <mat-label>Comments</mat-label>
                  <input type="text" matInput formControlName="Comments" >
                </mat-form-field> -->
          </div>







        </div>


        <div class="row">


          <div class="col-lg-6">
            <h4>Nurse: {{nurse?.NurseName}}</h4>
            <!-- <mat-form-field class="example-full-width">
              <mat-label>Nurse Name</mat-label>
              <input type="text" matInput formControlName="NurseName">
              <mat-error>Please enter Nurse Name</mat-error>

            </mat-form-field> -->

          </div>

          <div class="col-lg-6">
            <mat-form-field class="example-full-width">
              <mat-label>Signature/Initial</mat-label>
              <input type="text" matInput formControlName="Signature">
              <mat-error>Signature required</mat-error>
            </mat-form-field>
            <p>Date:{{date | date: 'dd/MM/yyyy hh:mm a'}} </p>
          </div>
        </div>











        <button class="float-right lgBtCl" mat-button>
          <mat-icon>save</mat-icon>
          Sign & Save</button>

        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
          Successfully Generated Report
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </form>

    </div>
  </div>

</div>