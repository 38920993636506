import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GeneralsafteyService } from 'src/app/services/generalsaftey.service';
import { NurseService } from 'src/app/services/nurse.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-safety-management',
  templateUrl: './update-safety-management.component.html',
  styleUrls: ['./update-safety-management.component.css'],
})
export class UpdateSafetyManagementComponent implements OnInit {
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  id = 0;

  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private generalSfteyService: GeneralsafteyService,
    private router: Router,
    private nurseService: NurseService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);
    this.generalSfteyService.getGeneralSafteyById(this.id).subscribe((res) => {
      this.Frm.patchValue(res);
    });

    this.Frm = this.fb.group({
      NurseId: this.nurseId,
      CallBellWithinReach: [false],
      PatientIdentificationBandOn: [false],
      HeadOfBedElevation: [''],
      IVpump: [false],
      FeedingPump: [false],
      PcaPump: [false],
      EpiduralPump: [false],
      BedsideMonitor: [false],
      HypoThermiaBlanket: [false],
      UrinaryCatheterTempratureProbe: [false],
      OtherAlarmsSetandAudible: [''],
      Standard: [false],
      Contact: [false],
      Airborne: [false],
      Droplet: [false],
      SpecialContact: [false],
      Aspiration: [false],
      FallPrevention: [false],
      SuicideRisk: [false],
      AssualtRisk: [false],
      SelfHarmRisk: [false],
      ElopmentRisk: [false],
      OsaRisk: [false],
      Letex: [false],
      Seizure: [false],
      Bleeding: [false],
      Chemotherapy: [false],
      Neutropenic: [false],
      NoBpOrIvonRightArm: [false],
      NoBpOrIvonLeftArm: [false],
      WeightBearing: [false],
      SpinalPrecautions: [false],
      SternalPrecautions: [false],
      AnteriorHipPrecautions: [false],
      PosteriorHipPrecautions: [false],
      RemoveHarmfulObjectsFromImmedietArea: [false],
      NonSpecific: [false],
      SafteyTray: [false],
      FluidResrtiction: [false],
      CalerieCount: [false],
      LiquidOrSolidConsistency: [false],
      SwallowingStrategies: [false],
      CaffeineRestriction: [false],
      Normal: [false],
      L3LquidizedDiet: [false],
      L0ThinLiquids: [false],
      L1SlightlyThickLiquids: [false],
      L2MildlyThickLiquids: [false],
      L3ModeratelyThickLiquids: [false],
      L4Pureed: [false],
      L5MincedandMoist: [false],
      L6SoftandBiteSizePiece: [false],
      L7EasyToChew: [false],
      NoSwallowingStrategyNecessary: [false],
      AlternateLiquidsandSolids: [false],
      ChinTuckWitLiquids: [false],
      FrequesntCoughReswallow: [false],
      NoStrawsSmallSipsbyCup: [false],
      SmallSipsbySpoon: [false],
      NoLiquid: [false],
      MedSurgeObservation: [false],
      VideoMonitoringMedSurgeObservation: [false],
      OnetoOneMedSurgeObservation: [false],
      Every5MinutesPsychObservation: [false],
      Every15MinutesPsychObservation: [false],
      Every30MinutesPsychObservation: [false],
      OnetoOnePsychObservation: [false],
      ContinuousPsychObservation: [false],
      SupportivePsychObservation: [false],
      FrequentMedSurgeObservation: [false],
      Suppliments: [false],
    });
  }

  get PatientId() {
    return this.Frm.get('PatientId');
  }
  get NurseId() {
    return this.Frm.get('NurseId');
  }
  get CallBellWithinReach() {
    return this.Frm.get('CallBellWithinReach');
  }
  get PatientIdentificationBandOn() {
    return this.Frm.get('PatientIdentificationBandOn');
  }
  get HeadOfBedElevation() {
    return this.Frm.get('HeadOfBedElevation');
  }
  //Alarm Set and Audible
  get IVpump() {
    return this.Frm.get('IVpump');
  }
  get FeedingPump() {
    return this.Frm.get('FeedingPump');
  }
  get PcaPump() {
    return this.Frm.get('PcaPump');
  }
  get EpiduralPump() {
    return this.Frm.get('EpiduralPump');
  }
  get BedsideMonitor() {
    return this.Frm.get('BedsideMonitor');
  }
  get HypoThermiaBlanket() {
    return this.Frm.get('HypoThermiaBlanket');
  }
  get UrinaryCatheterTempratureProbe() {
    return this.Frm.get('UrinaryCatheterTempratureProbe');
  }
  get OtherAlarmsSetandAudible() {
    return this.Frm.get('OtherAlarmsSetandAudible');
  }
  //Precautions
  get Standard() {
    return this.Frm.get('Standard');
  }
  get Contact() {
    return this.Frm.get('Contact');
  }
  get Airborne() {
    return this.Frm.get('Airborne');
  }
  get Droplet() {
    return this.Frm.get('Droplet');
  }
  get SpecialContact() {
    return this.Frm.get('SpecialContact');
  }
  get Aspiration() {
    return this.Frm.get('Aspiration');
  }
  get FallPrevention() {
    return this.Frm.get('FallPrevention');
  }
  get SuicideRisk() {
    return this.Frm.get('SuicideRisk');
  }
  get AssualtRisk() {
    return this.Frm.get('AssualtRisk');
  }
  get SelfHarmRisk() {
    return this.Frm.get('SelfHarmRisk');
  }
  get ElopmentRisk() {
    return this.Frm.get('ElopmentRisk');
  }
  get OsaRisk() {
    return this.Frm.get('OsaRisk');
  }
  get Letex() {
    return this.Frm.get('Letex');
  }
  get Seizure() {
    return this.Frm.get('Seizure');
  }
  get Bleeding() {
    return this.Frm.get('Bleeding');
  }
  get Chemotherapy() {
    return this.Frm.get('Chemotherapy');
  }
  get Neutropenic() {
    return this.Frm.get('Neutropenic');
  }
  get NoBpOrIvonRightArm() {
    return this.Frm.get('NoBpOrIvonRightArm');
  }
  get NoBpOrIvonLeftArm() {
    return this.Frm.get('NoBpOrIvonLeftArm');
  }
  get WeightBearing() {
    return this.Frm.get('WeightBearing');
  }
  get SpinalPrecautions() {
    return this.Frm.get('SpinalPrecautions');
  }
  get SternalPrecautions() {
    return this.Frm.get('SternalPrecautions');
  }
  get AnteriorHipPrecautions() {
    return this.Frm.get('AnteriorHipPrecautions');
  }
  get PosteriorHipPrecautions() {
    return this.Frm.get('PosteriorHipPrecautions');
  }
  get RemoveHarmfulObjectsFromImmedietArea() {
    return this.Frm.get('RemoveHarmfulObjectsFromImmedietArea');
  }
  //Dietery
  //  DietaryConsideration
  get NonSpecific() {
    return this.Frm.get('NonSpecific');
  }
  get SafteyTray() {
    return this.Frm.get('SafteyTray');
  }
  get FluidResrtiction() {
    return this.Frm.get('FluidResrtiction');
  }
  get CalerieCount() {
    return this.Frm.get('CalerieCount');
  }
  get LiquidOrSolidConsistency() {
    return this.Frm.get('LiquidOrSolidConsistency');
  }
  get SwallowingStrategies() {
    return this.Frm.get('SwallowingStrategies');
  }
  get CaffeineRestriction() {
    return this.Frm.get('CaffeineRestriction');
  }
  get Suppliments() {
    return this.Frm.get('Suppliments');
  }
  //liquid/solid consistencies
  get Normal() {
    return this.Frm.get('Normal');
  }
  get L3LquidizedDiet() {
    return this.Frm.get('L3LquidizedDiet');
  }
  get L0ThinLiquids() {
    return this.Frm.get('L0ThinLiquids');
  }
  get L1SlightlyThikLiquids() {
    return this.Frm.get('L1SlightlyThickLiquids');
  }
  get L2MildlyThickLiquids() {
    return this.Frm.get('L2MildlyThickLiquids');
  }
  get L3ModeratelyThickLiquids() {
    return this.Frm.get('L3ModeratelyThickLiquids');
  }
  get L4Pureed() {
    return this.Frm.get('L4Pureed');
  }
  get L5MincedandMoist() {
    return this.Frm.get('L5MincedandMoist');
  }
  get L6SoftandBiteSizePiece() {
    return this.Frm.get('L6SoftandBiteSizePiece');
  }
  get L7EasyToChew() {
    return this.Frm.get('L7EasyToChew');
  }
  get NoSwallowingStrategyNecessary() {
    return this.Frm.get('NoSwallowingStrategyNecessary');
  }
  get AlternateLiquidsandSolids() {
    return this.Frm.get('AlternateLiquidsandSolids');
  }
  get ChinTuckWitLiquids() {
    return this.Frm.get('ChinTuckWitLiquids');
  }
  get FrequesntCoughReswallow() {
    return this.Frm.get('FrequesntCoughReswallow');
  }
  get NoStrawsSmallSipsbyCup() {
    return this.Frm.get('NoStrawsSmallSipsbyCup');
  }
  get SmallSipsbySpoon() {
    return this.Frm.get('SmallSipsbySpoon');
  }
  get NoLiquid() {
    return this.Frm.get('NoLiquid');
  }
  //OBSERVATIONS
  //MedSurgeObservation
  get FrequentMedSurgeObservation() {
    return this.Frm.get('FrequentMedSurgeObservation');
  }
  get MedSurgeObservation() {
    return this.Frm.get('MedSurgeObservation');
  }
  get VideoMonitoringMedSurgeObservation() {
    return this.Frm.get('VideoMonitoringMedSurgeObservation');
  }
  get OnetoOneMedSurgeObservation() {
    return this.Frm.get('OnetoOneMedSurgeObservation');
  }

  //Psych/Detox Unit Observation Status
  get Every5MinutesPsychObservation() {
    return this.Frm.get('Every5MinutesPsychObservation');
  }
  get Every15MinutesPsychObservation() {
    return this.Frm.get('Every15MinutesPsychObservation');
  }
  get Every30MinutesPsychObservation() {
    return this.Frm.get('Every30MinutesPsychObservation');
  }
  get OnetoOnePsychObservation() {
    return this.Frm.get('OnetoOnePsychObservation');
  }
  get ContinuousPsychObservation() {
    return this.Frm.get('ContinuousPsychObservation');
  }
  get SupportivePsychObservation() {
    return this.Frm.get('SupportivePsychObservation');
  }

  formSubmit() {
    this.generalSfteyService
      .updateGeneralSaftey(this.id, this.Frm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.router.navigate(['nurseDash']);
      });
  }
}
