import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-tab2-clinic-side',
  templateUrl: './tab2-clinic-side.component.html',
  styleUrls: ['./tab2-clinic-side.component.css'],
})
export class Tab2ClinicSideComponent implements OnInit {
  @Input() tabsArray: string[] = [];
  @Output() onTabChange = new EventEmitter<number>();
  activatedTab: number = 0;

  constructor(private tokenService: TokenService) {}

  ngOnInit(): void {}
  setTab(index: number) {
    this.activatedTab = index;
    // debugger;
    this.onTabChange.emit(this.activatedTab);
  }
}
