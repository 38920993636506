import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { PatientService } from '../../services/patient.service';
import { Patient } from '../../Models/patient.model';
import { DepartmentService } from '../../services/department.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Department } from '../../Models/department.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../services/user.service';
import { NurseDailyPatient } from 'src/app/Models/nursedailypatient.model';
import { NursedailypatientsService } from 'src/app/services/nursedailypatients.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog-component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Ward } from 'src/app/Models/ward.model';
import { WardService } from 'src/app/services/ward.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { AddAppointmentHospitalComponent } from '../add-appointment-hospital/add-appointment-hospital.component';
// import { error } from '@angular/compiler/src/util';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-viewpatient',
  templateUrl: './viewpatient.component.html',
  styleUrls: ['./viewpatient.component.css'],
})
export class ViewpatientComponent implements OnInit {
  @Input() isNurse: boolean;
  @Output() selectedPatientIdsEmitter = new EventEmitter<any>();
  @Output() navigation = new EventEmitter<any>();
  selectedPatientIds: string[] = [];
  nursePatient: NurseDailyPatient[] = [];
  displayedColumns: string[] = [
    'FirstName',
    'LastName',
    'Gender',
    'PatientNationalId',
    'Email',
    'PhoneNumber',
    'PrimaryDoctor',
    'DoctorName',
    'WardName',
    'Appointment',
    'View',
    'Edit',
    'Delete',
  ];
  id = 0;
  patientList: Patient[] = [];
  filteredList;
  dptList: Department;

  dataSource: MatTableDataSource<Patient>;

  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  patientData;
  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;
  wardList: Ward[];
  constructor(
    private patientService: PatientService,
    private dptService: DepartmentService,
    private route: ActivatedRoute,
    private userService: UserService,
    private nursePatientService: NursedailypatientsService,
    private dialog: MatDialog,
    private router: Router,
    private wardService: WardService,
    private fb: FormBuilder,
    private hosptalService: HospitalService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    // this.route.params.subscribe((res) => {
    //   this.id = res['id'];
    // });
    this.getWard();
    // this.getDptPatients(this.id)
    this.Frm = this.fb.group({
      WardId: [''],
    });
    this.getPatients(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  get WardId() {
    return this.Frm.get('WardId');
  }
  getWard() {
    this.wardService.getWards().subscribe((res) => {
      this.wardList = res;
    });
  }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatients(id) {
    // this.wardService.getWardPatients(this.WardId.value).subscribe((res) => {
    //  this.filteredList= res
    this.hosptalService.getPatients(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.Patients);
      this.dataSource.paginator = this.paginator;
      // this.patientList=res;
    });
    if (this.isNurse) {
      this.displayedColumns.splice(0, 0, 'select', 'Id');
      this.displayedColumns = this.displayedColumns.filter((column, id) => {
        if (column === 'Edit' || column === 'Delete') {
          return;
        }
        return column;
      });
    }
  }

  deletePatient(id) {
    this.patientService.deletePatient(id).subscribe((res) => {
      this.userService.deleteUser(id).subscribe(
        (res) => {
          this.getPatients(this.id);
        },
        (error) => console.log(error)
      );
    });
  }

  selectPatients(event, patient) {
    this.patientData = {
      PatientId: patient.PatientId,
      PatientFirstName: patient.PatientFirstName,
      PatientLastName: patient.PatientLastName,
      PatientDOB: patient.PatientDOB,
      PatientNationalId: patient.PatientNationalId,
      PatientGender: patient.PatientGender,
      Email: patient.Email,
      PhoneNumber: patient.PhoneNumber,
      PrimaryDoctorsName: patient.PrimaryDoctorsName,
      WardName: patient.WardName,
      NurseId: this.nurseId,
    };
    let index = this.patientList?.findIndex(
      (patient) => patient?.PatientId === this.patientData?.PatientId
    );
    if (event.checked) {
      if (index > -1) {
        this.patientList[index] = this.patientData;
      } else {
        this.patientList.push(this.patientData);
      }
    } else if (index > -1) {
      this.patientList.splice(index, 1);
    }
  }

  emitSelectedIds() {
    this.selectedPatientIdsEmitter.emit(this.nursePatient);
  }

  selectedPatient() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Assign Patient to me',
        content:
          'Are you sure you want to assign selected patient to your Work Space?',
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res === true) {
          this.patientList.forEach((patient) => {
            this.nursePatientService
              .registerNurseDailyPatient(patient)
              .subscribe((res) => {
                this.isSuccess = true;
              });
          });
          setTimeout(() => {
            this.navigation.emit();
          }, 2000);
        }
      },
    });
  }
  dialogRefs: MatDialogRef<any>[] = [];
  openAddAppointmentModal(userId, firstName: string, lastName: string): void {
    const dialogRef = this.dialog.open(AddAppointmentHospitalComponent, {
      width: '680px', // You can set the width or other configurations here
      data: { userId: userId, userFname: firstName, userLname: lastName },
      disableClose: true,
      hasBackdrop: false,
    });

    this.dialogRefs.push(dialogRef); // Keep track of the opened dialogs

    dialogRef.afterClosed().subscribe(() => {
      // Remove from the dialogRefs list once closed
      this.dialogRefs = this.dialogRefs.filter((ref) => ref !== dialogRef);
    });
  }

  closeAllDialogs(): void {
    this.dialogRefs.forEach((ref) => ref.close());
    this.dialogRefs = [];
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
