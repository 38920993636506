import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PainChartService } from 'src/app/services/pain-chart.service';
import { PatientService } from 'src/app/services/patient.service';
import { environment } from 'src/environments/environment';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-pain-chart',
  templateUrl: './add-pain-chart.component.html',
  styleUrls: ['./add-pain-chart.component.css'],
})
export class AddPainChartComponent implements OnInit {
  @ViewChild('painCanvas', { static: true })
  painCanvas!: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D;
  imageUrl: string;
  id = 0;
  isSuccess: boolean;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private paichartService: PainChartService
  , private tokenService: TokenService) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    console.log(this.id);
    this.initializeCanvas();
  }

  initializeCanvas(): void {
    const canvas = this.painCanvas.nativeElement;
    this.ctx = canvas.getContext('2d')!;
    const img = new Image();
    img.src = 'assets/PainLocation.png'; // Path to your preloaded image
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      this.ctx.drawImage(img, 0, 0);
    };
  }

  markPainLocation(event: MouseEvent): void {
    const canvas = this.painCanvas.nativeElement;
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left; // X coordinate relative to the canvas
    const y = event.clientY - rect.top; // Y coordinate relative to the canvas

    // Draw a red dot at the clicked position
    this.ctx.beginPath();
    this.ctx.arc(x, y, 5, 0, 2 * Math.PI); // Circle with a radius of 5px
    this.ctx.fillStyle = 'red';
    this.ctx.fill();
  }

  saveImage(): void {
    const canvas = this.painCanvas.nativeElement;
    const dataUrl = canvas.toDataURL('image/png');
    const formData = new FormData();

    // Convert the canvas data URL to a Blob
    fetch(dataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        // Generate a unique file name using the current timestamp
        const uniqueFileName = `painChart_${this.id}_${Date.now()}.png`;
        const file = new File([blob], uniqueFileName, { type: 'image/png' });

        // Add the file to FormData
        formData.append('file', file, file.name);

        // Upload the image via the PatientService
        this.patientService.imageUpload(formData).subscribe(
          (res) => {
            // Construct the image URL
            const storedImageUrl = environment.imgurl + res['path'];
            this.imageUrl = storedImageUrl;

            // Prepare the body for saving pain chart data
            const body = {
              PatientId: this.id,
              PainChartImage: this.imageUrl,
            };

            // Save the pain chart data via PainChartService
            this.paichartService.registerPainChart(body).subscribe(
              () => {
                this.isSuccess = true;
                alert('Marked image saved successfully!');
              },
              (err) => {
                console.error('Error saving pain chart:', err);
              }
            );
          },
          (err) => {
            console.error('Error uploading image:', err);
          }
        );
      })
      .catch((err) => {
        console.error('Error converting canvas to Blob:', err);
      });
  }
}
