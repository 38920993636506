import { Component } from '@angular/core';

@Component({
  selector: 'app-add-nurse-progress-note',
  templateUrl: './add-nurse-progress-note.component.html',
  styleUrls: ['./add-nurse-progress-note.component.css']
})
export class AddNurseProgressNoteComponent {

}
