import { Component } from '@angular/core';

@Component({
  selector: 'app-view-favorite-dignosis-code',
  templateUrl: './view-favorite-dignosis-code.component.html',
  styleUrls: ['./view-favorite-dignosis-code.component.css']
})
export class ViewFavoriteDignosisCodeComponent {

}
