import { PrescriptionService } from './../../services/prescription.service';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MedicineService } from '../../services/medicine.service';
import { Medicine } from 'src/app/Models/medicine.model';
import * as moment from 'moment';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { PatientService } from 'src/app/services/patient.service';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { Allergy } from 'src/app/Models/allergy.models';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { TwofaComponent } from '../twofa/twofa.component';
import { TwofaDeaComponent } from '../twofa-dea/twofa-dea.component';
import { HttpClient } from '@angular/common/http';
import { Prescription } from 'src/app/Models/prescription.model';

import { TokenService } from 'src/app/services/token.service';
import { FavoritePrescriptionService } from 'src/app/services/favorite-prescription.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Component({
  selector: 'app-add-prescription',
  templateUrl: './add-prescription.component.html',
  styleUrls: ['./add-prescription.component.css'],
})
export class AddPrescriptionComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  prescriptionForm: FormGroup;
  favoritePrescriptions: any[] = []; // Loaded from the backend
  selectedFavorites: any[] = []; // For storing selected favorites
  // medicineList: Medicine[];
  medicineList: any[] = [];
  filteredExt: Observable<string[]>;
  filteredOptions: Observable<string[]>;
  filteredNoOfrefills: Observable<string[]>;
  daRequiredDrugs: string[] = [];
  doctor: Doctor;
  pharmacy: PatientsPharmacy[];
  private readonly storageKey = 'drugNames'; // Key for local storage
  Routeoptions: string[] = [
    'Oral',
    'Topical',
    'Intracerebroventricular',
    'Intramuscular',
    'Intragastric',
    'Intraperitoneal',
    'Intradermal',
    'Intracerebral',
    'Intra-arterial',
    'Intrathecal',
    'Intravenous',
    'Subcutaneous',
  ];
  frequencyList: string[] = [
    'QD (quaque die) - Once a day',
    'BID (bis in die) - Twice a day',
    'TID (ter in die) - Three times a day',
    'QID (quater in die) - Four times a day',
    'QHS (quaque hora somni) - Every night at bedtime',
    'Q4H (quaque 4 hora) - Every 4 hours',
    'Q6H (quaque 6 hora) - Every 6 hours',
    'Q8H (quaque 8 hora) - Every 8 hours',
    'Q12H (quaque 12 hora) - Every 12 hours',
    'PRN (pro re nata) - As needed',
  ];

  refillDurationList: string[] = [
    '30 days',
    '60 days',
    '90 days',
    '6 months',
    '1 year',
  ];
  NumberOfRefillsList: string[] = ['1', '2', '3', '4', '5', '11'];
  dosageUnitOptions: string[] = [
    'Tablet',
    'Capsules',
    'Ointment',
    'Applications',
    'Liquid',
    'Solution',
  ];
  quantityUnitOptions: string[] = [
    'Tablet',
    'Capsules',
    'Applications',
    'Liquid',
    'Solution',
  ];
  amountPerDoseOptions: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  filteredDrugOptions: Observable<string[]>[] = [];
  drugOptions: string[] = [
    'Aspirin',
    'Paracetamol',
    'Ibuprofen',
    'Amoxicillin',
    'Ciprofloxacin',
  ]; // Example list
  drugExtention: string[] = [
    'Tablet',
    'Capsules',
    'ML',
    'Applications',
    // 'suspensions',
    // 'solutions',
    // 'colloids',
  ];
  public filteredMedicineList: any[] = [];
  filteredMedicineLists: any[][] = [];
  isSuccess: boolean;
  ext: any;
  allergies: Allergy[];
  isDAvalidated: boolean = false;
  netWorkFailure: any;
  error: boolean;
  IsthrowError: string;
  logiForm: any;
  daApprovalResult: string = '';
  daCode: any; // The DA code entered by the user
  isDARequired: boolean = false; // Flag to track if DA approval is required
  daValidationMessage: string = ''; // DA validation message
  medList: any;
  MedicineName = false;
  isFavorite: boolean = false;
  constructor(
    private fb: FormBuilder,
    private medicineService: MedicineService,
    private patientService: PatientService,
    private doctorService: DoctorService,
    private authService: AuthService,
    private dialog: MatDialog,
    private http: HttpClient,
    private route: ActivatedRoute,
    private prescriptionService: PrescriptionService,
    private favoritePrescriptionService: FavoritePrescriptionService,
    private tokenService: TokenService,
    private localStorageService: LocalStorageService
  ) {
    this.prescriptionForm = this.fb.group({
      prescriptions: this.fb.array([this.createPrescription()]),
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);

    this.getMedicine();
    this.loadFavorites(this.doctorId);

    this.getAllergies(this.id);

    this.controlledDrugs();

    this.getPharmacy(this.id);

    this.doctorService.getDoctorById(this.doctorId).subscribe((res) => {
      this.doctor = res;
    });

    this.initDrugAutocomplete();

    this.filteredExt = this.prescriptions
      .at(0)
      .get('Unit')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter7(value || ''))
      );

    this.filteredOptions = this.prescriptions
      .at(0)
      .get('Route')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || ''))
      );
    this.filteredNoOfrefills = this.prescriptions
      .at(0)
      .get('NumberOfRefills')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter1(value || ''))
      );
  }

  createPrescription(): FormGroup {
    return this.fb.group({
      DrugName: ['', Validators.required],
      AmountPerDose: [2, Validators.required],
      Unit: ['Tablet'],
      Quantity: [{ value: null }],
      Route: ['Oral', Validators.required],
      Frequency: ['', Validators.required],
      RefillDuration: ['30 days', Validators.required],
      NumberOfRefills: [1, [Validators.required, Validators.min(1)]],
      StartDate: [new Date().toISOString().split('T')[0], Validators.required],
      EndDate: [{ value: null }],
      Instruction: ['', [Validators.maxLength(500)]],
      Note: ['', [Validators.maxLength(500)]],
    });
  }

  get prescriptions(): FormArray {
    return this.prescriptionForm.get('prescriptions') as FormArray;
  }

  addPrescription(): void {
    this.prescriptions.push(this.createPrescription());
    this.filteredMedicineList.push([...this.medicineList]);
    this.initDrugAutocomplete();
  }

  removePrescription(index: number): void {
    this.prescriptions.removeAt(index);
    this.filteredDrugOptions.splice(index, 1);
  }
  initDrugAutocomplete(): void {
    this.filteredDrugOptions = []; // Reset before setting new ones
    this.prescriptions.controls.forEach((control, i) => {
      this.filteredDrugOptions[i] = control.get('DrugName')!.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterDrugOptions(value || ''))
      );
    });
  }

  private filterDrugOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.drugOptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  private _filter7(value: string): string[] {
    const fext = value.toLowerCase();

    return this.drugExtention.filter((aa) => aa.toLowerCase().includes(fext));
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Routeoptions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  private _filter1(value: string): string[] {
    const filterValue1 = value.toLowerCase();

    return this.NumberOfRefillsList.filter((pt) =>
      pt.toLowerCase().includes(filterValue1)
    );
  }
  getExtValue(e) {
    this.ext = e;

    this.prescriptions.at(0).get('Unit').setValue(e);
  }
  calculateQuantity(index: number): void {
    const prescription = this.prescriptions.at(index);
    const amountPerDose = prescription.get('AmountPerDose')?.value || 1;
    const frequency = prescription.get('Frequency')?.value || '';
    const refillDuration = this.convertRefillDurationToDays(
      prescription.get('RefillDuration')?.value || '30 days'
    );
    const numberOfRefills = +prescription.get('NumberOfRefills')?.value || 1;

    let dosesPerDay = 0;
    if (frequency.includes('Once a day')) dosesPerDay = 1;
    else if (frequency.includes('Twice a day')) dosesPerDay = 2;
    else if (frequency.includes('Three times a day')) dosesPerDay = 3;
    else if (frequency.includes('Four times a day')) dosesPerDay = 4;

    const totalDays = refillDuration * numberOfRefills;
    const totalQuantity = totalDays * dosesPerDay * amountPerDose;

    prescription.get('Quantity')?.setValue(totalQuantity || 0);
  }

  convertRefillDurationToDays(refillDuration: string): number {
    const durationMapping = {
      '30 days': 30,
      '60 days': 60,
      '90 days': 90,
      '6 months': 180, // approx. 6 months
      '1 year': 365,
    };
    return durationMapping[refillDuration] || 30;
  }

  calculateEndDate(index: number): void {
    const prescription = this.prescriptions.at(index);
    const refillDuration = this.convertRefillDurationToDays(
      prescription.get('RefillDuration')?.value || '30 days'
    );

    const numberOfRefills = prescription.get('NumberOfRefills')?.value || 1;

    const totalDays = refillDuration * numberOfRefills;
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + totalDays);

    prescription.get('EndDate')?.setValue(endDate.toISOString().split('T')[0]);
  }

  loadFavorites(id) {
    this.doctorService.getDoctorFavoritePrescriptions(id).subscribe((res) => {
      this.favoritePrescriptions = res.FavoritePrescriptions;
    });
  }

  // Handle favorite selection
  onFavoriteSelect(favorite: any, event: any) {
    if (event.checked) {
      this.selectedFavorites.push(favorite);
    } else {
      this.selectedFavorites = this.selectedFavorites.filter(
        (item) =>
          item.FavoritePrescriptionId !== favorite.FavoritePrescriptionId
      );
    }
  }
  addSelectedFavorites(tabGroup: MatTabGroup): void {
    if (this.selectedFavorites.length > 0) {
      const prescriptionsArray = this.prescriptionForm.get(
        'prescriptions'
      ) as FormArray;

      this.selectedFavorites.forEach((favorite, index) => {
        if (index === 0 && prescriptionsArray.length > 0) {
          // Patch the first prescription if it exists
          const firstPrescription = prescriptionsArray.at(0);
          firstPrescription.patchValue({
            DrugName: favorite.DrugName,
            AmountPerDose: favorite.AmountPerDose,
            Unit: favorite.Unit,
            Quantity: favorite.Quantity,
            Route: favorite.Route,
            Frequency: favorite.Frequency,
            RefillDuration: favorite.RefillDuration,
            NumberOfRefills: favorite.NumberOfRefills,
            StartDate: favorite.StartDate,
            EndDate: favorite.EndDate,
            Instruction: favorite.Instruction,
            Note: favorite.Note,
          });

          // Add missing AmountPerDose to options dynamically
          if (!this.amountPerDoseOptions.includes(favorite.AmountPerDose)) {
            this.amountPerDoseOptions.push(favorite.AmountPerDose);
          }
        } else {
          // Add a new prescription group for other favorites
          const prescriptionGroup = this.fb.group({
            DrugName: [favorite.DrugName],
            AmountPerDose: [favorite.AmountPerDose],
            Unit: [favorite.Unit],
            Quantity: [favorite.Quantity],
            Route: [favorite.Route],
            Frequency: [favorite.Frequency],
            RefillDuration: [favorite.RefillDuration],
            NumberOfRefills: [favorite.NumberOfRefills],
            StartDate: [favorite.StartDate],
            EndDate: [favorite.EndDate],
            Instruction: [favorite.Instruction],
            Note: [favorite.Note],
          });

          prescriptionsArray.push(prescriptionGroup);
        }
      });

      // Clear the selected favorites
      this.selectedFavorites = [];

      // Switch back to the "Add Prescription" tab
      tabGroup.selectedIndex = 0;
    }
  }

  getAllergies(id) {
    this.patientService.getPatientAllergy(id).subscribe((res) => {
      this.allergies = res.Allergies;
    });
  }
  getMedicine() {
    const cachedMedicines = this.localStorageService.getItem<any[]>(
      this.storageKey
    );

    if (cachedMedicines && cachedMedicines.length > 0) {
      // Load from local storage
      this.medicineList = cachedMedicines;
      this.filteredMedicineList = [...this.medicineList];
    } else {
      // Fetch from server if not in local storage
      this.medicineService.getMedicines().subscribe((data: any[]) => {
        this.medicineList = data;
        this.filteredMedicineList = [...this.medicineList];

        // Cache the data in local storage
        this.localStorageService.setItem(this.storageKey, data);
      });
    }
  }
  onFilter(filtered: any[], index: number) {
    this.filteredMedicineLists[index] = filtered; // Update filtered list for the specific prescription
  }
  onDrugSearch(searchTerm: string): void {
    if (searchTerm.trim().length > 0) {
      // Call the API or filter the cached medicine list
      this.filteredMedicineList = this.medicineList.filter((drug) =>
        drug.DrugName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      // Reset to the full list if no input
      this.filteredMedicineList = [...this.medicineList];
    }
  }

  displayDrugName(drug: any): string {
    return drug ? drug.DrugName : '';
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  getPharmacy(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((res) => {
      this.pharmacy = res.PatientsPharmacys;
    });
  }
  public controlledDrugs() {
    this.http.get('/assets/json/ControlledSubstance.json').subscribe({
      next: (res: any) => {
        this.daRequiredDrugs = res.map((x) => x.SUBSTANCE);
        // console.log(this.daRequiredDrugs);
      },
    });
  }
  getSelectedDrug(selectedDrug: string): void {
    // Normalize selectedDrug by trimming spaces
    const normalizedSelectedDrug = selectedDrug.trim().toLowerCase();

    // Patient's allergies (assumed to be normalized in advance)
    const patientAllergies = this.allergies.map((x) =>
      x.AllergicTo.trim().toLowerCase()
    );

    // Function to extract the base drug name from the selectedDrug
    const extractBaseDrugName = (drug: string): string => {
      // Extract base drug name by removing strength, dosage, and units
      const baseName = drug
        .split(/\s+/)[0] // Take the first word (e.g., "DIGOXIN" from "DIGOXIN 0.05 MG/ML SOLUTION")
        .replace(/[^a-zA-Z]/g, '') // Remove any non-alphabetic characters
        .toLowerCase();
      return baseName;
    };

    // Extract the base name of the selected drug
    const baseDrugName = extractBaseDrugName(normalizedSelectedDrug);

    // Check if any of the patient's allergies contain the exact base drug name
    const isAllergic = patientAllergies.some(
      (allergy) => allergy === baseDrugName
    );

    // Update the result in the DOM
    const resultElement = document.getElementById('result');
    if (resultElement) {
      if (isAllergic) {
        resultElement.textContent = `Patient is allergic to ${selectedDrug}`;
        resultElement.style.color = 'red'; // Red for allergy
      } else {
        resultElement.textContent = 'Patient has no allergies to selected drug';
        resultElement.style.color = 'green'; // Green for no allergy
      }
    }

    this.isDARequired = this.daRequiredDrugs.some((drug) =>
      drug.toLowerCase().includes(baseDrugName)
    );
    // Set the DA approval result message
    if (this.isDARequired) {
      this.daApprovalResult = `DA approval is required for ${selectedDrug}`;
    } else {
      this.daApprovalResult = `${selectedDrug} does not require DA approval`;
    }
  }

  validateDACode() {
    event.preventDefault();
    const trimmedDACode = this.daCode.trim(); // Trim any leading/trailing whitespace
    // console.log('Entered DA code:', this.daCode);
    const today = new Date().toISOString();
    const DeaDateIsValid = this.doctor.DeaExpiryDate >= today;
    const doctorDeaCode = this.doctor.DeaCode;
    // Check if the DA code is empty
    if (trimmedDACode === '') {
      this.daValidationMessage = 'DA code cannot be empty.';
    }
    // Validate if the DA code is correct
    else if (trimmedDACode === doctorDeaCode && DeaDateIsValid) {
      // Example valid code
      this.daValidationMessage = 'DA code is valid.';
      this.isDAvalidated = true;
    } else {
      this.daValidationMessage = 'Invalid DA code.';
    }
  }

  loginForm = this.fb.group({
    UserName: ['', [Validators.required]],
    Password: ['', Validators.required],
  });

  get UserName() {
    return this.logiForm.get('UserName');
  }
  get Password() {
    return this.logiForm.get('Password');
  }

  loginSubmit() {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe(
        (res) => {
          window.localStorage.setItem('token', res['token']);

          let currentToken = res['token'];

          let decodedToken = this.helper.decodeToken(currentToken);
          this.id = parseInt(decodedToken.nameid);
          this.showTwoFactorDialog(decodedToken);
          this.isDAvalidated = false;

          // this.router.navigate(['twofa', decodedToken]);
        },
        (err) => {
          this.error = true;
          this.netWorkFailure = err;
        }
      );
    } else {
      this.IsthrowError =
        'Please review and check privacy policy to continue login';
    }
  }
  // Open 2FA dialog for entering the 2FA code
  showTwoFactorDialog(decodedToken: string) {
    const dialogRef = this.dialog.open(TwofaDeaComponent, {
      data: { decodedToken },
    });
  }
  saveAsFavorite(index: number): void {
    const prescription = this.prescriptions.at(index).value; // Get the specific prescription
    this.favoritePrescriptionService
      .registerFavoritePrescription({
        DrugName: prescription.DrugName,
        AmountPerDose: prescription.AmountPerDose,
        Unit: prescription.Unit,
        Quantity: prescription.Quantity,
        Route: prescription.Route,
        Frequency: prescription.Frequency,
        RefillDuration: prescription.RefillDuration,
        NumberOfRefills: prescription.NumberOfRefills,
        StartDate: prescription.StartDate,
        EndDate: prescription.EndDate,
        Instruction: prescription.Instruction,
        Note: prescription.Note,
        DoctorId: this.doctorId, // Add the logged-in doctor's ID
      })
      .subscribe(() => {
        this.isSuccess = true;
        // alert('Saved as favorite successfully!');
      });
  }
  deleteFavorite(id: number, index: number): void {
    if (
      confirm('Are you sure you want to delete this favorite prescription?')
    ) {
      this.favoritePrescriptionService
        .deleteFavoritePrescription(id)
        .subscribe(() => {
          alert('Favorite deleted successfully!');
          this.favoritePrescriptions.splice(index, 1); // Remove from local array
        });
    }
  }
  isEditing: boolean = false;
  editingIndex: number | null = null;

  editFavorite(favorite: any, index: number): void {
    this.isEditing = true;
    this.editingIndex = index;
  }

  saveEditFavorite(id: number, updatedFavorite: any): void {
    this.favoritePrescriptionService
      .updateFavoritePrescription(id, updatedFavorite)
      .subscribe(() => {
        alert('Favorite updated successfully!');
        this.isEditing = false;
        this.editingIndex = null;
        this.loadFavorites(this.doctorId); // Reload the list to reflect changes
      });
  }

  cancelEdit(): void {
    this.isEditing = false;
    this.editingIndex = null;
  }

  submitForm(): void {
    let prescriptions: Prescription[] = this.prescriptionForm.get(
      'prescriptions'
    )?.value as Prescription[];

    prescriptions[0].DoctorName = this.doctor.DoctorName;

    prescriptions.forEach((percep, index) => {
      percep.DoctorName = this.doctor.DoctorName;
      percep.NpiNumber = this.doctor.NpiNumber;
      percep.Status = true;
      percep.PatientId = this.id;
      percep.DoctorId = this.doctorId;

      percep.isDAvalidated = this.isDAvalidated;
    });
    this.prescriptionService
      .saveMultiplePrescriptions(prescriptions)
      .subscribe((res) => {
        // console.log(res);
        this.isSuccess = true;
        this.prescriptionForm.reset();
        // this.prescriptions.clear();
        //  alert('Form submited with success');
      });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.prescriptionForm;
    } else if (event.index === 1) {
      this.loadFavorites(this.doctorId);
    }
  }
}
//if using check box for to save Favorite Prescription
// savePrescription(): void {
//   const prescriptions = this.prescriptions.value;

//   prescriptions.forEach((prescription: any) => {
//     if (prescription.saveAsFavorite) {
//       this.favoritePrescriptionService.saveMultipleFavoritePrescriptions({
//         DrugName: prescription.DrugName,
//         AmountPerDose: prescription.AmountPerDose,
//         Unit: prescription.Unit,
//         Quantity: prescription.Quantity,
//         Route: prescription.Route,
//         Frequency: prescription.Frequency,
//         RefillDuration: prescription.RefillDuration,
//         NumberOfRefills: prescription.NumberOfRefills,
//         StartDate: prescription.StartDate,
//         EndDate: prescription.EndDate,
//         Instruction: prescription.Instruction,
//         Note: prescription.Note,
//         DoctorId: this.doctorId
//       }).subscribe(() => {
//         console.log('Prescription saved as favorite successfully!');
//       });
//     }
//   });

//   console.log('Prescriptions submitted:', prescriptions);
// }
