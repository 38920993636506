import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, pipe, Subject } from 'rxjs';
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  map,
  share,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorReport } from 'src/app/Models/doctorreport.model';
import { DoctorTemplate } from 'src/app/Models/doctortemplate.model';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorReportService } from 'src/app/services/doctor-report.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';
declare var webkitSpeechRecognition: any;
@Component({
  selector: 'app-update-doctor-report',
  templateUrl: './update-doctor-report.component.html',
  styleUrls: ['./update-doctor-report.component.css'],
})
export class UpdateDoctorReportComponent implements OnInit {
  @ViewChild('textarea', { static: false }) textarea!: ElementRef; // Access to the textarea
  private cursorPosition: number = 0; // Store cursor position
  // private recognition: any;
  displayedColumns: string[] = ['Name'];
  templates: DoctorTemplate[] = [];
  recognition = new webkitSpeechRecognition();
  isStoppedSpeechRecog = false;
  instantStarted = false;
  public message1 = '';
  tempWords;
  reportNames: string[] = [
    'Consult note',
    'Progress note',
    'Procedure note',
    'Office visit note',
    'Telephone Note',
  ];
  id = 0;
  reportList: DoctorReport[] = [];
  isSuccess = false;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');
  UserId: number = 0;
  doctor: Doctor;
  listening = false;
  patient: Patient;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: any;
  filteredReportName: Observable<string[]>;
  patientId: any;
  constructor(
    private fb: UntypedFormBuilder,
    private doctorReportService: DoctorReportService,
    private route: ActivatedRoute,
    public service: VoiceRecognitionService,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private router: Router
  ) {
    // Initialize Web Speech API
    // const SpeechRecognition =
    // (window as any).SpeechRecognition ||
    // (window as any).webkitSpeechRecognition;
    // this.recognition = new SpeechRecognition();
    // this.recognition.lang = 'en-US';
    // this.recognition.continuous = false;
    // this.recognition.interimResults = false;
    // Event handler for speech recognition result
    // this.recognition.onresult = (event: any) => {
    //   const transcript = event.results[0][0].transcript; // Recognized speech
    //   this.insertTextAtCursor(transcript); // Insert recognized text
    // };
  }
  ngOnInit(): void {
    this.recognition.interimResults = false;
    this.recognition.lang = 'en-US';

    this.recognition.addEventListener('result', (e) => {
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .filter((result) => result)
        .join('');
      // this.tempWords = transcript;
      this.insertTextAtCursor(transcript); // Insert recognized text
      // console.log(transcript);
    });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.UserId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
      this.patientId = res['PatientId'];
    });

    this.doctorReportService.getDoctorReportById(this.id).subscribe((res) => {
      this.Frm.patchValue(res);
    });
    this.UserId = parseInt(decodedToken.nameid);
    this.loadTemplates(this.UserId);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);
    this.getDoctor(this.UserId);

    this.filteredReportName = this.ReportName.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter5(value || ''))
    );

    if (window.close) {
      this.formSubmit();
    }
    this.Frm.valueChanges
      .pipe(debounceTime(1500), distinctUntilChanged())
      .subscribe((v) => this.formSubmit());
  }
  Frm = this.fb.group({
    ReportName: ['', Validators.required],
    ReportDescription: ['', Validators.required],
  });
  get ReportName() {
    return this.Frm.get('ReportName');
  }

  get ReportDescription() {
    return this.Frm.get('ReportDescription');
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  formSubmit() {
    let ReportName = this.Frm.value['ReportName'];
    let reportDescription = this.Frm.value['ReportDescription'];

    let body = {
      PatientId: this.patientId,
      DoctorId: this.UserId,
      ReportName: ReportName,
      ReportDescription: reportDescription,
      DoctorName: this.doctor.DoctorName,
      Status: true,
    };

    this.doctorReportService
      .updateDoctorReport(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;
        // this.Frm.reset();
        this.router.navigate(['/splitScreenDctorPatient/', this.patientId]);
      });
  }
  loadTemplates(id) {
    this.doctorService.getDoctorTemplates(id).subscribe((data) => {
      // console.log(data);
      this.templates = data.DoctorTemplates;
      this.dataSource = new MatTableDataSource(data.DoctorTemplates);
      this.dataSource.paginator = this.paginator;
    });
  }

  textBoxContent: string = '';
  // templates = this.templates;

  selectTemplate(templateContent: string) {
    this.textBoxContent = templateContent;
  }
  // This method is triggered on every keystroke or text area change
  onTextInput(event: any): void {
    const value = event.target.value;

    // Loop through templates and replace shortcut with the actual content
    this.templates.forEach((template) => {
      if (value.includes(template.shortcut)) {
        this.textBoxContent = value.replace(
          template.shortcut,
          template.Content
        );
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  delete(id) {
    this.doctorReportService.deleteDoctorReport(id).subscribe((res) => {
      this.dataSource(this.id);
    });
  }

  private _filter5(value: string): string[] {
    const ds = value.toLowerCase();

    return this.reportNames.filter((aa) => aa.toLowerCase().includes(ds));
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      // this.getReportName();
    }
  }
  // Start voice recognition
  startVoiceRecognition() {
    this.recognition.start();
  }
  // Start voice recognition
  stopVoiceRecognition() {
    this.recognition.stop();
  }

  start() {
    if (!this.instantStarted) {
      this.isStoppedSpeechRecog = false;
      this.recognition.start();
      this.instantStarted = true;
      // console.log('Speech recognition started');
      this.recognition.addEventListener('end', (condition) => {
        if (this.isStoppedSpeechRecog) {
          this.recognition.stop();
          // console.log('End speech recognition');
        } else {
          this.wordConcat();
          this.recognition.start();
        }
      });
    }
  }
  stop() {
    if (this.instantStarted) {
      // console.log('Speech recognition stopped');
      this.isStoppedSpeechRecog = true;
      this.instantStarted = false;
      this.wordConcat();
      this.recognition.stop();
    }
    // console.log('End speech recognition');
  }

  wordConcat() {
    this.textarea;
  }
  // Capture cursor position when the user interacts with the text area
  captureCursorPosition(event: any) {
    this.cursorPosition = event.target.selectionStart; // Get cursor position
  }

  // Insert recognized text at the captured cursor position
  insertTextAtCursor(text: string) {
    const textareaElement = this.textarea.nativeElement;

    const textBeforeCursor = textareaElement.value.substring(
      0,
      this.cursorPosition
    );
    const textAfterCursor = textareaElement.value.substring(
      this.cursorPosition
    );

    // Combine text before and after cursor with the recognized text
    const updatedText = textBeforeCursor + text + textAfterCursor;

    // Set the updated text directly into the textarea
    textareaElement.value = updatedText;

    // Update cursor position after the inserted text
    this.cursorPosition += text.length;

    // Reset the cursor to the correct position after inserting text
    textareaElement.selectionStart = textareaElement.selectionEnd =
      this.cursorPosition;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == KEY_CODE.DOWN_ARROW) {
      // Your row selection code
      // console.log(event);
      this.stop();
    }
  }
}
export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
}
