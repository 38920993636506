import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { Nurse } from 'src/app/Models/nurse.model';
import { Patient } from 'src/app/Models/patient.model';
import { ReviewOfSystem } from 'src/app/Models/reviewofsystem.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';
import { ReviewOfSystemService } from 'src/app/services/review-of-system.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-review-of-system',
  templateUrl: './add-review-of-system.component.html',
  styleUrls: ['./add-review-of-system.component.css'],
})
export class AddReviewOfSystemComponent implements OnInit {
  reviewOfSystemForm: FormGroup;
  submitted = false;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  id = 0;

  doctorId = 0;
  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;
  doctor: Doctor;
  patient: Patient;
  nurse: Nurse;
  constructor(
    private fb: FormBuilder,
    private reviewofSystemsService: ReviewOfSystemService,
    private doctorService: DoctorService,
    private nurseService: NurseService,
    private patientService: PatientService,
    private route: ActivatedRoute
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);

    this.getDoctor(this.doctorId);
    this.getPatient(this.id);

    this.reviewOfSystemForm = this.fb.group({
      Chills: [false],
      Fatigue: [false],
      Fever: [false],
      WeightGain: [false],
      WeightLoss: [false],
      HearingLoss: [false],
      SinusPressure: [false],
      VisualChanges: [false],
      Cough: [false],
      ShortnessOfBreath: [false],
      Wheezing: [false],
      ChestPain: [false],
      Claudication: [false],
      Edema: [false],
      Palpitations: [false],
      AbdominalPain: [false],
      BloodInStool: [false],
      Constipation: [false],
      Diarrhea: [false],
      Heartburn: [false],
      LossOfAppetite: [false],
      Nausea: [false],
      Vomiting: [false],
      Dysuria: [false],
      Polyuria: [false],
      UrinaryFrequency: [false],
      ColdIntolerance: [false],
      HeatIntolerance: [false],
      Polydipsia: [false],
      Polyphagia: [false],
      Dizziness: [false],
      ExtremityNumbness: [false],
      ExtremityWeakness: [false],
      Headaches: [false],
      Seizures: [false],
      Tremors: [false],
      Anxiety: [false],
      Depression: [false],
      BreastDischarge: [false],
      BreastLump: [false],
      Hives: [false],
      MoleChanges: [false],
      Rash: [false],
      SkinLesion: [false],
      BackPain: [false],
      JointPain: [false],
      JointSwelling: [false],
      NeckPain: [false],
      EasilyBleeds: [false],
      EasilyBruises: [false],
      Lymphedema: [false],
      BloodClots: [false],
      FoodAllergies: [false],
      SeasonalAllergies: [false],
      NoneApply: [false],
    });
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  onSubmit() {
    let body = {
      ...this.reviewOfSystemForm.value,
      PatientId: this.id,
      DoctorName: this.doctor.DoctorName,
      DoctorId: this.doctorId,
    };
    console.log(body);
    this.reviewofSystemsService
      .registerReviewOfSystem(body)
      .subscribe((res) => {
        this.isSuccess = true;

        this.reviewOfSystemForm.reset();
      });
  }
}
