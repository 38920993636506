import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-split-b',
  templateUrl: './split-b.component.html',
  styleUrls: ['./split-b.component.css'],
})
export class SplitBComponent implements OnInit {
  id = 0;
  constructor(private route: ActivatedRoute) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }
  tabs: string[] = [
    'Visit Summary',
    'Progress Notes',
    'Recent Prescriptions',
    'Lab Reports',
    'Radiology Reports',
    'Orders',
    'Test Results',
    'Study Reports',
    'Vital Reports',
    'Nurse Notes',
    'Inital Evaluation',
    'Refferal',
    'Rounds Book',
    'Invoice',
    'Study Images',
    'Patient Summary',
    'Allergies',
  ];
  activatedTabIndex: number = 0;

  tabChange(tabIndex: number) {
    // debugger;
    this.activatedTabIndex = tabIndex;
  }
}
