import { Component, OnInit } from '@angular/core';
import { MriService } from '../../services/mri.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder } from '@angular/forms';
import { HttpEvent, HttpEventType } from '@angular/common/http';
// import { AngularFireStorage } from "@angular/fire/compat/storage";

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-mri',
  templateUrl: './add-mri.component.html',
  styleUrls: ['./add-mri.component.css'],
})
export class AddMriComponent implements OnInit {
  id = 0;
  imageUrl: string;
  isSuccess = false;
  uploadProgress: number = 0;
  constructor(
    private mriService: MriService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }

  mriFrm = this.fb.group({
    MriImage: [''],
    StudyName: [''],
    StudyDate: [''],
  });

  get StudyDate() {
    return this.mriFrm.get('StudyDate');
  }
  get StudyName() {
    return this.mriFrm.get('StudyName');
  }
  get MriImage() {
    return this.mriFrm.get('MriImage');
  }

  image(e) {
    let formData = new FormData();
    let files = e.target.files;
    formData.append('file', files[0], files[0].name);

    this.mriService.imageUpload(formData).subscribe(
      (res: HttpEvent<any>) => {
        if (res.type === HttpEventType.UploadProgress) {
          this.uploadProgress = Math.round((100 * res.loaded) / res.total);
        } else if (res.type === HttpEventType.Response) {
          let stImg = environment.imgurl + res.body['path'];
          this.imageUrl = stImg;
          this.uploadProgress = 100;
        }
      },
      ({ error }) => {
        console.log(error);
        // alert(error.message)
      }
    );
  }
  // image(event: any): void {
  //   const formData = new FormData();
  //   const files: FileList = event.target.files;
  //   const patientId = this.id; // Assume this.id contains the correct patient ID
  //   const series = 'Series001'; // Series name or ID

  //   if (!files || files.length === 0) {
  //     console.error('No files selected for upload.');
  //     return;
  //   }

  //   // Append files
  //   for (let i = 0; i < files.length; i++) {
  //     formData.append('files', files[i], files[i].name);
  //   }

  //   formData.append('patientId', patientId.toString());
  //   formData.append('series', series);

  //   this.mriService.imageUpload(formData).subscribe(
  //     (res: HttpEvent<any>) => {
  //       if (res.type === HttpEventType.UploadProgress) {
  //         this.uploadProgress = Math.round((100 * res.loaded) / res.total);
  //       } else if (res.type === HttpEventType.Response) {
  //         console.log('Upload successful:', res.body);

  //         // Save only the folder path
  //         this.imageUrl = `${environment.imgurl}${res.body.folderPath}`;
  //         this.uploadProgress = 100;
  //       }
  //     },
  //     (error) => {
  //       console.error('Upload error:', error);
  //     }
  //   );
  // }

  formSubmit() {
    let driImage = this.mriFrm.value['MriImage'];
    let studyName = this.mriFrm.value['StudyName'];
    let studyDate = this.mriFrm.value['StudyDate'];

    let body = {
      PatientId: this.id,
      StudyDate: studyDate,
      StudyName: studyName,
      MriImage: this.imageUrl,
    };
    this.mriService.registerRadiologyMri(body).subscribe((res) => {
      this.isSuccess = true;
      this.mriFrm.reset();
    });
  }
}
