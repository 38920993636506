<app-navbar></app-navbar>

<div class="reset-password-container">
    <h2>Reset Password</h2>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <!-- Username Field -->
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Username</mat-label>
            <input matInput formControlName="userName" placeholder="Username" />
            <mat-error *ngIf="submitted && resetPasswordForm.controls.userName.invalid">
                Username is required.
            </mat-error>
        </mat-form-field>

        <!-- Reset Token Field -->
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Reset Token</mat-label>
            <input matInput formControlName="token" placeholder="Reset Token" />
            <mat-error *ngIf="submitted && resetPasswordForm.controls.token.invalid">
                Reset token is required.
            </mat-error>
        </mat-form-field>

        <!-- Password Field with Toggle -->
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>New Password</mat-label>
            <input matInput [type]="passwordFieldType" formControlName="password" placeholder="New Password" />
            <button mat-icon-button matSuffix type="button" (click)="togglePasswordVisibility()"
                [attr.aria-label]="'Toggle password visibility'">
                <mat-icon>{{ passwordFieldType === 'password' ? 'visibility' : 'visibility_off' }}</mat-icon>
            </button>
            <mat-error *ngIf="submitted && resetPasswordForm.controls.password.invalid">
                Password is required.
            </mat-error>
        </mat-form-field>

        <!-- Submit Button -->
        <button mat-raised-button color="primary" type="submit" class="full-width">
            Reset Password
        </button>
    </form>

    <!-- Success and Error Messages -->
    <mat-card *ngIf="successMessage" class="success-message">
        {{ successMessage }}
    </mat-card>
    <mat-card *ngIf="errorMessage" class="error-message">
        {{ errorMessage }}
    </mat-card>
</div>