<div class="container mt-5">
    <canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)" (touchmove)="onMouseMove($event)"
        (touchstart)="onMouseDown($event)" #signPad width="350" height="200">
    </canvas>
    <button type="button" class="ml-3" color="primary" mat-raised-button (click)="saveSignature($event)">Save</button>

    <button type="button" class="ml-3" color="accent" mat-raised-button (click)="clearSignature($event)">Clear</button>
</div>
<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">



                <mat-form-field class="example-full-width" hidden>
                    <mat-label>Use Id</mat-label>
                    <mat-icon matPrefix>person</mat-icon>
                    <input type="text" formControlName="UserId" matInput>

                </mat-form-field>



                <button type="submit" [disabled]="Frm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>

            </form>

        </div>

    </div>
</div>
<div *ngFor="let signature of signatures" class="dropdown-item">
    <img style="max-width: 250px;" [src]="signature.SignatureData" alt="Signature" class="signature-preview" />
    <button (click)="copySignature(signature.SignatureData)">Copy</button>
    <button (click)="updateSignature(signature)">Update</button>
    <button (click)="deleteSignature(signature.id)">Delete</button>
</div>

<div>
    <div [innerHTML]="textBoxContent" class="preview"></div>

</div>