import { Component } from '@angular/core';
import { HospitalService } from 'src/app/services/hospital.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.css'],
})
export class PatientSearchComponent {
  nationalId: string = '';
  phoneNumber: string = '';
  patients: any[] = [];
  errorMessage: string = '';

  constructor(
    private hospitalService: HospitalService,
    private patientService: PatientService
  ) {}

  searchPatients() {
    if (!this.nationalId && !this.phoneNumber) {
      this.errorMessage =
        'Please provide either National ID or Phone Number to search.';
      this.patients = [];
      return;
    }
    // Validation: Check if at least one field is filled
    if (!this.nationalId && !this.phoneNumber) {
      this.errorMessage =
        'Please provide either National ID or Phone Number to search.';
      this.patients = [];
      return;
    }

    // Clear error message and proceed with the search
    this.errorMessage = '';
    this.patientService
      .searchPatients(this.nationalId, this.phoneNumber)
      .subscribe(
        (data) => {
          this.patients = data;
          this.errorMessage = '';
        },
        (error) => {
          this.patients = [];
          this.errorMessage =
            error.error || 'An error occurred while searching for patients.';
        }
      );
  }
}
