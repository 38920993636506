import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// Define an interface for the AuditLog
export interface AuditLog {
  id: number;
  action: string;
  entityName: string;
  entityId: string;
  changes: string;
  userId: string;
  userName: string;
  timestamp: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuditLogService {
  // private apiUrl = `${environment.Url}/auditlogs`;
  // url = environment.Url + 'AuditLog';
  // constructor(private http: HttpClient) {}
  // getAuditLogs(): Observable<AuditLog[]> {
  //   const token = localStorage.getItem('jwt_token');
  //   const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
  //   return this.http.get<AuditLog[]>(this.url, { headers });
  // }
}
