import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { RadiologyOrderSubject } from 'src/app/Models/radiologyordersubject.model';
import { RadilogyOrderSubjectService } from 'src/app/services/radilogy-order-subject.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-radiology-order-subject',
  templateUrl: './add-radiology-order-subject.component.html',
  styleUrls: ['./add-radiology-order-subject.component.css']
})
export class AddRadiologyOrderSubjectComponent implements OnInit {

  subjectList:RadiologyOrderSubject[]=[];
  isSuccess= false;
  constructor(private fb:UntypedFormBuilder,private radiologyOrderSubjectService:RadilogyOrderSubjectService, private tokenService: TokenService) { }

  ngOnInit(): void {
    this.getSubjects();
  }


  subFrm = this.fb.group({
    Subject:['',Validators.required],
    
  })


  getSubject(){
  return this.subFrm.get('Subject')
  }




  getSubjects(){
    this.radiologyOrderSubjectService.getRadiologyOrderSubjects().subscribe(res=>{
      this.subjectList=res;
    })
  }
  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getSubject();
      
    }
  }
  formSubmit(){
    let subject = this.subFrm.value['Subject']

let body={
  "Subject":subject,
}

this.radiologyOrderSubjectService.registerRadiologyOrderSubject(body).subscribe(res=>{

 this.subjectList.push(res);
 this.isSuccess=true
 this.subFrm.reset();

})

  }

  deleteSubject(id) {
    this.radiologyOrderSubjectService.deleteRadiologyOrderSubject(id).subscribe(res => {
    this.getSubjects()



    })
  }
}
