<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Narcotic (Opioid)
                Medical Policy Agreement</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">

                <hr>
                <strong>We have a primary goal of providing excellence in care to our patients.</strong>
                <p>The best practice in {{hospital.HospitalName}} recognizes that patients undergoing operative
                    procedures may
                    require narcotic pain medications. Your physician may prescribe pain medication during your
                    post-operative period with a limited supply and in accordance with The state Medical Board
                    regulations where the provider is located/registered.</p>
                <p>It is important to understand the physicians at {{hospital.HospitalName}} DO NOT prescribe pain
                    medication pre-operatively (before you have surgery).</p>

                <p>The physicians at {{hospital.HospitalName}} do not manage chronic pain, if you need chronic pain
                    management we can provide you a referral to a pain management specialist.</p>

                <p>To ensure that we, as the provider, and you as the patient, understand how our practice will ensure
                    high quality and safety in prescribing narcotics you will need to adhere and agree to the policy
                    agreement outlined below.</p>


                <p>I, understand that I may receive narcotic medication from {{hospital.HospitalName}} to treat my
                    post-operative
                    pain.</p>

                <ul>
                    <li> If I am receiving opioid pain medication from a {{hospital.HospitalName}} physician I will
                        not
                        seek narcotic pain medications from any other provider.</li>
                    <li> I understand that if I am receiving long term pain medicine from a pain management physician, I
                        will make arrangements for post-operative pain medication with that physician. I will not
                        receive
                        pain medications post operatively from a {{hospital.HospitalName}} physician.</li>
                    <li> I will not take prescribed narcotics in large amounts or more frequently than prescribed.</li>
                    <li> I will not buy and use additional narcotics or borrow/use narcotics prescribed to another
                        individual.</li>
                    <li> I will not use any illegal or street drugs (ex. Marijuana, Cocaine) while under the care of the
                        {{hospital.HospitalName}} physician.</li>
                    <li> I will not purchase or use narcotics which may be available in another country or through mail
                        order.</li>
                    <li> I understand that {{hospital.HospitalName}} will not provide refills on narcotics. A new
                        prescription may be provided at the first post-operative visit. Any ongoing need for narcotic
                        pain
                        medication beyond that point will be referred to a pain management specialist.</li>
                    <li> I will not drink alcohol while on narcotics.</li>
                    <li> I understand it is illegal to refill narcotic medication by phone.</li>
                    <li> If I have left over narcotic pain medication, I will dispose of it. I will not donate or sell
                        extra narcotics to another individual.</li>
                    <li> Patients having greater than expected amount of post-operative pain will be seen promptly in
                        clinic.</li>
                    <li> I understand that if my pain acute post-operative pain medication requirements are beyond a
                        neurosurgeon’s scope of practice to manage I will be referred to a pain a management physician.
                        I
                        understand it is my responsibility to follow up and schedule an appointment.</li>
                </ul>



                <strong>I UNDERSTAND AND AGREE TO THE CONDTIONS OF CARE DESCRIBED ABOVE AND WILL COMPLY WITH THEM.
                    FAILURE TO COMPLY WITH ANY OF THE TERMS OF THIS AGREEMENT MAY RESULT IN IMMEDIATE TERMINATION OF
                    SERVICE.</strong>
                <div class="row">

                    <div class="col-md-12">


                        <mat-form-field class="example-full-width">
                            <mat-label>This consent was signed by(Patient/Legal Representative Print Name):</mat-label>
                            <input type="text" formControlName="AuthorisedSignatoryName" matInput>
                            <mat-error>Please enter Patient/Legal Representative Print Name</mat-error>
                        </mat-form-field>
                        <!-- <mat-form-field class="example-full-width">
                <mat-label>Signature:</mat-label>
                <input type="text" formControlName="Signauture" matInput>
                <mat-error>Please enter Description</mat-error>
              </mat-form-field> -->


                    </div>

                    <div class="container mt-5">
                        <canvas (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)"
                            (touchmove)="onMouseMove($event)" (touchstart)="onMouseDown($event)" #signPad width="350"
                            height="200">
                        </canvas>
                        <button type="button" class="ml-3" color="primary" mat-raised-button
                            (click)="saveSignature($event)">Save</button>

                        <button type="button" class="ml-3" color="accent" mat-raised-button
                            (click)="clearSignature($event)">Clear</button>
                    </div>



                </div>
                <button type="submit" [disabled]="Frm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>

    </div>
</div>