import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Duty } from '../Models/duty.mode';

@Injectable({
  providedIn: 'root',
})
export class RosterService {
  // private apiUrl = 'http://localhost:5000/api/roster';
  private apiUrl = environment.Url + 'Rosters';
  private apiUrlDuty = environment.Url + 'Dutys';
  constructor(private http: HttpClient) {}

  getRosters(): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl);
  }

  getRoster(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${id}`);
  }

  createRoster(roster: any): Observable<any> {
    return this.http.post<any>(this.apiUrl, roster);
  }
  getWeekCommencings(wardId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}/week-commencings/${wardId}`);
  }

  createDuties(data: any): Observable<Duty> {
    return this.http.post<Duty>(this.apiUrlDuty, data);
  }
  updateRoster(id: number, roster: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/${id}`, roster);
  }

  getDuties(rosterId: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}/${rosterId}/duties`);
  }

  updateDuty(dutyId: number, duty: any): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/duties/${dutyId}`, duty);
  }
}
