<div class="row mt-5">
    <h2>QR Code Scanner</h2>

    <!-- Webcam Component -->
    <div class="col-md-12">
        <webcam [trigger]="invokeObservable" (imageCapture)="captureImg($event)" [height]="240" [width]="320">
        </webcam>
    </div>

    <!-- Capture Button -->
    <div class="col-md-12">
        <button class="btn btn-danger mt-3" (click)="getSnapshot()" type="button">
            Capture Image
        </button>
    </div>

    <!-- QR Code Result -->
    <div class="col-md-12 mt-4">
        <div id="results">
            <h4>Scanned QR Code Result:</h4>
            <p *ngIf="scannedResult">{{ scannedResult }}</p>
            <p *ngIf="!scannedResult">No QR code scanned yet.</p>
        </div>
    </div>

    <!-- Captured Image -->
    <div class="col-md-12 mt-3">
        <img *ngIf="webcamImage" [src]="webcamImage.imageAsDataUrl" height="180" />
    </div>
</div>