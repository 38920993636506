import { Component, OnInit } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-writing-pad',
  templateUrl: './writing-pad.component.html',
  styleUrls: ['./writing-pad.component.css'],
})
export class WritingPadComponent implements OnInit {
  constructor(private tokenService: TokenService) {}
  ngOnInit(): void {}
}
