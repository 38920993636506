<div class="container">
    <h2>Clinical Documentation Assistance</h2>
    <div id="editor" style="height: 200px; border: 1px solid #ccc;"></div>
    <div>
        <button color="primary" mat-raised-button (click)="generate()">Generate Clinical Note</button>
        <button color="accent" class="float-right" mat-raised-button (click)="startSpeechRecognition()">Start Speech
            Recognition</button>
    </div>
    <div *ngIf="response">
        <h3>Generated Documentation:</h3>
        <pre>{{ response }}</pre>
    </div>
</div>