import { Component } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-socialhistory',
  templateUrl: './update-socialhistory.component.html',
  styleUrls: ['./update-socialhistory.component.css']
})
export class UpdateSocialhistoryComponent {

}
