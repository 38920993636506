import { Hospital } from './../Models/hospital.model';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HospitalService {
  url = environment.Url + 'Hospitals';

  hospitalUsers = environment.Url + 'Hospitals/hospitalUsers';
  department = environment.Url + 'Hospitals/department';
  ward = environment.Url + 'Hospitals/ward';
  doctors = environment.Url + 'Hospitals/doctors';
  patients = environment.Url + 'Hospitals/patients';
  nurses = environment.Url + 'Hospitals/nurses';
  Hospitaltemplates = environment.Url + 'Hospitals/Hospitaltemplates';
  labMmbers = environment.Url + 'Hospitals/labMmbers';
  radiologyMmbers = environment.Url + 'Hospitals/radiologyMmbers';
  invoices = environment.Url + 'Hospitals/invoices';
  ptVisits = environment.Url + 'Hospitals/ptVisits';
  appointments = environment.Url + 'Hospitals/appointments';
  Hwards = environment.Url + 'Hospitals/wards';
  paymentMethod = environment.Url + 'Hospitals/paymentMethod';
  SearchPatient = environment.Url + 'Hospitals/SearchPatient';

  imgUrl = environment.Url + 'ImageUpload';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  imageUpload(body) {
    return this.http.post(this.imgUrl, body);
  }
  searchPatient(nationalId?: string, phoneNumber?: string): Observable<any> {
    const params: any = {};
    if (nationalId) params.nationalId = nationalId;
    if (phoneNumber) params.phoneNumber = phoneNumber;

    return this.http.get<any>(this.SearchPatient, { params });
  }

  registerHospital(body) {
    return this.http
      .post<Hospital>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getHospitals() {
    return this.http
      .get<Hospital[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getHospitalById(id) {
    return this.http.get<Hospital>(this.url + '/' + id);
  }

  gethospitalUsers(id) {
    return this.http.get<Hospital>(this.hospitalUsers + '/' + id);
  }

  getDepartment(id) {
    return this.http.get<Hospital>(this.department + '/' + id);
  }

  getHwards(id) {
    return this.http.get<Hospital>(this.Hwards + '/' + id);
  }
  getPaymentMethod(id) {
    return this.http.get<Hospital>(this.paymentMethod + '/' + id);
  }

  getWards(id) {
    return this.http.get<Hospital>(this.ward + '/' + id);
  }
  getDoctors(id) {
    return this.http.get<Hospital>(this.doctors + '/' + id);
  }
  getNurses(id) {
    return this.http.get<Hospital>(this.nurses + '/' + id);
  }
  geHospitaltemplates(id) {
    return this.http.get<Hospital>(this.Hospitaltemplates + '/' + id);
  }
  getPatients(id) {
    return this.http.get<Hospital>(this.patients + '/' + id);
  }
  getLabmembers(id) {
    return this.http.get<Hospital>(this.labMmbers + '/' + id);
  }
  getRadiologymembers(id) {
    return this.http.get<Hospital>(this.radiologyMmbers + '/' + id);
  }
  GetInvoices(id) {
    return this.http.get<Hospital>(this.invoices + '/' + id);
  }
  GetPatientVisits(id) {
    return this.http.get<Hospital>(this.ptVisits + '/' + id);
  }
  getAppointments(id) {
    return this.http.get<Hospital>(this.appointments + '/' + id);
  }

  updateHospital(id, body) {
    return this.http
      .put<Hospital>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deleteHospital(id) {
    return this.http
      .delete<Hospital>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
