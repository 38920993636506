import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Prescription } from '../Models/prescription.model';
import { FavoritePrescription } from '../Models/favoriteprescription.model';

@Injectable({
  providedIn: 'root',
})
export class FavoritePrescriptionService {
  url = environment.Url + 'FavoritePrescriptions';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}
  private apiUrl = 'https://pharmacy-api.com/send-prescription'; // Replace with actual API endpoint

  sendFavoritePrescription(body: any) {
    return this.http.post(this.apiUrl, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
  registerFavoritePrescription(body): Observable<FavoritePrescription> {
    return this.http
      .post<FavoritePrescription>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  saveMultipleFavoritePrescriptions(
    body: FavoritePrescription[]
  ): Observable<FavoritePrescription[]> {
    return this.http
      .post<FavoritePrescription[]>(
        this.url + '/saveMultipleFavoritePrescriptions',
        body
      )
      .pipe(catchError(this.handleError));
  }

  getFavoritePrescriptions(): Observable<FavoritePrescription[]> {
    return this.http
      .get<FavoritePrescription[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getFavoritePrescriptionById(id): Observable<FavoritePrescription> {
    return this.http.get<FavoritePrescription>(this.url + '/' + id);
  }

  updateFavoritePrescription(id, body): Observable<FavoritePrescription> {
    return this.http
      .put<FavoritePrescription>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deleteFavoritePrescription(id): Observable<FavoritePrescription> {
    return this.http
      .delete<FavoritePrescription>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid Prescription');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
