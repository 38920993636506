import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalHistory } from 'src/app/Models/medicalhistory.model';
import { MedicalHistoryService } from 'src/app/services/medical-history.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-patient-view-medicalhistory',
  templateUrl: './patient-view-medicalhistory.component.html',
  styleUrls: ['./patient-view-medicalhistory.component.css'],
})
export class PatientViewMedicalhistoryComponent implements OnInit {
  id = 0;
  medicalHistoryList: MedicalHistory[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private medicalHistoryService: MedicalHistoryService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPatientMedicalHistory(this.id);
  }

  getPatientMedicalHistory(id) {
    this.patientService.getMedicalHistory(id).subscribe((res) => {
      this.medicalHistoryList = res.MedicalHistorys;
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.medicalHistoryService.deleteMedicalHistory(id).subscribe((res) => {
      this.getPatientMedicalHistory(this.id);
    });
  }
}
