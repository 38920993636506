import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { ElectromyographyService } from 'src/app/services/electromyography.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-electromyography',
  templateUrl: './update-electromyography.component.html',
  styleUrls: ['./update-electromyography.component.css'],
})
export class UpdateElectromyographyComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  isSuccess = false;
  id = 0;

  doctorId: number = 0;
  doctor: Doctor;
  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private doctorService: DoctorService,
    private electromyographyService: ElectromyographyService,
    private router: Router
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.electromyographyService
      .getElectromyographyById(this.id)
      .subscribe((res) => {
        this.Frm.patchValue(res);
      });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.getDoctor(this.doctorId);
  }

  Frm = this.fb.group({
    Side: [''],
    Muscle: [''],
    Nerve: [''],
    Root: [''],
    InsAct: [''],
    Fibs: [''],
    Psw: [''],
    Amp: [''],
    Duration: [''],
    Poly: [''],
    Recrt: [''],
    IntPat: [''],
    Comment: [''],

    Signature: [''],
    DoctorName: [''],
  });

  get DoctorName() {
    return this.Frm.get('DoctorName');
  }

  get Side() {
    return this.Frm.get('Side');
  }

  get Muscle() {
    return this.Frm.get('Muscle');
  }

  get Nerve() {
    return this.Frm.get('Nerve');
  }

  get Root() {
    return this.Frm.get('Root');
  }

  get InsAct() {
    return this.Frm.get('InsAct');
  }

  get Fibs() {
    return this.Frm.get('Fibs');
  }

  get Psw() {
    return this.Frm.get('Psw');
  }

  get Amp() {
    return this.Frm.get('Amp');
  }

  get Duration() {
    return this.Frm.get('Duration');
  }

  get Poly() {
    return this.Frm.get('Poly');
  }

  get Recrt() {
    return this.Frm.get('Recrt');
  }

  get IntPat() {
    return this.Frm.get('IntPat');
  }

  get Comment() {
    return this.Frm.get('Comment');
  }

  get Signature() {
    return this.Frm.get('Signature');
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
      this.DoctorName.setValue(res.DoctorName);
    });
  }
  formSubmit() {
    let body = {
      ...this.Frm.value,
    };
    this.electromyographyService
      .updateElectromyography(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.router.navigate(['/doctorDash']);
      });
  }
}
