import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Department } from 'src/app/Models/department.model';
import { Patient } from 'src/app/Models/patient.model';
import { DepartmentService } from 'src/app/services/department.service';
import { PatientService } from 'src/app/services/patient.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog-component';
import { NurseService } from 'src/app/services/nurse.service';
import { NursedailypatientsService } from 'src/app/services/nursedailypatients.service';
import { NurseDailyPatient } from 'src/app/Models/nursedailypatient.model';
import * as moment from 'moment';
import { Nurse } from 'src/app/Models/nurse.model';
import { PrescriptionService } from 'src/app/services/prescription.service';
import { ToastrService } from 'ngx-toastr';
import { NursePatientTabAppearanceComponent } from '../nurse-patient-tab-appearance/nurse-patient-tab-appearance.component';

@Component({
  selector: 'app-nurse-dashboard',
  templateUrl: './nurse-dashboard.component.html',
  styleUrls: ['./nurse-dashboard.component.css'],
})
export class NurseDashboardComponent implements OnInit {
  displayedColumns: string[] = [
    'select',
    'Id',
    'FirstName',
    'LastName',
    'Gender',
    'PatientNationalId',
    'Email',
    'PhoneNumber',
    'PrimaryDoctor',
    'WardName',
    'Detail',
  ];
  userList: Patient[] = [];
  navigation: boolean = false;
  id = 0;
  patientList: Patient[] = [];
  filteredList;
  dptList: Department;
  dataSource: MatTableDataSource<any>;
  selectedPatientIds: string[] = [];
  selectedPatientsData: any[] = [];
  helper = new JwtHelperService();

  myToken = window.localStorage.getItem('token');

  showItem = '';
  nurse: Nurse;

  constructor(
    private patientService: PatientService,
    private dptService: DepartmentService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private nurseDailyPatientService: NursedailypatientsService,
    private toastr: ToastrService,
    private nurseService: NurseService,
    private prescriptionService: PrescriptionService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    if (decodedToken.role === 'Nurse') {
      setTimeout(() => {
        this.logout();
      }, 43200000);
    }
    this.getPatients(this.id);
    this.getNurse(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }

  getPatientPrescription(patientList: any) {
    let MorningMedication: boolean = true;
    let afternoonMedication: boolean = true;
    let nightMedication: boolean = true;
    const currentHour = new Date().getHours();
    if (patientList?.length > 0) {
      patientList.forEach((patientInfo) => {
        let medicationDue = 0;
        let medicineName = '';
        this.patientService
          .getmedicineGiven(patientInfo.PatientId)
          .subscribe((res) => {
            if (res.Prescriptions?.length > 0) {
              res.Prescriptions.forEach((medication) => {
                if (currentHour >= 5 && currentHour < 12) {
                  MorningMedication = medication.PrescriptionItems.some(
                    (givenMedication) => givenMedication.MorningGivenStatus
                  );
                  if (!MorningMedication) medicationDue++;
                  medicineName = medicineName
                    ? medicineName + ' , ' + medication.DrugName
                    : medication.DrugName;
                  this.showingMedicationToaster(
                    patientInfo,
                    medicineName,
                    medicationDue
                  );
                } else if (currentHour >= 12 && currentHour < 18) {
                  afternoonMedication = medication.PrescriptionItems.some(
                    (givenMedication) => givenMedication.AfternoonGivenStatus
                  );
                  if (!afternoonMedication) medicationDue++;
                  medicineName = medicineName
                    ? medicineName + ' , ' + medication.DrugName
                    : medication.DrugName;
                  this.showingMedicationToaster(
                    patientInfo,
                    medicineName,
                    medicationDue
                  );
                } else {
                  nightMedication = medication.PrescriptionItems.some(
                    (givenMedication) => givenMedication.NightGivenStatus
                  );
                  if (!MorningMedication) medicationDue++;
                  medicineName = medicineName
                    ? medicineName + ' , ' + medication.DrugName
                    : medication.DrugName;
                  this.showingMedicationToaster(
                    patientInfo,
                    medicineName,
                    medicationDue
                  );
                }
              });
            } else {
              // this.showingMedicationToaster(res.PatientFirstName, 'ALL', 0)
            }
          });
      });
    }
  }

  showingMedicationToaster(medicationTime, drugName: string = '', num = 0) {
    if (num) {
      medicationTime['dueMedication'] = num;
      medicationTime['drugName'] = drugName;
    }
  }

  getPatients(id) {
    this.nurseService.getNurseDalyPatients(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.NurseDailyPatients);
      this.dataSource.paginator = this.paginator;
      this.getPatientPrescription(res.NurseDailyPatients);
    });

    // .subscribe((res: any) => {
    //   let NurseDailyPatient = res.filter((patient, index, arr) => {
    //     return (
    //       index == arr.findIndex((obj) => obj.PatientId == patient.PatientId && patient.Status)
    //     );
    //   });
    //   this.setEmployees(NurseDailyPatient).then((res:any) => {
    //     const data = res.map(pt => ({...pt.Patient, NurseDailyPatientId: pt.NurseDailyPatientId}))

    //   });
    // });
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientList.filter((x) =>
        x.PatientNationalId.toLowerCase().includes(
          searchValue.value.toLowerCase()
        )
      );
    }
  }

  setEmployees(NurseDailyPatient) {
    const arr = [];
    return new Promise((resolve) => {
      NurseDailyPatient.map(async (patient, index) => {
        const data = await this.patientService
          .getPatientById(patient.PatientId)
          .toPromise();
        if (data) {
          patient.Patient = data;
          arr.push(patient);
        }
        if (index === NurseDailyPatient.length - 1) {
          resolve(arr);
        }
      });
    });
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // selectedPatients(patientIds) {
  //   this.selectedPatientIds = patientIds;

  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //     data: {
  //       title: 'Select these Patient?',
  //       content: 'Are you sure you want to select these patient?',
  //     },
  //   });
  //   dialogRef.afterClosed().subscribe((res) => {
  //     if (res === true) {
  //       this.selectedPatientIds.forEach((id) => {
  //         const payload = this.setDailyNursePatientDashboard(id);
  //         this.nurseDailyPatientService
  //           .registerNurseDailyPatient(payload)
  //           .subscribe({
  //             next: (res) => {},
  //           });
  //       });
  //       setTimeout(() => {
  //         this.showItem = null;
  //        this.selectedPatientIds = [];
  //         this.getPatients();
  //       }, 5e3);
  //     }
  //   });
  // }

  // setDailyNursePatientDashboard(PatientId): NurseDailyPatient {
  //   return {
  //     createdDate: new Date(),
  //     NurseId: this.id,
  //     PatientId: PatientId,
  //     Status: true,
  //   };
  // }

  clearFromShift() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Clear from Shift',
        content:
          'Are you sure you want to select clear these patients from shift?',
      },
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {
        if (res === true) {
          this.selectedPatientsData.forEach((data) => {
            this.nurseDailyPatientService
              .deleteNurseDailyPatient(data.NurseDailyPatientId)
              .subscribe({
                next: (res) => {
                  this.getPatients(this.id);
                },
              });
          });
        }
      },
    });
    // const index = this.dataSource.data.findIndex()
  }

  selectPatients(event, patient) {
    if (event.checked) {
      this.selectedPatientsData.push(patient);
    } else {
      const index = this.selectedPatientsData.findIndex(
        (patient) => patient.PatientId === patient.PatientId
      );
      this.selectedPatientsData.splice(index, 1);
    }
  }

  navigateToNurseDashboard() {
    this.showItem = '1';
    this.navigation = true;
  }

  dialogRefs: MatDialogRef<any>[] = [];
  openAddAppointmentModal(Id, firstName: string, lastName: string): void {
    const dialogRef = this.dialog.open(NursePatientTabAppearanceComponent, {
      width: '1280px',
      height: '1000px', // You can set the width or other configurations here
      data: { id: Id, userFname: firstName, userLname: lastName },
      disableClose: true,
      hasBackdrop: false,
    });

    this.dialogRefs.push(dialogRef); // Keep track of the opened dialogs

    dialogRef.afterClosed().subscribe(() => {
      console.log('Dialog for', firstName, 'closed');
      // Remove from the dialogRefs list once closed
      this.dialogRefs = this.dialogRefs.filter((ref) => ref !== dialogRef);
    });
  }

  closeAllDialogs(): void {
    this.dialogRefs.forEach((ref) => ref.close());
    this.dialogRefs = [];
  }
}
