<div class="container ">
    <div class="ml-3">
        <div class="row">
            <div class="col-lg float-right">
                <mat-form-field class="example-full-width mt-5">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class=" mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>




            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let row" (click)="selectTemplate(row.Content)"> {{row.Name}} </td>
            </ng-container>


            <!-- <ng-container matColumnDef="Delete">
          <th mat-header-cell *matHeaderCellDef> Delete</th>
          <td mat-cell *matCellDef="let element">
            <button (click)="delete(element.Id)" mat-mini-fab color="accent"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container> -->
            <!-- <ng-container matColumnDef="Update">
          <th  mat-header-cell *matHeaderCellDef> Edit </th>
          <td mat-cell *matCellDef="let element"> 
            <button type="button"  [routerLink]="['/editInvoice/',element.InvoiceId]" mat-mini-fab color="primary">
              <mat-icon matPrefix>edit</mat-icon>
            </button>
          </td>
        </ng-container>-->


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[2, 5,10, 25, 100]"></mat-paginator>
    </div>
    <!-- <div>
      <h2>Available Templates</h2>
  
      <ul>
        <li *ngFor="let template of templates">
          <button mat-raised-button color="info" (click)="selectTemplate(template.Content)">{{ template.Name }}</button>
        </li>
      </ul>
    </div> -->


    <div class="row">

        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Progress Notes</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Report Name</mat-label>
                    <!-- <input type="text" formControlName="ReportName" matInput> -->
                    <input type="text" matInput formControlName="ReportName" [matAutocomplete]="auto5"> <mat-icon
                        matSuffix>search</mat-icon>
                    <mat-autocomplete #auto5="matAutocomplete">
                        <mat-option *ngFor="let dos of filteredReportName | async" [value]="dos">
                            {{ dos }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error>Please enter Report Name</mat-error>
                </mat-form-field>


                <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Description</mat-label>
                    <textarea #textarea (click)="captureCursorPosition($event)" [(ngModel)]="textBoxContent" matInput
                        (input)="onTextInput($event)" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="10" cdkAutosizeMaxRows="140" formControlName="ReportDescription" matInput
                        (keyup)="captureCursorPosition($event)"></textarea>

                    <button class="app-speech-input-btn" (click)="start()"></button>
                    <button type="button" color="accent" (click)="stop()"
                        mat-mini-fab><mat-icon>stop</mat-icon></button>


                </mat-form-field>



                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Sign & Save</button>

            </form>

        </div>
    </div>
</div>




<!-- 
<textarea #textarea (click)="captureCursorPosition($event)" (keyup)="captureCursorPosition($event)" rows="10" cols="50"
    placeholder="Type here or use voice recognition..."></textarea>

<button (click)="startVoiceRecognition()">Start Voice Recognition</button> -->