import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Doctor } from 'src/app/Models/doctor.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';
import { UserService } from 'src/app/services/user.service';
import {
  connect,
  Room,
  LocalVideoTrack,
  LocalDataTrack,
  RemoteDataTrack,
  LocalAudioTrack,
  RemoteParticipant,
} from 'twilio-video';
import { Pipe, PipeTransform } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Pipe({ name: 'minutesSeconds' })
export class MinutesSecondsPipe implements PipeTransform {
  transform(value: number): string {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }
}

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.css'],
})
export class VideoCallComponent implements OnInit, OnDestroy {
  private tokenUrl =
    'https://binseraneurology.com/api/VideoToken/generate-token';
  public userName = '';
  private identity = '';
  // private identity = `user-${Math.random().toString(36).substring(7)}`;
  private roomName = 'TestRoom';
  private room?: Room;
  public isConnected = false;
  public remainingTime = 30 * 60; // Default 30 minutes
  private timerInterval: any;
  public isMuted = false;
  public isVideoDisabled = false;
  public isSharingScreen = false;
  public participantList: string[] = [];
  public messages: { sender: string; text: string }[] = [];
  public chatInput = '';
  private dataTrack?: LocalDataTrack;
  private screenTrack?: LocalVideoTrack;
  doctor: Doctor;
  patient: Patient;
  PatientId = 0;
  Doctorid = 0;

  private zoomLevels: { [key: string]: number } = {
    'local-video': 1,
    'remote-videos': 1,
  };
  private zoomStep: number = 0.1;
  private maxZoom: number = 2.0;
  private minZoom: number = 0.5;
  isDoctor: boolean = false;
  private videoTrack?: LocalVideoTrack;
  private audioTrack?: LocalAudioTrack;
  DoctorName: string;
  doctorImage: string;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private doctorService: DoctorService,
    private patientService: PatientService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA)
    public data: { doctorId: string; patientId: string; userName: string },
    private dialogRef: MatDialogRef<VideoCallComponent>
  ) {}
  ngOnInit(): void {
    this.startCall();
    this.userService.getCurrentUser().subscribe(
      (response) => {
        console.log('API Response for GetCurrentUser:', response);

        // Check if the response contains "Doctor" in roles
        this.isDoctor = response?.Roles?.includes('Doctor');
        // console.log('Derived isDoctor Value:', this.isDoctor);
      },
      (error) => {
        // console.error('Error fetching roles:', error);
        this.isDoctor = false; // Default to false on error
      }
    );

    const doctorId = this.data.doctorId;
    const patientId = this.data.patientId;
    this.identity = this.data.userName;
    // const doctorId = this.route.snapshot.queryParamMap.get('doctorId');
    // const patientId = this.route.snapshot.queryParamMap.get('patientId');
    // console.log(
    //   'Query Params - DoctorId:',
    //   doctorId,
    //   'PatientId:',
    //   patientId,
    //   'userName:',
    //   this.userName
    // );

    // Continue with existing logic to fetch doctor and patient
    if (doctorId) {
      this.getDoctor(doctorId);
    }
    if (patientId) {
      this.PatientId = parseInt(patientId, 10);
      this.getPatient(this.PatientId);
    }
    this.generateToken(doctorId, patientId);
  }

  private generateToken(
    doctorId: string | null,
    patientId: string | null
  ): void {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = {
      identity: `user-${Math.random().toString(36).substring(7)}`,
      doctorId,
      patientId,
    };

    this.http.post(this.tokenUrl, body, { headers }).subscribe({
      next: (response: any) => this.connectToRoom(response.token),
      error: (error) => console.error('Error fetching token:', error),
    });
  }

  private connectToRoom(token: string): void {
    this.dataTrack = new LocalDataTrack();

    connect(token, { name: this.roomName, tracks: [this.dataTrack] }).then(
      (room: Room) => {
        this.room = room;
        this.isConnected = true;

        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then((stream) => {
            this.videoTrack = new LocalVideoTrack(stream.getVideoTracks()[0]);
            this.audioTrack = new LocalAudioTrack(stream.getAudioTracks()[0]);

            this.room?.localParticipant.publishTrack(this.videoTrack);
            this.room?.localParticipant.publishTrack(this.audioTrack);
            room.localParticipant.publishTrack(this.dataTrack);
            this.attachTrack(this.videoTrack, 'local-video');
          });

        room.participants.forEach((participant) =>
          this.setupParticipantEvents(participant)
        );
        room.on('participantConnected', (participant) =>
          this.setupParticipantEvents(participant)
        );
        room.on('participantDisconnected', (participant) =>
          this.detachParticipantTracks(participant)
        );
        this.updateParticipantList();
      }
    );
  }

  private setupParticipantEvents(participant: RemoteParticipant): void {
    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed && publication.track) {
        if (publication.track.kind === 'data') {
          this.subscribeToDataTrack(publication.track as RemoteDataTrack);
        } else {
          this.attachTrack(publication.track, 'remote-videos');
        }
      }
    });

    // Handle new track subscriptions
    participant.on('trackSubscribed', (track) => {
      if (track.kind === 'data') {
        this.subscribeToDataTrack(track as RemoteDataTrack);
      } else {
        this.attachTrack(track, 'remote-videos');
      }
    });

    // Handle track unsubscriptions
    participant.on('trackUnsubscribed', (track) => this.detachTrack(track));
    // participant.tracks.forEach((publication) => {
    //   if (publication.isSubscribed && publication.track) {
    //     this.attachTrack(publication.track, 'remote-videos');
    //   }
    // });

    // participant.on('trackSubscribed', (track) =>
    //   this.attachTrack(track, 'remote-videos')
    // );
    // participant.on('trackUnsubscribed', (track) => this.detachTrack(track));
  }

  private subscribeToDataTrack(track: RemoteDataTrack): void {
    track.on('message', (data: string) => {
      const parsedMessage = JSON.parse(data);
      // console.log('Received message:', parsedMessage);
      this.messages.push(parsedMessage);
    });
  }

  sendMessage(): void {
    if (this.chatInput.trim() && this.dataTrack) {
      const message = { sender: this.identity, text: this.chatInput };
      this.messages.push(message);

      // Broadcast message using LocalDataTrack
      this.dataTrack.send(JSON.stringify(message));

      this.chatInput = '';
    } else {
      // console.warn('Message could not be sent: No data track or empty input.');
    }
  }

  private attachTrack(track: any, containerId: string): void {
    const container = document.getElementById(containerId);
    if (container) {
      const videoElement = track.attach();
      videoElement.style.transform = 'none'; // Ensure no mirroring
      container.appendChild(videoElement);
    }
    // if (container) {
    //   container.appendChild(track.attach());
    // }
  }

  private detachTrack(track: any): void {
    if (track && typeof track.detach === 'function') {
      track.detach().forEach((element: HTMLElement) => {
        if (element && element.parentNode) {
          element.parentNode.removeChild(element);
        }
      });
    }
  }

  startCall(): void {
    this.startTimer();

    // Example: Connect to the room
    connect('your-token-here', { name: 'your-room-name-here' }).then((room) => {
      this.room = room;
      this.isConnected = true;
    });
  }

  startTimer(): void {
    this.timerInterval = setInterval(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
      } else {
        this.endCall(); // Automatically end the call on timeout
      }
    }, 1000);
  }

  extendTimer(minutes: number): void {
    this.remainingTime += minutes * 60;
  }

  endCall(): void {
    clearInterval(this.timerInterval);

    if (this.room) {
      this.room.disconnect();
    }
    this.dialogRef.close();
    // window.close();
  }
  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
    if (this.room) {
      this.room.disconnect();
      // console.log('Disconnected from room');
    }
  }

  private detachParticipantTracks(participant: any): void {
    participant.videoTracks.forEach((publication: any) => {
      if (publication.track) {
        this.detachTrack(publication.track);
      }
    });
  }

  private updateParticipantList(): void {
    this.participantList = [this.identity];
    this.room?.participants.forEach((participant) =>
      this.participantList.push(participant.identity)
    );
  }

  // leaveRoom(): void {
  //   if (this.room) {
  //     this.room.disconnect();
  //     this.isConnected = false;
  //     console.log('Left the room');
  //   }
  // }
  toggleMute(): void {
    if (this.audioTrack) {
      this.isMuted = !this.isMuted;
      this.audioTrack.enable(!this.isMuted); // Toggle audio track state
      // console.log(`Audio is now ${this.isMuted ? 'muted' : 'unmuted'}`);
    } else {
      // console.warn('No audio track available to toggle mute.');
    }
  }

  toggleVideo(): void {
    if (this.videoTrack) {
      this.isVideoDisabled = !this.isVideoDisabled;
      this.videoTrack.enable(!this.isVideoDisabled); // Toggle video track state
      console.log(
        `Video is now ${this.isVideoDisabled ? 'disabled' : 'enabled'}`
      );
    } else {
      // console.warn('No video track available to toggle.');
    }
  }

  startScreenShare(): void {
    navigator.mediaDevices
      .getDisplayMedia({ video: true })
      .then((stream) => {
        const screenTrack = new LocalVideoTrack(stream.getTracks()[0]);
        this.screenTrack = screenTrack;
        this.room?.localParticipant.publishTrack(screenTrack);
        this.isSharingScreen = true;
        this.attachTrack(screenTrack, 'local-video');
        // console.log('Started screen sharing');
      })
      .catch((error) => {
        // console.error('Error starting screen share:', error);
      });
  }

  stopScreenShare(): void {
    if (this.screenTrack) {
      this.room?.localParticipant.unpublishTrack(this.screenTrack);
      this.screenTrack.stop();
      this.isSharingScreen = false;
      // console.log('Stopped screen sharing');
    }
  }

  leaveRoom(): void {
    // this.router.navigate(['/doctorDash/']);

    if (this.room) {
      // console.log('Leaving room...');

      // Detach and stop local participant's tracks
      this.room.localParticipant.tracks.forEach((publication) => {
        const track = publication.track;
        if (track) {
          // console.log(`Processing local track: ${track.kind}`);

          // Stop the track if applicable

          // Detach the track only for video and audio
          if (
            (track.kind === 'video' || track.kind === 'audio') &&
            typeof track.detach === 'function'
          ) {
            track.detach().forEach((element) => {
              if (element && element.parentNode) {
                element.parentNode.removeChild(element); // Safely remove the element
              }
            });
          }
        }
      });

      // Detach all remote participants' tracks
      this.room.participants.forEach((participant) => {
        participant.tracks.forEach((publication) => {
          const track = publication.track;
          if (track) {
            // console.log(`Processing remote track: ${track.kind}`);

            // Detach the track only for video and audio
            if (
              (track.kind === 'video' || track.kind === 'audio') &&
              typeof track.detach === 'function'
            ) {
              track.detach().forEach((element) => {
                if (element && element.parentNode) {
                  element.parentNode.removeChild(element); // Safely remove the element
                }
              });
            }
          }
        });
      });

      // Disconnect from the room
      this.room.disconnect();
      this.room = undefined; // Clear the room reference

      // Reset the UI state
      this.isConnected = false;
      this.participantList = [];
      // console.log('Disconnected from room and cleared participants.');
      this.endCall();
    } else {
      // console.warn('No active room to leave.');
    }
  }
  zoomIn(containerId: string): void {
    const zoomLevel = this.zoomLevels[containerId];
    this.zoomLevels[containerId] = Math.min(
      zoomLevel + this.zoomStep,
      this.maxZoom
    );
    this.applyZoom(containerId);
  }

  zoomOut(containerId: string): void {
    const zoomLevel = this.zoomLevels[containerId];
    this.zoomLevels[containerId] = Math.max(
      zoomLevel - this.zoomStep,
      this.minZoom
    );
    this.applyZoom(containerId);
  }

  resetZoom(containerId: string): void {
    this.zoomLevels[containerId] = 1;
    this.applyZoom(containerId);
  }

  private applyZoom(containerId: string): void {
    const container = document.getElementById(containerId);
    if (container) {
      const videoElement = container.querySelector('video');
      if (videoElement) {
        videoElement.style.transform = `scale(${this.zoomLevels[containerId]})`;
      }
    }
  }

  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
      this.DoctorName = res.DoctorName;
      this.doctorImage = res.DoctorImage;
    });
  }
  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
}
