<h2 mat-dialog-title>Favorite Diagnosis Codes</h2>
<mat-form-field>
    <mat-label>Filter Codes</mat-label>
    <input matInput [(ngModel)]="filterText" placeholder="Search by code or description" (input)="filterCodes()" />
</mat-form-field>
<mat-selection-list [(ngModel)]="selectedCodes">
    <mat-list-option *ngFor="let code of filteredCodes" [value]="code">
        {{ code.Code }} - {{ code.Description }}
    </mat-list-option>
</mat-selection-list>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Cancel</button>
    <button mat-button [disabled]="selectedCodes.length === 0" (click)="confirm()">
        Insert Selected
    </button>
</div>