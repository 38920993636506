<div class="container mt-5">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h2>Nurse Routine Assessment</h2>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">

                <div class="row">
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Patient Name</mat-label>
                            <input type="text" formControlName="PatientName" matInput>
                            <mat-error>Please enter Patient Name</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Patient D.O.B</mat-label>
                            <input type="text" formControlName="PatientDOB" matInput>
                            <mat-error>Please enter Patient Date of Birth</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Patient Height</mat-label>
                            <input type="text" formControlName="PatientHeight" matInput>
                            <mat-error>Please enter Patient Height</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Patient Weight</mat-label>
                            <input type="text" formControlName="PatientWeight" matInput>
                            <mat-error>Please enter Patient Weight</mat-error>
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Blood Pressure</mat-label>
                            <input type="text" formControlName="BloodPressure" matInput>
                            <mat-error>Please enter Blood Pressure</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Pulse</mat-label>
                            <input type="text" formControlName="Pulse" matInput>
                            <mat-error>Please enter Pulse</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Is Patienton Any Medication</mat-label>
                            <input type="text" formControlName="IsPatientonAnyMedication" matInput>
                            <mat-error>Please enter Is Patienton Any Medication</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">

                        <mat-form-field class="example-full-width">
                            <mat-label>Is Patient UnderAnyMedicalTreatment</mat-label>
                            <input type="text" formControlName="IsPatientUnderAnyMedicalTreatment" matInput>
                            <mat-error>Please enter Is Patient Under Any Medical Treatment</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <h2>Constitutional/General</h2>
                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RecentWeightChanges">
                                Recent Weight Changes
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Chills">
                                Chills
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Fever">
                                Fever
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Weakness">
                                Weakness
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoGeneralProblems">
                                No Other Problems
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other Problems</mat-label>
                                <input type="text" formControlName="OtherGeneralProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <h2>Eyes
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="VisionChange">
                                Vision Change
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="GlassessOrContacts">
                                Glassess/Contacts
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Catracts">
                                Catracts
                            </mat-checkbox>
                        </div>
                    </div>

                    <div class="col-md-2">
                        <mat-checkbox color="primary" formControlName="Glaucoma">
                            Glaucoma
                        </mat-checkbox>
                    </div>
                    <div class="col-lg-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoEyeProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherEyeProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Ear,Nose,Throat
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="HearingLoss">
                                Hearing Loss
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="EarAcheOrInfection">
                                EarAche/Infection
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="RingingInEar">
                                Ringing In Ear
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-lg-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoEarProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherEarProblems" matInput>
                            </mat-form-field>
                        </div>

                    </div>
                </div>

                <h2>Cardio Vascular
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="ChestPain">
                                Chest Pain
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="SwellingInLegs">
                                Swelling In Legs
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Palpitations">
                                Palpitations
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Cyanosis">
                                Cyanosis
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Edema">
                                Edema
                            </mat-checkbox>
                        </div>
                        <div class="col-md-1">
                            <mat-checkbox color="primary" formControlName="NoCadioVascularProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-5">
                            <mat-form-field class="example-full-width">
                                <mat-label>Rythm </mat-label>
                                <input type="text" formControlName="Rythm" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-5">
                            <mat-form-field class="example-full-width">
                                <mat-label>Volume </mat-label>
                                <input type="text" formControlName="Volume" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-5">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherCadioVascularProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Respiratory
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="ShortnessOfBreath">
                                Shortness Of Breath
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="WheezingOrAsthma">
                                Wheezing Or Asthma
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="FrequentCough">
                                Frequent Cough
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Dyspnoea">
                                Dyspnoea
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Gasping">
                                Gasping
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Haemoptysis">
                                Haemoptysis
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Expectorant">
                                Expectorant
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Oxygen">
                                Oxygen
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoRespiratoryProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherRespiratoryProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Gastrointestinal
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="AcidReflex">
                                Acid Reflex
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NauseaOrVomitimng">
                                Nausea Or Vomitimng
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="AbdominalPain">
                                Abdominal Pain
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Tenderness">
                                Tenderness
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Constipation">
                                Constipation
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Incontinence">
                                Incontinence
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Diarrhea">
                                Diarrhea
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoGastricProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherGastricProblems" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Date Of Last Bowel Movement </mat-label>
                                <input type="date" formControlName="DateOfLastBowelMovement" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Muskulatal
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="MuscleAches">
                                Muscle Aches
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="SwellingOfJoints">
                                Swelling Of Joints
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="StiffnessInJoints">
                                Stiffness In Joints
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoMuskularProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherMuskularProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Skin
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Rash">
                                Rash
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Ulcers">
                                Ulcers
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="AbnormalScars">
                                Abnormal Scars
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Dehydrated">
                                Dehydrated
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Cyanotic">
                                Cyanotic
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Pale">
                                Pale
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Icterus">
                                Icterus
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Cold">
                                Cold
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Redness">
                                Redness
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Lesson">
                                Lesson
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Wound">
                                Wound
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoSkinProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherSkinProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Neurological
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Headaches">
                                Headaches
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Dizziness">
                                Dizziness
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NumbnessTinglingLossofSensation">
                                Numbness, Tingling ,Loss of Sensation
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Paralysis">
                                Paralysis
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Oriented">
                                Oriented
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Unresponsive">
                                Unresponsive
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Swallow">
                                Swallow
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Speech">
                                Speech
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Tremors">
                                Tremors
                            </mat-checkbox>
                        </div>

                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoNeurologicalProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherNeurologicalProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Psychiatric
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Depression">
                                Depression
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Nervousness">
                                Nervousness
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Anxiety">
                                Anxiety
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="MoodSwings">
                                MoodSwings
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoPsychatricProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherPsychatricProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Endocrine
                </h2>


                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="ExessiveThirstOrHunger">
                                Exessive Thirst Or Hunger
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="HotColdIntolerance">
                                Hot Cold Intolerance
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="HotFlashes">
                                Hot Flashes
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoEndocrine">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherEndocrine" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Urinary Tract</h2>

                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="UrenaryIncontinence">
                                Urenary Incontinence
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Retention">
                                Retention
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Hematuria">
                                Hematuria
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="col-md-6">



                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Urine Color </mat-label>
                                <input type="text" formControlName="UrineColor" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Catheter Type </mat-label>
                                <input type="text" formControlName="CatheterType" matInput>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Catheter Inserted On </mat-label>
                                <input type="text" formControlName="CatheterInsertedOn" matInput>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6">


                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoUrenaryProblems">
                                None
                            </mat-checkbox>
                        </div>

                        <div class="col-lg-8">
                            <mat-form-field class="example-full-width">
                                <mat-label>Other </mat-label>
                                <input type="text" formControlName="OtherUrenaryProblems" matInput>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
                <h2>Gynecology</h2>
                <div class="row" [formGroup]="Frm">

                    <div class="col-lg-8">
                        <mat-form-field class="example-full-width">
                            <mat-label>LMP </mat-label>
                            <input type="text" formControlName="Lmp" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <h2>Fall Risk</h2>
                <div class="row" [formGroup]="Frm">
                    <div class="col-md-6">
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="Vulnerable">
                                Vulnerable
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="SideRailon">
                                Side Rail on
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="AttendantInformed">
                                Attendant Informed
                            </mat-checkbox>
                        </div>
                        <div class="col-md-2">
                            <mat-checkbox color="primary" formControlName="NoFallRisk">
                                NoFallRisk
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <!-- <mat-icon>save</mat-icon> -->
                    Save</button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully Created Prescription
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>