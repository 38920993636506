<div id="">

  <mat-toolbar [class]="color.value ? color.value : ''" class="toolbar mat-elevation-z6">

    <button mat-raised-button [class]="color.value ? color.value : ''" class="btClr" [matMenuTriggerFor]="crt">
      <mat-icon>menu</mat-icon>Menu
    </button> <br>

    <p class=" text-white mx-5"><img class="nurseimg " src="{{doctorImage}}" alt=""> {{DoctorName}} </p>

  </mat-toolbar>
  <button type="button" (click)="logout()" class="float-right" color="info" mat-raised-button>
    Logout
  </button>
  <mat-menu [class]="color.value ? color.value : ''" class="ItemS mx-5" #crt="matMenu">
    <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '17'"
      [ngClass]="{ active: showItem == '17' }">
      <mat-icon mat-list-icon> dashboard </mat-icon>
      <div class="mx-2">Dashboard</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS">
      <mat-icon mat-list-icon>search</mat-icon>
      <a routerLink="/doctorPatientScanner">Scan Patient</a>
    </div>
    <div class="ItemS">
      <mat-icon mat-list-icon>search</mat-icon>
      <a routerLink="/qrCodeScannerDoctor">Camera Scan</a>
    </div>
    <!-- <div class="ItemS">
      <mat-icon mat-list-icon>video</mat-icon>
      <a routerLink="/videoCall">Video Call</a>
    </div> -->


    <div class="ItemS " (click)="showItem='47'" [ngClass]="{'active': showItem =='47'}">
      <mat-icon>search</mat-icon>
      <div class="mx-2">Nationwide Patient Search </div>
      <mat-icon>navigate_next</mat-icon>
    </div>


    <div class="ItemS " (click)="showItem='12'" [ngClass]="{'active': showItem =='12'}">
      <mat-icon>person</mat-icon>
      <div class="mx-2">My Profile</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='29'" [ngClass]="{'active': showItem =='29'}">
      <mat-icon>add</mat-icon>
      <div class="mx-2">Add Dcotor Off Dates</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='32'" [ngClass]="{'active': showItem =='32'}">
      <mat-icon>calendar_today</mat-icon>
      <div class="mx-2">View Doctor Off Days</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '33'"
      [ngClass]="{ active: showItem == '33' }">
      <mat-icon mat-list-icon>code</mat-icon>
      <div class="mx-2">ICD 10 Dignosis Codes</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <!-- <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '1'"
        [ngClass]="{ active: showItem == '1' }">
        <mat-icon mat-list-icon>book_online</mat-icon>
        <div class="mx-2">My Appointments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '5'"
        [ngClass]="{ active: showItem == '5' }">
        <mat-icon mat-list-icon>schedule</mat-icon>
        <div class="mx-2">Todays Appointments</div>
        <mat-icon>navigate_next</mat-icon>
      </div>
      <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '2'"
        [ngClass]="{ active: showItem == '2' }">
        <mat-icon mat-list-icon>groups</mat-icon>
        <div class="mx-2">My Patients</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->
    <!-- <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '3'"
      [ngClass]="{ active: showItem == '3' }">
      <mat-icon mat-list-icon>blur_circular</mat-icon>
      <div class="mx-2">Medicine </div>
      <mat-icon>navigate_next</mat-icon>
    </div> -->
    <!-- <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '8'"
        [ngClass]="{ active: showItem == '8' }">
        <mat-icon mat-list-icon>science</mat-icon>
        <div class="mx-2">Lab Test List</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->
    <!-- <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '9'"
        [ngClass]="{ active: showItem == '9' }">
        <mat-icon mat-list-icon>science</mat-icon>
        <div class="mx-2">Radiolgy Test List</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->
    <!-- <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '4'"
      [ngClass]="{ active: showItem == '4' }">
      <mat-icon mat-list-icon>code</mat-icon>
      <div class="mx-2">CPT Codes</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '6'"
      [ngClass]="{ active: showItem == '6' }">
      <mat-icon mat-list-icon>science</mat-icon>
      <div class="mx-2">Add-Lab-Order-Items</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '7'"
      [ngClass]="{ active: showItem == '7' }">
      <mat-icon mat-list-icon>radiology</mat-icon>
      <div class="mx-2">Add-Radiology-Order-Items</div>
      <mat-icon>navigate_next</mat-icon>
    </div> -->
    <!-- <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '11'"
        [ngClass]="{ active: showItem == '11' }">
        <mat-icon mat-list-icon>visibility</mat-icon>
        <div class="mx-2">View Refill Requests</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->
    <!-- <div class="ItemS" [class]="color.value ? color.value : ''" (click)="showItem = '10'"
        [ngClass]="{ active: showItem == '10' }">
        <mat-icon mat-list-icon>search</mat-icon>
        <div class="mx-2">Scan Patient Data</div>
        <mat-icon>navigate_next</mat-icon>
      </div> -->
    <div class="ItemS " (click)="showItem='35'" [ngClass]="{'active': showItem =='35'}">
      <mat-icon>sms</mat-icon>
      <div class="mx-2"> Send SMS</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='36'" [ngClass]="{'active': showItem =='36'}">
      <mat-icon>mail</mat-icon>
      <div class="mx-2"> Send Email</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='45'" [ngClass]="{'active': showItem =='45'}">
      <mat-icon>send</mat-icon>
      <div class="mx-2"> Send Fax</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='46'" [ngClass]="{'active': showItem =='46'}">
      <mat-icon>signature</mat-icon>
      <div class="mx-2"> Signature</div>
      <mat-icon>navigate_next</mat-icon>
    </div>



    <div class="ItemS" [class]="color.value ? color.value : ''">
      <button type="button" (click)="logout()" class="btn logout" color="info" mat-raised-button>
        <!-- <mat-icon matPrefix>highlight_off</mat-icon> -->
        Logout
      </button>
    </div>

    <!-- <div class="ItemS  " >
          <mat-icon mat-list-icon>visibility</mat-icon>
          <div class="mx-2" [routerLink]="['/hospitalDash/',this.id]">Hospital</div>
          <mat-icon>navigate_next</mat-icon>
        </div> -->

  </mat-menu>




  <div class="main container-fluid">

    <span [ngSwitch]="showItem">
      <p *ngSwitchCase="'1'">
        <app-view-doctor-appointments></app-view-doctor-appointments>
      </p>
      <p *ngSwitchCase="'2'">
        <app-view-doctor-patients></app-view-doctor-patients>
      </p>
      <p *ngSwitchCase="'3'">
        <app-add-medicine></app-add-medicine>
      </p>
      <p *ngSwitchCase="'4'">
        <app-add-cptcode></app-add-cptcode>
      </p>
      <p *ngSwitchCase="'5'">
        <app-view-doctor-appointments-today></app-view-doctor-appointments-today>
      </p>
      <p *ngSwitchCase="'6'">
        <app-add-lab-order-subject></app-add-lab-order-subject>
      </p>
      <p *ngSwitchCase="'7'">
        <app-add-radiology-order-subject></app-add-radiology-order-subject>
      </p>
      <!-- <p *ngSwitchCase="'8'">
        <app-view-lab-order-subject></app-view-lab-order-subject>
      </p>
      <p *ngSwitchCase="'9'">
        <app-view-radiology-order-subject></app-view-radiology-order-subject>
      </p> -->
      <p *ngSwitchCase="'10'">
        <app-doctor-patient-scanner></app-doctor-patient-scanner>
      </p>
      <p *ngSwitchCase="'11'">
        <app-view-refill-request></app-view-refill-request>
      </p>
      <p *ngSwitchCase="'12'">
        <app-view-doctor-profile></app-view-doctor-profile>
      </p>
      <p *ngSwitchCase="'29'">
        <app-add-my-off-days></app-add-my-off-days>
      </p>
      <p *ngSwitchCase="'32'">
        <app-view-my-off-days></app-view-my-off-days>
      </p>
      <p *ngSwitchCase="'33'">
        <app-view-icd-codes></app-view-icd-codes>
      </p>
      <p *ngSwitchCase="'35'">
        <app-sms></app-sms>
      </p>
      <p *ngSwitchCase="'36'">
        <app-email></app-email>
      </p>
      <p *ngSwitchCase="'45'">
        <app-fax></app-fax>
      </p>
      <p *ngSwitchCase="'46'">
        <app-signature></app-signature>
      </p>
      <p *ngSwitchCase="'47'">
        <app-patient-search></app-patient-search>
      </p>
      <div *ngSwitchDefault>
        <mat-tab-group backgroundColor="primary" (selectedTabChange)="onTabChange($event)">

          <mat-tab label="Waiting Room">

            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">pending</mat-icon>
              <span class="ml-2 mt-1">Waiting Room
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 0">
              <div>
                <mat-slide-toggle class="mt-5" [(ngModel)]="showAllPatient">Show All Patients</mat-slide-toggle>

                <!-- <mat-form-field appearance="fill">
                <mat-label>Color</mat-label>
                <mat-select [formControl]="color" (selectionChange)="getValue()">
                  <mat-option value="accent">Accent</mat-option>
                  <mat-option value="warn">Warn</mat-option>
                  <mat-option value="primary">primary</mat-option>
                  <mat-option value="tealGreen">Teal Green</mat-option>
                  <mat-option value="paleBlue">Pale Blue</mat-option>
                  <mat-option value="custom">Custom</mat-option>
                </mat-select>
              </mat-form-field> -->



                <div class="d-flex flex-column mt-5" *ngIf="!showAllPatient">
                  <div class="d-flex justify-content-between align-items-center">
                    <h2>Confirmed Appointments</h2>

                  </div>

                  <div class="row container-fluid" *ngIf="todayAppointments?.length; else NoAppointments">
                    <mat-card class="col-md-5 col-6 mx-4" style="min-width: 350px"
                      *ngFor="let booking of todayAppointments; let inQueue = index">
                      <div class="d-flex flex-column" align="end">
                        <h3 class="mb-0"> #{{inQueue + 1}} in Queue</h3>
                      </div>
                      <mat-card-header>
                        <mat-card-title>
                          MRN: {{booking?.PatientId}},

                          Patient Name: {{booking?.PatientName}},
                          D.O.B: {{booking?.DateOfBirth}}

                        </mat-card-title>
                        <mat-card-subtitle>
                          <div class="d-flex flex-column">
                            <p class="mb-0">Appointment: {{booking.VisitDate}} {{booking.VisitTime}}</p>
                            <p>Token number: {{booking.Tocken}}</p>
                          </div>
                        </mat-card-subtitle>
                      </mat-card-header>
                      <mat-card-actions align="end">
                        <button mat-raised-button
                          [disabled]="booking.Status != 'Observing' && this.isUnderConsulation.PatientBookingId && this.isUnderConsulation.PatientBookingId != booking.PatientBookingId"
                          color="primary" (click)="complete(booking.PatientBookingId, booking)">Mark as
                          completed</button>
                        <button mat-raised-button
                          [disabled]="booking.Status != 'Observing' && this.isUnderConsulation.PatientBookingId && this.isUnderConsulation.PatientBookingId != booking.PatientBookingId"
                          [color]="booking.Status === 'Observing' ? 'accent' : 'warn' "
                          (click)="occupied(booking.PatientBookingId, booking)">{{ booking.Status === 'Observing' ?
                          'Under Consultation' : 'Consult' }}</button>
                        <a type="button" target="_blank" [routerLink]="['/splitView/', booking.PatientId]"
                          mat-raised-button color="primary"> view Patient
                          <!-- <mat-icon matPrefix>remove_red_eye</mat-icon> -->
                        </a>

                      </mat-card-actions>
                    </mat-card>
                  </div>
                </div>

                <ng-container *ngIf="showAllPatient">
                  <mat-form-field class="example-full-width mt-5">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input />
                  </mat-form-field>
                  <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" class="example-form">
                    <div class="container mt-4">
                      <div class="row ">
                        <div class="col-lg-3">
                          <mat-form-field class="example-full-width">
                            <mat-label>Ward Name</mat-label>
                            <mat-select formControlName="WardId" (selectionChange)="getPatients()">
                              <mat-option *ngFor="let ward of wardList" [value]="ward.WardId">{{
                                ward.WardName }}</mat-option>
                            </mat-select>
                            <mat-error>Select a Ward</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="mat-elevation-z8 container-fluid">
                    <table class="w-100" mat-table [dataSource]="dataSource" matSort>
                      <ng-container matColumnDef="Id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
                        <td mat-cell *matCellDef="let row">{{ row.PatientId }}</td>
                      </ng-container>

                      <ng-container matColumnDef="FirstName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          First Name
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.PatientFirstName }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="LastName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Last Name
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.PatientLastName }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Gender">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Gender
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.PatientGender }}</td>
                      </ng-container>
                      <ng-container matColumnDef="PatientNationalId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          National Id
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.PatientNationalId }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                        <td mat-cell *matCellDef="let row">{{ row.Email }}</td>
                      </ng-container>
                      <ng-container matColumnDef="PhoneNumber">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          PhoneNumber
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.PhoneNumber }}</td>
                      </ng-container>
                      <ng-container matColumnDef="PrimaryDoctor">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Primary Doctor
                        </th>
                        <td mat-cell *matCellDef="let row">
                          {{ row.PrimaryDoctorsName }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="WardName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ward</th>
                        <td mat-cell *matCellDef="let row">{{ row.WardName }}</td>
                      </ng-container>
                      <!-- <ng-container matColumnDef="RoomNo">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                          Room No
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.RoomNo }}</td>
                      </ng-container> -->
                      <ng-container matColumnDef="Detail">
                        <th mat-header-cell *matHeaderCellDef>Office Visit Encounter</th>
                        <td mat-cell *matCellDef="let row">
                          <a type="button" target="_blank" [routerLink]="['/splitView/', row.PatientId]" mat-mini-fab
                            color="primary">
                            <mat-icon matPrefix>remove_red_eye</mat-icon>
                          </a>
                          <!-- <td mat-cell *matCellDef="let row">
                        <a type="button" target="_blank" [routerLink]="['/allPatient/', row.PatientId]" mat-mini-fab
                          color="primary">
                          <mat-icon matPrefix>remove_red_eye</mat-icon>
                        </a> -->
                        </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                      <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">
                          No data matching the filter "{{ input.value }}"
                        </td>
                      </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </mat-tab>
          <!-- </div>
      </span> -->
          <!-- </div> -->
          <!-- </div> -->

          <ng-template #NoAppointments>
            <center>
              <h2 class="text-muted mt-3">No Confirmed Appointments.</h2>
            </center>
          </ng-template>

          <!-- </mat-tab> -->

          <mat-tab label="Todays Apppointment">
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">calendar_today</mat-icon>
              <span class="ml-2 mt-1">Todays Apppointment
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 1">

              <app-view-doctor-appointments-today></app-view-doctor-appointments-today>
            </ng-container>

          </mat-tab>
          <mat-tab label="All Apppointments">

            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">book_online</mat-icon>
              <span class="ml-2 mt-1">All Apppointments
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 2">
              <app-view-doctor-appointments></app-view-doctor-appointments>
            </ng-container>
          </mat-tab>
          <mat-tab label=" My Pateints">

            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">groups</mat-icon>
              <span class="ml-2 mt-1"> My Pateints
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 3">
              <app-view-doctor-patients></app-view-doctor-patients>
            </ng-container>

          </mat-tab>
          <!-- <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">person</mat-icon>
              <span class="ml-2 mt-1"> My Profile
              </span>
            </ng-template>
            <app-view-doctor-profile></app-view-doctor-profile>
    
    
    
          </mat-tab> -->
          <mat-tab label="Refill Requests">

            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">medication</mat-icon>
              <span class="ml-2 mt-1"> Refill Requests
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 4">

              <app-view-refill-request></app-view-refill-request>
            </ng-container>
          </mat-tab>
          <mat-tab label="Nurse Requests">

            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">medication</mat-icon>
              <span class="ml-2 mt-1"> Prescription Requests-Nurse
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 5">

              <app-view-prescription-requested-by-nurse></app-view-prescription-requested-by-nurse>
            </ng-container>
          </mat-tab>
          <mat-tab label="My Invoices">

            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">document</mat-icon>
              <span class="ml-2 mt-1">My Invoices
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 6">

              <app-doctor-patient-invoices></app-doctor-patient-invoices>
            </ng-container>
          </mat-tab>
          <mat-tab label="Chat Message">

            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">chat</mat-icon>
              <span class="ml-2 mt-1"> Chat
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 7 ">

              <app-chat></app-chat>
            </ng-container>
          </mat-tab>
          <!-- <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">visibility</mat-icon>
              <span class="ml-2 mt-1"> Favourite Template
              </span>
            </ng-template>
            <app-doctor-custom-template></app-doctor-custom-template>
    
          </mat-tab> -->
          <mat-tab label="New Template">

            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">add</mat-icon>
              <span class="ml-2 mt-1"> New Template
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 8 ">

              <app-doctor-template-editor></app-doctor-template-editor>
            </ng-container>
          </mat-tab>
          <mat-tab label="View Template">

            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">grading</mat-icon>
              <span class="ml-2 mt-1"> View Template
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 9 ">

              <app-doctor-template-list></app-doctor-template-list>
            </ng-container>
          </mat-tab>

          <mat-tab label="View Template">

            <ng-template mat-tab-label>
              <mat-icon class="example-tab-icon">grading</mat-icon>
              <span class="ml-2 mt-1"> Global Template
              </span>
            </ng-template>
            <ng-container *ngIf="selectedTabIndex === 10 ">

              <app-view-hospitaltemplate></app-view-hospitaltemplate>

            </ng-container>
          </mat-tab>


        </mat-tab-group>
      </div>
    </span>
  </div>
  <!-- <qrcode *ngIf="DoctorId" [qrdata]="DoctorId" [width]="256" [errorCorrectionLevel]="'M'"></qrcode> -->