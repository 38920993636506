import { Component } from '@angular/core';

@Component({
  selector: 'app-update-favorite-prescription',
  templateUrl: './update-favorite-prescription.component.html',
  styleUrls: ['./update-favorite-prescription.component.css']
})
export class UpdateFavoritePrescriptionComponent {

}
