import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhotographicConsent } from 'src/app/Models/photographicconsent.model';
import { PatientService } from 'src/app/services/patient.service';
import { PhotographicConsentService } from 'src/app/services/photographic-consent.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-patient-view-photographic-consent',
  templateUrl: './patient-view-photographic-consent.component.html',
  styleUrls: ['./patient-view-photographic-consent.component.css'],
})
export class PatientViewPhotographicConsentComponent implements OnInit {
  id = 0;
  PhotographicConsentList: PhotographicConsent[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private photographicConsentService: PhotographicConsentService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPhotographicConsent(this.id);
  }

  getPhotographicConsent(id) {
    this.patientService.GetPhotographicConsent(id).subscribe((res) => {
      this.PhotographicConsentList = res.PhotographicConsents;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.photographicConsentService
      .deletePhotographicConsent(id)
      .subscribe((res) => {
        this.getPhotographicConsent(this.id);
      });
  }
}
