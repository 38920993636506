import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { JwtHelperService } from '@auth0/angular-jwt';
import moment from 'moment';
import { Observable } from 'rxjs';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorDate } from 'src/app/Models/doctordate.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientBookedSlot } from 'src/app/Models/patientbookedslot.model';
import { TimeSlot } from 'src/app/Models/timeslot.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { EmailService } from 'src/app/services/email.service';
import { HospitalService } from 'src/app/services/hospital.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-my-off-days',
  templateUrl: './view-my-off-days.component.html',
  styleUrls: ['./view-my-off-days.component.css'],
})
export class ViewMyOffDaysComponent implements OnInit {
  helper = new JwtHelperService();
  id = 0;
  myToken = this.tokenService.getToken();
  options: string[] = ['New Patient', 'Follow Up'];
  doctorSpeciality: string[] = [
    'Allergy and Immunology',
    'Anesthesiology',
    'Cardiology',
    'Critical Care Medicine',
    'Colon and Rectal Surgery',
    'Dermatology',
    'Emergency Medicine',
    'Family Medicine',
    'Forensic Pathology',
    'Gastroenterology',
    'General Medicine',
    'Geriatric Medicine',
    'General Surgery',
    'Genetics and Genomics',
    'Hospice and Palliative Medicine',
    'Hospital Medicine',
    'General Internal Medicine',
    'General Surgery',
    'Neurology',
    'Neurological Surgery',
    'Obstetrics and Gynecology',
    'Ophthalmic Surgery',
    'Orthopaedic Surgery',
    'Otolaryngology',
    'Occupational Medicine',
    'Ophthalmology',
    'Orthopedic Surgery',
    'Pathology',
    'Pediatrics',
    'Physical Medicine and Rehabilitation',
    'Plastic Surgery',
    'Preventive Medicine',
    'Psychiatry',
    'Radiology',
    'Respirology',
    'Rheumatology',
    'Sleep Medicine',
    'Thoracic Surgery',
    'Urology',
    'Urology',
    'Cardiovascular Surgery',
    'Spinal Surgeon',
  ];
  doctorList: Doctor[] = [];

  @Output() showAppointmentList = new EventEmitter<string>();

  TimeSltId: any;
  DateId: any;
  DrId = 0;
  Codes: any[] = [];

  t = ['08:00 AM', '08:00 AM'];

  takenSlots: any;
  timeSlots: TimeSlot[] = [];
  UnAvailableDates: DoctorDate[] = [];
  BookedTimeSlots: PatientBookedSlot[] = [];
  DoctorAvalableDateList: DoctorDate[] = [];
  selectedItem;
  selectedSlot;
  selectedDate: DoctorDate;
  isSuccess = false;
  filteredOptions: Observable<string[]>;
  patient: Patient;
  patientId = 0;
  hospitalId: number;
  constructor(
    private appointmentService: AppointmentService,
    private fb: UntypedFormBuilder,
    private doctorService: DoctorService,

    private emailService: EmailService,
    private http: HttpClient,

    private hospitalService: HospitalService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);

    this.doctorService.getDoctorHospital(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getDoctors(this.hospitalId);
    });
  }

  unAvailable(date: Date): boolean {
    if (this.UnAvailableDates) {
      const unAvailableDates = this.UnAvailableDates.filter(
        (date) => date.AvailableDate
      ).map((d) => new Date(d.AvailableDate).toISOString().slice(0, 10));
      const currentDate = date.toISOString().slice(0, 10);

      return (
        !unAvailableDates?.includes(currentDate) &&
        moment(date).valueOf() >= moment().startOf('day').valueOf() &&
        this.disableWeekend(date)
      );
    }
    return true;
  }

  boundDateStart = this.unAvailable.bind(this);

  addVisitFrm = this.fb.group({
    DoctorName: [''],
    DoctorSpecialization: ['', Validators.required],

    DoctorId: ['', Validators.required],
    DoctorDateId: [],
    NotificationConsent: [null, Validators.required],
  });

  get visitForm() {
    return this.addVisitFrm.controls;
  }

  get DoctorDateId() {
    return this.addVisitFrm.get('DoctorDateId');
  }
  get DoctorId() {
    return this.addVisitFrm.get('DoctorId');
  }

  get Doctor() {
    return this.addVisitFrm.get('DoctorName');
  }

  get DoctorSpecialization() {
    return this.addVisitFrm.get('DoctorSpecialization');
  }

  datePickerDisabled = true;

  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res.Doctors;
    });
  }

  getTimeSlots() {
    this.doctorService
      .getDoctorAvailableDates(this.DoctorId.value)
      .subscribe((res) => {
        this.UnAvailableDates = res.DoctorDates;
        this.datePickerDisabled = false;
      });
  }

  disableWeekend(d) {
    if (d.getDay() != 0 && d.getDay() != 6) {
      return d;
    }
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getDoctors(this.id);
    }
  }

  formSubmit() {}
}
