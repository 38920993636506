import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Doctor } from 'src/app/Models/doctor.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicineService } from 'src/app/services/medicine.service';
import { PrescriptionService } from 'src/app/services/prescription.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-prescription-array',
  templateUrl: './add-prescription-array.component.html',
  styleUrls: ['./add-prescription-array.component.css'],
})
export class AddPrescriptionArrayComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  states;
  isSuccess = false;
  doctor: Doctor;
  QTimes: string[] = [
    'Q1-Every Hour',
    'Q2-Every 2 hours',
    'Q3-Every 3 hours',
    'Q4-Every 4 hours',
    'Q5-Every 5 hours',
    'Q4-Every 6 hours',
    'Q4-Every 6 hours',
    'Q8-Every 8 hours',
    'Q12-Every 12 hours',
  ];

  DrugsList: string[] = [
    'Calpol 200mg',
    'Paracetamol 200mg',
    'Co-codomol 200mg',
    'Ibuprofen 200mg',
  ];

  prescriptionFrm: FormGroup;
  medicineList: Medicine[];
  constructor(
    private fb: UntypedFormBuilder,
    private prescriptionService: PrescriptionService,
    private router: Router,
    private route: ActivatedRoute,
    private medicineService: MedicineService,
    private doctorService: DoctorService,
    private toastr: ToastrService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);

    this.getMedicine();
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.doctorService.getDoctorById(this.doctorId).subscribe((res) => {
      this.doctor = res;
    });

    this.prescriptionFrm = this.fb.group({
      PatientId: this.id,
      DoctorId: this.doctorId,
      DoctorSignature: [''],
      Prescription: this.fb.array([]),
    });
    this.addItem();
  }

  public addItem(): void {
    const item = this.fb.group({
      DrugName: ['', Validators.required],
      Strength: ['', Validators.required],
      BrandName: [''],
      Quantity: ['', Validators.required],
      DatePrescribed: ['', Validators.required],
      Instruction: ['', Validators.required],
      MorningTime: [''],
      AfternoonTime: [''],
      EveningTime: [''],
      NightTime: [''],
      QTime: [''],
      Status: [true],
      StartDate: [true],
      EndDate: [true],
      RefillDuration: [''],
      PatientId: this.id,
      DoctorId: this.doctorId,
      DoctorSignature: [''],
    });
    this.Prescription.push(item);
  }

  public removeItem(i: number): void {
    this.Prescription.removeAt(i);
  }

  get Prescription(): UntypedFormArray {
    return this.prescriptionFrm.get('Prescription') as UntypedFormArray;
  }

  get RefillDuration() {
    return this.prescriptionFrm.get('RefillDuration');
  }

  get DoctorName() {
    return this.prescriptionFrm.get('DoctorName');
  }

  get StartDate() {
    return this.prescriptionFrm.get('StartDate');
  }

  get EndDate() {
    return this.prescriptionFrm.get('EndDate');
  }

  get Status() {
    return this.prescriptionFrm.get('Status');
  }
  get MorningTime() {
    return this.prescriptionFrm.get('MorningTime');
  }
  get AfternoonTime() {
    return this.prescriptionFrm.get('AfternoonTime');
  }
  get EveningTime() {
    return this.prescriptionFrm.get('EveningTime');
  }
  get NightTime() {
    return this.prescriptionFrm.get('NightTime');
  }
  get QTime() {
    return this.prescriptionFrm.get('QTime');
  }
  get DoctorId() {
    return this.prescriptionFrm.get('DoctorId');
  }
  get DoctorSignature() {
    return this.prescriptionFrm.get('DoctorSignature');
  }
  get Quantity() {
    return this.prescriptionFrm.get('Quantity');
  }
  get DrugName() {
    return this.prescriptionFrm.get('DrugName');
  }
  get Dosage() {
    return this.prescriptionFrm.get('Strength');
  }

  get BrandName() {
    return this.prescriptionFrm.get('BrandName');
  }
  get DatePrescribed() {
    return this.prescriptionFrm.get('DatePrescribed');
  }

  get Instruction() {
    return this.prescriptionFrm.get('Instruction');
  }

  getMedicine() {
    this.medicineService.getMedicines().subscribe((res) => {
      this.medicineList = res;
    });
  }

  formSubmit() {
    // if (this.prescriptionFrm.valid) {

    // } else {
    //   alert('Form is not valid ');
    // }

    let prescriptions: Prescription[] = this.prescriptionFrm.get('Prescription')
      ?.value as Prescription[];

    this.prescriptionService
      .saveMultiplePrescriptions(prescriptions)
      .subscribe((res) => {
        this.isSuccess = true;
        this.prescriptionFrm.reset();
        alert('Form submited with success');
      });
  }
}
