import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { AppointmentService } from '../../services/appointment.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { DoctorDate } from 'src/app/Models/doctordate.model';
import { PatientBookedSlot } from 'src/app/Models/patientbookedslot.model';
import { TimeSlot } from 'src/app/Models/timeslot.model';
import { BookedslotsService } from 'src/app/services/bookedslots.service';
import { DoctordatesService } from 'src/app/services/doctordates.service';
import { EmailService } from 'src/app/services/email.service';
import { TimeslotService } from 'src/app/services/timeslot.service';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from 'src/app/Models/patient.model';
import { HospitalService } from 'src/app/services/hospital.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-appointment-hospital',
  templateUrl: './add-appointment-hospital.component.html',
  styleUrls: ['./add-appointment-hospital.component.css'],
})
export class AddAppointmentHospitalComponent implements OnInit {
  helper = new JwtHelperService();
  id = 0;
  myToken = this.tokenService.getToken();
  // options: string[] = ['New Patient', 'Follow Up'];
  options: string[] = ['In Person Visit', 'TeleVisit'];

  doctorSpeciality: string[] = [
    'Allergy and Immunology',
    'Anesthesiology',
    'Cardiology',
    'Critical Care Medicine',
    'Colon and Rectal Surgery',
    'Dermatology',
    'Emergency Medicine',
    'Family Medicine',
    'Forensic Pathology',
    'Gastroenterology',
    'General Medicine',
    'Geriatric Medicine',
    'General Surgery',
    'Genetics and Genomics',
    'Hospice and Palliative Medicine',
    'Hospital Medicine',
    'General Internal Medicine',
    'General Surgery',
    'Neurology',
    'Neurological Surgery',
    'Obstetrics and Gynecology',
    'Ophthalmic Surgery',
    'Orthopaedic Surgery',
    'Otolaryngology',
    'Occupational Medicine',
    'Ophthalmology',
    'Orthopedic Surgery',
    'Pathology',
    'Pediatrics',
    'Physical Medicine and Rehabilitation',
    'Plastic Surgery',
    'Preventive Medicine',
    'Psychiatry',
    'Radiology',
    'Respirology',
    'Rheumatology',
    'Sleep Medicine',
    'Thoracic Surgery',
    'Urology',
    'Urology',
    'Cardiovascular Surgery',
    'Spinal Surgeon',
  ];
  doctorList: Doctor[] = [];

  @Output() showAppointmentList = new EventEmitter<string>();

  TimeSltId: any;
  DateId: any;
  DrId = 0;
  Codes: any[] = [];

  t = ['08:00 AM', '08:00 AM'];

  takenSlots: any;
  timeSlots: TimeSlot[] = [];
  UnAvailableDates: DoctorDate[] = [];
  BookedTimeSlots: PatientBookedSlot[] = [];
  DoctorAvalableDateList: DoctorDate[] = [];
  selectedItem;
  selectedSlot;
  selectedDate: DoctorDate;
  isSuccess = false;
  filteredOptions: Observable<string[]>;
  patient: Patient;
  patientId = 0;
  UnAvailableSlots: PatientBookedSlot[];
  spciality: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService,
    private fb: UntypedFormBuilder,
    private doctorService: DoctorService,
    private timeSlotService: TimeslotService,
    private bookedslotsService: BookedslotsService,
    private doctorDateService: DoctordatesService,
    private emailService: EmailService,
    private http: HttpClient,
    private patientService: PatientService,
    private hospitalService: HospitalService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    // console.log('User ID from Modal:', this.data.userId);
    this.patientId = this.data.userId;
    this.createTimeSlots();
    // this.route.params.subscribe((res) => {
    //   this.patientId = res['id'];
    // });

    this.getDoctors(this.id);
    this.getPatient(this.patientId);
    this.countryCodes();
    this.filteredOptions =
      this.addVisitFrm.controls.CountryCode.valueChanges.pipe(
        startWith(''),
        map((value: string) => this._filter(value || ''))
      );
  }

  createTimeSlots() {
    const TimeSlots = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let min = 0; min < 60; min = min + 15) {
        if (hours > 8 && hours < 18) {
          const minStr = String(min).padStart(2, '0');
          const isAm = hours < 12 || hours == 0;
          const formattedTime = `${hours % 12 ? hours % 12 : 12}:${minStr} ${
            isAm ? 'am' : 'pm'
          }`;
          TimeSlots.push(formattedTime);
        }
      }
    }
    return TimeSlots;
  }

  unAvailable(date: Date): boolean {
    if (this?.UnAvailableDates) {
      const unAvailableDates = this.UnAvailableDates.filter(
        (date) => date?.AvailableDate
      ).map((d) => new Date(d?.AvailableDate).toISOString().slice(0, 10));
      const currentDate = date?.toISOString().slice(0, 10);
      return (
        !unAvailableDates?.includes(currentDate) &&
        moment(date).valueOf() >= moment().startOf('day').valueOf()
      );
    }
    return true;
  }

  boundDateStart = this.unAvailable.bind(this);

  addVisitFrm = this.fb.group({
    DoctorName: [''],
    DoctorSpecialization: ['', Validators.required],
    VisitType: ['', Validators.required],
    VisitDate: ['', Validators.required],
    VisitTime: [''],
    CountryCode: ['+1', Validators.required],
    PhoneNumber: ['', Validators.required],
    Message: ['', Validators.required],
    DoctorId: ['', Validators.required],
    DoctorDateId: [],
    NotificationConsent: [null, Validators.required],
  });

  get visitForm() {
    return this.addVisitFrm.controls;
  }

  get DoctorDateId() {
    return this.addVisitFrm.get('DoctorDateId');
  }
  get DoctorId() {
    return this.addVisitFrm.get('DoctorId');
  }
  get VisitType() {
    return this.addVisitFrm.get('VisitType');
  }
  get Doctor() {
    return this.addVisitFrm.get('DoctorName');
  }

  get DoctorSpecialization() {
    return this.addVisitFrm.get('DoctorSpecialization');
  }

  get VisitDate() {
    return this.addVisitFrm.get('VisitDate');
  }
  get VisitTime() {
    return this.addVisitFrm.get('VisitTime');
  }

  get Message() {
    return this.addVisitFrm.get('Message');
  }
  get PhoneNumber() {
    return this.addVisitFrm.get('PhoneNumber');
  }

  datePickerDisabled = true;

  getDoctors(id) {
    this.hospitalService.getDoctors(id).subscribe((res) => {
      this.doctorList = res?.Doctors;
    });
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  getTimeSlots() {
    this.doctorService
      .getDoctorBookedSlots(this.DoctorId.value)
      .subscribe((res) => {
        this.UnAvailableDates = res?.DoctorDates;
        this.datePickerDisabled = false;
      });
  }
  getSpeciality() {
    this.doctorService.getDoctorById(this.DoctorId.value).subscribe((res) => {
      this.spciality = res.DoctorSpecialization;
      this.DoctorSpecialization.setValue(res.DoctorSpecialization);
    });
  }
  disableWeekend(d) {
    if (d.getDay() != 0 && d.getDay() != 6) {
      return d;
    }
  }
  formSubmit() {
    let doctorName = this.addVisitFrm.value['DoctorName'];
    let doctorSpecialization = this.addVisitFrm.value['DoctorSpecialization'];
    let visitType = this.addVisitFrm.value['VisitType'];
    let date = this.addVisitFrm.value['VisitDate'];
    date = moment(date).format('MM/DD/YYYY');

    let phoneNumber =
      this.addVisitFrm.value['CountryCode'] +
      this.addVisitFrm.value['PhoneNumber'];
    let time = this.addVisitFrm.value['VisitTime'];
    let message = this.addVisitFrm.value['Message'];
    let doctorDateId = this.addVisitFrm.value['DoctorDateId'];
    let doctorId = this.addVisitFrm.value['DoctorId'];
    let idoctorName = this.doctorList.filter((it) => it.DoctorId == doctorId)[0]
      .DoctorName;

    let body = {
      PatientId: this.patientId,
      DoctorName: idoctorName,
      DoctorId: doctorId,
      DoctorSpecialization: doctorSpecialization,
      VisitType: visitType,
      VisitDate: date,
      VisitTime: this.selectedSlot,
      BookedSlots: this.selectedSlot,
      Message: message,
      Status: 'not confirmed',
      PhoneNumber: phoneNumber,
      Email: this.patient.Email,
    };
    this.appointmentService.registerPatientBooking(body).subscribe({
      next: (res) => {
        // this.isSuccess = true;

        this.emailService
          .sendEmail({
            Email: this.patient.Email,
            Subject: 'Appointment',
            Template: `<h3>Your appointment is booked with ${res.DoctorName} ${res.DoctorSpecialization} on ${res.VisitDate} at ${res.VisitTime} </h3> `,
          })
          .subscribe();

        const smsBodyDetails =
          'Appointment is booked with ' +
          res.DoctorName.replace('.', ' ') +
          ' ' +
          res.DoctorSpecialization +
          ' on ' +
          res.VisitDate +
          ' at ' +
          res.VisitTime +
          ' ';
        let smsBody = {
          PhoneNumber: phoneNumber,
          Body: smsBodyDetails,
        };
        this.emailService.sendSms(smsBody).subscribe();
        this.isSuccess = true;
        this.addVisitFrm.reset();

        this.selectedSlot = '';
        setTimeout(() => {
          this.showAppointmentList.emit('4');
        }, 2000);
      },
    });
  }

  public checkForTimeSlots(selectedDate) {
    const today = selectedDate.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    const bookedSlots = this.appointmentService.getPatientBookings();
    bookedSlots.subscribe({
      next: (res) => {
        this.timeSlots = this.createTimeSlots();
        this.takenSlots = res
          .filter(
            (x) =>
              x.BookedSlots &&
              x.VisitDate == today &&
              x.DoctorId == this.DoctorId.value
          )
          .map((y) => y.BookedSlots);
      },
    });
  }

  public selectSlot(selectedSlot): void {
    this.selectedSlot = selectedSlot;
  }
  public selectDate(selectedDate): void {
    this.checkForTimeSlots(selectedDate.value);
  }

  public selectTime() {
    this.selectedSlot = '';
  }

  public countryCodes() {
    this.http.get('/assets/json/country-codes.json').subscribe({
      next: (res: any) => {
        this.Codes = res.map((x) => x.dial_code);
      },
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.Codes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
