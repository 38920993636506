import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map } from 'jquery';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { Doctor } from 'src/app/Models/doctor.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { MedicineService } from 'src/app/services/medicine.service';
import { PrescriptionService } from 'src/app/services/prescription.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-prescription-free-text',
  templateUrl: './add-prescription-free-text.component.html',
  styleUrls: ['./add-prescription-free-text.component.css'],
})
export class AddPrescriptionFreeTextComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  states;
  isSuccess = false;
  doctor: Doctor;
  flteredmedicineList: Observable<any>;

  medicineList: Medicine[];

  twiceValue: number;
  refillValue: number;
  frequencyValue: number;
  tabLets: number;
  constructor(
    private fb: UntypedFormBuilder,
    private prescriptionService: PrescriptionService,
    private router: Router,
    private route: ActivatedRoute,
    private medicineService: MedicineService,
    private doctorService: DoctorService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);

    this.getMedicine();
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.doctorService.getDoctorById(this.doctorId).subscribe((res) => {
      this.doctor = res;
      this.DoctorName.setValue(res.DoctorName);
    });
  }

  prescriptionFrm = this.fb.group({
    DrugName: ['', Validators.required],
    Strength: [''],
    BrandName: [''],
    Quantity: [''],
    DatePrescribed: [''],
    Instruction: [''],
    MorningTime: [''],
    AfternoonTime: [''],
    EveningTime: [''],
    NightTime: [''],
    QTime: [''],
    Status: [true],
    StartDate: new FormControl(new Date().toISOString().substring(0, 10)),
    EndDate: [''],
    RefillDuration: [''],
    Numberofrefills: [''],
    DoctorName: [''],
    Note: [''],
    Route: [''],
    Frequency: [''],
    NoOfTablets: [''],
  });

  get NoOfTablets() {
    return this.prescriptionFrm.get('NoOfTablets');
  }
  get Frequency() {
    return this.prescriptionFrm.get('Frequency');
  }
  get Route() {
    return this.prescriptionFrm.get('Route');
  }
  get Numberofrefills() {
    return this.prescriptionFrm.get('Numberofrefills');
  }
  get RefillDuration() {
    return this.prescriptionFrm.get('RefillDuration');
  }

  get DoctorName() {
    return this.prescriptionFrm.get('DoctorName');
  }

  get StartDate() {
    return this.prescriptionFrm.get('StartDate');
  }

  get EndDate() {
    return this.prescriptionFrm.get('EndDate');
  }

  get Status() {
    return this.prescriptionFrm.get('Status');
  }
  get MorningTime() {
    return this.prescriptionFrm.get('MorningTime');
  }
  get AfternoonTime() {
    return this.prescriptionFrm.get('AfternoonTime');
  }
  get EveningTime() {
    return this.prescriptionFrm.get('EveningTime');
  }
  get NightTime() {
    return this.prescriptionFrm.get('NightTime');
  }
  get QTime() {
    return this.prescriptionFrm.get('QTime');
  }
  get DoctorId() {
    return this.prescriptionFrm.get('DoctorId');
  }
  get DoctorSignature() {
    return this.prescriptionFrm.get('DoctorSignature');
  }
  get Quantity() {
    return this.prescriptionFrm.get('Quantity');
  }
  get DrugName() {
    return this.prescriptionFrm.get('DrugName');
  }
  get Strength() {
    return this.prescriptionFrm.get('Strength');
  }

  get BrandName() {
    return this.prescriptionFrm.get('BrandName');
  }
  get DatePrescribed() {
    return this.prescriptionFrm.get('DatePrescribed');
  }

  get Instruction() {
    return this.prescriptionFrm.get('Instruction');
  }

  get Note() {
    return this.prescriptionFrm.get('Note');
  }

  getMedicine() {
    this.medicineService.getMedicines().subscribe((res) => {
      this.medicineList = res;
    });
  }
  getDoctor(id) {
    this.doctorService.getDoctorById(id).subscribe((res) => {
      this.doctor = res;
    });
  }

  formSubmit() {
    let body = {
      ...this.prescriptionFrm.value,
      PatientId: this.id,
      DoctorId: this.doctorId,
    };
    this.prescriptionService.registerPrescription(body).subscribe((res) => {
      this.isSuccess = true;
      this.prescriptionFrm.reset();
      location.reload();
    });
  }
}
