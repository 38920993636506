<div *ngIf="isLoading" class="spinner-container">
    <p>Loading audit logs...</p> <!-- You can add a spinner or loading animation here -->
</div>

<div *ngIf="!isLoading && auditLogs.length === 0">
    <p>No audit logs found.</p>
</div>

<table *ngIf="!isLoading && auditLogs.length > 0" class="table table-striped">
    <thead>
        <tr>
            <th>Action</th>
            <th>Entity</th>
            <th>Entity ID</th>
            <th>Changes</th>
            <th>User</th>
            <th>Date</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let log of auditLogs">
            <td>{{ log.action }}</td>
            <td>{{ log.entityName }}</td>
            <td>{{ log.entityId }}</td>
            <td>{{ log.changes }}</td>
            <td>{{ log.userName }}</td>
            <td>{{ log.timestamp | date: 'short' }}</td>
        </tr>
    </tbody>
</table>