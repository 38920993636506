<div class="ml-3">
  <div class="row">
    <div class="col-lg-6 float-right">
      <mat-form-field class="example-full-width mt-5">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
      </mat-form-field>
    </div>
  </div>
</div>
<div class=" mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button type="button" (click)="copyRowData(row)">
          <mat-icon>content_copy</mat-icon>
        </button>
      </td>
    </ng-container>


    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef> Date </th>
      <td mat-cell *matCellDef="let row"> {{row.createdDate | date:'medium'}} </td>
    </ng-container>
    <ng-container matColumnDef="ReportName">
      <th mat-header-cell *matHeaderCellDef> Report Name </th>
      <td mat-cell *matCellDef="let row"> {{row.ReportName}} </td>
    </ng-container>
    <ng-container matColumnDef="shortcut">
      <th mat-header-cell *matHeaderCellDef> shortcut </th>
      <td mat-cell *matCellDef="let row"> {{row.shortcut}} </td>
    </ng-container>

    <ng-container matColumnDef="DoctorName">
      <th mat-header-cell *matHeaderCellDef> Physician </th>
      <td mat-cell *matCellDef="let row"> {{row.DoctorName}} </td>
    </ng-container>
    <!-- <ng-container matColumnDef="Decription">
      <th mat-header-cell *matHeaderCellDef> Decription </th>
      <td mat-cell *matCellDef="let row" [innerHTML]="row.ReportDescription"> </td>
    </ng-container>  -->

    <ng-container matColumnDef="Signature">
      <th mat-header-cell *matHeaderCellDef> Signed </th>
      <td mat-cell *matCellDef="let row"> {{row.Status?'Yes':'No'}} </td>
    </ng-container>


    <ng-container matColumnDef="View">
      <th mat-header-cell *matHeaderCellDef> View </th>
      <td mat-cell *matCellDef="let row">
        <button class="btn mybtn" (click)="modal1.show();viewReport(row) " mat-mini-fab
          color="primary"><mat-icon>visibility</mat-icon></button>&nbsp;

        <app-modal id="printable" #modal1 class="panel-demo1" [maximizable]="true" [backdrop]="false">
          <ng-container class="app-modal-header">Patient Name: {{patient?.PatientFirstName}} &nbsp;
            {{patient?.PatientLastName}}</ng-container>
          <ng-container class="app-modal-body">
            <app-view-hospital></app-view-hospital>

            <div class="areaStyle mb-1">
              <P>Patient ID: {{patient?.PatientId}} </P>

              <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
              <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
              <P>Patient Address: {{patient?.PatientAddress}} </P>

            </div>
            <p>Doctor Name: {{row.DoctorName}}</p>

            <p>Report Id: {{row.DoctorReportId}}</p>
            <p>Report Name: {{row.ReportName}}</p>

            <!-- <p>Decription: {{reportDetails}}</p> -->
            <div class="mt-2" [innerHTML]="reportDetails"></div>
            <div class="row mt-5">
              <div class="col-lg-6">
                <div class="row">
                  <apx-chart [series]="heartRateChart.series" [chart]="heartRateChart.chart"
                    [xaxis]="heartRateChart.xAxis" [title]="heartRateChart.title"></apx-chart>
                </div>

                <div class="row">
                  <apx-chart [series]="respirationChart.series" [chart]="respirationChart.chart"
                    [xaxis]="respirationChart.xAxis" [title]="respirationChart.title"></apx-chart>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <apx-chart [series]="tempratureChart.series" [chart]="tempratureChart.chart"
                    [xaxis]="tempratureChart.xAxis" [title]="tempratureChart.title"></apx-chart>
                </div>
                <div class="row" *ngIf="bloodPressureChart.xAxis?.categories?.length > 0">
                  <apx-chart [series]="bloodPressureChart.series" [chart]="bloodPressureChart.chart"
                    [xaxis]="bloodPressureChart.xAxis" [title]="bloodPressureChart.title"></apx-chart>
                </div>
              </div>
            </div>
            <div class="row">
              <apx-chart [series]="OxygenSaturationChart.series" [chart]="OxygenSaturationChart.chart"
                [xaxis]="OxygenSaturationChart.xAxis" [title]="OxygenSaturationChart.title"></apx-chart>
            </div>
            <!-- mri Images start-->
            <div *ngIf="templatesRadiologyStudyReports && templatesRadiologyStudyReports.length > 0">
              <h3>Most Recent Radiology Reports</h3>
              <div *ngFor="let report of templatesRadiologyStudyReports">
                <div class="report">
                  <h4>Report Id: {{ report.UploadRadiologyReportId }}</h4> <!-- Replace with actual field name -->
                  <p>Date Uploaded: {{ report.createdDate | date:'short' }}</p> <!-- Replace with actual date field -->


                  <img [src]="report.StudyReport" alt="Radiology Image" *ngIf="report.StudyReport" />
                  <!-- Replace with actual image URL field -->
                  <p *ngIf="!report.StudyReport">No Study image available</p>
                </div>
              </div>
            </div>

            <div *ngIf="templatesRadiologyStudyReports.length === 0">
              <p>No Study reports available.</p>
            </div>

            <!-- mri Images end -->
            <!-- lab report start-->
            <div *ngIf="templatesLabReportsUploaded && templatesLabReportsUploaded.length > 0">
              <h3>Most Recent Radiology Reports</h3>
              <div *ngFor="let labreport of templatesLabReportsUploaded">
                <div class="report">
                  <h4>Report Id: {{ labreport.UploadLabReportId }}</h4>
                  <p>Date Uploaded: {{ labreport.createdDate | date:'short' }}</p>

                  <div [innerHTML]="labreport.LabReportImage"></div>
                  <p *ngIf="!labreport.LabReportImage">No image available</p>
                </div>
              </div>
            </div>

            <div *ngIf="templatesLabReportsUploaded.length === 0">
              <p>No Lab reports available.</p>
            </div>

            <!-- lab report end-->

            <p class="mt-5">Reported By: {{row.DoctorName}}</p>
            <p>Date: {{row.createdDate | date:'short'}}</p>

          </ng-container>
          <ng-container class="app-modal-footer">
            <button type="button" (click)="print()" class="btn btn-primary ">Print</button>

            <button class="dt-button dt-blue" style="float: right;" (click)="modal1.hide()">Close</button>
          </ng-container>
        </app-modal>

      </td>
    </ng-container>
    <!-- modal  -->


    <ng-container matColumnDef="Update">
      <th mat-header-cell *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let row">
        <button type="button" (click)="updateReport(row.DoctorReportId,$event)" mat-mini-fab
          [disabled]="CurrentDoctorId != row.DoctorId" color="mute">
          <mat-icon matPrefix>edit</mat-icon>
        </button>
        <!-- <button type="button" [routerLink]="['/updateDoctorReport/',row.DoctorReportId,this.id]" mat-mini-fab
          [disabled]="CurrentDoctorId != row.DoctorId" color="mute">
          <mat-icon matPrefix>edit</mat-icon>
        </button> -->
      </td>
    </ng-container>
    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef> Delete</th>
      <td mat-cell *matCellDef="let row">
        <button (click)="delete(row.DoctorReportId)" [disabled]="CurrentDoctorId != row.DoctorId" mat-mini-fab
          color="accent"><mat-icon>delete</mat-icon></button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100,150,200,500,750,1000]"></mat-paginator>
</div>