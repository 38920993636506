import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NerveStudyResult } from 'src/app/Models/nervestudyresult.model';
import { NerveStudyResultService } from 'src/app/services/nerve-study-result.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-nerve-study-results',
  templateUrl: './view-nerve-study-results.component.html',
  styleUrls: ['./view-nerve-study-results.component.css'],
})
export class ViewNerveStudyResultsComponent implements OnInit {
  id = 0;
  date: string;
  studyResult: NerveStudyResult[] = [];
  constructor(
    private patientService: PatientService,
    private nerveStudyResult: NerveStudyResultService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  , private tokenService: TokenService) {}
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    // this.date = new Date(moment().format('MM/DD/YYYY')).toISOString()
    this.date = this.datePipe.transform(new Date(), 'MM/dd/YYYY');
    this.getNerveStudyResult(this.id);
  }

  getNerveStudyResult(id) {
    this.patientService.GetnerveStudyResult(id).subscribe((res) => {
      this.studyResult = res.NerveStudyResults.sort(function (x, y) {
        return y.NerveStudyResultId - x.NerveStudyResultId;
      });
    });
  }

  delete(id) {
    this.nerveStudyResult.deleteNerveStudyResult(id).subscribe((res) => {
      this.getNerveStudyResult(this.id);
    });
  }
}
