<div class="container ">
    <h2 class="mt-4">Disclosure and Consent Medical and Surgical Procedures</h2>
    <mat-horizontal-stepper [linear]="'true'" #stepper color="primary">
        <div class="row">

            <div class="col-lg regFrm mat-elevation-z8">
                <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" class="example-form">
                    <mat-step label="Disclosure and Consent Page-1">
                        <p>To The Patient: You have the rght, as a patient to be informed about
                        </p>
                        <ul>
                            <li>1. Your condition</li>
                            <li>1. The recommended medical care or surgical procedures</li>
                            <li>3.The risks to this care procedure</li>
                        </ul>
                        <p>Please ask your physician / health care provider any remaining questions you have before
                            signing
                            form.</p>
                        <div class="row">
                            <p>I voluntarily request my physician / health care provider </p>
                            <div class="col-md-12">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Name/Credntials of provider</mat-label>
                                    <input type="text" formControlName="CareProvider" matInput>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <p>and other health care providers, to treart my condition which is</p>
                            <div class="col-md-12">
                                <mat-form-field class="example-full-width">
                                    <mat-label></mat-label>
                                    <input type="text" formControlName="HealthCondition" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>I understand following care/procedures are planned for me:</p>
                                <mat-form-field class="example-full-width">
                                    <mat-label></mat-label>
                                    <input type="text" formControlName="PlannedProcedure" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <h2>Potential for Additional Necessary Care/Procedure(s)</h2>
                        <p> I understand that during my care/procedure(s) my physician/health care provider may discover
                            other conditions which require additional or different care/procedure than originally
                            planned.
                        </p>
                        <p> I authorize my physicians/health care provide : to use their professional judgment to
                            perform
                            the
                            additional or different
                            care/procedure(s) they believe are needed. </p>

                        <div class="row">
                            <div class="col-md-12">
                                <h2>Use of Blood</h2>
                                <h4>Please initial Yes or No</h4>

                                <div class="col-md-4"> <mat-radio-group aria-label="Select an option"
                                        formControlName="UseOfBlood">
                                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                                        <mat-radio-button class="mx-2" value="No">No</mat-radio-button>
                                    </mat-radio-group>
                                    <mat-form-field class="example-full-width">
                                        <mat-label></mat-label>
                                        <input type="text" formControlName="UseOfBloodPatientInitial" matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-8">
                                    <p>I consent to the use of blobd and blood products as necessary for my health
                                        during
                                        the
                                        care/procedure (s). "the rlsks that may occur
                                        with the use of blood and blood products are:
                                    </p>
                                    <ul>
                                        <li>1. Serious infection incIuding but not limited Hepatitis and HIV which can
                                            lead
                                            to
                                            organ damage and perrnanent impairment. </li>
                                        <li>2. Transfusion related injury resulting in imparment of lungs, heart, liver,
                                            kidneys, and immune system.
                                        </li>
                                        <li>3. Severe allergic rea I n, potentially fatal. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <h2>Risks Related to this Care/Procedure(s) </h2>
                            <p>Just as there may be risks and hazards to 'my health without treatment,there are alos
                                risks
                                and
                                hazards related to the care/procedure(s) planned for me. </p>
                            <p>
                                I understand that all care/procedure(S)involve some risks, ranging from minor to severe.
                                These
                                risks include

                                infection, blood clots in veins, lungs or otner organs, hemorrhace (severe bleeding),
                                allergic
                                reactions, poor wound healing, and death.

                            </p>
                            <p>
                                The chances of these occurring may be different for each pati nt based on the
                                care/procedure(s)
                                and the patient's current health.

                            </p>
                        </div>
                        <!-- PAGE-2-START -->
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">

                        <div class="row">
                            <div class="col-md-12">
                                <p>Risks of this care / procedure(s) include, but not limited to [Include List A risks
                                    here
                                    ad
                                    additional risks if any]:</p>

                                <mat-form-field class="w-100">
                                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="1" cdkAutosizeMaxRows="140" name="export"
                                        formControlName="RiskOfProcedure"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <h2>Granting of Consent for this Care/Procedure(s)</h2>
                            <div class="col-md-12">
                                <p>In signing below, I consent to the care/procedure(s) described above. I acknoldge the
                                    following:</p>
                                <ul>
                                    <li>1 understand this care/procedure(s) does not guarantee a result or a cure to my
                                        co9ndition.</li>
                                    <li>I have been given an opportunity to ask questions : I may have about:
                                    </li>
                                </ul>
                                <ol>
                                    <li> Alternative forms of treatment,</li>
                                    <li> Risks of non-treatment,</li>
                                    <li> Steps that will occur during my care/procedure(s). and</li>
                                    <li> Risks and hazards involved in the care/proceourets.</li>

                                </ol>
                                <p> If any of those statements are not true for you. please talk to your
                                    physician/health
                                    care
                                    provider before continuing</p>
                                <hr>
                                <h2>Patient/Other Legally Authorized Representative(Signature required)</h2>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6"> <mat-form-field class="example-full-width">
                                    <mat-label> Authorised Person Name</mat-label>
                                    <input type="text" formControlName="AuthorisedPersonName" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Authorised Person Signature</mat-label>
                                    <input type="text" formControlName="AuthorisedPersonSignatire" matInput>
                                </mat-form-field>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-5">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Authorised Person Relation to Patient</mat-label>
                                    <input type="text" formControlName="AuthorisedPersonRelationtoPatient" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Sign Date</mat-label>
                                    <input type="date" formControlName="SignDate" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">

                                <mat-form-field class="example-full-width">
                                    <mat-label> Time </mat-label>
                                    <input [ngxTimepicker]="picker" matInput formControlName="SignTime">
                                    <ngx-material-timepicker #picker></ngx-material-timepicker>
                                    <mat-error>Please enter SignTime</mat-error>
                                </mat-form-field>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-md-6"> <mat-form-field class="example-full-width">
                                    <mat-label> Witness Name</mat-label>
                                    <input type="text" formControlName="Witness" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Witness Signature</mat-label>
                                    <input type="text" formControlName="WitnessSignature" matInput>
                                </mat-form-field>

                            </div>
                        </div>

                        <div class="row">

                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Witness Signature Date</mat-label>
                                    <input type="date" formControlName="WitnessSignatureDate" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">

                                <mat-form-field class="example-full-width">
                                    <mat-label> Witness Signature Time </mat-label>
                                    <input [ngxTimepicker]="picker1" matInput formControlName="WitnessSignatureTime">
                                    <ngx-material-timepicker #picker1></ngx-material-timepicker>
                                    <mat-error>Please enter Sign Time</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Addresss</mat-label>
                                    <input type="text" formControlName="Addresss" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field class="example-full-width">
                                    <mat-label>CityStateZip</mat-label>
                                    <input type="text" formControlName="CityStateZip" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col md-12">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Interpreter Name</mat-label>
                                    <input type="text" formControlName="InterpreterName" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Physician Signature</mat-label>
                                    <input type="text" formControlName="DoctorSignature" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="example-full-width">
                                    <mat-label> Signature Date</mat-label>
                                    <input type="date" formControlName="DoctorSignatureDate" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">

                                <mat-form-field class="example-full-width">
                                    <mat-label> Time </mat-label>
                                    <input [ngxTimepicker]="picker2" matInput formControlName="DoctorSignatureTime">
                                    <ngx-material-timepicker #picker2></ngx-material-timepicker>
                                    <mat-error>Please enter Sign Time</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- PAGE-2 -END-->
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">
                        <!-- PAGE-3 -START-->
                        <p>List A Procedures requiring full disclosure. The following treatments and procedures require
                            full
                            disolosure by the physician or health care provider to the
                            patient or person authorized to consent for the patient.
                        </p>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="Anesthesia">
                                    Anesthesia
                                </mat-checkbox>
                                <p> (1) Epidural</p>
                                <ul>
                                    <li> Nerve damage</li>
                                    <li>Persistent back pain</li>
                                    <li>Headache</li>
                                    <li>Bleeding/epidural hematuma</li>
                                    <li>Infection</li>
                                    <li>Medical necessity to convert to general anesthesia</li>
                                    <li>Brain Damage</li>
                                    <li>Chronic Pain</li>
                                </ul>
                                <p> (2) General</p>
                                <ul>
                                    <li>permanent organ damage</li>
                                    <li>Memory dysfunction/memory loss</li>
                                    <li>InJury to vocal cords, teeth, lips, eyes</li>
                                    <li>Awarenes during the procedure</li>
                                    <li>Brain damage</li>
                                    <li>InJury to vocal cords, teeth, lips, eyes</li>
                                    <li>Awarenes during the procedure</li>

                                </ul>
                                <p> (3) Spinal</p>
                                <ul>
                                    <li>Nerve damage</li>
                                    <li>Persistent back pain</li>
                                    <li>Brain damage</li>
                                    <li>Bleeding/epidural hematoma</li>
                                    <li>Headache</li>
                                    <li>Chronic pain</li>
                                    <li>Infection</li>
                                    <li>Medical neccessity to convert to general anesthesia</li>
                                </ul>




                            </div>

                            <div class="col-md-6">
                                <p> (4) Regional block</p>
                                <ul>
                                    <li>Nerve damage</li>
                                    <li>Persistent pain</li>
                                    <li>Brain damage</li>
                                    <li>Bleeding/ hematoma</li>
                                    <li>Infection</li>
                                    <li>Medical neccessity to convert to general anesthesia</li>
                                </ul>
                                <p>(5) Deep Sedation</p>
                                <ul>
                                    <li>Memory dysfunction/memory loss</li>
                                    <li>Peramanent Organ Damage</li>
                                    <li>Brain damage</li>

                                    <li>Medical neccessity to convert to general anesthesia</li>
                                </ul>

                                <p> (6) Moderate Sedation</p>
                                <ul>
                                    <li>Memory dysfunction/memory loss</li>
                                    <li>Peramanent Organ Damage</li>
                                    <li>Brain damage</li>

                                    <li>Medical neccessity to convert to general anesthesia</li>
                                </ul>
                                <p> (6) Moderate Sedation</p>
                                <ul>
                                    <li>Memory dysfunction/memory loss</li>
                                    <li>Peramanent Organ Damage</li>
                                    <li>Brain damage</li>

                                    <li>Medical neccessity to convert to general anesthesia</li>
                                </ul>

                                <p> (7) Prenatal/Early Childhood Anesthesia. Potential long-term negative effects on
                                    memory,
                                    behavior, and learning with prolonged or repeated exposure to general
                                    anesthesla/moderate
                                    sedation/deep sedation during pregnancy and in early childhood.</p>

                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="AnesthesiaInital" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- <div class="row">
                    <div class="col-md-6">
                        <mat-checkbox color="primary" formControlName="CardioVascularSystem">
                            CardioVascular System
                        </mat-checkbox>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>INITIALS</mat-label>
                            <input type="text" formControlName="CardioVascularSystemInitials" matInput>
                        </mat-form-field>
                    </div> -->

                        <!-- </div> -->
                        <div class="row">
                            <div class="col-md-6">
                                <p>Cardiovascular System</p>
                                <h4>1.Cardiac</h4>
                                <mat-checkbox color="primary" formControlName="Surgical">
                                    (A) Surgical
                                </mat-checkbox>
                                <ul>
                                    <li> Coronary artery bypass, valve replacement</li>

                                </ul>
                                <ol>
                                    <li>Acute myocardial infarction</li>
                                    <li>Hemorrhage</li>
                                    <li>Kidney failure</li>
                                    <li>Stroke</li>
                                    <li>Sudden death</li>
                                    <li>Infection of chest wall/ohest cavity</li>
                                    <li>Valve related delayed onset infection</li>
                                </ol>
                                <ul>
                                    <li> Heart Transpant</li>

                                </ul>
                                <ol>
                                    <li>Infection</li>
                                    <li>Rejection</li>
                                    <li>Death</li>
                                </ol>
                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="SurgicalInitials" matInput>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6">

                                <mat-checkbox color="primary" formControlName="NonSurgicalCoronaryAngioplasty">
                                    (B) Non-Surgical Non-Surgical-Coronary angioplasty, I coronary stent insertion,
                                    pacemaker
                                    insertion, AICD lnsertion,
                                    and cardioversion,

                                </mat-checkbox>

                                <ol>
                                    <li>All associated risks as listed under paragraph (2)(B)
                                        of this subsection
                                    </li>
                                    <li>Acute myocardial infarction (heart attack), </li>
                                    <li>Rupture of myocardium (hole in wall of heart). </li>
                                    <li>Life threatening arrhythmias (irregular heart rhytmm)</li>
                                    <li>Need for emergency open heart surgery</li>
                                    <li>Sudden death</li>
                                    <li>Devlce related delayed onset infection (infection related to the device that
                                        happens
                                        sometime after surgery)
                                    </li>

                                </ol>

                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="NonSurgicalCoronaryAngioplastyInitials"
                                        matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- PAGE-3 -END-->

                        <div class="row">
                            <div class="col-md-6">


                                <mat-checkbox color="primary" formControlName="Dignostic">
                                    (C) Dignostic
                                </mat-checkbox>
                                <ul>
                                    <li> Cardiac catheterlzallon</li>

                                </ul>
                                <ol>
                                    <li>All associated risks as listed under paragraph (2)(B)
                                        of this subsection
                                    </li>
                                    <li>Contrast nephropathy(injury to kidney function due to fuse of contrast material
                                        during
                                        procedure),
                                    </li>
                                    <li>Heart arrhythmias (ilTegular heart rhythm), possibly life , threatening, </li>
                                    <li>Acute myocardial infarction (heart attack), </li>
                                    <li>Need for emergency open heart surgery</li>


                                </ol>

                            </div>
                            <div class="col-md-6">
                                <ul>
                                    <li> Elettrophysiologic studies</li>

                                </ul>
                                <ol>
                                    <li>Cardiac perforation</li>
                                    <li>Life threatening arrhythmias (irregular heart rhytmm)</li>
                                    <li>Injury to vessels that may require immediate surgical intervention</li>
                                    <li>Stress testing--Acute myocardial infarction</li>

                                    <li>Transesophageal echooardiography-- Esophageal perforation</li>
                                </ol>
                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="DignosticInitials" matInput>
                                </mat-form-field>
                            </div>

                        </div>
                        <!-- Page-4-start  -->
                        <div class="row">
                            <div class="col-md-6">

                                <h4>2.Vascular</h4>
                                <mat-checkbox color="primary" formControlName="Vascular">
                                    (A) Open surgical repair of aortic, subclavian, and iliac, artery an~urysms or
                                    occlusions,
                                    and renal artery bypass
                                </mat-checkbox>
                                <ul>
                                    <li> Coronary artery bypass, valve replacement</li>

                                </ul>
                                <ol>

                                    <li>Hemorrhage</li>
                                    <li>Kidney damage</li>
                                    <li>Paraplegia</li>
                                    <li>Stroke</li>
                                    <li>Acute myocardial infraction</li>
                                    <li>Infection of grat</li>
                                </ol>

                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="VascularInitials" matInput>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6">

                                <mat-checkbox color="primary" formControlName="Angiography">
                                    (B) Ang ography (inclusive of aortography, arteriography,
                                    venography -Injection of contrast material into blood vessels, )


                                </mat-checkbox>

                                <ol>
                                    <li>Injury to or occlusion (blocking) of artery which may
                                        require immediate surgery or other intervention. </li>
                                    <li>Hemorrhage (severe bleeding). </li>
                                    <li>Damage to parts of the body supplied by the artery with esulting loss of use or
                                        amputation (removal of body part), </li>
                                    <li>Worsening of the condition for which the prooedure is being done, (v) Stroke
                                        and/or
                                        seizure (for procedures involving
                                        blood vessels supplying the spine, arms, neck or head), (vi) w· ontrast-related,
                                        temporary blindness or memory loss for studies of the blood vessels of the
                                        brain.
                                    </li>
                                    <li>Paralysis (inability to move) and inflammation of nerves (for
                                        procedures involving blood vessels supplying the spine).</li>
                                    <li>Contrast nephropathy (kidney damage due to the
                                        contest agent used during procedure). </li>
                                    <li>thrombosis (blood clot forming at or brocking the
                                        blood vessel) at access site or elsewhere. </li>

                                </ol>

                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="AngiographyInitials" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">

                                <mat-checkbox color="primary" formControlName="Angioplasty">
                                    (C) Angioplasy Stending (intravascular dilatation technique)
                                </mat-checkbox>



                                <ol>

                                    <li>All associated risks as listed under paragraph (2)(B)
                                        of this subsection, </li>
                                    <li>Failure.of procedure or injury to blood vessel requiring stent Ismail, permanent
                                        tube
                                        placed in blood vessel to
                                        eep it open placement or open surgery. </li>

                                </ol>

                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="AngioplastyInitials" matInput>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6">

                                <mat-checkbox color="primary" formControlName="EndoVascularStending">
                                    (D) Endovascular stenting (placement of permanent tube into 0 blootJ vessel to keep
                                    It
                                    open)
                                    of any portion of the aorta,
                                    iliag or carotid artery or other (peripheral) arteries or veins,


                                </mat-checkbox>

                                <ol>
                                    <li>AII associated risks as listed under paragraph (2)(B) of this subsection.

                                    </li>
                                    <li>Change in procedure to open surgical procedure</li>
                                    <li> Failure to place stentlendoluminal graft (stent w/fabric covering it), (iv)
                                        Stent
                                        migration (stent moves from location in which
                                        it was placed), </li>
                                    <li>Stentmigration (stent moves from location in which it was placed), </li>
                                    <li> Vessel occlusion (blocking). </li>
                                    <li> Impotence (difficulty with or inability to obtain penile erection)
                                        (for abdominal aorta and iliac artery procedures). </li>

                                </ol>

                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="EndoVascularStendingInitials" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- Page-4-end  -->
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">
                        <div class="row">
                            <div class="col-md-6">


                                <mat-checkbox color="primary" formControlName="VascularThrombolysis">
                                    (E) Vascular thrombolysis (removal or dissolving of blbod
                                    clots) • percutaneous (mechanical or chemical).

                                </mat-checkbox>


                                <ol>
                                    <li> All associated risks as listed under paragraph (2)(B) of this
                                        subsection. </li>
                                    <li>Increased risk of bleeding at or away from site of treatment
                                        (when using medications to dissolve clots). </li>
                                    <li>For arterial procedures: distal embolus (fragmepts of blooo clot may travel and
                                        block
                                        other blood vessels With possible injury to the supplied tissue). </li>
                                    <li>For venous procedures: pulmonary embolus (fragmerits of
                                        blood clot may travel to the blood vessels in the lungs and
                                        cause breathing problems or if severe could be life
                                        threatening). </li>
                                    <li>Kidney injury or failure which may be temporary; or
                                        , permanent (for procedures using certain mechahical
                                        thrombectomy devices).</li>
                                    <li>Need for emergency surgery. </li>
                                </ol>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="VascularThrombolysisInitials" matInput>
                                </mat-form-field>
                            </div>

                            <div class="col-md-6">

                                <mat-checkbox color="primary" formControlName="AniographyWithOccationTechnique">
                                    (F) Angiography with occlusion techniques (including emboliZation and sclerosis) -
                                    therapeutic.
                                </mat-checkbox>
                                <P>A.For all emboliZation</P>
                                <p>Angiography risks (inclusive of aortography, arteriobraphy, venography) - injection
                                    of
                                    contrast material irito blood vessels. </p>
                                <ol>
                                    <li> Unintended injury to or occlusion (blocking) of blood vessel which may require
                                        immediate surgery dr other intervention.
                                    </li>
                                    <li>Hemorrhage (severe bleeding).</li>
                                    <li>Damage to parts of the body supplied b~ the artery with resulting loss of use or
                                        amputation (removal ofi body
                                        part). </li>
                                    <li>Worsening of the condition for which the procedure is
                                        being done. </li>
                                    <li>Contrast nephropathy (kidney damage due to the
                                        contrast agent used during procedure).</li>
                                    <li>Unintended thrombosis (blood clot formirg at or blockin~1 the blood vessel) at
                                        access
                                        site or elsewhere.
                                    </li>
                                    <li> Loss or injury to body parts with potential need for ~urgery, including death
                                        of
                                        overlying skin
                                        for sclerothe'rapy/~eatment of superiiciallesionsNessels and nerve injury wlth
                                        associated pain, numbness
                                        or tingling or paralysis (inabil)ty to move.</li>
                                    <li>Infection in the form of abscess (infected fluid collec;Uon) or
                                        septicemia (infection of blood stream)</li>
                                    <li>Nontarget embolization (blocking of blood ves'sels other than those intended)
                                        which
                                        can
                                        result in injury to tissues supplied by those vessels. </li>
                                    <li> For procedures involving the thoracic aorta and/or vessels supplying the brain,
                                        spinal
                                        cord, head, neck or arms, these risks in addition to t110se under clause (i) of
                                        this
                                        subparagraph:
                                    </li>
                                    <li>Stroke.</li>
                                    <li>Seizure.</li>
                                    <li>Paralysis (inability to move).</li>
                                    <li>Inflammation or other injury of nerves, </li>
                                    <li>For studies of the blpod vessels of the brain: ' contrast-related, temporary
                                        blindness
                                        or memory loss.</li>
                                    <li>For female pelvic artJI erial embolizations including uterine
                                        fibroid embolization these risks in addition to those under I clause (i) of this
                                        subparagraph.
                                    </li>
                                    <li> Premature menopaufe with resulting sterility. </li>
                                    <li>Injury to or infection irvolving the uterus which might necessitate hysterectomy
                                        (removal of the uterus) with
                                        resulting sterility. </li>
                                    <li>After fibroid embolization: prolonged vaginal discharge. </li>
                                    <li>After fibroid embolization: expulsion/delayed expulsion of fibroid tissue
                                        possibly
                                        requiring
                                        a procedure to deliver/remove the tissue.</li>
                                    <li>For male pelvic arterial embolizatlons, In addition to the risks
                                        under clause (i) of this subparagraph: impotence (difficulty with or inability
                                        to
                                        obtain
                                        penile erection). </li>
                                    <li>For embolizations of pulmonary arteriovenous :
                                        fistulae/malformation;s, these risks in addition to those under clause (i) of
                                        this
                                        subRaragraph. </li>
                                    <li>New or worsening pulmonary hypertension (high blood pressure in the lung bIood
                                        vessels).
                                    </li>
                                    <li>Paradoxical embolization (passage of air or an occluding
                                        I device beyond the fistuia/maiformation and into the arterial circulation)
                                        causing
                                        tJlockage
                                        of blood flow to tissues
                                        supplied by the receiVing artery and damage to tissues
                                        served (for example the blood vessels supplying the heart (which could cause
                                        chest
                                        pain
                                        and/or
                                        heart attack) or brain I (which could cause stroke, paralysis (inability to
                                        move) or

                                        other neurological injLry)).</li>
                                    <li>For varicocele embolization, these risks in addition to those
                                        under clause (i) of thib subparagraph.</li>
                                    <li>Phlebitis/inflammati0d of veins draining the testicles ,\Ieading to
                                        decreasedlsize
                                        and
                                        possibly decreased function of affected testis and!sterility (if both sides
                                        performed).
                                    </li>
                                    <li>Nerve injury (thigh numbness or tingling).</li>
                                    <li>For ovarian vein em~olization/pelvic congestion syndrome embolization: general
                                        angiography and embolization risks as I listed in clause (i) of ~his
                                        subparagraph.
                                    </li>
                                    <li>For cases utilizing ethanol (alcohol) injection, in addition to the risks under
                                        clause
                                        (i) of this subparagraph: shock or severe lowering of blpOd pressure,</li>
                                    <li>For varicose vein treatments (with angiography) see
                                        subparagraph (L) of Ihis paragraph</li>

                                </ol>


                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="AniographyWithOccationTechniqueInitials"
                                        matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- page-5-start  -->
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary"
                                    formControlName="MensentericAngiographyWithInfusionalTherapy">
                                    (G) Mesenteric angiography with infusional therapy
                                    (Vasopressin) for gastrointestinal bleeding.,


                                </mat-checkbox>

                                <ol>
                                    <li> All associated risks as listed under paragraph (2)(B) of this
                                        subsection. </li>
                                    <li>Ischemia/infarction of supplied or distant vascular bsds (reduction in blood
                                        flow
                                        causing lack of oxygen withi injury or death of tissues supplied by the treated
                                        vessel
                                        or tissues
                                        supplied by blood vessels away from the trea'ted site including heart, brain,
                                        bowel,
                                        extremities).</li>
                                    <li>Antidiuretic hormone side effects of vasopressin (reduced urine output with
                                        disturbance
                                        of fluid balance lin thepody,
                                        rarely leading to swelling of the brain). </li>

                                </ol>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text"
                                        formControlName="MensentericAngiographyWithInfusionalTherapyInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary"
                                    formControlName="InferiorVenaCavalFilterInsertionAndRemoval">
                                    (H) Inferior vena caval filter insertion and rernoval

                                </mat-checkbox>



                                <ol>
                                    <li> All associated risks as listed under paragraph (2)(B) of this
                                        subsection. </li>
                                    <li>Injury to the inferior vena cava (main vein in the ebdornen). </li>
                                    <li>Filter migration or fracture (filter could break andorlmove from where it was
                                        placed).
                                    </li>
                                    <li>Caval thrombosis (clotting of the main vein in the abdomen and episodes of
                                        swelling
                                        of
                                        legs).</li>
                                    <li>Risk of recurrent pulmonary embolus (continued risk of blood clots going to
                                        blood
                                        vessels in
                                        the lungs desplts filter).</li>
                                    <li>Inability to remove filter (for "optional"/retrievable filters).</li>
                                </ol>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text"
                                        formControlName="InferiorVenaCavalFilterInsertionAndRemovalInitials" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="PulmonaryAngiograpgy">
                                    (I) Pulmonary angiography.


                                </mat-checkbox>


                                <ol>
                                    <li> All associated risks as listed under paragraph (2)(B) of this
                                        subsection. </li>
                                    <li>Cardiac arrhythmia (irregular heart rhythm) or cardiac arrest </li>
                                    <li>Cardiac injury/perforation (heart injury). </li>
                                    <li>Death.</li>
                                </ol>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="PulmonaryAngiograpgyInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="PercutneousTreatmentOfPseudcaneurysm">
                                    (J) Percutaneous treatment of pseudoaneurysm (percutaneous thrombin injection versus
                                    compression).
                                </mat-checkbox>

                                <ol>
                                    <li> Thrombosis (clotting) of supplying vessel or branches in its territory. </li>
                                    <li>Allergic reaction to thrombin (agent used fordirect injection) </li>

                                </ol>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="PercutneousTreatmentOfPseudcaneurysmInitials"
                                        matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="VascularAccess">
                                    (K) Vascular access- nontunneled catheters, tunnelled catheters, D
                                    Implanted access.


                                </mat-checkbox>

                                <ol>
                                    <li> Pneumothorax (collapsed lung). </li>
                                    <li>Injury to blood vessel. </li>
                                    <li>Hemothoraxlhemomediastinum (bleeding into the chest
                                        around the lungs or around the heart). </li>
                                    <li>Air embolism (passage of air into blood vessel andpoealofy to the heart and/or
                                        blood
                                        vessels entering the Iungs).</li>
                                    <li>Vessel thrombosis (clotting of blood vessel)</li>
                                </ol>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="VascularAccessInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="VaricoseVeinTreatment">
                                    (L) Varirose vein treatment (pbrcutaneous via laser, RFA,
                                    cheMical or other method) without angiography.
                                </mat-checkbox>

                                <ol>
                                    <li>Bums</li>
                                    <li>Deep vein thrombosis (blood clots in deep veins). </li>
                                    <li>Hyperpigmentation (darkening of skin). </li>
                                    <li>skin wound (ulcer). </li>
                                    <li>Elangiectatic matting (appearance of tiny blood vessels in
                                        treated area). </li>
                                    <li>Paresthesia and dysesthesia (numbness or tingling in the area or limb treated).
                                    </li>
                                    <li>Injury to blood vessel requiring additional procedure to treat. </li>

                                </ol>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="VaricoseVeinTreatmentInitials" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="DigestiveSystemTreatmentAndProcedures">
                                    (C)Digestive system treatments and procedures


                                </mat-checkbox>
                                <p>Cholecystectomy with or without common bile duct exploration.

                                </p>
                                <ol>
                                    <li> Pancreatiiti </li>
                                    <li>Injury to tube between the liver and the bowel. </li>
                                    <li>Retained stones in the tube between the liver and the bowel</li>

                                    <li>Injury to the bowel and / or intestinal obstruction</li>

                                </ol>
                                <p>Bariantric Surgery</p>
                                (A)Laparoscopic
                                <ol>
                                    <li>Conversion to open procedure. </li>
                                    <li>Injury to organs.</li>
                                    <li>Obstructive symptoms requiring additional surgical procedure</li>
                                    <li>Development of gallbtones </li>
                                    <li>Development of metabolic and vitamin disorders</li>
                                    <li>Suture line leak withlabscess or fistula formation</li>
                                </ol>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="DigestiveSystemTreatmentAndProceduresInitials"
                                        matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="Open">
                                    (8) Open
                                </mat-checkbox>


                                <ol>
                                    <li>Failure of wound to heal or wound dehiscence (separation od wound)</li>
                                    <li>Injury to organs. </li>
                                    <li>Failure of device requiring additional surgical procedure</li>
                                    <li>Obstructive symptoms requiring additional surgical procedure </li>
                                    <li>Development of gallstones (Roux-En· Y). </li>
                                    <li>Development of metbbolic and vitamin disorders.</li>

                                </ol>
                                <p>(3) Pacreatectomy(subtotal or total)</p>
                                <ol>
                                    <li>Pancreatitis(subtotal)</li>
                                    <li>Diabetes(total)</li>
                                    <li>Lifelong requirement of enzyme and Digestive medications</li>
                                    <li>Anastamotic leaks </li>
                                </ol>
                                <p>(4)Total colectomy</p>
                                <ol>
                                    <li>Permanent ileostomy</li>
                                    <li>Injury to organs</li>
                                    <li>Infection</li>
                                    <li>Anastamotic leaks </li>
                                </ol>

                                <p> (5) Subtotal colectomy. </p>
                                <ol>


                                    <li> Anastomotic leaks. </li>
                                    <li> Temporary colostomy.</li>
                                    <li> Infection. </li>
                                    <li> Second surgery. </li>
                                    <li>Injury to organs. </li>
                                    <p> (6) Hepatobiliary drainage/intervention including percutaneous
                                        transhepatic cholangiography, percutaneous biliarY drainage,
                                        percutaneous cholecystostomy, biliary stent placement (temporary
                                        or permanent), biliary stone removal/therapy. </p>
                                    <li> Leakage of bile at the skin site or into the abdomen with possible peritonitis
                                        (inflammation of the abdominallinipg and
                                        pain or if severe can be life threatening), </li>
                                    <li> Pancreatitis (inflammation of the pancreas).</li>
                                    <li> Hemobilia (bleeding into the bile ducts). </li>
                                    <li> Cholangitis, cholecystitis, sepsis (inflammationlinfection of the
                                        bile ducts, gallbladder or blood). </li>
                                    <li> Pneumothorax (collapsed lung) or other pleural complications
                                        , (complication involving chest cavity)</li>
                                    <p> (7) Gastrointestinal tract stenting.</p> :
                                    <li> Stent migration (stent moves from location in which it was
                                        placed). </li>
                                    <li> Esophageallbowel perforation (creation of a hole or tear in the tube from the
                                        throat to
                                        the stomach or in the intestine).</li>
                                    <li> Tumor ingrowth or other obstruction of stent. </li>
                                    <li> For stent placement in the esophagus (tube from the throat to
                                        the stomach). </li>
                                    <li> Tracheal compression (narrowing of windpipe) withlresultjn~1
                                        , or worsening of shortness of breath. </li>
                                    <li> Reflux (stomach contents passing up into esophagus or
                                        higher). </li>
                                    <li> Aspiration pneumonia (pneumonia from fluid getting in lungs)
                                        (if stent in lower part of the esophagus). </li>
                                    <li> Foreign body sensation (feeling like there is something in
                                        throat) (for stent placement in the upper esopmagus). </li>

                                </ol>
                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="OpenInitials" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- page-5-end  -->
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">
                        <!-- page-6-start  -->
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="EarTreatmentsAndProcedures">
                                    Ear treatments and procedures


                                </mat-checkbox>

                                <p>Stapedectomy.</p>
                                <li> Diminished or bad taste.</li>
                                <li> Total or partlalloss of hearing in the operated ear. </li>
                                <li> Brief or long·standing
                                    dizziness.</li>
                                <li> Eardrum hole requiring more surgery.
                                <li> Ringing in the ear</li>
                                <p> Reconstruction of auricle of ear for congenital deformity or trauma.</p>
                                <ol>
                                    <li>Less satisfactory appearance compared to possible alternatve
                                        artlficial ear. </li>
                                    <li> Exposure of implanted material.</li>
                                    <p> Tympanoplasty with mastoidectomy.</p>
                                    <li> Facial nerve paralysis.</li>
                                    <li> Altered or loss of taste.</li>
                                    <li> Recurrence of original disease process, </li>
                                    <li> Total loss of hearing in operated ear.</li>

                                    <li> Dizziness</li>
                                    <li> Ringing in the ear.</li>
                                </ol>




                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="EarTreatmentsAndProceduresInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="EndocrineSystemTreatmentsAndProcedures">
                                    (Ear) EndocrIne system treatmen,s and procedures.
                                </mat-checkbox>

                                <p>(1) Thyrfidectomy.</p>
                                <li>Acute airway obstructio~ requiring temporary tracheostomy.</li>
                                <li>Injury to nerves resultin~ in hoarseness or impairment of speech. </li>
                                <li> Injury to parathyroid
                                    glapds resulting in low blood calcium levels
                                    that require extensive medication to avoid serious degenerative conditions, such as
                                    catracts, brittle bones, muscle weakness ahd muscle irritability. </li>
                                <li> LIfelong requirement of thyroid medication.</li>
                                <p> (2) Para,hyroidectomy.</p>
                                <li> Acute airway obstructio~ requiring temporary tracheostomy.</li>
                                <li> In ury to nerves resulting in hoarseness or impairment of speech. </li>
                                <li> Low blood calcium
                                    levels that require extensive medication to
                                    all avoid serious degenerative conditions, such as cataracts, brittle bones, muscle
                                    weakness,
                                    and
                                    muscle irritability,</li>
                                <p> (3) Adrenalectomy.</p>
                                <ol>
                                    <li> Loss of endocrine functions.</li>
                                    <li> Lirelong requirement for hormone replacement therapy and steroid medication,
                                    </li>
                                    <li> Damage to kidneys.</li>
                                </ol>
                                <p> (4) Other procedures.</p>
                                <p> (5) See aIso Pancreatectomy under SUbsection (c)(3) of this section
                                    (relatihg to digestive system treatments and procedures).</p>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="EndocrineSystemTreatmentsAndProceduresInitials"
                                        matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="EyeTreatmentsAndProcedures">
                                    Eye treatments and procedures


                                </mat-checkbox>






                                <p> (1) Eye muscle surgery. </p>

                                <ol>
                                    <li> Additional treatment and/or surgery, </li>
                                    <li> Double vision. </li>
                                    <li>Partlal or total blindness. </li>
                                    <p> Reconstruction of auricle of ear for congenital deformity or trauma.</p>
                                </ol>
                                <p>(2) Surge[y for cataract with or without implantation of intraocular lens. </p>
                                <ol>
                                    <li> Complications requiring additional treatment and/or surgery,</li>
                                    <li> Need for glasses or contact lenses.</li>
                                    <li>Complications requiring the removal of implanted lens,</li>
                                    <li> Partial or total blindness</li>
                                </ol>


                                <p>(3) Retina1 or vitreous surgery.</p>
                                <ol>
                                    <li> Complications requiring aqditional treatment and/or surgery.</li>
                                    <li> Redurrence or spread of
                                        disease.</li>
                                    <li> Partial or total blindness. </li>

                                </ol>
                                <p> (4) Recon1structive and/or plastic[surgical procedures of the eye and eye region,
                                    such
                                    as
                                    blepharoplasty, tumor, fracture, lacrimal surgery, foreig~ body, abscess, or trauma.
                                </p>
                                <ol>
                                    <li> Blindness.</li>
                                    <li> Nerve damage with loss of use and lor feeling to eye or other
                                        areas of face.</li>
                                    <li>Painful or unattractive scarring.</li>
                                    <li> Worsening or unsatisfactd,ry appearrance.</li>
                                    <li> Dry eye. </li>
                                </ol>
                                <p> (5) photocagulation and/or cryotherapy</p>
                                <ol>
                                    <li> Complications requiring additional treatment and/or surgery.</li>
                                    <li> Pain.</li>
                                    <li> Partial or toatl blidness.</li>
                                </ol>
                                <p> (6) Corneal surgery, such as corneal transplant, refractive s~rgery and
                                    pterygium</p>
                                <ol>

                                    <li> Complications requiring additional treatment arid/or surgery.
                                    <li> Pain.</li>
                                    <li> Need for glasses or contact lenses.</li>
                                    <li> Partial or total blindness.</li>
                                    <p> Glaucoma surgery by any method. </p>
                                    <li> Complications requiring additional treatment and/or surgery.</li>
                                    <li> Worsening of the glaucoma. </li>
                                    <li> Pain. </li>
                                    <li> Partial or total blindness.</li>
                                    <li> Removal of the eye or its contents (enucleation or evisce~ation), : (A)
                                        Complications
                                        requiring additional treatment and/or surgery.</li>
                                    <li> Worsening or unsatisfactory appearance.</li>
                                    <li> Recurrence or spread of disease.</li>
                                </ol>
                                <p> Surgery for penetrating ocular injury, including lntraoculat foreign
                                    body</p>
                                <ol>

                                    <li>Complications requiring additional treatment and/or surgery. </li>
                                    <li> Possible removal of eye.</li>
                                    <li> Pain.</li>
                                    <li> Partial or total blindness.</li>

                                </ol>
                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="EyeTreatmentsAndProceduresInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary"
                                    formControlName="FemaleGenitalSystemTreatmentsAndProcedures">
                                    (G) Female genital system treatments and procedures.
                                </mat-checkbox>

                                <P> (1) Hysterectomy (abdominal and vaginal). </P>
                                <ol>


                                    <li> Uncontrollable leakage of urine.</li>
                                    <li> Injury to bladder. i
                                    <li> Sterility. . i
                                    <li> Injury to the tube (ureter) between the kidneyamd the bladder. </li>
                                    <li> Injury to the bowel and/or intestinal cbslrucfioru </li>
                                    <li> Need to covert to abdominal incision, </li>
                                    <li> If a power morcellator in laparoscopic surgery is utilized, include
                                        . the following risks: </li>
                                    <ul>
                                        <li> If cancer is present, may increase the risk of the spread of
                                            cancer.
                                        <li> (ii) Increased risk of damage to adjacent structUres.
                                    </ul>
                                </ol>
                                <p> (2) All fallopian tube and ovarian surgery with or without hysterectomy,
                                    including removal and lysis of adhesions.</p>
                                <ol>
                                    <li> Injury to the bowel and/or bladder.</li>
                                    <li> Sterility,</li>
                                    <li> Failure to obtain fertility (if applicable).</li>
                                    <li> Failure to obtain sterility (if applicable). </li>
                                    <li> Loss of ovarian functions or hormone productio~ from pvary(les). (F) If
                                        performed
                                        with
                                        hysterectomy, all associatedrishks under
                                        paragraph (1) of this subsection. </li>
                                    <li> For fallopian tube occlusion (for sterilization willi or without
                                        hysterectomy), see subparagraph (14) of this paragraph.</li>

                                </ol>
                                <p> (3) Removing fibroids (uterine myomectomy). </p>
                                <ol>
                                    <li> Injury to bladder</li>
                                    <li> Serility.</li>
                                    <li> Injury to the tube (ureter) between the kidney and the bladder.</li>
                                    <li> Injury to the bowel and/or intestinal obstruction.</li>
                                    <li>May need to convert to Ilhysterectomy.</li>
                                    <li>If a power morcellator in laparoscopic surgery is utilized, include
                                        the following risks: </li>
                                    <ul>
                                        <li>If cancer is present, may increase the risk of the spread of I cancer.</li>
                                        <li>Increased risk of damage to adjacent structures.</li>
                                    </ul>
                                </ol>

                                <p> (4) Uteripe suspension.</p>
                                <ol>
                                    <li> Uncontrollable leakage of urine.</li>
                                    <li> Injury to bladder.
                                    <li> Injury to the tube (urete1) between the kidney and the bladder.</li>
                                    <li> Injury to the bowel
                                        and/or intestinal obstruction.</li>
                                </ol>
                                <p> (5) Rem?val of the nerves to the uterus (presacral neurectomy)</p>
                                <ol>
                                    <li> Urcontrollable leakage of
                                        urine.</li>
                                    <li> Injury to bladder.</li>
                                    <li> Injury to the tube (urete~) between the kidney and the bladder.</li>
                                    <li> Irljury to the bowel
                                        and/qr intestinal obstruction.</li>
                                    <li> Hrmorrhage (severe ble,eding)
                                    </li>
                                </ol>
                                <p> (6) Rem?val of the cervix.</p>
                                <ol>
                                    <li> Upcontrollable leakage Qf urine.v
                                    <li> InjUry to bladder.</li>
                                    <li> Sterility.</li>
                                    <li> Injury to the tube (ureter) between the kidney and the bladder. (E) Injury to
                                        the
                                        bowel
                                        and/or intestinal obstruction.</li>
                                    <li> Need to convert to abdominal incision.</li>
                                </ol>
                                <p> (7) Repa/rof vaginal hernia (anterior and/or posterior colporrhaphy and'o]
                                    enterocele
                                    repair).</p>
                                <ol>
                                    <li> Uncontrollable leakage of urine.</li>
                                    <li> InjUry to bladder. </li>
                                    <li> Sterility. </li>
                                    <li> Injury to the tube (ureter)i between the kidney and the bladder.</li>
                                    <li> Injury to the bowel
                                        and/ or intestinal obstruction.</li>
                                    <li> Mrsh erosion (with dama1ge to vagina and adjacent tissue).</li>
                                </ol>
                                <p> (8) Abdorhinal suspension of thel bladder (retropubic urethropexy)</p>
                                <ol>
                                    <li> Uncontrollable leakage</li>
                                    of urine.
                                    <li> Injury to bladder. </li>
                                    <li> InJJury to the tube (ureter) between the kidney and the bladder. </li>
                                    <li> Injury to the bowel
                                        and/or intestinal obstruction.</li>
                                </ol>
                                <p> (9) ConizaUon of cervix.</p>
                                <ol>
                                    <li> Hemorrhage (severe bleeding) which may result in hysterectomy </li>
                                    <li> Sterility.</li>
                                    <li> Injury to bladder. </li>
                                    <li> Injury to rectum</li>
                                </ol>
                                <p> (10) OilatiOr, and curettage of uter~s (diagnostic/therapeutic).</p>
                                <ol>
                                    <li> Possible hysterectomy.</li>
                                    <li> Perforation (hole) created in the uterus.</li>
                                    <li> Sterility.</li>
                                    <li> Injury to bowel ancVor bladder.</li>
                                    <li> (E) AtJdominal incision and oqeration to correct injury.</li>
                                </ol>

                                <p> (11) Surgica1 abortion/dilation and curettage/dilation and evacuation.</p>
                                <ol>
                                    <li> Possible
                                        hysterectomy.</li>
                                    <li>Perforation (hole) created in the uterus.</li>
                                    <li>Sterility.</li>
                                    <li>Injury to the bowel and/or bladder.</li>
                                    <li>Abdominal incision and operation to correct injUry.</li>
                                    <li> Failure to remove all products of conception.</li>
                                </ol>



                                <p> (12) Medical abortlorvncn-surqical</p>
                                <ol>
                                    <li> Hemorrhage with possible need for surgical intervention. </li>

                                    <li>Failure to remove all products of conception. </li>

                                    <li> Sterility. </li>
                                </ol>
                                <p> (13) Selective salpingography and tubal reconstruction.</p>

                                <ol>
                                    <li> Perforation (hole) created in the uterus or Fallopian tube.</li>

                                    <li> Future Ectopic pregnancy (pregnancy outside of the uterus).</li>

                                    <li>Pelvic infection</li>
                                </ol>

                                <p> (14) Fallopian tube occlusion (for sterilization with or without:

                                    hysterectomy)</p>
                                <ol>
                                    <li> Perforation (hole) created in the uterus </li>

                                    <li>Future Ectopic pregnancy (pregnancy outside of the uterus) </li>

                                    <li> Pelvic infection </li>

                                    <li> Failure to obtain sterility </li>
                                </ol>
                                <p> (15) Hysteroscopy </p>

                                <ol>
                                    <li> Perforation (hole) created in the uterus or Fallopian tube</li>

                                    <li> Fluid overload/electrolyte imbalance </li>

                                    <li> Possible hysterectomy</li>

                                    <li> Abdomimal incision to correct injury</li>
                                </ol>

                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text"
                                        formControlName="FemaleGenitalSystemTreatmentsAndProceduresInitials" matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <!-- page-6-end  -->
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">
                        <!-- PAGE-7 -Start-->

                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary"
                                    formControlName="MaleGenitalSystemTreatmentsAndProcedures">
                                    (j) Male genital system
                                </mat-checkbox>
                                <p>Orchidpexy (reposition of testis(es))</p>
                                <ol>
                                    <li>Removal of testicle. </li>
                                    <li>Atrophy (shriveling) of the testicle with loss of function, </li>
                                </ol>
                                <p>Orchiectomy (removal of the testis(es)). </p>
                                <ol>
                                    <li>Dcreased sexual desire. </li>
                                    <li>Difficulties with penile erecfion. </li>
                                    <li>Permanent sterility (inability to father children) if both testes are removed
                                    </li>
                                </ol>
                                <p>Vasectomy, </p>
                                <ol>
                                    <li>Lolss of testicle, </li>
                                    <li>Failure to produce perrnsnent sterility (inability to father children), </li>
                                </ol>
                                <p>Circumcision, </p>
                                <ol>
                                    <li>Injury to penis, </li>
                                    <li>Need for further surgery </li>
                                </ol>




                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text"
                                        formControlName="MaleGenitalSystemTreatmentsAndProceduresInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="MaternityAndRelatedCases">
                                    (k) Maternity and related cases,
                                </mat-checkbox>

                                <p>Delivery (vaginal), </p>
                                <ol>
                                    <li>Injury to bowel and/or bladder, </li>
                                    <li>Sterlity (inability to get pregnant), </li>
                                    <li>Injury to ureter (tube between kidney and bladder). </li>
                                    <li>Brain damage, injury or even death occurring to the fetus before
                                        or during labor and/or cesarean delivery whether or not the cause is known
                                    </li>
                                    <li>Uterine disease or injury requiring hysterectomy(removal of utres) </li>
                                </ol>
                                <p>Cerclage </p>
                                <ol>
                                    <li>Premature labor</li>
                                    <li>Injury to bowel and/or bladder</li>
                                    <li>Rupture to membranes and possible infection</li>
                                </ol>





                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="MaternityAndRelatedCasesInitials" matInput>
                                </mat-form-field>
                            </div>

                        </div>



                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="HemmaticAndLymphaticSystem">
                                    (h)Hematic and lymphatic system
                                </mat-checkbox>
                                <p>Transfusion of blood and blood components</p>
                                <ol>
                                    <li>Serious infection including but not limited to Hepatitis and HIV
                                        which can lead to organ damage and permanent impairment.
                                    </li>
                                    <li>Transfusion related injury resulting in impairmeht of lungs, heart, liver,
                                        kidneys,
                                        and
                                        immune system, </li>
                                    <li>Severe allergic reaction, potentially fatal. </li>
                                </ol>
                                <p>Splenectomy </p>
                                <ol>
                                    <li>Susceptibility to infections and inceased severity Of infesction </li>
                                    <li>Increased immunization requirements, </li>
                                    <li> </li>
                                </ol>





                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="HemmaticAndLymphaticSystemInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="BreatSurgery">
                                    (i) Breast surgery (non-cosmetic).
                                </mat-checkbox>


                                <p>Radical or modified radical mastectomy, </p>

                                <ol>
                                    <li>Limitation of movement of shoulder and arm, </li>
                                    <li>Loss of the skin of the chest requiring skin graft. </li>
                                    <li>Permanent swelling of the arm</li>
                                    <li>Recurrence of malignancy, if present. </li>
                                    <li>Decreased sensation or numbness of the inner aspect of the arm and chest wall
                                    </li>
                                </ol>
                                <p>Simple mastectomy </p>

                                <ol>
                                    <li>Loss of the skin of the chest requiring skin graft. </li>
                                    <li>Recurrence of malignancy, if present. </li>
                                    <li>Decreased sensation or numbness of the nipple. </li>
                                </ol>
                                <p>Lumpectomy </p>

                                <ol>
                                    <li>Loss of the skin of the chest requiring skin graft. </li>
                                    <li>Recurrence of malignancy, if present. </li>
                                    <li>Decreased sensation or numbness of the nipple. </li>
                                </ol>
                                <p>Open biopsy </p>

                                <ol>
                                    <li>Loss of the skin of the chest requiring skin graft. </li>
                                    <li>Recurrence of malignancy, if present. </li>
                                    <li>Decreased sensation or numbness of the nipple. </li>
                                </ol>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="BreatSurgeryInitials" matInput>
                                </mat-form-field>
                            </div>

                        </div>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">

                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary"
                                    formControlName="MuskuloskeletalSystemTreatmentsAndProcedures">
                                    (I) Musculo~keletal system treatments and procedures,
                                </mat-checkbox>
                                <p>Arthroplasty of any joints with mechanical device, </p>

                                <ol>
                                    <li>Impaired function such as stiffness, limp, or change in limb length Impaired
                                        function
                                        such as stiffness, limp, or change in limb length, </li>
                                    <li>Blood vessel or nerve injury</li>
                                    <li>Pain or discomfort</li>
                                    <li>Blood clot in limb or lung </li>
                                    <li>Failure of bone to heal. </li>
                                    <li>Infection, </li>
                                    <li> Removal or replacement of any implanted device or material</li>
                                    <li>Dislocation or loosening r9quiring additional surgery, </li>
                                    <li>If Prrformed on a child age 12 or under, include the following additional risks:
                                        problems with appearance, use, or growth requiring additional surgery,

                                    </li>
                                </ol>



                                <p>(2) Arthroscopy of any joint. </p>
                                <ol>
                                    <li> BI.O.Od vessel or nerve injury. </li>
                                    <li> Continued pain </li>
                                    <li> Stiffness of joint. </li>
                                    <li>Blood clot i,n limb or leg. </li>
                                    <li> JOint infection. </li>
                                    <li>If performed on a child age .12 or under, include the following
                                        additional risks: problems with appearance, use, or growth requiring additional
                                        surgery
                                    </li>
                                </ol>

                                <p> Open reduction with internal fixation. </p>
                                <ol>
                                    <li> Impaired function such as stiffness, limp, or change in limb length, </li>
                                    <li> Blood vessel or nerve injury </li>
                                    <li>Pain </li>
                                    <li> Blood clot in limb or lung </li>
                                    <li> Failure of bone to heal. </li>
                                    <li> Failure of bone to heal. </li>
                                    <li> Infection </li>
                                    <li> Removal or replacement of any implanted device or material, </li>
                                    <li> If performed on a child age 12 or under, include the folIowing
                                        additional risks: problems with appearance, use, or grbwth requiring additlonal
                                        surgery.
                                    </li>
                                </ol>

                                <p>Osteotomy.</p>
                                <ol>
                                    <li> Impaired function such as stiffness, limp, or change in limb length, </li>
                                    <li> Blood vessel or nerve injury </li>
                                    <li>Pain </li>
                                    <li> Blood clot in limb or lung </li>
                                    <li> Failure of bone to heal. </li>
                                    <li> Failure of bone to heal. </li>
                                    <li> Infection </li>
                                    <li> Removal or replacement of any implanted device or material, </li>
                                    <li> If performed on a child age 12 or under, include the folIowing
                                        additional risks: problems with appearance, use, or grbwth requiring additlonal
                                        surgery.
                                    </li>
                                </ol>
                                <p>Ugamentous reconstruction of joints. </p>
                                <ol>
                                    <li>Continued instability of the joint. </li>
                                    <li>Continued pain. </li>
                                    <li>Stiffness of joint. </li>
                                    <li>Blood vessel or nerve injury. </li>
                                    <li>Impaired function and/or scarring</li>
                                    <li>Blood clot in limb or lung </li>
                                    <li>If performed on a child age 12 or under, include the fqllowing
                                        additional risks: problems with appearance, use; or growth requiring additional
                                        surgery,
                                    </li>
                                </ol>
                                <p>
                                    Vertebroplasty/kyphoplasty,
                                </p>
                                <ol>
                                    <li>Nerve/spinal cord injury</li>
                                    <li>Need for emergency surgery. </li>
                                    <li>Embolization of cement (cement passes into blood vessels and possibly all the
                                        way to
                                        the
                                        blood vessels in:the lungs), </li>
                                    <li>Collapse of adjacent vertebrae (bones in spine), </li>
                                    <li>Leak of cerebrospinal fluid (fluid around the brain and spinal/cord) </li>
                                    <li>Pneumothorax (collapsed lung). </li>
                                    <li>Failure to relieve pain, </li>
                                    <li>Rib fracture, </li>
                                </ol>
                                <p>If performed on a child age 12 or under, include the fqllowing
                                    additional risks: problems with appearance, use; or growth requiring additional
                                    surgery
                                    should be disclosed</p>
                                <ol>
                                    <li>Artprotomy (opening of the joint), </li>
                                    <li>Closed reduction with or without pin or external fixation, </li>
                                    <li>Surgical management of open wound, </li>
                                    <li>Partial excision or removal of bone, </li>
                                    <li>Removal of external fixallon device. </li>
                                    <li>Trabtion or casting with or without manipulation for reduction, </li>
                                </ol>
                                <p>Amputation of limb. </p>
                                <ol>
                                    <li>Pain and/or phamtom sensation in removed limb. </li>
                                    <li>Need for futher sugery, </li>
                                    <li>Infection, </li>
                                    <li>Hemorrhage (severe bleeding). </li>
                                    <li>Difficulty with prosthesis fitting. </li>

                                </ol>
                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text"
                                        formControlName="MuskuloskeletalSystemTreatmentsAndProceduresInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="NervousSystemTreatmentsAndProcedures">
                                    Nervous System Treatments And Procedures
                                </mat-checkbox>
                                <p> Craniqtomy, craniectomy or cranloplasty, </p>
                                <ol>
                                    <li> Additional loss of brain function such as memory and/or </li>
                                    <li> Recurrence, continuation or worsening of the condition that required this
                                        operation(no
                                        improvemt or symptoms made worse)</li>

                                    <li>Stroke(damage to brain rerulting in loss of one or more functions), </li>
                                    <li>Loss of senses (blindness, double vision, deafness, smell,numbness, taste).
                                    </li>
                                    <li>Weakness, paralysis, loss of coordination </li>
                                    <li>Cerebrospinal fluid leak with potential for severe headaches</li>
                                    <li>Meningitis (infection of coverings of brain and spinal cord). </li>
                                    <li>Brain abscess, </li>
                                    <li>Persistent vegetative state (not able to communicate or interact with others)
                                    </li>
                                    <li>Hydrocephalus (abnormal fluid buildup causing pressure in the brain)</li>
                                    <li>Seizers(uncontrollerd nerve activity)</li>

                                    <li>Need for permanent breathing tube and/or permanent feeding tube.</li>
                                    <li></li>
                                </ol>
                                <p> Cranial nerve operations </p>
                                <ol>
                                    <li> Weakness, numbness, impaired muscle function or paralysis. </li>
                                    <li> Recurrence, continuation or worsening of the condition that required this
                                        operation(no
                                        improvemt or symptoms made worse)</li>
                                    <li>Seizers(uncontrollerd nerve activity)</li>
                                    <li>New or different pain. </li>
                                    <li>Stroke(damage to brain rerulting in loss of one or more functions), </li>

                                    <li>Persistent vegetative state (not able to communicate or interact with others)
                                    </li>
                                    <li>Loss of senses (blindness, double vision, deafness, smell,numbness, taste).
                                    </li>
                                    <li>Cerebrospinal fluid leak with potential for severe headaches</li>
                                    <li>Meningitis (infection of coverings of brain and spinal cord). </li>

                                    <li>Need for prolonged nursingi care. </li>
                                    <li>Need for permanent breathing tube and/or permanent feeding tube.</li>
                                    <li></li>
                                </ol>

                                <p> 3.Spine operation, including laminectomy, decompression, fusion,
                                    internal fixation or procedures for nerve root or spinal cord
                                    compression; diagnois; pain; deformity; mechani?al, instaq,illity; injury;
                                    : removal of tumor, abscess or hematoma (excluding coxccygeal
                                    operations).
                                </p>
                                <ol>
                                    <li>Weakness, pain, numbness or clumsiness. numbness, taste</li>
                                    <li>Impaired muscle function or paralysis.</li>
                                    <li>Incontinence impotence or impaired bowel function (less of
                                        I bowel!bladd~r control and/or sexual function)</li>
                                    <li>Mirgration of implants (movement of implanted device)</li>
                                    <li>Failure of implants (breaking of implanted devices)</li>
                                    <li>Adjacent level degeneration(breakdown of spine above and/or
                                        below the level treated).</li>
                                    <li>Cerebrospinal fluid leak with potential for severe headaches, reqplred lhls
                                        opera~lon
                                        (neD
                                        Improvement or symptoms made worse)</li>
                                    <li> Meningitis Infection of coverings of brain or spinal cord </li>
                                    <li>Recurrence , continuation or worsening of teh conditions that required this
                                        operation(no
                                        improvement or symptpms made worse)</li>

                                </ol>


                                <p> (4) Penpheral nerve operation; nerve grafts, decompression,
                                    transposition or tumor removal; neurorrhaphy, neurectomy or with others.
                                    neurolysis. </p>
                                <ol>
                                    <li> Numbness.</li>
                                    <li> Impaired muscle function.</li>
                                    <li> Recurrence, continuation or worsening of the conditiorn that

                                        : required this operation (no improvement or symptoms or,made worse) </li>
                                    <li>Continued, increased or different pain. </li>
                                    <li> Weakness '</li>
                                </ol>
                                <p> (5) Transphenoidal hypophysectomy or other pituitarY gland operation.</p>
                                <ol>
                                    <li> Cerebrospinal fluid leak with potential for severe headaches.
                                    <li> Necessity for hormone replacement. i th1 spleen. .
                                    <li> Recurrence or continuation of the condition that required this
                                        operation.

                                    <li>Deformity or perforation of nasal spectum (hole in wall between
                                        the right and left halves of the nose). •</li>
                                    <li> Facial nerve injury resulting in disfigurement (loss of n~rve func ion " .
                                        controlling muscules in face). </li>

                                    <li> Loss of senses (blindness, double vnson, deafne,ss, s1ell,
                                        numbness, taste).</li>
                                    <li> Stoke (damage to brain resulting In loss of one .or more. functions).</li>
                                    <li>Persistent vegltative state (not able to communicate o~ Interact organ requmnq
                                        further
                                        intervenfion,intract With others). </li>

                                    <li> Headaches.</li>

                                </ol>
                                <p>(6)Cerebral spinal fluid shunting procedure or revision.</p>
                                <li> Shunt obstructlon(blockage of shunt/tubing causmg It to stop
                                    draining .adequately)</li>
                                <li> Malposition or rnlqrauon of shunt/tube lmproper posisoning or
                                    later movement of shunt/tube causing it to stop draining adequately.
                                <li> Seizures (uncontrolled nerve activity). I
                                <li> Recurrance or continuation of brain dysfunction.
                                <li> Injury to internal organs of the chest or abdomen.
                                <li>Brain Injury</li>
                                <li>Stroke(Damage to the brain resulting in loss of onew or more function)</li>
                                <li>Persistent vegltative state (not able to communicate o~ Interact organ requmnq
                                    further
                                    intervenfion,intract With others). </li>
                                <li> Loss of senses (blindness, double vnson, deafne,ss, s1ell,
                                    numbness, taste).</li>
                                <li>Cerebral spinal fluid leak with potential for severe headaches</li>
                                <li> Meningitis Infection of coverings of brain or spinal cord </li>
                                <li>Need for prolonged nursingi care. </li>
                                <li>Need for permanent breathing tube and/or permanent feeding tube.</li>

                                <p>(7)Evaluation of depresses skull fracture </p>
                                <ol>
                                    <li>Loss of brain function such as memory and/or abilityto speak. </li>
                                    <li>Recurrence, continuation,or worsening of the condition that required
                                        this operation (new Improvement or symptoms made worse) </li>
                                    <li>Loss of senses bllndness, double vnson, deafness, smell</li>
                                    <li>Weakness, paralysis, loss of coordination </li>
                                    <li>Cerebrospinal fluid leak with potential for severe headaches</li>
                                    <li>Meningitis (infection of corerings of brain or spinal cord). </li>
                                    <li>Brain abscess </li>
                                    <li>Persistent vegitative state not able to communicate or interact </li>
                                    <li>Seizures (uncontrolled nerve activity). </li>
                                    <li>Need for permanent breathing tube and/or permanent feeding tube </li>

                                </ol>


                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="NervousSystemTreatmentsAndProceduresInitials"
                                        matInput>
                                </mat-form-field>
                            </div>

                        </div>
                        <!-- PAGE-7 -end-->
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">
                        <!-- PAGE-8-Start-->

                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary"
                                    formControlName="RespiratorySystemTreatmentsAndProcedures">
                                    Respiratory System Treatments And Procedures
                                </mat-checkbox>


                                <p> 1.Biopsy and/or excision of lesion of larynx, vocal cords, trachea. </p>
                                <ol>
                                    <li>Loss or change of voice</li>
                                    <li>Swallowing or breathing difficulties. </li>
                                    <li>Perforation (hole) or fistula (connection) in esopragus (tube from throat to
                                        stomach).
                                    </li>

                                </ol>
                                <p>2. Rhinoplasty or nasal reconstruction with or without septoplasty </p>
                                <ol>
                                    <li>Deformity of skin, bone or cartilage. </li>
                                    <li>Creation of new problems, such as perforation of the nasal
                                        septum (hole in wall between the right and left halves Of the nose) or breathing
                                        difficulty.
                                    </li>

                                </ol>
                                <p>3. Submucous resection of nasal septum or nasal septoplasty, </p>
                                <ol>
                                    <li>Persistence, recurrence or worsening of the obstruction</li>
                                    <li>Perforation of nasal septum (hole in wall between the right and left halves of
                                        the
                                        nose)
                                        withl dryness and crusting. </li>
                                    <li>Efternal deformity of the nose. </li>

                                </ol>
                                <p>(4) Lung biopsy. </p>
                                <ol>
                                    <li>Pneumothorax (collapseb lung). </li>
                                    <li>Hemothorax (blood in thy chest around the lung). </li>
                                </ol>
                                <p>5.Segmental resection of lung. </p>
                                <ol>
                                    <li>Hemothorax (blood in the chest around the lung). </li>
                                    <li>Abscess (Infected fluid collection) in chest. </li>
                                    <li>Infection of tube into space between lung and chest wall or repeat surgery.
                                    </li>
                                    <li>Need for additional surgery. </li>
                                </ol>
                                <p>(6) Thoracotomy. </p>
                                <ol>
                                    <li>Hemothorax (blood in the~ chest around the lung). </li>
                                    <li>Abscess (infected fluid collection) in chest. </li>
                                    <li>Pneumothorax (collapsed lung). </li>
                                    <li>Need for additional surgery. </li>
                                </ol>
                                <p>Thorcotomy with drainage. </p>
                                <ol>
                                    <li>Breathing difficulties. </li>
                                    <li>Hemothorax (blood in the chest around the lung). </li>
                                    <li>Abscess (infected fluid collection) in chest. </li>
                                    <li>Pneumothorax (collapsed lung). </li>
                                    <li>Need for additional surgery. </li>
                                </ol>
                                <p>Open Tracheostomy</p>
                                <ol>
                                    <li>Loss of voice. </li>
                                    <li>Breathing difficulties. </li>
                                    <li>P1eumothorax (colapsed lung). </li>
                                    <li>Hemothorax (blood in the chest around the lung). </li>
                                    <li>Scarring in trachea (windpipe). </li>
                                    <li>Fistula (connection) between trachea into esophagus (tube from throat to stomach
                                        )
                                        or
                                        great vessels.</li>
                                    <li></li>
                                </ol>
                                <p>(9) Respiratory tract/tracheobronchial balloon dilatation/stenting. </p>
                                <ol>
                                    <li>Stent migration (stent Moves from position in which it including the space
                                        arounb
                                        the
                                        heart). </li>
                                    <li>Mucosal injury (injury to lining of airways). </li>
                                    <li>Pneumomediastinum (air enters the space around the airways including the space
                                        around
                                        the heart). </li>
                                    <li></li>
                                </ol>

                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text"
                                        formControlName="RespiratorySystemTreatmentsAndProceduresInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="UrenarySystem">
                                    UrenarySystem
                                </mat-checkbox>


                                <p> Partial nephrectomy (removal of part of the kidney). </p>
                                <ol>
                                    <li>Incomplete removal of stone(s) or tumor, if present</li>
                                    <li>Blockage of urine. </li>
                                    <li>Leakage of urine at surgical site. </li>
                                    <li>Injury to or loss of the kidney. </li>

                                </ol>
                                <p>Radical nephrectomy (removal of kidney and adrenal gland for cancer).</p>
                                <ol>
                                    <li>Loss of the adrenal gland (gland on top of kidney that makes certain
                                        hormones/chemicals
                                        the body needs). </li>
                                    <li>IncompIete removal of tumor. </li>
                                    <li>Damage to organs next to kidney. </li>
                                </ol>
                                <p>(3) Nephrectomy (removal of kidhey). </p>
                                <ol>
                                    <li>Incomplete removal of tumor if present. </li>
                                    <li> Damage to organs next to kidney. </li>
                                    <li>Injury or loss of the kidney</li>
                                </ol>
                                <p>4.Nephrolithotomy and pyelolithotomy (removal of kioney stone(s)). </p>
                                <ol>
                                    <li>Incomplete removal of stone(s) or tumor, if present</li>
                                    <li>Blockage of urine. </li>
                                    <li>Leakage of urine at surgical site. </li>
                                    <li>Injury to or loss of the kidney. </li>
                                </ol>
                                <p>5. Pyeloureteroplasty (pyeloplasty or reconstruction of the kidney drainage system).
                                </p>
                                <ol>
                                    <li>Blockage of urine. </li>
                                    <li>Leakage of urine at surgical site. </li>
                                    <li>Injury to or loss of the kidney. </li>
                                    <li> Damage to organs next to kidney. </li>

                                </ol>
                                <p>6.Exploration of kidney or perinephric mass </p>
                                <ol>
                                    <li>Incomplete removal of stone(s) or tumor, if present</li>
                                    <li>Blockage of urine. </li>
                                    <li>Leakage of urine at surgical site. </li>
                                    <li> Damage to organs next to kidney. </li>

                                </ol>
                                <p>7.Ureteroplasty (reconstruction of ureter (tube between kidney and
                                    bladder)).
                                </p>
                                <ol>
                                    <li>Incomplete removal of stone(s) or tumor, if present</li>
                                    <li>Blockage of urine. </li>
                                    <li>Leakage of urine at surgical site. </li>
                                    <li> Damage to organs next to ureter. </li>
                                    <li> Damage or loss of ureter. </li>
                                </ol>
                                <p>8. Ureterolithotomy (surgical removal of stone(s) from ureter (tube
                                    between kidney and bladder)).
                                </p>
                                <ol>
                                    <li>Incomplete removal of stone(s) </li>
                                    <li>Blockage of urine. </li>
                                    <li>Leakage of urine at surgical site. </li>
                                    <li> Damage to organs next to ureter. </li>
                                    <li> Damage or loss of ureter. </li>
                                </ol>
                                <p>9.Reterectomy (partial/complete removal of ureter (tube between
                                    kidney and bladder)).
                                </p>
                                <ol>
                                    <li>Leakage of urine at surgical site. </li>
                                    <li>Incomplete removal of tumor (when applicable). </li>
                                    <li>Blockage of urine. </li>
                                    <li> Damage to organs next to ureter. </li>

                                </ol>
                                <p>10. Ureterolysis (partial/complete removal of ureter (tube betvyeen
                                    I kidney and bladder from adjacent tissue)).
                                </p>
                                <ol>
                                    <li>Leakage of urine at surgical site. </li>
                                    <li>Blockage of urine. </li>
                                    <li> Damage to organs next to ureter. </li>
                                    <li> Damage or loss of ureter. </li>
                                </ol>
                                <p>11.Ureteral reimplantation (reinserting ureter (tube between kidney and
                                    bladder) into the bladder).
                                </p>
                                <ol>
                                    <li>Leakage of urine at surgical site. </li>
                                    <li>Blockage of urine. </li>
                                    <li> Damage or loss of ureter. </li>

                                </ol>
                                <p>12.Prost tectomy (partial or total removal of prostate). (A) Le;1 kage of urine at
                                    surgibal
                                    site. </p>
                                <ol>
                                    <li>Blockage of urine. </li>
                                    <li>In continence (difficulty with control of urine flow). (D) S~men passing
                                        backward
                                        into
                                        bladder. </li>
                                    <li>Semen passing backward into bladder. </li>
                                    <li>Dificulty with penile erection (possible with partial and probable wir total
                                        prostatectomy)</li>
                                </ol>

                                <p>13.Total cystectomy (removal of urinary bladder)</p>
                                <ol>


                                    <li>Probable loss of penile erection and ejaculation in the male.</li>
                                    <li>Damage to organs next to bladder. </li>
                                    <li>This procedure will requiJe an alternate method of urinary drainage.</li>

                                </ol>
                                <p>14.Radical cystectomy. </p>
                                <ol>

                                    <li>Probable loss of penile erection and ejaculation in the male. </li>
                                    <li>Damage to organs next to bladder. </li>
                                    <li>Thisprocedure will require an alternate method of urinary
                                        drainage.</li>
                                    <li>Coronic (continuing) swelling of thighs, legs and feet. </li>
                                    <li>Recurrence or spread of cancer if present. </li>
                                </ol>
                                <p>15.Partia!"cystectomy (partial reh,oval of bladder). </p>
                                <ol>
                                    <li>Leakage of urine at surgical site. </li>
                                    <li>Insohtinence (difficulty with control of urine flow). </li>
                                    <li>Backward flow of urine fr~m bladder into ureter (tube between ki~ney and
                                        bladder).
                                    </li>
                                    <li>Blockage of urine. </li>
                                    <li>Damage to organs next to bladder. </li>
                                </ol>

                                <p>16.Urlnal diversion (ileal conduit, colon conduit). </p>
                                <ol>
                                    <li> Blood chemistry abnorma'lities requiring medication. </li>
                                    <li> Development of stones, strictures or infection in the kidneys, ureter or bowel
                                        (intestine) </li>
                                    <li> Leakage of urine at surgical site. </li>

                                </ol>
                                <p>17.Ureterpsigmoidostomy (plac~ment of kidney drainage tubes into the
                                    large bowel (intestine)).
                                </p>
                                <ol>
                                    <li>Bldod chemistry abnormalities requiring medication. </li>
                                    <li>Development of stones, strictures or infection in the kidneys, ureter or bowel
                                        (intestine). </li>
                                    <li>Leackage of urine at surgical site. </li>
                                    <li>Difficulty in holding urine ih the rectum. </li>
                                </ol>
                                <p>18.UrethrEplasty (constructionlr~construction of drainage tube from bladder). </p>
                                <ol>
                                    <li>Leakage of urine at surgiqal site. </li>
                                    <li>structure formation (narrowing of urethra (tube from bladder to outside)). </li>
                                    <li> Need for additional surgery. </li>
                                    <li> </li>
                                    <li> </li>
                                </ol>
                                <p>19.Percutaneous nephrostomy/~tenting/stone removal. </p>
                                <ol>
                                    <li>Pneumothorax or other pleural complications (collapsed lung or fillirlg of the
                                        chest
                                        cavity on the same side with fluid). </li>
                                    <li>Septic shock/oacteremia (infection of the blood :stream
                                        with possible shock/severe lowering of blood pressure) when pyonephrosis
                                        (infected
                                        urine
                                        in the kidney) present. </li>
                                    <li> Bowel (intestinal) injury. </li>
                                    <li> Blood vessel injury with or without significant bleeding:. </li>
                                </ol>


                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="UrenarySystemInitials" matInput>
                                </mat-form-field>
                            </div>

                        </div>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="Dialysis">
                                    Dialysis
                                </mat-checkbox>

                                <p>20.Dialysis (technique to replace functions of kidney a:nd clean blood of
                                    toxins).
                                </p>
                                <h4>Hemodialysis</h4>
                                <ol>
                                    <li> Hypotension (low blood pressure),</li>
                                    <li> Hypertension (high blood pressure).</li>
                                    <li> Air embolism (air bubble in blood vessel) resulting in possible
                                        death or paralysis. </li>
                                    <li>Cardiac arrhythmias (irregular heart rhythms).</li>
                                    <li>Infections of blood stream, access site, or blood borne (for
                                        example: Hepatitis 8, C, or HIV). </li>
                                    <li> Hemorrhage (severe bleeding as a result of clotting! problems
                                        or due to disconnection of the bloodline).</li>
                                    <li> Nausea, vomiting, cramps, headaches, and mild confusion during and/or
                                        temporarily
                                        after
                                        dialysis. </li>
                                    <li> Allergic reactions. </li>
                                    <li> Chemical imbalances and metabolic disorder,s (unirltended
                                        change in blood minerals). </li>
                                    <li> Pyrogenic reactions (fever). </li>
                                    <li> Hemolysis (rupture of red blood celis). </li>
                                    <li>Graftlfistula damage including bleeding, aneuvsrn formation (ballooning of
                                        vessel),
                                        clotting (closure) of grattifisttJla. </li>
                                </ol>
                                <p> Peritoneal dialysis. </p>
                                <ol>
                                    <li> Infections, including peritonitis (inflammation or irritation of the tissue
                                        lining
                                        the
                                        inside wall of abdomen and covering organs),
                                        catheter infection and catheter exit site infection.</li>
                                    <li> Development of hemias of umbilicus (weakening of ?bdominal
                                        wall or muscle). </li>
                                    <li> Hypertension (high blood pressure). </li>
                                    <li> Hypotension (low blood pressure).</li>
                                    <li> Hydrothorax (fluid in chest cavity).</li>
                                    <li> Arrhythmia (irregular heart rhythm).</li>
                                    <li> Perforation of the bowel.</li>
                                    <li> Sclerosis or scarring of the peritoneum. (ix) Weight gain leading to obesity.
                                    </li>
                                    <li> Abdominal discomfort/distension.</li>
                                    <li> Heartbum or reflux.</li>
                                    <li>Increase in need for anti-diabetic medication. </li>
                                    <li> Muscle weakness. </li>
                                    <li> Dehydration (extreme loss of body fluid)</li>
                                    <li>Chemical imbalances and metabolic disorders (unintended
                                        change in blood minerals).</li>
                                    <li>Allergic reactions. </li>
                                    <li> Nausea, vomiting, cramps, headaches, and mild ccofuslon
                                        during and/or temporarily after dialysis.</li>

                                </ol>
                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="DialysisInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="PsychatricProcedures">
                                    Psychatric Procedures
                                </mat-checkbox>


                                <p>PsychIatric procedures </p>
                                <ol>
                                    <h4> Eleptroconvulsion therap~with modification by intravenous muscle relaxants and
                                        sedatives. </h4>
                                    <li> memory changes of e~ents prior to, during, and immediately ,ollowing the
                                        treatment,
                                    </li>
                                    <li> Fractures or dislocatiors of bones,
                                    <li> significant temporary confusion requiring special care. (2) OtHer procedures.
                                        No
                                        other
                                        procedures are assigned at this time. </li>

                                </ol>
                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="PsychatricProceduresInitials" matInput>
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="RadiationTherapy">
                                    RadiationTherapy
                                </mat-checkbox>


                                <p>A child is defined for the purpose of this subsection as an individual who
                                    is not physiologically mature as determired by the physician Using the appropriate
                                    medical
                                    perameters </p>
                                <ul>
                                    <li>Heart and Neck </li>
                                </ul>
                                <p>Early reactions</p>
                                <ol>
                                    <li> Reduced and sticky saliva, loss of taste and appetite, altered .l sense of
                                        smell,
                                        naus~a.
                                    <li> Sore thoat, difficulty swallowing, weight loss, fatigue. </li>
                                    <li> Skin changes: redneks,
                                        irritation, scaliness, blistering or
                                        ulceration, color cha~ge, thickening, hair loss.</li>
                                    <li> Hoarseness, cough,lbss of voice, and swelling of airway. </li>

                                    <li> Blockage and crustigg of
                                        nasal passages.</li>
                                    <li> Inflammation of ear canal, feeling of "stopped up" ear, hearing loss,
                                        dizziness,
                                    </li>
                                    <li> Dry and irritable eye(s)</li>
                                    <li> In children, these reaptions are likely to be intensified by I. chemotherapy
                                        before,
                                        during or after radiation therapy.</li>
                                    <li> In children, depression of blood count leading to increased 1 risk of infection
                                        and/pr
                                        bleeding is more common.

                                    </li>
                                </ol>
                                <ol>
                                    <p>Late Reactions</p>
                                    <li>Dry mounth and alte~ed sense, or loss, of taste.</li>
                                    <li> Tooth decay and gum changes.</li>
                                    <li> Bone damage, espefially in jaws.</li>
                                    <li> Stiffness and limitation of jaw movement</li>
                                    <li> Changes in sakin tekture and/or coloration, permanent hair
                                        loss, and scarring of Is kin</li>
                                    <li>Swelling of tissues, particularly under the chin.</li>
                                    <li>Throat damage causing hoarseness, pain or difficulty I breathing or swallowing.
                                    <li> Eye damage causing dye eye(s), cataract, loss of viison, or
                                        loss of eye. I</li>
                                    <li> Ear damage causing dryness of ear canal, fluid collection in
                                        middle ear, hearing Ioss.</li>
                                    <li>Brain, spinal cord or nerve damage causing alteration of thinking ability or
                                        memory,
                                        and/or
                                        loss of strength, feeling or coordination in any part of the body.</li>
                                    <li> Pituitary or thyroid glknd damage requiring long-term hormone replacement
                                        therapy.
                                    </li>
                                    <li> In children, there may be addiuonallate reactions.</li>
                                </ol>
                                <p> Disturbance of bone and tissue growth.</p>
                                <ol>
                                    <li> Bone damage to face causing abnormal swelling</li>
                                    <li> Brain damage causing a loss of lntellectual.abiiity,
                                        capacity, and reduced intelligence quotient </li>
                                    <li> Second cancers developing in the irradiated area .</li>
                                </ol>
                                <p> Central nervous system. </p>

                                <h4> (A) Early reactions.</h4>
                                <ol>
                                    <li> Skin and scalp reaction with redness, irritation,
                                        blistering, ulceration, change in color, thickening,</li>

                                    <li> Nausea, vomiting, headaches. (iii) Fatigue, drowsiness.
                                    <li> Altered sense of taste or smell.</li>
                                    <li>Inflammation of ear canal, feeling of "stopped-up" ear, hearing
                                        loss, dizziness. </li>
                                    <li> Depression of blood count leading to increased nsklot
                                        infection and/or bleeding.</li>
                                    <li>In children, these reactions are likely to be irttensifi~d by chemotherapy
                                        before,
                                        during
                                        or after radiatioh therapy.</li>
                                    <li> In children, depression of blood count leading to increased risk
                                        of infection and/or bleeding is more common. </li>
                                </ol>
                                <h4> (B) Late reactions. </h4>
                                <ol>

                                    <li> Permanent hair loss of variable degrees, altered regrowth,
                                        texture and color of hair. </li>
                                    <li> Persistent drowsiness and tiredness.</li>
                                    <li> Brain damage causing a loss of some degree of thihking
                                        ability or memory, or personality changes.</li>
                                    <li> Scarring of skin. </li>
                                    <li> Spinal cord or nerve damage causing loss 0f'streng~, feeling
                                        or coordination in any part of the body. </li>
                                    <li> Damage to eye(s), or optic nerve(s) causing loss of. vision. (vii) Ear damage
                                        causing
                                        dryness of ear canal, Mid collection in
                                        middle ear, hearing loss. </li>
                                    <li> Pituitary gland damage requiring long-term hormone
                                        replacement therapy. </li>
                                    <li> In children, there may be additional late reactions.
                                    <li> Disturbances of bone and tissue growth. </li>
                                    <li> Bone damage to spine, causing stunting of growth,
                                        curvature and/or reduction in height. </li>
                                    <li> Bone damage to face, or pelvis causing s~untinglof bone
                                        growth and/or abnormal development. </li>
                                    <li> Brain damage causing a loss of intellectual abili~, learning capacity, and
                                        reduced
                                        intelligence quotient </li>
                                    <li> Second cancers developing in the irradiated area</li>

                                </ol>
                                <p>Thorax</p>


                                <h4> (A) Early reactions.</h4>
                                <ol>
                                    <li> Skin changes: redness, irritation, scali ness, ulceration, chanl19
                                        in color, thickening, hair loss. </li>
                                    <li> Inflammation of esophagus causing pain on swallowing,
                                        heartbum, or sense of obstruction.</li>
                                    <li> Loss of appetite, nausea, vomiting.</li>
                                    <li> Weight loss, weakness, vomiting.</li>
                                    <li> Inflammation of the lung with pain, fever and cough</li>
                                    <li> Inflammation of the heart sac with chest pain and palpitations.</li>
                                    <li>Bleeding or creation iof a fistula resulting from tumor.</li>
                                    <li>Depression of blood count leading to increased risk of infection and/or
                                        bleeding.
                                    </li>
                                    <li>Intermittent electric shock-like feelings in the lower spine or legs on beding
                                        the
                                        neck
                                    </li>
                                    <li>In children,these reactions are likely to be intersified by cheamotherapy
                                        before,during
                                        or after radiation therapy.</li>
                                    <li>In children,depression of blood count leading to increased risk of infection and
                                        /or
                                        bleeding is more common.</li>
                                </ol>
                                <p>(B) Late reactions</p>
                                <ol>
                                    <li>Changes in skin texture and /or coloration,permanent hair loss,scarring skin.
                                    </li>
                                    <li>Damage to heart muscle or arteries or heart sac leading to heart failure.</li>
                                    <li>Nerve damage causing pain,loss of strength or feeling in arm.</li>

                                    <li>Lung scaring shrinkage causing shortness of breath.</li>
                                    <li>Narrowing of easophagus causimng shortness of breath.</li>
                                    <li>construction of heart sac which may require surgical correction</li>
                                    <li>Damages to heart muscles or artieries leading to heart failure.</li>
                                    <li>Fracture of ribs.</li>
                                    <li>Spinal cord damage causing loss of stregth or feeling in arms and legs, and / or
                                        loss of
                                        control of bladder and rectum</li>
                                    <li>In children,there may be additional late reactions.</li>
                                    <ul>
                                        <li>Disturbances of bone and tissue grwoth</li>
                                        <li>Bone damage to spine , causing stuting of growth,curvature and / or
                                            reduction in
                                            height.</li>
                                        <li>Under development or absence of development of female breast.</li>
                                        <li>Second cancers developing in the irradiated area.</li>
                                    </ul>
                                </ol>
                                <p>Breast</p>


                                <h4> (A) Early reactions.</h4>
                                <ol>
                                    <li> Skin changes: redness, irritation, scali ness, ulceration, chanl19
                                        in color, thickening, hair loss. </li>
                                    <li>Breat changes including swelling , tightness, or tenderness</li>
                                    <li> Inflammation of esophagus causing pain on swallowing,
                                        heartbum, or sense of obstruction.</li>
                                    <li> Lung inflammation with cough.</li>

                                    <li> Inflammation of the heart sac with chest pain and palpitations.</li>

                                </ol>
                                <p>(B) Late reactions</p>
                                <ol>
                                    <li>Changes in skin texture and /or coloration,permanent hair loss,scarring skin.
                                    </li>
                                    <li>Breast changes including thickening, firmness,tenderness,shrinkage.</li>
                                    <li>Sweeling of arm</li>
                                    <li>Stiffness and discomfort in shoulder joint
                                    <li>
                                    <li>Rib or lung damage causing pain,loss of strength or feeling in arm.</li>
                                    <li>Damage to heart muscle or arteries or heart sac leading to heart failure.</li>
                                </ol>
                                <p>Abdomen</p>


                                <h4> (A) Early reactions.</h4>
                                <ol>
                                    <li> Skin changes: redness, irritation, scali ness, ulceration, chanl19
                                        in color, thickening, hair loss. </li>
                                    <li> Inflammation of stomach causing pain on indigestion,heartburn and ulcers,
                                        heartbum, or sense of obstruction.</li>
                                    <li> Loss of appetite, nausea, vomiting.</li>
                                    <li> Weight loss, weakness, vomiting.</li>
                                    <li> Inflammation of the bowel causing cramping and diarrhea.</li>
                                    <li>Depression of blood count leading to increased risK of infections and/or
                                        bleeding,
                                    </li>
                                    <li>In children. these reactions are likely to be intensified by chemotherapy
                                        before.
                                        during
                                        and after radiation therapy. </li>
                                    <li>In children. depression of blood count leading to inpreased risk of infection
                                        and/or
                                        bleeding is more common</li>
                                </ol>
                                <p>(B) Late reactions</p>
                                <ol>
                                    <li>Changes in skin texture and/or coloration. permanent hair loss. scarring of
                                        skin.
                                    </li>
                                    <li>Stomach damage causing persistent indigestion. pain. and bleeding.
                                    </li>
                                    <li>Bowel damage causing narrowing or adhesions of bowel with obstruction.
                                        ulceration.
                                        or bleeding which may require surgical correction. chronic diarrhea. or poor
                                        absorption
                                        of food elements.</li>
                                    <li>Kidney damage leading to kidney failure and/or high blood pressure.</li>
                                    <li>Liver damage leading to liver failure</li>
                                    <li>Spinal cord or nerve damage causing loss of strength or feeling in legs and/or
                                        loss
                                        of
                                        control of bladder and/or rectum. </li>
                                    <li>In children. there may be additional late reactions</li>
                                    <ul>
                                        <li>Disturbances of bone and tissue grwoth</li>
                                        <li>Bone damage to spine , causing stuting of growth,curvature and / or
                                            reduction in
                                            height.</li>
                                        <li>Under development or absence of development of female breast.</li>
                                        <li>Second cancers developing in the irradiated area.</li>
                                    </ul>
                                </ol>
                                <h4>Female Pelvis</h4>
                                <p>(A) Early reactions. </p>
                                <ol>
                                    <li>Inflammation of bowel causing cramping and' diarrhea. </li>
                                    <li>Inflammation of rectum and anus causing paih, spasm, discharge. bleeding. /li>
                                    <li>Bladder inflammation causing burning, frequ~mcy, spasm, pain, bleeding. </li>
                                    <li>Skin changes: redness. irritation, scatiness, blisterirg or ulceration.
                                        coloration,
                                        thickening. hair loss </li>
                                    <li>Disturbance of menstrual cycle. </li>
                                    <li>Vaginal discharge. pain. irritation. bleeding.</li>
                                    <li>Depression of blood count leading to Increased risk of infection and/or bleeding
                                    </li>
                                    <li>In children. these reactions are likely to be intensified by chemotherapy
                                        before,
                                        during, or after radiation therapy. </li>
                                    <li>In children. depression of blood count leading to increased risk of infection
                                        and/or
                                        bleeding is more common. </li>
                                    <li></li>
                                    <li></li>
                                </ol>
                                <p>(B) Late reactions. </p>
                                <ol>
                                    <li>Bowel damage causing narrowing or adhesiqns of the bowel with obstruction,
                                        ulceration,
                                        bleeding, chronic diarrhea, or poor absorption of food elements and may require
                                        surgical
                                        correction or colostomy </li>
                                    <li>Bladder damage with loss of capacity. frequency or Urination
                                        blood in urine. recurrent urinary infections. pain, or spasm
                                        Which may require urinary diversion and/of' removal of bladder</li>
                                    <li>Changes in skin texture and/or coloration. permanent hair loss. scarring of skin
                                    </li>
                                    <li>Bone damage leadinG to fractures. </li>
                                    <li>Ovarian damage causing infertility. sterility. or premature menopause. </li>
                                    <li>Vaginal damage leading to dryness. shrinkage. pain. bleeding sexual dysfunction,
                                    </li>
                                    <li>Swelling of the genitalia or legs, </li>
                                    <li>Nerve damage causing pain. loss of strength or feeling in legs. and/or loss of
                                        control
                                        of bladder or rectum </li>
                                    <li>Fistula between the bladder and/or bowel and/or vagina. </li>
                                    <li>In children. there may be additional late reactions. </li>
                                    <li>Disturbances of bone and tissue growth. </li>
                                    <li>Bone damage to pelvis and hips causing stunting of bone growth and/or abnormal
                                        development. </li>
                                    <li>Second cancers developing in the irradiated area. </li>
                                </ol>
                                <h4>Male pelvis. </h4>
                                <p>(A) Early reactions. </p>

                                <ol>
                                    <li>Inflammation of bowel causing cramping and' diarrhea. </li>
                                    <li>Inflammation of rectum and anus causing paih, spasm, discharge. bleeding. /li>
                                    <li>Bladder inflammation causing burning, frequ~mcy, spasm, pain, bleeding. </li>
                                    <li>Skin changes: redness. irritation, scatiness, blisterirg or ulceration.
                                        coloration,
                                        thickening. hair loss </li>
                                    <li>Depression of blood count leading to Increased risk of infection and/or bleeding
                                    </li>

                                    <li>In children. these reactions are likely to be intensified by chemotherapy
                                        before,
                                        during, or after radiation therapy. </li>
                                    <li>In children. depression of blood count leading to increased risk of infection
                                        and/or
                                        bleeding is more common. </li>
                                </ol>
                                <p>(B) Late reactions. </p>

                                <ol>

                                    <li>Bowel damage causing narrowing or adhesiqns of the bowel with obstruction,
                                        ulceration,
                                        bleeding, chronic diarrhea, or poor absorption of food elements and may require
                                        surgical
                                        correction or colostomy </li>
                                    <li>Bladder damage with loss of capacity. frequency or Urination
                                        blood in urine. recurrent urinary infections. pain, or spasm
                                        Which may require urinary diversion and/of' removal of bladder</li>
                                    <li>Changes in skin texture and/or coloration. permanent hair loss. scarring of skin
                                    </li>
                                    <li>Bone damage leadinG to fractures. </li>
                                    <li>Swelling of the genitalia or legs, </li>
                                    <li>Nerve damage causing pain. loss of strength or feeling in legs. and/or loss of
                                        control
                                        of bladder or rectum </li>
                                    <li>Fistula between the bowel and other organs. </li>
                                    <li>In children. there may be additional late reactions. </li>
                                    <li>Disturbances of bone and tissue growth. </li>
                                    <li>Bone damage to pelvis and hips causing stunting of bone growth and/or abnormal
                                        development. </li>
                                    <li>Second cancers developing in the irradiated area. </li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ol>
                                <h4>Skin</h4>
                                <p>(A) Early reactions. </p>
                                <ol>
                                    <li> Redness, irritation, or soreness.</li>
                                    <li> Scaliness, ulceration, crusting, oozing, discharge.</li>
                                    <li> Hair loss. </li>
                                    <li> These reactions are likely to be intensified by. chernbtherapy.</li>

                                </ol>
                                <p>(B) Late reactions. </p>
                                <ol>

                                    <li> Changes in skin texture causing scaly or shiny smooth skin,
                                        thickening with contracture, puckering, scarring of skin.</li>
                                    <li> Changes in skin color. </li>
                                    <li> Prominent dilated small blood vessels.</li>
                                    <li> Permanent hair loss.</li>
                                    <li>Chronic or recurrent ulcerations.</li>
                                    <li> Damage to adjacent tissues including underlying bone or
                                        cartilage. </li>
                                    <li> In children, second cancers may develop in the irradiated
                                        area.</li>

                                </ol>
                                <h4>Extremities</h4>

                                <p>(A) Early reactions. </p>

                                <ol>
                                    <li> Skin changes: redness, irritation, scali ness, ulceratibn,
                                        coloration, thickening, hair loss. </li>

                                    <li> Inflammation of soft tissues causing tenderness, swelling, and
                                        interference with movement.</li>
                                    <li> Inflammation of joints causing pain, swelling and limitation of
                                        joint motion. </li>
                                    <li> In children, these reactions are likely to be intensified by chemotherapy
                                        before,
                                        during or after radiation therapy,</li>
                                    <li> In children, depression of blood count leading to increased risk of infection
                                        and/or
                                        bleeding is more common. </li>

                                    <li></li>
                                </ol>
                                <p>(B) Late reactions. </p>

                                <ol>
                                    <li> Changes in skin reaction and/or coloration, permanent hair
                                        loss and scarring of the skin. </li>
                                    <li> Scarring or shrinkage of soft tissues and muscle causing loss
                                        of flexibility and movement, swelling of the 'limb. </li>
                                    <li> Nerve damage causing loss of strength, feeling or :
                                        coordination. </li>
                                    <li> Bone damage causing fracture. </li>
                                    <li> Joint damage causing permanent stiffness, pains end arthritis.</li>

                                    <li>Swelling of limb below the area treated.</li>
                                    <li> In children, there may be additional late reactions.
                                    <li>Disturbances of bone and
                                        tissue growth. </li>
                                    <li> Bone damage to limbs causing stunting of bone growth
                                        and/or abnormal development. </li>
                                    <li> Second cancers developing in the irradiated arsa,</li>

                                </ol>
                                <h4>Total body irradiation. </h4>

                                <p>(A) Early reactions. </p>
                                <ol>
                                    <li>Loss of appetite, nausea, vomiting.</li>
                                    <li> Diarrhea. </li>
                                    <li> Reduced and sticky saliva, swelling of the salivary gland(s),
                                        loss of taste.</li>
                                    <li> Hair loss. </li>
                                    <li>Sore mouth and throat, difficulty swallowing.</li>
                                    <li> permanent destrUCtiorll of bone marrow leading to infection, pleeding, and
                                        possiblejdeath.</li>
                                    <li> Inflammation of the lung with fever, dry cough and difficulty breathing with
                                        possible
                                        fatal lung failure.</li>
                                    <li> Damage to liver with possible fatal liver failure.</li>
                                    <li>In. children, these reactions are likely to be intensified by qhemotherapy
                                        before,
                                        during or after radiation therapy.</li>
                                    <li>ln children, depression of blood count leading to increased risk of infection
                                        and/or
                                        bleeding is more common.</li>

                                </ol>
                                <p>(B) Late reactions. </p>

                                <ol>
                                    <li>Lung scarring causing shortness of breath, infection, and fatal lung failure.
                                    </li>
                                    <li> Cataract formation in thle eyes, possible loss of vision. </li>
                                    <li> Testicular damage in males causing sterility.</li>
                                    <li> Ovarian damage in females causing premature menopause
                                        and sterility. </li>
                                    <li> Increased risk of second cancer.</li>

                                </ol>
                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="RadiationTherapyInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="LaproscopicOrThoracoscopicSurgery">
                                    Laproscopic Or ThoracoscopicSurgery
                                </mat-checkbox>



                                <p> Laproscopic / Thoracoscopic risks. The following shall be in addition to risks and
                                    hazards
                                    of the same surgery when done as an open
                                    procedure. I
                                </p>
                                <ol>
                                    <li> Damage to adjacent structures, </li>
                                    <li> Abscess and infectious complications. </li>
                                    <li> Trocar site complications (e.g., hematomalbleeding, leakage of
                                        fluid, or hernia formation).</li>
                                    <li> Cardiac dysfunction. </li>
                                    <li> Postoperative pneumothorax.</li>
                                    <li> Subcutaneous emphysema. </li>
                                    <li> Conversion of the procedure to an open procedure. </li>

                                </ol>

                                <p> Use of a power morcellator in laparoscopic surgery. </p>
                                <ol>
                                    <li> If cancer is present, may incrsaas the risk of the spread of
                                        catcer. </li>
                                    <li> Increased. risk of damage to adjacent structures.</li>

                                </ol>
                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="LaproscopicOrThoracoscopicSurgeryInitials"
                                        matInput>
                                </mat-form-field>
                            </div>

                        </div>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="PainManagementProcedures">
                                    Pain Management Procedures
                                </mat-checkbox>


                                <p>Neuroaxial procedures (injections into or around spine). </p>
                                <ol>
                                    <li> Failure to reduce pain or 10rsening of pain.</li>
                                    <li> Nerve damage including paralysis (inability to move).</li>
                                    <li> Epidural hematoma (bleeding in
                                        or around spinal canal). </li>
                                    <li> Infection.</li>
                                    <li> Seizure.</li>
                                    <li> persistent leak of spinal fluid which may require surgery.
                                    <li> Breathing and/or heart problems including cardiac arrest (heart
                                        stops beating). </li>
                                    <li> Loss of vision</li>
                                    <li> Stroke </li>

                                </ol>
                                <p>Peripheral and visceral nerve blocks and/or ablations. </p>
                                <ol>
                                    <li> Failure to reduce pain or worsening of pain.</li>
                                    <li> Bleeding.</li>
                                    <li> Neve damage including paralysis (inability to move).</li>
                                    <li> Infection.</li>
                                    <li> Damage to nearby organ or structure.</li>
                                    <li>Seizure</li>

                                </ol>
                                <p> Implantation of pain control devices. </p>
                                <ol>
                                    <li> Failure to reduce pain or worsening of pain.</li>
                                    <li> Epidural hematoma (bleeding in or around spinial canal).</li>
                                    <li> Neve damage including paralysis (inability to move).</li>
                                    <li> Infection.</li>
                                    <li>Persistent leak of spinal fluid which may require surgery</li>

                                </ol>

                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="PainManagementProceduresInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="DentalSurgeryProcedures">
                                    Dental Surgery Procedures
                                </mat-checkbox>


                                <p>Oral surgery. </p>
                                <h4>Extraction (removing teeth).</h4>
                                <ol>

                                    <li>Dry socket (inflammation in the socket of a tooth). </li>
                                    <li>Permanent or t~m~orary numbness or altered sensation </li>
                                    <li>Sinus cormunlcanon (opening from tooth socket into the sinus cavity). </li>
                                    <li>Fracture of alveolus and/or mandible (upper and/or lower jaw). </li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ol>
                                <p>Surgical exposure of tooth in order to facilitate orthodontics </p>
                                <ol>

                                    <li> Injury to tooth or to adjacent teeth and sfructdres. </li>
                                    <li>Failure to get proper attachment to tooth requiring additional procedure.</li>

                                </ol>
                                <h4>Endodontics (deals with diseases of the dental pulp), </h4>
                                <p> Apicoectomy (surgical removal of root tip or end of the tooth with or without
                                    sealing
                                    it).
                                </p>
                                <ol>

                                    <li>Shrinkage of the gums and crown margin ex~osure.i </li>
                                    <li>Sinus communication (opening from tooth socket into the , sinus cavity). </li>
                                    <li>Displacement of teeth or foreign bodies into nearby tissues spaces, and
                                        cavities.
                                    </li>

                                </ol>
                                <p>Root amputation (surgical removal of portion ione rcot of a multi-rooted tooth). </p>
                                <ol>
                                    <li>Shrinkage of the gums and crown margin ex~osure.i </li>
                                    <li>Sinus communication (opening from tooth socket into the , sinus cavity). </li>
                                    <li>Displacement of teeth or foreign bodies into nearby tissues spaces, and
                                        cavities.
                                    </li>
                                    <li></li>
                                </ol>
                                <p> Root Canal therapy (from an occlusal access in 'order to clean and fill the canal
                                    system).
                                </p>
                                <ol>
                                    <li>Instrument separation (tiny files which break Within the tooth canal system).
                                    </li>
                                    <li>Fenestration (penetration of walls of tooth intd adjacbnt tissue). </li>
                                    <li>Failure to find and/or adequately fill all canals</li>
                                    <li> Expression of irrigants or filling material past the apex of the tooth
                                        (chemicals
                                        used
                                        to clean or materials used to fill a root may go out the end of the root and
                                        cause
                                        pain
                                        or swelling). </li>
                                    <li>Damage to adjacent tissues from irrigants or clamps</li>
                                    <li>Fracture or loss of tooth. </li>
                                </ol>
                                <p> Periodontal surgery (surgery of the gums). </p>
                                <ol>
                                    <li>Gingivectomy and gingivoplasty (involves the removallof soft tissue). </li>
                                    <li>Tooth sensitivity to hot, cold, sweet, or acid foods</li>
                                    <li>Shrinkage of the gums upon healing resulting in teeth appearing longer and
                                        greater
                                        spaces between some teeth, </li>
                                    <li></li>
                                </ol>

                                <p>Anatomical crown exposure (removal of enlarged gingival tissue and supporting bone to
                                    provide
                                    an anatomically correct gingival relationship). </p>
                                <ol>
                                    <li>Tooth sensitivity to hot. cold. sweet, or acid foods</li>
                                    <li>Shrinkage of the gums upon healing resulting in teeth appearing longer and
                                        greater
                                        spaces between some teeth. </li>

                                </ol>
                                <p>Gingival flap procedure, including root planing (soft tissue flap is iaid back or
                                    removed
                                    to
                                    alIow debridement (cleaning) of the rootsurface and the removal of granulation
                                    tissue
                                    (unhealthy soft tisbue)). </p>
                                <ol>
                                    <li>Permanent or temporary numbness or altered sensation</li>
                                    <li>Tooth sensitivity to hot.1 cold, sweet, or acid foods. </li>
                                    <li>Shrinkage of the gums lupon healing resulting in teeth appearing longer and
                                        greater
                                        spaces between some teeth</li>

                                </ol>
                                <p>Apically positioned flap (~sed to preserve keratinized gingival (a tached gum tissue)
                                    in
                                    conjunction with osseous resection (removal) and second stage implant procedure).
                                </p>
                                <ol>
                                    <li>Permanent or temporary numbness or altered sensation. </li>
                                    <li>Shrinkage of the gums lupon healing resulting in teeth appearing longer and
                                        greater
                                        spaces between some teeth</li>
                                </ol>
                                <p>Clinical crown lengthening (removal of gum tissue and/or bone from around tooth).
                                </p>
                                <ol>
                                    <li>Permanent or temporary numbness or altered sensation</li>
                                    <li>Shrinkage of the gums lupon healing resulting in teeth appearing longer and
                                        greater
                                        spaces between some teeth</li>
                                </ol>
                                <p> Osseous surgery-including flap entry and closure (modification of the bony support
                                    of
                                    the
                                    teeth). </p>
                                <ol>
                                    <li>Permanent or temporary numbness or altered sensation</li>
                                    <li>Tooth sensitivity to hot.1 cold, sweet, or acid foods. </li>
                                    <li>Loss of tooth</li>
                                    <li>Shrinkage of the gums lupon healing resulting in teeth appearing longer and
                                        greater
                                        spaces between some teeth</li>
                                </ol>
                                <p>Guided tissue regeneration-resorbable barrier.(includes membrane removal). </p>
                                <ol>
                                    <li>Permanent or temporary numbness or altered sensation</li>
                                    <li>Shrinkage of the gums lupon healing resulting in teeth appearing longer and
                                        greater
                                        spaces between some teeth</li>
                                    <li>Accidental aspiration (into the lungs) of foreign matter</li>
                                    <li>Rejection of donor materials. </li>

                                </ol>
                                <p> Pedicle soft tissue graft prd1cedure. </p>
                                <ol>
                                    <li>Permanent or temporary numbness or altered sensation</li>
                                    <li>Shrinkage of the gums lupon healing resulting in teeth appearing longer and
                                        greater
                                        spaces between some teeth</li>
                                    <li>Rejection of donor materials. </li>

                                </ol>
                                <p>Fre, soft tissue graft protection-including donor site surgery. </p>
                                <ol>
                                    <li>Permanent or temporary numbness or altered sensation</li>
                                    <li>Shrinkage of the gums lupon healing resulting in teeth appearing longer and
                                        greater
                                        spaces between some teeth</li>
                                    <li>Rejection of donor materials. </li>
                                </ol>
                                <p>Sub spithslial oonnccdve tissue graft procedures</p>
                                <ol>
                                    <li>Permanent or temporary numbness or altered sensation</li>
                                    <li>Rejection of graft. </li>
                                    <li>Shrinkage of the gums lupon healing resulting in teeth appearing longer and
                                        greater
                                        spaces between some teeth</li>

                                </ol>
                                <p>Distal or proximal wedge procedure (taking off dum fissue from the very back of the
                                    last
                                    tooth or between teeth). </p>
                                <ol>
                                    <li>Shrinkage of the gums upon healing resulting in teeth appearing
                                        longer and greater spaces between some teeth.</li>

                                </ol>
                                <p>Soft tissue allograft and connective tissue double pedicle graft
                                    from below (creates or augments gum tissue).
                                </p>
                                <ol>
                                    <li>Permanent or temporary numbness or altered sensation</li>
                                    <li>Tooth sensitivity to hot, cold, sweet, or acid foods. </li>
                                    <li>Shrinkage of the gums upon healing resulting in teeth appearing
                                        longer and greater spaces between some teeth.</li>
                                </ol>
                                <h4>Implant procedures</h4>
                                <p>(A) Bone grafting (replacing missing bone). </p>
                                <ol>
                                    <li>Permanent or temporary numbness or altered sensation</li>

                                    <li>Rejection of bone parflcles or graft from donor or recipient
                                        sites.
                                    </li>
                                    <li>Damage to adjacent teeth or bone, </li>

                                </ol>
                                <p>(B)Surgical placement of implant body. </p>
                                <ol>
                                    <li> Blood vessel or nerve injury.</li>
                                    <li> Damage to adjacent teeth or bone fracture</li>
                                    <li> Sinus communication (opening from tooth socket into the
                                        sinus cavity). </li>
                                    <li> Failure of implant requiring corrective surgery.</li>
                                    <li> Cyst formation, bone loss, or gum disease around the
                                        implant.</li>

                                </ol>










                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="DentalSurgeryProceduresInitials" matInput>
                                </mat-form-field>
                            </div>

                        </div>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step label="Disclosure and Consent Page-1">
                        <div class="row">
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="PlasticSurgery">
                                    Plastic Surgery
                                </mat-checkbox>

                                <p> Augmentation mammoplasty (breast enlargement with implant). </p>
                                <ol>
                                    <li> Bleeding around implant. </li>
                                    <li> Sensory changes or loss of nipple sensitivity.</li>
                                    <li> Failure, deflation. or leaking of implant requiring replabement. </li>
                                    <li> Worsening or
                                        unsatisfactory appearance including asymmetry
                                        (unequal size or shape). </li>
                                    <li> Problems with or the inability to breastfeed.v
                                    <li> Capsular contracture (hardening of breast).</li>

                                </ol>
                                <p> Bilateral breast reduction, </p>
                                <ol>
                                    <li> Skin flap or fat necrosis (injury or death of skin and fal). </li>
                                    <li> Loss of nipple or areola. </li>
                                    <li> Sensory changes or loss of nipple sensitivity. </li> I
                                    <li> Problems with or the inability to breastfeed. </li>
                                    <li> Worsening or unsatisfactory appearance including asymmetry
                                        (unequal size or shape or not desired size). </li>

                                </ol>
                                <p>Rhinoplasty or nasal reconstruction with or without septoplasty
                                    , (repairing the middle wall of the nose):
                                </p>
                                <ol>
                                    <li> Development of new problems, such as perforation of the nasal septum (hole in
                                        wall
                                        between the right and left halves of the nose)
                                        or breathing difficulty, </li>
                                    <li> Spinal fluid leak. </li>
                                    <li> Worsening or unsatisfactory eppearence
                                    </li>
                                </ol>
                                <p>Reconstruction and/or plastic surgery opertions of the face and neck, </p>
                                <ol>
                                    <li> Impairment of regional organs such as eye or lip function. </li>
                                    <li> Recurrance of the original condition </li>
                                    <li> Worsening or unsatifactory appearance. </li>
                                </ol>
                                <p>Liposfction (removal of fat by suction). </p>
                                <ol>
                                    <li> Shock.</li>
                                    <li> Pulmonary fat embolism (fat escaping with possible damage to
                                        vita1 organs). </li>
                                    <li> DJmage to skin with possible skin loss</li>
                                    <li>Loose skin. I
                                    <li> w1rsening or unsatisfactory appearance,

                                </ol>
                                <p>Breas reconstruction with other flaps and/or implants. </p>
                                <ol>
                                    <li> Bleeding around imPlant.</li>
                                    <li> sensory changes or loss of nipple sensitivity.</li>
                                    <li> Failure, deflation, or leaking of implant requiring replacement.</li>
                                    <li> Dmage to internal organs.</li>
                                    <li> Worsening or unsatisfactory appearance including asymmetry (urlequal size or
                                        shape),
                                    </li>

                                </ol>
                                <p>Nipple Areolar Reconstruction</p>
                                <ol>

                                    <li> Lobs of graft,</li>

                                    <li> Unsatisfactory eppeereree,v

                                </ol>
                                <p>Pannifulecotomy (removal df skin and fat). </p>

                                <ol>
                                    <li> Persistent swelling in the legs. </li>
                                    <li> Ndrve damage, </li>
                                    <li> Wprsening or unsatisfactory appearance.</li>

                                </ol>
                                <p>Tendonitis, tendon release, ~nd trigger releases</p>

                                <ol>
                                    <li> R1currence of symptoms.</li>
                                    <li> Damage to blood vessels, nerves, tendons, or muscles. </li>
                                    <li> Worsening function.</li>

                                </ol>
                                <p>Breast reconstruction with flaps. </p>

                                <ol>
                                    <li> Damage to blood vessels, nerves, or muscles. </li>
                                    <li> Losss of flap possibly requiring
                                        additional surgery. </li>
                                    <li> Damage to internal organs,</li>
                                    <li> Increased risk of sbdominal wall complications with pregnancy.</li>
                                    <li> Abdominal hemias with abdominal flaps.</li>
                                    <li> Chronic abdominal pain With abdominal flaps,</li>

                                    <li>Worsening or unsatisfact6ry appearance including asymmetry (unequal size or
                                        shape).
                                    </li>
                                </ol>
                                <p>Flap pr graft surgery. </p>

                                <ol>
                                    <li> Damage to blood vessel~, nerves, or muscles,</li>
                                    <li> Deep vein thrombosis (blp'od clot in legs or arms).</li>
                                    <li> Loss of flap possibly requiring
                                        additional surgery. </li>
                                    <li> Wprsening or unsatisfactbry appearance.</li>

                                </ol>
                                <p>Tendons, nerves, or blood vessel repair. </p>

                                <ol>
                                    <li>DAmage to nerves. </li>
                                    <li> Deep vein thrombosis (blood clot in legs or arms),</li>
                                    <li> Repture of repair. </li>
                                    <li> Worsening of function</li>

                                    <li></li>
                                </ol>
                                <p>Reconstruction and/or plastic surgical procedures of the eye and eye region, such as
                                    blepharloplasty, tumor, fracture, lacrimal
                                    surgery, foreign body, abscess, or trauma. See subsection (f) (4) of
                                    this section (relating to eye treatments and procedures)
                                </p>



                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="PlasticSurgeryInitials" matInput>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-checkbox color="primary" formControlName="Radiology">
                                    Radiology
                                </mat-checkbox>

                                <p> Splenoportography (needle injection of contrast media into spleen). </p>
                                <ol>
                                    <li>All associated risks as listed under subsection (b)(2)(B) of this section</li>
                                    <li>Injury to the spleen requiri1ng blood transfusion and/or removal of the spleen
                                    </li>

                                </ol>
                                <p> Chemoembolization </p>
                                <ol>
                                    <li>All associated risks as listed under subsection (b)(2)(B) of this section</li>

                                    <li>Tumorlysis sundrome(rapid death of tumor cells,releasing their conetnts which
                                        can be
                                        harmful)</li>
                                    <li>Injury to or failure of liver</li>
                                    <li>Risks of chemotherapeutic agent(s) utilized</li>
                                    <li>Cholecystitus(inflamtion of the gallblader)(for liver or other Gl embolizations)
                                    </li>
                                    <li>Abscess in the liver or other embolized organ requiring further intervention
                                    </li>
                                    <li>Bioma(collection fo bile in or near the liver requiring drainage)</li>

                                </ol>
                                <p>Radioembolization. </p>
                                <ol>
                                    <li>All associated risks as listed under subsection (b)(2)(B) of this section</li>
                                    <li>Tumorlysis sundrome(rapid death of tumor cells,releasing their conetnts which
                                        can be
                                        harmful)</li>
                                    <li>Injury to or failure of liver (or other organ in which tumor is located) </li>
                                    <li>Radiation complications: pneumonitis (inflammation of lung)
                                        whIch is potentially fatal; inflammation of stomach, intestines,
                                        gallbladder, pancreas; stomach or intestinal ulcer; scarring of liver.
                                    </li>
                                </ol>

                                <p> Thermal and other ablative techniques for treatment of tumors (for
                                    curative intent or palliation) including radiofrequencv ablation
                                    microwave ablation, cryoablation, and high intensity focused ultrasound (HIFU).
                                </p>
                                <ol>
                                    <li>Injury to tumor-containing organ or adjacent organs/structures.</li>
                                    <li>Injury to nearby nerves potentially resulting in temporary or
                                        chronic (continuing) pain and/or loss of use and/or feeling.
                                    </li>
                                    <li>Failure to completely treat tumor. </li>
                                </ol>
                                <p>TIPS. (Transjugular Intrahepatic Portosystemic Shunt) and its
                                    variants such as DIPS (Direct Intrahepatic Portocaval Shunt).
                                </p>
                                <ol>
                                    <li>All associated risks as listed under subsection (b)(2)(B) of this section</li>

                                    <li>Hepatic encephalopathy (confusion/decreased ~bility to think). </li>
                                    <li> Liver failure or injury. </li>
                                    <li>Gallbladder injury. </li>
                                    <li>Hemorrhage (severe bleeding). </li>
                                    <li>Recurrent ascites (fluid building up in abdomen) and/ or bleeding</li>
                                    <li>Kidney failure</li>
                                    <li>Heart failure. </li>
                                    <li>Death. </li>
                                </ol>

                                <p>Myelography</p>
                                <ol>
                                    <li>Chronic (continuing) pain. </li>
                                    <li>Nerve injury with loss of use and/or feeling.</li>
                                    <li>Transient (temporary) headache, nausea, and/or vomiting. </li>
                                    <li>Numbness</li>
                                    <li>Seizure</li>
                                </ol>
                                <p>Percutaneous abscess/fluid collection drainage (percutaneous
                                    abscess/seroma/lymphocele drainage and/or scletosls (incluslve of
                                    from percutaneous, transgluteal, transrectal and transvaginal routes)).
                                </p>
                                <ol>
                                    <li>Sepsis (infection in the blood stream), possibly resulting in shock
                                        (severe decrease in blood pressure). </li>
                                    <li>Injury to nearby organs</li>
                                    <li>Hemorrhage (severe bleeding).</li>
                                    <li>Infection of collection which was not previously infected, or additional
                                        infection
                                        of
                                        abscess</li>
                                </ol>
                                <p>Procedures utilizing prolonged fluoroscopy.
                                </p>
                                <ol>
                                    <li>Skin injury (such as epilation (hair loss), burns, or ulcers). </li>
                                    <li>Cataracts (for procedures in the region of the head)</li>
                                </ol>
                                <mat-form-field class="example-full-width">
                                    <mat-label>INITIALS</mat-label>
                                    <input type="text" formControlName="RadiologyInitials" matInput>
                                </mat-form-field>
                            </div>

                        </div>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <p>You have finished click save</p>
                        </div>
                        <button (click)="save()" class="float-right lgBtCl" mat-button>
                            <mat-icon>save</mat-icon>
                            Save</button>
                    </mat-step>


                    <!-- PAGE-8 -end-->

                    <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                        Successfully Submitted
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </mat-horizontal-stepper>
</div>