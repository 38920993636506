<div class="container ">

    <div class="row">

        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Templates</h1>
            <form (keydown.enter)="$event.preventDefault()" (ngSubmit)="saveTemplate()" class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Template Name</mat-label>
                    <input type="text" id="name" [(ngModel)]="template.name" name="name" required matInput>
                    <mat-error>Please enter Report Name</mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>Shortcut Name/Code</mat-label>
                    <input type="text" id="shortcut" [(ngModel)]="template.shortcut" name="shortcut" required matInput>
                    <mat-error>Please enter shortcut Code</mat-error>
                </mat-form-field>


                <!-- <mat-form-field appearance="fill" class="example-full-width">
                    <mat-label>Template Content</mat-label>

                    <textarea matInput [(ngModel)]="template.content" name="content" required cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5" cdkAutosizeMaxRows="140"
                        matInput></textarea>
                </mat-form-field> -->

                <div>
                    <quill-editor (onContentChanged)="onContentChanged($event)" [style]="{ height: '200px' }">
                    </quill-editor>

                </div>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

            </form>

        </div>
    </div>
</div>