import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FavoriteDignosisCode } from '../Models/favoritediagnosiscode.model';

@Injectable({
  providedIn: 'root',
})
export class FavoriteDignosisCodeService {
  url = environment.Url + 'FavoriteDignosisCodes';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}
  private apiUrl = 'https://pharmacy-api.com/send-prescription'; // Replace with actual API endpoint

  sendFavoriteDignosisCode(body: any) {
    return this.http.post(this.apiUrl, body, {
      headers: { 'Content-Type': 'application/json' },
    });
  }
  registerFavoriteDignosisCode(body): Observable<FavoriteDignosisCode> {
    return this.http
      .post<FavoriteDignosisCode>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  saveMultipleFavoriteDignosisCodes(
    body: FavoriteDignosisCode[]
  ): Observable<FavoriteDignosisCode[]> {
    return this.http
      .post<FavoriteDignosisCode[]>(
        this.url + '/saveMultipleFavoriteDignosisCodes',
        body
      )
      .pipe(catchError(this.handleError));
  }

  getFavoriteDignosisCodes(): Observable<FavoriteDignosisCode[]> {
    return this.http
      .get<FavoriteDignosisCode[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getFavoriteDignosisCodeById(id): Observable<FavoriteDignosisCode> {
    return this.http.get<FavoriteDignosisCode>(this.url + '/' + id);
  }

  updateFavoriteDignosisCode(id, body): Observable<FavoriteDignosisCode> {
    return this.http
      .put<FavoriteDignosisCode>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deleteFavoriteDignosisCode(id): Observable<FavoriteDignosisCode> {
    return this.http
      .delete<FavoriteDignosisCode>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid Prescription');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
