import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Allergy } from 'src/app/Models/allergy.models';
import { EmergencyContact } from 'src/app/Models/emergencycontact.models';
import { Labreport } from 'src/app/Models/labreport.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientIdProof } from 'src/app/Models/patientidproof.model';
import { PatientInsurance } from 'src/app/Models/patientinsurance.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { SurgicalHistory } from 'src/app/Models/surgicalhistory.model';
import { AppointmentService } from 'src/app/services/appointment.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-completed-forms',
  templateUrl: './completed-forms.component.html',
  styleUrls: ['./completed-forms.component.css'],
})
export class CompletedFormsComponent implements OnInit {
  id = 0;
  content_visibility = '';
  Patient: Patient;
  Insurance: PatientInsurance[] = [];
  emContacts: EmergencyContact[] = [];
  allergies: Allergy[] = [];
  surgeries: SurgicalHistory[] = [];
  prescriptions: Prescription[] = [];
  labReport: Labreport[] = [];
  upcomingAppointment;

  displayStyle = 'none';
  labDocument: string = '';
  PatientId: string = '';
  PatientIdProof: PatientIdProof[];
  displayStyle1 = 'none';

  openPopup() {
    this.displayStyle = 'block';
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const labReportImage = document.querySelector(
        '.lab-document img'
      ) as HTMLImageElement;
      if (labReportImage) {
        labReportImage.style.width = '100%';
      }
    }, 0);
  }
  openPopFront() {
    this.displayStyle1 = 'block';
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const labReportImage = document.querySelector(
        '.lab-document img'
      ) as HTMLImageElement;
      if (labReportImage) {
        labReportImage.style.width = '100%';
      }
    }, 0);
  }
  closePopup() {
    this.displayStyle = 'none';
  }
  closePopFront() {
    this.displayStyle1 = 'none';
  }

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private appointmentService: AppointmentService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.PatientId = this.id.toString();
    this.getPatientById(this.id);

    this.getPatientAppointments(this.id);
  }

  getPatientById(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.Patient = res;
    });
  }

  private getPatientAppointments(id: number | string): void {
    this.appointmentService.getPatientBookings().subscribe((res) => {
      let upcomingDate = null;
      const date = new Date();
      res.forEach((data) => {
        if (
          new Date(data.VisitDate).getTime() > date.getTime() &&
          (!upcomingDate || date.getTime() < upcomingDate.getTime())
        ) {
          this.upcomingAppointment = data;
        }
      });
    });
  }

  print() {
    window.print();
  }
  printPage() {
    window.print();
  }
}
