import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SurgicalhistoryService } from '../../services/surgicalhistory.service';
import * as moment from 'moment';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-surgical-history',
  templateUrl: './add-surgical-history.component.html',
  styleUrls: ['./add-surgical-history.component.css'],
})
export class AddSurgicalHistoryComponent implements OnInit {
  @Output() submitAction = new EventEmitter<any>();
  id = 0;
  isSuccess = false;
  constructor(
    private fb: UntypedFormBuilder,
    private sugeryService: SurgicalhistoryService,
    private route: ActivatedRoute,
    private router: Router
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
  }

  srgFrm = this.fb.group({
    SurgeyText: ['', Validators.required],
    DateOfSurgery: ['', Validators.required],
    Description: ['', Validators.required],
  });

  get Description() {
    return this.srgFrm.get('Description');
  }
  get SurgeyText() {
    return this.srgFrm.get('SurgeyText');
  }

  get DateOfSurgery() {
    return this.srgFrm.get('DateOfSurgery');
  }

  formSubmit() {
    let description = this.srgFrm.value['Description'];
    let surgeryText = this.srgFrm.value['SurgeyText'];
    let dateOfSurgery = this.srgFrm.value['DateOfSurgery'];
    dateOfSurgery = moment(dateOfSurgery).format('MM/DD/YYYY');

    let body = {
      PatientId: this.id,
      SurgeyText: surgeryText,
      DateOfSurgery: dateOfSurgery,
      Description: description,
    };

    this.sugeryService.registerSurgicalHistory(body).subscribe((res) => {
      this.isSuccess = true;
      this.srgFrm.reset();
      this.submitAction.emit('');
    });
  }
}
