import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpResponse } from '@microsoft/signalr';
import { HospitalService } from 'src/app/services/hospital.service';
import { MessagingServiceService } from 'src/app/services/messaging-service.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css'],
})
export class MessageComponent implements OnInit {
  messageForm: FormGroup;
  users: any[] = []; // List of doctors, nurses, and patients
  selectedFiles: File[] = [];
  progress: number = 0;
  helper = new JwtHelperService();
  id = 0;
  myToken = window.localStorage.getItem('token');
  messages: any[] = [];
  loggedInUserId: number;
  replyContent: string = '';
  constructor(
    private fb: FormBuilder,
    private messagingService: MessagingServiceService,
    private hospitalService: HospitalService
  ) {
    // Initialize the form
    this.messageForm = this.fb.group({
      recipientId: ['', Validators.required],
      content: ['', Validators.required],
      attachments: [null],
    });
  }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.loggedInUserId = parseInt(decodedToken.nameid);
    // Fetch users (doctors, nurses, patients)
    this.hospitalService
      .gethospitalUsers(this.loggedInUserId)
      .subscribe((res) => {
        this.users = res.Nurses;
      });

    this.getMessages();
  }
  // Fetch the inbox for the logged-in user
  getMessages(): void {
    this.messagingService.getInbox(this.loggedInUserId).subscribe(
      (messages: any[]) => {
        this.messages = messages;
      },
      (error) => {
        console.error('Error fetching messages', error);
      }
    );
  }
  // Handle file selection
  onFileSelect(event: any): void {
    const files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      this.selectedFiles.push(files[i]);
    }
  }

  // Submit the message
  onSendMessage(): void {
    if (this.messageForm.invalid) {
      return;
    }

    const messageData = new FormData();
    messageData.append('senderId', '1'); // Example senderId, replace with actual user ID
    messageData.append(
      'recipientId',
      this.messageForm.get('recipientId')?.value
    );
    messageData.append('content', this.messageForm.get('content')?.value);

    // Attach files to the message
    this.selectedFiles.forEach((file) => {
      messageData.append('attachments', file);
    });

    // Call the messaging service to send the message
    this.messagingService.sendMessage(messageData).subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);
        } else if (event instanceof HttpResponse) {
          console.log('Message sent successfully');
        }
      },
      (error) => {
        console.error('Error sending message', error);
      }
    );

    // Clear form after submission
    this.messageForm.reset();
    this.selectedFiles = [];
    this.progress = 0;
  }

  // Reply to a message
  onReply(message: any): void {
    const reply = {
      senderId: this.loggedInUserId,
      recipientId: message.senderId,
      content: this.replyContent,
      isGroupMessage: false,
    };

    this.messagingService.replyToMessage(reply).subscribe(
      (response) => {
        console.log('Reply sent successfully');
        this.replyContent = ''; // Clear the reply content
        this.getMessages(); // Refresh messages after reply
      },
      (error) => {
        console.error('Error sending reply', error);
      }
    );
  }
}
