import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PatientsPharmacy } from 'src/app/Models/patientspharmacy.model';
import { Prescription } from 'src/app/Models/prescription.model';
import { PatientService } from 'src/app/services/patient.service';
import { PharmacyService } from 'src/app/services/pharmacy.service';

import { PrescriptionService } from 'src/app/services/prescription.service';
import { RefillRequestService } from 'src/app/services/refill-request.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-refill-request',
  templateUrl: './add-refill-request.component.html',
  styleUrls: ['./add-refill-request.component.css'],
})
export class AddRefillRequestComponent implements OnInit {
  id = 0;
  prescription: Prescription;
  pharmacys: PatientsPharmacy[];
  Frm: FormGroup;
  isSuccess = false;
  myPharmacyAddress: string;
  phAddress: string;
  helper = new JwtHelperService();
  patientid = 0;
  myToken = this.tokenService.getToken();
  pharmacyName: string;
  pharmacyAddress: string;
  pharmacy: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private prescriptionService: PrescriptionService,
    private patientService: PatientService,
    private fb: FormBuilder,
    private refillRequestService: RefillRequestService,
    private pharmacyService: PharmacyService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPrescription(this.id);
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.patientid = parseInt(decodedToken.nameid);

    this.Frm = this.fb.group({
      PatientsPharmacyId: [],
      Description: [''],
      PharmacyName: [''],
    });

    this.SelectedPharmacy();
  }

  get PharmacyName() {
    return this.Frm.get('PharmacyName');
  }
  get PatientsPharmacyId() {
    return this.Frm.get('PatientsPharmacyId');
  }

  get Description() {
    return this.Frm.get('Description');
  }
  getPrescription(id) {
    this.prescriptionService.getPrescriptionById(id).subscribe((res) => {
      this.prescription = res;
      this.getPharmacy(res.PatientId);
    });
  }

  getPharmacy(id) {
    this.patientService.getPatientsPharmacy(id).subscribe((res) => {
      this.pharmacys = res.PatientsPharmacys;
    });
  }
  SelectedPharmacy() {
    this.pharmacyService
      .getPatientsPharmacyById(this.PatientsPharmacyId.value)
      .subscribe((res) => {
        this.pharmacy = res.PharmacyName + ',' + res.PharmacyAddress;
      });
  }
  formSubmit() {
    let PatientsPharmacyId = this.Frm.value['PatientsPharmacyId'];
    let pharmacyName = this.pharmacys.filter(
      (it) => it.PatientsPharmacyId == PatientsPharmacyId
    )[0].PharmacyName;
    let pharmacyAddress = this.pharmacys.filter(
      (it) => it.PatientsPharmacyId == PatientsPharmacyId
    )[0].PharmacyAddress;

    let body = {
      ...this.Frm.value,
      DrugName: this.prescription.DrugName,
      Quantity: this.prescription.Quantity,
      StartDate: this.prescription.StartDate,
      EndDate: this.prescription.EndDate,
      DoctorName: this.prescription.DoctorName,
      DoctorId: this.prescription.DoctorId,
      PatientId: this.prescription.PatientId,
      PrescriptionId: this.prescription.PrescriptionId,
      PharmacyName: pharmacyName,
      PharmacyAddress: pharmacyAddress,
      Status: 'Pending',
    };
    this.refillRequestService.registerRefillRequest(body).subscribe(
      (res) => {
        this.isSuccess = true;
        this.router.navigate(['/patientDash']);
      },
      (err) => console.log(err)
    );
  }
}
//NurseId: this.prescription.NurseId,
