import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Doctor } from 'src/app/Models/doctor.model';
import { IcdCode } from 'src/app/Models/icdcode.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { IcdCodeService } from 'src/app/services/icd-code.service';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';
import { VoiceRecognitionAbdomenService } from 'src/app/services/voice-recognition-abdomen.service';
import { VoiceRecognitionAllergiesService } from 'src/app/services/voice-recognition-allergies.service';
import { VoiceRecognitionAssessmentService } from 'src/app/services/voice-recognition-assessment.service';
import { VoiceRecognitionBloodPressureService } from 'src/app/services/voice-recognition-blood-pressure.service';
import { VoiceRecognitionChestService } from 'src/app/services/voice-recognition-chest.service';
import { VoiceRecognitionChiefComplaintService } from 'src/app/services/voice-recognition-chief-complaint.service';
import { VoiceRecognitionExtermitiesService } from 'src/app/services/voice-recognition-extermities.service';
import { VoiceRecognitionFamilyHistoryService } from 'src/app/services/voice-recognition-family-history.service';
import { VoiceRecognitionHeartRateService } from 'src/app/services/voice-recognition-heart-rate.service';
import { VoiceRecognitionHeartService } from 'src/app/services/voice-recognition-heart.service';
import { VoiceRecognitionHeightService } from 'src/app/services/voice-recognition-height.service';
import { VoiceRecognitionHENTService } from 'src/app/services/voice-recognition-hent.service';
import { VoiceRecognitionImpressionService } from 'src/app/services/voice-recognition-impression.service';
import { VoiceRecognitionMedicalService } from 'src/app/services/voice-recognition-medical.service';
import { VoiceRecognitionMedicationsService } from 'src/app/services/voice-recognition-medications.service';
import { VoiceRecognitionNeckService } from 'src/app/services/voice-recognition-neck.service';
import { VoiceRecognitionNeurologicalService } from 'src/app/services/voice-recognition-neurological.service';
import { VoiceRecognitionObjectiveService } from 'src/app/services/voice-recognition-objective.service';
import { VoiceRecognitionPlanService } from 'src/app/services/voice-recognition-plan.service';
import { VoiceRecognitionPresentIllnessService } from 'src/app/services/voice-recognition-present-illness.service';
import { VoiceRecognitionPulseService } from 'src/app/services/voice-recognition-pulse.service';
import { VoiceRecognitionRadiologyService } from 'src/app/services/voice-recognition-radiology.service';
import { VoiceRecognitionRecomendationService } from 'src/app/services/voice-recognition-recomendation.service';
import { VoiceRecognitionReviewOfSymptomsService } from 'src/app/services/voice-recognition-review-of-symptoms.service';
import { VoiceRecognitionSocialHistoryService } from 'src/app/services/voice-recognition-social-history.service';
import { VoiceRecognitionSurgicalService } from 'src/app/services/voice-recognition-surgical.service';
import { VoiceRecognitionTemparatureService } from 'src/app/services/voice-recognition-temparature.service';
import { VoiceRecognitionWeightService } from 'src/app/services/voice-recognition-weight.service';
import { VoiceRecognitionService } from 'src/app/services/voice-recognition.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-patient-visit-by-nurse',
  templateUrl: './add-patient-visit-by-nurse.component.html',
  styleUrls: ['./add-patient-visit-by-nurse.component.css'],
})
export class AddPatientVisitByNurseComponent implements OnInit {
  id = 0;
  myControl = new FormControl('');
  selectedIcdCodes: string[] = [];
  options: string[] = ['New Patient', 'Follow Up'];
  doctorSpeciality: string[] = [
    'Neurology',
    'Neurological Surgery',
    'Orthopedic Surgery',
    'Spinal Surgeon',
  ];
  doctorList: Doctor[] = [];
  isSuccess = false;
  NurseId: Number;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  icdCodeList: IcdCode[] = [];
  filteredValues: IcdCode[];

  ExportedData = '';
  filteredOptions: Observable<IcdCode[]>;
  hospitalId: number;
  listening = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private patientVisitService: PatientvisitService,
    private fb: UntypedFormBuilder,
    private doctorService: DoctorService,
    private cd: ChangeDetectorRef,
    private icdCodeService: IcdCodeService,
    private patientService: PatientService,
    private hospitalService: HospitalService,
    public voiceRecognitionChiefComplaintService: VoiceRecognitionChiefComplaintService,
    public voiceRecognitionPresentIllness: VoiceRecognitionPresentIllnessService,
    public VoiceRecognitionMedical: VoiceRecognitionMedicalService,
    public VoiceRecognitionSurgical: VoiceRecognitionSurgicalService,
    public VoiceRecognitionAllergies: VoiceRecognitionAllergiesService,
    public VoiceRecognitionMedications: VoiceRecognitionMedicationsService,
    public VoiceRecognitionSocialHistory: VoiceRecognitionSocialHistoryService,
    public VoiceRecognitionFamilyHistory: VoiceRecognitionFamilyHistoryService,
    public VoiceRecognitionReviewOfSymptoms: VoiceRecognitionReviewOfSymptomsService,
    public VoiceRecognitionHeight: VoiceRecognitionHeightService,
    public VoiceRecognitionWeight: VoiceRecognitionWeightService,
    public VoiceRecognitionBloodPressure: VoiceRecognitionBloodPressureService,
    public VoiceRecognitionPulse: VoiceRecognitionPulseService,
    public VoiceRecognitionTemparature: VoiceRecognitionTemparatureService,
    public VoiceRecognitionHeartRate: VoiceRecognitionHeartRateService,
    public VoiceRecognitionHENT: VoiceRecognitionHENTService,
    public VoiceRecognitionNeck: VoiceRecognitionNeckService,
    public VoiceRecognitionChest: VoiceRecognitionChestService,
    public VoiceRecognitionHeart: VoiceRecognitionHeartService,
    public VoiceRecognitionAbdomen: VoiceRecognitionAbdomenService,
    public VoiceRecognitionExtermities: VoiceRecognitionExtermitiesService,
    public VoiceRecognitionNeurological: VoiceRecognitionNeurologicalService,
    public VoiceRecognitionRadiology: VoiceRecognitionRadiologyService,
    public VoiceRecognitionImpression: VoiceRecognitionImpressionService,
    public VoiceRecognitionRecomendation: VoiceRecognitionRecomendationService
  , private tokenService: TokenService) {
    this.voiceRecognitionChiefComplaintService.init();
    this.voiceRecognitionPresentIllness.init();
    this.VoiceRecognitionMedical.init();
    this.VoiceRecognitionSurgical.init();
    this.VoiceRecognitionAllergies.init();
    this.VoiceRecognitionMedications.init();
    this.VoiceRecognitionSocialHistory.init();
    this.VoiceRecognitionFamilyHistory.init();
    this.VoiceRecognitionReviewOfSymptoms.init();
    this.VoiceRecognitionHeight.init();
    this.VoiceRecognitionWeight.init();
    this.VoiceRecognitionBloodPressure.init();
    this.VoiceRecognitionPulse.init();
    this.VoiceRecognitionTemparature.init();
    this.VoiceRecognitionHeartRate.init();
    this.VoiceRecognitionHENT.init();
    this.VoiceRecognitionNeck.init();
    this.VoiceRecognitionChest.init();
    this.VoiceRecognitionHeart.init();
    this.VoiceRecognitionAbdomen.init();
    this.VoiceRecognitionExtermities.init();
    this.VoiceRecognitionNeurological.init();
    this.VoiceRecognitionRadiology.init();
    this.VoiceRecognitionImpression.init();
    this.VoiceRecognitionRecomendation.init();
  }

  startChiefComplaintService() {
    this.voiceRecognitionChiefComplaintService.start();
    this.listening = true;
  }

  stopChiefComplaintService() {
    this.voiceRecognitionChiefComplaintService.stop();
    this.listening = false;
  }

  onChiefComplaintsChange(e) {
    this.voiceRecognitionChiefComplaintService.chiefComplaints = e.target.value;
  }

  onChiefComplaintsBlur() {
    this.startChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopMedicationsService();
    this.stopSocialHistoryService();
    this.stopFamilyHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopSurgicalService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopChestService();
    this.stopNeckService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.stopAllergiesService();
  }
  startPresentIllnessService() {
    this.voiceRecognitionPresentIllness.start();
    this.listening = true;
  }

  stopPresentIllnessService() {
    this.voiceRecognitionPresentIllness.stop();
    this.listening = false;
  }

  onPresentIllnesssChange(e) {
    this.voiceRecognitionPresentIllness.presentIllness = e.target.value;
  }

  onPresentIllnesssBlur() {
    this.stopChiefComplaintService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopSocialHistoryService();
    this.stopFamilyHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.stopMedicalService(), this.stopSurgicalService();
    this.startPresentIllnessService();
  }
  startMedicalService() {
    this.VoiceRecognitionMedical.start();
    this.listening = true;
  }

  stopMedicalService() {
    this.VoiceRecognitionMedical.stop();
    this.listening = false;
  }

  onMedicalsChange(e) {
    this.VoiceRecognitionMedical.medicalComment = e.target.value;
  }

  onMedicalBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopSurgicalService();
    this.stopMedicationsService();
    this.stopSocialHistoryService();
    this.stopFamilyHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.stopAllergiesService();
    this.startMedicalService();
  }
  startSurgicalService() {
    this.VoiceRecognitionSurgical.start();
    this.listening = true;
  }

  stopSurgicalService() {
    this.VoiceRecognitionSurgical.stop();
    this.listening = false;
  }

  onSurgicalsChange(e) {
    this.VoiceRecognitionSurgical.surgical = e.target.value;
  }

  onSurgicalBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopMedicationsService();
    this.stopSocialHistoryService();
    this.stopFamilyHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopAllergiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startSurgicalService();
  }
  startAllergiesService() {
    this.VoiceRecognitionAllergies.start();
    this.listening = true;
  }

  stopAllergiesService() {
    this.VoiceRecognitionAllergies.stop();
    this.listening = false;
  }

  onAllergiessChange(e) {
    this.VoiceRecognitionAllergies.allergies = e.target.value;
  }

  onAllergiesBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopSocialHistoryService();
    this.stopFamilyHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopMedicationsService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startAllergiesService();
  }
  startMedicationsService() {
    this.VoiceRecognitionMedications.start();
    this.listening = true;
  }

  stopMedicationsService() {
    this.VoiceRecognitionMedications.stop();
    this.listening = false;
  }

  onMedicationssChange(e) {
    this.VoiceRecognitionMedications.medication = e.target.value;
  }

  onMedicationsBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopSocialHistoryService();
    this.stopFamilyHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();

    this.startMedicationsService();
  }
  startFamilyHistoryService() {
    this.VoiceRecognitionFamilyHistory.start();
    this.listening = true;
  }

  stopFamilyHistoryService() {
    this.VoiceRecognitionFamilyHistory.stop();
    this.listening = false;
  }

  onFamilyHistorysChange(e) {
    this.VoiceRecognitionFamilyHistory.familyHistory = e.target.value;
  }

  onFamilyHistoryBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();

    this.startFamilyHistoryService();
  }
  startSocialHistoryService() {
    this.VoiceRecognitionSocialHistory.start();
    this.listening = true;
  }

  stopSocialHistoryService() {
    this.VoiceRecognitionSocialHistory.stop();
    this.listening = false;
  }

  onSocialHistorysChange(e) {
    this.VoiceRecognitionSocialHistory.socialhsitory = e.target.value;
  }

  onSocialHistoryBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopHeightService();
    this.stopReviewOfSymptomsService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startSocialHistoryService();
  }
  startReviewOfSymptomsService() {
    this.VoiceRecognitionReviewOfSymptoms.start();
    this.listening = true;
  }

  stopReviewOfSymptomsService() {
    this.VoiceRecognitionReviewOfSymptoms.stop();
    this.listening = false;
  }

  onReviewOfSymptomssChange(e) {
    this.VoiceRecognitionReviewOfSymptoms.reviewofsymptoms = e.target.value;
  }

  onReviewOfSymptomsBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startReviewOfSymptomsService();
  }
  startHeightService() {
    this.VoiceRecognitionHeight.start();
    this.listening = true;
  }

  stopHeightService() {
    this.VoiceRecognitionHeight.stop();
    this.listening = false;
  }

  onHeightsChange(e) {
    this.VoiceRecognitionHeight.height = e.target.value;
  }

  onHeightBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startHeightService();
  }
  startWeightService() {
    this.VoiceRecognitionWeight.start();
    this.listening = true;
  }

  stopWeightService() {
    this.VoiceRecognitionWeight.stop();
    this.listening = false;
  }

  onWeightsChange(e) {
    this.VoiceRecognitionWeight.weight = e.target.value;
  }

  onWeightBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startWeightService();
  }
  startBloodPressureService() {
    this.VoiceRecognitionBloodPressure.start();
    this.listening = true;
  }

  stopBloodPressureService() {
    this.VoiceRecognitionBloodPressure.stop();
    this.listening = false;
  }

  onBloodPressuresChange(e) {
    this.VoiceRecognitionBloodPressure.bloodPressure = e.target.value;
  }

  onBloodPressureBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startBloodPressureService();
  }
  startPulseService() {
    this.VoiceRecognitionPulse.start();
    this.listening = true;
  }

  stopPulseService() {
    this.VoiceRecognitionPulse.stop();
    this.listening = false;
  }

  onPulsesChange(e) {
    this.VoiceRecognitionPulse.pulse = e.target.value;
  }

  onPulseBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startPulseService();
  }
  startTemparatureService() {
    this.VoiceRecognitionTemparature.start();
    this.listening = true;
  }

  stopTemparatureService() {
    this.VoiceRecognitionTemparature.stop();
    this.listening = false;
  }

  onTemparaturesChange(e) {
    this.VoiceRecognitionTemparature.temparature = e.target.value;
  }

  onTemparatureBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopHENTService();
    this.stopHeartRateService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startTemparatureService();
  }
  startHeartRateService() {
    this.VoiceRecognitionHeartRate.start();
    this.listening = true;
  }

  stopHeartRateService() {
    this.VoiceRecognitionHeartRate.stop();
    this.listening = false;
  }

  onHeartRatesChange(e) {
    this.VoiceRecognitionHeartRate.heartRate = e.target.value;
  }

  onHeartRateBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startHeartRateService();
  }
  startHENTService() {
    this.VoiceRecognitionHENT.start();
    this.listening = true;
  }

  stopHENTService() {
    this.VoiceRecognitionHENT.stop();
    this.listening = false;
  }

  onHENTsChange(e) {
    this.VoiceRecognitionHENT.hent = e.target.value;
  }

  onHENTBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startHENTService();
  }
  startNeckService() {
    this.VoiceRecognitionNeck.start();
    this.listening = true;
  }

  stopNeckService() {
    this.VoiceRecognitionNeck.stop();
    this.listening = false;
  }

  onNecksChange(e) {
    this.VoiceRecognitionNeck.neck = e.target.value;
  }

  onNeckBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopChestService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startNeckService();
  }
  startChestService() {
    this.VoiceRecognitionChest.start();
    this.listening = true;
  }

  stopChestService() {
    this.VoiceRecognitionChest.stop();
    this.listening = false;
  }

  onChestsChange(e) {
    this.VoiceRecognitionChest.chest = e.target.value;
  }

  onChestBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();

    this.startChestService();
  }
  startHeartService() {
    this.VoiceRecognitionHeart.start();
    this.listening = true;
  }

  stopHeartService() {
    this.VoiceRecognitionHeart.stop();
    this.listening = false;
  }

  onHeartsChange(e) {
    this.VoiceRecognitionHeart.heart = e.target.value;
  }

  onHeartBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();

    this.startHeartService();
  }
  startAbdomenService() {
    this.VoiceRecognitionAbdomen.start();
    this.listening = true;
  }

  stopAbdomenService() {
    this.VoiceRecognitionAbdomen.stop();
    this.listening = false;
  }

  onAbdomensChange(e) {
    this.VoiceRecognitionAbdomen.abdomen = e.target.value;
  }

  onAbdomenBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.startAbdomenService();
  }
  startExtermitiesService() {
    this.VoiceRecognitionExtermities.start();
    this.listening = true;
  }

  stopExtermitiesService() {
    this.VoiceRecognitionExtermities.stop();
    this.listening = false;
  }

  onExtermitiessChange(e) {
    this.VoiceRecognitionExtermities.extermities = e.target.value;
  }

  onExtermitiesBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.stopAbdomenService();
    this.startExtermitiesService();
  }
  startNeurologicalService() {
    this.VoiceRecognitionNeurological.start();
    this.listening = true;
  }

  stopNeurologicalService() {
    this.VoiceRecognitionNeurological.stop();
    this.listening = false;
  }

  onNeurologicalsChange(e) {
    this.VoiceRecognitionNeurological.neurological = e.target.value;
  }

  onNeurologicalBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopExtermitiesService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.stopAbdomenService();

    this.startNeurologicalService();
  }
  startRadiologyService() {
    this.VoiceRecognitionRadiology.start();
    this.listening = true;
  }

  stopRadiologyService() {
    this.VoiceRecognitionRadiology.stop();
    this.listening = false;
  }

  onRadiologysChange(e) {
    this.VoiceRecognitionRadiology.radiologyComment = e.target.value;
  }

  onRadiologyBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.stopAbdomenService();

    this.startRadiologyService();
  }
  startImpressionService() {
    this.VoiceRecognitionImpression.start();
    this.listening = true;
  }

  stopImpressionService() {
    this.VoiceRecognitionImpression.stop();
    this.listening = false;
  }

  onImpressionsChange(e) {
    this.VoiceRecognitionImpression.impression = e.target.value;
  }

  onImpressionBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopRecomendationService();
    this.stopAbdomenService();

    this.startImpressionService();
  }
  startRecomendationService() {
    this.VoiceRecognitionRecomendation.start();
    this.listening = true;
  }

  stopRecomendationService() {
    this.VoiceRecognitionRecomendation.stop();
    this.listening = false;
  }

  onRecomendationsChange(e) {
    this.VoiceRecognitionRecomendation.recomendation = e.target.value;
  }

  onRecomendationBlur() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopSurgicalService();
    this.stopAllergiesService();
    this.stopMedicationsService();
    this.stopFamilyHistoryService();
    this.stopSocialHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopNeckService();
    this.stopChestService();
    this.stopHeartService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopAbdomenService();

    this.startRecomendationService();
  }
  stopAll() {
    this.stopChiefComplaintService();
    this.stopPresentIllnessService();
    this.stopMedicalService();
    this.stopMedicationsService();
    this.stopSocialHistoryService();
    this.stopFamilyHistoryService();
    this.stopReviewOfSymptomsService();
    this.stopSurgicalService();
    this.stopHeightService();
    this.stopWeightService();
    this.stopBloodPressureService();
    this.stopPulseService();
    this.stopTemparatureService();
    this.stopHeartRateService();
    this.stopHENTService();
    this.stopChestService();
    this.stopNeckService();
    this.stopHeartService();
    this.stopAbdomenService();
    this.stopExtermitiesService();
    this.stopNeurologicalService();
    this.stopRadiologyService();
    this.stopImpressionService();
    this.stopRecomendationService();
    this.stopAllergiesService();
  }
  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getIcdCodes();
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.NurseId = parseInt(decodedToken.nameid);

    this.patientService.getpatienHospitalId(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      // this.getDoctors(this.hospitalId);
    });

    this.IcdCode.valueChanges.subscribe((newValue) => {
      this.filteredValues = this.filterValues(newValue);
    });
  }
  filterValues(search: string): IcdCode[] {
    const filterValue = search.toLowerCase();
    return this.icdCodeList.filter(function (opt) {
      if (opt.Code.includes(filterValue)) {
        return opt;
      }
    });
  }

  Frm = this.fb.group({
    VisitType: ['', Validators.required],
    VistitDate: ['', Validators.required],
    VistTime: ['', Validators.required],
    ChiefComplaint: ['', Validators.required],
    PresentIllness: ['', Validators.required],
    Medical: ['', Validators.required],
    Surgical: ['', Validators.required],
    Allergies: ['', Validators.required],
    Medications: ['', Validators.required],
    SocialHistory: ['', Validators.required],
    FamilyHistory: ['', Validators.required],
    ReviewOfSymptoms: ['', Validators.required],
    Height: ['', Validators.required],
    Weight: ['', Validators.required],
    BloodPressure: ['', Validators.required],
    Pulse: ['', Validators.required],
    Temparature: ['', Validators.required],
    HeartRate: ['', Validators.required],
    HENT: ['', Validators.required],
    Neck: ['', Validators.required],
    Chest: ['', Validators.required],
    Heart: ['', Validators.required],
    Abdomen: ['', Validators.required],
    Extermities: ['', Validators.required],
    Neurological: ['', Validators.required],
    Radiology: ['', Validators.required],
    Impression: ['', Validators.required],
    Recomendation: ['', Validators.required],
    NurseName: ['', Validators.required],
    Signature: ['', Validators.required],
    Date: ['', Validators.required],
    IcdCode: [''],
  });
  addOption(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.viewValue;
    // console.log('value: ', value);

    if (!this.selectedIcdCodes.includes(value)) {
      this.selectedIcdCodes.push(value);
      this.IcdCode.setValue(this.selectedIcdCodes.join(','));
    }
  }
  removeOption(option) {
    const index = this.selectedIcdCodes.indexOf(option);

    if (index >= 0) {
      this.selectedIcdCodes.splice(index, 1);
    }
  }
  get VistTime() {
    return this.Frm.get('VistTime');
  }
  get VistitDate() {
    return this.Frm.get('VistitDate');
  }
  get VisitType() {
    return this.Frm.get('VisitType');
  }
  get HeartRate() {
    return this.Frm.get('HeartRate');
  }
  get Temparature() {
    return this.Frm.get('Temparature');
  }
  get Pulse() {
    return this.Frm.get('Pulse');
  }

  get BloodPressure() {
    return this.Frm.get('BloodPressure');
  }
  get Weight() {
    return this.Frm.get('Weight');
  }

  get Date() {
    return this.Frm.get('Date');
  }
  get Signature() {
    return this.Frm.get('Signature');
  }
  get NurseName() {
    return this.Frm.get('NurseName');
  }
  get Recomendation() {
    return this.Frm.get('Recomendation');
  }
  get Impression() {
    return this.Frm.get('Impression');
  }
  get Radiology() {
    return this.Frm.get('Radiology');
  }
  get Neurological() {
    return this.Frm.get('Neurological');
  }
  get Extermities() {
    return this.Frm.get('Extermities');
  }
  get Abdomen() {
    return this.Frm.get('Abdomen');
  }
  get Heart() {
    return this.Frm.get('Heart');
  }
  get Chest() {
    return this.Frm.get('Chest');
  }

  get Neck() {
    return this.Frm.get('Neck');
  }
  get HENT() {
    return this.Frm.get('HENT');
  }
  get Height() {
    return this.Frm.get('Height');
  }
  get ReviewOfSymptoms() {
    return this.Frm.get('ReviewOfSymptoms');
  }
  get FamilyHistory() {
    return this.Frm.get('FamilyHistory');
  }
  get SocialHistory() {
    return this.Frm.get('SocialHistory');
  }
  get Medications() {
    return this.Frm.get('Medications');
  }
  get Allergies() {
    return this.Frm.get('Allergies');
  }
  get Surgical() {
    return this.Frm.get('Surgical');
  }
  get Medical() {
    return this.Frm.get('Medical');
  }

  get ChiefComplaint() {
    return this.Frm.get('ChiefComplaint');
  }
  get PresentIllness() {
    return this.Frm.get('PresentIllness');
  }
  get IcdCode() {
    return this.Frm.get('IcdCode');
  }

  getIcdCodes() {
    this.icdCodeService.getIcdCodes().subscribe((res) => {
      this.icdCodeList = res;
    });
  }

  formSubmit() {
    let IcdCode = this.Frm.value['IcdCode'];

    let body = {
      ...this.Frm.value,
      PatientId: this.id,
      NurseId: this.NurseId,
      HospitalId: this.hospitalId,
      SoftwareFee: 1.5,
    };
    this.patientVisitService.registerPatientVisit(body).subscribe((res) => {
      this.isSuccess = true;
      this.Frm.reset();
      this.router.navigate(['/nursePatient/', this.id]);
    });
  }
}
