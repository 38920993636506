import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Labreport } from 'src/app/Models/labreport.model';
import { LabTest } from 'src/app/Models/labtest.model';
import { Patient } from 'src/app/Models/patient.model';
import { LabreportService } from 'src/app/services/labreport.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-patient-labreport',
  templateUrl: './view-patient-labreport.component.html',
  styleUrls: ['./view-patient-labreport.component.css'],
})
export class ViewPatientLabreportComponent implements OnInit {
  displayedColumns: string[] = [
    'Date',
    'ReportType',
    'Description',
    'OrderedBy',
    'LabName',
    'ReportedBy',
    'View',
    // 'Delete',
  ];

  id = 0;
  labReport: Labreport[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  ReportType: string = '';
  OrderedBy: string = '';
  Description: string = '';
  ReportedBy: string = '';
  Date: string = '';
  TestName: string = '';
  TestResultValue: string = '';
  ReferanceRange: string = '';
  Units: string = '';
  patient: Patient;
  labTest: LabTest[];

  constructor(
    private patientService: PatientService,
    private labservice: LabreportService,
    private route: ActivatedRoute,
    private router: Router
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.getPatient(this.id);
    this.GetlabReports(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }

  GetlabReports(id) {
    this.patientService.getPatienLabReport(id).subscribe((res) => {
      this.labReport = res.LabReports;

      this.dataSource = new MatTableDataSource(
        res.LabReports.sort(function (x, y) {
          return y.LabReportId - x.LabReportId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }

  delete(id) {
    this.labservice.deleteLabReport(id).subscribe((res) => {
      // this.labReport = this.labReport.filter(x=>x.LabReportId !=id)
      this.GetlabReports(this.id);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  print() {
    window.print();
  }

  public viewLabReport(report: Labreport): void {
    this.Date = report.Date;
    this.ReportType = report.ReportType;
    this.ReportedBy = report.ReportedBy;
    this.OrderedBy = report.OrderedBy;
    this.Description = report.Description;
    this.labTest = report.LabTests;
    // this.TestName = report.TestName;
    // this.TestResultValue = report.TestResultValue;
    // this.ReferanceRange = report.ReferanceRange;
    // this.Units = report.Units;
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
      const labReportImage = document.querySelector(
        '.lab-document img'
      ) as HTMLImageElement;
      if (labReportImage) {
        labReportImage.style.width = '100%';
      }
    }, 0);
  }
}
