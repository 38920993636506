<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">


            <form (keydown.enter)="$event.preventDefault()" [formGroup]="dutyForm" (ngSubmit)="onSubmit()"
                class="example-form">

                <!-- Select Ward -->
                <mat-form-field class="example-full-width">
                    <mat-label>Select Ward</mat-label>
                    <mat-select formControlName="WardId" (selectionChange)="loadWeekCommencings()">
                        <mat-option *ngFor="let ward of wardList" [value]="ward.WardId">
                            {{ ward.WardName }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="dutyForm.controls['WardId'].hasError('required')">Please select a ward</mat-error>
                </mat-form-field>

                <!-- Select Week Commencing -->
                <mat-form-field class="example-full-width">
                    <mat-label>Select Week Commencing</mat-label>
                    <mat-select formControlName="RosterId">
                        <mat-option *ngFor="let date of weekCommencingList" [value]="date.RosterId">
                            {{ date.WeekCommencing }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="dutyForm.controls['RosterId'].hasError('required')">Please select a
                        date</mat-error>
                </mat-form-field>

                <!-- Add Duties Section -->
                <div formArrayName="Duties">
                    <div *ngFor="let duty of Duties.controls; let i = index" [formGroupName]="i" class="duty-row">
                        <mat-form-field class="example-full-width">
                            <mat-label>Employee Name</mat-label>
                            <input matInput formControlName="EmployeeName" placeholder="Enter Employee Name">
                            <mat-error *ngIf="duty.get('EmployeeName').hasError('required')">Employee Name is
                                required</mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Day</mat-label>
                            <mat-label>Day</mat-label>
                            <input type="date" formControlName="Day" matInput>
                            <mat-error>Please enter Day</mat-error>
                            <!-- <mat-select formControlName="Day">
                                <mat-option *ngFor="let day of days" [value]="day">{{ day }}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="duty.get('Day').hasError('required')">Day is required</mat-error> -->
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Duty Duration</mat-label>
                            <input matInput formControlName="DutyDuration" placeholder="Enter Duty Duration">
                            <mat-error *ngIf="duty.get('DutyDuration').hasError('required')">Duty Duration is
                                required</mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Duty Type</mat-label>
                            <mat-select formControlName="DutyType">
                                <mat-option value="ED">Early Day (ED)</mat-option>
                                <mat-option value="MD">Mid Day (MD)</mat-option>
                                <mat-option value="LD">Long Day (LD)</mat-option>
                            </mat-select>
                            <mat-error *ngIf="duty.get('DutyType').hasError('required')">Duty Type is
                                required</mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width">
                            <mat-label>Comments</mat-label>
                            <textarea matInput formControlName="Comments" placeholder="Add comments"></textarea>
                        </mat-form-field>

                        <!-- Remove Duty -->
                        <button mat-icon-button color="warn" (click)="removeDuty(i)" aria-label="Remove duty">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>

                <!-- Add Duty Button -->
                <button mat-button type="button" (click)="addDuty()">
                    <mat-icon>add</mat-icon> Add Duty
                </button>

                <!-- Submit Form -->
                <button mat-raised-button color="primary" type="submit" [disabled]="dutyForm.invalid">
                    <mat-icon>save</mat-icon> Save
                </button>

            </form>
        </div>
    </div>
</div>