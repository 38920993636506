import { Component, OnInit } from '@angular/core';
import { Duty } from 'src/app/Models/duty.mode'; // Ensure the correct path for the Duty model
import { Roster } from 'src/app/Models/roster.model'; // Ensure the correct path for the Roster model
import { RosterService } from 'src/app/services/roster.service'; // Ensure the correct path for the service

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-roster',
  templateUrl: './roster.component.html',
  styleUrls: ['./roster.component.css'],
})
export class RosterComponent implements OnInit {
  rosters: Roster[] = []; // List of all rosters
  selectedRoster: any; // Store the selected roster details
  duties: Duty[] = []; // Store the list of duties

  // Days of the week
  weekDays: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  constructor(private rosterService: RosterService, private tokenService: TokenService) {}

  ngOnInit(): void {
    this.loadRosters(); // Load the list of rosters when the component initializes
  }

  loadRosters(): void {
    this.rosterService.getRosters().subscribe((data) => {
      this.rosters = data; // Assign fetched data to the rosters array
    });
  }

  selectRoster(id: number): void {
    this.rosterService.getRoster(id).subscribe((data) => {
      console.log('Selected Roster:', data);
      this.selectedRoster = data;
      this.duties = data.Duties || [];
      console.log('Duties:', this.duties); // Add this line to check duties
    });
  }
}
