import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { SubscriptionPlan } from '../Models/subscriptionplan.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private apiUrl = 'https://api.example.com/subscriptions'; // API endpoint

  constructor(private http: HttpClient) {}

  // Get all available subscription plans
  getSubscriptionPlans(): Observable<SubscriptionPlan[]> {
    return this.http.get<SubscriptionPlan[]>(`${this.apiUrl}/plans`);
  }

  // Subscribe a facility to a plan
  subscribeFacility(
    facilityId: number,
    planId: number,
    durationMonths: number
  ): Observable<any> {
    const request = { PlanId: planId, DurationMonths: durationMonths };
    return this.http.post(`${this.apiUrl}/subscribe/${facilityId}`, request);
  }

  // Get subscription details for a facility
  getFacilitySubscription(facilityId: number): Observable<Subscription> {
    return this.http.get<Subscription>(
      `${this.apiUrl}/subscription/${facilityId}`
    );
  }
}
