<mat-tab-group (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Recent Prescription">
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">comment</mat-icon>
            Recent Prescription
        </ng-template>

        <ng-container *ngIf="selectedTabIndex === 0">
            <div class="ml-3">
                <div class="row">
                    <div class="col-lg-6 float-right">
                        <mat-form-field class="example-full-width mt-5">
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <button mat-raised-button color="primary" (click)="selectedPrecriptions()">Select Prections to copy</button>

            <div class=" mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort>
                    <!-- <ng-container matColumnDef="PrescriptionId">
                <th mat-header-cell *matHeaderCellDef > Id </th>
                <td mat-cell *matCellDef="let row"> {{row.PrescriptionId}} </td>
              </ng-container> -->

                    <ng-container matColumnDef="DatePrescribed">
                        <th mat-header-cell *matHeaderCellDef> Date Prescribed </th>
                        <td mat-cell *matCellDef="let rowGlobal">
                            <mat-accordion>
                                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            {{ rowGlobal | date: 'MM/dd/yyyy'}}
                                        </mat-panel-title>

                                    </mat-expansion-panel-header>
                                    <mat-panel-content>
                                        <div class="d-flex justify-content-end m-2">
                                            <button type="button" (click)="PrintPrescription(rowGlobal)"
                                                class="btn btn-primary" data-toggle="modal"
                                                data-target=".bd-example-modal-lg"> Print Prescription</button>
                                        </div>
                                        <table>
                                            <thead>
                                                <th>Select</th>
                                                <th>DrugName</th>
                                                <th>Amt./dose</th>
                                                <!-- <th>BrandName</th>-->
                                                <!-- <th>Strength</th> -->
                                                <th>Quantity</th>
                                                <!-- <th>MorningTime</th>
                                            <th>AfternoonTime</th>
                                            <th>EveningTime</th>
                                            <th>NightTime</th>
                                            <th>QTime</th> -->
                                                <th>Instruction</th>
                                                <th>Note</th>
                                                <th>Route</th>
                                                <th>Frequency</th>
                                                <th>Issued Date</th>
                                                <th>End</th>
                                                <th>Status</th>

                                                <th>Update</th>
                                                <th>Delete</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let row of getPrescriptionOfOneDay(rowGlobal)">
                                                    <td><mat-checkbox class="example-margin"
                                                            (change)="selectPrescription($event,row)"></mat-checkbox>
                                                    </td>
                                                    <td>{{row.DrugName}}</td>
                                                    <td>{{row.AmountPerDose}} {{row.Unit}}</td>
                                                    <!-- <td>{{row.BrandName}}</td> -->
                                                    <!-- <td>{{row.Strength}}</td> -->
                                                    <td class="mx-2">{{row.Quantity}} {{row.Unit}}</td>
                                                    <!-- <td>{{row.MorningTime}}</td>
                                              <td class="mx-2">{{row.AfternoonTime}}</td>
                                              <td>{{row.EveningTime}}</td>
                                              <td class="mx-2">{{row.NightTime}}</td>
                                              <td>{{row.QTime}}</td> -->
                                                    <td>{{row.Instruction}}</td>
                                                    <td class="mx-2">{{row.Note}}</td>
                                                    <td class="mx-2">{{row.Route}}</td>
                                                    <td class="mx-2">{{row.Frequency}}</td>
                                                    <td class="mx-2">{{row.StartDate | date: 'MM/dd/yyyy'}}</td>
                                                    <td class="mx-2">{{row.EndDate | date: 'MM/dd/yyyy'}}</td>
                                                    <td class="mx-2">{{row.Status?'Current':'Stopped'}}</td>

                                                    <td>
                                                        <button type="button"
                                                            (click)="openAddAppointmentModal(row.PrescriptionId)"
                                                            mat-mini-fab [disabled]="CurrentNurseId != row.NurseId"
                                                            color="primary">
                                                            <mat-icon matPrefix>edit</mat-icon>
                                                        </button>

                                                        <!-- <button type="button" [routerLink]="['/updatePrescription/',row.PrescriptionId,this.id]"
                                                  mat-mini-fab color="primary">
                                                  <mat-icon matPrefix>edit</mat-icon>
                                                </button> -->
                                                    </td>
                                                    <td>
                                                        <button type="button" (click)="delete(row.PrescriptionId)"
                                                            [disabled]="CurrentNurseId != row.NurseId" mat-mini-fab
                                                            color="accent">

                                                            <mat-icon matPrefix>delete</mat-icon>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>



                                    </mat-panel-content>
                                </mat-expansion-panel>
                            </mat-accordion>



                        </td>
                    </ng-container>



                    <!-- <ng-container matColumnDef="DrugName">
            <th mat-header-cell *matHeaderCellDef> DrugName </th>
            <td mat-cell *matCellDef="let row"> {{row.DrugName}} </td>
          </ng-container>
          <ng-container matColumnDef="BrandName">
            <th mat-header-cell *matHeaderCellDef> BrandName </th>
            <td mat-cell *matCellDef="let row"> {{row.BrandName}} </td>
          </ng-container>
          <ng-container matColumnDef="Strength">
            <th mat-header-cell *matHeaderCellDef> Strength </th>
            <td mat-cell *matCellDef="let row"> {{row.Strength}} </td>
          </ng-container>
          <ng-container matColumnDef="Quantity">
            <th mat-header-cell *matHeaderCellDef> Quantity </th>
            <td mat-cell *matCellDef="let row"> {{row.Quantity}} </td>
          </ng-container>
          <ng-container matColumnDef="MorningTime">
            <th mat-header-cell *matHeaderCellDef> Morning </th>
            <td mat-cell *matCellDef="let row"> {{row.MorningTime}} </td>
          </ng-container>
          <ng-container matColumnDef="AfternoonTime">
            <th mat-header-cell *matHeaderCellDef> Afternoon </th>
            <td mat-cell *matCellDef="let row"> {{row.AfternoonTime}} </td>
          </ng-container>
          <ng-container matColumnDef="EveningTime">
            <th mat-header-cell *matHeaderCellDef> Evening </th>
            <td mat-cell *matCellDef="let row"> {{row.EveningTime}} </td>
          </ng-container>
          <ng-container matColumnDef="NightTime">
            <th mat-header-cell *matHeaderCellDef> Night </th>
            <td mat-cell *matCellDef="let row"> {{row.NightTime}} </td>
          </ng-container>
          <ng-container matColumnDef="QTime">
            <th mat-header-cell *matHeaderCellDef> Q-Time </th>
            <td mat-cell *matCellDef="let row"> {{row.QTime}} </td>
          </ng-container>
          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let row"> {{row.Status?'Current':'Stopped'}} </td>
          </ng-container> -->


                    <!-- <ng-container matColumnDef="Instruction">
            <th class="message" mat-header-cell *matHeaderCellDef> Instruction </th>
            <td class="message" mat-cell *matCellDef="let row"> {{row.Instruction}} </td>
          </ng-container> -->



                    <!-- modal  -->

                    <!-- <ng-container matColumnDef="Update">
            <th mat-header-cell *matHeaderCellDef> Edit</th>
            <td class="mx-5" mat-cell *matCellDef="let row">
              <button type="button" [routerLink]="['/updatePrescription/',row.PrescriptionId]" mat-mini-fab
                color="primary">
                <mat-icon matPrefix>edit</mat-icon>
              </button>
            </td>
          </ng-container> -->





                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                    </tr>
                </table>

                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
            </div>

        </ng-container>


    </mat-tab>
    <mat-tab label="Card View">
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">visibility</mat-icon>
            Card View
        </ng-template>
        <ng-container *ngIf="selectedTabIndex === 1">
            <app-card-view-prescription></app-card-view-prescription>
        </ng-container>
    </mat-tab>
    <mat-tab label="New Presciption">
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">add</mat-icon>
            New Presciption
        </ng-template>
        <ng-container *ngIf="selectedTabIndex === 2">
            <app-add-prescription-by-nurse></app-add-prescription-by-nurse>
        </ng-container>
    </mat-tab>



</mat-tab-group>


<div class="modal w-100 fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Print Presciption</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body " id="printable" #prescriptionList>
                <app-view-hospital></app-view-hospital>
                <div>
                    <div class="areaStyle mb-1">
                        <P>Patient ID: {{patient?.PatientId}} </P>

                        <P>Patient Name: {{patient?.PatientFirstName}} &nbsp; {{patient?.PatientLastName}} </P>
                        <P>Patient D.O.B: {{patient?.PatientDOB}} </P>
                        <P>Patient Address: {{patient?.PatientAddress}} </P>
                    </div>
                    <hr>
                    <div class="areaStyle mb-1 ">
                        <table>
                            <thead>
                                <th>Drug Name</th>
                                <th>Amount/dose</th>
                                <!-- <th>BrandName</th>-->
                                <!-- <th>Strength</th> -->
                                <th>Quantity</th>

                                <th>Refill Duration</th>
                                <th>#Refills</th>
                                <th>Route</th>
                                <th>Frequency</th>
                                <th>Instruction</th>
                                <th>Note</th>
                                <th>Issued Date</th>
                                <th>End Date</th>
                                <th>Status</th>

                            </thead>
                            <tbody>
                                <tr *ngFor="let row of getPrescriptionOfOneDay(currentPrintedPrescriptionDate)">
                                    <td>{{row.DrugName}}</td>
                                    <td>{{row.NoOfTablets}}</td>
                                    <!-- <td>{{row.BrandName}}</td>-->
                                    <!-- <td>{{row.Strength}}</td> -->
                                    <td>{{row.Quantity}}</td>

                                    <td>{{row?.RefillDuration}}</td>
                                    <td>{{row?.Numberofrefills}}</td>
                                    <td>{{row.Route}}</td>
                                    <td>{{row.Frequency}}</td>
                                    <td>{{row.Instruction}}</td>
                                    <td>{{row.Note}}</td>
                                    <td>{{row?.StartDate | date: 'MM/dd/yyyy'}}</td>
                                    <td>{{row?.EndDate | date: 'MM/dd/yyyy'}}</td>
                                    <td>{{row?.Status?'Current':'Stopped'}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <hr>
                        <div *ngFor="let row of getPrescriptionOfOneDay(currentPrintedPrescriptionDate)">
                            <p>Refill Duration: {{row.RefillDuration}} for {{row.DrugName}} &nbsp;&nbsp; &nbsp;&nbsp;
                                &nbsp;&nbsp;
                                &nbsp;&nbsp; &nbsp;&nbsp;
                                <span *ngIf="row.DoctorName"> Approved By: {{row.DoctorName}}</span>&nbsp;&nbsp;
                                &nbsp;&nbsp;

                                <span *ngIf="row.NurseName"> Nurse Practitioner: {{row.NurseName}}</span>
                            </p>



                        </div>

                    </div>
                    <!-- <P class="areaStyle">Instruction: {{row.Instruction}}</P> -->
                    <div class="areaStyle">
                        <!-- <P>Doctor Name: {{row.DoctorName}}</P>
            <P>Doctor Signature: {{row.DoctorSignature}}</P> -->
                        <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5"
                            role="alert">
                            Successfully send Prescription
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer d-flex flex-sm-row flex-column justify-content-between align-items-center">
                <h4>Pharmacy Details</h4>
                <ul class="list-unstyled">

                    <li>Pharmacy Name : <span>{{pharmacy[0]?.PharmacyName}}</span>
                    <li>Pharmacy Address : <span>{{pharmacy[0]?.PharmacyAddress}}</span>
                    <li>Pharmacy Phone : <span>{{pharmacy[0]?.PhoneNumber}}</span>
                    <li>Pharmacy Fax : <span>{{pharmacy[0]?.FaxNumber}}</span>


                </ul>
                <div class="send-email-prescription d-flex align-items-baseline">
                    <mat-form-field class="w-100 mr-3" appearance="outline">
                        <input matInput type="text" [formControl]="email" placeholder="Enter email">
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="mb-1" (click)="sendPresciption()">Send</button>
                </div>
                <div class="send-fax-prescription d-flex align-items-baseline">
                    <mat-form-field class="w-100 mr-3" appearance="outline">
                        <input matInput type="text" [formControl]="fax" placeholder="Enter Fax Number">
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="mb-1" (click)="faxPresciption()">Send</button>
                </div>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Fax has been sent Successfully.
                    <button type="button" class="btn-close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div *ngIf="errorMessage" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    {{errorMessage}}
                    <button type="button" class="close " data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div>
                    <button type="button" (click)="print()" class="btn btn-primary ">Print</button>
                    <button type="button" class="btn btn-secondary mx-3" data-dismiss="modal">Close</button>
                </div>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
        </div>
    </div>
</div>