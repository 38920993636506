<app-navbar></app-navbar>


<div class="forgot-password-container lgForm">
    <h2>Forgot Password</h2>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <mat-label>Enter Your Username</mat-label>
                <input id="username" formControlName="userName" type="text" matInput placeholder="Username"
                    [ngClass]="{'is-invalid': submitted && forgotPasswordForm.controls.userName.invalid}" />
                <div *ngIf="submitted && forgotPasswordForm.controls.userName.invalid" class="invalid-feedback">
                    Username is required.
                </div>

            </mat-form-field>

        </div>
        <button type="submit" class="btn  float-right  lgBtCl" mat-button> <mat-icon>send</mat-icon>Send Reset
            Token</button>
    </form>
    <div *ngIf="successMessage" class="alert alert-success mt-3">{{ successMessage }}</div>
    <div *ngIf="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
</div>