import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import Quill from 'quill';
import { NurseTemplate } from 'src/app/Models/nursetemplate.model';
import { HospitalService } from 'src/app/services/hospital.service';
import { HospitaltemplateService } from 'src/app/services/hospitaltemplate.service';

import { environment } from 'src/environments/environment';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-hospitaltemplate',
  templateUrl: './update-hospitaltemplate.component.html',
  styleUrls: ['./update-hospitaltemplate.component.css'],
})
export class UpdateHospitaltemplateComponent implements OnInit {
  id = 0;
  hospitalId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();

  isSuccess = false;
  editorContent: any = ''; // Your editor content
  template: { name: string; shortcut: string; content: string };

  quillEditor: any;
  previousTranscript: string = '';
  recognition: any;
  isRecognizing: any;

  editorModules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
      ['blockquote', 'code-block'],
      [{ script: 'sub' }, { script: 'super' }],
      ['emoji'], // You can add any additional modules you want
    ],
    clipboard: true, // Enable clipboard for copy-paste
  };
  templates: NurseTemplate[] = [];

  constructor(
    private templateService: HospitaltemplateService,
    private hospitalService: HospitalService,
    private route: ActivatedRoute,
    private router: Router
  , private tokenService: TokenService) {}
  ngOnInit(): void {
    this.quillEditor = new Quill('#editor', {
      theme: 'snow',
      modules: this.editorModules,
    });
    // Bind the text input event
    this.quillEditor.on('text-change', (delta, oldDelta, source) => {
      this.onTextInput();
    });

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.hospitalId = parseInt(decodedToken.nameid);

    this.templateService.getTemplateById(this.id).subscribe((res) => {
      this.template.name = res.Name;
      this.template.shortcut = res.shortcut;
      // this.template.content = res.Content;
      this.quillEditor.clipboard.dangerouslyPasteHTML(res.Content);
    });
    this.template = {
      name: '',
      shortcut: '',
      content: '',
    };
  }

  question_image(event) {
    // console.log(e)
    let formData = new FormData();
    let files = event.target.files;
    formData.append('file', files[0], files[0].name);
  }

  // Update the content on every change in the editor
  onContentChanged(event: any) {
    this.template.content = event.html; // Update the content based on Quill's HTML
  }
  saveTemplate() {
    let body = {
      ...this.template,
      Content: this.quillEditor.root.innerHTML,
      hospitalId: this.hospitalId,
    };

    this.templateService.updateTemplate(this.id, body).subscribe((response) => {
      this.isSuccess = true;
      this.router.navigate(['/hospitalDash/']);
    });
  }

  // Method to handle click on the table row for template selection
  onTableRowClick(template: any) {
    // console.log(template.Content);
    this.quillEditor.clipboard.dangerouslyPasteHTML(template.Content);
    // this.selectTemplate(template.Content);
  }
  //This function is triggered on every keystroke or text area change in the Quill editor
  onTextInput(): void {
    const cursorPosition = this.quillEditor.getSelection()?.index;
    let currentText = this.quillEditor.getText();

    this.templates.forEach((template) => {
      if (currentText.includes(template.shortcut)) {
        const startIndex = currentText.indexOf(template.shortcut);
        const endIndex = startIndex + template.shortcut.length;

        this.quillEditor.deleteText(startIndex, template.shortcut.length);

        const sanitizedContent = DOMPurify.sanitize(template.Content);

        this.quillEditor.clipboard.dangerouslyPasteHTML(
          startIndex,
          sanitizedContent
        );
        this.quillEditor.setSelection(startIndex + sanitizedContent.length, 0);
      }
    });
  }

  startVoiceRecognition() {
    this.recognition = new (window.SpeechRecognition ||
      (window as any).webkitSpeechRecognition)();
    this.recognition.lang = 'en-US';
    this.recognition.continuous = true; // Keep listening
    this.recognition.interimResults = true; // Show real-time speech results
    this.recognition.onresult = (event: any) => {
      const transcript = event.results[event.resultIndex][0].transcript;

      // Only insert the final result or if the text is different from the previous
      if (
        event.results[event.resultIndex].isFinal &&
        transcript !== this.previousTranscript
      ) {
        // console.log('Final Recognized Text:', transcript);

        // Get the current selection (cursor position)
        const range = this.quillEditor.getSelection();

        if (range) {
          // Insert the recognized text at the current cursor position
          this.quillEditor.insertText(range.index, transcript);
        }

        // Update the previousTranscript to prevent duplicate insertions
        this.previousTranscript = transcript;
      }
    };

    this.recognition.onerror = (event: any) => {};
    // Event listener for when recognition ends (you can stop manually here too if needed)
    this.recognition.onend = () => {
      this.isRecognizing = false;
    };
    this.recognition.start();
  }
  // Stop Speech Recognition
  stopRecognition() {
    if (this.isRecognizing) {
      this.recognition.stop(); // Stop the recognition
      this.isRecognizing = false;
    }
  }
}
// Content: this.editorContent.replace(/<\/?p[^>]*>/g, ''),
