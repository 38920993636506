import { Component, AfterViewInit } from '@angular/core';
import { ClinicalAssistanceService } from 'src/app/services/clinical-assistance.service';
import Quill from 'quill';

@Component({
  selector: 'app-clinical-assistance',
  templateUrl: './clinical-assistance.component.html',
  styleUrls: ['./clinical-assistance.component.css'],
})
export class ClinicalAssistanceComponent implements AfterViewInit {
  quillEditor: any;
  recognition: any; // Speech recognition instance
  response: string | null = null;
  previousTranscript: string = '';
  isRecognizing: any;
  editorModules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // Headings
      ['bold', 'italic', 'underline', 'strike'], // Text formatting
      [{ list: 'ordered' }, { list: 'bullet' }], // Lists
      [{ indent: '-1' }, { indent: '+1' }], // Indentation
      [{ align: [] }], // Text alignment
      ['link', 'image', 'video'], // Links, images, videos
      ['clean'], // Remove formatting
    ],
  };

  constructor(private assistanceService: ClinicalAssistanceService) {}

  ngAfterViewInit() {
    // Initialize Quill editor
    this.quillEditor = new Quill('#editor', {
      theme: 'snow',
      modules: this.editorModules,
    });

    // Dynamically adjust the editor height on text changes
    this.quillEditor.on('text-change', () => {
      const editor = document.querySelector('.ql-editor') as HTMLElement;
      const container = document.querySelector('.ql-container') as HTMLElement;
      container.style.height = editor.scrollHeight + 'px';
    });
  }

  generate() {
    // Get the current content from the Quill editor
    const query = this.quillEditor.getText().trim();
    if (query) {
      this.assistanceService.generateDocumentation(query).subscribe(
        (res) => {
          this.insertTextAtCursor(res.result);
        },
        (err) => {
          console.error('Error:', err);
        }
      );
    }
  }

  insertTextAtCursor(text: string) {
    const range = this.quillEditor.getSelection();
    if (range) {
      this.quillEditor.insertText(range.index, text);
      this.quillEditor.setSelection(range.index + text.length); // Move cursor after the inserted text
    } else {
      this.quillEditor.insertText(this.quillEditor.getLength(), text); // Append at the end if no selection
    }
  }

  startSpeechRecognition() {
    this.recognition = new (window.SpeechRecognition ||
      (window as any).webkitSpeechRecognition)();
    this.recognition.lang = 'en-US';
    this.recognition.continuous = true; // Keep listening
    this.recognition.interimResults = true; // Show real-time speech results
    this.recognition.onresult = (event: any) => {
      const transcript = event.results[event.resultIndex][0].transcript;

      // Only insert the final result or if the text is different from the previous
      if (
        event.results[event.resultIndex].isFinal &&
        transcript !== this.previousTranscript
      ) {
        console.log('Final Recognized Text:', transcript);

        // Get the current selection (cursor position)
        const range = this.quillEditor.getSelection();

        if (range) {
          // Insert the recognized text at the current cursor position
          this.quillEditor.insertText(range.index, transcript);
        }

        // Update the previousTranscript to prevent duplicate insertions
        this.previousTranscript = transcript;
      }
    };

    this.recognition.onerror = (event: any) => {
      console.error('Speech recognition error:', event.error);
    };
    // Event listener for when recognition ends (you can stop manually here too if needed)
    this.recognition.onend = () => {
      console.log('Speech recognition ended');
      this.isRecognizing = false;
    };
    this.recognition.start();
  }
  // Stop Speech Recognition
  stopRecognition() {
    if (this.isRecognizing) {
      console.log('Stopping recognition...');
      this.recognition.stop(); // Stop the recognition
      this.isRecognizing = false;
    }
  }
}
