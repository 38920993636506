import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Nurse } from 'src/app/Models/nurse.model';
import { InpatientScreeningMriService } from 'src/app/services/inpatient-screening-mri.service';
import { NurseService } from 'src/app/services/nurse.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-mri-screening',
  templateUrl: './update-mri-screening.component.html',
  styleUrls: ['./update-mri-screening.component.css'],
})
export class UpdateMriScreeningComponent implements OnInit {
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  id = 0;

  nurseId = 0;
  isSuccess = false;
  Frm: FormGroup;
  nurse: Nurse;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private mriScreeningService: InpatientScreeningMriService,
    private router: Router,
    private nurseService: NurseService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.getNurse(this.nurseId);

    this.mriScreeningService
      .getInPatientScreeningMRIById(this.id)
      .subscribe((res) => {
        this.Frm.patchValue(res);
      });
    this.Frm = this.fb.group({
      PatientId: this.id,
      NurseId: this.nurseId,
      HaveYouHadPriorSurgery: [false],
      DateOfSurgery: [''],
      TypeOfSurgery: [''],
      HaveYouEverHadMRIbefore: [''],
      WhenWasLastMRI: [''],

      HaveYouExperiencedAnyProblemsRelatedToPreviuosMRI: [false],
      DescribeProblemsRelatedToPreviuosMRI: [''],
      HaveYouBeenDiagnosedWithCancer: [false],

      WhatTypeOfCancer: [''],

      HaveYouHadRadiation: [''],

      Chemotherapy: [''],

      DoYouHaveHistoryOfRenalDisease: [false],
      IsthereAnyPossibilityThatYouArepregnant: [false],

      BreastFeeding: [false],
      AnySurgeryInThePastSixWeeks: [false],

      BrainSurgery: [false],

      CardiacPacemaker: [false],
      MetalInYourEyes: [false],

      AneurysmClip: [false],
      CardiacStent: [false],
      WireOrStaples: [false],
      HarringtonRod: [false],
      ProstheticLimbs: [false],
      InsulinPump: [false],

      ElectronicImplant: [false],
      HeartValveReplacement: [false],

      MetalRodsPlatesPins: [false],
      NeuroStimulatorOrTens: [false],
      VascularPorterOrCatheter: [false],
      StentShuntFilterCoil: [false],
      WhenWasStentPlaced: [''],
      ImplantedDrugPump: [false],
      ForiegnObjectOrShrapnel: [false],
      EyeProsthesisOrImplant: [false],
      BodyPiercingOrJwellary: [false],
      HearingAids: [false],
      MagneticallyActivatedImplant: [false],
      MedicationPatch: [false],
      SwanganzOrThermodilutionCath: [false],
      PenelieImplant: [false],
      CochlearImaplant: [false],
      BoneFusionStimulator: [false],
      TattooPermanantMakeUp: [false],
      Claustrophobic: [false],
      CanPatientLieStillForExam: [false],
      IsPatientOnEvent: [false],
      PatientSignature: [''],
      Date: [''],
      Time: [''],
      PatientRepresentative: [''],
      NurseName: [''],
    });
  }

  get PatientId() {
    return this.Frm.get('PatientId');
  }
  get NurseId() {
    return this.Frm.get('NurseId');
  }
  get NurseName() {
    return this.Frm.get('NurseName');
  }

  get PatientRepresentative() {
    return this.Frm.get('PatientRepresentative');
  }
  get Time() {
    return this.Frm.get('Time');
  }
  get Date() {
    return this.Frm.get('Date');
  }
  get PatientSignature() {
    return this.Frm.get('PatientSignature');
  }
  get IsPatientOnEvent() {
    return this.Frm.get('IsPatientOnEvent');
  }
  get CanPatientLieStillForExam() {
    return this.Frm.get('CanPatientLieStillForExam');
  }
  get Claustrophobic() {
    return this.Frm.get('Claustrophobic');
  }

  get TattooPermanantMakeUp() {
    return this.Frm.get('TattooPermanantMakeUp');
  }
  get BoneFusionStimulator() {
    return this.Frm.get('BoneFusionStimulator');
  }

  get CochlearImaplant() {
    return this.Frm.get('CochlearImaplant');
  }
  get PenelieImplant() {
    return this.Frm.get('PenelieImplant');
  }
  get SwanganzOrThermodilutionCath() {
    return this.Frm.get('SwanganzOrThermodilutionCath');
  }

  get MedicationPatch() {
    return this.Frm.get('MedicationPatch');
  }
  get MagneticallyActivatedImplant() {
    return this.Frm.get('MagneticallyActivatedImplant');
  }
  get HearingAids() {
    return this.Frm.get('HearingAids');
  }

  get BodyPiercingOrJwellary() {
    return this.Frm.get('BodyPiercingOrJwellary');
  }
  get EyeProsthesisOrImplant() {
    return this.Frm.get('EyeProsthesisOrImplant');
  }
  get ForiegnObjectOrShrapnel() {
    return this.Frm.get('ForiegnObjectOrShrapnel');
  }

  get ImplantedDrugPump() {
    return this.Frm.get('ImplantedDrugPump');
  }

  get WhenWasStentPlaced() {
    return this.Frm.get('WhenWasStentPlaced');
  }
  get StentShuntFilterCoil() {
    return this.Frm.get('StentShuntFilterCoil');
  }
  get VascularPorterOrCatheter() {
    return this.Frm.get('VascularPorterOrCatheter');
  }
  get HaveYouHadPriorSurgery() {
    return this.Frm.get('HaveYouHadPriorSurgery');
  }
  get DateOfSurgery() {
    return this.Frm.get('DateOfSurgery');
  }
  get TypeOfSurgery() {
    return this.Frm.get('TypeOfSurgery');
  }
  get HaveYouEverHadMRIbefore() {
    return this.Frm.get('HaveYouEverHadMRIbefore');
  }
  get WhenWasLastMRI() {
    return this.Frm.get('WhenWasLastMRI');
  }
  get HaveYouExperiencedAnyProblemsRelatedToPreviuosMRI() {
    return this.Frm.get('HaveYouExperiencedAnyProblemsRelatedToPreviuosMRI');
  }

  //B=Background
  get DescribeProblemsRelatedToPreviuosMRI() {
    return this.Frm.get('DescribeProblemsRelatedToPreviuosMRI');
  }
  get HaveYouBeenDiagnosedWithCancer() {
    return this.Frm.get('HaveYouBeenDiagnosedWithCancer');
  }

  get WhatTypeOfCancer() {
    return this.Frm.get('WhatTypeOfCancer');
  }

  get HaveYouHadRadiation() {
    return this.Frm.get('HaveYouHadRadiation');
  }

  get Chemotherapy() {
    return this.Frm.get('Chemotherapy');
  }

  //A=Assessment
  get DoYouHaveHistoryOfRenalDisease() {
    return this.Frm.get('DoYouHaveHistoryOfRenalDisease');
  }
  get IsthereAnyPossibilityThatYouArepregnant() {
    return this.Frm.get('IsthereAnyPossibilityThatYouArepregnant');
  }

  get BreastFeeding() {
    return this.Frm.get('BreastFeeding');
  }

  //R=Recomendations
  get AnySurgeryInThePasSixWeeks() {
    return this.Frm.get('AnySurgeryInThePasSixWeeks');
  }

  get BrainSurgery() {
    return this.Frm.get('BrainSurgery');
  }
  get CardiacPacemaker() {
    return this.Frm.get('CardiacPacemaker');
  }
  get MetalInYourEyes() {
    return this.Frm.get('MetalInYourEyes');
  }

  get AneurysmClip() {
    return this.Frm.get('AneurysmClip');
  }
  get CardiacStent() {
    return this.Frm.get('CardiacStent');
  }
  get WireOrStaples() {
    return this.Frm.get('WireOrStaples');
  }
  get HarringtonRod() {
    return this.Frm.get('HarringtonRod');
  }
  get ProstheticLimbs() {
    return this.Frm.get('ProstheticLimbs');
  }
  get InsulinPump() {
    return this.Frm.get('InsulinPump');
  }
  get ElectronicImplant() {
    return this.Frm.get('ElectronicImplant');
  }
  get HeartValveReplacement() {
    return this.Frm.get('HeartValveReplacement');
  }
  get MetalRodsPlatesPins() {
    return this.Frm.get('MetalRodsPlatesPins');
  }
  get NeuroStimulatorOrTens() {
    return this.Frm.get('NeuroStimulatorOrTens');
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }

  formSubmit() {
    let body = {
      ...this.Frm.value,
      NurseName: this.nurse.NurseName,
    };
    this.mriScreeningService
      .updateInPatientScreeningMRI(this.id, body)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
      });
  }
}
