import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MedicationService } from '../../services/medication.service';
import { NurseService } from '../../services/nurse.service';
import { Nurse } from '../../Models/nurse.model';
import { Medicine } from 'src/app/Models/medicine.model';
import { MedicineService } from 'src/app/services/medicine.service';
import * as moment from 'moment';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-medication',
  templateUrl: './add-medication.component.html',
  styleUrls: ['./add-medication.component.css']
})
export class AddMedicationComponent implements OnInit {
id=0
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken()
  nurse:Nurse
  options: string[] = ['Morning', 'Afternoon', 'Evening','Night'];
  isSuccess= false;
  medicineList:Medicine[];
  nurseId=0;
  medFrm: UntypedFormGroup;


  constructor(private fb:UntypedFormBuilder,private medicineService:MedicineService,private medicationService:MedicationService,
    private router:Router,private route:ActivatedRoute,private nurseService:NurseService, private tokenService: TokenService) { }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);

    this.nurseId  = parseInt(decodedToken.nameid)

    this.route.params.subscribe(res=>{
      this.id = res['id']
    })
    this.getMedicine();


    this.medFrm= this.fb.group({
      NurseName:[''],
      PatientId: this.id,
      NurseId: this.nurseId,
      Date:[''],
      Time:[''],
      Description:[''],
      Comments:[''],
      Signature:[''],
      PartofDay:[''],
      Drugs: this.fb.array([]),
  
    });

    this.addItem();

  }

  



  public addItem(): void {
    const item = this.fb.group({
      DrugName: [''],
      Dosage: [''],
      BrandName: [''],
      NumberOfDrugs: [''],
    });
    this.Drugs.push(item);
  }

  public removeItem(i: number): void {
    this.Drugs.removeAt(i);
  }

  get Drugs(): UntypedFormArray {
    return this.medFrm.get('Drugs') as UntypedFormArray;
  }








get NumberOfDrugs(){
 return this.medFrm.get('NumberOfDrugs')
}
get PartofDay(){
 return this.medFrm.get('PartofDay')
}
get NurseName(){
 return this.medFrm.get('NurseName')
}
get DrugName(){
 return this.medFrm.get('DrugName')
}
get BrandName(){
 return this.medFrm.get('BrandName')
}
get Dosage(){
 return this.medFrm.get('Dosage')
}
get Date(){
 return this.medFrm.get('Date')
}
get Time(){
 return this.medFrm.get('Time')
}
get Comments(){
 return this.medFrm.get('Comments')
}
get Signature(){
 return this.medFrm.get('Signature')
}


getMedicine(){
  this.medicineService.getMedicines().subscribe(res=>{
    this.medicineList = res;
  })
}

getNurse(id){
  this.nurseService.getNurseById(id).subscribe(res=>{
    this.nurse=res
  })

}




  formSubmit(){

    let numberOfDrugs = this.medFrm.value['NumberOfDrugs']
    let partofDay = this.medFrm.value['PartofDay']
    let nurseName = this.medFrm.value['NurseName']
    let drugName = this.medFrm.value['DrugName']
    let dosage = this.medFrm.value['Dosage']
   let brandName = this.medFrm.value['BrandName']
   let date = this.medFrm.value['Date']
date=  moment(date).format('MM/DD/YYYY');

    
    let time = this.medFrm.value['Time']
  
    let comments = this.medFrm.value['Comments']
    let signature = this.medFrm.value['Signature']
    let  nurseId = this.medFrm.value['NurseId']
    // let idoctorName = this.nurseList.filter(it => it.NurseId == nurseId)[0].NurseName;

    let body ={
      "PatientId":this.id,
      "NurseId":this.nurseId,
      "DrugName":drugName,
      "BrandName":brandName,
      "Dosage":dosage,
      "Comments":comments,
      "Date":date,
      "Time":time,
      "NurseName":nurseName,
      "Signature":signature,
      "PartofDay":partofDay,
      "NumberOfDrugs":numberOfDrugs
    }

   
this.medicationService.registerMedication(this.medFrm.value).subscribe(res=>{
 this.isSuccess=true
  this.medFrm.reset();

})
  }
}
