<div class="container ">
    <!-- <div class="row mt-3">
        <div class="col-lg-3 ">

            Patient Name : {{patient.PatientFirstName}}
            {{patient.PatientLastName}},
        </div>
        <div class="col-lg-5">

            D.O.B : {{patient.PatientDOB}},
            Patient No : {{patient.PatientId}}

        </div>
        <div class="col-lg-4"></div>
    </div> -->
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Procedure Test Result</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Test Name/Title</mat-label>
                    <input type="text" formControlName="TestName" matInput>
                    <mat-error>Please enter Report Name</mat-error>
                </mat-form-field>



                <div class="row">
                    <div class="col-lg-11 app-speech-input">
                        <mat-form-field class="example-full-width">
                            <mat-label>Patient Complaints:</mat-label>


                            <textarea matInput (input)="onSubjectiveChange($event)" formControlName="PatientComplaints"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                cdkAutosizeMaxRows="140" [value]="service.message1" matInput
                                (blur)="onSubjectiveBlur()"></textarea>
                            <button type="button" class="app-speech-input-btn" [disabled]="listening"
                                (click)="startService()"></button>

                        </mat-form-field>

                    </div>
                    <button type="button" color="accent" (click)="stopService()"
                        mat-mini-fab><mat-icon>stop</mat-icon></button>
                </div>
                <div class="row">
                    <div class="col-lg-11 app-speech-input">
                        <mat-form-field class="example-full-width">
                            <mat-label>Findings:</mat-label>
                            <textarea matInput (input)="onObjectiveChange($event)" formControlName="Findings"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                cdkAutosizeMaxRows="140" [value]="serviceObjective.Objective" matInput
                                (blur)="onObjectiveBlur()"></textarea>

                            <button type="button" class="app-speech-input-btn" [disabled]="listeningObjective"
                                (click)="startServiceObjective()"></button>

                        </mat-form-field>

                    </div>
                    <button type="button" color="accent" (click)="stopServiceObjective()"
                        mat-mini-fab><mat-icon>stop</mat-icon></button>
                </div>
                <div class="row">
                    <div class="col-lg-11 app-speech-input">
                        <mat-form-field class="example-full-width">
                            <mat-label>Impression:</mat-label>
                            <textarea matInput (input)="onAssessmentChange($event)" formControlName="Impression"
                                cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                                cdkAutosizeMaxRows="140" [value]="serviceAssessment.Assessment" matInput
                                (blur)="onAssessmentBlur()"></textarea>


                            <button type="button" class="app-speech-input-btn" [disabled]="listeningAssessment"
                                (click)="startServiceAssessment()"></button>

                        </mat-form-field>

                    </div>
                    <button type="button" color="accent" (click)="stopServiceAssessment()"
                        mat-mini-fab><mat-icon>stop</mat-icon></button>
                </div>


                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
            <button class="float-right btn-danger mx-3" (click)="resetFn($event)" mat-raised-button>
                reset
            </button>
        </div>
    </div>
</div>