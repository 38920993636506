import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-nerve-conduction-study',
  templateUrl: './add-nerve-conduction-study.component.html',
  styleUrls: ['./add-nerve-conduction-study.component.css'],
})
export class AddNerveConductionStudyComponent implements OnInit {
  isSuccess = false;
  constructor(private fb: FormBuilder, private tokenService: TokenService) {}

  ngOnInit(): void {}

  Frm = this.fb.group({
    Site: [''],
    Muscle: [''],
    Latency: [''],
    LatencyRefms: [''],
    AmplitudemV: [''],
    AmplitudeRefmV: [''],
    AmplitudeRatio: [''],
    Durms: [''],
    Segments: [''],
    Distance: [''],
    Velocity: [''],
    Velocityref: [''],
  });

  formSubmit() {}
}
