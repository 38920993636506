import { Component, OnInit } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-nursing-assessment-by-id',
  templateUrl: './view-nursing-assessment-by-id.component.html',
  styleUrls: ['./view-nursing-assessment-by-id.component.css']
})
export class ViewNursingAssessmentByIdComponent implements OnInit {

  constructor(private tokenService: TokenService) { }

  ngOnInit(): void {
  }

}
