import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseReport } from 'src/app/Models/nursereport.model';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-vitals-chart',
  templateUrl: './vitals-chart.component.html',
  styleUrls: ['./vitals-chart.component.css'],
})
export class VitalsChartComponent implements OnInit {
  heartRateChart: any = {
    series: [
      {
        name: 'Heart Rate',
        data: [],
      },
    ],
    title: {
      text: 'Heart Rate Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  respirationChart: any = {
    series: [
      {
        name: 'Respiration Rate',
        data: [],
      },
    ],
    title: {
      text: 'Respiratory Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  tempratureChart: any = {
    series: [
      {
        name: 'Temprature',
        data: [],
      },
    ],
    title: {
      text: 'Temprature Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };

  bloodPressureChart: any = {
    series: [
      {
        name: 'Systolic',
        data: [],
      },
      {
        name: 'Diastolic',
        data: [],
      },
    ],
    title: {
      text: 'Blood Pressure Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      categories: [],
    },
  };
  OxygenSaturationChart: any = {
    series: [
      {
        name: 'Oxygen Saturation',
        data: [],
      },
    ],
    title: {
      text: 'Oxygen Saturation Chart',
    },
    chart: {
      type: 'line',
    },
    xAxis: {
      type: 'datetime',
    },
  };
  id = 0;
  nurseReports: NurseReport[] = [];

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getNurseReport(this.id);
  }

  getNurseReport(id) {
    this.patientService.getNurseReport(id).subscribe((res) => {
      this.nurseReports = res.NurseReports;
      this.heartRateChart.series[0].data = this.nurseReports.map((report) => [
        new Date(report.createdDate).getTime(),
        report.HeartBeat,
      ]);
      this.OxygenSaturationChart.series[0].data = this.nurseReports.map(
        (report) => [
          new Date(report.createdDate).getTime(),
          report.OxygenSaturation,
        ]
      );
      this.respirationChart.series[0].data = this.nurseReports.map((report) => [
        new Date(report.createdDate).getTime(),
        report.Respiration,
      ]);
      this.tempratureChart.series[0].data = this.nurseReports.map((report) => [
        new Date(report.createdDate).getTime() - 5 * 60 * 60 * 1000,
        report.Temparature,
      ]);
      this.nurseReports.forEach((report: any) => {
        report.BloodPressure = report.BloodPressure?.split('/');
      });
      const bloodPressure = this.nurseReports
        .filter((report) => report.BloodPressure)
        .map((report) => {
          return {
            systolic: report.BloodPressure[0],
            diastolic: report.BloodPressure[1],
            date: report.createdDate,
          };
        });
      const systolic = bloodPressure.map((x) => x.systolic);
      const diastolic = bloodPressure.map((x) => x.diastolic);
      bloodPressure.forEach((rep, index) => {
        this.bloodPressureChart.xAxis.categories[index] = new Date(
          rep.date
        ).toLocaleDateString();
        this.bloodPressureChart.series[0].data = systolic;
        this.bloodPressureChart.series[1].data = diastolic;
      });
    });
  }
}
