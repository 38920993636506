import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FamilyHistory } from 'src/app/Models/familyhistory.model';
import { FamilyhistoryService } from 'src/app/services/familyhistory.service';
import { PatientService } from 'src/app/services/patient.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-patient-view-familyhistory',
  templateUrl: './patient-view-familyhistory.component.html',
  styleUrls: ['./patient-view-familyhistory.component.css'],
})
export class PatientViewFamilyhistoryComponent implements OnInit {
  id = 0;
  familyHistoryList: FamilyHistory[] = [];
  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private familyHistoryService: FamilyhistoryService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.getPatientFamilyHistory(this.id);
  }

  getPatientFamilyHistory(id) {
    this.patientService.getFamilyHistory(id).subscribe((res) => {
      this.familyHistoryList = res.FamilyHistorys;
    });
  }

  print() {
    window.print();
  }

  delete(id) {
    this.familyHistoryService.deleteFamilyHistory(id).subscribe((res) => {
      this.getPatientFamilyHistory(this.id);
    });
  }
}
