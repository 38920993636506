<div>
    <app-tab-nurse-side [tabsArray]="tabs" (onTabChange)="tabChange($event)"></app-tab-nurse-side>
</div>

<div *ngIf="activatedTabIndex ==0">
    <app-add-nursereport></app-add-nursereport>
</div>
<div *ngIf="activatedTabIndex ==1">

    <app-add-nurse-progress-note></app-add-nurse-progress-note>


</div>
<div *ngIf="activatedTabIndex==2">
    <app-add-nurse-note></app-add-nurse-note>

</div>
<div *ngIf="activatedTabIndex ==3">
    <app-add-prescription-by-nurse></app-add-prescription-by-nurse>
</div>

<div *ngIf="activatedTabIndex ==4">

    <app-add-patient-visit-by-nurse></app-add-patient-visit-by-nurse>
</div>
<div *ngIf="activatedTabIndex ==5">
    <app-add-communication-assessment></app-add-communication-assessment>

</div>
<div *ngIf="activatedTabIndex ==6">

    <app-new-orders></app-new-orders>


</div>
<div *ngIf="activatedTabIndex ==7">

    <app-add-refferal></app-add-refferal>

</div>
<div *ngIf="activatedTabIndex ==8">

    <app-add-labreport></app-add-labreport>


</div>
<div *ngIf="activatedTabIndex ==9">

    <app-add-upload-lab-repor></app-add-upload-lab-repor>


</div>
<div *ngIf="activatedTabIndex ==10">

    <app-add-upload-radiology-report></app-add-upload-radiology-report>


</div>
<div *ngIf="activatedTabIndex ==11">

    <app-add-mri></app-add-mri>


</div>
<div *ngIf="activatedTabIndex ==12">

    <app-add-allergy></app-add-allergy>


</div>
<div *ngIf="activatedTabIndex ==13">

    <app-add-surgical-history></app-add-surgical-history>


</div>