import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Ward } from 'src/app/Models/ward.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { RosterService } from 'src/app/services/roster.service';
import { WardService } from 'src/app/services/ward.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-roster',
  templateUrl: './add-roster.component.html',
  styleUrls: ['./add-roster.component.css'],
})
export class AddRosterComponent implements OnInit {
  isSuccess = false;
  helper = new JwtHelperService();

  id = 0;
  myToken = this.tokenService.getToken();
  wardList: Ward[];
  hospitalId: number;
  constructor(
    private fb: UntypedFormBuilder,
    private rosterService: RosterService,
    private hospitalService: HospitalService,
    private doctorService: DoctorService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.doctorService.getDoctorHospital(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.getWard(this.hospitalId);
    });
  }

  Frm = this.fb.group({
    WardId: ['', Validators.required],
    WardWeekCommencing: ['', Validators.required],
  });

  get WardId() {
    return this.Frm.get('WardId');
  }
  get WardName() {
    return this.Frm.get('WardName');
  }
  get WardWeekCommencing() {
    return this.Frm.get('WardWeekCommencing');
  }
  getWard(id) {
    this.hospitalService.getWards(id).subscribe((res) => {
      this.wardList = res.Wards;
    });
  }

  weekCommencing: Date | null = null;

  /**
   * Adjust the selected date to the start of the week (Sunday or Monday)
   */
  adjustToWeekStart(): void {
    if (this.weekCommencing) {
      const dayOfWeek = this.weekCommencing.getDay(); // 0 = Sunday, 1 = Monday, ...
      const adjustment = dayOfWeek; // Adjust to Sunday (day 0) or modify to start with Monday
      this.weekCommencing.setDate(this.weekCommencing.getDate() - adjustment);
    }
  }

  /**
   * Handle the date selection event
   */
  onDateChange(event: any): void {
    this.adjustToWeekStart(); // Ensure the date aligns with the start of the week
    console.log('Week Commencing:', this.weekCommencing);
  }

  formSubmit() {
    let wardId = this.Frm.value['WardId'];
    let wardName = this.wardList.filter((it) => it.WardId == wardId)[0]
      .WardName;

    let body = {
      HospitalId: this.hospitalId,
      WardId: wardId,
      WeekCommencing: this.weekCommencing,
      WardName: wardName,
    };
    // console.log(body);
    this.rosterService.createRoster(body).subscribe((res) => {
      this.isSuccess = true;
      this.Frm.reset();
    });
  }
}
