import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import Quill from 'quill';
import { DoctorTemplateService } from 'src/app/services/doctor-template.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { environment } from 'src/environments/environment';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-doctor-template-editor',
  templateUrl: './update-doctor-template-editor.component.html',
  styleUrls: ['./update-doctor-template-editor.component.css'],
})
export class UpdateDoctorTemplateEditorComponent implements OnInit {
  id = 0;
  doctorId = 0;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  template: { name: string; shortcut: string; content: string };

  quillEditor: any;
  previousTranscript: string = '';
  recognition: any;
  isRecognizing: any;
  isSuccess = false;
  editorContent: any = ''; // Your editor content
  editorModules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
      ['blockquote', 'code-block'],
      [{ script: 'sub' }, { script: 'super' }],
      ['emoji'], // You can add any additional modules you want
    ],
    clipboard: true, // Enable clipboard for copy-paste
  };
  constructor(
    private templateService: DoctorTemplateService,
    private doctorService: DoctorService,
    private route: ActivatedRoute,
    private router: Router
  , private tokenService: TokenService) {}
  ngOnInit(): void {
    this.quillEditor = new Quill('#editor', {
      theme: 'snow',
      modules: this.editorModules,
    });

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);

    this.templateService.getTemplateById(this.id).subscribe((res) => {
      this.template.name = res.Name;
      this.template.shortcut = res.shortcut;
      // this.template.content = res.Content;
      this.quillEditor.clipboard.dangerouslyPasteHTML(res.Content);
    });
    this.template = {
      name: '',
      shortcut: '',
      content: '',
    };
  }

  question_image(event) {
    // console.log(e)
    let formData = new FormData();
    let files = event.target.files;
    formData.append('file', files[0], files[0].name);

    this.doctorService.imageUpload(formData).subscribe(
      (res) => {
        let qImag = `<p><img src="${environment.imgurl + res['path']}"/></p>`;
        // let stImg = environment.imgurl + res['path'];

        this.editorContent.setValue(this.editorContent.value + qImag);
      },
      (err) => console.log(err)
    );
  }

  // Update the content on every change in the editor
  onContentChanged(event: any) {
    this.template.content = event.html; // Update the content based on Quill's HTML
  }
  saveTemplate() {
    let body = {
      ...this.template,
      Content: this.quillEditor.root.innerHTML,
      DoctorId: this.doctorId,
    };

    this.templateService.updateTemplate(this.id, body).subscribe((response) => {
      this.isSuccess = true;
      this.router.navigate(['/doctorDash/']);
    });
  }

  startVoiceRecognition() {
    this.recognition = new (window.SpeechRecognition ||
      (window as any).webkitSpeechRecognition)();
    this.recognition.lang = 'en-US';
    this.recognition.continuous = true; // Keep listening
    this.recognition.interimResults = true; // Show real-time speech results
    this.recognition.onresult = (event: any) => {
      const transcript = event.results[event.resultIndex][0].transcript;

      // Only insert the final result or if the text is different from the previous
      if (
        event.results[event.resultIndex].isFinal &&
        transcript !== this.previousTranscript
      ) {
        // console.log('Final Recognized Text:', transcript);

        // Get the current selection (cursor position)
        const range = this.quillEditor.getSelection();

        if (range) {
          // Insert the recognized text at the current cursor position
          this.quillEditor.insertText(range.index, transcript);
        }

        // Update the previousTranscript to prevent duplicate insertions
        this.previousTranscript = transcript;
      }
    };

    this.recognition.onerror = (event: any) => {};
    // Event listener for when recognition ends (you can stop manually here too if needed)
    this.recognition.onend = () => {
      this.isRecognizing = false;
    };
    this.recognition.start();
  }
  // Stop Speech Recognition
  stopRecognition() {
    if (this.isRecognizing) {
      this.recognition.stop(); // Stop the recognition
      this.isRecognizing = false;
    }
  }
}
// Content: this.editorContent.replace(/<\/?p[^>]*>/g, ''),
