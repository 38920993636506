<div class="pain-chart-container ml-5 mt-5 align-center">
    <canvas #painCanvas class="pain-chart-canvas" (click)="markPainLocation($event)"></canvas>
    <button (click)="saveImage()" class="btn btn-primary mt-3">
        Save Marked Image
    </button>
    <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
        You have successfully submitted the pain chart!
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>