import { Component, OnInit } from '@angular/core';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-allergy',
  templateUrl: './update-allergy.component.html',
  styleUrls: ['./update-allergy.component.css']
})
export class UpdateAllergyComponent implements OnInit {

  constructor(private tokenService: TokenService) { }

  ngOnInit(): void {
  }

}
