import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Patient } from 'src/app/Models/patient.model';
import { RefillRequest } from 'src/app/Models/refillrequest.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { RefillRequestService } from 'src/app/services/refill-request.service';
import { AppovePrescriptionComponent } from '../appove-prescription/appove-prescription.component';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-refill-request',
  templateUrl: './view-refill-request.component.html',
  styleUrls: ['./view-refill-request.component.css'],
})
export class ViewRefillRequestComponent implements OnInit {
  displayedColumns: string[] = [
    'DrugName',
    'StartDate',
    'EndDate',
    'Description',
    'Status',
    'PharmacyName',
    'PharmacyAddress',
    'Detail',
    'Delete',
  ];

  id = 0;
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  reffillRequest: RefillRequest[] = [];
  dataSource: MatTableDataSource<any>;
  radioDocument: string = '';

  isHide = false;
  constructor(
    private doctorService: DoctorService,
    private refillService: RefillRequestService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.GetRefillRequest(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  GetRefillRequest(id) {
    this.doctorService.getDoctorRefillRequest(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(res.RefillRequests);

      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.refillService.deleteRefillRequest(id).subscribe((res) => {
      alert('deleted');
      this.GetRefillRequest(this.id);
    });
  }
  print() {
    window.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  dialogRefs: MatDialogRef<any>[] = [];
  openAddAppointmentModal(userId): void {
    const dialogRef = this.dialog.open(AppovePrescriptionComponent, {
      width: '680px', // You can set the width or other configurations here
      data: { userId: userId },
      disableClose: true,
      hasBackdrop: false,
    });

    this.dialogRefs.push(dialogRef); // Keep track of the opened dialogs

    dialogRef.afterClosed().subscribe(() => {
      // console.log('Dialog for', firstName, 'closed');
      // Remove from the dialogRefs list once closed
      this.dialogRefs = this.dialogRefs.filter((ref) => ref !== dialogRef);
    });
  }

  closeAllDialogs(): void {
    this.dialogRefs.forEach((ref) => ref.close());
    this.dialogRefs = [];
  }
}
