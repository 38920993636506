import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  UntypedFormArray,
} from '@angular/forms';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Ward } from 'src/app/Models/ward.model';
import { DoctorService } from 'src/app/services/doctor.service';
import { HospitalService } from 'src/app/services/hospital.service';
import { RosterService } from 'src/app/services/roster.service';
import { WardService } from 'src/app/services/ward.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-add-duty',
  templateUrl: './add-duty.component.html',
  styleUrls: ['./add-duty.component.css'],
})
export class AddDutyComponent implements OnInit {
  isSuccess = false;
  helper = new JwtHelperService();

  id = 0;
  myToken = this.tokenService.getToken();
  wardList: Ward[];
  hospitalId: number;
  dutyForm: FormGroup;
  weekCommencingList = []; // To be populated based on Ward selection
  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  constructor(
    private fb: FormBuilder,
    private rosterService: RosterService,
    private hospitalService: HospitalService,
    private doctorService: DoctorService,
    private wardService: WardService
  , private tokenService: TokenService) {
    this.dutyForm = this.fb.group({
      WardId: ['', Validators.required],
      RosterId: ['', Validators.required],
      Duties: this.fb.array([]), // Dynamic array of duties
    });
  }

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.id = parseInt(decodedToken.nameid);
    this.doctorService.getDoctorHospital(this.id).subscribe((res) => {
      this.hospitalId = res.Hospital.HospitalId;
      this.loadWards(this.hospitalId);
    });
    this.addDuty();
  }

  addDuty(): void {
    this.Duties.push(
      this.fb.group({
        EmployeeName: ['', Validators.required],
        Day: ['', Validators.required],
        DutyDuration: ['', Validators.required],
        DutyType: ['', Validators.required],
        Comments: [''],
      })
    );
  }
  get Duties(): UntypedFormArray {
    return this.dutyForm.get('Duties') as UntypedFormArray;
  }
  removeDuty(index: number): void {
    this.Duties.removeAt(index);
  }

  get RosterId() {
    return this.dutyForm.get('RosterId');
  }
  get EmployeeName() {
    return this.dutyForm.get('EmployeeName');
  }
  get Day() {
    return this.dutyForm.get('Day');
  }
  get DutyDuration() {
    return this.dutyForm.get('DutyDuration');
  }
  get DutyType() {
    return this.dutyForm.get('DutyType');
  }
  get Comments() {
    return this.dutyForm.get('Comments');
  }

  loadWards(id): void {
    this.hospitalService.getWards(id).subscribe((res) => {
      this.wardList = res.Wards;
    });
  }

  loadWeekCommencings(): void {
    const selectedWard = this.dutyForm.value.WardId;

    // Validate Ward selection
    if (!selectedWard) {
      console.warn('No ward selected');
      return;
    }

    this.wardService.getroster(selectedWard).subscribe(
      (res) => {
        if (res && res.Rosters) {
          this.weekCommencingList = res.Rosters; // Populate week commencing
        } else {
          this.weekCommencingList = [];
        }
      },
      (err) => {
        this.weekCommencingList = []; // Handle API error
      }
    );
  }

  onSubmit(): void {
    if (this.dutyForm.valid) {
      const formValue = this.dutyForm.value;
      const rosterId = formValue.RosterId; // Extract RosterId

      // Loop through Duties array and send each object
      formValue.Duties.forEach((duty: any) => {
        const dutyPayload = {
          ...duty,
          RosterId: rosterId, // Include RosterId in each duty object
        };

        this.rosterService.createDuties(dutyPayload).subscribe(
          (response) => {
            console.log('Duty created successfully:', response);
          },
          (error) => {
            console.error('Error creating duty:', error);
          }
        );
      });
    } else {
      console.warn('Form is invalid', this.dutyForm);
    }
  }
}
