import { Component, OnInit } from '@angular/core';
import { SubscriptionPlan } from 'src/app/Models/subscriptionplan.model';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
})
export class SubscriptionComponent implements OnInit {
  plans: SubscriptionPlan[] = [];
  selectedPlanId: number = null;
  facilityId: number = 1; // This would come from the logged-in user's session or profile

  constructor(private subscriptionService: SubscriptionService) {}

  ngOnInit(): void {
    this.loadPlans();
  }

  loadPlans(): void {
    this.subscriptionService.getSubscriptionPlans().subscribe((plans) => {
      this.plans = plans;
    });
  }

  subscribeToPlan(): void {
    if (this.selectedPlanId) {
      const durationMonths = 12; // Example: subscription for 12 months
      this.subscriptionService
        .subscribeFacility(this.facilityId, this.selectedPlanId, durationMonths)
        .subscribe((response) => {
          alert('Subscription successful!');
        });
    } else {
      alert('Please select a subscription plan.');
    }
  }
}
