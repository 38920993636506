<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">

            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">
                <h1 class="mx-5 my-5">New- Nerve Conduction Studies </h1>

                <h2>Right Median (APB)</h2>
                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Nerve/Sites</mat-label>
                            <input type="text" formControlName="Site" matInput>
                            <mat-error>Please enter a</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Muscle</mat-label>
                            <input type="text" formControlName="Muscle" matInput>
                            <mat-error>Please enter a</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Latency (ms)</mat-label>
                            <input type="text" formControlName="Latency" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Ref(ms)</mat-label>
                            <input type="text" formControlName="LatencyRefms" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Amplitude (mV)</mat-label>
                            <input type="text" formControlName="AmplitudemV" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Ref(mV)</mat-label>
                            <input type="text" formControlName="AmplitudeRefmV" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Amplitude Ratio %</mat-label>
                            <input type="text" formControlName="AmplitudeRatio" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Duration (ms)</mat-label>
                            <input type="text" formControlName="Durms" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Segments</mat-label>
                            <input type="text" formControlName="Segments" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Distance (mm)</mat-label>
                            <input type="text" formControlName="Distance" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Velocity (m/s)</mat-label>
                            <input type="text" formControlName="Velocity" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Ref(m/s)</mat-label>
                            <input type="text" formControlName="Velocityref" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>



                </div>
                <button type="submit" [disabled]="Frm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>

    </div>
</div>