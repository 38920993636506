import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Department } from 'src/app/Models/department.model';
import { Nurse } from 'src/app/Models/nurse.model';
import { Patient } from 'src/app/Models/patient.model';
import { PatientVisit } from 'src/app/Models/patientvisit.model';
import { DepartmentService } from 'src/app/services/department.service';
import { NurseService } from 'src/app/services/nurse.service';
import { PatientService } from 'src/app/services/patient.service';
import { PatientvisitService } from 'src/app/services/patientvisit.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-nurse-patients',
  templateUrl: './nurse-patients.component.html',
  styleUrls: ['./nurse-patients.component.css'],
})
export class NursePatientsComponent implements OnInit {
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();

  id = 0;
  patient: Patient;
  patientVisitList: PatientVisit[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  patientVisitId: PatientVisit[];
  showItem = '';
  nurse: Nurse;
  nurseId = 0;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private ptVisitService: PatientvisitService,
    private nurseService: NurseService
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.nurseId = parseInt(decodedToken.nameid);

    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });

    this.patientService.getPatientvisits(this.id).subscribe((res) => {
      this.patient = res;
      this.filteredList = res.PatientVisits;
      this.patientVisitList = res.PatientVisits;

      //  if(res.PatientVisits.filter(x=>x.VisitType=="In Patient")){
      //   this.isAdmitted
      //  }
    });

    this.getNurse(this.nurseId);
  }

  getNurse(id) {
    this.nurseService.getNurseById(id).subscribe((res) => {
      this.nurse = res;
    });
  }
  printPage() {
    window.print();
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.Date.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.ptVisitService.deletePatientVisit(id).subscribe((res) => {
        this.filteredList = this.patientVisitList.filter(
          (x) => x.PatientVisitId != id
        );
      });
    }
  }
}
