import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Doctor } from 'src/app/Models/doctor.model';
import { DoctorTemplate } from 'src/app/Models/doctortemplate.model';
import { DoctorReportService } from 'src/app/services/doctor-report.service';
import { DoctorService } from 'src/app/services/doctor.service';
export interface Template {
  shortcut: string;
  content: string;
}

// export const templates: Template[] = [
//   {
//     shortcut: '@greeting',
//     content: 'Dear [Name],\n\nThank you for reaching out to us.',
//   },
//   { shortcut: '@closing', content: 'Best regards,\n[Your Name]' },
// ];

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-template-textbox-component',
  templateUrl: './template-textbox-component.component.html',
  styleUrls: ['./template-textbox-component.component.css'],
})
export class TemplateTextboxComponentComponent implements OnInit {
  displayedColumns: string[] = ['Name', 'Delete'];
  templates: DoctorTemplate[] = [];

  id = 0;
  isSuccess = false;
  helper = new JwtHelperService();
  myToken = this.tokenService.getToken();
  UserId: number = 0;
  doctor: Doctor;

  constructor(
    private fb: UntypedFormBuilder,
    private doctorReportService: DoctorReportService,
    private route: ActivatedRoute,
    private doctorService: DoctorService
  , private tokenService: TokenService) {}
  ngOnInit(): void {
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.UserId = parseInt(decodedToken.nameid);
    this.loadTemplates(this.UserId);
  }
  loadTemplates(id) {
    this.doctorService.getDoctorTemplates(id).subscribe((data) => {
      console.log(data);
      this.templates = data.DoctorTemplates;
    });
  }
  textBoxContent: string = '';
  // templates = this.templates;

  // This method is triggered on every keystroke or text area change
  onTextInput(event: any): void {
    const value = event.target.value;

    // Loop through templates and replace shortcut with the actual content
    this.templates.forEach((template) => {
      if (value.includes(template.shortcut)) {
        this.textBoxContent = value.replace(
          template.shortcut,
          template.Content
        );
      }
    });
  }
}
