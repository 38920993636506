import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Medication } from 'src/app/Models/medication.model';
import { PatientService } from 'src/app/services/patient.service';
import {MatDialog} from '@angular/material/dialog';
import { Patient } from 'src/app/Models/patient.model';
import { MedicationService } from 'src/app/services/medication.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-view-medication',
  templateUrl: './view-medication.component.html',
  styleUrls: ['./view-medication.component.css']
})
export class ViewMedicationComponent implements OnInit {
  
  id = 0
  medicationList: Medication[] = [];
  displayedColumns: string[] = ['Date', 'Time', 'ViewReport'];
  dataSource: MatTableDataSource<any>;
  patient: Patient
  currentMedciation!: Medication;
  drugList;
  constructor(private patientService: PatientService, private route: ActivatedRoute, private router: Router, private medicationService: MedicationService, private tokenService: TokenService) { }

  ngOnInit(): void {
    this.route.params.subscribe(res => {
      this.id = res['id'];
    })

    this.getMedication(this.id);
    this.getPatient(this.id);

  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('medicationList') medication!: ElementRef<any>;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }




  getMedication(id) {
    this.patientService.getPatientMedication(id).subscribe(res => {
      let groupingViaCommonProperty = Object.values(
        res.Medications.reduce((acc, current) => {
          acc[current.Date] = acc[current.Date] ?? [];
          acc[current.Date].push(current);
          return acc;
        }, {})
      );

      this.drugList = res.Medications.map(item => item.Drugs)
      this.dataSource = new MatTableDataSource(groupingViaCommonProperty);
      this.dataSource.paginator = this.paginator;


    })

  }
  getDrugs(MedicationId) {
    return this.medicationService.getDrugs(MedicationId);
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe(res => {
      this.patient = res;
    })
  }

  PrintReport(printReport: Medication) {
    this.currentMedciation = printReport;
    
    setTimeout(() => {
      const isModal = document.querySelector('.modal-backdrop.show');
      if (isModal) {
        isModal.classList.remove('modal-backdrop');
      }
    }, 0);
  }

  print() {
    window.print();
  }
}

