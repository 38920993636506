<div class="toolbar mat-elevation-z6"> <button type="button" (click)="logout()"
    class="btn float-right mx-3 my-3 logout " color="info" mat-raised-button>
    <!-- <mat-icon matPrefix>highlight_off</mat-icon> -->
    <span>Logout</span>
  </button>
  <!-- <div class="header">
            <img src="../../../assets/tesss.jpg" class="image" alt="">
            <h4 class="text-white ml-2">Josh Tylor</h4>
           </div> -->

  <!-- *ngIf="!isNewPatient" -->
  <button mat-raised-button class="backG mt-3 ml-3" [matMenuTriggerFor]="crt"><mat-icon>menu</mat-icon>Menu</button>

  <mat-menu class="ItemS" #crt="matMenu">

    <div class="ItemS " (click)="showItem='5'" [ngClass]="{'active': showItem =='5'}">
      <mat-icon mat-list-icon>dashboard</mat-icon>
      <div class="mx-2">Dash Board</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS " (click)="showItem='1'" [ngClass]="{'active': showItem =='1'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2">New Appointment</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='10'" [ngClass]="{'active': showItem =='10'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2"> New History</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <!-- <div class="ItemS" (click)="showItem='11'" [ngClass]="{'active': showItem =='11'}">
      <mat-icon mat-list-icon>add</mat-icon>
      <div class="mx-2"> New Pharmacy</div>
      <mat-icon>navigate_next</mat-icon>
    </div> -->
    <div class="ItemS" (click)="showItem='2'" [ngClass]="{'active': showItem =='2'}">
      <mat-icon mat-list-icon>style</mat-icon>
      <div class="mx-2"> Medical Records</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='18'" [ngClass]="{'active': showItem =='18'}">
      <mat-icon mat-list-icon>style</mat-icon>
      <div class="mx-2"> Progress Notes</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='3'" [ngClass]="{'active': showItem =='3'}">
      <mat-icon mat-list-icon>receipt</mat-icon>
      <div class="mx-2">View Invoices</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='4'" [ngClass]="{'active': showItem =='4'}">
      <mat-icon mat-list-icon>schedule</mat-icon>
      <div class="mx-2"> View Appointment</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <hr>

    <div class="ItemS" (click)="showItem='12'" [ngClass]="{'active': showItem =='12'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">Procedure Test Result</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='13'" [ngClass]="{'active': showItem =='13'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">Nerve Study Result</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='14'" [ngClass]="{'active': showItem =='14'}">
      <mat-icon mat-list-icon>visibility</mat-icon>
      <div class="mx-2">Electromyography Result</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <hr>

    <div class="ItemS" (click)="showItem='15'" [ngClass]="{'active': showItem =='15'}">
      <mat-icon mat-list-icon>schedule</mat-icon>
      <div class="mx-2"> Send Message to Doctor</div>
      <mat-icon>navigate_next</mat-icon>
    </div>

    <div class="ItemS" (click)="showItem='16'" [ngClass]="{'active': showItem =='16'}">
      <mat-icon mat-list-icon>chart</mat-icon>
      <div class="mx-2"> New Pain Chart</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <div class="ItemS" (click)="showItem='17'" [ngClass]="{'active': showItem =='17'}">
      <mat-icon mat-list-icon>chart</mat-icon>
      <div class="mx-2"> Pain Chart</div>
      <mat-icon>navigate_next</mat-icon>
    </div>
    <!-- <div class="ItemS" (click)="showItem='6'" [ngClass]="{'active': showItem =='4'}">
            <mat-icon mat-list-icon>schedule</mat-icon>
            <div class="mx-2"> QRcode Scanner</div>
            <mat-icon>navigate_next</mat-icon>
          </div> -->
  </mat-menu>


</div>


<!-- <span *ngIf="isNewPatient">
  <p>
    <app-patient-intake-items></app-patient-intake-items>
  </p>
</span> -->
<!-- *ngIf="!isNewPatient" -->
<span [ngSwitch]="showItem">

  <p *ngSwitchCase="'1'">
    <app-add-appointment-patient (showAppointmentList)="innerRouting($event)"></app-add-appointment-patient>
  </p>

  <p *ngSwitchCase="'2'">
    <app-ptmedicalrecords></app-ptmedicalrecords>
  </p>

  <p *ngSwitchCase="'3'">
    <app-ptinvoices></app-ptinvoices>
  </p>
  <p *ngSwitchCase="'4'">
    <app-view-appointment></app-view-appointment>
  </p>

  <p *ngSwitchCase="'5'">
    <app-patientsummary></app-patientsummary>

  </p>

  <p *ngSwitchCase="'6'">
    <app-scanner></app-scanner>

  </p>

  <p *ngSwitchCase="'9'">
    <app-patient-intake-items></app-patient-intake-items>
  </p>
  <p *ngSwitchCase="'10'">
    <app-patient-history></app-patient-history>
  </p>
  <p *ngSwitchCase="'11'">
    <app-add-patient-pharmacy></app-add-patient-pharmacy>
  </p>
  <p *ngSwitchCase="'12'">
    <app-view-test-results></app-view-test-results>
  </p>
  <p *ngSwitchCase="'13'">
    <app-view-nerve-study-results></app-view-nerve-study-results>
  </p>
  <p *ngSwitchCase="'14'">
    <app-view-electromyography></app-view-electromyography>
  </p>
  <p *ngSwitchCase="'15'">
    <app-chat-for-patient></app-chat-for-patient>
  </p>
  <p *ngSwitchCase="'16'">
    <app-add-pain-chart></app-add-pain-chart>
  </p>
  <p *ngSwitchCase="'17'">
    <app-view-pain-chart></app-view-pain-chart>
  </p>
  <p *ngSwitchCase="'18'">
    <app-patient-side-progress-note></app-patient-side-progress-note>
  </p>
  <p *ngSwitchDefault>
    <app-patientsummary></app-patientsummary>
  </p>






</span>