<div>
    <div>
        <app-tabs-component [tabsArray]="tabs" (onTabChange)="tabChange($event)"></app-tabs-component>
    </div>
    <div *ngIf="activatedTabIndex ==0">

        <app-doctor-patient-visit></app-doctor-patient-visit>

    </div>

    <div *ngIf="activatedTabIndex ==1">

        <app-view-doctor-report></app-view-doctor-report>

    </div>
    <div *ngIf="activatedTabIndex ==2">

        <app-prescription-display></app-prescription-display>


    </div>
    <div *ngIf="activatedTabIndex ==3">

        <app-view-labreport></app-view-labreport>

    </div>
    <div *ngIf="activatedTabIndex ==4">
        <app-view-radiology-report></app-view-radiology-report>


    </div>
    <div *ngIf="activatedTabIndex ==5">

        <app-view-orders></app-view-orders>

    </div>
    <div *ngIf="activatedTabIndex ==6">
        <app-all-test-results></app-all-test-results>



    </div>
    <div *ngIf="activatedTabIndex ==7">
        <app-view-radiology-report></app-view-radiology-report>



    </div>
    <div *ngIf="activatedTabIndex ==8">
        <app-view-nursereport></app-view-nursereport>



    </div>
    <div *ngIf="activatedTabIndex ==9">

        <app-view-nurse-note></app-view-nurse-note>


    </div>
    <div *ngIf="activatedTabIndex ==10">

        <app-view-doctor-initial-evaluation></app-view-doctor-initial-evaluation>



    </div>
    <div *ngIf="activatedTabIndex ==11">
        <app-view-refferal></app-view-refferal>




    </div>
    <div *ngIf="activatedTabIndex ==12">

        <app-vew-doctor-note></app-vew-doctor-note>

    </div>
    <div *ngIf="activatedTabIndex ==13">

        <app-view-invoice></app-view-invoice>



    </div>
    <div *ngIf="activatedTabIndex ==14">

        <app-view-mri></app-view-mri>



    </div>
    <div *ngIf="activatedTabIndex ==15">

        <app-patientsummary></app-patientsummary>



    </div>
    <div *ngIf="activatedTabIndex ==16">

        <app-view-allergy></app-view-allergy>



    </div>



</div>