import { RadiologyOrder } from './../Models/radilogyorder.model';
import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { environment } from 'src/environments/environment';
import { EmailPayload } from '../Models/email.model';

@Injectable({
  providedIn: 'root',
})
export class RadiologyOrderService {
  url = environment.Url + 'RadiologyOrders';

  imgUrl = environment.Url + 'ImageUpload';
  emailRefferal = environment.Url + 'Emails/refferalEmail';

  header = new HttpHeaders({
    Authorization: 'token' + localStorage.getItem('token'),
  });

  constructor(private http: HttpClient) {}

  imageUpload(body) {
    return this.http.post(this.imgUrl, body);
  }

  registerRadiologyOrder(body): Observable<RadiologyOrder> {
    return this.http
      .post<RadiologyOrder>(this.url, body)
      .pipe(catchError(this.handleError));
  }

  getRadiologyOrders(): Observable<RadiologyOrder[]> {
    return this.http
      .get<RadiologyOrder[]>(this.url)
      .pipe(catchError(this.handleError));
  }
  getRadiologyOrderById(id): Observable<RadiologyOrder> {
    return this.http.get<RadiologyOrder>(this.url + '/' + id);
  }
  sendRadiologyOroder(body): Observable<EmailPayload> {
    return this.http
      .post<EmailPayload>(this.emailRefferal, body)
      .pipe(catchError(this.handleError));
  }
  updateRadiologyOrder(id, body): Observable<RadiologyOrder> {
    return this.http
      .put<RadiologyOrder>(this.url + '/' + id, body)
      .pipe(catchError(this.handleError));
  }

  deleteRadiologyOrder(id): Observable<RadiologyOrder> {
    return this.http
      .delete<RadiologyOrder>(this.url + '/' + id)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status == 400) {
        console.log(error.error);
        return throwError(error.error);
      } else if (error.status == 401) {
        return throwError('invalid username or password');
      } else if (error.status == 0) {
        return throwError('network failure please try again later ');
      }
      return throwError(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
