<div class="float-right">


    <button mat-raised-button (click)="printReports()">Print</button>
    <button mat-raised-button (click)="downloadPDF()">Download</button>
</div>
<div class="float-right"><span>
        <div class="send-email-prescription d-flex align-items-baseline">

            <mat-form-field class="w-100 mr-3" appearance="outline">
                <mat-label>Enter Email to send records</mat-label>
                <input matInput type="text" [formControl]="email" placeholder="Enter email">
            </mat-form-field>
            <button mat-raised-button color="primary" class="mx-5" (click)="send()">Send</button> <br>

        </div>
    </span></div>

<div class="container" #PatientVisit id="printable">
    <div [innerHTML]="reportContent"></div>
</div>