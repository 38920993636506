<div>
    <!-- <h2>Peronalised Templates</h2>

    <div>
        <mat-form-field appearance="fill" class="example-full-width">
            <mat-label>Search by Name</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input type="text" #searchValue (keyup)="search(searchValue)" matInput>

        </mat-form-field>
    </div> -->
    <!-- <table class="table table">
        <thead>
            <th>Name</th>
            <th>Load Template</th>
            <th>Delete</th>
        </thead>
        <tbody>
            <tr *ngFor="let template of templates ">
                <td> {{ template.Name }}</td>
                <td><button (click)="loadTemplate(template.Id)" mat-raised-button color="info">Load</button></td>
                <td><button (click)="delete(template.Id)" mat-raised-button
                        color="accent"><mat-icon>delete</mat-icon></button></td>
            </tr>
        </tbody>
    </table> -->

    <!-- {{ template.Content }} -->

    <div class="ml-3">
        <div class="row">
            <div class="col-lg-6 float-right">
                <mat-form-field class="example-full-width mt-5">
                    <mat-label>Filter</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex.Mia" #input>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class=" mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>




            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let row" (click)="loadTemplate(row.Id)"> {{row.Name}} </td>
            </ng-container>


            <!-- 
            <ng-container matColumnDef="Load">
                <th mat-header-cell *matHeaderCellDef> Load</th>
                <td mat-cell *matCellDef="let row">
                    <button (click)="loadTemplate(row.Id)" mat-mini-fab
                        color="primary"><mat-icon>search</mat-icon></button>
                </td>
            </ng-container> -->

            <ng-container matColumnDef="Update">
                <th mat-header-cell *matHeaderCellDef> Edit </th>
                <td mat-cell *matCellDef="let row">
                    <button type="button" [routerLink]="['/updateNurseTemplate/',row.Id]" mat-mini-fab color="primary">
                        <mat-icon matPrefix>edit</mat-icon>
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="Delete">
                <th mat-header-cell *matHeaderCellDef> Delete</th>
                <td mat-cell *matCellDef="let row">
                    <button (click)="delete(row.Id)" mat-mini-fab color="accent"><mat-icon>delete</mat-icon></button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
    &nbsp;


</div>


<div class="mx-5 my-5 " [innerHTML]="templateContent">
</div>