<div class="row">

    <div class="col-lg regFrm mat-elevation-z8">
        <h1>Update Progress Notes</h1>
        <form [formGroup]="Frm" (ngSubmit)="formSubmit()" class="example-form">
            <mat-form-field class="example-full-width">
                <mat-label>Report Name</mat-label>
                <!-- <input type="text" formControlName="ReportName" matInput> -->
                <input type="text" matInput formControlName="ReportName" [matAutocomplete]="auto5"> <mat-icon
                    matSuffix>search</mat-icon>
                <mat-autocomplete #auto5="matAutocomplete">
                    <mat-option *ngFor="let dos of filteredReportName | async" [value]="dos">
                        {{ dos }}
                    </mat-option>
                </mat-autocomplete>
                <mat-error>Please enter Report Name</mat-error>
            </mat-form-field>

            <!-- (input)="onTextInput($event)" -->
            <mat-form-field appearance="fill" class="example-full-width">
                <mat-label>Description</mat-label>
                <textarea #textarea (click)="captureCursorPosition($event)" [(ngModel)]="textBoxContent" matInput
                    cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="10" cdkAutosizeMaxRows="140"
                    formControlName="ReportDescription" matInput (keyup)="captureCursorPosition($event)"></textarea>

                <button type="button" class="app-speech-input-btn" (click)="start()"></button>
                <button type="button" color="accent" (click)="stop()" mat-mini-fab><mat-icon>stop</mat-icon></button>


            </mat-form-field>


            <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                Successfully submitted
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <button class="float-right lgBtCl" mat-button>
                <mat-icon>save</mat-icon>
                Sign & Save</button>

        </form>

    </div>
</div>





<!-- 
  <textarea #textarea (click)="captureCursorPosition($event)" (keyup)="captureCursorPosition($event)" rows="10" cols="50"
    placeholder="Type here or use voice recognition..."></textarea>
  
  <button (click)="startVoiceRecognition()">Start Voice Recognition</button> -->