import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { PatientService } from 'src/app/services/patient.service';
import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-patient-data-scanner',
  templateUrl: './patient-data-scanner.component.html',
  styleUrls: ['./patient-data-scanner.component.css'],
})
export class PatientDataScannerComponent implements OnInit {
  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router
  , private tokenService: TokenService) {}

  ngOnInit(): void {}

  // https://www.npmjs.com/package/angularx-qrcode
  qrCodeString = 'This is a secret qr code message';
  scannedResult: any;
  content_visibility = '';

  async checkPermission() {
    try {
      // check or request permission
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        // the user granted permission
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
    }
  }

  async startScan(e) {
    try {
      const permission = await this.checkPermission();
      // if(!permission) {
      //   return;
      // }
      await BarcodeScanner.hideBackground();
      document.querySelector('body').classList.add('scanner-active');
      this.content_visibility = 'hidden';
      const result = await BarcodeScanner.startScan();
      // console.log(result);
      BarcodeScanner.showBackground();
      document.querySelector('body').classList.remove('scanner-active');
      this.content_visibility = '';
      if (result?.hasContent) {
        this.scannedResult = result.content;
        const patientId = parseInt(this.scannedResult);
        this.router.navigate(['/nursePatient/', patientId]);
      }
    } catch (e) {
      console.log(e);
      this.stopScan();
    }
  }

  stopScan() {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    document.querySelector('body').classList.remove('scanner-active');
    this.content_visibility = '';
  }

  ngOnDestroy(): void {
    this.stopScan();
  }
}
