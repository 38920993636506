import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientService } from 'src/app/services/patient.service';
import { Patient } from '../../Models/patient.model';
import { PatientVisit } from '../../Models/patientvisit.model';
import { PatientvisitService } from '../../services/patientvisit.service';

@Component({
  selector: 'app-patientdetails',
  templateUrl: './patientdetails.component.html',
  styleUrls: ['./patientdetails.component.css'],
})
export class PatientdetailsComponent implements OnInit {
  id = 0;
  patient: Patient;
  patientVisitList: PatientVisit[] = [];
  isAdmitted = false;
  filteredList;
  pvId = 0;
  PatientVisitId: PatientVisit[];
  showItem = '';
  show = true;
  isHide: boolean;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    private patientVisitService: PatientvisitService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    this.patientService.getPatientvisits(this.id).subscribe((res) => {
      this.patient = res;

      this.filteredList = res.PatientVisits.sort(function (x, y) {
        return y.PatientVisitId - x.PatientVisitId;
      });
      // this.patientVisitList = res.PatientVisits;
    });
  }

  printPage() {
    // window.print();
    document.execCommand('print');
  }
  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.patientVisitList.filter((x) =>
        x.Date.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  deleteVisit(id) {
    let C_confirm = confirm('Are you Sure you want to delete ?');
    if (C_confirm == true) {
      this.patientVisitService.deletePatientVisit(id).subscribe((res) => {
        this.filteredList = this.patientVisitList.filter(
          (x) => x.PatientVisitId != id
        );
      });
    }
  }

  itemsToShow = 2;

  // Function to load more items
  loadMore() {
    this.itemsToShow += 1; // Increase the number of items to show by 3
  }

  // Function to check if there are more items to load
  hasMoreItems() {
    return this.itemsToShow < this.filteredList.length;
  }
}
