<mat-tab-group (selectedTabChange)="tabChanged($event)">
  <!-- *ngIf = "!currentPatient.IsInsuranceCompleted" -->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">add</mat-icon>
      Create Lab Report
    </ng-template>
    <div class="container">
      <div class="row mt-3">
        <div class="col-lg-3 ">

          Patient Name : {{patient.PatientFirstName}}
          {{patient.PatientLastName}},
        </div>
        <div class="col-lg-5">

          D.O.B : {{patient.PatientDOB}},
          Patient No : {{patient.PatientId}}

        </div>
        <div class="col-lg-4"></div>
      </div>
      <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
          <form (keydown.enter)="$event.preventDefault()" [formGroup]="labFrm" (ngSubmit)="formSubmit()"
            class="example-form">
            <div class="row">
              <div class="col-lg-3">

                <mat-form-field class="example-full-width">
                  <mat-label>Report Type</mat-label>
                  <input type="text" formControlName="ReportType" matInput>
                  <mat-error>Please enter ReportType</mat-error>
                </mat-form-field>

              </div>

              <div class="col-lg-3">

                <mat-form-field class="example-full-width">
                  <mat-label>Date Of Report</mat-label>
                  <input type="date" formControlName="DateOfReport" matInput>
                  <mat-error>Please enter Date of report</mat-error>
                </mat-form-field>

              </div>
              <div class="col-lg-3">

                <mat-form-field class="example-full-width">
                  <mat-label>Date Drawn</mat-label>
                  <input type="date" formControlName="DateDrawn" matInput>
                  <mat-error>Please enter Date Drawn</mat-error>
                </mat-form-field>

              </div>
              <div class="col-lg-3">

                <mat-form-field class="example-full-width">
                  <mat-label>Date Received</mat-label>
                  <input type="date" formControlName="DateReceived" matInput>
                  <mat-error>Please enter Date Received</mat-error>
                </mat-form-field>

              </div>

            </div>

            <div class="row">
              <div class="col-lg-4">

                <mat-form-field class="example-full-width">
                  <mat-label>Ordered By Doctor</mat-label>




                  <mat-select formControlName="OrderedBy">

                    <mat-option *ngFor="let doctor of doctorList"
                      [value]="doctor.DoctorName">{{doctor.DoctorName}}</mat-option>

                  </mat-select>
                </mat-form-field>

              </div>
              <div class="col-lg-4">

                <mat-form-field class="example-full-width">
                  <mat-label>Name of Laboratory</mat-label>
                  <input type="text" formControlName="LabName" matInput>
                  <mat-error>Please enter Name of Laboratory</mat-error>
                </mat-form-field>

              </div>
              <div class="col-lg-4">

                <mat-form-field class="example-full-width">
                  <mat-label>Reported By</mat-label>
                  <input type="text" formControlName="ReportedBy" matInput>
                  <mat-error>Please enter Reported By</mat-error>
                </mat-form-field>

              </div>


            </div>

            <div class="row">
              <div class="col-lg-12">


                <mat-form-field appearance="fill" class="example-full-width">
                  <mat-label>Description </mat-label>

                  <textarea matInput formControlName="Description" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1" cdkAutosizeMaxRows="40"></textarea>
                  <mat-error *ngIf="Description.invalid && Description.touched">Please enter Description's</mat-error>

                </mat-form-field>
              </div>


            </div>

            <div class="row">

              <!-- <div class="col-lg-3">
                <input type="file" (change)="image($event)">
    
              </div> -->
            </div>
            <div class="" formArrayName="InvoiceItems">
              <div class="row" *ngFor="let item of LabTests.controls; let i=index">
                <ng-container [formGroup]="item">
                  <div class="col-lg-2">

                    <mat-form-field class="example-full-width">
                      <mat-label>Test Name</mat-label>
                      <input type="text" matInput formControlName="TestName">
                      <mat-error>Please Enter TestName</mat-error>

                    </mat-form-field>
                  </div>
                  <div class="col-lg-2">

                    <mat-form-field class="example-full-width">
                      <mat-label>Test Result Value</mat-label>
                      <input type="text" matInput formControlName="TestResultValue">
                      <mat-error>Please Enter Test Result Value</mat-error>

                    </mat-form-field>
                  </div>

                  <div class="col-lg-2">

                    <mat-form-field class="example-full-width">
                      <mat-label>Referance Range</mat-label>
                      <input type="text" matInput formControlName="ReferanceRange">
                      <mat-error>Please Enter ReferanceRange</mat-error>

                    </mat-form-field>
                  </div>
                  <div class="col-lg-2">

                    <mat-form-field class="example-full-width">
                      <mat-label>Units</mat-label>
                      <input type="text" matInput formControlName="Units">
                      <mat-error>Please Enter Units</mat-error>

                    </mat-form-field>
                  </div>

                  <div class="col-lg-1">
                    <button type="button" mat-mini-fab (click)="removeItem(i)"><mat-icon>remove</mat-icon></button>
                  </div>
                </ng-container>
              </div>

              <button type="button" mat-mini-fab color="primary" (click)="addItem()"><mat-icon>add</mat-icon></button>


            </div>

            <button class="float-right lgBtCl" mat-button>
              <mat-icon>save</mat-icon>
            </button>
            <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
              Successfully submitted Lab Report
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">upload</mat-icon>
      Upload Document
    </ng-template>
    <!-- (submitAction)="handleSubmitAction()" -->
    <app-add-upload-lab-repor></app-add-upload-lab-repor>

  </mat-tab>
</mat-tab-group>