<div class="container">
    <!-- <div class="row mt-3">
        <div class="col-lg-3 ">

            Patient Name : {{patient.PatientFirstName}}
            {{patient.PatientLastName}},
        </div>
        <div class="col-lg-5">

            D.O.B : {{patient.PatientDOB}},
            Patient No : {{patient.PatientId}}

        </div>
        <div class="col-lg-4"></div>
    </div> -->
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">

            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">
                <h1 class="mx-5 my-5">New- Electromyography</h1>


                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Side</mat-label>
                            <input type="text" formControlName="Side" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Muscle</mat-label>
                            <input type="text" formControlName="Muscle" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Nerve</mat-label>
                            <input type="text" formControlName="Nerve" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Root</mat-label>
                            <input type="text" formControlName="Root" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Ins.Act</mat-label>
                            <input type="text" formControlName="InsAct" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Fibs</mat-label>
                            <input type="text" formControlName="Fibs" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Psw</mat-label>
                            <input type="text" formControlName="Psw" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Amp</mat-label>
                            <input type="text" formControlName="Amp" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Duration</mat-label>
                            <input type="text" formControlName="Duration" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Poly</mat-label>
                            <input type="text" formControlName="Poly" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Recrt</mat-label>
                            <input type="text" formControlName="Recrt" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                        <mat-form-field class="example-full-width">
                            <mat-label>Int.Pat</mat-label>
                            <input type="text" formControlName="IntPat" matInput>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <mat-form-field class="example-full-width">
                            <mat-label> Comments</mat-label>
                            <textarea matInput formControlName="Comment" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="140"></textarea>
                            <mat-error>Please enter a value</mat-error>

                        </mat-form-field>
                    </div>
                </div>
                <button type="submit" [disabled]="Frm.invalid" class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                </button>
                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>
            <button class="float-right btn-danger mx-3" (click)="resetFn($event)" mat-raised-button>
                reset
            </button>
        </div>

    </div>
</div>