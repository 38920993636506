<div>
    <app-tab2-nurse-side [tabs2Array]="tabs2" (onTabChange)="tabChange2($event)"></app-tab2-nurse-side>
</div>

<div *ngIf="activatedTabIndex2 ==0">

    <app-view-nursereport></app-view-nursereport>

</div>
<div *ngIf="activatedTabIndex2 ==1">

    <app-vitals-chart></app-vitals-chart>

</div>
<div *ngIf="activatedTabIndex2 ==2">

    <app-view-nurse-note></app-view-nurse-note>

</div>
<div *ngIf="activatedTabIndex2 ==3">

    <app-view-prescription-by-nurse></app-view-prescription-by-nurse>

</div>

<div *ngIf="activatedTabIndex2 ==4">

    <app-view-doctor-report></app-view-doctor-report>

</div>
<div *ngIf="activatedTabIndex2 ==5">

    <app-doctor-patient-visit></app-doctor-patient-visit>

</div>
<div *ngIf="activatedTabIndex2 ==6">

    <app-view-communication-assessment></app-view-communication-assessment>


</div>
<div *ngIf="activatedTabIndex2 ==7">

    <app-view-doctor-initial-evaluation></app-view-doctor-initial-evaluation>

</div>
<div *ngIf="activatedTabIndex2 ==8">

    <app-view-lab-order></app-view-lab-order>

</div>
<div *ngIf="activatedTabIndex2 ==9">

    <app-view-radiology-order></app-view-radiology-order>


</div>

<div *ngIf="activatedTabIndex2 ==10">

    <app-view-procedure></app-view-procedure>

</div>
<div *ngIf="activatedTabIndex2 ==11">

    <app-view-labreport></app-view-labreport>


</div>
<div *ngIf="activatedTabIndex2 ==12">

    <app-lab-test-chart></app-lab-test-chart>

</div>
<div *ngIf="activatedTabIndex2 ==13">

    <app-view-test-results></app-view-test-results>


</div>
<div *ngIf="activatedTabIndex2 ==14">

    <app-view-radiology-report></app-view-radiology-report>



</div>
<div *ngIf="activatedTabIndex2 ==15">

    <app-view-mri></app-view-mri>

</div>
<div *ngIf="activatedTabIndex2 ==16">

    <app-vew-doctor-note></app-vew-doctor-note>


</div>
<div *ngIf="activatedTabIndex2 ==17">

    <app-view-nerve-study-results></app-view-nerve-study-results>

</div>
<div *ngIf="activatedTabIndex2 ==18">

    <app-view-electromyography></app-view-electromyography>

</div>
<div *ngIf="activatedTabIndex2 ==19">

    <app-view-allergy></app-view-allergy>


</div>
<div *ngIf="activatedTabIndex2 ==20">

    <app-view-surgical-history></app-view-surgical-history>


</div>
<div *ngIf="activatedTabIndex2 ==21">

    <app-patientsummary></app-patientsummary>



</div>

<div *ngIf="activatedTabIndex2 ==22">

    <app-view-pain-chart></app-view-pain-chart>



</div>

<div *ngIf="activatedTabIndex2 ==23">

    <app-view-nurse-progress-note></app-view-nurse-progress-note>



</div>