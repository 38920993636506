<h4 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">Patient Name: {{ data.userFname }} {{ data.userLname
    }}</h4>
<p> Patient MRN: {{data.userId}}</p>
<hr>
<div mat-dialog-content>
    <div class="my-3 ">

        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Appointment</h1>
            <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5 " role="alert">
                Successfully Scheduled appointment
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="addVisitFrm" (ngSubmit)="formSubmit()" class="example-form">
                <div class="row">
                    <div class="col-lg-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Doctor Name</mat-label>

                            <mat-select formControlName="DoctorId" (selectionChange)="getTimeSlots();getSpeciality()">
                                <mat-option *ngFor="let doctor of doctorList" [value]="doctor.DoctorId">{{
                                    doctor?.DoctorName
                                    }}</mat-option>
                            </mat-select>
                            <mat-error>Select a Doctor</mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-lg-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Doctor Specialization</mat-label>
                            <input type="text" formControlName="DoctorSpecialization" matInput>
                            <!-- <mat-select formControlName="DoctorSpecialization">
                                <mat-option *ngFor="let sp of doctorSpeciality" [value]="sp">{{
                                    sp
                                    }}</mat-option>
                                <mat-option *ngFor="let doctor of doctorList" [value]="doctor.DoctorSpecialization">{{
                      doctor.DoctorSpecialization }}</mat-option>
                            </mat-select> -->
                            <mat-error>Select a Doctor specialization</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <p class="text-primary my-3">Please select a doctor to enable Select a date*</p>

                <div class="row">
                    <div class="col-lg-6">

                        <mat-form-field class="example-full-width"
                            [ngClass]="datePickerDisabled ? 'strict-disabled': '' ">
                            <mat-label>Select a date </mat-label>
                            <input matInput formControlName="VisitDate" [matDatepicker]="picker"
                                (dateInput)="selectDate($event)" [disabled]="!UnAvailableDates?.length"
                                [matDatepickerFilter]="boundDateStart" />
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error>Select a Date </mat-error>

                        </mat-form-field>
                    </div>

                    <div class="col-lg-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>VisitType</mat-label>
                            <!-- <input type="text" formControlName="VisitType" matInput > -->
                            <!-- <mat-error >Please enter Visit Type</mat-error>  -->

                            <mat-select formControlName="VisitType">
                                <mat-option *ngFor="let op of options" [value]="op">{{
                                    op
                                    }}</mat-option>
                            </mat-select>
                            <mat-error>Visit Type required</mat-error>
                        </mat-form-field>
                    </div>


                </div>
                <div class="row">


                    <div class="col-2">
                        <mat-form-field class="example-full-width">
                            <mat-label>Country Code</mat-label>
                            <input type="text" formControlName="CountryCode" [matAutocomplete]="auto" matInput
                                placeholder="+1, +91">
                            <mat-error>Please enter a Phone Number with country code eg: +1,+91,+44</mat-error>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                    {{ option }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field class="example-full-width">
                            <mat-label>Phone Number</mat-label>
                            <input type="text" formControlName="PhoneNumber" matInput>
                            <mat-error>Please enter a Phone Number</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <mat-form-field class="example-full-width">
                            <mat-label>Reason For Visit </mat-label>
                            <!-- <mat-icon matPrefix>event</mat-icon> -->
                            <!-- <input matInput type="text" class="txt_height" formControlName="TodoText"> -->
                            <textarea matInput formControlName="Message" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="40"></textarea>


                            <mat-error *ngIf="Message.invalid && Message.touched">Please enter Reason For
                                Visit</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <ng-container *ngFor="let slot of timeSlots">
                        <div class="col-lg-2 col-md-3 col-6 mb-2" *ngIf="!selectedSlot">
                            <button type="button" [disabled]="takenSlots.includes(slot)"
                                [class.active]="selectedSlot == slot" mat-stroked-button color="primary" class="w-100"
                                (click)="selectSlot(slot)" *ngIf="!selectedSlot">
                                {{ slot }}
                            </button>
                        </div>
                    </ng-container>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6">
                                <button type="button" class="active" mat-stroked-button color="primary" class="w-100"
                                    *ngIf="selectedSlot">
                                    {{ selectedSlot }}
                                </button>

                            </div>
                            <div class="col-md-6 pt-3">
                                <span *ngIf="selectedSlot" (click)="selectTime()"
                                    style="border-bottom: 1px solid rgb(8, 134, 238);color:rgb(8, 134, 238)">Select
                                    another
                                    time</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-md-6 ">
                        <mat-checkbox color="primary" class="" formControlName="NotificationConsent">
                            <p class="text-wrap"> By checking this box, I agree to receive SMS and Email notifications
                                from
                                Binsera®
                            </p>
                        </mat-checkbox>
                    </div>
                </div>
                <button class="float-right " [class]="visitForm.NotificationConsent.value ? 'lgBtCl': '' " mat-button
                    [disabled]="!visitForm.NotificationConsent.value">
                    <mat-icon>save</mat-icon>
                </button>
            </form>
        </div>
    </div>
</div>

<div mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close>Close</button>
    <!-- <button mat-button>Save</button> -->
</div>
<!-- </div> -->