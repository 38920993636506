<div>

    <div class="toolbar  mat-elevation-z6">

        <!-- <button mat-raised-button [routerLink]="['/hospitalDash/']" class="backG mx-5"
            [matMenuTriggerFor]="crt"><mat-icon>dashboard</mat-icon>Back to Dashboard</button> -->
        <!-- <h4 class="text-white ml-5 "><img class="nurseimg" src="{{patient?.PatientImage}}" alt="">

            <span class="text-white ml-3 font-weight-bold"> Patient Name: {{patient?.PatientFirstName}}
                {{patient?.PatientLastName}},
                Date
                Of
                Birth :
                {{patient?.PatientDOB}}, MRN :
                {{patient?.PatientId}}</span>
        </h4> -->

        <!-- <br> -->
        <button type="button" (click)="logout()" class="float-right mx-2 my-2" color="info" mat-raised-button>
            Logout
        </button>
        <mat-menu class="ItemS " #crt="matMenu">
            <!-- <div class="ItemS">
                <mat-icon mat-list-icon>dashboard</mat-icon>

                <div class="mx-2" [routerLink]="['/doctorDash/']"> Doctor Dashboard</div>
            </div> -->
            <!-- <div class="ItemS " (click)="showItem='0'" [ngClass]="{'active': showItem =='0'}">
                <mat-icon mat-list-icon>dashboard</mat-icon>
                <div class="mx-2">Back</div>
                <mat-icon>navigate_next</mat-icon>
            </div> -->

            <hr>
        </mat-menu>

    </div>

    <div class="main ">


        <div class="row ">
            <div class="col-md-1 ">

                <div class="patient-image" [ngStyle]="{
                    'background-image': 'url(' + patient?.PatientImage + ')'
                  }">

                </div>
                <br>
                <span style="font-weight: bold; font-size: medium; margin-bottom: 10px;">
                    {{patient?.PatientFirstName}}
                    {{patient?.PatientLastName}}</span> <br>
                MRN : {{patient?.PatientId}}, <br>




                D.O.B : {{patient?.PatientDOB}},<br>
                Sex : {{patient?.PatientGender}}<br>
                Address : {{patient?.PatientAddress}}


            </div>

            <div class="col-md-5  ">
                <div>
                    <app-tab2-clinic-side [tabsArray]="tabs2" (onTabChange)="tabChange($event)"></app-tab2-clinic-side>
                </div>

                <div *ngIf="activatedTabIndex ==0">
                    <app-add-appointment></app-add-appointment>


                </div>
                <div *ngIf="activatedTabIndex ==1">
                    <app-patient-intake-items></app-patient-intake-items>

                </div>

                <div *ngIf="activatedTabIndex ==2">
                    <app-add-invoice></app-add-invoice>

                </div>
                <div *ngIf="activatedTabIndex ==3">
                    <app-add-patient-insurance></app-add-patient-insurance>

                </div>
                <div *ngIf="activatedTabIndex ==4">
                    <app-add-patient-idproof></app-add-patient-idproof>

                </div>
                <div *ngIf="activatedTabIndex ==5">
                    <app-add-emergency-contact></app-add-emergency-contact>

                </div>
                <div *ngIf="activatedTabIndex ==6">
                    <app-add-patient-pharmacy></app-add-patient-pharmacy>

                </div>
                <div *ngIf="activatedTabIndex ==7">
                    <app-patient-history></app-patient-history>


                </div>
                <!-- <div *ngIf="activatedTabIndex ==8">
                    <app-add-refferal></app-add-refferal>


                </div> -->

                <div *ngIf="activatedTabIndex ==8">
                    <app-add-upload-lab-repor></app-add-upload-lab-repor>


                </div>
                <div *ngIf="activatedTabIndex ==9">
                    <app-add-upload-radiology-report></app-add-upload-radiology-report>


                </div>
                <div *ngIf="activatedTabIndex ==10">
                    <app-add-pain-chart></app-add-pain-chart>


                </div>




            </div>
            <div class="col-md-6 ">
                <div>
                    <app-tab-clinic-side [tabsArray]="tabs" (onTabChange)="tabChange2($event)"></app-tab-clinic-side>
                </div>

                <div *ngIf="activatedTabIndex2 ==0">
                    <app-completed-forms></app-completed-forms>


                </div>
                <div *ngIf="activatedTabIndex2 ==1">
                    <app-patientsummary></app-patientsummary>


                </div>
                <div *ngIf="activatedTabIndex2 ==2">
                    <app-view-appointment></app-view-appointment>


                </div>
                <div *ngIf="activatedTabIndex2 ==3">
                    <app-view-invoice></app-view-invoice>


                </div>
                <div *ngIf="activatedTabIndex2 ==4">
                    <app-view-payment-history></app-view-payment-history>


                </div>
                <div *ngIf="activatedTabIndex2 ==5">
                    <app-view-patient-insurance></app-view-patient-insurance>


                </div>
                <div *ngIf="activatedTabIndex2 ==6">
                    <app-view-patient-idproof></app-view-patient-idproof>


                </div>
                <div *ngIf="activatedTabIndex2 ==7">
                    <app-emergency-contact></app-emergency-contact>


                </div>
                <div *ngIf="activatedTabIndex2 ==8">
                    <app-view-patient-pharmacy></app-view-patient-pharmacy>

                </div>

                <div *ngIf="activatedTabIndex2 ==9">
                    <app-view-allergy></app-view-allergy>

                </div>
                <div *ngIf="activatedTabIndex2 ==10">
                    <app-view-surgical-history></app-view-surgical-history>

                </div>
                <div *ngIf="activatedTabIndex2 ==11">
                    <app-view-refferal></app-view-refferal>

                </div>
                <div *ngIf="activatedTabIndex2 ==12">
                    <app-view-lab-order></app-view-lab-order>

                </div>
                <div *ngIf="activatedTabIndex2 ==13">
                    <app-view-labreport></app-view-labreport>

                </div>
                <div *ngIf="activatedTabIndex2 ==14">
                    <app-view-radiology-order></app-view-radiology-order>

                </div>
                <div *ngIf="activatedTabIndex2 ==15">
                    <app-view-radiology-report></app-view-radiology-report>

                </div>
                <div *ngIf="activatedTabIndex2 ==16">
                    <app-view-test-results></app-view-test-results>

                </div>
                <div *ngIf="activatedTabIndex2 ==17">
                    <app-view-nerve-study-results></app-view-nerve-study-results>

                </div>
                <div *ngIf="activatedTabIndex2 ==18">
                    <app-view-electromyography></app-view-electromyography>

                </div>
                <div *ngIf="activatedTabIndex2 ==19">
                    <app-view-vaccine></app-view-vaccine>

                </div>
                <div *ngIf="activatedTabIndex2 ==20">
                    <app-staff-view-prescriptiondisplay></app-staff-view-prescriptiondisplay>

                </div>
                <div *ngIf="activatedTabIndex2 ==21">
                    <app-patientdetails></app-patientdetails>


                </div>
                <div *ngIf="activatedTabIndex2 ==22">
                    <app-view-doctor-report></app-view-doctor-report>

                </div>
                <div *ngIf="activatedTabIndex2 ==23">
                    <app-view-pastmedication></app-view-pastmedication>

                </div>
                <div *ngIf="activatedTabIndex2 ==24">
                    <app-view-pain-chart></app-view-pain-chart>

                </div>




            </div>

        </div>
    </div>










</div>