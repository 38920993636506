<div class="container">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>New Roster</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="Frm" (ngSubmit)="formSubmit()"
                class="example-form">

                <mat-form-field class="example-full-width">
                    <mat-label>Ward Name</mat-label>
                    <mat-select formControlName="WardId">
                        <mat-option *ngFor="let ward of wardList" [value]="ward.WardId">{{
                            ward.WardName }}</mat-option>
                    </mat-select>
                    <mat-error>Select a Ward</mat-error>
                </mat-form-field>

                <!-- 
                <mat-form-field class="example-full-width">
                    <mat-label>Week Commencing</mat-label>
                    <input type="text" matInput formControlName="WardWeekCommencing">
                    <mat-error>Please enter WeekCommencing</mat-error>
                </mat-form-field> -->
                <mat-form-field appearance="fill">
                    <mat-label>Week Commencing</mat-label>
                    <input matInput [matDatepicker]="weekPicker" formControlName="WardWeekCommencing"
                        [(ngModel)]="weekCommencing" name="weekCommencing" (dateChange)="onDateChange($event)"
                        required />
                    <mat-datepicker-toggle matSuffix [for]="weekPicker"></mat-datepicker-toggle>
                    <mat-datepicker #weekPicker startView="month" (closed)="adjustToWeekStart()"></mat-datepicker>
                </mat-form-field>

                <!-- <button mat-raised-button color="primary" type="submit">Select Week</button> -->




                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>
            </form>
            <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                Successfully Created Roster
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    </div>
</div>