import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorReport } from 'src/app/Models/doctorreport.model';
import { LabOrder } from 'src/app/Models/laborder.model';
import { Patient } from 'src/app/Models/patient.model';
import { DoctorReportService } from 'src/app/services/doctor-report.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-view-doctor-report',
  templateUrl: './view-doctor-report.component.html',
  styleUrls: ['./view-doctor-report.component.css'],
})
export class ViewDoctorReportComponent implements OnInit {
  displayedColumns: string[] = [
    'Date',
    'ReportName',
    'DoctorName',
    'Decription',
    'Signature',
    'View',
    'Update',
    'Delete',
  ];

  id = 0;
  doctorReport: DoctorReport[] = [];
  dataSource: MatTableDataSource<any>;
  labDocument: string = '';
  patient: Patient;
  reportDetails: string;
  helper = new JwtHelperService();
  myToken = window.localStorage.getItem('token');

  CurrentDoctorId: number;

  constructor(
    private patientService: PatientService,
    private doctorReportService: DoctorReportService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
    });
    let decodedToken = this.helper.decodeToken(this.myToken);
    this.CurrentDoctorId = parseInt(decodedToken.nameid);
    this.GetReports(this.id);
    this.getPatient(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  getPatient(id) {
    this.patientService.getPatientById(id).subscribe((res) => {
      this.patient = res;
    });
  }
  GetReports(id) {
    this.patientService.getDoctorReport(id).subscribe((res) => {
      this.dataSource = new MatTableDataSource(
        res.DoctorReports.sort(function (x, y) {
          return y.DoctorReportId - x.DoctorReportId;
        })
      );
      this.dataSource.paginator = this.paginator;
    });
  }
  delete(id) {
    this.doctorReportService.deleteDoctorReport(id).subscribe((res) => {
      alert('are you sure you wanted to delete record?');
      this.GetReports(this.id);
    });
  }
  print() {
    window.print();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public viewReport(report: DoctorReport): void {
    // console.log('report: ', report);
    this.reportDetails = report.ReportDescription;
  }
}
