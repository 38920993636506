<div class="container ">
    <div class="row">
        <div class="col-lg regFrm mat-elevation-z8">
            <h1>Register ICD 10 Code</h1>
            <form (keydown.enter)="$event.preventDefault()" [formGroup]="cptFrm" (ngSubmit)="formSubmit()"
                class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>ICD 10 Code (Please Enter Code, Description together here)</mat-label>
                    <input type="text" formControlName="Code" matInput>
                    <mat-error>Please enter ICD Code</mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <mat-label>Description</mat-label>
                    <input type="text" formControlName="Description" matInput>
                    <mat-error>Please enter Description</mat-error>

                </mat-form-field>




                <button class="float-right lgBtCl" mat-button>
                    <mat-icon>save</mat-icon>
                    Save</button>

                <div *ngIf="isSuccess" class="alert alert-success alert-dismissible fade show mt-5" role="alert">
                    Successfully submitted
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>