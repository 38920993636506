import { Component, OnInit, ViewChild } from '@angular/core';
import { Nurse } from '../../Models/nurse.model';
import { ActivatedRoute } from '@angular/router';
import { NurseService } from '../../services/nurse.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../../services/user.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HospitalService } from 'src/app/services/hospital.service';

@Component({
  selector: 'app-view-nurse',
  templateUrl: './view-nurse.component.html',
  styleUrls: ['./view-nurse.component.css'],
})
export class ViewNurseComponent implements OnInit {
  id = 0;
  filteredList: Nurse[];
  nurseList: Nurse[] = [];
  dataSource: MatTableDataSource<Nurse>;

  constructor(
    private router: ActivatedRoute,
    private hospitalService: HospitalService,
    private userService: UserService,
    private nurseService: NurseService
  ) {}

  ngOnInit(): void {
    this.getNurse(this.id);
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  getNurse(id) {
    this.hospitalService.getNurses(id).subscribe((res) => {
      this.filteredList = res.Nurses;
      this.nurseList = res.Nurses;
      // this.dataSource = new MatTableDataSource(res);
      // this.dataSource.paginator=this.paginator;
    });
  }

  tabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.getNurse(this.id);
    }
  }

  search(searchValue) {
    // console.log(searchValue);
    if (searchValue.value != null) {
      this.filteredList = this.nurseList.filter((x) =>
        x.NurseName.toLowerCase().includes(searchValue.value.toLowerCase())
      );
    }
  }

  delete(id) {
    this.nurseService.deleteNurse(id).subscribe((res) => {
      this.userService.deleteUser(id).subscribe();
      this.getNurse(this.id);
    });
  }
}
