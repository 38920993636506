<div>

    <div class="toolbar mat-elevation-z6">

        <button mat-raised-button [routerLink]="['/nurseDash/']" class="backG mx-5"
            [matMenuTriggerFor]="crt"><mat-icon>dashboard</mat-icon>Nurse Dashboard</button>
        <button type="button" (click)="logout()" class="float-right mx-2 my-2" color="info" mat-raised-button>
            Logout
        </button>
        <mat-menu class="ItemS " #crt="matMenu">
            <!-- <div class="ItemS">
                <mat-icon mat-list-icon>dashboard</mat-icon>

                <div class="mx-2" [routerLink]="['/doctorDash/']"> Doctor Dashboard</div>
            </div> -->
            <!-- <div class="ItemS " (click)="showItem='0'" [ngClass]="{'active': showItem =='0'}">
                <mat-icon mat-list-icon>dashboard</mat-icon>
                <div class="mx-2">Back</div>
                <mat-icon>navigate_next</mat-icon>
            </div> -->

            <hr>
        </mat-menu>
        <h2 class="ml-5 text-white text-center"><img class="nurseimg " src="{{nurse?.NurseImage}}" alt="">
            {{nurse?.NurseName}} (RN) <span class="text-white "> </span></h2>
    </div>

    <div class="main ">


        <div class="row ">
            <div class="col-md-1 ml-2 mt-2">

                <div class="patient-image" [ngStyle]="{
                    'background-image': 'url(' + patient?.PatientImage + ')'
                  }">

                </div>
                <br>
                <span style="font-weight: bold; font-size: medium; margin-bottom: 10px;">
                    {{patient?.PatientFirstName}}
                    {{patient?.PatientLastName}}</span> <br>
                MRN : {{patient?.PatientId}}, <br>

                <!-- Patient Name : {{patient.PatientFirstName}}
                {{patient.PatientLastName}}<br> -->


                D.O.B : {{patient?.PatientDOB}},<br>
                Sex : {{patient?.PatientGender}}<br>
                Address : {{patient?.PatientAddress}}


            </div>

            <div class="col-md-5  ">
                <div>
                    <app-tab-nurse-side [tabsArray]="tabs" (onTabChange)="tabChange($event)"></app-tab-nurse-side>
                </div>

                <div *ngIf="activatedTabIndex ==0">
                    <app-add-nursereport></app-add-nursereport>
                </div>
                <div *ngIf="activatedTabIndex==1">
                    <app-add-nurse-note></app-add-nurse-note>

                </div>
                <div *ngIf="activatedTabIndex ==2">
                    <app-add-prescription-by-nurse></app-add-prescription-by-nurse>
                </div>

                <div *ngIf="activatedTabIndex ==3">

                    <app-add-patient-visit-by-nurse></app-add-patient-visit-by-nurse>
                </div>
                <div *ngIf="activatedTabIndex ==4">
                    <app-add-communication-assessment></app-add-communication-assessment>

                </div>
                <div *ngIf="activatedTabIndex ==5">

                    <app-new-orders></app-new-orders>


                </div>
                <div *ngIf="activatedTabIndex ==6">

                    <app-add-refferal></app-add-refferal>

                </div>
                <div *ngIf="activatedTabIndex ==7">

                    <app-add-labreport></app-add-labreport>


                </div>
                <div *ngIf="activatedTabIndex ==8">

                    <app-add-upload-lab-repor></app-add-upload-lab-repor>


                </div>
                <div *ngIf="activatedTabIndex ==9">

                    <app-add-upload-radiology-report></app-add-upload-radiology-report>


                </div>
                <div *ngIf="activatedTabIndex ==10">

                    <app-add-mri></app-add-mri>


                </div>
                <div *ngIf="activatedTabIndex ==11">

                    <app-add-allergy></app-add-allergy>


                </div>
                <div *ngIf="activatedTabIndex ==12">

                    <app-add-surgical-history></app-add-surgical-history>


                </div>


            </div>
            <div class="col ">
                <div>
                    <app-tab2-nurse-side [tabs2Array]="tabs2" (onTabChange)="tabChange2($event)"></app-tab2-nurse-side>
                </div>

                <div *ngIf="activatedTabIndex2 ==0">

                    <app-view-nursereport></app-view-nursereport>

                </div>
                <div *ngIf="activatedTabIndex2 ==1">

                    <app-vitals-chart></app-vitals-chart>

                </div>
                <div *ngIf="activatedTabIndex2 ==2">

                    <app-view-nurse-note></app-view-nurse-note>

                </div>
                <div *ngIf="activatedTabIndex2 ==3">

                    <app-view-prescription-by-nurse></app-view-prescription-by-nurse>

                </div>

                <div *ngIf="activatedTabIndex2 ==4">

                    <app-view-doctor-report></app-view-doctor-report>

                </div>
                <div *ngIf="activatedTabIndex2 ==5">

                    <app-doctor-patient-visit></app-doctor-patient-visit>

                </div>
                <div *ngIf="activatedTabIndex2 ==6">

                    <app-view-communication-assessment></app-view-communication-assessment>


                </div>
                <div *ngIf="activatedTabIndex2 ==7">

                    <app-view-doctor-initial-evaluation></app-view-doctor-initial-evaluation>

                </div>
                <div *ngIf="activatedTabIndex2 ==8">

                    <app-view-lab-order></app-view-lab-order>

                </div>
                <div *ngIf="activatedTabIndex2 ==9">

                    <app-view-radiology-order></app-view-radiology-order>


                </div>

                <div *ngIf="activatedTabIndex2 ==10">

                    <app-view-procedure></app-view-procedure>

                </div>
                <div *ngIf="activatedTabIndex2 ==11">

                    <app-view-labreport></app-view-labreport>


                </div>
                <div *ngIf="activatedTabIndex2 ==12">

                    <app-lab-test-chart></app-lab-test-chart>

                </div>
                <div *ngIf="activatedTabIndex2 ==13">

                    <app-view-test-results></app-view-test-results>


                </div>
                <div *ngIf="activatedTabIndex2 ==14">

                    <app-view-radiology-report></app-view-radiology-report>



                </div>
                <div *ngIf="activatedTabIndex2 ==15">

                    <app-view-mri></app-view-mri>

                </div>
                <div *ngIf="activatedTabIndex2 ==16">

                    <app-vew-doctor-note></app-vew-doctor-note>


                </div>
                <div *ngIf="activatedTabIndex2 ==17">

                    <app-view-nerve-study-results></app-view-nerve-study-results>

                </div>
                <div *ngIf="activatedTabIndex2 ==18">

                    <app-view-electromyography></app-view-electromyography>

                </div>
                <div *ngIf="activatedTabIndex2 ==19">

                    <app-view-allergy></app-view-allergy>


                </div>
                <div *ngIf="activatedTabIndex2 ==20">

                    <app-view-surgical-history></app-view-surgical-history>


                </div>
                <div *ngIf="activatedTabIndex2 ==21">

                    <app-patientsummary></app-patientsummary>



                </div>



            </div>
        </div>
    </div>










</div>