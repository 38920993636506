import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { DoctorInitialEvaluationService } from 'src/app/services/doctor-initial-evaluation.service';

import { TokenService } from 'src/app/services/token.service';

@Component({
  selector: 'app-update-doctor-initial-evaluation',
  templateUrl: './update-doctor-initial-evaluation.component.html',
  styleUrls: ['./update-doctor-initial-evaluation.component.css'],
})
export class UpdateDoctorInitialEvaluationComponent implements OnInit {
  helper = new JwtHelperService();

  myToken = this.tokenService.getToken();

  id = 0;

  doctorId = 0;
  isSuccess = false;
  Frm: FormGroup;
  painLevel = [
    { value: '1' },
    { value: '2' },
    { value: '3' },
    { value: '4' },
    { value: '5' },
    { value: '6' },
    { value: '7' },
    { value: '8' },
    { value: '9' },
    { value: '10' },
  ];
  seveierityOfpain = [
    { value: 'Mild' },
    { value: 'Moderate' },
    { value: 'Severe' },
    { value: 'Extremely Severe' },
  ];
  patientId: any;
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private doctorEvaluationService: DoctorInitialEvaluationService,
    private router: Router
  , private tokenService: TokenService) {}

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      this.id = res['id'];
      this.patientId = res['PatientId'];
    });
    this.doctorEvaluationService
      .getDoctorInitialEvaluationById(this.id)
      .subscribe((res) => {
        this.Frm.patchValue(res);
        this.PatientName.setValue(res.PatientName);
        this.PatientDOB.setValue(res.PatientDOB);
      });

    let decodedToken = this.helper.decodeToken(this.myToken);
    this.doctorId = parseInt(decodedToken.nameid);
    this.Frm = this.fb.group({
      DoctorId: this.doctorId,
      PatientName: ['', Validators.required],
      PatientDOB: ['', Validators.required],
      PatientHeight: ['', Validators.required],
      PatientWeight: ['', Validators.required],
      BloodPressure: ['', Validators.required],
      Pulse: ['', Validators.required],
      IsPatientonAnyMedication: ['', Validators.required],
      IsPatientUnderAnyMedicalTreatment: ['', Validators.required],
      RefferringPhysicianName: [''],
      RefferringPhysicianAddress: [''],
      RefferringPhysicianPhone: [''],
      PrimaryCareDoctorName: [''],
      //CheifComplaint
      Isthisvisitrelatedworkrelatedinjury: ['', Validators.required],
      Isthisvisitrelatedtoamotorvehicleaccident: ['', Validators.required],
      Whatisthereasonforyourvisittoday: ['', Validators.required],
      Areyouexperiencinganypain: ['', Validators.required],
      PainLocation: ['', Validators.required],
      LevelOfPain: ['', Validators.required],
      DateOfSymtomsBegan: ['', Validators.required],
      SeverityOfSymptoms: ['', Validators.required],
      //Quality of Pain
      Ache: [false],
      Burning: [false],
      Deep: [false],
      Diffuse: [false],
      Discomforting: [false],
      Dull: [false],
      Localized: [false],
      Numbness: [false],
      Throbbing: [false],
      Piercing: [false],
      Sharp: [false],
      Shooting: [false],
      //Radiation of Pain
      RadiatingLowerBack: [false],
      RadiatingNeck: [false],
      RadiatingLeftArm: [false],
      RadiatingRightArm: [false],
      RadiatingLeftLeg: [false],
      RadiatingRightLeg: [false],
      RadiatingLeftHip: [false],
      RadiatingRightHip: [false],
      RadiatingLeftShoulder: [false],
      RadiatingRightShoulder: [false],
      RadiatingOtherPain: [false],
      RadiatingNoPain: [false],
      //Location of Pain
      LowerBack: [false],
      Neck: [false],
      LeftArm: [false],
      RightArm: [false],
      LeftLeg: [false],
      RightLeg: [false],
      LeftHip: [false],
      RightHip: [false],
      LeftShoulder: [false],
      RightShoulder: [false],
      NoPain: [false],
      OtherPain: [''],

      //Constitutional/Genaral
      RecentWeightChanges: [false],
      Chills: [false],
      Fever: [false],
      Weakness: [false],
      NoGeneralProblems: [false],
      OtherGeneralProblems: [''],
      //Eyes
      VisionChange: [false],
      GlassessOrContacts: [false],
      Catracts: [false],
      Glaucoma: [false],
      NoEyeProblems: [false],
      OtherEyeProblems: [''],
      //Ear,Nose,Throat
      HearingLoss: [false],
      EarAcheOrInfection: [false],
      RingingInEar: [false],
      NoEarProblems: [false],
      OtherEarProblems: [''],
      //Cardio Vascular
      ChestPain: [false],
      SwellingInLegs: [false],
      Palpitations: [false],
      NoCadioVascularProblems: [false],
      OtherCadioVascularProblems: [''],
      //Respiratory
      ShortnessOfBreath: [false],
      WheezingOrAsthma: [false],
      FrequentCough: [false],
      NoRespiratoryProblems: [false],
      OtherRespiratoryProblems: [''],
      //Gastrointestinal
      AcidReflex: [false],
      NauseaOrVomitimng: [false],
      AbdominalPain: [false],
      NoGastricProblems: [false],
      OtherGastricProblems: [''],
      MuscleAches: [false],
      SwellingOfJoints: [false],
      StiffnessInJoints: [false],
      NoMuskularProblems: [false],
      OtherMuskularProblems: [''],

      //Skin
      Rash: [false],
      Ulcers: [false],
      AbnormalScars: [false],
      NoSkinProblems: [false],
      OtherSkinProblems: [''],

      //Neurological

      Headaches: [false],
      Dizziness: [false],
      NumbnessTinglingLossofSensation: [false],
      NoNeurologicalProblems: [false],
      OtherNeurologicalProblems: [''],

      // Psychiatric
      Depression: [false],
      Nervousness: [false],
      Anxiety: [false],
      MoodSwings: [false],
      NoPsychatricProblems: [false],
      OtherPsychatricProblems: [''],
      //Endocrine
      ExessiveThirstOrHunger: [false],
      HotColdIntolerance: [false],
      HotFlashes: [false],
      NoEndocrine: [false],
      OtherEndocrine: [''],
      DoctorName: [''],
      Areyouonbloodthinners: [false],
      Paralysis: [false],
      PainManagementPhysician: [''],
      Areyouunderthecareofapainmanagementphysician: [false],
    });
  }

  get Areyouunderthecareofapainmanagementphysician() {
    return this.Frm.get('Areyouunderthecareofapainmanagementphysician');
  }
  get PainManagementPhysician() {
    return this.Frm.get('PainManagementPhysician');
  }
  get Areyouonbloodthinners() {
    return this.Frm.get('Areyouonbloodthinners');
  }
  get Paralysis() {
    return this.Frm.get('Paralysis');
  }
  get RefferringPhysicianName() {
    return this.Frm.get('RefferringPhysicianName');
  }
  get RefferringPhysicianAddress() {
    return this.Frm.get('RefferringPhysicianAddress');
  }
  get RefferringPhysicianPhone() {
    return this.Frm.get('RefferringPhysicianPhone');
  }
  get PrimaryCareDoctorName() {
    return this.Frm.get('PrimaryCareDoctorName');
  }
  get LowerBack() {
    return this.Frm.get('LowerBack');
  }
  get Neck() {
    return this.Frm.get('Neck');
  }
  get LeftArm() {
    return this.Frm.get('LeftArm');
  }

  get RightArm() {
    return this.Frm.get('RightArm');
  }
  get LeftLeg() {
    return this.Frm.get('LeftLeg');
  }
  get RightLeg() {
    return this.Frm.get('RightLeg');
  }
  get LeftHip() {
    return this.Frm.get('LeftHip');
  }
  get RightHip() {
    return this.Frm.get('RightHip');
  }

  get LeftShoulder() {
    return this.Frm.get('LeftShoulder');
  }
  get RightShoulder() {
    return this.Frm.get('RightShoulder');
  }
  get NoPain() {
    return this.Frm.get('NoPain');
  }
  get OtherPain() {
    return this.Frm.get('OtherPain');
  }
  get RadiatingLowerBack() {
    return this.Frm.get('RadiatingLowerBack');
  }
  get RadiatingNeck() {
    return this.Frm.get('RadiatingNeck');
  }
  get RadiatingLeftArm() {
    return this.Frm.get('RadiatingLeftArm');
  }
  get RadiatingRightArm() {
    return this.Frm.get('RadiatingRightArm');
  }
  get RadiatingLeftLeg() {
    return this.Frm.get('RadiatingLeftLeg');
  }
  get RadiatingRightLeg() {
    return this.Frm.get('RadiatingRightLeg');
  }
  get RadiatingLeftHip() {
    return this.Frm.get('RadiatingLeftHip');
  }
  get RadiatingRightHip() {
    return this.Frm.get('RadiatingRightHip');
  }
  get RadiatingLeftShoulder() {
    return this.Frm.get('RadiatingLeftShoulder');
  }
  get RadiatingRightShoulder() {
    return this.Frm.get('RadiatingRightShoulder');
  }
  get RadiatingOtherPain() {
    return this.Frm.get('RadiatingOtherPain');
  }
  get RadiatingNoPain() {
    return this.Frm.get('RadiatingNoPain');
  }
  get Ache() {
    return this.Frm.get('Ache');
  }
  get Burning() {
    return this.Frm.get('Burning');
  }
  get Deep() {
    return this.Frm.get('Deep');
  }
  get Diffuse() {
    return this.Frm.get('Diffuse');
  }
  get Discomforting() {
    return this.Frm.get('Discomforting');
  }
  get Dull() {
    return this.Frm.get('Dull');
  }
  get Localized() {
    return this.Frm.get('Localized');
  }
  get Numbness() {
    return this.Frm.get('Numbness');
  }
  get Throbbing() {
    return this.Frm.get('Throbbing');
  }

  get Piercing() {
    return this.Frm.get('Piercing');
  }
  get Sharp() {
    return this.Frm.get('Sharp');
  }
  get Shooting() {
    return this.Frm.get('Shooting');
  }
  get SeverityOfSymptoms() {
    return this.Frm.get('SeverityOfSymptoms');
  }
  get DateOfSymtomsBegan() {
    return this.Frm.get('DateOfSymtomsBegan');
  }
  get LevelOfPain() {
    return this.Frm.get('LevelOfPain');
  }
  get PainLocation() {
    return this.Frm.get('PainLocation');
  }

  get Whatisthereasonforyourvisittoday() {
    return this.Frm.get('Whatisthereasonforyourvisittoday');
  }

  get Isthisvisitrelatedtoamotorvehicleaccident() {
    return this.Frm.get('Isthisvisitrelatedtoamotorvehicleaccident');
  }
  get Isthisvisitrelatedworkrelatedinjury() {
    return this.Frm.get('Isthisvisitrelatedworkrelatedinjury');
  }
  get PatientName() {
    return this.Frm.get('PatientName');
  }
  get PatientDOB() {
    return this.Frm.get('PatientDOB');
  }
  get PatientId() {
    return this.Frm.get('PatientId');
  }
  get NurseId() {
    return this.Frm.get('NurseId');
  }
  get PatientHeight() {
    return this.Frm.get('PatientHeight');
  }
  get PatientWeight() {
    return this.Frm.get('PatientWeight');
  }
  get BloodPressure() {
    return this.Frm.get('BloodPressure');
  }
  get Pulse() {
    return this.Frm.get('Pulse');
  }
  get IsPatientonAnyMedication() {
    return this.Frm.get('IsPatientonAnyMedication');
  }
  get IsPatientUnderAnyMedicalTreatment() {
    return this.Frm.get('IsPatientUnderAnyMedicalTreatment');
  }
  get RecentWeightChanges() {
    return this.Frm.get('RecentWeightChanges');
  }
  get Chills() {
    return this.Frm.get('Chills');
  }
  get Fever() {
    return this.Frm.get('Fever');
  }
  get Weakness() {
    return this.Frm.get('Weakness');
  }
  get NoGeneralProblems() {
    return this.Frm.get('NoGeneralProblems');
  }
  get OtherGeneralProblems() {
    return this.Frm.get('OtherGeneralProblems');
  }
  get VisionChange() {
    return this.Frm.get('VisionChange');
  }
  get GlassessOrContacts() {
    return this.Frm.get('GlassessOrContacts');
  }
  get Catracts() {
    return this.Frm.get('Catracts');
  }
  get Glaucoma() {
    return this.Frm.get('Glaucoma');
  }
  get NoEyeProblems() {
    return this.Frm.get('NoEyeProblems');
  }
  get OtherEyeProblems() {
    return this.Frm.get('OtherEyeProblems');
  }
  get HearingLoss() {
    return this.Frm.get('HearingLoss');
  }
  get EarAcheOrInfection() {
    return this.Frm.get('EarAcheOrInfection');
  }
  get RingingInEar() {
    return this.Frm.get('RingingInEar');
  }
  get NoEarProblems() {
    return this.Frm.get('NoEarProblems');
  }
  get OtherEarProblems() {
    return this.Frm.get('OtherEarProblems');
  }
  get ChestPain() {
    return this.Frm.get('ChestPain');
  }
  get SwellingInLegs() {
    return this.Frm.get('SwellingInLegs');
  }
  get Palpitations() {
    return this.Frm.get('Palpitations');
  }
  get NoCadioVascularProblems() {
    return this.Frm.get('NoCadioVascularProblems');
  }
  get OtherCadioVascularProblems() {
    return this.Frm.get('OtherCadioVascularProblems');
  }
  get ShortnessOfBreath() {
    return this.Frm.get('ShortnessOfBreath');
  }
  get WheezingOrAsthma() {
    return this.Frm.get('WheezingOrAsthma');
  }
  get FrequentCough() {
    return this.Frm.get('FrequentCough');
  }
  get NoRespiratoryProblems() {
    return this.Frm.get('NoRespiratoryProblems');
  }
  get OtherRespiratoryProblems() {
    return this.Frm.get('OtherRespiratoryProblems');
  }
  get AcidReflex() {
    return this.Frm.get('AcidReflex');
  }
  get NauseaOrVomitimng() {
    return this.Frm.get('NauseaOrVomitimng');
  }
  get AbdominalPain() {
    return this.Frm.get('AbdominalPain');
  }
  get NoGastricProblems() {
    return this.Frm.get('NoGastricProblems');
  }
  get OtherGastricProblems() {
    return this.Frm.get('OtherGastricProblems');
  }
  get MuscleAches() {
    return this.Frm.get('MuscleAches');
  }
  get NoMuskularProblems() {
    return this.Frm.get('NoMuskularProblems');
  }

  get OtherMuskularProblems() {
    return this.Frm.get('OtherMuskularProblems');
  }

  get Rash() {
    return this.Frm.get('Rash');
  }

  get Ulcers() {
    return this.Frm.get('Ulcers');
  }

  get AbnormalScars() {
    return this.Frm.get('AbnormalScars');
  }

  get NoSkinProblems() {
    return this.Frm.get('NoSkinProblems');
  }

  get OtherSkinProblems() {
    return this.Frm.get('OtherSkinProblems');
  }

  get Headaches() {
    return this.Frm.get('Headaches');
  }

  get Dizziness() {
    return this.Frm.get('Dizziness');
  }

  get NumbnessTinglingLossofSensation() {
    return this.Frm.get('NumbnessTinglingLossofSensation');
  }

  get NoNeurologicalProblems() {
    return this.Frm.get('NoNeurologicalProblems');
  }

  get OtherNeurologicalProblems() {
    return this.Frm.get('OtherNeurologicalProblems');
  }

  get Depression() {
    return this.Frm.get('Depression');
  }

  get Nervousness() {
    return this.Frm.get('Nervousness');
  }

  get Anxiety() {
    return this.Frm.get('Anxiety');
  }
  get MoodSwings() {
    return this.Frm.get('MoodSwings');
  }
  get NoPsychatricProblems() {
    return this.Frm.get('NoPsychatricProblems');
  }
  get OtherPsychatricProblems() {
    return this.Frm.get('OtherPsychatricProblems');
  }
  get ExessiveThirstOrHunger() {
    return this.Frm.get('ExessiveThirstOrHunger');
  }
  get HotColdIntolerance() {
    return this.Frm.get('HotColdIntolerance');
  }
  get HotFlashes() {
    return this.Frm.get('HotFlashes');
  }
  get NoEndocrine() {
    return this.Frm.get('NoEndocrine');
  }
  get OtherEndocrine() {
    return this.Frm.get('OtherEndocrine');
  }

  formSubmit() {
    this.doctorEvaluationService
      .updateDoctorInitialEvaluation(this.id, this.Frm.value)
      .subscribe((res) => {
        this.isSuccess = true;
        this.Frm.reset();
        // this.router.navigate(['/doctorDash/']);
        this.router.navigate(['/splitScreenDctorPatient/', this.patientId]);
      });
  }
}
